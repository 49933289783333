import React, { Component } from 'react';
import _ from 'lodash';

import MainContentSection from './MainContentSection';
import TemplateSettingSection from './TemplateSettingSection';
import UploadLogo from './UploadLogo';
import UploadSeal from './UploadSeal';

import SiteSettingStore from '../../stores/SiteSettingStore';
import Loading from '../AfterSaveLoading';

export default class EditSetupDetails extends Component {
  constructor(){
    super();

    this.state = {
      loading: true,
      siteSetting: {
        code: "", 
        center_code: "",
        name: "",
        email: "",
        address: "",
        external_receiver_ids: [],
        document_code_internal: "",
        document_code_external: "",
        document_code_seal: "",
        document_code_order: "",
        document_code_rule: "",
        document_code_force: "",
        use_th_e_gif_connect: false,
        use_pass_flow: false,
        use_sign_inform: false,
        use_sent_order: false,
        use_sent_order_in_order: false,
        use_booking_number: false,
        use_replace_number: false,
        logo: "",
        seal: "",
        enable_connect_with_th_egif: false,
        use_through: false,
        use_note_signee: false,
        use_internal_forward: false,
        informee_can_forward_document: false,
        can_book_number: false,
        can_insert_number: false,
        enable_secretary: false,
        enable_on_duty: false
      },
      error_message: "",
      uploading_logo: false,
      uploading_seal: false,
      external_receivers: [],
      document_templates: [],
      document_types: []
    }

    this.reloadData = this.reloadData.bind(this);
    this.closeAndReload = this.closeAndReload.bind(this);
    this.handleUpdateSiteSetting = this.handleUpdateSiteSetting.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdateExternalReceivers = this.handleUpdateExternalReceivers.bind(this);
    this.onUpdateDocumentTemplate = this.onUpdateDocumentTemplate.bind(this);
  }

  componentWillMount(){
    this.reloadData();
  }

  reloadData(){
    SiteSettingStore.request({}).then((resp)=>{
      let siteSetting = _.get(resp.data, 'data', []);
      let external_receivers = _.get(resp.data, 'included.external_receivers', []);
      let document_templates = _.get(resp.data, 'included.document_templates', []);
      let document_types = _.get(resp.data, 'included.document_types', []);

      console.log('document_templates ==>', document_templates)

      this.setState({
        siteSetting: siteSetting,
        external_receivers: external_receivers,
        document_templates: document_templates,
        document_types: document_types
      })
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  handleUpdateSiteSetting(event){
    let siteSetting = {...this.state.siteSetting};
    const key = event.target.name;
    const value = event.target.value;

    _.set(siteSetting, key, value);

    this.setState({
      siteSetting: siteSetting
    })
  }

  handleSubmit(){
    let self = this;
    var normalized = SiteSettingStore.normalizeForSave(this.state.siteSetting);
    
    SiteSettingStore.request({
      method: 'put',
      action: 'setup',
      data: { 
        site_setting: normalized, 
        document_templates: this.state.document_templates,
        external_receiver_ids: this.state.siteSetting.external_receiver_ids,
        document_types: this.state.document_types
      },
    }).then(()=>{
      // this.props.handleShow();
      window.location.href = '/settings/setup';
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      self.setState({error_message: error_message});
    });
  }

  closeAndReload(){
    this.setState({
      uploading_seal: false, 
      uploading_logo: false
    }, this.reloadData());
  }

  handleUpdateExternalReceivers(field_name, value){
    let { siteSetting } = this.state;
    siteSetting[field_name] = value;

    this.setState({siteSetting: siteSetting});
  }

  onUpdateDocumentTemplate(document_template_id, disable_value){
    let { document_templates } = this.state;
    let template = _.find(document_templates, {id: document_template_id})
    template.disable = disable_value;

    this.setState({document_templates: document_templates});
  }

  render(){
    return (
      <div>
        {this.state.loading && <Loading />}
        <form>
          <div className='card-box-shadow darkgreen-border-top p-4 mb-4 bg-light'>
            <MainContentSection 
              {...this.state.siteSetting}
              external_receiver_ids={this.state.siteSetting.external_receiver_ids}
              external_receivers={this.state.external_receivers} 
              onUpdateSiteSetting={this.handleUpdateSiteSetting}
              onUpdateExternalReceivers={this.handleUpdateExternalReceivers} />
          </div>
          <div className='card-box-shadow darkcyan-border-top p-4 mb-4 bg-light'>
            <TemplateSettingSection 
              document_types={this.state.document_types}
              document_templates={this.state.document_templates}
              onUpdateDocumentTemplate={this.onUpdateDocumentTemplate} />
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <button 
                type='button' 
                className='btn btn-success pointer mr-2 mt-2' 
                onClick={this.handleSubmit}>
                บันทึก
              </button>
              <button 
                type='button' 
                className='btn btn-success pointer mr-2 mt-2'
                onClick={()=>this.setState({uploading_logo: true, uploading_seal: false})}>
                อัพโหลดโลโก
              </button>
              <button 
                type='button' 
                className='btn btn-success pointer mr-2 mt-2'
                onClick={()=>this.setState({uploading_seal: true, uploading_logo: false})}>
                อัพโหลดตราประทับ
              </button>
              <button 
                type='button' 
                className='btn btn-danger mr-2 mt-2 pointer'
                onClick={this.props.handleShow}>
                ยกเลิก
              </button>
            </div>
          </div>
        </form>

        {!this.state.loading && (
          <UploadLogo
            open={this.state.uploading_logo} 
            logo={this.state.siteSetting.logo}
            reloadData={this.closeAndReload}
            onClose={()=>this.setState({uploading_seal: false, uploading_logo: false})} />
        )}
        {!this.state.loading && (
          <UploadSeal 
            open={this.state.uploading_seal}
            seal={this.state.siteSetting.seal}
            reloadData={this.closeAndReload}
            onClose={()=>this.setState({uploading_seal: false, uploading_logo: false})} />
        )}
      </div>
    )
  }
}
