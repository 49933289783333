import React, { Component } from 'react';
import _ from 'lodash';
import Permission from '../../components/Permission';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';
import AfterSaveLoading from '../../components/AfterSaveLoading';
import SimpleModal from '../../components/SimpleModal';

import DepartmentGroupStore from '../../stores/DepartmentGroupStore';

import Form from '../../components/DepartmentGroup/Form';

import './stylesheets/DepartmentGroupsSettingPage.css';

class DepartmentGroupsSettingPage extends Component {
  constructor(){
    super();

    this.state = {
      isDataFetched: false,
      loading: false,
      departmentGroups: [],
      modalOpen: false,
      modalTitle: "",
      edittingDepartmentGroup: {},
      positions_users: []
    }

    this.reloadData = this.reloadData.bind(this);
    this.rowMemberAmount = this.rowMemberAmount.bind(this);
    this.renderRowActions = this.renderRowActions.bind(this);
    this.newRow = this.newRow.bind(this);
    this.editRow = this.editRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount(){
    if (Permission.canEditDepartmentGroup()) {
      this.reloadData();
    }else{
      window.location.href = '/receive_documents/mydoc';
    }
  }

  reloadData(){
    DepartmentGroupStore.request({}).then((resp)=>{
      let departmentGroups = _.get(resp.data, 'data', []);
      let positions_users = _.get(resp.data, 'included.positions_users', []);

      this.setState({
        departmentGroups: departmentGroups,
        positions_users: positions_users
      })
    }).then(()=>{
      this.setState({isDataFetched: true, loading: false});
    })
  }

  rowMemberAmount(cell, row){
    return (
      <div style={{whiteSpace: 'normal'}}>{row.positions_users.length}</div>
    )
  }

  newRow(){
    this.setState({
      modalOpen: true,
      modalTitle: "เพิ่มกลุ่ม",
    });
  }

  editRow(id){
    const departmentGroup = _.find(this.state.departmentGroups, { id: id})

    this.setState({
      modalOpen: true,
      modalTitle: "แก้ไขกลุ่ม",
      edittingDepartmentGroup: departmentGroup
    });
  }

  deleteRow(id){
    if(window.confirm("Are you sure?")){
      let self = this;

      DepartmentGroupStore.delete(id).then(()=>{
        this.handleCloseAndReloadData()
      }, function(error){
        let error_message = _.get(error.response.data, 'error.message', '');
        alert(error_message);
      });
    }
  }

  onClose(){
    this.setState({
      modalOpen: false,
      modalTitle: "",
      edittingDepartmentGroup: {}
    });
  }

  handleSubmit(departmentGroup){
    let self = this;
    
    const id = departmentGroup.id;
    // departmentGroup = _.omit(departmentGroup, ['id']);

    DepartmentGroupStore.save(id, {
      department_group: departmentGroup
    }).then(()=>{
      this.handleCloseAndReloadData()
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  handleCloseAndReloadData(){
    this.setState(
      {
        loading: true,
        modalOpen: false,
        modalTitle: "",
        edittingDepartmentGroup: {}
      }, 
      this.reloadData()
    );
  }

  renderRowActions(cell, row){
    return (
      <div>
        <button className="btn btn-sm btn-success" onClick={() => this.editRow(cell)}>
          <i className="fa fa-cog"></i>{' '}แก้ไข
        </button>
        {' '}
        <button className="btn btn-sm btn-danger" onClick={() => this.deleteRow(cell)}>
          <i className="fa fa-trash"></i>{' '}ลบ
        </button>
      </div>
    )
  }

  _setTableOption(){ 
      if(this.state.isDataFetched){
            return "Not found";
        }else{
            return <Loading />
        }
  }

  render(){
    const options = {
      sizePerPageList: [],
      noDataText: this._setTableOption(),
    };
    let data = MergeAttributes(this.state.departmentGroups);

    return (
      <div id="department-groups">
        {this.state.loading && <AfterSaveLoading />}

        <div className="row mb-2">
          <div className="col-6">
            <h2>ตั้งค่ากลุ่ม</h2>
          </div>
          <div className="col-6 text-right">
            <button className='btn btn-success' onClick={() => this.newRow()}>
              <span>สร้างกลุ่มใหม่</span>
            </button>
          </div>
        </div>
        
        <div className="row">
          <div className="col-12 rui-department-group-setting">
            <BootstrapTable 
              data={data}
              search={ true }
              multiColumnSearch={ true }
              options={ options }
              striped
              hover
              pagination
              version='4'
              tableContainerClass={'table-sm'}>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='name' dataSort={ true }>ชื่อกลุ่ม</TableHeaderColumn>
              <TableHeaderColumn  dataField='member_names' dataSort={ true }>คนภายในกลุ่ม</TableHeaderColumn>
              <TableHeaderColumn  dataField='member_amount' 
                                  dataSort={ true } 
                                  width='100'
                                  dataFormat={this.rowMemberAmount}>
                                จำนวน(คน)
              </TableHeaderColumn>
              <TableHeaderColumn  dataField='id' 
                                  dataSort={ true } 
                                  width='150'
                                  dataFormat={this.renderRowActions}>
                                
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>

        <SimpleModal 
          isOpen={this.state.modalOpen}
          title={this.state.modalTitle}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onClose}>
            <Form 
              departmentGroup={this.state.edittingDepartmentGroup} 
              positions_users={this.state.positions_users} 
              onClose={this.onClose} 
              onSubmit={this.handleSubmit} />
        </SimpleModal>
      </div>
    );
  }
}

export default DepartmentGroupsSettingPage;
