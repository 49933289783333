import React, {Component} from 'react';
import _ from 'lodash';

import GroupStore from '../../stores/GroupStore';
import RenderMainGroup from './RenderMainGroup';

export default class OrganizationalStructure extends Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      group: {}
    }

    this.reloadData = this.reloadData.bind(this);
  }

  componentWillMount() {
    this.reloadData()
  }

  reloadData(){
    var self = this;
    GroupStore.request({action: 'get_main_group'}).then((resp)=>{
      const group = _.get(resp.data, 'data', {});

      this.setState({
        group: group
      })
    }, function(error){
      self.setState({loading: false});
    }).then(()=>{
      this.setState({loading: false});
    });
  }

  render(){
    return (
      <div className='mt-3'>
        {!_.isEmpty(this.state.group) && (
          <ul className='ul-group-list pl-1 ml-2'>
            <RenderMainGroup 
              focus={this.props.focus}
              group={this.state.group}
              onFocus={this.props.onFocus} />
          </ul>
        )}
      </div>
    )
  }
}