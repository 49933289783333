import React, { Component } from 'react';

class EditNote extends Component {
  constructor(){
    super();

    this.state = {
      note_text: ""
    }

    this.setNoteText = this.setNoteText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setNoteText(event){
    const note_text = event.target.value;
    this.setState({note_text: note_text});
  }

  handleSubmit(){
    this.props.onSubmitAction(this.state.note_text);
  }

  render(){
    return(
      <div>
        <div className="row">
          <div className="col-12">
            <h4>ข้อความ</h4>
            <textarea cols="30" rows="10" className="w-100" value={this.state.note_text} onChange={this.setNoteText}></textarea>
          </div>
        </div>

        <div className="modal-footer row mt-2">
          <button type='button' className="btn btn-primary pointer" disabled={this.props.clicked} onClick={this.handleSubmit}>ยืนยัน</button>
          <button type='button' className="btn btn-secondary pointer" onClick={this.props.onCloseModal}>ยกเลิก</button>
        </div>
      </div>
    )
  }
}

export default EditNote;