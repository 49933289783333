import React, { Component } from 'react';
import _ from 'lodash';

export default class ReferenceDocumentList extends Component {
  constructor(props){
    super(props)

    this.renderRow = this.renderRow.bind(this);
  }

  renderRow(reference_document, index){
    return (
      <li key={index}>
        {!_.isEmpty(reference_document.attributes.reference_text) ? (
          reference_document.attributes.reference_text
        ) : (
          <a href={reference_document.attributes.document_pdf_url} target="_blank">
            {reference_document.attributes.reference_document_text}
          </a>
        )}
      </li>
    )
  }

  render(){
    let hasItems = this.props.reference_documents && this.props.reference_documents.length > 0
    return (
      <ul className='m-0 ul-group-list'>
        {!_.isEmpty(this.props.reference_documents) && _.map(this.props.reference_documents, this.renderRow)}
        {!hasItems && <li>No items</li>}
      </ul>
    )
  }
}
