import React, {Component} from 'react';
import _ from 'lodash';

import ReceiveNumberStore from '../stores/ReceiveNumberStore';

export default class SetNextReceiveNumber extends Component {
  constructor(props){
    super(props);

    this.state = {
      next_receive_number: props.next_receive_number
    }

    this.handleSetNextReceiveNumber = this.handleSetNextReceiveNumber.bind(this);
    this.handleUpdateNextReceiveNumber = this.handleUpdateNextReceiveNumber.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.next_receive_number && this.state.next_receive_number !== nextProps.next_receive_number) {
      this.setState({
        next_receive_number: nextProps.next_receive_number
      });
    }
  }

  handleSetNextReceiveNumber(e){
    const next_receive_number = e.target.value;

    this.setState({
      next_receive_number: next_receive_number
    });
  }

  handleUpdateNextReceiveNumber(){
    const self = this;
    const next_receive_number = this.state.next_receive_number;

    ReceiveNumberStore.request({
      action: 'set_next_receive_number',
      method: 'post',
      data: {
        next_receive_number: next_receive_number
      },
    }).then((resp)=>{
      const next_receive_number = _.get(resp.data, 'data.next_number', "");

      self.setState({ 
        next_receive_number: next_receive_number
      });

      this.props.onUpdatedNextReceiveNumber(next_receive_number);
    });
  }

  render(){
    return (
      <div>
        <div className='row'>
          <div className='col-7'>
            <h2>
              {this.props.label} 
              <span className='next-number mx-2'>{`(${this.state.next_receive_number})`}</span>
              <i id="collapseSetNextReceiveNumberTrigger" className="fa fa-cog pointer" aria-hidden="true" data-toggle="collapse" href="#collapseSetNextReceiveNumber" aria-expanded="false" aria-controls="collapseSetNextReceiveNumber"></i>
            </h2>
          </div>
        </div>

        <div id="collapseSetNextReceiveNumber" className="row collapse card bg-light mb-3">
          <div className="col-12 card-body">
            <h4>ตั้งค่าเลขรับ</h4>

            <form className="form-inline">
              <div className="form-group mb-2">
                <label htmlFor="nextReceiveNumber">เลขรับต่อไป คือ:</label>
                <input 
                  type="text" 
                  className="form-control mx-2" 
                  id="nextReceiveNumber" 
                  value={this.state.next_receive_number}
                  onChange={this.handleSetNextReceiveNumber}
                />
              </div>
              <button type="button" className="btn btn-primary mb-2" onClick={this.handleUpdateNextReceiveNumber}>บันทึก</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}