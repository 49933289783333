import React, { Component } from 'react';
import _ from 'lodash';

import LoginSession from '../../../stores/LoginSession';

class DocumentActions extends Component {
  constructor(props){
    super(props)

    this.renderAction = this.renderAction.bind(this);
  }

  renderAction(action, index){
    const action_name = action.attributes.action_name;
    const action_class_name = action.attributes.action_class_name;
    const hidden_actions = ['แก้ไข', 'แก้ไขใบปะหน้า', 'แก้ไขเอกสารหลัก'];
    return (
      <div className='col-12 mb-2 px-0' key={index}>
        <button 
          type='button' 
          disabled={this.props.clicked}
          className={`btn btn-sm ${action_class_name} btn-block ${_.includes(hidden_actions, action_name) ? 'd-none d-lg-inline' : ''}`}
          onClick={() => this.props.onActionClick(action_name)}>
          {action_name}
        </button>
      </div>
    )
  }

  render(){
    const document = this.props.document;
    const workflow_process = this.props.workflow_process;
    let available_actions = workflow_process.attributes.available_actions;
    const current_state_name = _.get(workflow_process, "attributes.current_workflow_state.attributes.state_name");
    const status = _.get(workflow_process, "attributes.status");

    const is_document_owner = (document.attributes.created_by.id === LoginSession.current.user.id);
  
    const assignees = _.map(workflow_process.attributes.positions_users, (positions_user) => {
                        return positions_user.attributes.user_id
                      });
    const is_assignee = _.indexOf(assignees, LoginSession.current.user.id) !== -1;
    if(!is_assignee){
      _.remove(available_actions, (action) => {
        return action.attributes.action_name !== "ส่งต่อหน่วยงานอื่น";
      })
    }

    if(this.props.preview){
      _.remove(available_actions, (action) => {
        return action.attributes.action_name === "ร่างใบปะหน้า" || action.attributes.action_name === "ส่งตรวจทาน";
      })
    }

    _.remove(available_actions, (action) => {
      return action.attributes.action_name === "ส่งคืนสารบรรณกรม" || action.attributes.action_name === "ส่งคืนเจ้าของหนังสือ";
    })
    
    // เอาปุ่ม "รับหนังสือ (ไม่ออกเลข)" ออก
    _.remove(available_actions, (action) => {
      return action.attributes.action_name === "รับหนังสือ (ไม่ออกเลข)";
    })

    return (
      <div className='row'>
        <div className='col-12 text-white text-center py-2 mb-2' style={{backgroundColor: 'rgb(49, 90, 125)'}}>
          การปฏิบัติ
        </div>

        {/* is_document_owner && current_state_name === "เอกสารส่งออก" &&  status !== "Archived" && (
          <div className='col-12 mb-2 px-0'>
            <button type='button' className={`btn btn-sm btn-success btn-block`} onClick={() => this.props.onActionClick("จัดเก็บเอกสาร")}>
              จัดเก็บเอกสาร
            </button>
          </div>
        ) */}

        {this.props.preview && (
          <div className='col-12 mb-2 px-0'>
            <button type='button' className={`btn btn-sm btn-success btn-block`} onClick={() => this.props.onActionClick("ดำเนินการต่อ")}>
              ดำเนินการต่อ
            </button>
          </div>
        )}

        {!_.isEmpty(available_actions) && _.map(available_actions, this.renderAction)}
        

        {is_assignee && current_state_name !== "เอกสารรอบันทึกรับ" && (
          <div className='col-12 mb-2 px-0'>
            <button type='button' className={`btn btn-sm btn-warning btn-block`} onClick={() => this.props.onActionClick("แก้ไข")}>
              แก้ไขเอกสาร
            </button>
          </div>
        )}

        <div className='col-12 mb-2 px-0'>
          <button type='button' className={`btn btn-sm btn-secondary btn-block`} onClick={() => this.props.onActionClick("โหลดเอกสารอีกครั้ง")}>
            โหลดเอกสารอีกครั้ง
          </button>
        </div>
        
        <div className='col-12 mb-2 px-0'>
          <button type='button' className={`btn btn-sm btn-secondary btn-block`} onClick={() => this.props.onActionClick("กลับ")}>
            กลับ
          </button>
        </div>
      </div>
    )
  }
}

export default DocumentActions;

