import React, { Component } from 'react';
import _ from 'lodash';
import '../themes/dark.css';
import '../themes/light.css';
import '../themes/secret.css';

import LoginSession from '../stores/LoginSession';

import AppLayout from './AppLayout';
import LoginPage from '../pages/LoginPage';
import SiteSettingStore from '../stores/SiteSettingStore';
import Loading from '../components/Loading';

const THEME_STORAGE_KEY = 'current_theme';
const CURRENT_THEME = localStorage.getItem(THEME_STORAGE_KEY) || 'light';

export default class App extends Component {
	constructor(props){
		super(props)

		this.state = {
			loading: true,
			code_name: "",
			is_secret_system: false
		}

		this.renderPage = this.renderPage.bind(this);
	}

	componentDidMount(){
		SiteSettingStore.request({url: 'site_settings/get_code_name'}).then((resp)=>{
      const code_name = _.get(resp.data, 'code_name', "");
      const is_secret_system = _.get(resp.data, 'is_secret_system', "");
      localStorage.setItem(THEME_STORAGE_KEY, is_secret_system == "true" ? "secret" : "light");

      this.setState({
      	code_name: code_name,
      	is_secret_system: is_secret_system == "true"
      });
    }).then(()=>{
    	this.setState({loading: false});
    })
	}

	renderPage(){
		const { code_name, is_secret_system } = this.state;
		return (
			<div>
				{LoginSession.current ? (
					<AppLayout {...this.props} />
				) : (
					<div>
						<LoginPage />
					</div>
				)}
			</div>
		)
	}

	render (){
		return (
			<div id={this.state.is_secret_system ? 'secret' : CURRENT_THEME}>
				{this.state.loading ? (
					<Loading />
				) : (
					this.renderPage()
				)}
			</div>
		)
	}
}
