import React, { Component } from 'react';
import _ from 'lodash';

export default class GroupLDAPForm extends Component {
  constructor(props){
    super(props)

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(){
    let { group } = this.props;
    group.can_view_secret_document = this.refs.can_view_secret_document.checked;
    group.can_access_folder = this.refs.can_access_folder.checked;
    group.can_book_number = this.refs.can_book_number.checked;
    group.can_send_document_to_internal = this.refs.can_send_document_to_internal.checked;
    group.can_send_document_to_external = this.refs.can_send_document_to_external.checked;
    group.can_issue_number = this.refs.can_issue_number.checked;
    group.can_edit_others_document = this.refs.can_edit_others_document.checked;
    group.can_create_internal_document = this.refs.can_create_internal_document.checked;
    group.can_create_external_document = this.refs.can_create_external_document.checked;
    group.can_receive_internal_document = this.refs.can_receive_internal_document.checked;
    group.can_receive_external_document = this.refs.can_receive_external_document.checked;
    group.can_cancel_document = this.refs.can_cancel_document.checked;
    group.can_attach_file = this.refs.can_attach_file.checked;
    group.can_scan_document = this.refs.can_scan_document.checked;
    group.can_close_document = this.refs.can_close_document.checked;
    group.can_undo_sent_document = this.refs.can_undo_sent_document.checked;
    group.can_return_document = this.refs.can_return_document.checked;

    this.props.handleChangeData(group);
  }

  render() {
    const { group } = this.props;
    return (
      <div>

        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_view_secret_document'
              checked={group.can_view_secret_document}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการดูหนังสือที่มีชั้นความลับ (นายทะเบียนหนังสือลับ)
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_access_folder'
              checked={group.can_access_folder}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการเข้าใช้งานแฟ้ม และตัวเอกสารแบบละเอียด
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_book_number'
              checked={group.can_book_number}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการจองเลขที่หนังสือ/กันเลขรับ
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_send_document_to_internal'
              checked={group.can_send_document_to_internal}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการส่งหนังสือภายในหน่วยงาน
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_send_document_to_external'
              checked={group.can_send_document_to_external}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการส่งหนังสือออกภายนอกหน่วยงาน
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_issue_number'
              checked={group.can_issue_number}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการออกเลขที่หนังสือ
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_edit_others_document'
              checked={group.can_edit_others_document}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการแก้ไขหนังสือ สาหรับ User ที่ไม่ใช่ผู้สร้างหนังสือ
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_create_internal_document'
              checked={group.can_create_internal_document}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการสร้างหนังสือภายใน
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_create_external_document'
              checked={group.can_create_external_document}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการสร้างหนังสือส่งออก
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_receive_internal_document'
              checked={group.can_receive_internal_document}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการรับหนังสือภายในหน่วยงาน
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_receive_external_document'
              checked={group.can_receive_external_document}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการรับหนังสือภายนอกหน่วยงาน
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_cancel_document'
              checked={group.can_cancel_document}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการยกเลิกหนังสือ
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_attach_file'
              checked={group.can_attach_file}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการแนบไฟล์
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_scan_document'
              checked={group.can_scan_document}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการ Scan หนังสือ
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_close_document'
              checked={group.can_close_document}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการปิดงาน
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_undo_sent_document'
              checked={group.can_undo_sent_document}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการดึงกลับ
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-1 text-center'>
            <input 
              type='checkbox'
              ref='can_return_document'
              checked={group.can_return_document}
              onChange={this.handleChange} />
          </div>
          <div className='col-11'>
            สิทธิ์ในการส่งคืน
          </div>
        </div>

      </div>
    )
  }
}
