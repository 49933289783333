import React, { Component } from 'react';
import _ from 'lodash';

import PositionsUserTable from '../PositionsUserTable';

class SelectReviewer extends Component {
  constructor(props){
    super(props)

    this.state = {
      current_form: "selectInternalUsers",
      selectedReviewerId: "",
      actor_positions_users: []
    };

    this.handleRowSelect = this.handleRowSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleShowActor = this.handleShowActor.bind(this);
    this.handleCloseActor = this.handleCloseActor.bind(this);
  }

  handleRowSelect(row, isSelected, e) {
    this.setState({selectedReviewerId: row.id});
  }

  handleSubmit() {
    const on_duty = _.includes(this.props.acting_positions_user_ids, this.state.selectedReviewerId);
    
    if(on_duty){
      alert("ผู้ดำเนินการไปราชการ กรุณาเลือกคนทำงานแทน");
      this.handleShowActor();
    } else {
      this.props.onSubmitAction(this.state.selectedReviewerId);
    }
  }

  handleShowActor(){
    const acting =  _.find(this.props.actings, (acting) => {
                      return acting.attributes.positions_user.id === this.state.selectedReviewerId
                    });
    const actor_positions_users = acting.attributes.actor_positions_users;

    this.setState({
      actor_positions_users: actor_positions_users,
      current_form: "selectActor",
    });
  }

  handleCloseActor(){
    this.setState({
      current_form: "selectInternal",
    });
  }

  render(){
    return (
      <div>
        {this.state.current_form !== "selectActor" && (
          <div>
            <ul className="nav nav-tabs" id="SelectReviewer" role="tablist">
              <li className="nav-item">
              <span className={`nav-link pointer ${this.state.current_form === 'selectInternalUsers' && 'active'}`} 
                    href='#'
                    onClick={()=>this.setState({current_form: "selectInternalUsers"})}>
                ตามสายงาน
              </span>
              </li>
              <li className="nav-item">
              <span className={`nav-link pointer ${this.state.current_form === 'selectAllUsers' && 'active'}`} 
              href="#"
              onClick={()=>this.setState({current_form: "selectAllUsers"})}>
                ทั้งหมด
              </span>
              </li>
            </ul>
            <div className="tab-content mt-3" id="SelectReviewer">
              <div className={`tab-pane fade ${this.state.current_form === 'selectInternalUsers' && 'show active'}`}>
                <PositionsUserTable positions_users={this.props.internal_users}
                                    onSelect={this.handleRowSelect}
                                    hideClose={true} />
              </div>
              <div className={`tab-pane fade ${this.state.current_form === 'selectAllUsers' && 'show active'}`}>
                <PositionsUserTable positions_users={this.props.all_users}
                                    onSelect={this.handleRowSelect}
                                    hideClose={true} />
              </div>
            </div>
          </div>
        )}
        {this.state.current_form === "selectActor" && (
          <PositionsUserTable positions_users={this.state.actor_positions_users}
                              onSelect={this.handleRowSelect}
                              hideClose={true} />
        )}
        
        <div className="modal-footer row">
          <button type='button' className="btn btn-primary pointer" disabled={this.props.clicked} onClick={this.handleSubmit}>ตกลง</button>
          <button type='button' className="btn btn-secondary pointer" onClick={this.props.onCloseModal}>ยกเลิก</button>
        </div>
      </div>
    )
  }
}

export default SelectReviewer;
