import React, {Component} from 'react';
import _ from 'lodash';

const EditingFieldName = 'government_service';

export default class InlineEditGovermentService extends Component {
	constructor(props){
		super(props)

		this.state = {
			editing: false,
			government_service: this.props.government_service
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSetValue = this.handleSetValue.bind(this);
	}

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading){
      this.setState({
        government_service: nextProps.government_service
      })
    }
  }

	handleChange(event){
		this.setState({
			government_service: event.target.value
		})
	}

	handleSetValue(){
		console.log('set government service value')
		this.setState({editing: false}, this.props.handleChange(EditingFieldName, this.state.government_service));
	}

	render(){
		const { document_template } = this.props;

		let text_underline_dashed = '';
		if (!_.isEmpty(document_template) && document_template.attributes.has_underline) {
			text_underline_dashed = 'text_underline_dashed';
		}
		return (
			<div className='row'>
				<div className='col-2 pr-0'>
					<span className={this.props.label_class}>
						{this.props.label}
					</span>
				</div>
				<div className={`col-10 ${this.props.content_class} px-0 ml-minus-15`}>
					{this.state.editing ? (
						<div className='row'>
							<div className='col-11'>
								<input 
									type='text' 
									name='government_service'
									value={this.state.government_service}
									className='form-control form-control-sm'
									autoFocus
                	autocomplete='organization'
									onBlur={this.handleSetValue}
									onChange={this.handleChange} />
							</div>
							<div className='col-1'>
								<button 
									type='button' 
									className='btn btn-sm btn-primary'
									onClick={this.handleSetValue}>ตกลง</button>
							</div>
						</div>
					) : (
						<div className='pointer' onClick={()=>this.setState({editing: true})}>
							<p className={text_underline_dashed}>{this.props.government_service}</p>
						</div>
					)}
				</div>
			</div>
		)
	}
}