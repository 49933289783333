export function GroupType() {
	return 'groups'
}

export function PositionType() {
	return 'positions'
}

export function UserType() {
	return 'users'
}