import React, { Component } from 'react';
import _ from 'lodash';

export default class NotificationSettingPage extends Component {
  constructor(props){
    super(props)
  }

  render(){
    return (
      <div>
        <div className='row mb-1'>

          <div className="col-12 my-1">
            <input 
              type='checkbox' 
              name='allow_email_notice' 
              checked={this.props.allow_email_notice} 
              className='mr-2' 
              onChange={this.props.handleChange} /> 
              รับการแจ้งเตือนผ่านทาง Email
          </div>

          <div className="col-12 my-1">
            <input 
              type='checkbox' 
              name='allow_sms_notice' 
              checked={this.props.allow_sms_notice} 
              className='mr-2' 
              onChange={this.props.handleChange} /> 
              รับการแจ้งเตือนผ่านาง SMS
          </div>

          <div className="col-12 my-1">
            <input 
              type='checkbox' 
              name='allow_push_notification' 
              checked={this.props.allow_push_notification} 
              className='mr-2' 
              onChange={this.props.handleChange} /> 
              รับการแจ้งเตือนผ่านทาง Mobile Push notification
          </div>

        </div>
        <div className='row mt-3'>
          <div className='col-12 text-right'>
            <button type='button' className='btn btn-primary pointer mr-2' onClick={this.props.handleSave}>
              บันทึกการเปลี่ยนแปลง
            </button>
            <button type='button' className='btn btn-dark pointer' onClick={this.props.handleClear}>
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    );
  }
}