import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import SimpleModal from '../../SimpleModal';
import { MergeAttributes } from '../../FlatData';

import EditAction from '../../EditAction';
import DeleteAction from '../../DeleteAction';

import UserPropertyStore from '../../../stores/UserPropertyStore';

const UserPropertyAttributes = {
	key: '',
	description: ''
}

export default class UserProperty extends Component {
	constructor(props){
		super(props)

		this.state = {
			open: false,
			user_properties: [],
			editing_id: '',
			user_property: {}
		}

		this.actionsFormatter = this.actionsFormatter.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.fetchData = this.fetchData.bind(this);
	}

	componentWillMount() {
		this.fetchData();
	}

	fetchData(){
		UserPropertyStore.request({}).then((resp)=>{
			var data = _.get(resp.data, 'data', []);
			this.setState({user_properties: data})
		})
	}

	handleAdd(){
		this.setState({
			open: true,
			editing_id: '',
			user_property: UserPropertyAttributes
		});
	}

	handleEdit(id){
		UserPropertyStore.request({id: id}).then((resp)=>{
			var data = _.get(resp.data, 'data', []);
			this.setState({
				open: true,
				editing_id: data.id,
				user_property: {
					key: data.attributes.key,
					description: data.attributes.description
				}
			})
		})
	}

	handleDelete(id){
		if (window.confirm(`Would you like to delete this property?`)) {
			UserPropertyStore.request({id: id, method: 'delete'}).then(()=>{
				this.fetchData();
			})
		}
	}

	handleClose(){
		this.setState({
			open: false,
			editing_id: '',
			user_property: UserPropertyAttributes
		});
	}

	actionsFormatter(cell, row) {
	  	return (
	  		<div>
	  			<EditAction id={ cell } onEdit={this.handleEdit.bind(this, cell)}/>&ensp;
	  			<DeleteAction id={ cell } onDelete={this.handleDelete.bind(this, cell)}/>
	  		</div>
	  	)
	}

	handleChange(){
		this.setState({
			user_property: {
				key: this.refs.key.value,
				description: this.refs.description.value
			}
		})
	}

	handleSave(event){
		event.preventDefault();

		UserPropertyStore.save(this.state.editing_id, {
			user_property: {
				key: this.state.user_property.key,
				description: this.state.user_property.description
			}
		}).then(()=>{
			this.setState({open: false}, this.fetchData());
		})
	}

	render(){
		var data = MergeAttributes(this.state.user_properties);
		const options = {
	      	sizePerPageList: []
	    };
		return (
			<div>
				<div className='row form-group'>
					<div className='col-6'>
						<h4>User Property</h4>
					</div>
					<div className='col-6 text-right'>
						<button 
							type='button' 
							className='btn btn-success'
							onClick={this.handleAdd}>+ Add</button>
					</div>
				</div>

				<div className='row'>
					<div className='col-12'>
						<BootstrapTable 
								data={data}
								options={options}
								hover
								version='4'
								tableContainerClass={'table-sm'}>
								<TableHeaderColumn isKey dataField='key'>Key</TableHeaderColumn>
								<TableHeaderColumn dataField='description'>Description</TableHeaderColumn>
								<TableHeaderColumn width='250px' dataField='id' dataFormat={ this.actionsFormatter } columnClassName='text-center'></TableHeaderColumn>
							</BootstrapTable>
					</div>
				</div>

				<SimpleModal 
					isOpen={this.state.open}
					title={'Create Property'}
					showSubmitButton={false}
					onClose={this.handleClose}>
					<form onSubmit={this.handleSave}>
						<div className='row form-group'>
							<div className='col-3 text-right'>
								<label className='required'>Key:</label>
							</div>
							<div className='col-9'>
								<input 
									type='text' 
									ref='key'
									value={this.state.user_property.key || ''}
									className='form-control'
									onChange={this.handleChange} />
							</div>
						</div>
						<div className='row form-group'>
							<div className='col-3 text-right'>
								<label className='required'>Description:</label>
							</div>
							<div className='col-9'>
								<textarea 
									ref='description'
									value={this.state.user_property.description || ''}
									className='form-control'
									onChange={this.handleChange} />
							</div>
						</div>
						<div className="modal-footer row">
							<button type='submit' className="btn btn-primary pointer">Save changes</button>
							<button type='button' className="btn btn-secondary pointer" onClick={this.handleClose}>Close</button>
						</div>
					</form>
				</SimpleModal>
			</div>
		)
	}
}