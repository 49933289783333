import _ from 'lodash';

export function MergeAttributes(objects) {
	var newObjects = _.map(objects, (object)=>{
    if (_.isEmpty(object.attributes)){
      return object;
    }else{
      return _.merge(object, object.attributes);
    }
	});
	return newObjects
}