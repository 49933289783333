import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Pathname from './Pathname';
import Permission from './Permission';

import myDoc from '../assets/images/mydoc.png';
import operated from '../assets/images/operated.png';
import page_forward from '../assets/images/page_forward.png';
import page_back from '../assets/images/page_back.png';
import file_text from '../assets/images/file_text.png';
import page_find from '../assets/images/page_find.png';
import page_cancel from '../assets/images/page_cancel.png';

export default class DashboardSidebar extends Component {
  constructor(props) {
    super(props)
  }

  render(){
    const prefixPathname = Pathname.prefixPathname(this.props.location);
    const fullPathname = Pathname.fullPathname(this.props.location);

    return(
      <div className="documents-sidebar pl-4 pl-lg-0 sub-sidebar-menu">
        <ul className="nav flex-column nav-pills py-4 ml-0" id="v-pills-tab" role="tablist">
          <li className="nav-item">
            <Link to='/' className={`pl-3 nav-link ${(fullPathname === '/') && 'active'}`}>
              <img alt="Search" src={page_forward} className="mr-2" />
              หน้าแรก
            </Link>
          </li>
          {(Permission.isDepartmentCorrespondence() || Permission.isDepartmentSecretary()) && (
            <li className="nav-item">
              <Link to='/department_numbers/department_numbers' className={`pl-3 nav-link ${(prefixPathname === 'department_numbers') && 'active'}`}>
                <img alt="Search" src={page_forward} className="mr-2" />
                ออกเลขกรม
              </Link>
            </li>
          )}
          {(Permission.isUnitCorrespondence() || Permission.isDepartmentSecretary()) && (
            <li className="nav-item">
              <Link to='/unit_numbers/unit_numbers' className={`pl-3 nav-link ${(prefixPathname === 'unit_numbers') && 'active'}`}>
                <img alt="Search" src={page_forward} className="mr-2" />
                ออกเลขหน่วยงาน
              </Link>
            </li>
          )}
          <li className="nav-item">
            <Link to='/documents/search_receive_documents' className={`pl-3 nav-link ${(fullPathname === '/documents/search_receive_documents') && 'active'}`}>
              <img alt="Search" src={page_find} className="mr-2" />
              ค้นหาทะเบียนหนังสือรับ
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/documents/search_send_documents' className={`pl-3 nav-link ${(fullPathname === '/documents/search_send_documents') && 'active'}`}>
              <img alt="Search" src={page_find} className="mr-2" />
              ค้นหาทะเบียนหนังสือส่ง
            </Link>
          </li>
        </ul>
      </div>
    )
  }
}