import React, {Component} from 'react';
import _ from 'lodash';

import { speedLevelList } from '../../../utils/documentType';

const EditSpeedLevel = 'speed_level';
const EditSecretLevel = 'secret_level';
const EditSubject = 'subject';

export class SpeedLevel extends Component {
  constructor(props){
    super(props)

    this.onChange = this.onChange.bind(this);
  }

  onChange(event){
    let value = event.target.value;
    this.props.handleChange(EditSpeedLevel, value);
  }

	render(){
		return (
			<div className='row'>
				<div className='col-12'>
					ชั้นความเร็ว
				</div>
				<div className='col-12'>
					<select 
						name='speed_level' 
            className='form-control form-control-sm'
            value={this.props.speed_level}
            onChange={this.onChange}>
          	{_.map(speedLevelList, (speed) => {
            		return <option value={speed} key={speed}>{speed}</option>
          	})}
        	</select>
				</div>
			</div>
		)
	}
}

export class SecretLevel extends Component {
  constructor(props){
    super(props)

    this.onChange = this.onChange.bind(this);
  }

  onChange(event){
    let value = event.target.value;
    this.props.handleChange(EditSecretLevel, value);
  }

	render(){
		const secretList = ["ปกติ", "ลับ", "ลับมาก", "ลับที่สุด"]
		return (
			<div className='row'>
				<div className='col-12'>
					ชั้นความลับ
				</div>
				<div className='col-12'>
					<select 
						name='secret_level' 
            className='form-control form-control-sm'
            value={this.props.secret_level}
            onChange={this.onChange}>
          	{_.map(secretList, (secret) => {
            		return <option value={secret} key={secret}>{secret}</option>
          	})}
        	</select>
				</div>
			</div>
		)
	}
}

export class Subject extends Component {
  constructor(props){
    super(props)

    this.onChange = this.onChange.bind(this);
  }

  onChange(event){
    let value = event.target.value;
    this.props.handleChange(EditSubject, value);
  }

	render(){
		return (
			<div>
				<div>เรื่อง</div>
				<input 
					type='text' 
					name='subject'
          value={this.props.subject} 
					className='form-control form-control-sm'
					onChange={this.onChange} />
			</div>
		)
	}
}







