import React, {Component} from 'react';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';

import QuillShiftEnter from '../../QuillShiftEnter';

export default class InlineEditTextAttr extends Component {
	constructor(props){
		super(props)

		this.state = {
			editing: false,
			editing_field_name: this.props.field_name,
			value: this.props.value
		}

		this.handleEditAttribute = this.handleEditAttribute.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSetValue = this.handleSetValue.bind(this);
	}

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading){
      this.setState({
        editing_field_name: nextProps.field_name,
				value: nextProps.value
      })
    }
  }

  handleChange(value) {
    this.setState({ value: value })
  }

	handleEditAttribute(){
		this.setState({editing: true})
	}

	handleSetValue(){
		var value = this.state.value;
		this.setState({editing: false}, this.props.handleChange(this.state.editing_field_name, value));
	}

  	stripHTML(text){
  		if (_.isEmpty(text)) { return }

  		var regex = /(<([^>]+)>)/ig;
   		return text.replace(regex, "");
  	}

	render(){
		return (
			<div className='row'>
				<div className='col-12'>
					<div className={`${this.props.label_class} text_underline`}>
						{this.props.label}
					</div>
					<div className={`row ${this.props.content_class}`}>
						{this.state.editing ? (
							<div className='col-10 ml-auto' id="editing_text_attribute_id">
								<div className='mr-2 mb-2'>
									<ReactQuill 
                    value={this.state.value}
                    onChange={this.handleChange}
                    modules={{toolbar: false}} />

								</div>
								<div>
									<button
                    id="submit_text_attribute" 
										type='button' 
										className='btn btn-sm btn-primary'
										onClick={this.handleSetValue}>ตกลง</button>
								</div>
							</div>

						) : (
							<div className='col-10 ml-auto' id="show_text_attribute_id">
								{this.props.value ? (
									<div className='pointer editing_content' style={{display: 'inline-block'}} onClick={this.handleEditAttribute}>
										{renderHTML(this.props.value)}
									</div>
								) : (
									<div className='text-danger pointer' style={{display: 'inline-block'}} onClick={this.handleEditAttribute}>
										<em>{this.props.suggest_text}</em>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}
}



document.addEventListener('click', function(event){ 
  var editing_text_attribute_id = document.getElementById('editing_text_attribute_id');
  var show_text_attribute_id = document.getElementById('show_text_attribute_id');

  if (show_text_attribute_id){
    if (show_text_attribute_id.contains(event.target)){
      return
    }
  }

  if (editing_text_attribute_id){
    if (editing_text_attribute_id.contains(event.target)){
      // Clicked in box
      console.log('Clicked in box')
    } else{
      // Clicked outside the box
      console.log('Clicked outside the box')
      document.getElementById('submit_text_attribute').click();
    }
  }
});