import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import WorkflowProcessStore from '../stores/WorkflowProcessStore';
import UserImage from '../assets/images/userimage.png';

class WorkflowProcessHistory extends Component {
  constructor(){
    super();

    this.state = {
      workflow_process: {},
      workflow_process_histories: [],
      workflow_states: [],
      workflow_state_actions: [],
      workflow_process_histories_count: 0,
      loading: true
    }

    this.renderHistoryRow = this.renderHistoryRow.bind(this);
    this.renderCurrentRow = this.renderCurrentRow.bind(this);
    this.renderTargets = this.renderTargets.bind(this);
    this.renderDepartmentIssueNumber = this.renderDepartmentIssueNumber.bind(this);
    this.renderUnitIssueNumber = this.renderUnitIssueNumber.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const nextId = nextProps.id;
    const currentId = this.props.id;

    if (nextId && nextId !== currentId) {
      this.setState({
        workflow_process: {},
        workflow_process_histories: [],
        workflow_states: [],
        workflow_state_actions: [],
        workflow_process_histories_count: 0,
        loading: true
      });
      
      WorkflowProcessStore.request({id: nextProps.id}).then((resp)=>{
        let workflow_process = _.get(resp.data, 'data', {});
        let included = _.get(resp.data, 'included', {});
        let workflow_process_histories = included.workflow_process_histories || [];
        let workflow_states = included.workflow_states || [];
        let workflow_state_actions = included.workflow_state_actions || [];

        this.setState({
          workflow_process: workflow_process,
          workflow_process_histories: workflow_process_histories,
          workflow_states: workflow_states,
          workflow_state_actions: workflow_state_actions,
          workflow_process_histories_count: workflow_process_histories.length,
        })
      }).then(()=>{
        this.setState({loading: false});
      })
    }
  }

  renderHistoryRow(workflow_process_history, rowIndex){
    const self = this;

    const workflow_process = this.state.workflow_process;
    const workflow_state = _.find(this.state.workflow_states, { id: workflow_process_history.attributes.workflow_state.id } );
    const workflow_state_action = _.find(this.state.workflow_state_actions, { id: workflow_process_history.attributes.workflow_state_action.id } );
    const executor = _.get(workflow_process_history, "attributes.executor");
    const cc_positions_users = _.get(workflow_process_history, "attributes.cc_positions_users");
    let department_name = executor && executor.attributes ? executor.attributes.department_name : ""

    let action_name = workflow_state_action.attributes.action_name;

    if(workflow_process_history.attributes.order_type === "issue_department_number"){
      action_name = "ส่งต่อเพื่อออกเลขกรม"
    } else if (workflow_process_history.attributes.order_type === "issue_unit_number"){
      action_name = "ส่งต่อเพื่อออกเลขหน่วยงาน"
    } else if (workflow_process_history.attributes.order_type === "return_to_document_owner"){
      action_name = "ส่งคืนเจ้าของเรื่อง"
    }

    const department_issue_numbers = _.get(workflow_process, 'attributes.department_issue_numbers');
    const unit_issue_numbers = _.get(workflow_process, 'attributes.unit_issue_numbers');

    return(
      <li key={workflow_process_history.id} className='py-3 border-bottom'>
        <div className="row">
          <div className="col-1">
            {rowIndex + 1}
          </div>
          <div className="col-4">
            <div>{department_name}</div>
            <div><b>ส่ง: </b><span>{workflow_process_history.attributes.created_at}</span></div>
            <div><b>สถานะเอกสาร: </b><span>{workflow_state.attributes.state_name}</span></div>
          </div>
          <div className="col-4">
            <div>{action_name}</div>
            <div>{_.get(workflow_process_history, "attributes.updated_at")}</div>

            {!_.isEmpty(workflow_process_history.attributes.receive_number_text) && (
              <div>
                <div><b>เลขรับ: </b>{workflow_process_history.attributes.receive_number_text}</div>
              </div>
            )}

            {
              (
                workflow_state_action.attributes.action_name === "ออกเลขส่งใบปะหน้า" || 
                workflow_state_action.attributes.action_name === "แทรกเลขส่งหใบปะหน้า"
              ) && (
                <div><b>เลขส่ง: </b>{workflow_process.attributes.front_page_issue_number}</div>
              )
            }

            {
              (
                workflow_state_action.attributes.action_name === "ออกเลขส่งหนังสือ" || 
                workflow_state_action.attributes.action_name === "แทรกเลขส่งหนังสือ" ||
                workflow_state_action.attributes.action_name === "ออกเลขส่ง"
              ) && (
                <div><b>เลขส่ง: </b>{workflow_process.attributes.issue_number || workflow_process.attributes.prev_issue_number}</div>
              )
            }

            {
              (
                workflow_state_action.attributes.action_name === "ออกเลขรับใบปะหน้า" || 
                workflow_state_action.attributes.action_name === "แทรกเลขรับใบปะหน้า"
              ) && (
                <div><b>เลขรับ: </b>{workflow_process.attributes.front_page_receive_number}</div>
              )
            }

            {
              (
                workflow_state_action.attributes.action_name === "ออกเลขรับหนังสือ" || 
                workflow_state_action.attributes.action_name === "แทรกเลขรับหนังสือ"
              ) && (
                <div><b>เลขรับ: </b>{workflow_process.attributes.receive_number}</div>
              )
            }

            { 
              (
                workflow_process_history.attributes.order_type === "issue_department_number" ||
                workflow_process_history.attributes.order_type === "issue_unit_number"
              ) && (
                <div
                  className='text-danger pointer' 
                  onClick={() => this.unsendForIssueNumber(workflow_process_history.attributes.workflow_process.id)}>
                    ยกเลิก
                </div>
              )
            }

            { workflow_state_action.attributes.action_name === "ออกเลขกรม" && (
              <div>
                <b>เลขกรม: </b>
                {_.map(department_issue_numbers, this.renderDepartmentIssueNumber)}
              </div>
            )}

            { workflow_state_action.attributes.action_name === "ออกเลขหน่วยงาน" && (
              <div>
                <b>เลขหน่วยงาน: </b>
                {_.map(unit_issue_numbers, this.renderUnitIssueNumber)}
              </div>
            )}

            {
              !_.isEmpty(cc_positions_users) && (
                <div>
                  <b>หน่วยงานปลายทาง: </b>
                  <ul className="ul-target-list">
                    {_.map(cc_positions_users, function(cc_positions_user, index){
                      return self.renderTargets(cc_positions_user, workflow_process_history.attributes.workflow_process.id);
                    })}

                  </ul>
                </div>
              )
            }
          </div>
          <div className="col-3">
            {workflow_process_history.attributes.note_text && (
              <div className="p-2" style={{whiteSpace: "pre-wrap"}}>
                {workflow_process_history.attributes.note_text}
              </div>
            )}
            {workflow_process_history.attributes.comment && (
              <div className="p-2" style={{whiteSpace: "pre-wrap"}}>
                {workflow_process_history.attributes.comment}
              </div>
            )}
          </div>
        </div>
      </li>
    )
  }

  renderDepartmentIssueNumber(department_number){
    return(
      <div key={department_number.id}>
        {department_number.attributes.number}
      </div>
    )
  }

  renderUnitIssueNumber(unit_issue_number){
    return(
      <div key={unit_issue_number.id}>
        {unit_issue_number.attributes.number}
      </div>
    )
  }

  renderTargets(cc_positions_user, workflow_process_id){
    const sent_success_user_ids = this.state.workflow_process.attributes.sent_success_user_ids;
    const cancelled_user_ids = this.state.workflow_process.attributes.cancelled_user_ids;;

    const positions_user = cc_positions_user.attributes.positions_user;
    const received_number = cc_positions_user.attributes.received_number;
    const received_at = cc_positions_user.attributes.received_at;

    if(!_.isEmpty(received_number)){
      return(
        <li key={cc_positions_user.id}>
          {positions_user.attributes.position_name}

          <div className='text-success ml-1'>
            {`เลขรับ: ${received_number} เมื่อ: ${received_at}`}
          </div>
        </li>
      )
    } else if (cc_positions_user.attributes.failed){
      return(
        <li key={cc_positions_user.id}>
          {positions_user.attributes.position_name}

          <div
            className='text-danger pointer' 
            onClick={() => this.unsendWorkflowProcess(workflow_process_id, positions_user.id)}>
            คลิกเพื่อยกเลิก
          </div>
        </li>
      )
    } else if (!_.includes(sent_success_user_ids, positions_user.attributes.user_id)){
      return(
        <li key={cc_positions_user.id}>
          {positions_user.attributes.position_name}

          <div>
            <span className='orange-text'>นำส่งเอกสาร</span>
           </div>
        </li>
      )
    } else if (this.props.cancelable){
      return(
        <li key={cc_positions_user.id}>
          {positions_user.attributes.position_name}

          <div>
            <span className='purple-text'>ส่งเอกสารสำเร็จ</span>
            <a
              className='text-danger ml-1 pointer' 
              onClick={() => this.unsendWorkflowProcess(workflow_process_id, positions_user.id)}>
              คลิกเพื่อยกเลิก
            </a>
           </div>
        </li>
      )
    } else if (_.includes(cancelled_user_ids, positions_user.attributes.user_id)){
      return(
        <li key={cc_positions_user.id}>
          {positions_user.attributes.position_name}

          <div>
            <span className='purple-text'>เอกสารถูกยกเลิก</span>
          </div>
        </li>
      )
    } 
  }

  renderCurrentRow(workflow_process){
    const { workflow_process_histories_count } = this.state;
    const assignees = _.get(workflow_process, "attributes.positions_users");
    const assignee = _.first(assignees);
    const updated_at = _.get(workflow_process, "attributes.updated_at");
    
    let current_state_name = _.get(workflow_process, "attributes.current_workflow_state.attributes.state_name");

    if(current_state_name === "จบการทำงาน"){
      return(
        <li key={workflow_process.id}>
          <h2 className="text-danger text-center">จบการทำงาน</h2>
        </li>
      )
    }

    if(current_state_name === "เอกสารส่งออก"){
      return;
    }

    return(
      <li key={workflow_process.id} className='py-3'>
        <div className="row">
          <div className="col-1">
            {workflow_process_histories_count + 1}
          </div>
          <div className="col-4">
            <div>{_.get(assignee, "attributes.department_name")}</div>
            <div><b>ส่ง: </b><span>{updated_at}</span></div>
            <div><b>สถานะเอกสาร: </b><span>{current_state_name}</span></div>
          </div>
          <div className="col-3"></div>
          <div className="col-4"></div>
        </div>
      </li>
    )
  }

  unsendWorkflowProcess(workflow_process_id, positions_user_id){
    if (!window.confirm('ยืนยันการยกเลิกส่ง')) { return }

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'delete',
      action: 'unsend',
      data: {
        positions_user_id: positions_user_id
      }
    }).then((response)=>{
      console.log("response", response);

      const workflow_process = response.data.data;
      const workflow_process_histories = response.data.included.workflow_process_histories;

      this.setState({
        workflow_process: workflow_process,
        workflow_process_histories: workflow_process_histories,
      });
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    });
  }

  unsendForIssueNumber(workflow_process_id){
    if (!window.confirm('ยืนยันการยกเลิกส่ง')) { return }

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'delete',
      action: 'unsend_for_issue_number'
    }).then(()=>{
      window.location.href = "/send_documents/send_all";
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    });
  }

  render(){
    const { workflow_process, workflow_process_histories } = this.state;

    return(
      <div className="row">
        <div className="col-12">
          <ul className="mx-0 px-0 ul-group-list">
            <li className='pb-3 border-bottom'>
              <div className="row">
                <div className="col-1"><strong>#</strong></div>
                <div className="col-4"><strong>หน่วยงาน</strong></div>
                <div className="col-4"><strong>วันที่รับ</strong></div>
                <div className="col-3"><strong>คำสั่งการ/การปฏิบัติ</strong></div>
              </div>
            </li>

            {_.map(workflow_process_histories, this.renderHistoryRow)}

            {this.renderCurrentRow(workflow_process)}
          </ul>
        </div>
      </div>
    )
  }
}

export default withRouter(WorkflowProcessHistory);
