import React, {Component} from 'react';
import _ from 'lodash';

import { IssueNumber } from './elements/Elements';

import { RenderInformeeTo} from './elements/Informees';
import { RenderInformTo } from './elements/Informs';

import References from './elements/References';
import InlineEditGovermentService from './elements/InlineEditGovermentService';
import NumberAndAddress from './elements/NumberAndAddress';
import InlineEditAttribute from './elements/InlineEditAttribute';
import InlineEditTopic from './elements/InlineEditTopic';
import InlineEditAttached from './elements/InlineEditAttached';
import InlineEditTextAttr from './elements/InlineEditTextAttr';


export class InfosSection extends Component {
  render(){
    const { document_template, document_attributes, document, reference_documents, loading } = this.props;

    return (
      <div>
        {!_.isEmpty(document_template.attributes.template_attributes.government_service) && (
        <InlineEditGovermentService 
          label={document_attributes.government_service.title}
          label_class={'text-md font-weight-bold'}
          content_class={'text-md'}
          government_service={document_attributes.government_service.value}
          document_template={document_template}
          handleChange={this.props.handleChangeAttributeValue}
          loading={loading} />
        )}

        <NumberAndAddress 
          loading={loading}
          document_template={document_template}
          document_attributes={document_attributes}
          handleChangeAttributeValue={this.props.handleChangeAttributeValue} />

        {!_.isEmpty(document_template.attributes.template_attributes.heading) && (
          <div className={`${['minutes', 'news'].includes(document_template.attributes.const_value) ? 'text-center' : 'mt-5 text-center'}`}>
            <InlineEditAttribute
              label={document_attributes.heading.title}
              suggest_text={document_attributes.heading.suggest_text} 
              field_name={document_attributes.heading.name}
              value={document_attributes.heading.value}
              label_class={'text-md'}
              content_class={'text-md'}
              has_space_after_label={false}
              has_parenthesis={false}
              handleChange={this.props.handleChangeAttributeValue}
              loading={loading} />
          </div>
        )}

        {(!_.isEmpty(document_template.attributes.template_attributes.issue_number) && !_.isEmpty(document_template.attributes.template_attributes.heading)) && (
          <div className='text-center'>
            <IssueNumber
              label={document_attributes.issue_number.title} 
              label_class={'text-md'}
              suggest_text={document_attributes.issue_number.suggest_text}
              issue_number={document_attributes.issue_number.value}
              document_template={document_template} />
          </div>
        )}

        {!_.isEmpty(document_template.attributes.template_attributes.topic) && (
          <InlineEditTopic
            label={document_attributes.topic.title}
            suggest_text={document_attributes.topic.suggest_text} 
            topic={document_attributes.topic.value}
            document_template={document_template}
            text_align={!_.isEmpty(document_template.attributes.template_attributes.heading) ? 'center' : 'left'}
            handleChange={this.props.handleChangeAttributeValue}
            loading={loading} />
        )}

        {!_.isEmpty(document_template.attributes.template_attributes.with) && (
          <InlineEditAttribute
            label={document_attributes.with.title}
            suggest_text={document_attributes.with.suggest_text} 
            field_name={document_attributes.with.name}
            value={document_attributes.with.value}
            label_class={'text-md'}
            content_class={'text-md'}
            has_space_after_label={false}
            has_parenthesis={false}
            handleChange={this.props.handleChangeAttributeValue}
            loading={loading} />
        )}

        {!_.isEmpty(document_template.attributes.template_attributes.no) && (
          <InlineEditAttribute 
            label={document_attributes.no.title}
            suggest_text={document_attributes.no.suggest_text} 
            field_name={document_attributes.no.name}
            value={document_attributes.no.value}
            label_class={'text-md'}
            content_class={'text-md'}
            has_space_after_label={true}
            handleChange={this.props.handleChangeAttributeValue}
            loading={loading} />
        )}

        {!_.isEmpty(document_template.attributes.template_attributes.number) && (
          <InlineEditAttribute 
            label={document_attributes.number.title}
            suggest_text={document_attributes.number.suggest_text} 
            field_name={document_attributes.number.name}
            value={document_attributes.number.value}
            label_class={'text-md'}
            content_class={'text-md'}
            has_space_after_label={true}
            handleChange={this.props.handleChangeAttributeValue}
            loading={loading} />
        )}

        {!_.isEmpty(document_template.attributes.template_attributes.year) && (
          <InlineEditAttribute 
            label={document_attributes.year.title}
            suggest_text={document_attributes.year.suggest_text} 
            field_name={document_attributes.year.name}
            value={document_attributes.year.value}
            label_class={'text-md'}
            content_class={'text-md'}
            has_space_after_label={true}
            has_parenthesis={false}
            handleChange={this.props.handleChangeAttributeValue}
            loading={loading} />
          )}

        {/* Minutes Document */}
        {!_.isEmpty(document_template.attributes.template_attributes.time) && (
          <InlineEditAttribute
            label={document_attributes.time.title}
            suggest_text={document_attributes.time.suggest_text} 
            field_name={document_attributes.time.name}
            value={document_attributes.time.value}
            label_class={'text-md'}
            content_class={'text-md'}
            has_space_after_label={true}
            has_parenthesis={false}
            handleChange={this.props.handleChangeAttributeValue}
            loading={loading} />
        )}

        {!_.isEmpty(document_template.attributes.template_attributes.date) && (
          <InlineEditAttribute
            label={document_attributes.date.title}
            suggest_text={document_attributes.date.suggest_text} 
            field_name={document_attributes.date.name}
            value={document_attributes.date.value}
            label_class={'text-md'}
            content_class={'text-md'}
            has_space_after_label={true}
            has_parenthesis={false}
            handleChange={this.props.handleChangeAttributeValue}
            loading={loading} />
        )}

        {!_.isEmpty(document_template.attributes.template_attributes.place) && (
          <InlineEditAttribute
            label={document_attributes.place.title}
            suggest_text={document_attributes.place.suggest_text} 
            field_name={document_attributes.place.name}
            value={document_attributes.place.value}
            label_class={'text-md'}
            content_class={'text-md'}
            has_space_after_label={true}
            has_parenthesis={false}
            handleChange={this.props.handleChangeAttributeValue}
            loading={loading} />
        )}
        {/* End Minutes Document */}

        {!_.isEmpty(document_template.attributes.template_attributes.heading) && (
          <div className='row my-2 text-center'>
            <div className='col-6 ml-auto mr-auto'>
              <hr style={{borderTop: '1px solid rgb(0,0,0)'}} />
            </div>
          </div>
        )}

        {/* Minutes Document */}
        {!_.isEmpty(document_template.attributes.template_attributes.people_come) && (
          <div className='mt-4 mb-1'>
            <InlineEditTextAttr
              label={document_attributes.people_come.title}
              suggest_text={document_attributes.people_come.suggest_text} 
              field_name={document_attributes.people_come.name}
              value={document_attributes.people_come.value}
              label_class={'text-md'}
              content_class={'text-md'}
              has_space_after_label={true}
              has_parenthesis={false}
              handleChange={this.props.handleChangeAttributeValue}
              loading={loading} />
          </div>
        )}

        {!_.isEmpty(document_template.attributes.template_attributes.people_do_not_come) && (
          <div className='mb-1'>
            <InlineEditTextAttr
              label={document_attributes.people_do_not_come.title}
              suggest_text={document_attributes.people_do_not_come.suggest_text} 
              field_name={document_attributes.people_do_not_come.name}
              value={document_attributes.people_do_not_come.value}
              label_class={'text-md'}
              content_class={'text-md'}
              has_space_after_label={true}
              has_parenthesis={false}
              handleChange={this.props.handleChangeAttributeValue}
              loading={loading} />
          </div>
        )}

        {!_.isEmpty(document_template.attributes.template_attributes.attendees) && (
          <div className='mb-1'>
            <InlineEditTextAttr
              label={document_attributes.attendees.title}
              suggest_text={document_attributes.attendees.suggest_text} 
              field_name={document_attributes.attendees.name}
              value={document_attributes.attendees.value}
              label_class={'text-md'}
              content_class={'text-md'}
              has_space_after_label={true}
              has_parenthesis={false}
              handleChange={this.props.handleChangeAttributeValue}
              loading={loading} />
          </div>
        )}

        {!_.isEmpty(document_template.attributes.template_attributes.start_meeting_date) && (
          <div>
            <InlineEditAttribute
              label={document_attributes.start_meeting_date.title}
              suggest_text={document_attributes.start_meeting_date.suggest_text} 
              field_name={document_attributes.start_meeting_date.name}
              value={document_attributes.start_meeting_date.value}
              text_align_left={true}
              label_class={'text-md'}
              content_class={'text-md'}
              has_space_after_label={true}
              has_parenthesis={false}
              handleChange={this.props.handleChangeAttributeValue}
              loading={loading} />
          </div>
        )}
        {/* End Minutes Document */}

        {document_template.attributes.has_inform_to_department_group && (
          <RenderInformTo 
            label={document_attributes.opening.value} 
            values={document.informs}
            show_one_line_informee={document_attributes.show_one_line_informee.value} />
        )} 

        {document_template.attributes.has_inform_to_informee && (
          <RenderInformeeTo 
            label={document_attributes.opening.value} 
            values={document.informs}
            show_one_line_informee={document_attributes.show_one_line_informee.value} />
        )}

        {(!_.isEmpty(document_template.attributes.template_attributes.references) && document_attributes.hidden_ref.value.toString() === "false") && (
          <References 
            document_attributes={document_attributes}
            references={document.references}
            reference_documents={reference_documents} />
        )}

        {(!_.isEmpty(document_template.attributes.template_attributes.attachment) && document_attributes.hidden_file.value.toString() === "false") && (
          <InlineEditAttached
            label={document_attributes.attachment.title}
            suggest_text={document_attributes.attachment.suggest_text}
            attachment={document_attributes.attachment.value}
            handleChange={this.props.handleChangeAttributeValue}
            loading={loading} />
        )}
      </div>
    )
  }
}

export default InfosSection;
