import React, { Component } from 'react';
import _ from 'lodash';

import FrontDocument from '../../components/documents/FrontDocument';

import DocumentTemplateStore from '../../stores/DocumentTemplateStore';

class FrontPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      document_template: {}
    }
  }

  componentDidMount(){
    DocumentTemplateStore.request({id: 'front_page'}).then((resp)=>{
      let document_template = _.get(resp.data, 'data', {});

      this.setState({
        document_template: document_template
      });
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  render(){
    return (
      <div>
        {(!_.isEmpty(this.state.document_template)) && (
          <FrontDocument 
            document_template={this.state.document_template}
            {...this.props.params} />
        )}
      </div>
    )
  }
}

export default FrontPage;
