import React from 'react';
import _ from 'lodash';

export const DocumentOwner = (props) => {
  return (
    <div>
      <u className='mr-2'>เจ้าของเรื่อง</u>
      {_.isEmpty(props.row.owner) ? (
        '-'
      ) : (
        <span>{props.row.owner}</span>
      )}
    </div>
  )
}

export const SpeedLevelClassName = (level) => {
  let speed_level_class_name = 'normal';
  switch(level) {
    case "ด่วน":
      speed_level_class_name = 'hurry';
      break;
    case "ด่วนมาก":
      speed_level_class_name = 'very_hurry';
      break;
    case "ด่วนที่สุด":
      speed_level_class_name = 'urgent';
      break;
    default:
      speed_level_class_name = 'normal';
  }

  return speed_level_class_name;
}

export const SpeedLevel = (props) => {
  let speed_key = 'normal';
  switch(props.level) {
    case "ด่วน":
      speed_key = 'hurry';
      break;
    case "ด่วนมาก":
      speed_key = 'very_hurry';
      break;
    case "ด่วนที่สุด":
      speed_key = 'urgent';
      break;
    default:
      speed_key = 'normal';
  }

  return (
    <label 
      className={`btn btn-sm btn-secondary speed-level-${speed_key} ${props.speed_level === props.level && 'active'}`}
      onClick={() => props.handleChangeSpeedLevel(props.level)}>
      <input type="radio" name="speed_level" id={`speed_level_${props.index}`} autoComplete="off" checked /> 
      {props.level}
    </label>
  )
}

export const SecretLevel = (props) => {
  let secret_key = 'normal';
  switch(props.level) {
    case "ลับ":
      secret_key = 'confidential';
      break;
    case "ลับมาก":
      secret_key = 'secret';
      break;
    case "ลับที่สุด":
      secret_key = 'top_secret';
      break;
    default:
      secret_key = 'normal';
  }
  return (
    <label  
      className={`btn btn-sm btn-secondary secret-level-${secret_key} ${props.secret_level === props.level && 'active'}`}
      onClick={() => props.handleChangeSecretLevel(props.level)}>
      <input type="radio" name="secret_level" id={`secret_level_${props.index}`} autoComplete="off" checked /> 
      {props.level}
    </label>
  )
}