import React, {Component} from 'react';

import { SpeedLevel } from './FormElement';

export default class SpeedLevelForm extends Component {
  render(){
    return (
      <div className='my-3'>
        <SpeedLevel 
          speed_level={this.props.speed_level}
          handleChange={this.props.handleChange} />
      </div>
    )
  }
}