import React, { Component } from 'react';
import _ from 'lodash';

export default class ChangePasswordPage extends Component {
  constructor(props){
    super(props)
  }

  render(){
    return (
      <div>
        <div className='row mb-1'>
          <div className='col-12 col-sm-4 col-form-label'>
            <strong>รหัสผ่านเดิม:</strong>
          </div>
          <div className='col-12 col-sm-8'>
            <input 
              type="password" 
              name='old_password' 
              value={this.props.old_password}
              className='form-control form-control-sm'
              onChange={this.props.handleChange} />
          </div>
        </div>
        <div className='row mb-1'>
          <div className='col-12 col-sm-4 col-form-label'>
            <strong>รหัสผ่านใหม่:</strong>
          </div>
          <div className='col-12 col-sm-8'>
            <input 
              type="password" 
              name='new_password' 
              value={this.props.new_password}
              className='form-control form-control-sm'
              onChange={this.props.handleChange} />
          </div>
        </div>
        <div className='row mb-1'>
          <div className='col-12 col-sm-4 col-form-label'>
            <strong>ยืนยันรหัสผ่านใหม่:</strong>
          </div>
          <div className='col-12 col-sm-8'>
            <input 
              type="password" 
              name='new_password_confirmation' 
              value={this.props.new_password_confirmation}
              className='form-control form-control-sm'
              onChange={this.props.handleChange} />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-12 text-right'>
            <button type='button' className='btn btn-primary pointer mr-2' onClick={this.props.handleSave}>
              บันทึกการเปลี่ยนแปลง
            </button>
            <button type='button' className='btn btn-dark pointer' onClick={this.props.handleClear}>
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    );
  }
}