import _ from 'lodash';

import { adminApi } from '../api'

export default class BaseStore {
	constructor() {
		this.api = adminApi;
		this.endpoint = '';
		//console.log('REGISTER: BaseStore.' + this.constructor.name)
		BaseStore.STORES[this.constructor.name] = this;
	}

	normalizeForSave(values, options) {
		if (!_.isPlainObject(values)) {
			return values;
		} 
		options = options || {};
		var normalized = {};
		_.forIn(values, (value, key) => {
			if (value && value._id) {
				normalized[key + '_id'] = value._id;
			} else {
				if (_.includes(options.objects, key)) {
					normalized[key] = this.normalizeForSave(value);
				} else {
					normalized[key] = value;
				}
			}
		});
		return normalized;
	}

	save(id, attributes, options) {
		options = options || {};
		var normalized = this.normalizeForSave(attributes, options);
		return this.request({
			id: id,
			method: (id ? 'put' : 'post'),
			data: normalized,
			...options,
		})
	}

	delete(id) {
		return this.request({
			id: id,
			method: 'delete'
		})
	}

	request(config) {
		var url = this.endpoint;
		if (config.id) {
			url = url + '/' + config.id;
		}
		if (config.action) {
			url = url + '/' + config.action;
		}
		if (config.url) {
			url = config.url;
		}
		if ((config.method === 'get' || config.method === null || config.method === 'GET')) {
			if (url.indexOf('?') >= 0) {
				url += '&_=' + new Date().getTime()
			} else {
				url += '?_=' + new Date().getTime()
			}
		}
		return this.api.request({
			...config,
			timeout: 120000,
			url: url
		})
	}
}

BaseStore.STORES = {}

window.BaseStore = BaseStore;