import React from 'react';
import _ from 'lodash';

const SelectedDepartmentGroup = (props) => (
  <div className="card mb-1">
    <div className="card-body">
      <input type="text" className="form-control from-control-sm mb-2" value={props.department_group_name} onChange={(event) => props.setInformeeText(event, props.positions_user_id)} />
      <h4>{props.department_group_name}</h4>
      {_.map(props.department_group_members, function(member, index){
        return(
          <div key={index} className="green-border green-text p-1 mb-1">
            {member}
          </div>
        )
      })}
      <i  className='fa fa-times close-icon text-danger' 
          onClick={() => props.handleRemoveTarget(props.positions_user_id)}>
      </i>
    </div>
  </div>
)

export default SelectedDepartmentGroup;
