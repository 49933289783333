import React, { Component } from 'react';
import _ from 'lodash';
import Permission from '../../components/Permission';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';
import AfterSaveLoading from '../../components/AfterSaveLoading';
import SimpleModal from '../../components/SimpleModal';

import InformeeStore from '../../stores/InformeeStore';

import Form from '../../components/Informee/Form';

class InformeesSettingPage extends Component {
  constructor(){
    super();

    this.state = {
      loading: false,
      isDataFetched: false,
      informees: [],
      departmentGroups: [],
      modalOpen: false,
      modalTitle: "",
      edittingInformee: {}
    }

    this.reloadData = this.reloadData.bind(this);
    this.rowMemberAmount = this.rowMemberAmount.bind(this);
    this.renderRowActions = this.renderRowActions.bind(this);
    this.newRow = this.newRow.bind(this);
    this.editRow = this.editRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount(){
    if (Permission.canEditDepartmentGroup()) {
      this.reloadData();
    }else{
      window.location.href = '/receive_documents/mydoc';
    }
  }

  reloadData(){
    InformeeStore.request({}).then((resp)=>{
      let informees = _.get(resp.data, 'data', []);
      let departmentGroups = _.get(resp.data, 'included.department_groups', []);
      let positions = _.get(resp.data, 'included.positions', []);

      this.setState({
        informees: informees,
        departmentGroups: departmentGroups,
        positions: positions
      })
    }).then(()=>{
      this.setState({isDataFetched: true, loading: false});
    })
  }

  rowMemberAmount(cell, row){
    return (
      <div style={{whiteSpace: 'normal'}}>{row.users.length}</div>
    )
  }

  newRow(){
    this.setState({
      modalOpen: true,
      modalTitle: "เพิ่มเรียน",
    });
  }

  editRow(id){
    const informee = _.find(this.state.informees, { id: id})

    this.setState({
      modalOpen: true,
      modalTitle: "แก้ไขตั้งค่าเรียน",
      edittingInformee: informee
    });
  }

  deleteRow(id){
    if(window.confirm("ยืนยันการลบตั้งค่าเรียน?")){
      let self = this;

      InformeeStore.delete(id).then(()=>{
        this.handleCloseAndReloadData()
      }, function(error){
        let error_message = _.get(error.response.data, 'error.message', '');
        self.setState({error_message: error_message});
      });
    }
  }

  onClose(){
    this.setState({
      modalOpen: false,
      modalTitle: "",
      edittingInformee: {}
    });
  }

  handleSubmit(informee){
    let self = this;
    
    const id = informee.id;

    if(id === null){
      informee = _.omit(informee, ['id']);
    }

    InformeeStore.save(id, {
      informee: informee
    }).then(()=>{
      this.handleCloseAndReloadData()
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      self.setState({error_message: error_message});
    });
  }

  handleCloseAndReloadData(){
    this.setState(
      {
        loading: true,
        modalOpen: false,
        modalTitle: "",
        edittingInformee: {}
      }, 
      this.reloadData()
    );
  }

  renderRowActions(cell, row){
    return (
      <div>
        <button className="btn btn-sm btn-success" onClick={() => this.editRow(cell)}>
          <i className="fa fa-cog"></i>{' '}แก้ไข
        </button>
        {' '}
        <button className="btn btn-sm btn-danger" onClick={() => this.deleteRow(cell)}>
          <i className="fa fa-trash"></i>{' '}ลบ
        </button>
      </div>
    )
  }

  _setTableOption(){ 
      if(this.state.isDataFetched){
            return "Not found";
        }else{
            return <Loading />
        }
  }

  render(){
    const options = {
      noDataText: this._setTableOption(),
      sizePerPageList: []
    };
    let data = MergeAttributes(this.state.informees);

    return (
      <div id="department-groups">
        {this.state.loading && <AfterSaveLoading />}

        <div className="row mb-2">
          <div className="col-6">
            <h2>ตั้งค่าเรียน</h2>
          </div>
          <div className="col-6 text-right">
            <button className='btn btn-success' onClick={() => this.newRow()}>
              <span>สร้างใหม่</span>
            </button>
          </div>
        </div>
        
        <div className="row">
          <div className="col-12 rui-informee-setting">
            <BootstrapTable 
              data={data}
              search={ true }
              multiColumnSearch={ true }
              options={ options }
              striped
              hover
              pagination
              version='4'
              tableContainerClass={'table-sm'}>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='name' dataSort={ true }>เรียน</TableHeaderColumn>
              <TableHeaderColumn dataField='department_group_names' dataSort={ true }>ผู้ดำเนินการ</TableHeaderColumn>
              <TableHeaderColumn  dataField='id'
                                  width='150' 
                                  dataFormat={this.renderRowActions}>
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>

        <SimpleModal 
          isOpen={this.state.modalOpen}
          title={this.state.modalTitle}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onClose}>
            <Form informee={this.state.edittingInformee} 
                  departmentGroups={this.state.departmentGroups} 
                  positions={this.state.positions} 
                  onClose={this.onClose} 
                  onSubmit={this.handleSubmit} />
        </SimpleModal>
      </div>
    );
  }
}

export default InformeesSettingPage;
