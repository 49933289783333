import React, { Component } from 'react';
import _ from 'lodash';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { MergeAttributes } from '../FlatData';
import Loading from '../Loading';
import AfterSaveLoading from '../AfterSaveLoading';

import UnitNumberStore from '../../stores/UnitNumberStore';

export default class ArchiveUnitNumbers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDataFetched: false,
      pagination: {},
      archive_unit_numbers: [],
      searchText: ''
    }

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    UnitNumberStore.request({url: 'unit_numbers/archive_unit_numbers'}).then((resp)=>{
      const archive_unit_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        archive_unit_numbers: archive_unit_numbers,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, archive_unit_numbers: []});
    const { searchText } = this.state;

    UnitNumberStore.request({url: `unit_numbers/archive_unit_numbers?page=${page}&searchText=${searchText}`}).then((resp)=>{
      const archive_unit_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        archive_unit_numbers: archive_unit_numbers,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, archive_unit_numbers: []});

    UnitNumberStore.request({url: `unit_numbers/archive_unit_numbers?page=1&searchText=${searchText}`}).then((resp)=>{
      const archive_unit_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        archive_unit_numbers: archive_unit_numbers,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  _setTableOption(){ 
    if(this.state.isDataFetched){
      return "ไม่มีข้อมูล";
    }else{
      return <Loading />
    }
  }

  render(){
    const { archive_unit_numbers, isDataFetched } = this.state;

    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);

    let data = [];
    if (!_.isEmpty(archive_unit_numbers)){
      data = MergeAttributes(archive_unit_numbers);
    }

    const options = {
      sizePerPageList: [],
      sizePerPage: 20,
      page: active_page,
      noDataText: this._setTableOption(),
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500
    };

    return (
      <div>
        {!isDataFetched && <AfterSaveLoading />}

        <div className='rui-draftdoc'>
          <BootstrapTable 
            data={data}
            remote={ true }
            search={ true }
            fetchInfo={{dataTotalSize: total_items_count}}
            multiColumnSearch={ true }
            options={ options }
            striped
            hover
            pagination
            version='4'
            tableContainerClass={'table-sm'}
            trClassName='td-white-space-normal'>
            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.props.issueNumberFormatter} width='200'>เลขที่</TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.props.documentDetailsFormatter}>รายละเอียด</TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.props.numbersFormatter} width='200'></TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    )
  }
}

