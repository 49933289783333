import React from 'react';

const Confirmation = (props) => (
  <div className="row">
    <div className="col-12 mb-2">ย้ายภาระงานจำนวน <strong>{props.movingWorkflowProcessIds.length}</strong> ภาระงาน</div>

    <div className="col-6">
      <div className="font-weight-bold">จาก</div>
      <div>{props.sourcePositionUser.attributes.full_name}</div>
      <div className="text-success">{props.sourcePositionUser.attributes.position_name}</div>
      <div className="text-success">{props.sourcePositionUser.attributes.department_name}</div>
    </div>

    <div className="col-6">
      <div className="font-weight-bold">ไปยัง</div>
      <div>{props.targetPositionUser.attributes.full_name}</div>
      <div className="text-success">{props.targetPositionUser.attributes.position_name}</div>
      <div className="text-success">{props.targetPositionUser.attributes.department_name}</div>
    </div>

    <div className="modal-footer col-12">
      <button type='button' className="btn btn-primary pointer" onClick={props.onSubmit}>ย้ายภาระงาน</button>
      <button type='button' className="btn btn-secondary pointer" onClick={props.onClose}>ยกเลิก</button>
    </div>
  </div>
)

export default Confirmation;
