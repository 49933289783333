import React, { Component } from 'react';

class GeneralSettingSection extends Component {
  render(){
    return(
      <div>
        <h3>ตั้งค่าอื่น ๆ</h3>
        
        <div className="form-check">
          <label className="form-check-label">
            <input  className="form-check-input mr-2" 
                    type="checkbox" 
                    name="enable_connect_with_th_egif" 
                    checked={this.props.site_setting.enable_connect_with_th_egif} 
                    onChange={this.props.onUpdateSiteSettingCheckbox}
            />
            {"เชื่อมต่อ TH-eGif"}
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input  className="form-check-input mr-2" 
                    type="checkbox" 
                    name="use_through" 
                    checked={this.props.site_setting.use_through} 
                    onChange={this.props.onUpdateSiteSettingCheckbox}
            />
            {"ใช้ Flow ผ่าน"}
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input  className="form-check-input mr-2" 
                    type="checkbox" 
                    name="use_note_signee" 
                    checked={this.props.site_setting.use_note_signee}
                    onChange={this.props.onUpdateSiteSettingCheckbox}
            />
            {"ใช้การลงนามกำกับ(เกษียนเอกสาร)"}
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input  className="form-check-input mr-2" 
                    type="checkbox" 
                    name="use_internal_forward" 
                    checked={this.props.site_setting.use_internal_forward} 
                    onChange={this.props.onUpdateSiteSettingCheckbox}
            />
            {"ใช้การส่งต่อ(เฉพาะภายใต้หน่วยงาน)"}
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input  className="form-check-input mr-2" 
                    type="checkbox" 
                    name="informee_can_forward_document" 
                    checked={this.props.site_setting.informee_can_forward_document} 
                    onChange={this.props.onUpdateSiteSettingCheckbox}
            />
            {"ผู้เกษียนสั่งการส่งต่อได้"}
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input  className="form-check-input mr-2" 
                    type="checkbox" 
                    name="can_book_number" 
                    checked={this.props.site_setting.can_book_number} 
                    onChange={this.props.onUpdateSiteSettingCheckbox}
            />
            {"ใช้งานการจองเลข(ทะเบียนรับ)"}
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input  className="form-check-input mr-2" 
                    type="checkbox" 
                    name="can_insert_number" 
                    checked={this.props.site_setting.can_insert_number} 
                    onChange={this.props.onUpdateSiteSettingCheckbox}
            />
            {"ใช้งานการแทรกเลข"}
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input  className="form-check-input mr-2" 
                    type="checkbox" 
                    name="enable_secretary" 
                    checked={this.props.site_setting.enable_secretary} 
                    onChange={this.props.onUpdateSiteSettingCheckbox}
            />
            {"ใช้งานระบบเลขาฯ (โปรดตรวจสอบกับผู้ดูแลระบบก่อนปิดใช้งาน)"}
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input  className="form-check-input mr-2" 
                    type="checkbox" 
                    name="enable_on_duty" 
                    checked={this.props.site_setting.enable_on_duty} 
                    onChange={this.props.onUpdateSiteSettingCheckbox}
            />
            {"ใช้งานระบบรักษาการแทน"}
          </label>
        </div>

      </div>
    )
  }
}

export default GeneralSettingSection;
