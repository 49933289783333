import React, { Component } from 'react';
import _ from 'lodash';

class GroupDataForm extends Component {
	constructor(props){
		super(props)

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(){
		let { group } = this.props;
		group.code = this.refs.code.value;
		group.name = this.refs.name.value;
		group.short_name = this.refs.short_name.value;
		this.props.handleChangeData(group);
	}

	render() {
		return (
			<div>
				<div className='row form-group'>

					<div className='col-md-12'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>รหัสหน่วยงาน</strong></label>
							</div>
							<div className='col-md-12'>
								<input 
									type='text' 
									ref='code' 
									value={this.props.group.code}
									className='form-control'
									onChange={this.handleChange}/>
							</div>
						</div>
					</div>

					<div className='col-md-12'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>ชื่อหน่วยงาน</strong></label>
							</div>
							<div className='col-md-12'>
								<input
									type='text' 
									ref='name' 
									value={this.props.group.name}
									className='form-control'
									onChange={this.handleChange}/>
							</div>
						</div>
					</div>

					<div className='col-md-12'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label'><strong>ชื่อย่อหน่วยงาน</strong></label>
							</div>
							<div className='col-md-12'>
								<input
									type='text' 
									ref='short_name' 
									value={this.props.group.short_name}
									className='form-control'
									onChange={this.handleChange}/>
							</div>
						</div>
					</div>

				</div>
			</div>
		)
	}
}

export default GroupDataForm;