import React, { Component } from 'react';
import _ from 'lodash';

import { RenderThroughsSignature} from './elements/Throughs';

import InlineEditAttribute from './elements/InlineEditAttribute';

import PageNumber from './elements/PageNumber';
import DeletePageButton from './elements/DeletePageButton';

import Header from './Header';
import Levels from './Levels';
import InfosSection from './InfosSection';
import ContentsSection from './ContentsSection';
import AdditionalInfoSection from './AdditionalInfoSection';
import ImportantNoteSection from './ImportantNoteSection';

export default class DocumentBody extends Component {
  constructor(props){
    super(props)

    this.renderPage = this.renderPage.bind(this);
  }

  render(){
    const { document_attributes, document, all_users } = this.props;

    return (
      <div className='col-md-9 pl-0'>
        <div className='bg-secondary a4-vertical-padding'>

          {!_.isEmpty(document_attributes.contents) && _.map(document_attributes.contents, this.renderPage)}

          {/* New Page */}
          {!_.isEmpty(document.throughs) && (
            <RenderThroughsSignature 
              throughs={document.throughs}
              users={all_users}
              handleChange={this.props.handleChange} />
          )}
        </div>
      </div>
    )
  }

  renderPage(content, index){
    var page = this.firstPage(content);
    if (index > 0){
      page = this.renderPageMoreThanOne(content, index);
    }
    
    return page
  }

  firstPage(content){
    const {
      document_template, 
      document_attributes, 
      document, 
      loading
    } = this.props;

    return (
      <div key={content.page_number} className='a4-paper bg-light'>
        <Header document_template={document_template} />
        <Levels document_template={document_template} document={document} />
        <InfosSection {...this.props} />
        <ContentsSection content={content} {...this.props} />

        {/* Minutes Document */}
        {!_.isEmpty(document_template.attributes.template_attributes.end_meeting_date) && _.size(document_attributes.contents) <= 1 && (
          <div className='pt-3'>
            <InlineEditAttribute
              label={document_attributes.end_meeting_date.title}
              suggest_text={document_attributes.end_meeting_date.suggest_text} 
              field_name={document_attributes.end_meeting_date.name}
              value={document_attributes.end_meeting_date.value}
              text_align_left={true}
              label_class={'text-md'}
              content_class={'text-md'}
              has_space_after_label={true}
              has_parenthesis={false}
              handleChange={this.props.handleChangeAttributeValue}
              loading={loading} />
          </div>
        )}
        {/* End Minutes Document */}

        <AdditionalInfoSection content={content} {...this.props} />

        {!_.isEmpty(document_template.attributes.template_attributes.important_note_title) && _.size(document_attributes.contents) <= 1 && (
          <ImportantNoteSection {...this.props} />
        )}

        <div className='footer-page'></div>
      </div>
    )
  }

  renderPageMoreThanOne(content, index){
    const { document_attributes, document_template, loading } = this.props;
    const contents_size = _.size(document_attributes.contents);
    return (
      <div key={content.page_number} className='a4-paper bg-light mt-4'>
        <DeletePageButton content={content} onDeletePage={this.props.onDeletePage} />
        <PageNumber content={content} />
        <ContentsSection content={content} {...this.props} />

        {/* Minutes Document */}
        {!_.isEmpty(document_template.attributes.template_attributes.end_meeting_date) && contents_size > 1 && contents_size === (index + 1) && (
          <div className='pt-3'>
            <InlineEditAttribute
              label={document_attributes.end_meeting_date.title}
              suggest_text={document_attributes.end_meeting_date.suggest_text} 
              field_name={document_attributes.end_meeting_date.name}
              value={document_attributes.end_meeting_date.value}
              text_align_left={true}
              label_class={'text-md'}
              content_class={'text-md'}
              has_space_after_label={true}
              has_parenthesis={false}
              handleChange={this.props.handleChangeAttributeValue}
              loading={loading} />
          </div>
        )}
        {/* End Minutes Document */}

        <AdditionalInfoSection content={content} {...this.props} />

        {!_.isEmpty(document_template.attributes.template_attributes.important_note_title) && contents_size > 1 && contents_size === (index + 1) && (
          <ImportantNoteSection {...this.props} />
        )}

        <div className='footer-page'></div>
      </div>
    )
  }

}