import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Link } from 'react-router-dom';

import './stylesheets/DocumentationSummaryPage.css';

import ReportStore from '../../stores/ReportStore';
import AfterSaveLoading from '../../components/AfterSaveLoading';
import { monthList, getThaiMonth } from '../../utils';

export default class DocumentationSummaryPage extends Component {
  constructor() {
    super()

    const dt = new Date();
    const day = dt.getDate();
    const month = dt.getMonth() + 1;
    let year = dt.getFullYear();
    if(year < 2500) {
      year = year + 543;
    }

    this.state = {
      isDataFetched: false,
      month: month,
      year: year,
      results: [],
      yearList: []
    }

    this.renderRow = this.renderRow.bind(this);
    this.handleChangeMonth = this.handleChangeMonth.bind(this);
    this.handleChangeYear = this.handleChangeYear.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    ReportStore.request({
      method: 'POST',
      action: 'documentation_summary'
    }).then((resp)=>{
      const results = _.get(resp.data, 'data', []);
      const years = _.get(resp.data, 'included.years', []);

      this.setState({
        results: results,
        yearList: years
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleChangeMonth(event){
    let value = event.target.value;
    this.setState({month: value});
  }

  handleChangeYear(event){
    let value = event.target.value;
    this.setState({year: value});
  }

  handleSearch() {
    console.log('Search')
    this.setState({isDataFetched: false});

    ReportStore.request({
      method: 'POST',
      action: 'documentation_summary',
      data: {
        month: this.state.month,
        year: this.state.year
      }
    }).then((resp)=>{
      const results = _.get(resp.data, 'data', []);

      this.setState({
        results: results
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  renderRow(summary, index){
    const received_amount = (summary.received_amount === 0) ? '' : summary.received_amount;
    const sent_amount = (summary.sent_amount === 0) ? '' : summary.sent_amount;
    const dt = summary.date.split('/');
    const day = parseInt(dt[0]);
    const month = parseInt(dt[1]);
    const year = parseInt(dt[2]);
    return (
      <tr key={index}>
        <td><strong>{day} {getThaiMonth(month)} {year}</strong></td>
        <td className='bg-light'>{received_amount}</td>
        <td className='bg-light'>
          {received_amount && (
            <Link to={`/reports/daily_receive_summary?date=${summary.date}`}>พิมพ์ ({received_amount})</Link>
          )}
        </td>
        <td>{sent_amount}</td>
        <td>
          {sent_amount && (
            <Link to={`/reports/daily_send_summary?date=${summary.date}`}>พิมพ์ ({sent_amount})</Link>
          )}
        </td>
      </tr>
    )
  }

  renderTotalRow(results){
    const received_amount = _.reduce(results, function(sum, result) {
                              return sum + (parseInt(result.received_amount) || 0);
                            }, 0);
    const sent_amount = _.reduce(results, function(sum, result) {
                              return sum + (parseInt(result.sent_amount) || 0);
                            }, 0);
    return (
      <tr key="total-row">
        <td><strong>รวม</strong></td>
        <td className='bg-light'>{received_amount}</td>
        <td className='bg-light'>
          {received_amount && (
            <Link to={`/reports/daily_receive_summary?month=${this.state.month}&year=${this.state.year}&is_total=true`}>พิมพ์ ({received_amount})</Link>
          )}
        </td>
        <td>{sent_amount}</td>
        <td>
          {sent_amount && (
            <Link to={`/reports/daily_send_summary?month=${this.state.month}&year=${this.state.year}&is_total=true`}>พิมพ์ ({sent_amount})</Link>
          )}
        </td>
      </tr>
    )
  }

  render(){
    const { results, yearList } = this.state;
    return (
      <div>
        {!this.state.isDataFetched && <AfterSaveLoading />}

        <div className='row'>
          <div className='col-12'>
            <h2>สรุปการ รับ-ส่ง หนังสือ</h2>
          </div>
        </div>

        <div className='row'>
          <div className='col-4 col-sm-3 col-md-2 ml-auto text-right mb-2 pr-1'>
            <select 
              name='month' 
              className='form-control form-control-sm'
              value={this.state.month}
              onChange={this.handleChangeMonth}>
              {_.map(monthList, (month, index) => {
                return <option value={month[0]} key={month[0]}>{`เดือน ${month[1]}`}</option>
              })}
            </select>
          </div>
          <div className='col-4 col-sm-3 col-md-2 text-right mb-2 pl-1 pr-1'>
            <select 
              name='year' 
              className='form-control form-control-sm'
              value={this.state.year}
              onChange={this.handleChangeYear}>
              {_.map(yearList, (year, index) => {
                return <option value={year} key={year}>{year + 543}</option>
              })}
            </select>
          </div>
          <div className='col-4 col-sm-3 col-md-2 text-right mb-2 pl-1'>
            <button 
              type='button' 
              className='btn btn-block btn-primary' 
              onClick={this.handleSearch}>
              ค้นหา
            </button>
          </div>
          <div className='col-12'>

            <table className='table table-bordered w-100 text-center'>
              <thead>
                <tr>
                  <th rowSpan='2' className='w-25'>วันที่</th>
                  <th colSpan='2' className='bg-light'>หนังสือรับ</th>
                  <th colSpan='2'>หนังสือส่ง</th>
                </tr>
                <tr>
                  <th className='bg-light'>จำนวนหนังสือเข้า</th>
                  <th className='bg-light'>รายงานประจำวัน</th>
                  <th>จำนวนหนังสือออก</th>
                  <th>รายงานประจำวัน</th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(results) && _.map(results, this.renderRow)}
                {!_.isEmpty(results) && this.renderTotalRow(results)}
              </tbody>
            </table>
            
          </div>
        </div>
      </div>
    );
  }
}