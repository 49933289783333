import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import qs from 'qs';

class ExternalReceiveSummaryPage extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }

  render(){
    return (
      <div>
        <div className='row'>
          <div className='col-12'>
            <h2>หนังสือรับเข้าจำแนกตามหน่วยงานภายนอก</h2>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ExternalReceiveSummaryPage)