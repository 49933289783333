import React, { Component } from 'react';

import Permission from '../../components/Permission';

import ShowSettingDetails from '../../components/settings/ShowSettingDetails';
import EditSettingDetails from '../../components/settings/EditSettingDetails';

import './stylesheets/SettingPage.css';

class SettingPage extends Component {
	constructor(props){
		super(props)

		this.state = {
			show: true
		}

		this.handleShow = this.handleShow.bind(this);
	}

	componentDidMount(){
		if (!Permission.isAdmin()) {
			window.location.href = '/receive_documents/mydoc';
		}
	}

	handleShow(){
		if (this.state.show) {
			this.setState({show: false});
		}else{
			this.setState({show: true});
		}
	}

	render(){
		return (
		  <div className='settings-page'>
		  {Permission.isAdmin() && (
		  	<div>
					<div className='row form-group'>
						<div className='col-md-12'>
							<h2>การตั้งค่า</h2>
						</div>
					</div>
					{this.state.show ? (
						<ShowSettingDetails handleShow={this.handleShow} />
					) : (
						<EditSettingDetails handleShow={this.handleShow} />
					)}
		  	</div>
		  )}
		  </div>
		)
	}
}

export default SettingPage;