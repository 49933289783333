import React, {Component} from 'react';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';

const EditingFieldName = 'address';

export default class InlineEditAddress extends Component {
	constructor(props){
		super(props)

		this.state = {
  		value: this.props.address,
  		editing_address: false
  	}

  	this.handleChange = this.handleChange.bind(this);
  	this.handleSetValue = this.handleSetValue.bind(this);
  	this.onEditingAddress = this.onEditingAddress.bind(this);
	}

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading){
      this.setState({
        value: nextProps.address
      })
    }
  }

  handleChange(value) {
    this.setState({ value: value })
  }

	handleSetValue(){
		var val = this.state.value;
		this.setState({editing_address: false}, this.props.handleChange(EditingFieldName, val));
	}

	onEditingAddress(){
  	if (this.state.editing_address){
    		this.setState({editing_address: false});
  	}else{
    		this.setState({editing_address: true});
  	}
	}

	stripHTML(text){
		if (_.isEmpty(text)) { return }

		var regex = /(<([^>]+)>)/ig;
 		return text.replace(regex, "");
	}

	render(){
		var address = this.stripHTML(this.props.address);
		return (
			<div className='row mb-2'>
				<div className='col-12 ml-3 mt-1 pl-4 pr-0'>
					{this.state.editing_address ? (
						<div>
							<ReactQuill 
                value={this.state.value}
                onChange={this.handleChange}
                modules={{toolbar: false}} />

					        <div className='mt-3'>
					        	<button 
						        	type='button' 
						        	className='btn btn-primary'
						        	onClick={this.handleSetValue}>
						        	ตกลง
						        </button>
						    </div>
				    </div>
					) : (
						<div>
							{address ? (
								<div className='pointer' onClick={this.onEditingAddress}>
									{renderHTML(this.props.address)}
								</div>
							) : (
								<div className='text-danger ml-5 pointer' onClick={this.onEditingAddress}>
									<em>{this.props.suggest_text}</em>
								</div>
							)}	
						</div>
					)}
				</div>
			</div>
		)
	}
}