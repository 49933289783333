import React, {Component} from 'react';
import _ from 'lodash';

import SimpleModal from '../SimpleModal';

const BookIssueNumberModal = (props) => {
  const renderOption = (group, index) => {
    return (
      <option key={index} value={group.id}>{group.attributes.name}</option>
    )
  }

  const renderNumberTypeOption = (document_template, index) => {
    return (
      <option key={`number_type_${index}`} value={document_template.attributes.name}>
        {document_template.attributes.name}
      </option>
    )
  }

  const group = _.find(props.groups, {id: props.group_id});

  return (
    <SimpleModal 
      isOpen={props.open}
      title={props.modalTitle}
      showSubmitButton={false}
      onClose={props.onClose}>
        {!props.show_warning ? (
          <div>
            <div className='row mb-3'>
              <div className='col-md-4 pt-2'>
                <strong>ประเภท</strong>
              </div>
              <div className='col-md-7'>
                <select 
                  value={props.selectedNumberType}
                  name='selectedNumberType'
                  className='form-control form-control-sm'
                  onChange={props.handleChange}>
                  {!_.isEmpty(props.document_templates) && _.map(props.document_templates, renderNumberTypeOption)}
                </select>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-md-4 pt-2'>
                <strong>หน่วยงาน</strong>
              </div>
              <div className='col-md-7'>
                <select 
                  value={props.group_id}
                  name='group_id'
                  className='form-control form-control-sm'
                  onChange={props.handleChange}>
                  {(!props.loading && _.isEmpty(props.groups)) && (
                    <option value=''>-- เลือกหน่วยงาน --</option>
                  )}
                  {(!props.loading && !_.isEmpty(props.groups)) && _.map(props.groups, renderOption)}
                </select>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-md-4 pt-2'>
                <strong>จำนวนหนังสือที่จอง</strong>
              </div>
              <div className='col-md-3'>
                <input 
                  type='text' 
                  value={props.amount}
                  name='amount'
                  className='form-control'
                  onChange={props.handleChange} />
              </div>
              <div className='col-md-4 pt-2'>
                เรื่อง
              </div>
            </div>

            <div className="modal-footer row">
              <button type='button' className="btn btn-primary pointer" onClick={props.confirm}>บันทึก</button>
              <button type='button' className="btn btn-secondary pointer" onClick={props.onClose}>ยกเลิก</button>
            </div>
          </div>
        ) : (
          <div>
            <div>
              คุณต้องการเพิ่มการจองเลข <u>{props.selectedNumberType}</u>
            </div>
            {!_.isEmpty(group) ? (
              <div>
                ของหน่วยงาน <u>{group.attributes.name}</u>
              </div>
            ) : (
              <div>
                ของหน่วยงาน -
              </div>
            )}
            <div>
              ทั้งหมด {props.amount} เรื่อง
            </div>
            <div className="modal-footer row">
              <button type='button' className="btn btn-primary pointer" onClick={props.handleSubmit}>ยืนยัน</button>
              <button type='button' className="btn btn-secondary pointer" onClick={props.onClose}>ยกเลิก</button>
            </div>
          </div>
        )}
    </SimpleModal>
  )
}

export default BookIssueNumberModal;