import React, {Component} from 'react';
import _ from 'lodash';

import { RenderSigneeSignature } from './elements/Signees';

import InlineEditContact from './elements/InlineEditContact';
import InlineEditRespect from './elements/InlineEditRespect';
import InlineEditSince from './elements/InlineEditSince';
import InlineEditDate from './elements/InlineEditDate';

export class AdditionalInfoSection extends Component {

  isRenderHere(){
    const { document_attributes, content } = this.props;

    const contents_size = _.size(document_attributes.contents);
    const isRender = parseInt(contents_size) === parseInt(content.page_number)

    return isRender
  }

  RenderInlineEditRespect(){
    const { document_attributes, loading } = this.props;
    return (
      <InlineEditRespect 
        respect={document_attributes.respect.value}
        handleChange={this.props.handleChangeAttributeValue}
        loading={loading} />
    )
  }

  DisplaySigneeSignature(){
    const { document_template, document, all_users, number_issuers } = this.props;
    return (
      <RenderSigneeSignature 
        document_template={document_template}
        signees={document.signees}
        users={all_users}
        number_issuer_id={document.number_issuer_id}
        number_issuers={number_issuers}
        handleChange={this.props.handleChange} />
    )
  }

  RenderInlineEditContact(){
    const { document_attributes, loading } = this.props;
    return (
      <InlineEditContact 
        suggest_text={document_attributes.contact.suggest_text}
        contact={document_attributes.contact.value}
        handleChange={this.props.handleChangeAttributeValue}
        loading={loading} />
    )
  }

  RenderInlineEditAnnounceDate(){
    const { document_attributes, loading } = this.props;
    return (
      <InlineEditDate
        label={document_attributes.announce_date.title}
        suggest_text={document_attributes.announce_date.suggest_text} 
        field_name={document_attributes.announce_date.name}
        date={document_attributes.announce_date.value}
        label_class={'text-md'}
        content_class={'text-md'}
        text_align={'center'}
        handleChange={this.props.handleChangeAttributeValue}
        loading={loading} />
    )
  }

  RenderInlineEditSince(){
    const { document_attributes, loading } = this.props;
    return (
      <InlineEditSince
        label={document_attributes.since.title}
        suggest_text={document_attributes.since.suggest_text} 
        since={document_attributes.since.value}
        label_class={'text-md'}
        content_class={'text-md'}
        text_align={'left'}
        handleChange={this.props.handleChangeAttributeValue}
        loading={loading} />
    )
  }

  RenderInlineEditOrderDate(){
    const { document_attributes, loading } = this.props;
    return (
      <InlineEditDate
        label={document_attributes.order_date.title}
        suggest_text={document_attributes.order_date.suggest_text} 
        field_name={document_attributes.order_date.name}
        date={document_attributes.order_date.value}
        label_class={'text-md'}
        content_class={'text-md'}
        text_align={'left'}
        handleChange={this.props.handleChangeAttributeValue}
        loading={loading} />
    )
  }

  RenderInlineEditProvideDate(){
    const { document_attributes, loading } = this.props;
    return (
      <InlineEditDate
        label={document_attributes.provide_date.title}
        suggest_text={document_attributes.provide_date.suggest_text} 
        field_name={document_attributes.provide_date.name}
        date={document_attributes.provide_date.value}
        label_class={'text-md'}
        content_class={'text-md'}
        text_align={'left'}
        handleChange={this.props.handleChangeAttributeValue}
        loading={loading} />
    )
  }

  render(){
    const { document_template, document_attributes, document } = this.props;

    return (
      <div>
        {(!_.isEmpty(document_template.attributes.template_attributes.announce_date) && this.isRenderHere()) && (
            this.RenderInlineEditAnnounceDate()
        )}

        {(!_.isEmpty(document_template.attributes.template_attributes.since) && this.isRenderHere())&& (
          this.RenderInlineEditSince()
        )}

        {(!_.isEmpty(document_template.attributes.template_attributes.order_date) && this.isRenderHere()) && (
          this.RenderInlineEditOrderDate()
        )}

        {(!_.isEmpty(document_template.attributes.template_attributes.provide_date) && this.isRenderHere()) && (
          this.RenderInlineEditProvideDate()
        )}

        {(!_.isEmpty(document.signees) && !_.isEmpty(document_attributes.respect) && this.isRenderHere()) && (
          this.RenderInlineEditRespect()
        )}

        {(!_.isEmpty(document.signees) && this.isRenderHere()) && (
          this.DisplaySigneeSignature()
        )}

        {(!_.isEmpty(document_template.attributes.template_attributes.contact) && this.isRenderHere()) && (
            this.RenderInlineEditContact()
        )}
      </div>
    )
  }
}

export default AdditionalInfoSection;
