import React, {Component} from 'react';
import _ from 'lodash';

import LoginSession from '../../../stores/LoginSession';

export class PositionFilter extends Component {
  constructor(props) {
    super(props)
    
    this.renderPosition = this.renderPosition.bind(this);
  }

  handleChangePosition(pu_id){
    this.props.handleChangePosition(pu_id);
  }

  renderPosition(pu, index){
    return (
      <label 
        key={index} 
        onClick={this.handleChangePosition.bind(this, pu.id)}
        className={`btn btn-sm btn-secondary ${this.props.active_position_user_id === pu.id && 'active'}`}>
        <input type="radio" name="position_user" id={`position_user_${index}`} defaultChecked="true" /> 
        {pu.position_name}
      </label>
    )
  }

  render(){
    const position_list = _.get(LoginSession.current, 'user.position_users', []);
    return (
      <div className='row mb-1'>
        <div className='col-md-1'><b>ตำแหน่ง</b></div>
        <div className='col-md-11'>
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label
              onClick={this.handleChangePosition.bind(this, '')}
              className={`btn btn-sm btn-secondary ${this.props.active_position_user_id === '' && 'active'}`}>
              <input type="radio" name="position_user" id={'position_user'} defaultChecked="true" /> 
              ทั้งหมด
            </label>

            {_.map(position_list, this.renderPosition)}
          </div>
        </div>
      </div>
    )
  }
}

export default PositionFilter;
