import React, {Component} from 'react';
import _ from 'lodash';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import GarudaEmblem from '../../assets/images/emblem.png';
import UserImage from '../../assets/images/16User.png';

import SimpleModal from '../../components/SimpleModal';
import OriginalDocumentFiles from '../../components/documents/OriginalDocumentFiles';
import MultipleUpload from '../../components/MultipleUpload';
import RenderFileNames from '../../components/RenderFileNames';
import DepartmentList from '../../components/DepartmentList';
import { SecretLevel, SpeedLevel } from '../../utils/Helper';
import SelectInform from './SelectInform';
import InformText from './InformText';
import AutoCompleteTextField from '../../components/AutoCompleteTextField';

const NormalLevel = 'ปกติ';

export default class ReceiveDocumentForm extends Component {
  constructor(props){
    super(props)

    this.renderSpeedLevel = this.renderSpeedLevel.bind(this);
    this.renderSecretLevel = this.renderSecretLevel.bind(this);
  }

  renderSpeedLevel(speed_level, index){
    return (
      <SpeedLevel key={index} level={speed_level} index={index} {...this.props} />
    )
  }

  renderSecretLevel(secret_level, index){
    return (
      <SecretLevel key={index} level={secret_level} index={index} {...this.props} />
    )
  }

  render(){
    const inform = _.first(this.props.informs);
    const { document_template, position_name_list } = this.props;

    return (
      <div>
        <div className='row mb-2'>
          <div className='col-12 col-md-2'>
            <label className='required'><b>จากหน่วยงาน</b></label>
          </div>
          <div className='col-12 col-md-10'>
            <AutoCompleteTextField 
              field={"government_service"}
              value={this.props.government_service}
              options={this.props.saved_from_departments}
              onChange={this.props.handleChangeSavedField} />
          </div>
        </div>

        {!this.props.is_send && (
          <div className='row mb-2'>
            <div className='col-12 col-md-2'>
              <label className='required'><b>ที่</b></label>
            </div>
            <div className='col-12 col-md-4'>
              <input 
                type='text' 
                className='form-control form-control-sm'
                name='issue_number'
                value={this.props.issue_number}
                onChange={this.props.handleChange} />
            </div>
          </div>
        )}

        {_.isEmpty(this.props.document_id) && this.props.is_send && (
          <div className='row mb-2'>
            <div className='col-12 col-md-2'><b>หนังสือเวียน</b></div>
            <div className='col-12 col-md-10'>
              <input 
                type='checkbox' 
                name='is_circular'
                checked={this.props.is_circular}
                onChange={this.props.handleChangeCircular} />
            </div>
          </div>
        )}

        <div className='row mb-2'>
          <div className='col-12 col-md-2'><b>ลงวันที่</b></div>
          <div className='col-12 col-md-10'>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              selected={this.props.document_date}
              onChange={this.props.handleChangeReceiveDate}
              className='form-control form-control-sm' />
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-2'><b>ชั้นความเร็ว</b></div>
          <div className='col-12 col-md-10'>

            <div className="btn-group btn-group-toggle speed_level-list" data-toggle="buttons">
              {_.map(this.props.speedLevelList, this.renderSpeedLevel)}
            </div>

          </div>
        </div>

        {this.props.speed_level !== NormalLevel && (
          <div className='row mb-2'>
            <div className='col-12 col-md-2'><b>วันที่คาดว่าจะแล้วเสร็จ</b></div>
            <div className='col-12 col-md-10'>

              <DatePicker
                dateFormat="DD/MM/YYYY"
                selected={this.props.due_date}
                onChange={this.props.handleChangeDueDate}
                className='form-control form-control-sm' />

            </div>
          </div>
        )}

        <div className='row mb-2'>
          <div className='col-12 col-md-2'><b>ชั้นความลับ</b></div>
          <div className='col-12 col-md-10'>

            <div className="btn-group btn-group-toggle secret_level-list" data-toggle="buttons">
              {_.map(this.props.secretLevelList, this.renderSecretLevel)}
            </div>

          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-2'>
            <label className='required'><b>เรื่อง</b></label>
          </div>
          <div className='col-12 col-md-10'>
            <AutoCompleteTextField 
              field={"topic"}
              value={this.props.topic}
              options={this.props.saved_topics}
              onChange={this.props.handleChangeSavedField} />
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-2'>
            <label className='required'><b>เรียน</b></label>
          </div>
          <div className='col-12 col-md-10'>
            <InformText 
              inform={inform}
              position_name_list={position_name_list}
              handleChangeInformText={this.props.handleChangeInformText} />
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-2'><b>อ้างถึงเลขที่หนังสือ (ถ้ามี)</b></div>
          <div className='col-12 col-md-10'>
            <input
              type='text' 
              name='reference_issue_numbers'
              autoComplete='reference_issue_numbers'
              value={this.props.reference_issue_numbers}
              onChange={this.props.handleChange}
              className='form-control form-control-sm' />
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-2'>
            <label><b>เจ้าของเรื่อง</b></label>
          </div>
          <div className='col-12 col-md-10'>
            <input 
              type='text' 
              name='owner'
              value={this.props.owner}
              onChange={this.props.handleChange} 
              className='form-control form-control-sm' />
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-2'><b>สิ่งที่ส่งมาด้วย</b></div>
          <div className='col-12 col-md-10'>
            <input
              type='text' 
              name='attachment' 
              value={this.props.attachment}
              onChange={this.props.handleChange}
              className='form-control form-control-sm' />
          </div>
        </div>

        {this.props.is_send && (
          <div className='row mb-2'>
            <div className='col-12 col-md-2'><b>คำสั่งการ</b></div>
            <div className='col-12 col-md-10'>
              <input
                type='text'
                name='execute' 
                value={this.props.execute}
                onChange={this.props.handleChange}
                className='form-control' />
            </div>
          </div>
        )}

        <div className='row mb-2'>
          <div className='col-12 col-md-2'><b>หมายเหตุ</b></div>
          <div className='col-12 col-md-10'>
            <input
              type='text' 
              name='keywords'
              value={this.props.keywords}
              onChange={this.props.handleChange}
              className='form-control form-control-sm' />
          </div>
        </div>

        <hr className='my-3' />

        <OriginalDocumentFiles
          original_document_files={this.props.original_document_files} 
          handleChangeOriginalDoc={this.props.handleChangeOriginalDoc}
          onRemoveOriginalDocument={this.props.onRemoveOriginalDocument}
          handleInsert={this.props.handleInsert}
          handleReplace={this.props.handleReplace}
          onSortEnd={this.props.onSortEnd} />
        
        <div id="scanner_field" className='row mb-2' style={{display: 'none'}}>
          <div className='col-12 offset-md-2 col-md-10'>
            <span id="file_name_from_scanner" className="mr-2"></span>
            <input type="text" id="file_from_scanner" hidden onClick={this.props.handleChangeFileFromScanner} />
            <label className="btn btn-info" htmlFor="file_from_scanner">อัพโหลด</label>
          </div>
        </div>

        <hr className='my-3' />

        <div className='row mb-2'>
          <div className='col-12 col-md-2'>แนบไฟล์-สิ่งที่ส่งมาด้วย</div>
          <div className='col-12 col-md-10'>
            <MultipleUpload 
              label={"แนบไฟล์-เอกสารที่ส่งมาด้วย"}
              limitation_label={"( จำกัดขนาดไฟล์ไม่เกิน 10MB. )"}
              notice_label={"จำกัดขนาดไฟล์ไม่เกิน 10MB."}
              onChange={this.props.handleUpdateFiles} />
              
            <RenderFileNames 
              files={this.props.files}
              onDeleteFile={this.props.onDeleteFile} />
          </div>
        </div>

        {(this.props.is_send && _.isEmpty(this.props.document_id)) && (
          <div className='alert alert-success'>
            <div className='row mb-2'>
              <div className='col-12'>
                {!this.props.loading && (
                  <DepartmentList 
                    all_groups={this.props.all_groups}
                    root_group={this.props.root_group}
                    selected_group_ids={this.props.selected_group_ids}
                    external_department={this.props.external_department}
                    batchSelectGroups={this.props.batchSelectGroups}
                    batchRemoveGroups={this.props.batchRemoveGroups}
                    selectGroup={this.props.selectGroup}
                    handleChange={this.props.handleChangeGroup}
                    removeGroup={this.props.removeGroup}
                    onKeyPress={this.props.onKeyPress} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}