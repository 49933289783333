import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class DataForm extends Component {
	constructor(props){
		super(props)

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeExpiredDate = this.handleChangeExpiredDate.bind(this);
	}

	handleChange(){
		var user = _.clone(this.props.user);
		user.code = this.refs.code.value;
		user.username = this.refs.username.value;
		user.prefix_name = this.refs.prefix_name.value;
		user.email = this.refs.email.value;
		user.first_name = this.refs.first_name.value;
		user.phone = this.refs.phone.value;
		user.last_name = this.refs.last_name.value;
		user.authentication_type = this.refs.authentication_type.value;
		user.gender = this.refs.gender.value;
		user.status = this.refs.status.value;
		
		this.props.onChangeData(user);
	}

	handleChangeExpiredDate(date){
		let { user } = this.props;
		user.expired_date = date;

		this.props.onChangeData(user);
	}

	render() {
		return (
			<div>
				<div className='row form-group'>

					<div className='col-md-6'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>Code</strong></label>
							</div>
							<div className='col-md-12'>
								<input 
									type='text' 
									ref='code' 
									value={this.props.user.code}
									className='form-control' 
									placeholder='Code...'
									onChange={this.handleChange} />
							</div>
						</div>
					</div>

					<div className='col-md-6'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>Username</strong></label>
							</div>
							<div className='col-md-12'>
								<input 
									type='text' 
									ref='username' 
									value={this.props.user.username}
									className='form-control' 
									placeholder='Username...'
									onChange={this.handleChange} />
							</div>
						</div>
					</div>

					<div className='col-md-6'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label'><strong>Prefix Name</strong></label>
							</div>
							<div className='col-md-12'>
								<input 
									type='text' 
									ref='prefix_name' 
									value={this.props.user.prefix_name}
									className='form-control' 
									placeholder='Prefix Name...'
									onChange={this.handleChange} />
							</div>
						</div>
					</div>

					<div className='col-md-6'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label'><strong>Email</strong></label>
							</div>
							<div className='col-md-12'>
								<input 
									type='email' 
									ref='email' 
									value={this.props.user.email}
									className='form-control' 
									placeholder='Email...'
									onChange={this.handleChange} />
							</div>
						</div>
					</div>

					<div className='col-md-6'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>First Name</strong></label>
							</div>
							<div className='col-md-12'>
								<input 
									type='text' 
									ref='first_name' 
									value={this.props.user.first_name}
									className='form-control' 
									placeholder='First Name...'
									onChange={this.handleChange} />
							</div>
						</div>
					</div>

					<div className='col-md-6'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label'><strong>Telephone</strong></label>
							</div>
							<div className='col-md-12'>
								<input 
									type='text' 
									ref='phone' 
									value={this.props.user.phone}
									className='form-control' 
									placeholder='Telephone...'
									onChange={this.handleChange} />
							</div>
						</div>
					</div>

					<div className='col-md-6'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>Last Name</strong></label>
							</div>
							<div className='col-md-12'>
								<input 
									type='text' 
									ref='last_name' 
									value={this.props.user.last_name}
									className='form-control' 
									placeholder='Last Name...'
									onChange={this.handleChange} />
							</div>
						</div>
					</div>

					<div className='col-md-6'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>Authen Type</strong></label>
							</div>
							<div className='col-md-12'>
								<select 
									ref='authentication_type' 
			                        className='form-control form-control-sm'
			                        value={this.props.user.authentication_type}
			                        onChange={this.handleChange} >
				                    	{_.map(this.props.authen_types, (opt) => {
				                      		return <option value={opt} key={opt}>{opt}</option>
				                    	})}
			                  	</select>
							</div>
						</div>
					</div>

					<div className='col-md-6'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>Gender</strong></label>
							</div>
							<div className='col-md-12'>
								<select 
									ref='gender' 
			                        className='form-control form-control-sm'
			                        value={this.props.user.gender}
			                        onChange={this.handleChange}>
				                    	{_.map(this.props.genders, (opt) => {
				                      		return <option value={opt} key={opt}>{opt}</option>
				                    	})}
			                  	</select>
							</div>
						</div>
					</div>

					<div className='col-md-6'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>Status</strong></label>
							</div>
							<div className='col-md-12'>
								<select 
									ref='status' 
			                        className='form-control form-control-sm'
			                        value={this.props.user.status}
			                        onChange={this.handleChange}>
				                    	{_.map(this.props.status, (opt) => {
				                      		return <option value={opt} key={opt}>{opt}</option>
				                    	})}
			                  	</select>
							</div>
						</div>
					</div>

					<div className='col-md-6'>
						<div className='row'>
							<div className='col-md-12'>
								<label className='field-label'><strong>วันหมดอายุ Account</strong></label>
							</div>
							<div className='col-md-12'>
								<DatePicker
		              dateFormat="DD/MM/YYYY"
		              selected={!_.isEmpty(this.props.user.expired_date) ? moment(this.props.user.expired_date) : null}
		              className='form-control form-control-sm'
		              placeholderText={'DD/MM/YYYY'}
		              onChange={this.handleChangeExpiredDate} />
							</div>
						</div>
					</div>

				</div>
				
				<div className="modal-footer row">
					<button type='submit' className="btn btn-primary pointer">Save changes</button>
					<button type='button' className="btn btn-secondary pointer" onClick={()=>this.props.onClose()}>Close</button>
				</div>
			</div>
		)
	}
}

export default DataForm;