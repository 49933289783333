import React, {Component} from 'react';

import { RenderSpeedLevel, RenderSecretLevel } from './elements/Elements';

export class Levels extends Component {
  render(){
    const { document_template, document } = this.props;
    return (
      <div>
        {document_template.attributes.has_speed_level && (
          <RenderSpeedLevel 
            speed_level={document.speed_level}
            document_template={document_template} />
        )}

        {document_template.attributes.has_secret_level && (
          <RenderSecretLevel 
            secret_level={document.secret_level}
            document_template={document_template} />
        )}
      </div>
    )
  }
}

export default Levels;
