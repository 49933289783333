import React, { Component } from 'react';
import _ from 'lodash';

class GroupLDAPForm extends Component {
	constructor(props){
		super(props)

		this.state = {
			active_directory_id: ''
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleAddLdap = this.handleAddLdap.bind(this);
	}

	handleChange(){
		var active_directory_id = this.refs.active_directory_id.value;
		this.setState({
			active_directory_id: active_directory_id
		});
	}

	handleAddLdap(){
		var group = _.clone(this.props.group);
		var active_directories = group.active_directories;
		var active_directory_ids = _.map(group.active_directories, 'id', []);

		if (!_.includes(active_directory_ids, this.state.active_directory_id)){
			active_directories = _.union(active_directories, [{id: this.state.active_directory_id, type: 'active_directories'}]);
			group.active_directories = active_directories;
		}
		
		this.props.handleChangeData(group);
	}

	handleDelete(ad){
		var group = _.clone(this.props.group);
		_.remove(group.active_directories, {id: ad.id});
		
		this.props.handleChangeData(group);
	}

	render() {
		return (
			<div>
				<div className='row form-group'>
					<div className='col-md-12'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label'><strong>LDAP</strong></label>
							</div>
							<div className='col-md-10'>
								<select 
									ref='active_directory_id' 
			                        className='form-control form-control-sm'
			                        value={this.state.active_directory_id}
			                        onChange={this.handleChange}>
			                        <option value={''} key={'Please Select'}>-- Please Select --</option>
			                    	{_.map(this.props.active_directories, (ad) => {
			                      		return <option value={ad.id} key={ad.id}>{ad.attributes.name}</option>
			                    	})}
			                  	</select>
							</div>
							<div className='col-md-2'>
								{_.isEmpty(this.state.active_directory_id) ? (
									<button 
										type='button' 
										className='btn btn-sm btn-success btn-block'
										disabled>+ Add</button>
								) : (
									<button 
										type='button' 
										className='btn btn-sm btn-success btn-block'
										onClick={this.handleAddLdap}>+ Add</button>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='row form-group'>
					<div className='col-12'>
						{!_.isEmpty(this.props.group.active_directories) && _.map(this.props.group.active_directories, (ad)=>{
							var ad_object = _.find(this.props.active_directories, {id: ad.id});
							return (
								<div key={ad.id} className="alert alert-primary" role="alert">
								  	{ad_object.attributes.name}
								  	<button type="button" className="close" onClick={this.handleDelete.bind(this, ad)}>
								    	<span aria-hidden="true">&times;</span>
								  	</button>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		)
	}
}

export default GroupLDAPForm;