import React, { Component } from 'react';
import _ from 'lodash';

export default class DocumentVersions extends Component {
  constructor(props){
    super(props)

    this.renderRow = this.renderRow.bind(this);
  }

  renderRow(document_version, index){
    const updated_by = _.get(document_version, 'attributes.updated_by.full_name', '');
    return (
      <li key={index} className='border-bottom mt-2'>
        <a href={document_version.attributes.url} target="_blank" className='text-dark'>
          {document_version.attributes.created_at}
        </a>
        <div>
          <em className='text-black-50'>{`แก้ไขโดย ${updated_by}`}</em>
        </div>
      </li>
    )
  }

  render(){
    let hasItems = this.props.document_versions && this.props.document_versions.length > 0
    return (
      <div className='row mt-3'>
        <div className='col-12'>
          <div className='text-white text-center py-2 mb-2' style={{backgroundColor: 'rgb(49, 90, 125)'}}>
            Versions ของ PDF
          </div>
        </div>
        <ul className='ul-group-list w-100 ml-0 mr-3'>
          {!_.isEmpty(this.props.document_versions) && _.map(this.props.document_versions, this.renderRow)}
          {!hasItems && <li>No items</li>}
        </ul>
      </div>
    )
  }
}
