import React, {Component} from 'react';
import _ from 'lodash';

export default class FooterButtons extends Component {
	handleSave(){
		this.props.handleSave('proceed');
	}

	handleSaveDraft(){
		this.props.handleSave('draft');
	}

	handleAddPage(){
		this.props.handleNewPage();
	}

	backToMyDocumentPage(){
		window.location.href = '/receive_documents/mydoc'
	}

	handlePreview(){
		this.props.handleSave('preview');
	}

	render(){
		return (
			<div style={{position: 'fixed', bottom: '5px', right: '1rem'}}>
				<button 
					type='button' 
					className='btn btn-sm btn-warning mr-2 small-btn pointer'
					onClick={()=>this.backToMyDocumentPage()}>
					<i className="fa fa-backward small-icon mr-1" aria-hidden="true"></i>
					กลับ
				</button>
				<button 
					type='button' 
					className='btn btn-sm btn-primary mr-2 small-btn pointer'
					onClick={this.handleSaveDraft.bind(this)}>
					<i className="fa fa-floppy-o black-icon small-icon mr-1" aria-hidden="true"></i>
					บันทึกร่าง
				</button>
				<button 
					type='button' 
					className='btn btn-sm btn-info mr-2 small-btn pointer'
					onClick={this.handlePreview.bind(this)}>
					<i className="fa fa-file black-icon small-icon mr-1" aria-hidden="true"></i>
					ดูตัวอย่าง
				</button>
				<button 
					type='button' 
					className='btn btn-sm btn-success mr-2 small-btn pointer'
					onClick={this.handleSave.bind(this)}>
					<i className="fa fa-check-square-o black-icon small-icon mr-1" aria-hidden="true"></i>
					ดำเนินงานต่อ
				</button>
				{(!this.props.document_template.attributes.is_upload_file && this.props.type !== 'upload') && (
          <button 
            type='button' 
            className='btn btn-sm btn-danger mr-2 small-btn pointer'
            onClick={this.handleAddPage.bind(this)}>
            <i className="fa fa-plus black-icon small-icon mr-1" aria-hidden="true"></i>
            เพิ่มหน้า
          </button>
        )}
			</div>
		)
	}
}