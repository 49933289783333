import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import _ from 'lodash';
import '../pages/documents/stylesheets/ReceiveNumberTable.css';

import { MergeAttributes } from './FlatData';
import ReceiveNumberStore from '../stores/ReceiveNumberStore';
import Loading from './Loading';
import { thaiDateFormatter } from '../utils/bootstrapTableFormatters';

class ReceiveNumberTable extends Component {
  constructor(){
    super();

    this.state = {
      isDataFetched: false,
      receive_numbers: [],
      pagination: {},
      searchText: ''
    }

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount(){
    const { typeName } = this.props;

    ReceiveNumberStore.request({url: `receive_numbers/get_booked_receive_numbers?type=${typeName}`}).then((resp)=>{
      const receive_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        receive_numbers: receive_numbers,
        pagination: pagination
      })

    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  _setTableOption(){ 
    if(this.state.isDataFetched){
      return "ไม่มีข้อมูล";
    }else{
      return <Loading />
    }
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, receive_numbers: []});
    const { searchText } = this.state;
    const { typeName } = this.props;

    ReceiveNumberStore.request({url: `receive_numbers/get_booked_receive_numbers?page=${page}&searchText=${searchText}&type=${typeName}`}).then((resp)=>{
      const receive_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        receive_numbers: receive_numbers,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    const { typeName } = this.props;
  
    this.setState({isDataFetched: false, receive_numbers: []});

    ReceiveNumberStore.request({url: `receive_numbers/get_booked_receive_numbers?page=1&searchText=${searchText}&type=${typeName}`}).then((resp)=>{
      const receive_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        receive_numbers: receive_numbers,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  render(){
    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);

    var data = [];
    if (this.state.isDataFetched){
      data = MergeAttributes(this.state.receive_numbers);
    }

    const selectRowProp = {
      mode: 'radio',
      clickToSelect: true,
      bgColor: 'pink',
      hideSelectColumn: true,
      onSelect: this.props.onSelect
    };
    const options = {
      sizePerPageList: [],
      sizePerPage: 10,
      page: active_page,
      noDataText: this._setTableOption(),
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500
    };

    return(
      <div className="row">
        <div className="col-12 rui-receive-number-tb">
          <BootstrapTable 
            data={data}
            remote={ true }
            fetchInfo={{dataTotalSize: total_items_count}}
            search={ true }
            multiColumnSearch={ true }
            selectRow={ selectRowProp }
            options={ options }
            striped
            hover
            condensed
            pagination
            version='4'
            tableContainerClass={'table-sm'}>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='number' dataSort={ true } width='80'>เลขที่รับ</TableHeaderColumn>
              <TableHeaderColumn dataField='issue_number' dataSort={ true }>เลขที่เอกสาร</TableHeaderColumn>
              <TableHeaderColumn dataField='from_department' columnClassName='overflow-visible' dataSort={ true }>จากหน่วยงาน</TableHeaderColumn>
              <TableHeaderColumn dataField='to_department' columnClassName='overflow-visible' dataSort={ true }>ถึงหน่วยงาน</TableHeaderColumn>
              <TableHeaderColumn dataField='created_at' dataFormat={ thaiDateFormatter } dataSort={ true } width='100'>วันที่รับเอกสาร</TableHeaderColumn>
              <TableHeaderColumn dataField='type_name'>ประเภท</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    )
  }
}

export default ReceiveNumberTable;
