import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import StateStore from '../../stores/StateStore';

import SimpleModal from '../../components/SimpleModal';
import { MergeAttributes } from '../../components/FlatData';

import StateForm from './StateForm';

class Form extends Component {
  constructor(){
    super();

    this.state = {
      id: null,
      name: "",
      description: "",
      states: [],
      actions: [],
      edittingState: {},
      modalOpen: false,
      modalTitle: "",
    }
    
    // Workflow form
    this.setAttributeValue = this.setAttributeValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    // State table
    this.handleAddNewState = this.handleAddNewState.bind(this);
    this.renderRowActions = this.renderRowActions.bind(this);
    this.renderStateActions = this.renderStateActions.bind(this);
    this.editStateRow = this.editStateRow.bind(this);
    this.deleteStateRow = this.deleteStateRow.bind(this);
    // Action table
    this.onAddNewAction = this.onAddNewAction.bind(this);
    this.editActionRow = this.editActionRow.bind(this);
    this.deleteActionRow = this.deleteActionRow.bind(this);
    // Modal
    this.onClose = this.onClose.bind(this);
    this.handleSetModalTitle = this.handleSetModalTitle.bind(this);
    this.setEdittingStateAttributes = this.setEdittingStateAttributes.bind(this);
    this.handleSubmitEdittingState = this.handleSubmitEdittingState.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const nextWorkflow = nextProps.workflow;
    const currentWorkflow = this.props.workflow;

    if (nextWorkflow.id && nextWorkflow.id !== currentWorkflow.id) {
      this.setState({
        id: nextWorkflow.id,
        name: nextWorkflow.attributes.name,
        description: nextWorkflow.attributes.description,
      });
    }

    if (nextProps.states && nextProps.states !== this.props.states) {
      this.setState({states: nextProps.states})
    }

    if (nextProps.actions && nextProps.actions !== this.props.actions) {
      this.setState({actions: nextProps.actions})
    }
  }

  handleSubmit(){
    alert("handleSubmit");
  }

  handleCancel(){
    if(window.confirm("All changes will be discarded, Are you sure?")){
      this.props.history.push("/settings/workflows");
    }
  }

  onClose(){
    this.setState({
      modalOpen: false,
      modalTitle: "",
      edittingState: {}
    });
  }

  handleCloseAndReloadData(){
    this.setState(
      {
      modalOpen: false,
      modalTitle: "",
      edittingState: {}
      }, 
      this.props.onReloadData()
    );
  }

  handleSetModalTitle(title){
    this.setState({
      modalTitle: title
    })
  }

  setAttributeValue(event){
    const field_name = event.target.name;
    const value = event.target.value;

    this.setState({
      [field_name]: value
    });
  }

  setEdittingStateAttributes(event){
    const field_name = event.target.name;
    const value = event.target.value;

    this.setState({
      edittingState: _.assign({...this.state.edittingState}, {[field_name]: value})
    });
  }

  handleAddNewState(){
    this.setState({
      modalOpen: true,
      modalTitle: "Add workflow state",
      edittingState: {
        name: "",
        description: ""
      },
    });
  }

  editStateRow(row, isSelected, e){
    const state = _.find(this.state.states, { id: row.id })

    this.setState({
      modalOpen: true,
      modalTitle: "Edit workflow state",
      edittingState: state,
    });
  }

  deleteStateRow(row){
    if(window.confirm("Are you sure?")){
      let self = this;
      var stateStore = new StateStore({workflow_id: this.state.id})

      stateStore.delete(row.id).then(()=>{
        this.handleCloseAndReloadData()
      }, function(error){
        let error_message = _.get(error.response.data, 'error.message', '');
        self.setState({error_message: error_message});
      });
    }
  }

  handleSubmitEdittingState(){
    var self = this;
    var stateStore = new StateStore({workflow_id: this.state.id})

    const edittingStateId = this.state.edittingState.id || null;

    stateStore.save(edittingStateId, {
      state: this.state.edittingState
    }).then(()=>{
      this.handleCloseAndReloadData();
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      self.setState({error_message: error_message});
    })
  }

  onAddNewAction(){
    alert("onAddNewAction");
  }
  editActionRow(){
    alert("editActionRow");
  }
  deleteActionRow(){
    alert("deleteActionRow");
  }

  _setTableOption(){ 
    return "This workflow has no state yet.";
  }

  renderRowActions(cell, row){
    return (
      <div>
        <button className="btn btn-success" onClick={() => this.editStateRow(row)}>
          <i className="fa fa-cog"></i>{' '}Edit
        </button>
        {' '}
        <button className="btn btn-danger" onClick={() => this.deleteStateRow(row)}>
          <i className="fa fa-trash"></i>{' '}Delete
        </button>
      </div>
    )
  }

  renderStateActions(cell, row){
    const action_ids = _.map(row.actions, 'id');
    const actions = _.filter(this.state.actions, function(action) { return _.includes(action_ids, action.id); })

    return (
      <div>{actions.map(action => action.attributes.name).join(", ")}</div>
    )
  }

  render(){
    const options = {
      sizePerPageList: [],
      noDataText: this._setTableOption()
    };

    let stateData = MergeAttributes(this.state.states);

    return(
      <div id="workflow-form">
        <h3>แก้ไข workflow</h3>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
          <div className="col-sm-10">
            <input type='text' name='name' value={this.state.name} onChange={this.setAttributeValue} className='form-control form-control-sm' />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="description" className="col-sm-2 col-form-label">Description</label>
          <div className="col-sm-10">
            <textarea name='description' value={this.state.description} onChange={this.setAttributeValue} className='form-control form-control-sm'>
            </textarea>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <h5>States</h5>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-primary" onClick={this.handleAddNewState}>
              Add new state
            </button>
          </div>
        </div>
        
        <div className="row mt-3">
          <div className="col-12">
            <BootstrapTable 
              data={stateData}
              options={ options }
              striped
              hover
              version='4'
              tableContainerClass={'table-sm'}>
              <TableHeaderColumn  isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn  dataField='name' width='200'>Name</TableHeaderColumn>
              <TableHeaderColumn  dataField='actions' 
                                  dataFormat={this.renderStateActions}>
                                  Available Actions
              </TableHeaderColumn>
              <TableHeaderColumn  dataField='id'
                                  width='200' 
                                  dataFormat={this.renderRowActions}>
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
        <div className="form-group row mt-3">
          <div className="col-12">
            <button type="button" onClick={this.handleSubmit} className="btn btn-success">บันทึก</button>
            {' '}
            <button type="button" onClick={this.handleCancel} className="btn btn-danger">ยกเลิก</button>
          </div>
        </div>

        <SimpleModal 
          isOpen={this.state.modalOpen}
          title={this.state.modalTitle}
          size={'modal-lg'}
          showSubmitButton={true}
          onSubmit={this.handleSubmitEdittingState}
          onClose={this.onClose}>
            <StateForm  state={this.state.edittingState}
                        setEdittingStateAttributes={this.setEdittingStateAttributes} 
                        states={this.state.states} 
                        actions={this.state.actions} 
                        onAddNewAction={this.onAddNewAction} 
                        editActionRow={this.editActionRow} 
                        deleteActionRow={this.deleteActionRow} 
                        onClose={this.onClose} />
        </SimpleModal>
      </div>
    )
  }
}

export default withRouter(Form);
