import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Link } from 'react-router-dom';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './stylesheets/SummaryReceivePage.css';

import ReportStore from '../../stores/ReportStore';
import Loading from '../../components/Loading';

export default class SummaryReceivePage extends Component {
  constructor(props){
    super(props)

    this.state = {
      isDataFetched: false,
      start_date: moment(),
      end_date: moment(),
      results: []
    }

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount(){
    ReportStore.request({
      method: 'POST',
      action: 'summary_receive'
    }).then((resp)=>{
      const results = _.get(resp.data, 'data', []);

      this.setState({
        results: results
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleChangeStartDate(date){
    this.setState({start_date: date});
  }

  handleChangeEndDate(date){
    this.setState({end_date: date});
  }

  handleSearch(event){
    this.setState({isDataFetched: false});
    ReportStore.request({
      method: 'POST',
      action: 'summary_receive',
      data: {
        start_date: this.state.start_date,
        end_date: this.state.end_date
      }
    }).then((resp)=>{
      const results = _.get(resp.data, 'data', []);

      this.setState({
        results: results
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleDownload(){
    axios({
      method:'POST',
      url: '/api/v1/system_usages/statistic',
      responseType:'arraybuffer',
      cache: false,
      data: {
        start_date: this.state.start_date || '',
        end_date: this.state.end_date || '',
        response_type: 'csv',
        receive_type: 'external'
      }
    }).then(function(response) {

      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' } )
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'รายงานสถิติการเข้าใช้งาน.xlsx'
      link.click()
    });
  }

  renderRow(summary, index){
    return (
      <tr key={index}>
        <td>
          {summary.duration}
        </td>
        <td className='bg-light'>
          <span className='mr-2'>{summary.external_receive_count}</span>
          {summary.external_receive_count > 0 && (
            <Link to={`/reports/external_receive_summary?from=${summary.start_date}&to=${summary.end_date}`}>พิมพ์</Link>
          )}
        </td>
        <td>
          <span className='mr-2'>{summary.internal_receive_count}</span>
          {summary.internal_receive_count > 0 && (
            <Link to={`/reports/internal_receive_summary?from=${summary.start_date}&to=${summary.end_date}`}>พิมพ์</Link>
          )}
        </td>
      </tr>
    )
  }

  render(){
    const { results, start_date, end_date } = this.state;
    return (
      <div>
        {!this.state.isDataFetched && <Loading />}

        <div className='row'>
          <div className='col-12'>
            <h2>รายงานสรุปจำนวนหนังสือรับเข้า</h2>
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-4 col-sm-3 col-md-2 pr-0 ml-auto'>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              selected={start_date}
              className='form-control form-control-sm'
              placeholderText={'DD/MM/YYYY'}
              onChange={this.handleChangeStartDate} />
          </div>
          <div className='col-4 col-sm-3 col-md-2 pr-0'>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              selected={end_date}
              className='form-control form-control-sm'
              placeholderText={'DD/MM/YYYY'}
              onChange={this.handleChangeEndDate} />
          </div>
          <div className='col-4 col-sm-3 col-md-2'>
            <button 
              type='button' 
              className='btn btn-block btn-primary' 
              onClick={this.handleSearch}>
              ค้นหา
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>

            <table className='table table-bordered w-100 text-center'>
              <thead>
                <tr>
                  <th className='w-25'>จาก - ถึง</th>
                  <th className='bg-light'>จำนวนหนังสือรับเข้าจำแนกตามหน่วยงานภายนอก</th>
                  <th>จำนวนหนังสือรับเข้าจำแนกตามหน่วยงานภายใน</th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(results) && _.map(results, this.renderRow)}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}