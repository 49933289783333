import React, { Component } from 'react';
import _ from 'lodash';

export default class ViewDocumentPermission extends Component {
  constructor(props){
    super(props)

    this.renderTemplateRow = this.renderTemplateRow.bind(this);
  }

  renderTemplateRow(document_template, index){
    const { can_view_document_template_ids } = this.props;
    return (
      <div key={document_template.id} className='col-12 mx-2 mb-2'>
        <input 
          type='checkbox'
          name={document_template.attributes.const_value}
          value={document_template.id}
          checked={_.includes(can_view_document_template_ids, document_template.id)}
          className='mr-2'
          onChange={this.props.handleChangeViewDocumentType} />
          {document_template.attributes.name}
      </div>
    )
  }

  render(){
    const { document_templates } = this.props;

    return (
      <div>
        <div className='row'>
          
          <div className='col-6'>
            <div className='row'>
              <div className='col-12'>
                <h5><strong>ดูหนังสือตามประเภทหนังสือได้</strong></h5>
              </div>
              {!_.isEmpty(document_templates) && _.map(document_templates, this.renderTemplateRow)}
            </div>
          </div>
          <div className='col-6'>
            <div className='row'>
              <div className='col-12'>
                <h5><strong>ดูหนังสือตามลำดับชั้นความลับของหนังสือ</strong></h5>
              </div>
              <div className='col-12 mx-2 mb-2'>
                <input 
                  type='checkbox'
                  name='can_view_confidential'
                  checked={this.props.can_view_confidential}
                  className='mr-2'
                  onChange={this.props.handleChangeViewPermission} />
                  ลับ
              </div>
              <div className='col-12 mx-2 mb-2'>
                <input 
                  type='checkbox'
                  name='can_view_secret'
                  checked={this.props.can_view_secret}
                  className='mr-2'
                  onChange={this.props.handleChangeViewPermission} />
                  ลับมาก
              </div>
              <div className='col-12 mx-2 mb-2'>
                <input 
                  type='checkbox'
                  name='can_view_top_secret'
                  checked={this.props.can_view_top_secret}
                  className='mr-2'
                  onChange={this.props.handleChangeViewPermission} />
                  ลับที่สุด
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}