import React, {Component} from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import LoginSession from '../stores/LoginSession';

import receiveIcon from '../assets/images/receive-icon.png';
import sendIcon from '../assets/images/send-icon.png';
import disabledSendIcon from '../assets/images/disabled-send-icon.png';
import listIcon from '../assets/images/list-icon.png';
import shareFileIcon from '../assets/images/share-file-icon.png';
import fileIcon from '../assets/images/file-icon.png';
import userManualIcon from '../assets/images/user-manual-icon.png';
import organizationalIcon from '../assets/images/organizational-icon.png';

import WorkflowProcessStore from '../stores/WorkflowProcessStore';
import IssueNumberStore from '../stores/IssueNumberStore';
import Permission from '../components/Permission';

export default class DashboardPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDataFetched: false,
      wait_for_receive_count: 0,
      total_sent_this_month: 0
    }
  }

  componentDidMount() {
    WorkflowProcessStore.request({url: 'wait_for_receive_count'}).then((resp)=>{
      const wait_for_receive_count = _.get(resp.data, 'data.wait_for_receive_count', 0);
      this.setState({
        wait_for_receive_count: "(" + wait_for_receive_count + ")"
      });
    }).then(()=>{
      IssueNumberStore.request({url: 'issue_numbers/get_total_sent_this_month'}).then((resp)=>{
        const total_sent_this_month = _.get(resp.data, 'data.total_sent_this_month', 0);
        this.setState({ 
          total_sent_this_month: "(" + total_sent_this_month + ")" 
        });
      })
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  render(){
    const username = _.get(LoginSession.current, 'user.username', '');
    const email = _.get(LoginSession.current, 'user.email', '');

    const { wait_for_receive_count, total_sent_this_month } = this.state;
    return (
      <div id='DashboardPage'>
        <div className="alert alert-info" role="alert">
          <div className='row'>
            <div className='col-12 text-center'>
              <h2 className='font-weight-bold'>ระบบสารบรรณอิเล็กทรอนิกส์</h2>
              <h4 className='mb-0'>กรมสุขภาพจิต</h4>
              <h4 className='mb-0'>Department of Mental Health</h4>
            </div>
            <div className='col-12'>
              <span>LoginName:</span><span><strong className='mr-3'> {username}</strong></span>
              <span>Email:</span><span><strong className='mr-3'> {email}</strong></span>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-6 col-md-4 col-lg-3'>
            <Link to='/receive_documents/mydoc' className='nav-link h5'>
              <img src={receiveIcon} alt='ReceiveIcon' className='mr-2' style={{height: '40px', marginTop: '-5px'}} />
              ทะเบียนหนังสือรับ 
              <span className='text-danger ml-1 h4'>{wait_for_receive_count}</span>
            </Link>
          </div>
          <div className='col-6 col-md-4 col-lg-3'>
            {Permission.isCorrespondence() ? (
              <Link to='/send_documents/send_all' className='pt-2 nav-link'>
                <img src={sendIcon} alt='Send' className='mr-2' style={{height: '40px', marginTop: '-5px'}} />
                ทะเบียนออกเลขส่ง
                <span className='text-danger ml-1 h4'>{total_sent_this_month}</span>
              </Link>
            ) : (
              <div style={{color: '#b5b5b5'}}>
                <img src={disabledSendIcon} alt='Send' className='mr-2' style={{height: '40px', marginTop: '-5px'}} />
                ทะเบียนออกเลขส่ง
              </div>
            )}

          </div>
          <div className='col-6 col-md-4 col-lg-3'>
            <Link to='/forums' className='pt-2 nav-link'>
              <img src={listIcon} alt='List' className='mr-2' style={{height: '40px', marginTop: '-5px'}} />
              กระดานข้อความ
            </Link>
          </div>
        </div>

        <div className='row mt-2'>
          <div className='col-6 col-md-4 col-lg-3'>
            <Link to='/dms' className='pt-2 nav-link'>
              <img src={shareFileIcon} alt='ShareFile' className='mr-2' style={{height: '40px', marginTop: '-5px'}} />
              ระบบแชร์ไฟล์เอกสาร
            </Link>
          </div>
          <div className='col-6 col-md-4 col-lg-3'>
            <Link to='/reports/documentation_summary' className='pt-2 nav-link'>
              <img src={fileIcon} alt='File' className='mr-2' style={{height: '40px', marginTop: '-5px'}} />
              สรุปการ รับ-ส่ง หนังสือ
            </Link>
          </div>
          <div className='col-6 col-md-4 col-lg-3'>
            <Link to='/manuals' className='pt-2 nav-link'>
              <img src={userManualIcon} alt='File' className='mr-2' style={{height: '40px', marginTop: '-5px'}} />
              คู่มือการใช้งาน
            </Link>
          </div>
        </div>

        <div className='row mt-2'>
          <div className='col-6 col-md-4 col-lg-3'>
            <Link to='/organizations' className='pt-2 nav-link'>
              <img src={organizationalIcon} alt='File' className='mr-2' style={{height: '40px', marginTop: '-5px'}} />
              แผนผังองค์กร
            </Link>
          </div>
        </div>
      </div>
    )
  }
}