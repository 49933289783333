import _ from 'lodash';

export function NumberInThai(numbers){
  if (_.isEmpty(numbers)){ return "" }

  var numbers_in_thai = numbers;

  numbers_in_thai = _.replace(numbers_in_thai, /0/g, '๐');
  numbers_in_thai = _.replace(numbers_in_thai, /1/g, '๑');
  numbers_in_thai = _.replace(numbers_in_thai, /2/g, '๒');
  numbers_in_thai = _.replace(numbers_in_thai, /3/g, '๓');
  numbers_in_thai = _.replace(numbers_in_thai, /4/g, '๔');
  numbers_in_thai = _.replace(numbers_in_thai, /5/g, '๕');
  numbers_in_thai = _.replace(numbers_in_thai, /6/g, '๖');
  numbers_in_thai = _.replace(numbers_in_thai, /7/g, '๗');
  numbers_in_thai = _.replace(numbers_in_thai, /8/g, '๘');
  numbers_in_thai = _.replace(numbers_in_thai, /9/g, '๙');

  return numbers_in_thai
}