import React, {Component} from 'react';
import _ from 'lodash';

const EditingFieldName = 'topic';

export default class InlineEditTopic extends Component {
	constructor(props){
		super(props)

		this.state = {
			editing: false,
			topic: this.props.topic
		}

		this.handleEditTopic = this.handleEditTopic.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSetValue = this.handleSetValue.bind(this);
	}

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading){
      this.setState({
        topic: nextProps.topic
      })
    }
  }

	handleEditTopic(){
		this.setState({editing: true})
	}

	handleChange(event){
		this.setState({topic: event.target.value});
	}

	handleSetValue(){
		let { topic } = this.state;
		this.setState({editing: false}, this.props.handleChange(EditingFieldName, topic));
	}

	renderCenterEditTopic(){
		return (
			<div className='row'>
				<div className='col-12 text-center'>
					<label className='pr-2' style={{display: 'inline-block'}}>
						{this.props.label}
					</label>
					<label style={{display: 'inline-block'}}>
						{this.state.editing ? (
							<div>
								<div className='mr-2' style={{display: 'inline-block', width: '400px'}}>
									<input 
										type='text' 
										name='topic' 
										value={this.state.topic} 
										className='form-control form-control-sm' 
										autoFocus
										onBlur={this.handleSetValue}
										onChange={this.handleChange} />
								</div>
								<div style={{display: 'inline-block'}}>
									<button 
										type='button' 
										className='btn btn-sm btn-primary'
										onClick={this.handleSetValue}>ตกลง</button>
								</div>
							</div>

						) : (
							<div>
								{this.props.topic ? (
									<div className='pointer' style={{display: 'inline-block'}} onClick={this.handleEditTopic}>
										{this.props.topic}
									</div>
								) : (
									<div className='text-danger pointer' style={{display: 'inline-block'}} onClick={this.handleEditTopic}>
										<em>{this.props.suggest_text}</em>
									</div>
								)}
							</div>
						)}
					</label>
				</div>
			</div>
		)
	}

	renderEditTopic(){
		const { document_template } = this.props;

		let text_underline_dashed = '';
		if (!_.isEmpty(document_template) && document_template.attributes.has_underline) {
			text_underline_dashed = 'text_underline_dashed';
		}

		let font_weight_bold = '';
		if (!_.isEmpty(document_template) && document_template.attributes.has_underline) {
			font_weight_bold = 'font-weight-bold';
		}
		return (
			<div className='row mt-2'>
				<div className={`col-md-1 pr-0 ${font_weight_bold}`}>
					{this.props.label}
				</div>
				<div className='col-md-11 pl-0'>
					{this.state.editing ? (
						<div className='row'>
							<div className='col-10'>
								<input 
									type='text' 
									name='topic' 
									value={this.state.topic} 
									className='form-control form-control-sm'
									autoFocus 
									onBlur={this.handleSetValue}
									onChange={this.handleChange} />
							</div>
							<div className='col-2'>
								<button 
									type='button' 
									className='btn btn-sm btn-primary'
									onClick={this.handleSetValue}>ตกลง</button>
							</div>
						</div>

					) : (
						<div>
							{this.props.topic ? (
								<div className={`pointer ${text_underline_dashed}`} onClick={this.handleEditTopic}>
									{this.props.topic}
								</div>
							) : (
								<div className={`text-danger pointer ${text_underline_dashed}`} onClick={this.handleEditTopic}>
									<em>{this.props.suggest_text}</em>
								</div>
							)}
						</div>
					)}
					
				</div>
			</div>
		)
	}

	render(){
		let card = this.renderEditTopic();
		if (this.props.text_align === 'center'){
			card = this.renderCenterEditTopic();
		}

		return (
			card
		)
	}
}