import React, {Component} from 'react';
import _ from 'lodash';
import { PieChart, Pie, Legend, Tooltip } from 'recharts';

export default class SimplePieChart extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: this.props.chart_data
    }

    this.renderCustomizedLabel = this.renderCustomizedLabel.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading) {
      this.setState({
        data: nextProps.chart_data
      });
    }
  }

  renderCustomizedLabel(attributes) {
    const {data} = this.state;
    const {index} = attributes;

    return `${data[index].name} - ${data[index].value}`;
  }

  render(){
    return (
      <div>
        {!_.isEmpty(this.state.data) && (
          <PieChart width={1000} height={350}>
            <Pie  isAnimationActive={false} 
                  data={this.state.data} 
                  dataKey={"value"}
                  cx={"50%"}
                  cy={"50%"}
                  outerRadius={80} 
                  fill="#8884d8"
                  label={this.renderCustomizedLabel} />
          </PieChart>
        )}
      </div>
    )
  }
}