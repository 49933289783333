import React, {Component} from 'react';
import _ from 'lodash';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';

export default class InlineEditPositionName extends Component {
  constructor(props){
    super(props)

    this.state = {
      editing: false,
      mode: this.props.mode,
      position_name: this.props.position_name
    }

    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSetValue = this.handleSetValue.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      mode: nextProps.mode,
      position_name: nextProps.position_name
    })
  }

  handleEdit(){
    this.setState({editing: true})
  }

  handleChange(value){
    this.setState({position_name: value});
  }

  handleSetValue(){
    let { mode, position_name } = this.state;
    this.setState({editing: false}, this.props.handleChange(this.props.signee, mode, position_name));
  }

  stripHTML(text){
    if (_.isEmpty(text)) { return }

    var regex = /(<([^>]+)>)/ig;
    return text.replace(regex, "");
  }

  render(){
    var position_name = this.stripHTML(this.props.position_name);
    return (
      <div className='row'>
        <div className='col-12 text-center'>
          <div className='row'>
            <div className='col-12'>

              {this.state.editing ? (
                <div className='row'>
                  <div className='col-10'>
                    <ReactQuill 
                      value={this.state.position_name}
                      onChange={this.handleChange}
                      modules={{toolbar: false}} />
                  </div>
                  <div className='col-2'>
                    <button 
                      type='button' 
                      className='btn btn-sm btn-primary'
                      onClick={this.handleSetValue}>ตกลง</button>
                  </div>
                </div>

              ) : (
                <div>
                  {position_name ? (
                    <div className='pointer' onClick={this.handleEdit}>
                      {renderHTML(this.props.position_name)}
                    </div>
                  ) : (
                    <div className='pointer text-danger' onClick={this.handleEdit}>
                      <em>ตำแหน่ง</em>
                    </div>
                  )}
                </div>
              )}
              
            </div>
          </div>
        </div>
      </div>
    )
  }
}

