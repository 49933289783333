import React, { Component } from 'react';
import _ from 'lodash';

import PositionDataForm from './PositionDataForm';
import PositionRoleForm from './PositionRoleForm';

import PositionStore from '../../../stores/PositionStore';
import Loading from '../../Loading';

import { positionVariables } from '../../Variable';
import ErrorMessage from '../../ErrorMessage';

const dataMode = 'data';
const roleMode = 'role';

class PositionForm extends Component {
	constructor(props){
		super(props)

		this.state = {
			loading: true,
			activeMode: 'data',
			position: positionVariables(),
			references: [],
			roles: [],
			levels: [],
			modules: [],
			error_message: ''
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentWillMount(){
		if (this.props.action === 'edit') {
			PositionStore.request({id: this.props.focus.id, action: 'edit'}).then((resp)=>{
				var position = resp.data.data;
				var included = resp.data.included || {}
				var reference_id = _.get(position, 'attributes.reference.id', '');
				var level_id = _.get(position, 'attributes.level.id', '');
				var role_ids = _.map(position.attributes.roles, 'id');
				this.setState({
					position: {
						reference_id: reference_id,
						code: position.attributes.code,
						name: position.attributes.name,
						level_id: level_id,
						role_ids: role_ids
					},
					references: included.references || [],
					roles: included.roles || [],
					levels: included.levels || [],
					modules: included.modules || [],
					loading: false
				})
			}).then(()=>{
				this.setState({loading: false});
			})
		}else{
			PositionStore.request({url: 'positions/new'}).then((resp)=>{
				var included = resp.data.included || {}
				this.setState({
					references: resp.data.data,
					roles: included.roles || [],
					levels: included.levels || [],
					modules: included.modules || [],
					loading: false
				})
			}).then(()=>{
				this.setState({loading: false});
			})
		}
	}

	handleChange(position){
		this.setState({position: position});
	}

	handleSave(event){
		event.preventDefault();
		var self = this;

		var id = null;
		var position_attributes = {};
		if (this.props.action === 'edit') {
			id = this.props.focus.id;
			position_attributes = {
				code: this.state.position.code,
				name: this.state.position.name,
				reference_id: this.state.position.reference_id,
				level_id: this.state.position.level_id,
				role_ids: this.state.position.role_ids
			}
		} else {
			position_attributes = {
				code: this.state.position.code,
				name: this.state.position.name,
				reference_id: this.state.position.reference_id,
				level_id: this.state.position.level_id,
				role_ids: this.state.position.role_ids,
				group_id: this.props.focus.id
			}
		}

		PositionStore.save(id, {
			position: position_attributes
		}).then(()=>{
			this.props.onCloseAndReloadData()
		}, function(error){
			var error_message = _.get(error.response.data, 'error.message', '');
			self.setState({error_message: error_message});
		})
	}

	render(){
		var roleSize = _.size(this.state.position.role_ids);
		
		return (
			<form onSubmit={this.handleSave}>
				{this.state.error_message && <ErrorMessage error_message={this.state.error_message} />}
				
				<ul className="nav nav-tabs nav-fill ml-0" id="ManageUser" role="tablist">
				  	<li className="nav-item">
						<span className={`nav-link pointer ${(this.state.activeMode === dataMode) && 'active'}`} 
						href='#'
						onClick={()=>this.setState({activeMode: dataMode})}>
							ข้อมูล
						</span>
				  	</li>
				  	<li className="nav-item">
						<span className={`nav-link pointer ${(this.state.activeMode === roleMode) && 'active'}`} 
						href="#"
						onClick={()=>this.setState({activeMode: roleMode})}>
							Roles
							{roleSize > 0 && (
				    			<em>&nbsp;({roleSize})</em>
				    		)}
						</span>
				  	</li>
				</ul>
				<div className="tab-content mt-4" id="ManageUserContent">
					{this.state.loading && <Loading />}
					
				  	<div className={`tab-pane fade ${(this.state.activeMode === dataMode) && 'show active'}`}>
				  		<PositionDataForm 
				  			position={this.state.position}
				  			references={this.state.references}
				  			levels={this.state.levels}
				  			handleChangeData={this.handleChange}/>
				  	</div>
				  	<div className={`tab-pane fade ${(this.state.activeMode === roleMode) && 'show active'}`}>
				  		<PositionRoleForm 
				  			position={this.state.position}
				  			modules={this.state.modules}
				  			roles={this.state.roles}
				  			handleChangeData={this.handleChange}/>
				  	</div>
				</div>
				<div className="modal-footer row">
					<button type='submit' className="btn btn-primary pointer px-3">บันทึก</button>
					<button type='button' className="btn btn-secondary pointer px-3" onClick={()=>this.props.onClose()}>ปิด</button>
				</div>
			</form>
		)
	}
}

export default PositionForm;