import React, {Component} from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import AfterSaveLoading from '../AfterSaveLoading';
import './elements/Elements.css';

import DocumentParameters from './DocumentParameters';
import DocumentForm from './DocumentForm';
import DocumentBody from './DocumentBody';
import ActorForm from './ActorForm';
import FooterButtons from './elements/FooterButtons';
import DocumentStore from '../../stores/DocumentStore';

const NormalLevel = 'ปกติ';
const DefaultTopic = 'เพื่อโปรดพิจารณาลงนาม';

export default class FrontDocument extends Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      document: {
        id: this.props.document_id || '',
        parent_id: this.props.parent_id || '',
        document_template_id: this.props.document_template.id,
        speed_level: NormalLevel,
        secret_level: NormalLevel,
        due_date: moment(),
        subject: '',
        number_issuer_id: '',
        is_circular: false,
        references: [],
        informs: [],
        files: [],
        signees: [],
        throughs: [],
        draft_by_department_id: ''
      },
      document_attributes: this.props.document_template.attributes.template_attributes || {},
      department_groups: [],
      all_users: [],
      internal_users: [],
      number_issuers: [],
      internal_number_issuers: [],
      informees: [],
      reference_documents: [],
      actings: [],
      selected_acting: [],
      selected_acting_type: "",
      actor_positions_users: [],
      current_user_departments: [],
      open_actor_form: false
    }

    this.handleChangeAttributeValue = this.handleChangeAttributeValue.bind(this);
    this.onDeleteRefDoc = this.onDeleteRefDoc.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleUpdateFiles = this.handleUpdateFiles.bind(this);
    this.onDeleteFile = this.onDeleteFile.bind(this);
    this.handleDeleteSignee = this.handleDeleteSignee.bind(this);
    this.handleDeleteThrough = this.handleDeleteThrough.bind(this);
    this.handleShowActorsForm = this.handleShowActorsForm.bind(this);
    this.handleSelectActor = this.handleSelectActor.bind(this);
    this.handleCloseActorForm = this.handleCloseActorForm.bind(this);
    this.handleChangeSigneePositionName = this.handleChangeSigneePositionName.bind(this);
    this.handleChangeContent = this.handleChangeContent.bind(this);
    
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleNewPage = this.handleNewPage.bind(this);
    this.onDeletePage = this.onDeletePage.bind(this);

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount(){
    // if has props parent_id meaning new front document
    // if has props document_id meaning edit front document
    let request = {action: 'new'};
    if (!_.isEmpty(this.props.document_id)){
      request = {id: this.props.document_id, action: 'edit'}
    }
    if (!_.isEmpty(this.props.parent_id)){
      request = {id: this.props.parent_id, action: 'edit'}
    }
    this.loadData(request);
  }

  loadData(request){
    DocumentStore.request(request).then((resp)=>{
      var document_object = _.get(resp.data, 'data', {});
      var { document } = this.state;
      var document_attributes = this.state.document_attributes;

      if (!_.isEmpty(document_object)){
        document.due_date = !_.isEmpty(document_object.attributes.due_date) ? moment(document_object.attributes.due_date) : moment();
        document.speed_level = document_object.attributes.speed_level || NormalLevel;
        document.secret_level = document_object.attributes.secret_level || NormalLevel;

        if (!_.isEmpty(this.props.parent_id)){ // main document
          document.informs = _.get(document_object.attributes, 'front_page_infroms', []);

          const parent_government_service = _.get(document_object.attributes, 'document_attributes.government_service', {});
          if (!_.isEmpty(parent_government_service)){
            document_attributes.government_service = parent_government_service; // keep government_service if main document has
          }

          document_attributes.topic.value = DefaultTopic; // default topic for front page document

          // const parent_topic = _.get(document_object.attributes, 'document_attributes.topic', {});
          // if (!_.isEmpty(parent_topic)){
          //   document_attributes.topic = parent_topic; // keep topic if main document has
          // }

          // if (!_.isEmpty(document_object.attributes.subject)){ // keep subject in topic of front document
          //   document_attributes.topic.value = document_object.attributes.subject;
          // }else{
          //   document_attributes.topic.value = DefaultTopic;
          // }

          const parent_contents = _.get(document_object.attributes, 'document_attributes.contents', []);
          document_attributes.contents = parent_contents;
        }

        if (!_.isEmpty(this.props.document_id)){ // front documemnt
          document.subject = document_object.attributes.subject || '';
          document.number_issuer_id = _.get(document_object.attributes, 'number_issuer.id', '');
          document.is_circular = document_object.attributes.is_circular || false;
          document.informs = document_object.attributes.informs || [];
          document.references = document_object.attributes.reference_documents || [];
          document.files = document_object.attributes.attachments || [];
          document.signees = document_object.attributes.signees || [];
          document.throughs = document_object.attributes.throughs || [];
          document.draft_by_department_id = _.get(document_object.attributes, 'draft_by_department.id', '');

          document_attributes = document_object.attributes.document_attributes || {};
        }
      }

      var department_groups = _.get(resp.data, 'included.department_groups', []);
      var all_users = _.get(resp.data, 'included.all_users', []);
      var internal_users = _.get(resp.data, 'included.internal_users', []);
      var number_issuers = _.get(resp.data, 'included.number_issuers', []);
      var internal_number_issuers = _.get(resp.data, 'included.internal_number_issuers', []);
      var informees = _.get(resp.data, 'included.informees', []);
      var reference_documents = _.get(resp.data, 'included.reference_documents', []);
      var actings = _.get(resp.data, 'included.actings', []);
      var current_user_departments = _.get(resp.data, 'included.current_user_departments', []);

      this.setState({
        document: document,
        document_attributes: document_attributes,
        department_groups: department_groups,
        all_users: all_users,
        internal_users: internal_users,
        number_issuers: number_issuers,
        internal_number_issuers: internal_number_issuers,
        informees: informees,
        reference_documents: reference_documents,
        actings: actings,
        current_user_departments: current_user_departments
      })
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  handleSave(saveType){
    var data = new FormData();

    data = DocumentParameters._assign_attachments(data, this.state);
    data = DocumentParameters._assign_document_attributes(data, this.state);
    data = DocumentParameters._assign_signees(data, this.state);
    data = DocumentParameters._assign_throughs(data, this.state);
    data = DocumentParameters._assign_references(data, this.state);
    data = DocumentParameters._assign_informs(data, this.state);
    
    if (_.isEmpty(this.state.document.id)){
      data.append('document[parent_id]', this.props.parent_id || '')
    }
    
    data.append('document[subject]', this.state.document.subject || '')
    data.append('document[due_date]', this.state.document.due_date || '')
    data.append('document[document_template_id]', this.state.document.document_template_id || '')
    data.append('document[number_issuer_id]', this.state.document.number_issuer_id || '')
    data.append('document[speed_level]', this.state.document.speed_level || NormalLevel)
    data.append('document[secret_level]', this.state.document.secret_level || NormalLevel)
    data.append('document[is_circular]', this.state.document.is_circular || false)
    data.append('document[draft_by_department_id]', this.state.document.draft_by_department_id || '')

    DocumentStore.save(this.state.document.id, data).then((resp)=>{
      let document = _.get(resp.data, 'data', {});

      let workflow_process_id = _.get(document, 'attributes.workflow_process.id');
      let action = 'show';
      if (saveType === 'draft') {
        action = 'edit';
      }
      window.location.href = `/templates/documents/${document.id}/${action}/${document.attributes.document_template.id}/wp/${workflow_process_id}`
    }, function(error){
        var error_message = _.get(error.response.data, 'error.message', '');
        alert(error_message)
    })
  }

  handleChangeAttributeValue(field_name, value){
    console.log('attr field_name --->', field_name)
      console.log('attr value --->', value)
      var { document_attributes } = this.state;
      document_attributes[field_name].value = value;
      this.setState({
         document_attributes: document_attributes
      });
  }

  handleChangeContent(field_name, value, page_number){
    var { document_attributes } = this.state;
    var content = _.find(document_attributes.contents, {page_number: page_number});
    content.value = value;

    this.setState({document_attributes: document_attributes});
  }

  handleChangeEndDate(date){
    let { document } = this.state;
    document.due_date = date;

    this.setState({
          document: document
      });
  }

  onDeleteRefDoc(index){
      if (!window.confirm('ยืนยันการลบ')) { return }

      var { document } = this.state;
      document.references.splice(index, 1);

      this.setState({
        document: document
      });
  }

  handleUpdateFiles(files){
      var { document } = this.state;
      document.files = _.concat(document.files, files);

      this.setState({
        document: document
      });
  }

  onDeleteFile(index){
      if (!window.confirm('ยืนยันการลบไฟล์')) { return }
        
      var { document } = this.state;
      var files = document.files;
      files.splice(index, 1);

      document.files = files;
      this.setState({
        document: document
      });
  }

  handleDeleteSignee(signee){
    if (!window.confirm('ยืนยันการลบผู้ลงนาม')) { return }

    var { document } = this.state;
    let signees = document.signees;
    _.remove(signees, function(signee_obj) {
        return signee_obj.user_id === signee.user_id && signee_obj.position_id === signee.position_id
    });

    document.signees = signees;
    if (_.isEmpty(document.signees)) { document.throughs = []; }

    this.setState({
       document: document
    });
  }

  handleDeleteThrough(through){
    if (!window.confirm('ยืนยันการลบผู้ส่งผ่าน')) { return }

    var { document } = this.state;
    let throughs = document.throughs;
    _.remove(throughs, function(through_obj) {
      return through_obj.user_id === through.user_id && through_obj.position_id === through.position_id
    });

    document.throughs = throughs;
    this.setState({
       document: document
    });
  }

  handleShowActorsForm(object, type){
    const {user_id, position_id} = object;

    const acting =  _.find(this.state.actings, (acting) => {
                      return acting.attributes.user.id === user_id
                    });

    const actor_positions_users = acting.attributes.actor_positions_users;
    this.setState({
      open_actor_form: true,
      selected_acting: acting,
      selected_acting_type: type,
      actor_positions_users: actor_positions_users
    });
  }

  handleSelectActor(row, isSelected, e){
    var { document, selected_acting, selected_acting_type } = this.state;
    
    if(selected_acting_type === "signee"){
      let signees = [...document.signees, {position_id: row.position_id, user_id: row.user_id, position_name: row.position_name}];
      signees = _.reject(signees, { user_id: selected_acting.attributes.user.id })
      document.signees = signees;
    } 

    if(selected_acting_type === "through"){
      let throughs = [...document.throughs, {position_id: row.position_id, user_id: row.user_id, position_name: row.position_name}];
      throughs = _.reject(throughs, { user_id: selected_acting.attributes.user.id })
      document.throughs = throughs;
    }

    if(selected_acting_type === "number_issuer"){
      document.number_issuer_id = row.id;
    }

    this.setState({
      document: document,
      open_actor_form: false,
      selected_acting_type: "",
      actor_positions_users: []
    });
  }

  handleCloseActorForm(){
    this.setState({
      open_actor_form: false,
      selected_acting_type: "",
      actor_positions_users: []
    });
  }

  handleUpdate(field_name, value){
      console.log('field_name --->', field_name)
      console.log('value --->', value)
      var { document } = this.state;
      document[field_name] = value;

      this.setState({
        document: document
      });
  }

  handleChangeSigneePositionName(signee, mode, position_name){
    var { document } = this.state;
    var signees = document.signees;
    var throughs = document.throughs;
    var s = null;

    if (mode === "signee"){
      s = _.find(signees, {user_id: signee.user_id, position_id: signee.position_id})
    }

    if (mode === "through"){
      s = _.find(throughs, {user_id: signee.user_id, position_id: signee.position_id})
    }

    if (!_.isEmpty(s)) {
      s.position_name = position_name
    }

    this.setState({
      document: document
    });
  }

  handleNewPage(){
    var { document_attributes } = this.state;
    const contents_size = _.size(document_attributes.contents);

    const page_content = {
      page_number: (contents_size + 1),
      title: "",
      name: "content",
      can_inline_edit: true,
      suggest_text: "เขียนเนื้อความ",
      value: ""
    }
    document_attributes.contents = _.concat(document_attributes.contents, page_content)
    console.log('contents size', document_attributes.contents)

    this.setState({document_attributes: document_attributes});
  }

  onDeletePage(page_number){
    var { document_attributes } = this.state;

    _.remove(document_attributes.contents, function(content){
      return parseInt(content.page_number) === parseInt(page_number)
    })

    if (_.size(document_attributes.contents) > 1) {
      // Re-order content's page number
      _.forEach(document_attributes.contents, function(value, index) {
        value.page_number = index + 1
      });
    }

    this.setState({document_attributes: document_attributes});
  }

  render() {
    var document_template = this.props.document_template;
    return (
      <div id='Document' className='px-3 py-5'>
        {this.state.loading && <AfterSaveLoading />}

        <div className='row'>
          <DocumentForm
            is_front_document={true}
            document_template={document_template}
            document_attributes={this.state.document_attributes}
            document={this.state.document}
            all_users={this.state.all_users}
            internal_users={this.state.internal_users}
            internal_number_issuers={this.state.internal_number_issuers}
            number_issuers={this.state.number_issuers}
            department_groups={this.state.department_groups}
            informees={this.state.informees}
            reference_documents={this.state.reference_documents}
            actings={this.state.actings}
            current_user_departments={this.state.current_user_departments}
            handleUpdate={this.handleUpdate}
            handleChangeEndDate={this.handleChangeEndDate}
            handleChangeAttributeValue={this.handleChangeAttributeValue}
            onDeleteRefDoc={this.onDeleteRefDoc}
            handleUpdateFiles={this.handleUpdateFiles}
            onDeleteFile={this.onDeleteFile}
            handleDeleteSignee={this.handleDeleteSignee}
            handleDeleteThrough={this.handleDeleteThrough}
            onShowActorsForm={this.handleShowActorsForm} />

          <DocumentBody 
            document_template={document_template}
            document={this.state.document}
            document_attributes={this.state.document_attributes}
            all_users={this.state.all_users}
            number_issuers={this.state.number_issuers}
            reference_documents={this.state.reference_documents}
            loading={this.state.loading}
            handleChangeAttributeValue={this.handleChangeAttributeValue}
            handleChangeContent={this.handleChangeContent}
            handleChange={this.handleChangeSigneePositionName}
            onDeletePage={this.onDeletePage} />

          <ActorForm  actor_positions_users={this.state.actor_positions_users}
                      open={this.state.open_actor_form}
                      onSelectActor={this.handleSelectActor}
                      onClose={this.handleCloseActorForm} />
        </div>
        <FooterButtons 
          document_template={document_template}
          handleSave={this.handleSave} 
          handleNewPage={this.handleNewPage} />
      </div>
    )
  }
}