import React, { Component } from 'react';

export default class DataMapping extends Component {

	render(){
		return (
			<div>
				<div className='row form-group'>
					<div className='col-12'>
						<h4>Data Mapping</h4>
					</div>
				</div>
			</div>
		)
	}
}