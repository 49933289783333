import React, {Component} from 'react';
import _ from 'lodash';

import { MergeAttributes } from '../../FlatData';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

const Informees = 'informees';
const InformGroups = 'inform_groups';

export default class SelectMultipleInformee extends Component {
  constructor(props){
    super(props)

    this.state = {
      selectedInformees: [],
      selectedInformGroups: [],
      activeMode: Informees
    }

    this.onSelectInformee = this.onSelectInformee.bind(this);
    this.onSelectInformGroup = this.onSelectInformGroup.bind(this);
    this.informeeFormat = this.informeeFormat.bind(this);
    this.handleSetValue = this.handleSetValue.bind(this);
    this.renderInformees = this.renderInformees.bind(this);
    this.renderInformGroups = this.renderInformGroups.bind(this);
    this.showInformees = this.showInformees.bind(this);
  }

  informeeFormat(cell, row){
    return (
      <div style={{whiteSpace: 'normal'}}>
        <span className='mr-2'>{row.name}</span>
        <span className='mr-2 text-success xs-small'>{row.user_name}</span>
      </div>
    )
  }

  onSelectInformee(row, isSelected, e) {
    let { selectedInformees } = this.state;
    const isIncluded = _.includes(selectedInformees, row.id);

    if (isIncluded){
      selectedInformees = _.pull(selectedInformees, row.id)
    }else{
      selectedInformees = _.union(selectedInformees, [row.id])
    }

    this.setState({ selectedInformees: selectedInformees });
  }

  onSelectInformGroup(row, isSelected, e) {
    let { selectedInformGroups } = this.state;
    const isIncluded = _.includes(selectedInformGroups, row.id);

    if (isIncluded){
      selectedInformGroups = _.pull(selectedInformGroups, row.id)
    }else{
      selectedInformGroups = _.union(selectedInformGroups, [row.id])
    }

    this.setState({ selectedInformGroups: selectedInformGroups });
  }

  handleSetValue(){
    let selectedInformeeIds = this.state.selectedInformees;

    {this.state.selectedInformGroups.forEach(informGroupId => {
      const informGroup = _.find(this.props.inform_groups, {id: informGroupId});
      
      selectedInformeeIds = _.concat(selectedInformeeIds, _.map(informGroup.informees, 'id'))
    })}

    selectedInformeeIds = _.uniq(selectedInformeeIds);
    this.props.onChange(selectedInformeeIds);
  }

  showInformees(cell, row){
    const informeeIds = _.map(cell, 'id');
    return(
      <div>
        {informeeIds.map(informeeId => {
          const informee = _.find(this.props.informees, {id: informeeId});
          return(
            <div key={informeeId} className="mb-2">
              {informee.name}
              <br />
              <span className="text-success xs-small">{informee.user_name}</span>
            </div>
          )
        })}
      </div>
    );
  }

  renderInformees(){
    const selectRowProp = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: this.onSelectInformee,
        selected: this.state.selectedInformees
    };

    const options = {
      sizePerPageList: []
    };

    var data = MergeAttributes(this.props.informees);

    return (
      <div>
        <BootstrapTable 
          data={data}
          search={ true }
          multiColumnSearch={ true }
          selectRow={ selectRowProp }
          options={ options }
          striped
          hover
          condensed
          pagination
          version='4'
          tableContainerClass={'table-sm'}>
            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn dataField='user_name' hidden></TableHeaderColumn>
            <TableHeaderColumn dataField='name' dataSort={ true } dataFormat={this.informeeFormat}>เรียน</TableHeaderColumn>
            <TableHeaderColumn dataField='executor_names' dataSort={ true }>ผู้ดำเนินการ</TableHeaderColumn>
        </BootstrapTable>

        <div className="modal-footer row">
          <button type='button' className="btn btn-primary pointer" onClick={this.handleSetValue}>ยืนยันที่เลือก</button>
          <button type='button' className="btn btn-secondary pointer" onClick={()=>this.props.onClose()}>ยกเลิก</button>
        </div>
      </div>
    )
  }

  renderInformGroups(){
    const selectRowProp = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: this.onSelectInformGroup,
        selected: this.state.selectedInformGroups
    };

    const options = {
      sizePerPageList: [],
      sizePerPage: 5
    };

    var data = MergeAttributes(this.props.inform_groups);

    return (
      <div>
        <BootstrapTable 
          data={data}
          search={ true }
          multiColumnSearch={ true }
          selectRow={ selectRowProp }
          options={ options }
          striped
          hover
          condensed
          pagination
          version='4'
          tableContainerClass={'table-sm'}>
            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn dataField='name' dataSort={ true }>กลุ่มเรียน</TableHeaderColumn>
            <TableHeaderColumn dataField='informees' dataFormat={this.showInformees} dataSort={ true }>ตั้งค่าเรียน</TableHeaderColumn>
        </BootstrapTable>

        <div className="modal-footer row">
          <button type='button' className="btn btn-primary pointer" onClick={this.handleSetValue}>ยืนยันที่เลือก</button>
          <button type='button' className="btn btn-secondary pointer" onClick={()=>this.props.onClose()}>ยกเลิก</button>
        </div>
      </div>
    )
  }

  render(){
    return (
      <div>
        <ul className="nav nav-tabs" id="Informees" role="tablist">
            <li className="nav-item">
            <span className={`nav-link pointer ${(this.state.activeMode === Informees) && 'active'}`} 
            href='#'
            onClick={()=>this.setState({activeMode: Informees})}>
              เรียน
            </span>
            </li>
            <li className="nav-item">
            <span className={`nav-link pointer ${(this.state.activeMode === InformGroups) && 'active'}`} 
            href="#"
            onClick={()=>this.setState({activeMode: InformGroups})}>
              กลุ่มเรียน
            </span>
            </li>
        </ul>
        <div className="tab-content mt-3" id="InformGroups">
            <div className={`tab-pane fade ${(this.state.activeMode === Informees) && 'show active'}`}>
              {this.renderInformees()}
            </div>
            <div className={`tab-pane fade ${(this.state.activeMode === InformGroups) && 'show active'}`}>
              {this.renderInformGroups()}
            </div>
        </div>
      </div>
    )
  }
}