import React, {Component} from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import moment from 'moment';
import qs from 'qs';
import $ from "jquery";

import { speedLevelList, secretLevelList } from '../../utils/documentType';
import AfterSaveLoading from '../../components/AfterSaveLoading';

import DocumentStore from '../../stores/DocumentStore';
import IssueNumberStore from '../../stores/IssueNumberStore';
import DocumentParameters from '../../components/documents/DocumentParameters';
import ReceiveDocumentForm from '../../components/receive_documents/ReceiveDocumentForm';
import SetNextIssueNumber from '../../components/SetNextIssueNumber';

const NormalLevel = 'ปกติ';

export default class SendDocumentPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      open: false,
      is_send: true,
      document_template_id: '',
      has_front_page: false,
      due_date: moment(),
      government_service: '',
      issue_number: '',
      origin_issue_number_id: '',
      topic: '',
      owner: '',
      is_circular: false,
      execute: '',
      keywords: '',
      attachment: '',
      document_date: moment(),
      speed_level: NormalLevel,
      secret_level: NormalLevel,
      original_document_files: [],
      inform_text: '',
      selected_group_ids: [],
      external_department: '',
      files: [],
      document_templates: [],
      department_groups: [],
      reference_issue_numbers: "",
      next_issue_number: "",
      next_full_issue_number: "",
      number_book_name: "ทั่วไป",
      root_group: {},
      all_groups: [],
      position_name_list: [],
      saved_topics: [],
      saved_from_departments: [],
      is_custom: false
    }

    this.renderDocumentTemplateOption = this.renderDocumentTemplateOption.bind(this);
    this.handleChangeReceiveDate = this.handleChangeReceiveDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDocumentTemplate = this.handleChangeDocumentTemplate.bind(this);
    this.handleChangeDueDate = this.handleChangeDueDate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeOriginalDoc = this.handleChangeOriginalDoc.bind(this);
    this.handleUpdateFiles = this.handleUpdateFiles.bind(this);
    this.onDeleteFile = this.onDeleteFile.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onRemoveOriginalDocument = this.onRemoveOriginalDocument.bind(this);
    this.handleInsert = this.handleInsert.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
    this.getNextIssueNumber = this.getNextIssueNumber.bind(this);
    this.presetIssueNumber = this.presetIssueNumber.bind(this);
    this.handleChangeSpeedLevel = this.handleChangeSpeedLevel.bind(this);
    this.handleChangeSecretLevel = this.handleChangeSecretLevel.bind(this);
    this.handleChangeInformText = this.handleChangeInformText.bind(this);
    this.handleChangeFileFromScanner = this.handleChangeFileFromScanner.bind(this);
    this.handleChangeIssueNumber = this.handleChangeIssueNumber.bind(this);
    this.handleChangeCircular = this.handleChangeCircular.bind(this);
    this.handleChangeSavedField = this.handleChangeSavedField.bind(this);

    this.batchSelectGroups = this.batchSelectGroups.bind(this);
    this.batchRemoveGroups = this.batchRemoveGroups.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.handleChangeGroup = this.handleChangeGroup.bind(this);
    this.removeGroup = this.removeGroup.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);

    this.handleUpdatedNextIssueNumber = this.handleUpdatedNextIssueNumber.bind(this);
  }

  componentDidMount(){
    DocumentStore.request({action: 'new_send'}).then((resp)=>{
      const document_template_id = _.get(resp.data, 'included.document_template.id', '');
      const department_groups = _.get(resp.data, 'included.department_groups', []);
      const document_templates = _.get(resp.data, 'included.document_templates', []);
      const root_group = _.get(resp.data, 'included.root_group', {});
      const all_groups = _.get(resp.data, 'included.all_groups', []);
      const position_name_list = _.get(resp.data, 'included.position_name_list', []);
      const saved_topics = _.get(resp.data, 'included.saved_topics', []);
      const saved_from_departments = _.get(resp.data, 'included.saved_from_departments', []);

      this.setState({
        document_template_id: document_template_id,
        document_templates: document_templates,
        department_groups: department_groups,
        root_group: root_group,
        all_groups: all_groups,
        position_name_list: position_name_list,
        saved_topics: saved_topics,
        saved_from_departments: saved_from_departments
      })
    }).then(()=>{
      this.getNextIssueNumber();
    });
  }

  onKeyPress(e){
    if(e.which === 13) {
      this.handleSave();
    }
  }

  getNextIssueNumber(){
    let url = "issue_numbers/get_next_issue_number";
    url += `?document_template_id=${this.state.document_template_id}`

    IssueNumberStore.request({url: url}).then((resp)=>{
      const next_issue_number = _.get(resp.data, 'data.next_number', "");
      const next_full_issue_number = _.get(resp.data, 'data.next_full_issue_number', "");
      
      this.setState({
        next_issue_number: next_issue_number,
        next_full_issue_number: next_full_issue_number,
        issue_number: next_full_issue_number
      })
    }).then(()=>{
      this.presetIssueNumber();
    }).then(()=>{
      this.setState({loading: false, isDataFetched: true});
    });
  }

  batchSelectGroups(department_id){
    const department = _.find(this.state.all_groups, { id: department_id });
    const group_ids = _.map(department.attributes.children, 'id');
    const selected_group_ids = _.concat(this.state.selected_group_ids, group_ids);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  batchRemoveGroups(department_id){
    const department = _.find(this.state.all_groups, { id: department_id });
    const group_ids = _.map(department.attributes.children, 'id');
    const selected_group_ids = _.pullAll(this.state.selected_group_ids, group_ids);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  selectGroup(group_id){
    const selected_group_ids = _.concat(this.state.selected_group_ids, group_id);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  removeGroup(group_id){
    const selected_group_ids = _.pull(this.state.selected_group_ids, group_id);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  handleChangeGroup(event){
    const field_name = event.target.name;
    const value = event.target.value;

    this.setState({
      [field_name]: value
    })
  }

  // Use department/unit issue number in paramters as preset issue number
  // Do nothing if params issue number is null
  presetIssueNumber(){
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    
    if(query.number_book_name){
      const selected_document_template = _.find(this.state.document_templates, function(dt){ return dt.attributes.name === query.number_book_name });
      const document_template_id = _.get(selected_document_template, "id", "");
      this.setState({document_template_id: document_template_id});
    }

    if(query.id){
      IssueNumberStore.request({id: query.id}).then((resp)=>{
        const issue_number = _.get(resp.data, 'data', []);
        const doc = _.get(resp.data, 'included.document', []);

        let number = issue_number.attributes.number;

        // ตัดประเภทหนังสือด้านหน้าออก
        number = _.replace(number, /^ทป\s{1}/, "");
        number = _.replace(number, /^คส\s{1}/, "");
        number = _.replace(number, /^รับรอง\s{1}/, "");

        this.setState({
          due_date: moment(doc.attributes.due_date),
          government_service: _.get(doc.attributes, "document_attributes.government_service.value", ""),
          issue_number: number,
          origin_issue_number_id: query.id,
          next_full_issue_number: number,
          topic: doc.attributes.topic,
          owner: doc.attributes.owner,
          execute: doc.attributes.execute,
          keywords: doc.attributes.keywords,
          document_date: moment(doc.attributes.document_date),
          speed_level: doc.attributes.speed_level,
          secret_level: doc.attributes.secret_level,
          inform_text: doc.attributes.informs[0].name,
          is_custom: true
        });
      });
    }
  }

  onSortEnd({oldIndex, newIndex}){
    this.setState(({original_document_files}) => ({
      original_document_files: arrayMove(this.state.original_document_files, oldIndex, newIndex),
    }));
  }

  handleSave(){
    const self = this;

    if (_.isEmpty(this.state.selected_group_ids) && _.isEmpty(this.state.external_department)){
      alert('กรุณาระบุหน่วยงานปลายทาง')
      return
    }

    this.setState({ loading: true });

    var data = new FormData();
    const { document_template_id, document_templates } = this.state;
    const document_template = _.find(document_templates, function(t) { return t.id === document_template_id; });
    var document_attributes = _.get(document_template, 'attributes.template_attributes', {});

    document_attributes.government_service.value = this.state.government_service;
    document_attributes.topic.value = this.state.topic;
    document_attributes.issue_number.value = this.state.issue_number;

    let attachment_obj = {
      attachment: {
        title: 'สิ่งที่ส่งมาด้วย',
        name: 'attachment',
        can_inline_edit: false,
        suggest_text: '',
        value: this.state.attachment
      }
    }
    _.assign(document_attributes, attachment_obj);
    delete(document_attributes.content);
    
    if (!_.isEmpty(document_attributes)){
      _.forEach(document_attributes, function(document_attr, key){
        data.append(`document_attributes[${key}][title]`, document_attr['title']);
        data.append(`document_attributes[${key}][name]`, document_attr['name']);
        data.append(`document_attributes[${key}][can_inline_edit]`, document_attr['can_inline_edit']);
        data.append(`document_attributes[${key}][suggest_text]`, document_attr['suggest_text']);
        data.append(`document_attributes[${key}][value]`, document_attr['value']);
      })
    }

    data.append('document[due_date]', this.state.due_date || '');
    data.append('document[is_circular]', this.state.is_circular || '');
    data.append('document[has_front_page]', this.state.has_front_page || false);
    data.append('document[execute]', this.state.execute || '');
    data.append('document[keywords]', this.state.keywords || '');
    data.append('document[document_date]', this.state.document_date || '');
    data.append('document[speed_level]', this.state.speed_level || '');
    data.append('document[secret_level]', this.state.secret_level || '');
    data.append('document[is_external_receive]', false);
    data.append('document[is_internal_receive]', false);
    data.append('document[document_template_id]', this.state.document_template_id);
    data.append('document[issue_number]', this.state.issue_number || '');
    data.append('document[origin_issue_number_id]', this.state.origin_issue_number_id || '');
    data.append('document[owner]', this.state.owner || '');
    data.append('document[reference_issue_numbers]', this.state.reference_issue_numbers || '');
    
    data.append('inform_text', this.state.inform_text || '');
    data.append('external_department', this.state.external_department || '');

    data.append('is_custom', this.state.is_custom);

    if (!_.isEmpty(this.state.selected_group_ids)){
      _.forEach(this.state.selected_group_ids, function(group_id){
        if (_.isEmpty(group_id)) { return }
        data.append('selected_group_ids[]', group_id);
      })
    }

    if (!_.isEmpty(this.state.original_document_files)){
      _.forEach(this.state.original_document_files, function(file){
        if (file.file){
          data.append('original_document_files[][id]', file['id'] || '');
          data.append('original_document_files[][file]', file.file);
        }
      })
    }

    if (!_.isEmpty(this.state.files)){
      _.forEach(this.state.files, function(file){
        if (!_.isEmpty(file)){
          data.append('attachments[][id]', file['id'] || '');
          data.append('attachments[][file]', file.file);
        }
      })
    }

    DocumentStore.request({
      method: 'post',
      action: 'create_document',
      data: data
    }).then(()=>{
      window.location.href = "/send_documents/send_all";
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      error_message = error_message ? error_message : 'มีปัญหาการเชื่อมต่อเครือข่าย';
      alert(error_message);
      self.setState({ loading: false });
      if (error_message == 'มีปัญหาการเชื่อมต่อเครือข่าย')
      {
        window.location.href = "/send_documents/send_all";
      }
    });
  }

  handleChangeReceiveDate(date){
    this.setState({document_date: date});
  }

  handleChangeDueDate(date){
    this.setState({due_date: date});
  }

  handleChangeSpeedLevel(speed_level){
    this.setState({speed_level: speed_level});
  }

  handleChangeSecretLevel(secret_level){
    this.setState({secret_level: secret_level});
  }

  handleChange(event){
    const field_name = event.target.name;
    var value = event.target.value;

    if (_.includes(['is_circular', 'has_front_page'], field_name)){
      value = event.target.checked;
    }

    this.setState({
      [field_name]: value
    })
  }

  handleChangeDocumentTemplate(event){
    const value = event.target.value;
    const document_template_name = _.find(this.state.document_templates, { id: value }).attributes.name;

    this.setState({
      document_template_id: value,
      number_book_name: document_template_name
    }, () => {
      this.getNextIssueNumber();
    })
  }

  handleChangeOriginalDoc(event){
    var original_file = event.target.files[0];
    if (original_file.size === 0) { return }
    if (original_file.type !== "application/pdf"){
      alert("แนบไฟล์-เอกสารต้นฉบับ จำกัดประเภทเฉพาะไฟล์นามสกุล .pdf");
      return
    }

    const new_file = {
      file: original_file
    }

    let { original_document_files } = this.state;

    original_document_files = _.concat(original_document_files, [new_file]);

    this.setState({
      original_document_files: original_document_files
    });
  }

  handleInsert(files){
    this.setState({
      original_document_files: files
    });
  }

  handleReplace(files){
    this.setState({
      original_document_files: files
    });
  }

  onRemoveOriginalDocument(index){
    let { original_document_files } = this.state;
    original_document_files.splice(index, 1);

    this.setState({
      original_document_files: original_document_files
    });
  }

  onClose(){
    this.setState({open: false});
  }

  handleChangeInformText(value){
    this.setState({inform_text: value});
  }

  handleUpdateFiles(new_files){
    if (_.isEmpty(new_files)) { return }

    var { files } = this.state;
    _.map(new_files, function(file, index){
      if (!_.isEmpty(file)){
        files = _.concat(files, file);
      }
    })
    
    this.setState({
      files: files
    });
  }

  onDeleteFile(index){
      if (!window.confirm('ยืนยันการลบไฟล์')) { return }
        
      var { files } = this.state;
      files.splice(index, 1);

      this.setState({
        files: files
      });
  }

  urltoFile(url, filename, mimeType){
    return (fetch(url)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], filename, {type:mimeType});})
    );
  }

  handleChangeFileFromScanner(event){
    let self = this;

    const filename = event.target.getAttribute('data-filename');

    this.urltoFile(event.target.value, filename, 'application/pdf')
    .then(function(file){
        const new_file = { file: file };
        let { original_document_files } = self.state;
        original_document_files = _.concat(original_document_files, [new_file]);

        self.setState({
          original_document_files: original_document_files
        });
    })
  }

  handleChangeIssueNumber(event){
    this.setState({
      issue_number: event.target.value,
      next_full_issue_number: event.target.value,
      is_custom: false
    });
  }

  handleUpdatedNextIssueNumber(next_issue_number, next_full_issue_number){
    this.setState({
      next_issue_number: next_issue_number,
      next_full_issue_number: next_full_issue_number,
      issue_number: next_full_issue_number,
      is_custom: false
    }, function(){
      $("#collapseSetNextIssueNumberTrigger").trigger("click");
    });
  }

  handleChangeCircular(event){
    const document_template_id = this.state.document_template_id;
    const document_template_name = _.find(this.state.document_templates, { id: document_template_id }).attributes.name;
    let issue_number = this.state.issue_number.split('/');

    switch(document_template_name) {
    case "คำสั่ง":
    case "รับรอง":
      if(event.target.checked){
        issue_number = "ว" + issue_number[0] + '/' + issue_number[1];
      } else {
        issue_number = issue_number[0].replace(/^ว/, "") + '/' + issue_number[1];
      }
      break;
    default:
      if(event.target.checked){
        issue_number = issue_number[0] + '/' + "ว" + issue_number[1];
      } else {
        issue_number = issue_number[0] + '/' + issue_number[1].replace(/^ว/, "");
      }
    }

    this.setState({
      is_circular: event.target.checked,
      issue_number: issue_number,
      next_full_issue_number: issue_number
    });
  }


  handleChangeSavedField(field, value){
    this.setState({ 
      [field]: value
     });
  }

  renderDocumentTemplateOption(document_template, index){
    return (
      <option key={document_template.id} value={document_template.id}>
        {document_template.attributes.name}
      </option>
    )
  }

  render(){
    let { document_template_id, document_templates, next_full_issue_number } = this.state;
    const document_template = _.find(document_templates, function(t) { return t.id === document_template_id; });

    const informs = [{name: this.state.inform_text}];
    return (
      <div>
        {this.state.loading && <AfterSaveLoading />}  

        <SetNextIssueNumber 
          label={"ทะเบียนส่งภายใน"}
          next_issue_number={this.state.next_issue_number}
          number_book_name={this.state.number_book_name}
          onUpdatedNextIssueNumber={this.handleUpdatedNextIssueNumber} />
  
        <div className={`row mb-2 ${!_.isEmpty(this.props.location.search) && 'd-none'}`}>
          <div className='col-12 col-md-2'><b>ประเภทหนังสือ</b></div>
          <div className='col-12 col-md-4'>
            <select 
              value={document_template_id}
              name='document_template_id'
              className='form-control form-control-sm'
              onChange={this.handleChangeDocumentTemplate}>
              {_.map(document_templates, this.renderDocumentTemplateOption)}
            </select>
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-2'><b>ที่</b></div>
          <div className='col-12 col-md-4'>

          <input type="text" value={next_full_issue_number} onChange={this.handleChangeIssueNumber} className="form-control form-control-sm" readOnly={this.state.is_custom} />

          </div>
        </div>

        <ReceiveDocumentForm 
          document_template={document_template}
          handleChangeInformText={this.handleChangeInformText}
          handleChange={this.handleChange}
          speedLevelList={speedLevelList}
          secretLevelList={secretLevelList}
          handleChangeOriginalDoc={this.handleChangeOriginalDoc}
          onRemoveOriginalDocument={this.onRemoveOriginalDocument}
          handleInsert={this.handleInsert}
          handleReplace={this.handleReplace}
          onSortEnd={this.onSortEnd}
          handleUpdateFiles={this.handleUpdateFiles}
          onDeleteFile={this.onDeleteFile}
          handleChangeSpeedLevel={this.handleChangeSpeedLevel}
          handleChangeSecretLevel={this.handleChangeSecretLevel}
          handleChangeDueDate={this.handleChangeDueDate}
          handleChangeReceiveDate={this.handleChangeReceiveDate}
          handleChangeCircular={this.handleChangeCircular}
          batchSelectGroups={this.batchSelectGroups}
          batchRemoveGroups={this.batchRemoveGroups}
          selectGroup={this.selectGroup}
          handleChangeGroup={this.handleChangeGroup}
          handleChangeFileFromScanner={this.handleChangeFileFromScanner}
          removeGroup={this.removeGroup}
          onClose={this.onClose}
          informs={informs}
          onKeyPress={this.onKeyPress}
          handleChangeSavedField={this.handleChangeSavedField}
          {...this.state} />

        <div className='row py-3'>
          <div className='col-12'>
            <button type='button' className='btn btn-primary mr-1 pointer' onClick={this.handleSave}>ส่งหนังสือ</button>
            <Link to='/send_documents/send_all' className='btn btn-secondary'>ยกเลิก</Link>
          </div>
        </div>
      </div>
    )
  }
}