import React, { Component } from 'react';
import _ from 'lodash';
import Permission from '../../components/Permission';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';
import AfterSaveLoading from '../../components/AfterSaveLoading';
import SimpleModal from '../../components/SimpleModal';

import InformGroupStore from '../../stores/InformGroupStore';

import Form from '../../components/InformGroup/Form';

class InformGroupsSettingPage extends Component {
  constructor(){
    super();

    this.state = {
      loading: false,
      isDataFetched: false,
      informGroups: [],
      informees: [],
      modalOpen: false,
      modalTitle: "",
      edittingInformGroup: {},
      errorMessage: ""
    }

    this.reloadData = this.reloadData.bind(this);
    this.renderRowActions = this.renderRowActions.bind(this);
    this.newRow = this.newRow.bind(this);
    this.editRow = this.editRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showInformees = this.showInformees.bind(this);
  }

  componentWillMount(){
    if (Permission.canEditDepartmentGroup()) {
      this.reloadData();
    }else{
      window.location.href = '/receive_documents/mydoc';
    }
  }

  reloadData(){
    InformGroupStore.request({}).then((resp)=>{
      let informGroups = _.get(resp.data, 'data', []);
      let informees = _.get(resp.data, 'included.informees', []);

      this.setState({
        informGroups: informGroups,
        informees: informees
      })
    }).then(()=>{
      this.setState({isDataFetched: true, loading: false});
    })
  }

  newRow(){
    this.setState({
      modalOpen: true,
      modalTitle: "เพิ่มเรียน",
    });
  }

  editRow(id){
    const informGroup = _.find(this.state.informGroups, { id: id })

    this.setState({
      modalOpen: true,
      modalTitle: "แก้ไขกลุ่มเรียน",
      edittingInformGroup: informGroup
    });
  }

  deleteRow(id){
    if(window.confirm("ยืนยันการลบกลุ่มเรียน?")){
      let self = this;

      InformGroupStore.delete(id).then(()=>{
        this.handleCloseAndReloadData()
      }, function(error){
        let errorMessage = _.get(error.response.data, 'error.message', '');
        self.setState({errorMessage: errorMessage});
      });
    }
  }

  onClose(){
    this.setState({
      modalOpen: false,
      modalTitle: "",
      edittingInformGroup: {}
    });
  }

  handleSubmit(inform_group){
    let self = this;
    
    const id = inform_group.id;
    if(id === null){
      inform_group = _.omit(inform_group, ['id']);
    }

    this.setState({ errorMessage: null });

    InformGroupStore.save(id, {
      inform_group: inform_group
    }).then(()=>{
      this.handleCloseAndReloadData()
    }, function(error){
      let errorMessage = _.get(error.response.data, 'error.message', '');
      self.setState({errorMessage: errorMessage});
    });
  }

  handleCloseAndReloadData(){
    this.setState(
      {
        loading: true,
        modalOpen: false,
        modalTitle: "",
        edittingInformGroup: {}
      }, 
      this.reloadData()
    );
  }

  renderRowActions(cell, row){
    return (
      <div>
        <button className="btn btn-sm btn-success" onClick={() => this.editRow(cell)}>
          <i className="fa fa-cog"></i>{' '}แก้ไข
        </button>
        {' '}
        <button className="btn btn-sm btn-danger" onClick={() => this.deleteRow(cell)}>
          <i className="fa fa-trash"></i>{' '}ลบ
        </button>
      </div>
    )
  }

  _setTableOption(){ 
      if(this.state.isDataFetched){
            return "Not found";
        }else{
            return <Loading />
        }
  }

  showInformees(cell, row){
    const informeeIds = _.map(cell, 'id');
    return(
      <div>
        {informeeIds.map(informeeId => {
          const informee = _.find(this.state.informees, {id: informeeId});
          return(
            <div key={informeeId} className="mb-2">
              {informee.name}
              <br />
              <span className="text-success xs-small">{informee.user_name}</span>
            </div>
          )
        })}
      </div>
    );
  }

  render(){
    const options = {
      noDataText: this._setTableOption(),
      sizePerPageList: []
    };
    let data = MergeAttributes(this.state.informGroups);

    return (
      <div id="department-groups">
        {this.state.loading && <AfterSaveLoading />}

        <div className="row mb-2">
          <div className="col-6">
            <h2>ตั้งค่ากลุ่มเรียน</h2>
          </div>
          <div className="col-6 text-right">
            <button className='btn btn-success' onClick={() => this.newRow()}>
              <span>สร้างใหม่</span>
            </button>
          </div>
        </div>
        
        <div className="row">
          <div className="col-12 rui-inform-group-setting">
            <BootstrapTable 
              data={data}
              search={ true }
              multiColumnSearch={ true }
              options={ options }
              striped
              hover
              pagination
              version='4'
              tableContainerClass={'table-sm'}>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='name' width='200' dataSort={ true }>กลุ่มเรียน</TableHeaderColumn>
              <TableHeaderColumn dataField='informees' dataFormat={this.showInformees} dataSort={ true }>ตั้งค่าเรียน</TableHeaderColumn>
              <TableHeaderColumn  dataField='id'
                                  width='150' 
                                  dataFormat={this.renderRowActions}>
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>

        <SimpleModal 
          isOpen={this.state.modalOpen}
          title={this.state.modalTitle}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onClose}>
            <Form informGroup={this.state.edittingInformGroup} 
                  informees={this.state.informees}
                  onClose={this.onClose} 
                  onSubmit={this.handleSubmit}
                  errorMessage={this.state.errorMessage} />
        </SimpleModal>
      </div>
    );
  }
}

export default InformGroupsSettingPage;
