import React, { Component } from 'react';
import _ from 'lodash';

import DataForm from './DataForm';
import SelectPositions from './SelectPositions';
import PropertyForm from './PropertyForm';
import Images from './Images';

import UserStore from '../../../stores/UserStore';

import { userVariables } from '../../Variable';
import ErrorMessage from '../../ErrorMessage';

const dataMode = 'data';
const positionMode = 'position';
const propertyMode = 'property';
const signatureMode = 'signature';

const AuthenTypes = ["Local DB", "AD", "Webservice"];
const Genders = ["Male", "Female"];
const Status = ["Active", "Inactive"];

class UserForm extends Component {
	constructor(props){
		super(props)

		this.state = {
			activeMode: dataMode,
			authen_types: AuthenTypes,
			genders: Genders,
			status: Status,
			positions: [],
			user_properties: [],
			user: userVariables,
			user_data: {},
			error_message: ''
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSelectPositions = this.handleSelectPositions.bind(this);
		this.handleDeletePosition = this.handleDeletePosition.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.loadData = this.loadData.bind(this);
	}

	componentWillMount(){
		if (!_.isEmpty(this.props.editingId)) {
			this.loadData(this.props.editingId);
		}else{
			UserStore.request({action: 'new'}).then((resp)=>{
				this.setStateForNew(resp);
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.open !== nextProps.open){
			this.setState({activeMode: dataMode});
		}

		if (this.props.editingId !== nextProps.editingId && !_.isEmpty(nextProps.editingId)){
			this.loadData(nextProps.editingId);
		}else{
			UserStore.request({action: 'new'}).then((resp)=>{
				this.setStateForNew(resp);
			});
		}
	}

	setStateForNew(resp){
		this.setState({
			user: userVariables,
			positions: [],
			user_properties: _.get(resp.data, 'included.user_properties', []),
			user_data: {},
			error_message: ''
		});
	}

	loadData(id){
		UserStore.request({id: id}).then((resp)=>{
			var user = resp.data.data;
			var lookups = _.get(resp.data, 'included', {});
			this.setState({
				user: {
					code: user.attributes.code,
					username: user.attributes.username,
					prefix_name: user.attributes.prefix_name,
					email: user.attributes.email,
					first_name: user.attributes.first_name,
					last_name: user.attributes.last_name,
					phone: user.attributes.phone,
					authentication_type: user.attributes.authentication_type,
					gender: user.attributes.gender,
					status: user.attributes.status,
					positions: user.attributes.positions,
					user_properties: user.attributes.user_properties,
					profile_image: user.attributes.profile_image,
					signature: user.attributes.signature,
					expired_date: user.attributes.expired_date
				},
				positions: lookups.positions,
				user_properties: lookups.user_properties,
				user_data: user,
				error_message: ''
			})
		})
	}

	handleChange(user){
		this.setState({user: user})
	}

	handleSelectPositions(position){
		var user = _.clone(this.state.user);
		var new_position = [{id: position.id, type: position.type}];
		user.positions = _.union(user.positions, new_position);

		var positions = _.clone(this.state.positions);
		positions = _.union(positions, [position])
		
		this.setState({
			user: user,
			positions: positions
		});
	}

	handleDeletePosition(position){
		var user = _.clone(this.state.user);
		_.remove(user.positions, {id: position.id});

		var positions = _.clone(this.state.positions);
		_.remove(positions, {id: position.id})

		this.setState({
			user: user,
			positions: positions
		});
	}

	handleSave(event){
		event.preventDefault();
		var self = this;

		var id = null;
		if (!_.isEmpty(this.props.editingId)){
			id = this.props.editingId;
		}
		var position_ids = _.map(this.state.user.positions, 'id');

		var data = new FormData();
		if (typeof(this.state.user.profile_image) !== 'string' && this.state.user.profile_image !== null && this.state.user.profile_image !== undefined){
			data.append('user[profile_image]', this.state.user.profile_image);
		}else{
			data.append('user[profile_image]', '');
		}
		if (typeof(this.state.user.signature) !== 'string' && this.state.user.signature !== null && this.state.user.signature !== undefined){
			data.append('user[signature]', this.state.user.signature);
		}else{
			data.append('user[signature]', '');
		}
		
		data.append('user[code]', this.state.user.code);
		data.append('user[username]', this.state.user.username);
		data.append('user[prefix_name]', this.state.user.prefix_name);
		data.append('user[email]', this.state.user.email);
		data.append('user[first_name]', this.state.user.first_name);
		data.append('user[last_name]', this.state.user.last_name);
		data.append('user[phone]', this.state.user.phone);
		data.append('user[authentication_type]', this.state.user.authentication_type);
		data.append('user[gender]', this.state.user.gender);
		data.append('user[status]', this.state.user.status);
		data.append('user[expired_date]', this.state.user.expired_date || '');

		if (!_.isEmpty(position_ids)){
			_.forEach(position_ids, function(position_id){
				data.append('user[position_ids][]', position_id);
			})
		}else{
			data.append('user[position_ids][]', []);
		}
		if (!_.isEmpty(this.state.user.user_properties)){
			_.forEach(this.state.user.user_properties, function(user_property){
				data.append('user[user_properties][][id]', user_property.id);
				data.append('user[user_properties][][value]', user_property.value);
			})
		}else{
			data.append('user[user_properties]', []);
		}

		UserStore.save(id, data).then(()=>{
			this.props.onCloseAndReloadData()
		}, function(error){
			var error_message = _.get(error.response.data, 'error.message', '');
			self.setState({error_message: error_message});
		})
	}

  	render(){
  		var positionSize = _.size(this.state.positions);
		return (
			<form onSubmit={this.handleSave}>
				{this.state.error_message && <ErrorMessage error_message={this.state.error_message} />}

				<ul className="nav nav-tabs nav-fill ml-0" id="ManageUser" role="tablist">
				  	<li className="nav-item">
						<span className={`nav-link pointer ${(this.state.activeMode === dataMode) && 'active'}`} 
						href='#'
						onClick={()=>this.setState({activeMode: dataMode})}>
							Data
						</span>
				  	</li>
				  	<li className="nav-item">
						<span className={`nav-link pointer ${(this.state.activeMode === positionMode) && 'active'}`} 
						href="#"
						onClick={()=>this.setState({activeMode: positionMode})}>
							Position
							{positionSize > 0 && (
				    			<em>&nbsp;({positionSize})</em>
				    		)}
						</span>
				  	</li>
				  	<li className="nav-item">
						<span className={`nav-link pointer ${(this.state.activeMode === propertyMode) && 'active'}`} 
						href="#"
						onClick={()=>this.setState({activeMode: propertyMode})}>
							Property
						</span>
				  	</li>
				  	<li className="nav-item">
						<span className={`nav-link pointer ${(this.state.activeMode === signatureMode) && 'active'}`} 
						href="#"
						onClick={()=>this.setState({activeMode: signatureMode})}>
							Image/Signature
						</span>
				  	</li>
				</ul>
				<div className="tab-content mt-4" id="ManageUserContent">
				  	<div className={`tab-pane fade ${(this.state.activeMode === dataMode) && 'show active'}`}>
				  		<DataForm 
				  			onChangeData={this.handleChange} 
				  			onClose={this.props.onClose} 
				  			{...this.state} />
				  	</div>
				  	<div className={`tab-pane fade ${(this.state.activeMode === positionMode) && 'show active'}`}>
				  		<SelectPositions 
				  			onChangeData={this.handleSelectPositions} 
				  			onClose={this.props.onClose} 
				  			open={this.props.open}
				  			onDelete={this.handleDeletePosition}
				  			{...this.state} />
				  	</div>
				  	<div className={`tab-pane fade ${(this.state.activeMode === propertyMode) && 'show active'}`}>
				  		<PropertyForm 
				  			onChangeData={this.handleChange}
				  			onClose={this.props.onClose}
				  			{...this.state} />
				  	</div>
				  	<div className={`tab-pane fade ${(this.state.activeMode === signatureMode) && 'show active'}`}>
				  		<Images 
				  			onChangeData={this.handleChange}
				  			onClose={this.props.onClose}
				  			open={this.props.open}
				  			{...this.state} />
				  	</div>
				</div>
			</form>
		)
	}
}

export default UserForm;