import React, { Component } from 'react';

export default class RenderPositionRow extends Component {
	constructor(props) {
		super(props)

		this.handleFocus = this.handleFocus.bind(this);
	}

	handleFocus(){
		this.props.onFocus(this.props.position);
	}

	render(){
		return (
			<li>
				<i className="fa fa-circle mini-icon blue-icon mr-3" aria-hidden="true"></i>
				<span className={`group-list pointer ${(this.props.focus.id === this.props.position.id) && 'focus-item'}`} onClick={this.handleFocus}>
					<i className="fa fa-user red-icon mr-3" aria-hidden="true"></i>
					<span className='mr-3'>{this.props.position.attributes.code}</span>
					<span className='mr-3'>{this.props.position.attributes.name}</span>
				</span>
			</li>
		)
	}
}