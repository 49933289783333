import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Link, withRouter } from 'react-router-dom';

import './stylesheets/DraftDocumentsPage.css';

import { draftTextFormatter, thaiDateFormatter } from '../../utils/bootstrapTableFormatters';
import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';

import WorkflowProcessStore from '../../stores/WorkflowProcessStore';
import StepFilter from '../../components/documents/filters/StepFilter';

class DraftDocumentsPage extends Component {
  constructor() {
    super()

    this.state = {
      workflow_processes: [],
      active_step: '',
      pagination: {},
      searchText: ''
    }

    this.redirectToEditDocumentPage = this.redirectToEditDocumentPage.bind(this);
    this.redirectToShowDocumentPage = this.redirectToShowDocumentPage.bind(this);
    this.handleChangeStep = this.handleChangeStep.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    const step = this.state.active_step;

    WorkflowProcessStore.request({url: `workflow_processes?type=draft&status=${step}&page=1`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  draftText(cell, row) {
    return (
      <div style={{whiteSpace: "normal"}}>Draft</div>
    )
  }

  dashFormatter(cell, row) {
    return (
      <div style={{whiteSpace: "normal"}}>-</div>
    )
  }

  createdAtFormatter(cell, row) {
    return (
      <div style={{whiteSpace: "normal"}}>{cell}</div>
    )
  }

  _setTableOption(){ 
      if(this.state.isDataFetched){
            return "ไม่มีข้อมูล";
        }else{
            return <Loading />
        }
  }

  redirectToEditDocumentPage(row, isSelected, e){
    this.props.history.push(`/templates/documents/${row.attributes.document.id}/edit/${row.attributes.document_template.id}`);
  }

  redirectToShowDocumentPage(row, isSelected, e){
    this.props.history.push(`/templates/documents/${row.attributes.document.id}/show/${row.attributes.document_template.id}/wp/${row.id}`);
  }

  handleChangeStep(step){
    this.setState({isDataFetched: false, workflow_processes: []});
    const { searchText } = this.state;

    WorkflowProcessStore.request({url: `workflow_processes?type=draft&status=${step}&page=1&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});
      
      this.setState({
        active_step: step,
        workflow_processes: workflow_processes,
        pagination: pagination,
        isDataFetched: true
      });
    });
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, workflow_processes: []});
    const { active_step, searchText } = this.state;

    WorkflowProcessStore.request({url: `workflow_processes?type=draft&status=${active_step}&page=${page}&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, workflow_processes: []});
    const step = this.state.active_step;

    WorkflowProcessStore.request({url: `workflow_processes?type=draft&status=${step}&page=1&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

	render(){
    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);
    const options = {
      sizePerPageList: [],
      sizePerPage: 10,
      page: active_page,
      noDataText: this._setTableOption(),
      onRowClick: this.redirectToShowDocumentPage,
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500
    };
    var data = [];
    if (!_.isEmpty(this.state.workflow_processes)){
      data = MergeAttributes(this.state.workflow_processes);
    }

  	return (
  		<div className="inbox">
        <div className='row'>
          <div className='col-7'>
            <h2>หนังสือร่าง</h2>
          </div>
          <div className='col-5 text-right d-none d-lg-block'>
            <Link to='/templates' className='btn btn-success'>ร่างเอกสาร</Link>
          </div>
        </div>

        <StepFilter active_step={this.state.active_step} handleChangeStep={this.handleChangeStep} />

        <div className='rui-draftdoc'>
          <BootstrapTable 
            data={data}
            remote={ true }
            fetchInfo={{dataTotalSize: total_items_count}}
            search={ true }
            multiColumnSearch={ true }
            options={ options }
            striped
            hover
            pagination
            version='4'
            tableContainerClass={'table-sm'}
            trClassName='td-white-space-normal'>
            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn dataFormat={ draftTextFormatter } width='150'>เลขที่</TableHeaderColumn>
            <TableHeaderColumn dataField='created_at' dataFormat={ thaiDateFormatter } width='140'>ลงวันที่</TableHeaderColumn>
            <TableHeaderColumn dataField='informee_name' columnTitle dataSort={ true }>เรียน</TableHeaderColumn>
            <TableHeaderColumn dataField='topic' columnTitle dataSort={ true }>เรื่อง</TableHeaderColumn>
            <TableHeaderColumn dataField='from_department' columnTitle dataSort={ true }>จาก</TableHeaderColumn>
            <TableHeaderColumn dataField='to_department' columnTitle dataSort={ true }>ถึง</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
  	)
	}
}

export default withRouter(DraftDocumentsPage);