import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../pages/documents/stylesheets/PositionsUserTable.css';

import { MergeAttributes } from './FlatData';

class PositionsUserTable extends Component {
  render(){
    const data = MergeAttributes(this.props.positions_users);

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      bgColor: 'darkseagreen',
      onSelect: this.props.onSelect
    };
    const options = {
      sizePerPageList: []
    };

    return(
      <div className="row">
        <div className="col-12 rui-positions-user-tb">
          <BootstrapTable 
            data={data}
            search={ true }
            multiColumnSearch={ true }
            selectRow={ selectRowProp }
            options={ options }
            striped
            hover
            condensed
            pagination
            version='4'
            tableContainerClass={'table-sm'}>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='position_name' dataSort={ true }>ผู้ถูกระบุส่งต่อ</TableHeaderColumn>
          </BootstrapTable>
        </div>
        
        {this.props.hideClose === undefined && (
          <div className="modal-footer col-12">
            <button type='button' className="btn btn-secondary pointer" onClick={this.props.onClose}>ยกเลิก</button>
          </div>
        )}
      </div>
    )
  }
}

export default PositionsUserTable;
