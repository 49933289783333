import React, {Component} from 'react';
import _ from 'lodash';

import SimpleModal from '../SimpleModal';

export default class InsertOriginalFiles extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this);
  }

  onChange(e){
    this.props.handleChnage(e.target.name, e.target.value);
  }

  render(){
    const { files } = this.props;
    return (
      <SimpleModal 
        isOpen={this.props.insert_file_open}
        title={'แทรกหน้า'}
        showSubmitButton={false}
        onClose={ this.props.onCloseModal }>
        <div className='row'>
          <div className='col-12'>
            <label>แทรกหน้าลำดับที่</label>
          </div>
          <div className='col-4'>
            <input 
              type='number' 
              name='insert_number'
              className='form-control form-control-sm'
              onChange={this.onChange} />
          </div>
          <div className='col-12 mt-3'>
            <label 
              className="btn btn-sm btn-primary pointer mb-0 mr-2">
              <i className="fa fa-upload mr-1" aria-hidden="true"></i>
              แทรกหน้า
              <input 
                type="file" 
                name='insert_original_file' 
                hidden
                onChange={this.props.handleUpload} />
            </label>
          </div>
          {!_.isEmpty(files) && (
            <div className='col-12 mt-3'>
              {!_.isEmpty(files) && _.map(files, function(file, index){
                return (
                  <div key={index}>{file.file.name}</div>
                )
              })}
            </div>
          )}
          <div className='col-12 mt-3'>
            <div className="modal-footer row">
              <button 
                type='button' 
                className="btn btn-primary pointer"
                onClick={this.props.handleMergeInsertFiles}>
                บันทึก
              </button>
              <button 
                type='button' 
                className="btn btn-secondary pointer" 
                onClick={ this.props.onCloseModal }>
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </SimpleModal>
    )
  }
}