import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';

import NewUnitNumbers from '../../components/unit_numbers/NewUnitNumbers';
import ArchiveUnitNumbers from '../../components/unit_numbers/ArchiveUnitNumbers';
import DepartmentNumberList from '../../components/department_numbers/DepartmentNumberList';
import AfterSaveLoading from '../../components/AfterSaveLoading';
import ArchiveDepartmentNumberButton from '../../components/department_numbers/form/ArchiveDepartmentNumberButton';
import { SpeedLevelClassName } from '../../utils/Helper';

import WorkflowProcessStore from '../../stores/WorkflowProcessStore';

const newDocumentMode = 'new';
const archiveDocumentMode = 'archive';

export default class UnitNumberPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeMode: newDocumentMode
    }

    this.issueNumberFormatter = this.issueNumberFormatter.bind(this);
    this.documentDetailsFormatter = this.documentDetailsFormatter.bind(this)
    this.numbersFormatter = this.numbersFormatter.bind(this)
    this.redirectToShowDocumentPage = this.redirectToShowDocumentPage.bind(this);
    this.handleArchiveUnitNumber = this.handleArchiveUnitNumber.bind(this);
  }

  issueNumberFormatter(cell, row){
    return (
      <div>
        <div className='small text-link pointer' onClick={() => this.redirectToShowDocumentPage(cell, row)}>
          {row.prev_issue_number}
        </div>

        {row.attributes.speed_level !== 'ปกติ' && (
          <div className={`mt-2 text-${SpeedLevelClassName(row.attributes.speed_level)}`}>
            {row.attributes.speed_level}
          </div>
        )}
      </div>
    )
  }

  documentDetailsFormatter(cell, row){
    return (
      <div>
        <div className='row'>
          <div className='col-12 col-sm-2 col-md-1'><strong>จาก</strong></div>
          <div className='col-12 col-sm-10 col-md-11'>{row.from_department}</div>
        </div>
        <div className='row'>
          <div className='col-12 col-sm-2 col-md-1'><strong>เรียน</strong></div>
          <div className='col-12 col-sm-10 col-md-11'>{row.to_department}</div>
        </div>
        <div className='row'>
          <div className='col-12 col-sm-2 col-md-1'><strong>ลงวันที่</strong></div>
          <div className='col-12 col-sm-10 col-md-11'>
          {moment(row.created_at, "DD-MM-YYYY").format("DD/MM/YYYY")}</div>
        </div>
        <div className='row'>
          <div className='col-12 col-sm-2 col-md-1'><strong>เจ้าของเรื่อง</strong></div>
          <div className='col-12 col-sm-10 col-md-11'>{row.owner}</div>
        </div>
        <div className='row'>
          <div className='col-12 col-sm-2 col-md-1'><strong>เรื่อง</strong></div>
          <div className='col-12 col-sm-10 col-md-11'>{row.topic}</div>
        </div>
      </div>
    )
  }

  numbersFormatter(cell, row){
    return (
      <div>
        <DepartmentNumberList 
          title="เลขหน่วยงาน"
          issue_numbers={row.unit_issue_numbers} />

        {_.get(row, "attributes.status") !== "Archived" && (
          <ArchiveDepartmentNumberButton
            workflow_process_id={row.id}
            handleArchiveNumber={this.handleArchiveUnitNumber} />
        )}
      </div>
    )
  }

  redirectToShowDocumentPage(cell, row){
    this.props.history.push(`/unit_numbers/${row.document.id}/edit/wp/${cell}`);
  }

  handleArchiveUnitNumber(workflow_process_id){
    if (!window.confirm('ยันยันการเก็บเข้าแฟ้ม')) { return }

    const self = this;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      action: 'archive',
    }).then(()=>{
      window.location.href = "/unit_numbers/unit_numbers";
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    });
  }

  render(){
    return (
      <div>

        <div className='row'>
          <div className='col-12'>
            <h2>ออกเลขหน่วยงาน</h2>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>

            <ul className="nav nav-tabs nav-fill ml-0" id="IssueDepartmentNumber" role="tablist">
              <li className="nav-item">
                <span className={`nav-link pointer ${(this.state.activeMode === newDocumentMode) && 'active'}`} 
                  href='#'
                  onClick={()=>this.setState({activeMode: newDocumentMode})}>
                    หนังสือมาใหม่
                </span>
              </li>
              <li className="nav-item">
                <span className={`nav-link pointer ${(this.state.activeMode === archiveDocumentMode) && 'active'}`} 
                  href='#'
                  onClick={()=>this.setState({activeMode: archiveDocumentMode})}>
                  แฟ้มเก็บ
                </span>
              </li>
            </ul>
            <div className="tab-content mt-4" id="IssueDepartmentNumberContent">
              <div className={`tab-pane fade ${(this.state.activeMode === newDocumentMode) && 'show active'}`}>
                <NewUnitNumbers 
                  issueNumberFormatter={this.issueNumberFormatter}
                  documentDetailsFormatter={this.documentDetailsFormatter}
                  numbersFormatter={this.numbersFormatter} />
              </div>
              <div className={`tab-pane fade ${(this.state.activeMode === archiveDocumentMode) && 'show active'}`}>
                <ArchiveUnitNumbers 
                  issueNumberFormatter={this.issueNumberFormatter}
                  documentDetailsFormatter={this.documentDetailsFormatter}
                  numbersFormatter={this.numbersFormatter} />
              </div>
            </div>

          </div>
        </div>
        
      </div>
    )
  }
}
