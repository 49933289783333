import React, {Component} from 'react';
import _ from 'lodash';
import { MergeAttributes } from '../../FlatData';
import { issueNumberFormatter, thaiDateFormatter } from '../../../utils/bootstrapTableFormatters';
import { issueNumberFormat } from '../../../utils/index';


import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import SimpleModal from '../../SimpleModal';

const ChooseDoc = 'choose';
const SetDoc = 'set';
const EditingFieldName = 'hidden_ref';
const ReferencesFieldName = 'references';

export class ReferenceDocumentForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			open: false,
			activeMode: ChooseDoc,
			references: [
				{
					reference_text: '',
					document_id: ''
				}
			]
		}

		this.onOpen = this.onOpen.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.onRefAddDetail = this.onRefAddDetail.bind(this);
		this.renderRefDetail = this.renderRefDetail.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSetValue = this.handleSetValue.bind(this);
		this.handleChangeHiddenRef = this.handleChangeHiddenRef.bind(this);
	}

	onOpen(){
		this.setState({open: true});
	}

	onClose(){
		this.setState({open: false})
	}

	onSelect(row, isSelected, e){
		let { references } = this.props;
		references = _.union(references, [{reference_text: '', document_id: row.id}]);
		this.setState({open: false}, this.props.onChange(ReferencesFieldName, references));
	}

	onDelete(index){
		var { references } = this.state;
		references.splice(index, 1);

		this.setState({
			references: references
		});
	}

	handleChange(index, event){
		var { references } = this.state;
		references[index].reference_text = event.target.value;

		this.setState({
			references: references
		});
	}

	handleSetValue(){
		let { references } = this.props;
		let new_references = this.state.references;
		_.remove(new_references, function(ref){
			return ref.reference_text.length === 0 && ref.document_id.length === 0
		})
		references = _.union(references, new_references);

		this.setState({
			open: false,
			references: [
				{
					reference_text: '',
					document_id: ''
				}
			]
		}, this.props.onChange(ReferencesFieldName, references));
	}

	onRefAddDetail(){
		let { references } = this.state;
		references = _.concat(references, {reference_text: '', document_id: ''});

		this.setState({references: references});
	}

	renderRefDetail(doc, index){
		return (
			<div key={index} className='card bg-light mb-2 px-3 py-2'>
	  			<div className='row'>
	  				<div className='col-md-2'>รายละเอียด</div>
	  				<div className='col-md-9'>
	  					<textarea 
	  						name='reference_text' 
	  						value={this.state.references[index].reference_text}
	  						className='form-control' 
	  						onChange={this.handleChange.bind(this, index)} />
	  				</div>
	  				<div className='col-md-1'>
	  				{_.size(this.state.references) > 1 && (
	  					<i onClick={()=>this.onDelete(index)}
		    				className="fa fa-times top-right pointer" 
		    				aria-hidden="true">
		    			</i>
		    		)}
	  				</div>
	  			</div>
	  		</div>
		)
	}

	renderOldDocs(){
		const options = {
		  onRowClick: this.onSelect,
			sizePerPageList: []
		};
		var data = MergeAttributes(this.props.reference_documents);
		return (
			<BootstrapTable 
		  		data={data}
				search={ true }
				multiColumnSearch={ true }
				options={ options }
				striped
				hover
				condensed
				pagination
				version='4'
				tableContainerClass={'table-sm'}>
			  		<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
					<TableHeaderColumn 
						dataField='issue_number' 
						dataSort={ true } 
						columnClassName='pointer'
						dataFormat={issueNumberFormatter}>
						เลขที่หนังสือ
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='topic' 
						dataSort={ true } 
						columnClassName='pointer'>
						เรื่อง
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='informs' 
						dataSort={ true } 
						columnClassName='pointer'>
						เรียน
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='created_at' 
						dataSort={ true } 
						columnClassName='pointer'
						dataFormat={thaiDateFormatter}>
						ลงวันที่
					</TableHeaderColumn>
			</BootstrapTable>
		)
	}

	handleChangeHiddenRef(event){
		let isChecked = event.target.checked;
		this.props.handleChange(EditingFieldName, isChecked);
	}

	render(){
		const { is_receive_document_form } = this.props;
		return (
			<div className='row'>
				<div className={is_receive_document_form ? 'col-12 col-md-2' : 'col-12'}><b>รายการเอกสารอ้างถึง</b></div>
				<div className={is_receive_document_form ? 'col-12 col-md-10' : 'col-12'}>
					<button type='button' className='btn btn-sm btn-primary mr-2' onClick={this.onOpen}>
						เอกสารอ้างถึง
					</button>
					{this.props.hidden_section && (
						<label>
							<input 
								type='checkbox' 
								name='hidden_ref'
								className='mr-1 mt-2'
								checked={this.props.hidden_ref.toString() === "true"}
								onClick={this.handleChangeHiddenRef} />
							<label className='ex-small-text'>ซ่อน</label>
						</label>
					)}
				</div>

				<SimpleModal 
					isOpen={this.state.open}
					title={'เลือกเอกสารอ้างถึง'}
					size={'modal-lg'}
					showSubmitButton={false}
					onClose={this.onClose}>
					<div className='mt-2'>
						<ul className="nav nav-tabs" id="ReferenceDocuments" role="tablist">
						  	<li className="nav-item">
								<span className={`nav-link pointer ${(this.state.activeMode === ChooseDoc) && 'active'}`} 
								href='#'
								onClick={()=>this.setState({activeMode: ChooseDoc})}>
									เลือกจากเอกสารเก่า
								</span>
						  	</li>
						  	<li className="nav-item">
								<span className={`nav-link pointer ${(this.state.activeMode === SetDoc) && 'active'}`} 
								href="#"
								onClick={()=>this.setState({activeMode: SetDoc})}>
									กำหนดเอง
								</span>
						  	</li>
						</ul>
						<div className="tab-content mt-3" id="ReferenceDocuments">
						  	<div className={`tab-pane fade ${(this.state.activeMode === ChooseDoc) && 'show active'}`}>
						  		{this.renderOldDocs()}
						  	</div>
						  	<div className={`tab-pane fade ${(this.state.activeMode === SetDoc) && 'show active'}`}>
						  		<div className='mb-2'>
						  			<button 
						  				type='button' 
						  				className='btn btn-sm btn-success pointer'
						  				onClick={this.onRefAddDetail}>เพิ่ม</button>
						  		</div>
						  		{this.state.references && _.map(this.state.references, this.renderRefDetail)}
						  	</div>
						</div>
						<div className="modal-footer row">
							<button 
								type='button' 
								className="btn btn-primary pointer"
								onClick={this.handleSetValue}>ตกลง</button>
						</div>
					</div>
				</SimpleModal>
			</div>
		)
	}
}

export class RenderRefDocs extends Component {
	constructor(props){
		super(props)

		this.renderRow = this.renderRow.bind(this);
	}

	renderRow(ref, index){
		let text = ref.reference_text;
		if (!_.isEmpty(ref.document_id)){
			let ref_doc = _.find(this.props.reference_documents, {id: ref.document_id});
			
			if (!_.isEmpty(ref_doc)){
				text = ref_doc.attributes.topic
			}
		}
		return (
			<li key={index} style={{position: 'relative'}}>
				{text}
				<i onClick={()=>this.props.onDeleteRefDoc(index)}
  				className="fa fa-times top-right pointer" 
  				aria-hidden="true">
  			</i>
			</li>
		)
	}

	render(){
		return (
			<div className='row'>
				<div className='col-12 col-md-2'></div>
				<div className='col-12 col-md-10'>
					<ul className='pl-0 mb-0 ml-0 small mt-2 list-style-none'>
						{this.props.references && _.map(this.props.references, this.renderRow)}
				  </ul>
				</div>
			</div>
		)
	}
}

