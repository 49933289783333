import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './stylesheets/SystemUsageStatisticReportPage.css';

import SystemUsageStore from '../../stores/SystemUsageStore';
import SystemUsagesStatistic from '../../components/reports/SystemUsagesStatistic';
import Loading from '../../components/Loading';
import SimplePieChart from '../../components/SimplePieChart';

export default class SystemUsageStatisticReportPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      start_date: moment(),
      end_date: moment(),
      department: '',
      system_usages: [],
      chart_data: []
    }

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount(){
    this.loadData();
  }

  loadData(){
    SystemUsageStore.request({
      method: 'POST',
      action: 'statistic',
      data: {
        start_date: this.state.start_date || '',
        end_date: this.state.end_date || '',
        department: this.state.department || ''
      }
    }).then((resp)=>{
      const system_usages = _.get(resp.data, 'data', []);
      const chart_data = _.get(resp.data, 'included.chart_data', []);
      this.setState({
        system_usages: system_usages,
        chart_data: chart_data
      });
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  handleChangeStartDate(date){
    this.setState({start_date: date});
  }

  handleChangeEndDate(date){
    this.setState({end_date: date});
  }

  handleChange(event){
    let field_name = event.target.name;
    let value = event.target.value;

    this.setState({
      [field_name]: value
    });
  }

  onSubmit(event){
    event.preventDefault();
    this.setState({loading: true});
    this.loadData();
  }

  handleDownload(){
    axios({
      method:'POST',
      url: '/api/v1/system_usages/statistic',
      responseType:'arraybuffer',
      cache: false,
      data: {
        start_date: this.state.start_date || '',
        end_date: this.state.end_date || '',
        department: this.state.department || '',
        response_type: 'csv'
      }
    }).then(function(response) {

      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' } )
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'รายงานสถิติการเข้าใช้งาน.xlsx'
      link.click()
    });
  }

  render(){
    return (
      <div>
        <h2>รายงานการเข้าใช้ระบบ</h2>

        <div className='card'>
          <div className='p-2'>

            <form onSubmit={this.onSubmit}>
              <div className='row mb-2'>
                <div className='col-md-1'>หน่วยงาน:</div>
                <div className='col-md-5'>
                  <input 
                    type='text' 
                    name='department'
                    value={this.state.department}
                    className='form-control form-control-sm'
                    onChange={this.handleChange} />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-1'>จากวันที่:</div>
                <div className='col-md-2 mb-2 mb-md-0'>
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    selected={this.state.start_date}
                    onChange={this.handleChangeStartDate}
                    className='form-control form-control-sm' />
                </div>
                <div className='col-md-1'>ถึงวันที่:</div>
                <div className='col-md-2 mb-2 mb-md-0'>
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    selected={this.state.end_date}
                    onChange={this.handleChangeEndDate}
                    className='form-control form-control-sm' />
                </div>
                <div className='col-md-5'>
                  <button type='submit' className='btn btn-success mr-3'>ค้นหา</button>
                  <button type='button' className='btn btn-success' onClick={this.handleDownload}>พิมพ์</button>
                </div>
              </div>
            </form>
            
          </div>
        </div>

        <SimplePieChart 
          loading={this.state.loading} 
          chart_data={this.state.chart_data} />

        {this.state.loading ? (
          <div className='py-3'>
            <Loading />
          </div>
        ) : (
          <div className="rui-statistic-report">
            <SystemUsagesStatistic system_usages={this.state.system_usages} />
          </div>
        )}
      </div>
    );
  }
}