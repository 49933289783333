import React, { Component } from 'react';
import _ from 'lodash';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { speedLevelList, secretLevelList } from '../../../utils/documentType';
import { SecretLevel, SpeedLevel } from '../../../utils/Helper';
import InformText from '../../receive_documents/InformText';

const NormalLevel = 'ปกติ';

export default class EditDocumentForm extends Component {
  constructor(props) {
    super(props)

    this.renderSpeedLevel = this.renderSpeedLevel.bind(this);
    this.renderSecretLevel = this.renderSecretLevel.bind(this);
  }

  renderSpeedLevel(speed_level, index){
    return (
      <SpeedLevel key={index} level={speed_level} index={index} {...this.props} />
    )
  }

  renderSecretLevel(secret_level, index){
    return (
      <SecretLevel key={index} level={secret_level} index={index} {...this.props} />
    )
  }

  render(){
    const inform = _.first(this.props.informs);
    
    return (
      <div>
        <div className='row mb-2'>
          <div className='col-12 col-md-3'><b>เลขที่</b></div>
          <div className='col-12 col-md-9'>
            {_.get(this.props, "document.attributes.prev_issue_number", "")}
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-3'><b>จากหน่วยงาน</b></div>
          <div className='col-12 col-md-9'>
            <textarea 
              autocomplete='organization'
              name='government_service' 
              className='form-control'
              value={this.props.government_service}
              onChange={this.props.handleChange} />
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-3'><b>ชั้นความเร็ว</b></div>
          <div className='col-12 col-md-9'>

            <div className="btn-group btn-group-toggle speed_level-list" data-toggle="buttons">
              {_.map(speedLevelList, this.renderSpeedLevel)}
            </div>

          </div>
        </div>

        {this.props.speed_level !== NormalLevel && (
          <div className='row mb-2'>
            <div className='col-12 col-md-3'><b>วันที่คาดว่าจะแล้วเสร็จ</b></div>
            <div className='col-12 col-md-9'>

              <DatePicker
                dateFormat="DD/MM/YYYY"
                selected={this.props.due_date}
                onChange={this.props.handleChangeDueDate}
                className='form-control' />

            </div>
          </div>
        )}

        <div className='row mb-2'>
          <div className='col-12 col-md-3'><b>ชั้นความลับ</b></div>
          <div className='col-12 col-md-9'>

            <div className="btn-group btn-group-toggle secret_level-list" data-toggle="buttons">
              {_.map(secretLevelList, this.renderSecretLevel)}
            </div>

          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-3'>
            <label className='required'><b>เรียน</b></label>
          </div>
          <div className='col-12 col-md-9'>

            <InformText 
              inform={inform}
              position_name_list={this.props.position_name_list}
              handleChangeInformText={this.props.handleChangeInformText} />

          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-3'><b>ลงวันที่</b></div>
          <div className='col-12 col-md-9'>

            <DatePicker
              dateFormat="DD/MM/YYYY"
              selected={this.props.document_date}
              onChange={this.props.handleChangeReceiveDate}
              className='form-control form-control-sm' />

          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-3'><b>เจ้าของเรื่อง</b></div>
          <div className='col-12 col-md-9'>

            <input 
              type='text'
              name='owner'
              value={this.props.owner}
              onChange={this.props.handleChange}
              className='form-control form-control-sm' />

          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-3'><b>เรื่อง</b></div>
          <div className='col-12 col-md-9'>

            <input 
              type='text' 
              name='topic'
              value={this.props.topic}
              onChange={this.props.handleChange} 
              className='form-control form-control-sm' />

          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-12 col-md-3'></div>
          <div className='col-12 col-md-9'>

            <button type='button' className='btn btn-primary' onClick={this.props.handleSave}>บันทึกการแก้ไข</button>

          </div>
        </div>
      </div>
    )
  }
}