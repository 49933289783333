import React, {Component} from 'react';

import SimpleModal from '../SimpleModal';
import PositionsUserTable from '../PositionsUserTable';

export class ActorForm extends Component {
  render(){
    return (
        <div>
          <SimpleModal 
          isOpen={this.props.open}
          title={'เลือกผู้ดำเนินการแทน'}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.props.onClose}>
            <PositionsUserTable positions_users={this.props.actor_positions_users}
                                onSelect={this.props.onSelectActor}
                                onClose={this.props.onClose} />
        </SimpleModal>
      </div>
    )
  }
}

export default ActorForm;
