import React, { Component } from 'react';
import _ from 'lodash';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { MergeAttributes } from '../FlatData';
import Loading from '../Loading';

import { thaiDateFormatter } from '../../utils/bootstrapTableFormatters';

class ReceiveDocumentTable extends Component {
  constructor(props) {
    super(props);
  }

  _setTableOption(){ 
    if (_.isEmpty(this.props.results)) { 
      return "ไม่มีข้อมูล"; 
    }else{
      return <Loading />
    }
  }

  render(){
    var data = MergeAttributes(this.props.results);

    const options = {
      sizePerPageList: [],
      noDataText: this._setTableOption(),
      sizePerPage: 25
    };
    return (
      <BootstrapTable 
        data={data}
        search={ true }
        multiColumnSearch={ true }
        options={options}
        striped
        hover
        condensed
        pagination
        version='4'
        tableContainerClass={'table-sm'}>
          <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn dataField='issue_number' dataSort={ true } width='120px'>เลขที่เอกสาร</TableHeaderColumn>
            <TableHeaderColumn dataField='number' dataSort={ true }>เลขที่รับเอกสาร</TableHeaderColumn>
            <TableHeaderColumn dataField='from_department' columnTitle dataSort={ true }>จากหน่วยงาน</TableHeaderColumn>
            <TableHeaderColumn dataField='to_department' columnTitle dataSort={ true }>ถึงหน่วยงาน</TableHeaderColumn>
            <TableHeaderColumn dataField='created_at' dataFormat={ thaiDateFormatter } dataSort={ true }>วันที่รับเอกสาร</TableHeaderColumn>
            <TableHeaderColumn dataField='topic' columnTitle dataSort={ true }>หัวข้อ</TableHeaderColumn>
            <TableHeaderColumn dataField='current_state_name' dataSort={ true }>สถานะ</TableHeaderColumn>
      </BootstrapTable>
    )
  }
}

export default ReceiveDocumentTable;
