import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Link, withRouter } from 'react-router-dom';

import './stylesheets/SendAllDocumentsPage.css';

import { thaiDateFormatter, rowClassNameFormat, thaiDateTimeFormatter } from '../../utils/bootstrapTableFormatters';
import { DocumentOwner, SpeedLevelClassName } from '../../utils/Helper';
import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';
import Permission from '../../components/Permission';
import DocumentTypeFilter from '../../components/documents/filters/DocumentTypeFilter';
import ShowAttachedFiles from '../../components/documents/ShowAttachedFiles';
import HandleOriginalFiles from '../../components/documents/HandleOriginalFiles';

import DepartmentNumberList from '../../components/department_numbers/DepartmentNumberList';

import SimpleModal from '../../components/SimpleModal';
import IssueNumberStore from '../../stores/IssueNumberStore';

import WorkflowProcessHistory from '../../components/WorkflowProcessHistory';
import BookIssueNumberModal from '../../components/Modals/BookIssueNumberModal';

class SendAllDocumentsPage extends Component {
  constructor(props){
    super(props)

    var dateObj = new Date();
    var year = dateObj.getUTCFullYear();
    var current_year = year + 543;

    this.state = {
      loading: true,
      start_year: current_year.toString(),
      end_year: current_year.toString(),
      open: false,
      open_attached_file: false,
      open_insert_file: false,
      workflow_process_id: '',
      workflow_process_id_history: '',
      workflow_process_id_attachment: '',
      group_id: '',
      amount: 1,
      issue_numbers: [],
      groups: [],
      years: [],
      show_warning: false,
      allow_remove_ids: [],
      pagination: {},
      searchText: '',
      active_type: 'ทั้งหมด',
      selectedNumberType: ["ทั่วไป"],
      document_templates: []
    }

    this.handleBook = this.handleBook.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.confirm = this.confirm.bind(this);
    this.onClose = this.onClose.bind(this);
    this.loadData = this.loadData.bind(this);
    this.redirectToShowDocumentPage = this.redirectToShowDocumentPage.bind(this);
    this.stateFormatter = this.stateFormatter.bind(this);
    this.openStateModal = this.openStateModal.bind(this);
    this.onCloseStateModal = this.onCloseStateModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.numberWithReservation = this.numberWithReservation.bind(this);
    this.documentDetailsFormatter = this.documentDetailsFormatter.bind(this);
    this.toDepartmentFormatter = this.toDepartmentFormatter.bind(this);
    this.speedLevelFormatter = this.speedLevelFormatter.bind(this);
    this.renderDepartmentIssueNumberRow = this.renderDepartmentIssueNumberRow.bind(this);

    // Pagination & Search
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(){
    IssueNumberStore.request({}).then((resp)=>{
      const issue_numbers = _.get(resp.data, 'data', []);
      const groups = _.get(resp.data, 'included.groups', []);
      const years = _.get(resp.data, 'included.years', []);
      const allow_remove_ids = _.get(resp.data, 'included.allow_remove_ids', []);
      const document_templates = _.get(resp.data, 'included.document_templates', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      let group_id = '';
      if (!_.isEmpty(groups)){
        group_id = _.first(groups).id;
      }

      this.setState({
        issue_numbers: issue_numbers,
        groups: groups,
        group_id: group_id,
        years: years,
        allow_remove_ids: allow_remove_ids,
        document_templates: document_templates,
        pagination: pagination
      })
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  confirm(){
    this.setState({show_warning: true});
  }

  handleSubmit(){
    IssueNumberStore.save(null, {
      issue_number: {
        for_group_id: this.state.group_id,
        amount: this.state.amount,
        type: this.state.selectedNumberType
      }
    }).then((resp)=>{
      this.setState({
        open: false, 
        open_attached_file: false,
        open_insert_file: false,
        workflow_process_id: '',
        show_warning: false, 
        amount: 1
      }, this.loadData());
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    })
  }

  handleInsertFile(workflow_process_id, e){
    e.stopPropagation();
    this.setState({
      open_insert_file: true,
      workflow_process_id: workflow_process_id
    });
  }

  documentDetailsFormatter(cell, row){
    const workflow_process_id = _.get(row, 'document_detail.workflow_process_id', '');
    return (
      <div style={{whiteSpace: 'normal'}}>
        <div><u>เรียน</u> {row.to_department}</div>
        <div><u>เรื่อง</u> {row.topic}</div>
        <DocumentOwner cell={cell} row={row} />
        {!_.isEmpty(row.attachment) && (
          <div>
            <u className='mr-2'>สิ่งที่ส่งมาด้วย</u>
            <span>{row.attachment}</span>
          </div>
        )}

        {row.reference_issue_numbers && (
          <div><u>อ้างอิงเลขที่หนังสือ</u> {row.reference_issue_numbers}</div>
        )}
      </div>
    )
  }

  handleShowAttachedFiles(workflow_process_id, e){
    e.stopPropagation();
    this.setState({workflow_process_id_attachment: workflow_process_id, open_attached_file: true})
  }

  handleBook(){
    this.setState({
      open: true, 
      open_insert_file: false, 
      open_attached_file: false, 
      workflow_process_id: '', 
      amount: 1
    });
  }

  handleChange(event){
    let field_name = event.target.name;
    let value = event.target.value;
    this.setState({
      [field_name]: value
    })
  }

  handleDelete(issue_number){
    if (!window.confirm('ยืนยันยกเลิกการจอง')) { return }
    IssueNumberStore.request({id: issue_number.id, method: 'delete'}).then(()=>{
      this.loadData();
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    })
  }

  numberWithReservation(cell, row){
    const allow_remove_ids = this.state.allow_remove_ids;
    const workflow_process_id = _.get(row, 'document_detail.workflow_process_id', '');

    return (
      <div>
        {row.available ? (
          <div>
            {row.number}
            <br />
            <div className='xxs-small text-warning'>{row.booked_by_user_name}</div>
            {_.includes(allow_remove_ids, row.id) && (
              <button 
                type='button' 
                className='btn btn-sm btn-warning'
                onClick={this.handleDelete.bind(this, row)}>
                ยกเลิกการจอง
              </button>
            )}
          </div>
        ) : (
          <div>
            {row.number}
          </div>
        )}
        <div onClick={this.handleInsertFile.bind(this, workflow_process_id)} className='font-weight-bold pointer text-link'>
          ไฟล์แทรก ({_.size(row.original_documents) + row.attachment_count}) 
          {_.size(row.original_documents) > 0 && (
            <i 
              className="fa fa-paperclip ml-2 text-danger" 
              aria-hidden="true" 
              onClick={this.handleInsertFile.bind(this, workflow_process_id)}></i>
          )}
        </div>
        
        {/*
        <div className='text-link' onClick={this.handleShowAttachedFiles.bind(this, workflow_process_id)}>
          <strong className='pointer'>ไฟล์แนบ ({row.attachment_count})</strong>
        </div>
        */}

        {!_.isEmpty(row.department_issue_numbers) && (
          <div className="mt-2">
            <DepartmentNumberList 
                title="เลขกรม"
                issue_numbers={row.department_issue_numbers} />
          </div>
        )}

        {!_.isEmpty(row.unit_issue_numbers) && (
          <div className="mt-2">
            <DepartmentNumberList 
                title="เลขหน่วยงาน"
                issue_numbers={row.unit_issue_numbers} />
          </div>
        )}
      </div>
    )
  }

  renderDepartmentIssueNumberRow(department_issue_number, index){
    const id = department_issue_number.id;
    const number = department_issue_number.attributes.number;
    const number_book_name = department_issue_number.attributes.number_book_name;

    return (
      <div key={index}>
        <a href={`/send_documents/send?id=${id}&number=${number}&number_book_name=${number_book_name}`}>
          {number}
        </a>
      </div>
    )
  }

  onClose(){
    this.setState({
      open: false,
      open_attached_file: false,
      open_insert_file: false, 
      workflow_process_id: '',
      show_warning: false, 
      amount: 1
    });
  }

  stateFormatter(cell, row) {
    const workflow_process_id = _.get(row, "document_detail.workflow_process_id");
    
    return (
      <div>
        {workflow_process_id && (
          <div className='text-link pointer' onClick={this.openStateModal.bind(this, workflow_process_id)}>
            {cell}
          </div>
        )}
      </div>
    )
  }

  openStateModal(workflow_process_id, event){
    event.stopPropagation();

    this.setState({
      stateModalOpen: true,
      workflow_process_id_history: workflow_process_id,
    });
  }

  onCloseStateModal(){
    this.setState({
      stateModalOpen: false, 
      workflow_process_id: null
    });
  }

  toDepartmentFormatter(cell, row){
    let sent_success_target_count = row.sent_success_target_count;
    let total_target_count = row.total_target_count;

    if(sent_success_target_count > total_target_count){
      total_target_count = sent_success_target_count
    }

    return (
      <div style={{whiteSpace: 'normal'}}>
        {_.size(cell) == 1 ? cell : `${sent_success_target_count}/${total_target_count} หน่วยงาน`}
      </div>
    )
  }

  redirectToShowDocumentPage(row){
    if (_.isEmpty(row.document_detail)) { return }
      
    const { document_id, document_template_id, workflow_process_id } = row.document_detail;

    if (!_.isEmpty(document_id) && !_.isEmpty(document_template_id) && !_.isEmpty(workflow_process_id)){
      this.props.history.push(`/templates/documents/${document_id}/show/${document_template_id}/wp/${workflow_process_id}`);
    }
  }

  onChange(fieldname, value){
    this.setState({
      [fieldname]: value
    })
  }

  _setTableOption(){ 
    if(!this.state.loading){
      return "ไม่มีข้อมูล";
    }else{
      return <Loading />
    }
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, issue_numbers: []});
    const { searchText } = this.state;

    IssueNumberStore.request({url: `issue_numbers?page=${page}&searchText=${searchText}`}).then((resp)=>{
      const issue_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        issue_numbers: issue_numbers,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, issue_numbers: []});

    IssueNumberStore.request({url: `issue_numbers?page=1&searchText=${searchText}&type=${this.state.active_type}`}).then((resp)=>{
      const issue_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        issue_numbers: issue_numbers,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleChangeType(type){
    this.setState({isDataFetched: false, issue_numbers: []});

    IssueNumberStore.request({url: `issue_numbers?page=1&searchText=${this.state.searchText}&type=${type}`}).then((resp)=>{
      const issue_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        issue_numbers: issue_numbers,
        pagination: pagination,
        active_type: type
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  speedLevelFormatter(cell, row) {
    return (
      <div className={`text-${SpeedLevelClassName(row.attributes.speed_level)}`}>
        {row.attributes.speed_level}
      </div>
    )
  }

  render() {
    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);

    const options = {
      sizePerPageList: [],
      sizePerPage: 10,
      page: active_page,
      noDataText: this._setTableOption(),
      onRowClick: this.redirectToShowDocumentPage,
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500
    };
    var data = [];
    if (!this.state.loading && !_.isEmpty(this.state.issue_numbers)){
      data = MergeAttributes(this.state.issue_numbers);
    }

    let modalTitle = 'ระบุจำนวนเอกสารที่ต้องการจอง';
    if (this.state.show_warning){
      modalTitle = 'เตือน';
    }

    const { document_templates } = this.state;
    const document_template_names = _.map(document_templates, 'attributes.name');
    let documentTypes = ["ทั้งหมด"];
    documentTypes = _.union(documentTypes, document_template_names);

		return (
			<div>
        <div className='row'>
          <div className='col-md-6'>
            <h3>ทะเบียนหนังสือส่ง</h3>
          </div>
          <div className='col-md-6 text-right'>
            {/*Permission.canBookNumber() && (
              <button type='button' className='btn btn-info mr-1' onClick={this.handleBook}>จองเลข</button>
            )*/}
            <Link to='/templates' className='btn btn-success mr-1'>ร่างเอกสาร</Link>
          </div>
        </div>

        <div className='row form-group'>
          <div className='col-12'>
            <DocumentTypeFilter 
              active_type={this.state.active_type}
              document_types={documentTypes}
              handleChangeType={this.handleChangeType} />
          </div>
        </div>

        <div className="rui-sendalldoc">
          <BootstrapTable 
            data={data}
            remote={ true }
            fetchInfo={{dataTotalSize: total_items_count}}
            search={ true }
            multiColumnSearch={ true }
            options={ options }
            striped
            hover
            pagination
            version='4'
            tableContainerClass={'table-sm pointer'}
            trClassName={rowClassNameFormat}>
            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn dataField='speed_level' dataFormat={this.speedLevelFormatter} width='75'>ชั้นความเร็ว</TableHeaderColumn>
            <TableHeaderColumn dataField='code' width='70'>เลขส่ง</TableHeaderColumn>
            <TableHeaderColumn dataField='number' dataFormat={this.numberWithReservation} width='140'>ที่</TableHeaderColumn>
            <TableHeaderColumn dataField='document_date_thai_format' dataFormat={ thaiDateFormatter } width='90'>ลงวันที่</TableHeaderColumn>
            <TableHeaderColumn dataField='sent_targets' dataFormat={this.toDepartmentFormatter} width="200">ถึง</TableHeaderColumn>
            <TableHeaderColumn dataField='topic' dataFormat={this.documentDetailsFormatter}>รายละเอียดหนังสือ</TableHeaderColumn>
            <TableHeaderColumn dataField='template_type_name' columnTitle width='70'>ประเภท</TableHeaderColumn>
            <TableHeaderColumn dataField='created_at' dataFormat={thaiDateTimeFormatter} width='90'>วันที่ส่ง</TableHeaderColumn>
            <TableHeaderColumn dataField='current_state_name' dataFormat={this.stateFormatter} width='100'>สถานะ</TableHeaderColumn>
          </BootstrapTable>
        </div>

        <ShowAttachedFiles 
          workflow_process_id={this.state.workflow_process_id_attachment}
          open_attached_file={this.state.open_attached_file}
          onClose={this.onClose} />

        <HandleOriginalFiles 
          workflow_process_id={this.state.workflow_process_id}
          open_insert_file={this.state.open_insert_file}
          after_save_redirect_to={"/send_documents/send_all"}
          onClose={this.onClose} />

        <BookIssueNumberModal 
          open={this.state.open}
          modalTitle={modalTitle}
          onClose={this.onClose}
          show_warning={this.state.show_warning}
          selectedNumberType={this.state.selectedNumberType}
          group_id={this.state.group_id}
          amount={this.state.amount}
          handleChange={this.handleChange}
          document_templates={document_templates}
          groups={this.state.groups}
          confirm={this.confirm}
          handleSubmit={this.handleSubmit}
          loading={this.state.loading} />

        <SimpleModal 
          isOpen={this.state.stateModalOpen}
          title="ประวัติการดำเนินการ การปฏิบัติงาน"
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onCloseStateModal}>
            <WorkflowProcessHistory id={this.state.workflow_process_id_history} cancelable={true} />

            <div className="modal-footer row">
              <button type='button' className="btn btn-secondary pointer" onClick={this.onCloseStateModal}>ปิด</button>
            </div>
        </SimpleModal>
			</div>
		)
	}
}

export default SendAllDocumentsPage;