import React, { Component } from 'react';

import Permission from '../../components/Permission';

import ShowSetupDetails from '../../components/settings/ShowSetupDetails';
import EditSetupDetails from '../../components/settings/EditSetupDetails';

import './stylesheets/SettingPage.css';

class SetupPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      show: true
    }

    this.handleShow = this.handleShow.bind(this);
  }

  componentDidMount(){
    if (!Permission.isSuperAdmin()) {
      window.location.href = '/receive_documents/mydoc';
    }
  }

  handleShow(){
    if (this.state.show) {
      this.setState({show: false});
    }else{
      this.setState({show: true});
    }
  }

  render(){
    return (
      <div className='settings-page'>
      {Permission.isSuperAdmin() && (
        <div>
          <div className='row form-group'>
            <div className='col-md-12'>
              <h2 className='font-weight-bold'>SITE SETTING</h2>
            </div>
          </div>
          {this.state.show ? (
            <ShowSetupDetails handleShow={this.handleShow} />
          ) : (
            <EditSetupDetails handleShow={this.handleShow} />
          )}
        </div>
      )}
      </div>
    )
  }
}

export default SetupPage;