import React, { Component } from 'react';
import _ from 'lodash';

class PositionRoleForm extends Component {
	constructor(props){
		super(props)

		this.state = {
			currentModuleId: '',
			roles: [],
			role_id: ''
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeModule = this.handleChangeModule.bind(this);
		this.handleAddRoles = this.handleAddRoles.bind(this);
	}

	handleChangeModule(){
		var currentModuleId = this.refs.module_id.value;

		if (_.isEmpty(currentModuleId)) {
			this.setState({
				currentModuleId: '',
				roles: []
			})
		}else{
			var module = _.find(this.props.modules, {'id': currentModuleId});
			var role_ids = _.map(module.attributes.roles, 'id');
			var roles = _.filter(this.props.roles, function(r) { return _.includes(role_ids, r.id); });
			this.setState({
				currentModuleId: currentModuleId,
				roles: roles
			})
		}
	}

	handleChange(){
		this.setState({
			role_id: this.refs.role_id.value
		})
	}

	handleAddRoles(){
		if (_.isEmpty(this.state.role_id)) { return }

		var position = _.clone(this.props.position);
		var role_ids = _.concat(position.role_ids, this.state.role_id)
		role_ids = _.uniq(role_ids);
		position.role_ids = role_ids;

		this.props.handleChangeData(position);
	}

	handleDelete(role_id){
		var position = _.clone(this.props.position)
		var role_ids = _.pull(position.role_ids, role_id)
		position.role_ids = role_ids

		this.props.handleChangeData(position)
	}

	render() {
		return (
			<div>
				<div className='row form-group'>

					<div className='col-md-12'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>Module</strong></label>
							</div>
							<div className='col-md-9'>
								<select 
									ref='module_id' 
			                        className='form-control form-control-sm'
			                        value={this.state.currentModuleId}
			                        onChange={this.handleChangeModule}>
			                        <option value={''} key={'Please Select'}>-- Please Select --</option>
			                    	{_.map(this.props.modules, (module) => {
			                      		return <option value={module.id} key={module.id}>{module.attributes.name}</option>
			                    	})}
			                  	</select>
							</div>
						</div>
					</div>

					<div className='col-md-12 mb-3'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>Role</strong></label>
							</div>
							<div className='col-md-9'>
								<select 
									ref='role_id' 
			                        className='form-control form-control-sm'
			                        value={this.state.role_id}
			                        onChange={this.handleChange}>
			                        <option value={''} key={'Please Select'}>-- Please Select --</option>
			                    	{!_.isEmpty(this.state.roles) && _.map(this.state.roles, (role) => {
			                      		return <option value={role.id} key={role.id}>{role.attributes.name}</option>
			                    	})}
			                  	</select>
							</div>
							<div className='col-md-3 pl-0'>
								<button 
									type='button' 
									className='btn btn-success btn-block'
									onClick={this.handleAddRoles}>+ เพิ่ม</button>
							</div>
						</div>
					</div>

					<div className='col-md-12'>
						<div className='row'>
							<div className='col-md-12'>
								{!_.isEmpty(this.props.position.role_ids) && _.map(this.props.position.role_ids, (role_id)=>{
									var role = _.find(this.props.roles, {id: role_id})
									return (
										<div key={role_id} className="alert alert-primary mb-1" role="alert">
										  	{role.attributes.name}
										  	<button type="button" className="close" onClick={this.handleDelete.bind(this, role_id)}>
										    	<span aria-hidden="true">&times;</span>
										  	</button>
										</div>
									)
								})}
							</div>
						</div>
					</div>

				</div>
			</div>
		)
	}
}

export default PositionRoleForm;