import React, {Component} from 'react';
import _ from 'lodash';
import './Elements.css';

import { MergeAttributes } from '../../FlatData';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import SimpleModal from '../../SimpleModal';
import Loading from '../../Loading';
import SiteSettingStore from '../../../stores/SiteSettingStore';

import InlineEditPositionName from './InlineEditPositionName';

const UpLine = 'line';
const AllUsers = 'all';
const EditingFieldName = 'signees';

export class SigneeForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			open: false,
			activeMode: UpLine
		}

		this.positionFormat = this.positionFormat.bind(this);
		this.departmentFormat = this.departmentFormat.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.onSigneeOpen = this.onSigneeOpen.bind(this);
		this.onClose = this.onClose.bind(this);
	}

	positionFormat(cell, row){
		return (
			<div style={{whiteSpace: 'normal'}}>{row.position_name}</div>
		)
	}

	departmentFormat(cell, row){
		return (
			<div style={{whiteSpace: 'normal'}}>{row.department_name}</div>
		)
	}

	onSelect(row, isSelected, e){
		let { signees, document_template } = this.props;

		if (document_template.attributes.has_multiple_signee) {
			signees = _.union(signees, [{user_id: row.user_id, position_id: row.position_id, position_name: row.position_name, level: row.level}]);
			// signees = [{user_id: row.user_id, position_id: row.position_id, position_name: row.position_name, level: row.level}];

			this.setState({open: false}, this.props.handleChange(EditingFieldName, signees));
		}else{
			if (!_.isEmpty(signees)) {
				alert('ไม่สามารถเพิ่มส่วนราชการที่ส่งหนังสือออกได้มากกว่าหนึ่งรายการ');
				return
			}
			signees = [{user_id: row.user_id, position_id: row.position_id, position_name: row.position_name, level: row.level}];
			this.setState({open: false}, this.props.handleChange(EditingFieldName, signees));
		}

		const levels = _.map(signees, (s) => s.level);

		// Auto update set fornt page availability
		if (document_template.attributes.enable_front_page) {
			if (!_.isEmpty(signees) && _.includes(levels, document_template.attributes.signee_min_level)){
				this.props.handleChange('has_front_page', true);
			}else{
				this.props.handleChange('has_front_page', false);
			}
		}
	}

	onSigneeOpen(){
		this.setState({open: true});
	}

	onClose(){
		this.setState({open: false});
	}

	renderInternalUsers(){
		const options = {
		  	onRowClick: this.onSelect,
			sizePerPageList: [],
			sizePerPage: 6
		};
		var data = MergeAttributes(this.props.internal_users);
		return (
			<BootstrapTable 
		  		data={data}
				search={ true }
				multiColumnSearch={ true }
				options={ options }
				striped
				hover
				condensed
				pagination
				version='4'
				tableContainerClass={'table-sm'}>
			  		<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
					<TableHeaderColumn 
						dataField='full_name' 
						dataSort={ true } 
						columnClassName='pointer'>
						ชื่อ-สกุล
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='position_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.positionFormat}>
						ตำแหน่ง
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='department_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.departmentFormat}>
						แผนก
					</TableHeaderColumn>
			</BootstrapTable>
		)
	}

	renderLineUsers(){
		const options = {
		  	onRowClick: this.onSelect,
			sizePerPageList: [],
			sizePerPage: 6
		};
		var data = MergeAttributes(this.props.users);
   	var data =  _.filter(data, function(d) {
	                return d.is_actor == false
	              });

		return (
			<BootstrapTable 
		  		data={data}
				search={ true }
				multiColumnSearch={ true }
				options={ options }
				striped
				hover
				condensed
				pagination
				version='4'
				tableContainerClass={'table-sm'}>
			  		<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
					<TableHeaderColumn 
						dataField='full_name' 
						dataSort={ true } 
						columnClassName='pointer'>
						ชื่อ-สกุล
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='position_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.positionFormat}>
						ตำแหน่ง
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='department_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.departmentFormat}>
						แผนก
					</TableHeaderColumn>
			</BootstrapTable>
		)
	}

	render(){
		return (
			<div>
				<button 
					type='button' 
					className='btn btn-sm btn-primary mr-2'
					onClick={this.onSigneeOpen}>
					เพิ่มผู้ลงนาม
				</button>
				<div className='small mt-2'>
					รายชื่อผู้ลงนาม
				</div>

			    <SimpleModal 
					isOpen={this.state.open}
					title={'เลือกผู้ลงนาม'}
					size={'modal-lg'}
					showSubmitButton={false}
					onClose={this.onClose}>
					<div>
						<ul className="nav nav-tabs" id="ReferenceDocuments" role="tablist">
						  	<li className="nav-item">
								<span className={`nav-link pointer ${(this.state.activeMode === UpLine) && 'active'}`} 
								href='#'
								onClick={()=>this.setState({activeMode: UpLine})}>
									ตามสายงาน
								</span>
						  	</li>
						  	<li className="nav-item">
								<span className={`nav-link pointer ${(this.state.activeMode === AllUsers) && 'active'}`} 
								href="#"
								onClick={()=>this.setState({activeMode: AllUsers})}>
									ทั้งหมด
								</span>
						  	</li>
						</ul>
						<div className="tab-content mt-3" id="ReferenceDocuments">
						  	<div className={`tab-pane fade ${(this.state.activeMode === UpLine) && 'show active'}`}>
						  		{this.renderInternalUsers()}
						  	</div>
						  	<div className={`tab-pane fade ${(this.state.activeMode === AllUsers) && 'show active'}`}>
						  		{this.renderLineUsers()}
						  	</div>
						</div>
					</div>
				</SimpleModal>
			</div>
		)
	}
}

export class RenderSignee extends Component {
	constructor(props) {
		super(props)

		this.renderRow = this.renderRow.bind(this);
	}

	renderRow(signee, index){
		let user = '';
		let user_attributes = _.map(this.props.users, 'attributes');
		user = _.find(user_attributes, {user_id: signee.user_id, position_id: signee.position_id});

		const on_duty = _.find(this.props.actings, (acting) => {
											return acting.attributes.user.id === signee.user_id
										});
	
		if(on_duty){
			return (
				<li style={{position: 'relative'}} key={index} className="bg-danger text-white p-2">
	  			<i className="fa fa-times text-black top-right pointer" onClick={()=>this.props.onDeleteSignee(signee)}></i>
          <i className='fa fa-eject text-black top-right pointer' onClick={() => this.props.onShowActorsForm(signee, "signee")}></i>
	  			<div className='mr-1'>{user.full_name} ({user.position_name})</div>
	    	</li>
			)
		} else {
			return (
				<li style={{position: 'relative'}} key={index}>
	  			<i className="fa fa-times top-right pointer" onClick={()=>this.props.onDeleteSignee(signee)}></i>
	  			<div className='mr-1'>{user.full_name} ({user.position_name})</div>
	    	</li>
			)
		}
	}

	render(){
		return (
			<div>
				{!_.isEmpty(this.props.signees) && (
			    	<ul className='pl-4 mb-0 small mt-2'>
			    		{_.map(this.props.signees, this.renderRow)}
			    	</ul>
				)}
		    </div>
		)
	}
}


export class RenderSigneeSignature extends Component {
	constructor(props){
		super(props)

		this.state = {
			loading: true,
			seal: ''
		}

		this.renderRow = this.renderRow.bind(this);
	}

	componentWillMount(){
    SiteSettingStore.request({}).then((resp)=>{
      let siteSetting = _.get(resp.data, 'data', []);

      this.setState({
        seal: siteSetting.seal || ''
      })
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  renderSealEmblem(number_issuer_department){
  	return (
  		<div>
				<div className='show-department-name-seal-document text-center'>
					{number_issuer_department}<br />
					<small className='text-muted'>วันที่ลงนามจะระบุทีหลัง</small>
				</div>
				<img alt='File' src={this.state.seal} style={{maxHeight: '170px'}} />
			</div>
  	)
  }

  renderNameAndPosition(user, signee){
  	return (
  		<div className='space-between-signature'>
				<div>({user.full_name})</div>
				<div>
					<InlineEditPositionName 
						mode={'signee'} 
						signee={signee}
						position_name={signee.position_name}
						handleChange={this.props.handleChange} />
				</div>
			</div>
  	)
  }

  renderDepartmentNameSignature(user){
  	return (
  		<div className='space-between-signature'>
				<div>({user.department_name})</div>
				<div className='text-muted small'>วันที่ลงนามจะระบุทีหลัง</div>
			</div>
  	)
  }

	renderRow(signee, index){
		var user = '';
		let user_attributes = _.map(this.props.users, 'attributes');
		user = _.find(user_attributes, {user_id: signee.user_id, position_id: signee.position_id});

		let number_issuer = '';
		let number_issuer_department = '';
		if (!_.isEmpty(this.props.number_issuer_id)){
			number_issuer = _.find(this.props.number_issuers, {id: this.props.number_issuer_id});
			number_issuer_department = number_issuer.attributes.department_name || '';
		}

		let signee_box = null;
		switch(this.props.document_template.attributes.const_value){
			case 'sealed':
				signee_box = this.renderSealEmblem(number_issuer_department);
				break;
			case 'bulletin':
				signee_box = this.renderDepartmentNameSignature(user);
				break;
			case 'news':
				signee_box = this.renderDepartmentNameSignature(user);
				break;
			default:
				signee_box = this.renderNameAndPosition(user, signee);
				break;
		}
		return (
			<div key={index} className='col-7 ml-auto text-center'>
				{signee_box}
			</div>
		)
	}

	render(){
		return (
			<div>
				{this.state.loading && <Loading />}
				{!this.state.loading && _.map(this.props.signees, this.renderRow)}
			</div>
		)
	}
}





