import React, { Component } from 'react';
import _ from 'lodash';

export default class DepartmentNumberList extends Component {
  constructor(props) {
    super(props);

    this.renderDepartmentNumber = this.renderDepartmentNumber.bind(this);
  }

  useIssueNumber(issue_number){
    const id = issue_number.id;
    const number = issue_number.attributes.number;
    const number_book_name = issue_number.attributes.number_book_name;

    window.location.href = `/send_documents/send?id=${id}&number=${number}&number_book_name=${number_book_name}`;
  }

  renderDepartmentNumber(issue_number, index, array){
    return (
      <li key={index}>
        <div className="row">
          <div className="col-5">
            <div className="text-link pointer" onClick={() => this.useIssueNumber(issue_number)}>
              {issue_number.attributes.number}
            </div>
          </div>

          {this.props.deletable && (index === (array.length - 1)) && (
            <div className="col-5">
              <div className="text-link pointer" onClick={() => this.props.onCancelIssueNumber(issue_number)}>
                ยกเลิก
              </div>
            </div>
          )}
        </div>
      </li>
    )
  }

  render(){
    return (
      <div>
        {!_.isEmpty(this.props.issue_numbers) && (
          <div className='row mb-2'>
            <div className='col-12'><b>{this.props.title}</b></div>
            <div className='col-12'>
              <ul className="ul-group-list m-0">
                {_.map(this.props.issue_numbers, this.renderDepartmentNumber)}
              </ul>
            </div>
          </div>
        )}
      </div>
    )
  }
}
