import BaseStore from './BaseStore'

export class StateStore extends BaseStore {
  constructor(props) {
    super();

    this.endpoint = `workflows/${props.workflow_id}/states`
  }
}

export default StateStore;