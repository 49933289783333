import React, {Component} from 'react';
import _ from 'lodash';

export default class SetFrontPageAvailability extends Component {
  constructor(props){
    super(props)

    this.onChange = this.onChange.bind(this);
  }

  onChange(event){
    this.props.handleChange('has_front_page', event.target.checked);
  }

  render(){
    const { document } = this.props;

    return (
      <div className='row'>
        <div className='col-12'>
          
          <label className="form-check-label">
            <input 
              type="checkbox" 
              name='has_front_page' 
              value={document.has_front_page} 
              checked={document.has_front_page}
              className="mr-2" 
              onChange={this.onChange} />
              มีใบปะหน้า
          </label>
        </div>
      </div>
    )
  }
}