import { Component } from 'react';
import _ from 'lodash';

class DocumentParameters {

  _assign_attachments(data, state){
    if (!_.isEmpty(state.document.files)){
      _.forEach(state.document.files, function(file){
        if (!_.isEmpty(file['file_name'])){
          data.append('attachments[][id]', file['id'] || '');
          data.append('attachments[][file]', file['file']);
        }
      })
    }

    return data
  }

  _map_document_attributes(data, document_attributes){
    if (!_.isEmpty(document_attributes)){
      _.forEach(document_attributes, function(document_attr, key){
        data.append(`document_attributes[${key}][title]`, document_attr['title']);
        data.append(`document_attributes[${key}][name]`, document_attr['name']);
        data.append(`document_attributes[${key}][can_inline_edit]`, document_attr['can_inline_edit']);
        data.append(`document_attributes[${key}][suggest_text]`, document_attr['suggest_text']);
        data.append(`document_attributes[${key}][value]`, document_attr['value']);
      })
    }

    return data
  }

  _assign_document_attributes(data, state){
    if (!_.isEmpty(state.document_attributes)){
      _.forEach(state.document_attributes, function(document_attr, key){
        if (_.isArray(document_attr)){
          _.forEach(document_attr, function(v, k){
            data.append(`contents[][page_number]`, v['page_number']);
            data.append(`contents[][title]`, v['title']);
            data.append(`contents[][name]`, v['name']);
            data.append(`contents[][can_inline_edit]`, v['can_inline_edit']);
            data.append(`contents[][suggest_text]`, v['suggest_text']);
            data.append(`contents[][value]`, v['value']);
          })
        }else{ 
          data.append(`document_attributes[${key}][title]`, document_attr['title']);
          data.append(`document_attributes[${key}][name]`, document_attr['name']);
          data.append(`document_attributes[${key}][can_inline_edit]`, document_attr['can_inline_edit']);
          data.append(`document_attributes[${key}][suggest_text]`, document_attr['suggest_text']);
          data.append(`document_attributes[${key}][value]`, document_attr['value']);
        }
      })
    }

    return data
  }

  _assign_signees(data, state){
    if (!_.isEmpty(state.document.signees)){
      _.forEach(state.document.signees, function(signee){
        data.append('signees[][user_id]', signee.user_id || '');
        data.append('signees[][position_id]', signee.position_id || '');
        data.append('signees[][position_name]', signee.position_name || '');
      })
    }

    return data
  }

  _assign_throughs(data, state){
    if (!_.isEmpty(state.document.throughs)){
      _.forEach(state.document.throughs, function(through){
        data.append('throughs[][user_id]', through.user_id || '');
        data.append('throughs[][position_id]', through.position_id || '');
        data.append('throughs[][position_name]', through.position_name || '');
      })
    }

    return data
  }

  _assign_references(data, state){
    if (!_.isEmpty(state.document.references)){
      _.forEach(state.document.references, function(ref){
        data.append('references[][id]', ref['id'] || '');
        data.append('references[][reference_text]', ref['reference_text'] || '');
        data.append('references[][document_id]', ref['document_id'] || '');
      })
    }

    return data
  }

  _assign_informs(data, state){
    if (!_.isEmpty(state.document.informs)){
      _.forEach(state.document.informs, function(inform){
        
        data.append('informs[][id]', inform['id'] || '');

        if (!_.isEmpty(inform['informee_id'])){
          data.append('informs[][inform_text]', inform['position_name'] || '');
          data.append('informs[][informee_id]', inform['informee_id'] || '');
        }else{
          data.append('informs[][inform_text]', inform['name'] || '');

          if (!_.isEmpty(inform['department_group_ids'])){
            _.forEach(inform['department_group_ids'], function(department_group_id){
              data.append('informs[][department_group_ids][]', department_group_id);
            })
          }

        }
      })
    }

    return data
  }

  _assign_receive_document_informs(data, state){
    if (!_.isEmpty(state.informs)){
      _.forEach(state.informs, function(inform){
        
        data.append('informs[][id]', inform['id'] || '');
        data.append('informs[][inform_text]', inform['name'] || '');

        if (!_.isEmpty(inform['department_group_ids'])){
          _.forEach(inform['department_group_ids'], function(department_group_id){
            data.append('informs[][department_group_ids][]', department_group_id);
          })
        }

      });
    }

    return data
  }

}

export default new DocumentParameters();