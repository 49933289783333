import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Permission from './Permission';
import Pathname from './Pathname';

class SettingSidebar extends Component {
  render(){
    var fullPathname = Pathname.fullPathname(this.props.location);
    const { siteSetting } = this.props;

    return(
      <div className="settings-sidebar pl-4 pl-lg-0 sub-sidebar-menu">
        {Permission.isAdmin() && (
          <ul className="nav flex-column nav-pills py-4 ml-0 settings-sidebar" id="v-pills-tab" role="tablist">
            <li className="nav-item">
              <Link to='/settings/actings' className={`pl-3 nav-link ${(fullPathname.startsWith('/settings/actings')) && 'active'}`}>
                <i className="fa fa-cog mr-2" aria-hidden="true"></i>
                ตั้งค่ารักษาราชการ
              </Link>
            </li>
            <li className="nav-item">
              <Link to='/settings/sitesetting' className={`pl-3 nav-link ${(fullPathname.startsWith('/settings/sitesetting')) && 'active'}`}>
                <i className="fa fa-cog mr-2" aria-hidden="true"></i>
                ตั้งค่า site
              </Link>
            </li>
            <li className="nav-item">
              <Link to='/settings/signature' className={`pl-3 nav-link ${(fullPathname.startsWith('/settings/signature')) && 'active'}`}>
                <i className="fa fa-cog mr-2" aria-hidden="true"></i>
                จัดการลายมือชื่อ
              </Link>
            </li>
            <li className="nav-item">
              <Link to='/settings/workflow_migration' className={`pl-3 nav-link ${(fullPathname.startsWith('/settings/workflow_migration')) && 'active'}`}>
                <i className="fa fa-cog mr-2" aria-hidden="true"></i>
                ย้ายภาระงาน
              </Link>
            </li>
            <li className="nav-item">
              <Link to='/settings/system_notifications' className={`pl-3 nav-link ${(fullPathname.startsWith('/settings/system_notifications')) && 'active'}`}>
                <i className="fa fa-cog mr-2" aria-hidden="true"></i>
                แสดงข้อความแจ้งเตือน
              </Link>
            </li>
          </ul>
        )}

        {!Permission.isAdmin() && Permission.isSuperAdmin() && (
          <ul className="nav flex-column nav-pills py-4 ml-0 settings-sidebar" id="v-pills-tab" role="tablist">
            <li className="nav-item">
              <Link to='/settings/setup' className={`pl-3 nav-link ${(fullPathname.startsWith('/settings/setup')) && 'active'}`}>
                <i className="fa fa-cog mr-2" aria-hidden="true"></i>
                ตั้งค่า site
              </Link>
            </li>
          </ul>
        )}

        {(Permission.isSecretary() || Permission.isCorrespondence()) && (
          <ul className="nav flex-column nav-pills py-4 ml-0 settings-sidebar" id="v-pills-tab" role="tablist">
            <li className="nav-item">
              <Link to='/settings/actings' className={`pl-3 nav-link ${(fullPathname.startsWith('/settings/actings')) && 'active'}`}>
                <i className="fa fa-cog mr-2" aria-hidden="true"></i>
                ตั้งค่ารักษาราชการ
              </Link>
            </li>
          </ul>
        )}
      </div>
    )
  }
}

export default SettingSidebar;