import React, {Component} from 'react';
import _ from 'lodash';
import arrayMove from 'array-move';

import SimpleModal from '../SimpleModal';
import WorkflowProcessStore from '../../stores/WorkflowProcessStore';
import OriginalDocumentFiles from '../../components/documents/OriginalDocumentFiles';
import AttachedDocumentFiles from '../../components/documents/AttachedDocumentFiles';
import AfterSaveLoading from '../../components/AfterSaveLoading';

export default class HandleOriginalFiles extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDataFetched: false,
      original_document_files: [],
      attach_document_files: [],
      document_versions: [],
      current_files: [],
      attached_files: []
    }

    this.handleChangeOriginalDoc = this.handleChangeOriginalDoc.bind(this);
    this.onRemoveOriginalDocument = this.onRemoveOriginalDocument.bind(this);
    this.handleInsert = this.handleInsert.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
    this.handleChangeFileFromScanner = this.handleChangeFileFromScanner.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);

    this.handleChangeAttachDoc = this.handleChangeAttachDoc.bind(this);
    this.onRemoveAttachDocument = this.onRemoveAttachDocument.bind(this);

    this.renderRow = this.renderRow.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount(){
    if (!_.isEmpty(this.props.workflow_process_id)){
      WorkflowProcessStore.request({id: this.props.workflow_process_id}).then((resp)=>{
        const original_document_files = _.get(resp.data, 'data.attributes.original_documents', []);
        const attach_document_files = _.get(resp.data, 'data.attributes.attachments', []);
        const document_versions = _.get(resp.data, 'included.document_versions', []);
        const current_files = _.get(resp.data, 'included.current_files', []);
        const attached_files = _.get(resp.data, 'included.attached_files', []);

        this.setState({
          original_document_files: original_document_files,
          attach_document_files: attach_document_files,
          document_versions: document_versions,
          current_files: current_files,
          attached_files: attached_files
        });
      }).then(()=>{
        this.setState({isDataFetched: true});
      });
    }else{
      this.setState({isDataFetched: true});
    }
  }

  componentWillReceiveProps(nextProps){
    if (!_.isEmpty(nextProps.workflow_process_id) && nextProps.workflow_process_id != this.props.workflow_process_id){
      this.setState({
        isDataFetched: false, 
        original_document_files: [], 
        attach_document_files: [],
        document_versions: []
      });
      WorkflowProcessStore.request({id: nextProps.workflow_process_id, action: 'show_attachments'}).then((resp)=>{
        const original_document_files = _.get(resp.data, 'data.attributes.original_documents', []);
        const attach_document_files = _.get(resp.data, 'data.attributes.attachments', []);
        const document_versions = _.get(resp.data, 'included.document_versions', []);
        const current_files = _.get(resp.data, 'included.current_files', []);
        const attached_files = _.get(resp.data, 'included.attached_files', []);

        this.setState({
          original_document_files: original_document_files,
          attach_document_files: attach_document_files,
          document_versions: document_versions,
          current_files: current_files,
          attached_files: attached_files
        });
      }).then(()=>{
        this.setState({isDataFetched: true});
      });
    }
  }

  handleChangeOriginalDoc(event){
    var original_file = event.target.files[0];
    if (original_file.size === 0) { return }
    if (original_file.type !== "application/pdf"){
      alert("แนบไฟล์-เอกสารต้นฉบับ จำกัดประเภทเฉพาะไฟล์นามสกุล .pdf");
      return
    }

    const new_file = {
      file: original_file
    }

    let { original_document_files } = this.state;

    original_document_files = _.concat(original_document_files, [new_file]);

    this.setState({
      original_document_files: original_document_files
    });
  }

  handleInsert(files){
    this.setState({
      original_document_files: files
    });
  }

  handleReplace(files){
    this.setState({
      original_document_files: files
    });
  }

  onRemoveOriginalDocument(index){
    let { original_document_files } = this.state;
    original_document_files.splice(index, 1);

    this.setState({
      original_document_files: original_document_files
    });
  }

  onSortEnd({oldIndex, newIndex}){
    this.setState(({original_document_files}) => ({
      original_document_files: arrayMove(this.state.original_document_files, oldIndex, newIndex),
    }));
  }

  handleSave(event){
    const self = this;
    this.setState({isDataFetched: false});
    const { workflow_process_id } = this.props;
    const { original_document_files, attach_document_files } = this.state;
    var data = new FormData();

    if (!_.isEmpty(original_document_files) || !_.isEmpty(attach_document_files)){
      if (!_.isEmpty(original_document_files)){
        _.forEach(original_document_files, function(file){
          if (file.file){
            data.append('original_document_files[][id]', file['id'] || '');
            data.append('original_document_files[][file]', file.file);
          }
        })
      }

      if (!_.isEmpty(attach_document_files)){
        _.forEach(attach_document_files, function(file){
          if (file.file){
            data.append('attach_document_files[][id]', file['id'] || '');
            data.append('attach_document_files[][file]', file.file);
          }
        })
      }
    }

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      action: 'update_original_files',
      data: data
    }).then(()=>{
      window.location.href = this.props.after_save_redirect_to || "/receive_documents/mydoc";
    }, function(error){
      self.setState({isDataFetched: true});
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    });
  }

  urltoFile(url, filename, mimeType){
    return (fetch(url)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], filename, {type:mimeType});})
    );
  }

  handleChangeFileFromScanner(event){
    let self = this;

    const filename = event.target.getAttribute('data-filename');

    this.urltoFile(event.target.value, filename, 'application/pdf')
    .then(function(file){
        const new_file = { file: file };
        let { original_document_files } = self.state;
        original_document_files = _.concat(original_document_files, [new_file]);

        self.setState({
          original_document_files: original_document_files
        });
    })
  }

  handleChangeAttachDoc(event){
    var attach_file = event.target.files[0];
    if (attach_file.size === 0) { return }

    const new_file = {
      file: attach_file
    }

    let { attach_document_files } = this.state;
    attach_document_files = _.concat(attach_document_files, [new_file]);

    this.setState({ attach_document_files: attach_document_files });
  }

  onRemoveAttachDocument(index){
    let { attach_document_files } = this.state;
    attach_document_files.splice(index, 1);

    this.setState({
      attach_document_files: attach_document_files
    });
  }

  renderRow(file, index){
    return (
      <tr key={index}>
        <td style={{width: '20%'}}>{_.get(file, 'attributes.created_at')}</td>
        <td style={{width: '30%'}}>{_.get(file, 'attributes.creator_info.department_names', '')}</td>
        <td>
          <a href={file.attributes.url} target="_blank" className='text-primary'>
            {file.attributes.document_file_name}
          </a>
        </td>
      </tr>
    )
  }

  render(){
    const { open_insert_file, onClose } = this.props;
    const { original_document_files, document_versions, current_files, attach_document_files, attached_files } = this.state;
    return (
      <SimpleModal
        isOpen={open_insert_file}
        title={`ไฟล์แทรก ${_.size(current_files) + _.size(attached_files)} ไฟล์`}
        size={'modal-lg'}
        showSubmitButton={false}
        onClose={onClose}>

        {!this.state.isDataFetched && <AfterSaveLoading />}

        <h4>เอกสารหลัก {_.size(current_files)} ไฟล์</h4>
        <table className='table table-sm table-bordered table-striped'>
          <thead className='thead-dark'>
            <tr>
              <th>วันที่</th>
              <th>หน่วยงาน</th>
              <th>ชื่อไฟล์</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(current_files) && _.map(current_files, this.renderRow)}
          </tbody>
        </table>
        

        {this.state.isDataFetched && (
          <OriginalDocumentFiles
            original_document_files={original_document_files}
            handleChangeOriginalDoc={this.handleChangeOriginalDoc}
            onRemoveOriginalDocument={this.onRemoveOriginalDocument}
            handleInsert={this.handleInsert}
            handleReplace={this.handleReplace}
            onSortEnd={this.onSortEnd} />
        )}

        <h4>อื่นๆ {_.size(attached_files)} ไฟล์</h4>
        <table className='table table-sm table-bordered table-striped mt-3'>
          <thead className='bg-primary text-white'>
            <tr>
              <th>วันที่</th>
              <th>หน่วยงาน</th>
              <th>ชื่อไฟล์</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(attached_files) && _.map(attached_files, this.renderRow)}
          </tbody>
        </table>

        {this.state.isDataFetched && (
          <AttachedDocumentFiles
            attach_document_files={attach_document_files}
            handleChangeAttachDoc={this.handleChangeAttachDoc}
            onRemoveAttachDocument={this.onRemoveAttachDocument} />
        )}

        <div id="scanner_field" className='row mb-2' style={{display: 'none'}}>
          <div className='col-12 offset-md-2 col-md-10'>
            <span id="file_name_from_scanner" className="mr-2"></span>
            <input type="text" id="file_from_scanner" hidden onClick={this.handleChangeFileFromScanner} />
            <label className="btn btn-info" htmlFor="file_from_scanner">อัพโหลด</label>
          </div>
        </div>

        <div className="modal-footer row mt-3 pb-0">
          <button 
            type='button' 
            className="btn btn-primary pointer"
            onClick={this.handleSave}>อัพโหลด</button>
          <button type='button' className="btn btn-secondary pointer" onClick={onClose}>ยกเลิก</button>
        </div>
      </SimpleModal>
    )
  }
}