import React, { Component } from 'react';
import _ from 'lodash';
import { GroupType, PositionType, UserType } from '../../Constants';

class Actions extends Component {

	disabledButton(text){
		return (
			<button type='button' className='btn btn-success mr-2' disabled>
				<i className="fa fa-user" aria-hidden="true"></i>&ensp;{text}
			</button>
		)
	}

	render(){
		return (
			<div className='p-2 bg-light'>
				{this.props.focus.type === GroupType() ? (
					<button 
						type='button' 
						className='btn btn-success mr-2' 
						onClick={()=>this.props.onChangeMode(GroupType())}>
						<i className="fa fa-user" aria-hidden="true"></i>&ensp;
						Create Group
					</button>
				) : (
					this.disabledButton('Create Group')
				)}

				{this.props.focus.type === GroupType() ? (
					<button 
						type='button' 
						className='btn btn-success mr-2' 
						onClick={()=>this.props.onChangeMode(PositionType())}>
						<i className="fa fa-user" aria-hidden="true"></i>&ensp;
						Create Position
					</button>
				) : (
					this.disabledButton('Create Position')
				)}
				
				{this.props.focus.type === PositionType() ? (
					<button 
						type='button' 
						className='btn btn-success mr-2'
						onClick={()=>this.props.onChangeMode(UserType())}>
						<i className="fa fa-user" aria-hidden="true"></i>&ensp;
						Add User
					</button>
				) : (
					this.disabledButton('Add User')
				)}

				{_.isEmpty(this.props.focus.id) ? (
					<button type='button' className='btn btn-danger float-right' disabled>
						<i className="fa fa-times" aria-hidden="true"></i>&nbsp;
						Remove
					</button>
				) : (
					<button 
						type='button' 
						className='btn btn-danger float-right'
						onClick={()=>this.props.onDelete()}>
						<i className="fa fa-times" aria-hidden="true"></i>&nbsp;
						Remove
					</button>
				)}

				{_.isEmpty(this.props.focus.id) ? (
					<button type='button' className='btn btn-secondary float-right mr-2' disabled>
						<i className="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;
						Edit
					</button>
				) : (
					<button 
						type='button' 
						className='btn btn-secondary float-right mr-2'
						onClick={()=>this.props.onEdit()}>
						<i className="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;
						Edit
					</button>
				)}
			</div>
		)
	}
}

export default Actions;