import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import _ from 'lodash';
import './stylesheets/Form.css';

import { MergeAttributes } from '../FlatData';

class Form extends Component {
  constructor(){
    super();

    this.state = {
      id: null,
      selected: [],
      name: "",
      central: false,
      is_department: false,
      is_secretary: false,
      is_correspondence: false,
      is_office_of_secretary: false
    }

    this.onRowSelect = this.onRowSelect.bind(this);
    this.removeSelected = this.removeSelected.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setGroupName = this.setGroupName.bind(this);
    this.createCustomToolBar = this.createCustomToolBar.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let nextDepartmentGroup = nextProps.departmentGroup;
    if (nextDepartmentGroup.id && this.props.id !== nextDepartmentGroup.id) {
      let selected = nextDepartmentGroup.positions_users.map(pu => pu.id);

      this.setState({
        id: nextDepartmentGroup.id,
        selected: selected,
        name: nextDepartmentGroup.name,
        central: nextDepartmentGroup.central,
        is_department: nextDepartmentGroup.is_department,
        is_secretary: nextDepartmentGroup.is_secretary,
        is_correspondence: nextDepartmentGroup.is_correspondence,
        is_office_of_secretary: nextDepartmentGroup.is_office_of_secretary
      });
    } else {
      this.setState({
        id: null,
        selected: [],
        name: "",
        central: false,
        is_department: false,
        is_secretary: false,
        is_correspondence: false,
        is_office_of_secretary: false
      })
    }
  }

  onRowSelect(row, isSelected, e) {
    let selected = _.clone(this.state.selected)
    let isIncluded = _.includes(selected, row.id)

    if (isIncluded){
      selected = _.pull(selected, row.id)
    }else{
      selected = [...selected, row.id]
    }
    
    this.setState({selected: selected})
  }

  removeSelected(PositionUserId){
    this.setState({
      selected: _.pull(this.state.selected, PositionUserId)
    });
  }

  setGroupName(event){
    this.setState({ name: event.target.value });
  }

  handleSubmit(){
    if (!this.state.is_secretary && !this.state.is_correspondence){
      alert('กรุณาเลือกประเภท สำนักงานเลขานุการกรม, เลขาหน้าห้อง, สารบรรณกรม, สารบรรณกลาง หรือ สารบรรณหน่วยงาน');
      return
    }

    this.props.onSubmit({
      id: this.state.id,
      positions_user_ids: this.state.selected,
      name: this.state.name,
      central: this.state.central,
      is_department: this.state.is_department,
      is_secretary: this.state.is_secretary,
      is_correspondence: this.state.is_correspondence,
      is_office_of_secretary: this.state.is_office_of_secretary
    });
  }

  createCustomToolBar(props) {
    return (
      <div className='ml-auto col-6'>
        { props.components.searchPanel }
      </div>
    );
  }

  handleChange(department_group_type, event){
    let is_department = false;
    let is_central = false;
    let is_office_of_secretary = false;
    let is_secretary = false;
    let is_correspondence = true;

    if (department_group_type === 'department'){
      is_department = true;
    } else if (department_group_type === 'central') {
      is_central = true;
    } else if (department_group_type === 'office_of_secretary') {
      is_office_of_secretary = true;
    } else if (department_group_type === 'secretary') {
      is_secretary = true;
      is_correspondence = false;
    } else if (department_group_type === 'unit') {
      is_secretary = false;
    }

    this.setState({
      is_department: is_department,
      central: is_central,
      is_office_of_secretary: is_office_of_secretary,
      is_secretary: is_secretary,
      is_correspondence: is_correspondence
    });
  }

  render(){
    const data = MergeAttributes(this.props.positions_users);
    const selectRowProp = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: this.onRowSelect,
        selected: this.state.selected
    };
    const options = {
      sizePerPageList: [],
      toolBar: this.createCustomToolBar
    };

    const { is_secretary, is_correspondence, is_department, central, is_office_of_secretary } = this.state;

    return(
      <div id="department-group-form">
        <div className='card card-body mb-2 bg-light'>
          <div className="row form-group">
            <div className="col-md-2">
              <b>ชื่อกลุ่ม</b>
            </div>
            <div className="col-md-10">
              <input 
                type="text" 
                name="name" 
                className="form-control form-control-sm" 
                value={this.state.name} 
                onChange={this.setGroupName} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <b>ประเภท</b>
            </div>
            <div className="col-md-4">
              <input 
                type='radio' 
                name='department_group_type' 
                className='mr-2'
                checked={is_correspondence && is_office_of_secretary} 
                onChange={this.handleChange.bind(this, 'office_of_secretary')} /> 
                สำนักงานเลขานุการกรม
            </div>
            <div className="col-md-4">
              <input 
                type='radio' 
                name='department_group_type' 
                className='mr-2'
                checked={is_secretary && !is_correspondence} 
                onChange={this.handleChange.bind(this, 'secretary')} /> 
                เลขาหน้าห้อง
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
            </div>
            <div className="col-md-4 col-lg-4">
              <input 
                type='radio' 
                name='department_group_type' 
                className='mr-2'
                checked={is_correspondence && is_department} 
                onChange={this.handleChange.bind(this, 'department')}/> 
                สารบรรณกรม
            </div>
            <div className="col-md-4 col-lg-3">
              <input 
                type='radio' 
                name='department_group_type' 
                className='mr-2'
                checked={is_correspondence && central} 
                onChange={this.handleChange.bind(this, 'central')}/> 
                สารบรรณกลาง
            </div>
            <div className="col-md-4 col-lg-3">
              <input 
                type='radio' 
                name='department_group_type' 
                className='mr-2'
                checked={is_correspondence && !central && !is_department && !is_office_of_secretary}
                onChange={this.handleChange.bind(this, 'unit')}/> 
                สารบรรณหน่วยงาน
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7 rui-dp">
            <BootstrapTable 
              data={data}
              search={ true }
              multiColumnSearch={ true }
              selectRow={ selectRowProp }
              options={ options }
              striped
              hover
              condensed
              pagination
              version='4'
              tableContainerClass={'table-sm'}>
                <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
                <TableHeaderColumn dataField='position_name' columnTitle dataSort={ true }>ผู้ดำเนินการ</TableHeaderColumn>
                <TableHeaderColumn dataField='group_short_name' columnTitle dataSort={ true }>หน่วยงาน</TableHeaderColumn>
                <TableHeaderColumn dataField='group_code' columnTitle dataSort={ true }>รหัสหน่วยงาน</TableHeaderColumn>
            </BootstrapTable>
          </div>
          <div className="col-md-5 pl-md-0 mb-3">
            <h4>บุคคลที่ถูกเลือก {`(${this.state.selected.length})`}</h4>
            <div className={`${this.state.selected.length > 0 && "card"}`}>
              <ul id="selected-users" className="list-group list-group-flush m-0">
                {this.state.selected.map(PositionUserId => {
                  let position_user = _.find(this.props.positions_users, {id: PositionUserId});
                  return(
                    <li className="list-group-item" key={PositionUserId}>
                      { _.isEmpty(position_user.group_short_name) ? (
                        position_user.position_name
                      ) : (
                        `${position_user.position_name} - ${position_user.group_short_name}`
                      )}
                      <br />
                      {position_user.group_code}
                      <i className='fa fa-times close-icon text-danger' onClick={() => this.removeSelected(PositionUserId)}></i>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="modal-footer row">
          <button type='button' className="btn btn-primary pointer" onClick={this.handleSubmit}>บันทึกข้อมูล</button>
          <button type='button' className="btn btn-secondary pointer" onClick={this.props.onClose}>ยกเลิก</button>
        </div>
      </div>
    )
  }
}

export default Form;
