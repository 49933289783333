import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LineShareIcon from '../../assets/images/wide-default.png';

import Permission from '../../components/Permission';
import ForumStore from '../../stores/ForumStore';
import LoginSession from '../../stores/LoginSession';
import ForumForm from './ForumForm';

class ForumsPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      isDataFetched: false,
      forums: [],
      open: false,
      pagination: {},
      searchText: '',
      edit_topic_id: '',
      title: '',
      body: ''
    }

    this.loadData = this.loadData.bind(this);
    this.onCreateTopic = this.onCreateTopic.bind(this);
    this.onDeleteTopic = this.onDeleteTopic.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeBody = this.handleChangeBody.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onClose = this.onClose.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(){
    ForumStore.request({}).then((resp)=>{
      const forums = _.get(resp.data, 'data', {});
      this.setState({
        forums: forums
      })
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, topics: []});
    const { searchText } = this.state;

    ForumStore.request({url: `topics?page=${page}&searchText=${searchText}`}).then((resp)=>{
      const topics = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        topics: topics,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, topics: []});

    ForumStore.request({url: `topics?page=1&searchText=${searchText}`}).then((resp)=>{
      const topics = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        topics: topics,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  onCreateTopic(){
    this.setState({
      open: true,
      edit_topic_id: '',
      title: '',
      body: ''
    });
  }

  onEdit(forum, event){
    this.setState({
      open: true,
      edit_topic_id: forum.id,
      title: forum.attributes.title,
      body: forum.attributes.body
    });
  }

  onClose(){
    this.setState({
      open: false,
      edit_topic_id: '',
      title: '',
      body: ''
    });
  }

  handleChange(event){
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleChangeBody(value){
    this.setState({
      body: value
    });
  }

  handleSubmit(event){
    event.preventDefault();
    console.log('handleSave');

    const { edit_topic_id, title, body } = this.state;
    let self = this;
    ForumStore.save(edit_topic_id, {
      topic: {
        title: title,
        body: body
      }
    }).then(()=>{
      this.setState({
        open: false,
        edit_topic_id: '',
        title: '',
        body: ''
      }, this.loadData());
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  onDeleteTopic(topicId){
    if (!window.confirm('ยืนยันการลบกระทู้')) { return }

    ForumStore.delete(topicId).then(()=>{
      this.loadData();
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  renderRow(forum, index){
    const current_user_id = _.get(LoginSession.currentUser, 'id', '');
    const owner_id = _.get(forum.attributes, 'user.id', '');
    return (
      <div key={forum.id} className='forum-row row py-2 border-bottom'>
        <div className='col-10 text-black-50 mb-1'>
          {`สร้างกระทู้โดย ${forum.attributes.post_by} ${forum.attributes.created_at}`}
          {(forum.attributes.created_at !== forum.attributes.updated_at) && (
            <small className='ml-2 text-dark'>{`(มีการแก้ไขเนื้อหากระทู้ ${forum.attributes.updated_at})`}</small>
          )}
        </div>

        {Permission.isAdmin() && (
          <div className='col-2 text-right'>
            <button className='btn btn-danger' onClick={() => this.onDeleteTopic(forum.id)}>ลบกระทู้</button>
          </div>
        )}
        <div className='col-12 font-weight-bold'>
          <Link to={`/forums/${forum.id}`}>{forum.attributes.title}</Link>
        </div>
        <div className='col-12 mt-2'>
          <span className='text-muted mr-3 font-weight-bold'>
            <Link to={`/forums/${forum.id}`}>
              <i className="fa fa-comment mr-2" aria-hidden="true"></i>
              {forum.attributes.comments_count} Comments
            </Link>
          </span>
          <span className='text-muted font-weight-bold share-to-line-group'>
            <img 
              alt='LINE SHARE' 
              onClick={this.openLineShareWindow.bind(this, forum)} 
              src={LineShareIcon} 
              style={{height: '20px'}} />
          </span>
          {(current_user_id && owner_id && current_user_id === owner_id) && (
            <span className='ml-3 pointer text-muted font-weight-bold' onClick={this.onEdit.bind(this, forum)}>
              <u>แก้ไข</u>
            </span>
          )}
        </div>
      </div>
    )
  }

  openLineShareWindow(forum){
    const w = 503;
    const h = 510;
    const left = (window.screen.width/2)-(w/2);
    const top = (window.screen.height/2)-(h/2);
    const lineUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURI(window.location.origin + '/forums/' + forum.id)}`;

    window.open(lineUrl, '_blank', `height=${h},width=${w},top=${top},left=${left}`);
  }

  render() {
    const { forums, open, edit_topic_id, title, body } = this.state;
    return (
      <div className='m-3 forums'>

        <div className='row border-bottom'>
          <div className='col-6'>
            <h2>Webboard</h2>
          </div>
          <div className='col-6 text-right'>
            <button type='button' className='btn btn-success' onClick={this.onCreateTopic}>
              สร้างกระทู้
            </button>
          </div>
        </div>

        {!_.isEmpty(forums) && _.map(forums, this.renderRow)}

        <ForumForm 
          open={open}
          edit_topic_id={edit_topic_id}
          title={title}
          body={body}
          onClose={this.onClose}
          handleChange={this.handleChange}
          handleChangeBody={this.handleChangeBody}
          handleSubmit={this.handleSubmit} />
        
      </div>
    )
  }
}

export default ForumsPage;