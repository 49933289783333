import React, { Component } from 'react';
import _ from 'lodash';

import DashboardSidebar from '../components/DashboardSidebar';
import ReceiveDocumentSidebar from '../components/ReceiveDocumentSidebar';
import SendDocumentSidebar from '../components/SendDocumentSidebar';
import SettingSidebar from '../components/SettingSidebar';
import ReportSidebar from '../components/ReportSidebar';
import DepartmentGroupsSettingSidebar from '../components/DepartmentGroupsSettingSidebar';
import Pathname from '../components/Pathname';

class Sidebar extends Component {
  render(){
    const searchParam = _.get(this.props.location, 'search', '');
    const prefixPathname = Pathname.prefixPathname(this.props.location);
    const { siteSetting } = this.props;
    var AppSidebar;

    switch(prefixPathname) {
      case 'documents':
        AppSidebar = <DashboardSidebar location={this.props.location} />
        break;
      case 'receive_documents':
        AppSidebar = <ReceiveDocumentSidebar location={this.props.location} siteSetting={siteSetting}/>
        break;
      case 'send_documents':
        AppSidebar = <SendDocumentSidebar location={this.props.location} siteSetting={siteSetting}/>
        break;
      case 'settings':
        AppSidebar = <SettingSidebar location={this.props.location} siteSetting={siteSetting}/>
        break;
      case 'reports':
        AppSidebar = <ReportSidebar location={this.props.location} siteSetting={siteSetting}/>
        break;
      case 'manage_users':
        AppSidebar = <SettingSidebar location={this.props.location} siteSetting={siteSetting}/>
        break;
      case 'workflows':
        AppSidebar = <SettingSidebar location={this.props.location} siteSetting={siteSetting}/>
        break;
      case 'department_groups_settings':
        AppSidebar = <DepartmentGroupsSettingSidebar location={this.props.location} siteSetting={siteSetting}/>
        break;
      default:
        if (searchParam === "?activemenu=send_documents"){
          AppSidebar = <SendDocumentSidebar location={this.props.location} siteSetting={siteSetting}/>
        }else{
          AppSidebar = <DashboardSidebar location={this.props.location} />
        }
    }

    return(
      <div className="d-none d-lg-inline">
        {AppSidebar}
      </div>
    )
  }
}

export default Sidebar;