import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import qs from 'qs';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ReportStore from '../../stores/ReportStore';
import Loading from '../../components/Loading';

class InternalReceiveSummaryPage extends Component {
  constructor(props) {
    super(props)

    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    this.state = {
      isDataFetched: false,
      start_date: params.from,
      end_date: params.to,
      results: []
    }

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.documentDetailsFormatter = this.documentDetailsFormatter.bind(this);
    this.handleDownloadExcel = this.handleDownloadExcel.bind(this);
  }

  componentDidMount() {
    ReportStore.request({
      method: 'POST',
      action: 'summary_internal_receive',
      data: {
        start_date: this.state.start_date,
        end_date: this.state.end_date
      }
    }).then((resp)=>{
      const results = _.get(resp.data, 'data', []);

      this.setState({
        results: results
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleDownloadExcel(){
    axios({
      method:'POST',
      url: '/api/v1/reports/summary_internal_receive',
      responseType:'arraybuffer',
      cache: false,
      data: {
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        response_type: 'csv'
      }
    }).then(function(response) {
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' } )
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'หนังสือรับเข้าจำแนกตามหน่วยงานภายใน.xlsx'
      link.click()
    });
  }

  handleChangeStartDate(date){
    this.setState({start_date: date});
  }

  handleChangeEndDate(date){
    this.setState({end_date: date});
  }

  renderRow(receive_document, index){
    return (
      <tr key={index}>
        <td>{receive_document.attributes.issue_number}</td>
        <td>{receive_document.attributes.number}</td>
        <td>{receive_document.attributes.document_date_thai_format}</td>
        <td>{receive_document.attributes.from_department}</td>
        <td>
          {this.documentDetailsFormatter(receive_document)}
        </td>
        <td>{receive_document.attributes.created_at}</td>
        <td>{receive_document.attributes.created_at}</td>
      </tr>
    )
  }

  documentDetailsFormatter(row){
    console.log('row ==>', row)
    return (
      <div style={{whiteSpace: 'normal'}}>
        <div><u>เรียน</u> {row.attributes.to_department}</div>
        <div><u>เรื่อง</u> {row.attributes.topic}</div>
      </div>
    )
  }

  render(){
    const { start_date, end_date, results } = this.state;
    return (
      <div>
        {!this.state.isDataFetched && <Loading />}

        <div className='row'>
          <div className='col-12'>
            <h2>หนังสือรับเข้าจำแนกตามหน่วยงานภายใน</h2>
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-8'>
            จากวันที่ 
            <div className='ml-2' style={{display: 'inline-block'}}>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                selected={moment(start_date)}
                className='form-control form-control-sm'
                placeholderText={'DD/MM/YYYY'}
                onChange={this.handleChangeStartDate} />
            </div>
            <div className='mx-1' style={{display: 'inline-block'}}>ถึงวันที่</div>
            <div className='mr-2' style={{display: 'inline-block'}}>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                selected={moment(end_date)}
                className='form-control form-control-sm'
                placeholderText={'DD/MM/YYYY'}
                onChange={this.handleChangeEndDate} />
            </div>
            <div style={{display: 'inline-block'}}>
              <button type='button' className='btn btn-primary'>
                ค้นหา
              </button>
            </div>
          </div>
          <div className='col-4 text-right pt-3'>
            <button type='button' className='btn btn-success' onClick={this.handleDownloadExcel}>พิมพ์ MS-Excel</button>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>

            <table className='table table-bordered w-100'>
              <thead>
                <tr style={{backgroundColor: 'lightgray'}}>
                  <th>เลขผู้ส่ง</th>
                  <th>ที่</th>
                  <th>ลงวันที่</th>
                  <th>จาก</th>
                  <th width="250px">รายละเอียดหนังสือ</th>
                  <th width="100px">วันที่ส่ง</th>
                  <th width="100px">วันที่รับ</th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(results) && _.map(results, this.renderRow)}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(InternalReceiveSummaryPage)