import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import qs from 'qs';

import Document from '../../components/documents/Document';

import DocumentTemplateStore from '../../stores/DocumentTemplateStore';

class TemplatePage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			document_template: {}
		}
	}

	componentDidMount(){
    const self = this;

		DocumentTemplateStore.request({id: this.props.params.id}).then((resp)=>{
			let document_template = _.get(resp.data, 'data', {});

			this.setState({
				document_template: document_template
			});
		}).then(()=>{
			this.setState({loading: false});
		}, function(error){
        var error_message = _.get(error.response.data, 'error.message', '');
        alert(error_message)

        self.props.history.go(-1);
    });
	}

	render(){
		const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
		const { clone_document_id } = query;

  	return (
    	<div>
    		{!_.isEmpty(this.state.document_template) && (
    			<Document 
    				document_id={''}
    				document_template={this.state.document_template}
    				clone_document_id={clone_document_id} />
    		)}
    	</div>
  	)
	}
}

export default withRouter(TemplatePage);
