import React, { Component } from 'react';
import _ from 'lodash';
import { Popover } from 'reactstrap';

import UserStore from '../../../stores/UserStore';

const UserTab = 'user';

export default class RenderUsers extends Component {
	constructor(props){
		super(props)

		this.state = {
			popoverOpenUserId: '',
			popoverOpenPositionId: ''
		}

		this.renderUserRow = this.renderUserRow.bind(this);
		this.toggle = this.toggle.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if ((this.props.focus !== nextProps.focus) || nextProps.activeMode !== UserTab) {
			this.setState({popoverOpenUserId: '', popoverOpenPositionId: ''});
		}
	}

	toggle(user) {
		let user_id = user.user.id;
		let position_id = user.position.id;
		if (!_.isEmpty(this.state.popoverOpenUserId)) {
			user_id = '';
			position_id = '';
		}
    	this.setState({
      		popoverOpenUserId: user_id,
      		popoverOpenPositionId: position_id
    	});
  	}

  	handleSetDefaultPosition(user){
  		UserStore.request({
  			url: `users/${user.user.id}/set_default_position`,
  			method: 'put',
  			data: {
  				position_id: user.position.id
  			}
  		}).then((resp)=>{
  			this.setState({
  				popoverOpenUserId: '', popoverOpenPositionId: ''
  			}, this.props.onReloadUsers(this.props.focus));
  		});
  	}

  	handleRemovePosition(user){
  		if (window.confirm('Would you link to remove this item?')) {
	  		UserStore.request({
	  			url: `users/${user.user.id}/remove_position`,
	  			method: 'put',
	  			data: {
	  				position_id: user.position.id
	  			}
	  		}).then((resp)=>{
	  			this.setState({popoverOpenUserId: '', popoverOpenPositionId: ''}, this.props.onReloadUsers(this.props.focus));
	  		});
  		}else{
  			this.setState({popoverOpenUserId: '', popoverOpenPositionId: ''});
  		}
  	}

	renderUserRow(user, index){
		return (
			<li key={index} className='py-1' style={{borderBottom: '1px solid #eee'}}>
				<div className='row px-2'>
					<div className='col-1 text-center pr-0'>
						<i className="fa fa-user blue-icon" aria-hidden="true"></i>
					</div>
					<div className='col-10 pr-0'>
						{user.user.attributes.full_name}<br />
						<small className="text-muted font-italic">
							{this.renderStarIconForDefaultPosition(user)}
							{user.position.attributes.name}
						</small>
					</div>
					<div className='col-1 pl-0'>
						<i id={`Popover_${user.user.id}-${user.position.id}`} 
							className="fa fa-cog pointer gray-icon" 
							onClick={this.toggle.bind(this, user)}>
						</i>
						{(this.state.popoverOpenUserId === user.user.id && this.state.popoverOpenPositionId === user.position.id) && (
							<div>{this.renderPopoverUser(user)}</div>
						)}
					</div>
				</div>
			</li>
		)
	}

	renderStarIconForDefaultPosition(user){
		let star_icon = null;
		if (user.position.id === user.user.attributes.default_position_id) {
			star_icon = <i className="fa fa-star mr-1" aria-hidden="true"></i>
		}
		return (
			star_icon
		)
	}

	renderPopoverUser(user) {
		let open = false;
		if ((this.state.popoverOpenUserId === user.user.id) && (this.state.popoverOpenPositionId === user.position.id)){
			open = true;
		}
		return (
			<Popover placement="left" isOpen={open} target={`Popover_${user.user.id}-${user.position.id}`} toggle={this.toggle.bind(this, user)} style={{minWidth: '200px'}}>
			  	<ul className='p-0 m-0 popover-list'>
			  		<li className='px-2 py-1' onClick={this.handleSetDefaultPosition.bind(this, user)}>
			  			<i className="fa fa-user-plus mr-2 green-icon" aria-hidden="true"></i>
			  			Set Default Position
			  		</li>
			  		<li className='px-2 py-1' onClick={this.handleRemovePosition.bind(this, user)}>
			  			<i className="fa fa-user-times mr-2 red-icon" aria-hidden="true"></i>
			  			Remove From Position
			  		</li>
			  	</ul>
			</Popover>
		)
	}

	render(){
		return (
			<ul className='list-unstyled'>
				{_.map(this.props.users, this.renderUserRow)}
			</ul>
		)
	}
}