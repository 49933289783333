import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import arrayMove from 'array-move';

import SimpleModal from '../SimpleModal';
import AfterSaveLoading from '../AfterSaveLoading';
import DocumentStore from '../../stores/DocumentStore';
import WorkflowProcessStore from '../../stores/WorkflowProcessStore';
import Order from '../WorkflowActions/Order';

class ForwardToDepartmentModal extends Component {
  constructor(props){
    super(props)

    this.state = {
      isDataFetched: false,
      document: {},
      all_groups: [],
      root_group: {},
      internal_users: [],
      workflow_process: {},
      latest_note_text: '',
      clicked: false,
      document_versions: [],
      original_document_files: []
    }

    this.onSubmitAction = this.onSubmitAction.bind(this);
    this.handleChangeOriginalDoc = this.handleChangeOriginalDoc.bind(this);
    this.onRemoveOriginalDocument = this.onRemoveOriginalDocument.bind(this);
    this.handleInsert = this.handleInsert.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.preventMultiClicks = this.preventMultiClicks.bind(this);
  }

  componentDidMount(){
  }

  componentWillReceiveProps(nextProps) {
    const { document } = nextProps;
    const document_id = _.get(document, 'id', '');
    const workflow_process_id = _.get(document, 'attributes.workflow_process.id', '');

    if (!_.isEmpty(document_id) && !_.isEmpty(workflow_process_id)){
      DocumentStore.request({id: document_id}).then((resp)=>{
        const document = _.get(resp.data, 'data', {});
        const included = _.get(resp.data, 'included', {});
        const all_groups = included.all_groups || [];
        const root_group = included.root_group || [];
        const internal_users = included.internal_users || [];

        this.setState({
          document: document,
          all_groups: all_groups,
          root_group: root_group,
          internal_users: internal_users
        })
      }).then(()=>{
        this.getWorkflowProcess(workflow_process_id);
      });
    }
  }

  getWorkflowProcess(workflow_process_id){
    WorkflowProcessStore.request({id: workflow_process_id}).then((resp)=>{
      const workflow_process = _.get(resp.data, 'data', {});
      const included = _.get(resp.data, 'included', {});
      const document_versions = _.get(included, 'document_versions', []);
      const original_document_files = _.get(workflow_process, 'attributes.original_documents', []);
      const latest_note_text = _.get(workflow_process, 'attributes.latest_note_text', '');
      
      this.setState({ 
        original_document_files: original_document_files,
        workflow_process: workflow_process,
        document_versions: document_versions,
        latest_note_text: latest_note_text
      })
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleChangeOriginalDoc(event){
    var original_file = event.target.files[0];
    if (original_file.size === 0) { return }
    if (original_file.type !== "application/pdf"){
      alert("แนบไฟล์-เอกสารต้นฉบับ จำกัดประเภทเฉพาะไฟล์นามสกุล .pdf");
      return
    }

    const new_file = {
      file: original_file
    }

    let { original_document_files } = this.state;

    original_document_files = _.concat(original_document_files, [new_file]);

    this.setState({
      original_document_files: original_document_files
    });
  }

  onRemoveOriginalDocument(index){
    let { original_document_files } = this.state;
    original_document_files.splice(index, 1);

    this.setState({
      original_document_files: original_document_files
    });
  }

  handleInsert(files){
    this.setState({
      original_document_files: files
    });
  }

  handleReplace(files){
    this.setState({
      original_document_files: files
    });
  }

  onSortEnd({oldIndex, newIndex}){
    this.setState(({original_document_files}) => ({
      original_document_files: arrayMove(this.state.original_document_files, oldIndex, newIndex),
    }));
  }

  onSubmitAction(note_text, positions_user_ids, order_type, external_department){
    this.preventMultiClicks();
    this.setState({isDataFetched: false});
    var data = new FormData();

    if (!_.isEmpty(this.state.original_document_files)){
      _.forEach(this.state.original_document_files, function(file){
        if (file.file){
          data.append('original_document_files[][id]', file['id'] || '');
          data.append('original_document_files[][file]', file.file);
        }
      })
    }

    _.forEach(positions_user_ids, function(positions_user_id){
      data.append('targets[][positions_user_id]', positions_user_id);
    });

    data.append('action_name', 'ส่งต่อหน่วยงานอื่น');    
    data.append('note_data[note_text]', note_text || '');
    data.append('order_type', order_type || '');
    data.append('external_department', external_department || '');
    
    const workflow_process_id = this.state.workflow_process.id;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      data: data
    }).then(()=>{
      this.props.history.push("/receive_documents/mydoc");
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }
  
  preventMultiClicks(){
    this.setState({clicked: true});

    setTimeout(() => {
      this.setState({clicked: false});
    }, 30000);
  }

  render(){
    return (
      <SimpleModal 
        isOpen={this.props.open}
        title="ส่งต่อหน่วยงาน"
        size={'modal-lg'}
        showSubmitButton={false}
        onClose={this.props.onClose}>

        {this.state.isDataFetched ? (
          <Order  
          all_groups={this.state.all_groups}
          root_group={this.state.root_group}
          internal_users={this.state.internal_users}
          document={this.state.document}
          workflow_process={this.state.workflow_process}
          latest_note_text={this.state.latest_note_text}
          clicked={this.state.clicked}
          document_versions={this.state.document_versions}
          original_document_files={this.state.original_document_files}
          onSubmitAction={this.onSubmitAction}
          handleChangeOriginalDoc={this.handleChangeOriginalDoc}
          onRemoveOriginalDocument={this.onRemoveOriginalDocument}
          handleInsert={this.handleInsert}
          handleReplace={this.handleReplace}
          onSortEnd={this.onSortEnd}
          onCloseModal={this.props.onClose} />
        ) : (
          <AfterSaveLoading />
        )}
      </SimpleModal>
    )
  }
}

export default withRouter(ForwardToDepartmentModal);
