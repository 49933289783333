import React, { Component } from 'react';

class DocumentSettingPage extends Component {
  render(){
    return (
      <div>
        <h3>Document Setting</h3>
      </div>
    );
  }
}

export default DocumentSettingPage;