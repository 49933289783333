import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Link, withRouter } from 'react-router-dom';

import './stylesheets/OperatedDocumentsPage.css';

import { rowClassNameFormat, thaiDateFormatter, thaiDateTimeFormatter } from '../../utils/bootstrapTableFormatters';
import { DocumentOwner, SpeedLevelClassName } from '../../utils/Helper';
import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';
import SimpleModal from '../../components/SimpleModal';

import WorkflowProcessHistory from '../../components/WorkflowProcessHistory';
import WorkflowProcessStore from '../../stores/WorkflowProcessStore';
import Forward from '../../components/WorkflowActions/Forward';
import BatchForwarding from '../../components/BatchForwarding';
import ShowAttachedFiles from '../../components/documents/ShowAttachedFiles';
import HandleOriginalFiles from '../../components/documents/HandleOriginalFiles';

import PositionFilter from '../../components/documents/filters/PositionFilter';
import StatusFilter from '../../components/documents/filters/StatusFilter';

class OperatedDocumentsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      open_attached_file: false,
      open_insert_file: false,
      isDataFetched: false,
      workflow_process_id: '',
      workflow_process_id_history: '',
      workflow_process_id_attachment: '',
      workflow_processes: [],
      active_position_user_id: '',
      available_statuses: [],
      selectedWorkflowProcessIds: [],
      modal_form: "history",
      modal_title: "ประวัติการดำเนินการ การปฏิบัติงาน",
      internal_users: [],
      department_groups: [],
      actings: [],
      acting_positions_user_ids: [],
      active_status: '',
      pagination: {},
      searchText: ''
    }
    
    this.stateFormatter = this.stateFormatter.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.speedLevelFormatter = this.speedLevelFormatter.bind(this);
    this.documentDetailsFormatter = this.documentDetailsFormatter.bind(this);
    this.documentIssueNumberWithFilesFormatter = this.documentIssueNumberWithFilesFormatter.bind(this);
    this.documentReceiveNumberFormatter = this.documentReceiveNumberFormatter.bind(this);
    this.redirectToShowDocumentPage = this.redirectToShowDocumentPage.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleBatchForwardClick = this.handleBatchForwardClick.bind(this);
    this.handleBatchForward = this.handleBatchForward.bind(this);
    this.preventMultiClicks = this.preventMultiClicks.bind(this);
    this.getUnselectableDocuments = this.getUnselectableDocuments.bind(this);
    this.createBatchForwardButton = this.createBatchForwardButton.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
  }

  componentDidMount() {
    WorkflowProcessStore.request({url: 'workflow_processes?type=operated&page=1'}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const available_statuses = _.get(resp.data, 'available_statuses', []);
      const internal_users =  _.get(resp.data, 'included.internal_users', []);
      const department_groups =  _.get(resp.data, 'included.department_groups', []);
      const actings = _.get(resp.data, 'included.actings', []);
      const acting_positions_user_ids = _.map(actings, 'attributes.positions_user.id') || [];
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        available_statuses: available_statuses,
        internal_users: internal_users,
        department_groups: department_groups,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  redirectToShowDocumentPage(cell, row){
    this.props.history.push(`/templates/documents/${row.attributes.document.id}/show/${row.attributes.document_template.id}/wp/${row.id}`);  
  }

  stateFormatter(cell, row) {
    return (
      <div className='text-link pointer' style={{whiteSpace: 'normal'}} onClick={this.openModal.bind(this, row.id)}>
        {cell}
      </div>
    )
  }

  actionFormatter(cell, row) {
    return (
      <a href="#" className={'btn btn-sm btn-success'} onClick={this.handleRowForward.bind(this, row)}>
        ส่งต่อ
      </a>
    )
  }

  speedLevelFormatter(cell, row) {
    return (
      <div className={`text-${SpeedLevelClassName(row.attributes.speed_level)}`}>
        {row.attributes.speed_level}
        {_.size(row.original_documents) > 0 && (
          <i 
            className="fa fa-paperclip ml-2 text-danger" 
            aria-hidden="true" 
            onClick={this.handleInsertFile.bind(this, cell)}>
          </i>
        )}
      </div>
    )
  }

  documentDetailsFormatter(cell, row){
    return (
      <div style={{whiteSpace: 'normal'}}>
        <div><u>เรียน</u> {row.to_department}</div>
        <div><u>เรื่อง</u> {row.topic}</div>
        <DocumentOwner cell={cell} row={row} />

        {!_.isEmpty(row.attachment) && (
          <div>
            <u className='mr-2'>สิ่งที่ส่งมาด้วย</u> 
            <span>{row.attachment}</span>
          </div>
        )}
        {row.reference_issue_numbers && (
          <div><u>อ้างอิงเลขที่หนังสือ</u> {row.reference_issue_numbers}</div>
        )}
      </div>
    )
  }

  documentIssueNumberWithFilesFormatter(cell, row){
    const total_files_amount = _.size(row.original_documents) + row.attachment_count;
    return (
      <div>
        {row.workflow_name === "workflow_internal_receive" ? (
          <div>
            <div className='text-link pointer' onClick={() => this.redirectToShowDocumentPage(cell, row)}>
              {row.prev_issue_number || row.issue_number || "####"}
            </div>
            <div onClick={this.handleInsertFile.bind(this, cell)} className='font-weight-bold pointer text-link'>
              ไฟล์แทรก ({total_files_amount})
            </div>
          </div>
        ) : (
          <div>
            <div className='text-link pointer' onClick={() => this.redirectToShowDocumentPage(cell, row)}>
              {row.issue_number ? row.issue_number : "####"}
            </div>
            <div onClick={this.handleInsertFile.bind(this, cell)} className='font-weight-bold pointer text-link'>
              ไฟล์แทรก ({total_files_amount})
            </div>
          </div>
        )}

        {/*
        <div className='pointer text-link' onClick={this.handleShowAttachedFiles.bind(this, cell)}>
          <strong className='pointer'>ไฟล์แนบ ({row.attachment_count})</strong>
        </div>
        */}
      </div>
    )
  }

  documentReceiveNumberFormatter(cell, row){
    return (
      <div className='text-success'>{row.receive_number}</div>
    )
  }

  handleShowAttachedFiles(workflow_process_id, e){
    e.stopPropagation();
    this.setState({workflow_process_id_attachment: workflow_process_id, open_attached_file: true})
  }

  openModal(workflow_process_id, event){
    event.stopPropagation();

    this.setState({
      open: true,
      open_attached_file: false,
      open_insert_file: false,
      workflow_process_id_history: workflow_process_id,
    });
  }

  onChange(fieldname, value){
    this.setState({
      [fieldname]: value
    })
  }

  onClose(){
    this.setState({
      open: false,
      open_attached_file: false,
      open_insert_file: false,
      workflow_process_id: ''
    });
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, workflow_processes: []});
    const { active_status, active_position_user_id, searchText } = this.state;

    WorkflowProcessStore.request({url: `workflow_processes?type=operated&page=${page}&searchText=${searchText}&status=${active_status}&positions_user_id=${active_position_user_id}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, workflow_processes: []});
    const { active_status, active_position_user_id } = this.state;

    WorkflowProcessStore.request({url: `workflow_processes?type=operated&page=1&searchText=${searchText}&status=${active_status}&positions_user_id=${active_position_user_id}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSubmitSearch(){
    this.setState({isDataFetched: false, workflow_processes: []});
    const { searchText } = this.state;

    WorkflowProcessStore.request({url: `workflow_processes?type=operated&page=1&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  _setTableOption(){ 
      if(this.state.isDataFetched){
            return "ไม่มีข้อมูล";
        }else{
            return <Loading />
        }
  }

   onRowSelect(row, isSelected, e) {
    let selectedWorkflowProcessIds = _.clone(this.state.selectedWorkflowProcessIds)
    let isIncluded = _.includes(selectedWorkflowProcessIds, row.id)

    if (isIncluded){
      selectedWorkflowProcessIds = _.pull(selectedWorkflowProcessIds, row.id)
    }else{
      selectedWorkflowProcessIds = [...selectedWorkflowProcessIds, row.id]
    }
    
    this.setState({selectedWorkflowProcessIds: selectedWorkflowProcessIds})
  }

  createBatchForwardButton(onClick) {
    let hiddenClass = "";

    if(_.isEmpty(this.state.selectedWorkflowProcessIds)){
      hiddenClass = " d-none"
    }

    return (
      <button className={"btn btn-sm btn-success mt-3" + hiddenClass} onClick={ this.handleBatchForwardClick }>ส่งต่อ</button>
    );
  }

  handleRowForward(row){
    this.preventMultiClicks();
    
    this.onRowSelect(row, true);
    this.handleBatchForwardClick();
  }

  handleBatchForwardClick(){
    this.setState({ 
      modal_form: "forward",
      modal_title: "ส่งต่อ",
      open: true,
      open_attached_file: false,
      open_insert_file: false
    });
  }

  handleBatchForward(targets){
    this.preventMultiClicks();
    
    this.setState({
      modal_form: "batchForward",
      modal_title: "กำลังส่งต่อ",
    }, function(){
      this.forwardDocument(targets);
    });
  }

  forwardDocument(targets){
    let {currentForwardIndex, selectedWorkflowProcessIds} = this.state;
    const workflowProcessId = selectedWorkflowProcessIds[currentForwardIndex];

    if(currentForwardIndex < selectedWorkflowProcessIds.length){

      WorkflowProcessStore.request({
        id: workflowProcessId,
        method: 'put',
        data: {
          action_name: "ส่งต่อ",
          targets: targets
        },
      }).then(()=>{
        this.setState({
          currentForwardIndex: currentForwardIndex + 1
        }, function(){
          this.forwardDocument(targets);
        });
      }, function(error){
        let error_message = _.get(error.response.data, 'error.message', '');
        alert(error_message);
      });

    } else {
      this.setState({
        currentForwardIndex: 0
      }, function(){
        window.location.reload();
      });
    }

  }

  getUnselectableDocuments(documents){
    const unselectable = _.reject(documents, { 'workflow_name': 'workflow_internal_receive', 'current_state_name': 'รอนำเสนอเกษียน' })

    return _.map(unselectable, "id");
  }

  handleChangePosition(pu_id){
    this.setState({isDataFetched: false, workflow_processes: []});

    const { active_status, searchText } = this.state;
    WorkflowProcessStore.request({url: `workflow_processes?type=operated&positions_user_id=${pu_id}&status=${active_status}&page=1&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});
      
      this.setState({
        active_position_user_id: pu_id,
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleChangeStatus(status){
    this.setState({isDataFetched: false, workflow_processes: []});

    const { active_position_user_id, searchText } = this.state;
    WorkflowProcessStore.request({url: `workflow_processes?type=operated&status=${status}&positions_user_id=${active_position_user_id}&page=1&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});
      
      this.setState({
        active_status: status,
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  preventMultiClicks(){
    this.setState({clicked: true});

    setTimeout(() => {
      this.setState({clicked: false});
    }, 30000);
  }

  handleInsertFile(workflow_process_id, e){
    e.stopPropagation();
    this.setState({
      open_insert_file: true,
      workflow_process_id: workflow_process_id
    });
  }

  render(){
    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);
    const options = {
      sizePerPageList: [],
      sizePerPage: 10,
      page: active_page,
      noDataText: this._setTableOption(),
      // onRowClick: this.redirectToShowDocumentPage,
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500,
      insertBtn: this.createBatchForwardButton
    };
    var data = [];
    if (!_.isEmpty(this.state.workflow_processes)){
      data = MergeAttributes(this.state.workflow_processes);
    }
    
    /* ปิดชั่วคราว เพื่อเปลี่ยนการส่งต่อใหม่
    const selectRowProp = {
        mode: 'checkbox',
        onSelect: this.onRowSelect,
        selected: this.state.selected,
        unselectable: this.getUnselectableDocuments(data)
    };
    */

    const selectRowProp = {}

    return (
      <div className="inbox">
        <div className='row'>
          <div className='col-7 pr-0'>
            <h2>ปฏิบัติ</h2>
          </div>
        </div>

        <div className='rui-operateddoc'>
          <BootstrapTable 
            data={data}
            remote={ true }
            fetchInfo={{dataTotalSize: total_items_count}}
            search={ true }
            multiColumnSearch={ true }
            selectRow={ selectRowProp }
            options={ options }
            striped
            hover
            pagination
            version='4'
            tableContainerClass={'table-sm'}
            trClassName={rowClassNameFormat}
            insertRow>
            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            {/* ปิดชั่วคราว เพื่อเปลี่ยนการส่งต่อใหม่ <TableHeaderColumn dataField='id' dataFormat={this.actionFormatter} width='100'>ส่งต่อ</TableHeaderColumn> */}
            <TableHeaderColumn dataField='id' dataFormat={this.speedLevelFormatter} width='75'>ชั้นความเร็ว</TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.documentReceiveNumberFormatter} width='75'>เลขรับ</TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.documentIssueNumberWithFilesFormatter} width='140'>ที่</TableHeaderColumn>
            <TableHeaderColumn dataField='document_date_thai_format' dataFormat={thaiDateFormatter} width='90'>ลงวันที่</TableHeaderColumn>
            <TableHeaderColumn dataField='from_department' dataFormat={this.stateFormatter} columnTitle width="200">จาก</TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.documentDetailsFormatter}>รายละเอียดหนังสือ</TableHeaderColumn>
            <TableHeaderColumn dataField='created_at' dataFormat={thaiDateTimeFormatter} width='90'>วันที่ส่ง</TableHeaderColumn>
            <TableHeaderColumn dataField='received_at' dataFormat={thaiDateTimeFormatter} width='90'>วันที่รับ</TableHeaderColumn>
          </BootstrapTable>
        </div>

        <ShowAttachedFiles 
          workflow_process_id={this.state.workflow_process_id_attachment}
          open_attached_file={this.state.open_attached_file}
          onClose={this.onClose} />

        <HandleOriginalFiles 
          workflow_process_id={this.state.workflow_process_id}
          open_insert_file={this.state.open_insert_file}
          after_save_redirect_to={"/receive_documents/operated"}
          onClose={this.onClose} />

        <SimpleModal 
          isOpen={this.state.open}
          title={this.state.modal_title}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onClose}>

            {this.state.modal_form === "history" && (
              <div>
                <WorkflowProcessHistory id={this.state.workflow_process_id_history} />

                <div className="modal-footer row">
                  <button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ปิด</button>
                </div>
              </div>
            )}

            {this.state.modal_form === "forward" && (
              <Forward  internal_users={this.state.internal_users}
                        department_groups={this.state.department_groups}
                        acting_positions_user_ids={this.state.acting_positions_user_ids}
                        actings={this.state.actings}
                        onSubmitAction={this.handleBatchForward}
                        clicked={this.state.clicked}
                        onCloseModal={this.onClose} />
            )}

            {this.state.modal_form === "batchForward" && (
              <BatchForwarding  totalCount={this.state.selectedWorkflowProcessIds.length}/>
            )}
        </SimpleModal>
      </div>
    );
  }
}

export default withRouter(OperatedDocumentsPage);