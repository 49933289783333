import React, { Component } from 'react';
import _ from 'lodash';

import RenderChildren from './RenderChildren';

export default class RenderMainGroup extends Component {
  constructor(props) {
    super(props)

    let show = [];
    if (this.props.group && _.isEmpty(this.props.group.attributes.parent)) {
      show = [this.props.group.id];
    }

    this.state = {
      show: show,
      loading_item: {}
    }

    this.handleShowLoading = this.handleShowLoading.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  handleShowLoading(){
    this.setState({
      loading_item: {}
    });
  }

  handleShow(group){
    let { show } = this.state;
    show = _.concat(show, group.id);

    this.setState({show: show, loading_item: group});
  }

  handleHide(group){
    let { show } = this.state;
    show = _.pull(show, group.id);

    this.setState({show: show});
  }

  toggle(group, isShowChildren){
    var icon = null;
    if (_.isEmpty(group.attributes.children)) { return null }

    if (isShowChildren) {
      icon = <i className="fa fa-caret-down fa-lg pointer mr-2" aria-hidden="true" style={{color: '#6E2C00'}} onClick={this.handleHide.bind(this, group)}></i>
    }else{
      icon = <i className="fa fa-caret-right fa-lg pointer mr-2" aria-hidden="true" style={{color: '#6E2C00'}} onClick={this.handleShow.bind(this, group)}></i>
    } 
    
    return (
      icon
    )
  }

  handleFocus(){
    console.log("this.props", this.props);

    this.props.onFocus(this.props.group);
  }

  render() {
    var isShowChildren = false;
    var hasChild = false;
    var isFocus = false;

    if (!_.isEmpty(this.props.focus) && this.props.focus.id === this.props.group.id) {
      isFocus = true;
    }

    if (_.includes(this.state.show, this.props.group.id)) {
      isShowChildren = true;
    }

    if (!_.isEmpty(this.props.group.attributes.positions) || !_.isEmpty(this.props.group.attributes.children)){
      hasChild = true;
    }
    return (
      <li>
        {hasChild && (
          this.toggle(this.props.group, isShowChildren)
        )}

        <span className={`group-list pointer ${isFocus && 'focus-item'}`} onClick={this.handleFocus}>
          <i className="fa fa-folder-open-o mr-1" style={{color: '#D68910'}} aria-hidden="true"></i>
          <span className='mr-3'>{this.props.group.attributes.name}</span>
        </span>

        <RenderChildren 
          show={this.state.show}
          focus={this.props.focus}
          group={this.props.group}
          onFocus={this.props.onFocus}
          loading_item={this.state.loading_item}
          handleShowLoading={this.handleShowLoading} />
      </li>
    )
  }
}