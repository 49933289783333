import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import Pathname from './Pathname';
import Permission from './Permission';

import myDoc from '../assets/images/mydoc.png';
import operated from '../assets/images/operated.png';
import page_forward from '../assets/images/page_forward.png';
import page_back from '../assets/images/page_back.png';
import file_text from '../assets/images/file_text.png';
import page_find from '../assets/images/page_find.png';
import page_cancel from '../assets/images/page_cancel.png';

import WorkflowProcessStore from '../stores/WorkflowProcessStore';

class ReceiveDocumentSidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDataFetched: false,
      wait_for_receive_count: 0
    }
  }

  componentDidMount() {
    WorkflowProcessStore.request({url: 'wait_for_receive_count'}).then((resp)=>{
      this.setState({
        wait_for_receive_count: "(" + _.get(resp.data, 'data.wait_for_receive_count', []) + ")"
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }
  render(){
    var fullPathname = Pathname.fullPathname(this.props.location);
    return(
      <div className="documents-sidebar pl-4 pl-lg-0 sub-sidebar-menu">
        <ul className="nav flex-column nav-pills py-4 ml-0" id="v-pills-tab" role="tablist">
          <li className="nav-item">
            <Link to='/receive_documents/mydoc' className={`pl-3 nav-link ${(fullPathname === '/receive_documents/mydoc') && 'active'}`}>
              <img alt="Receive" src={page_back} className="mr-2" />
              หนังสือรอรับ <span className='wait-for-receive'>{this.state.wait_for_receive_count}</span>
            </Link>
          </li>
          {Permission.isCorrespondence() && ( 
            <li className="nav-item">
              <Link to='/receive_documents/internal_receive' className={`pl-3 nav-link ${(fullPathname === '/receive_documents/internal_receive') && 'active'}`}>
                <img alt="Draft" src={file_text} className="mr-2" />
                หนังสือรับใหม่
              </Link>
            </li>
          )}
          <li className="nav-item">
            <Link to='/receive_documents/operated' className={`pl-3 nav-link ${(fullPathname === '/receive_documents/operated') && 'active'}`}>
              <img alt="Operated" src={operated} className="mr-2" />
              ปฏิบัติ
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/receive_documents/search_receive_documents' className={`pl-3 nav-link ${(fullPathname === '/receive_documents/search_receive_documents') && 'active'}`}>
              <img alt="Search" src={page_find} className="mr-2" />
              ค้นหาทะเบียนหนังสือรับ
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/receive_documents/cancel' className={`pl-3 nav-link ${(fullPathname === '/receive_documents/cancel') && 'active'}`}>
              <img alt="Cancel" src={page_cancel} className="mr-2" />
              เอกสารยกเลิก
            </Link>
          </li>
        </ul>
      </div>
    )
  }
}

export default ReceiveDocumentSidebar;