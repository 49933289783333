import React, { Component } from 'react';
import _ from 'lodash';
// import { Document, Page } from 'react-pdf';

import './DocumentExample.css';

import TestPdf from '../../../assets/images/test.pdf';
import WorkflowProcessStore from '../../../stores/WorkflowProcessStore';

export default class DocumentExample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      numPages: null,
      pageNumber: 1,
      pdf_file: ''
    }
  }

  componentDidMount(){
    const workflow_process_id = this.props.workflow_process.id;
    if (!_.isEmpty(workflow_process_id)) {
      WorkflowProcessStore.request({id: workflow_process_id, action: 'pdf'}).then((resp)=>{
        let pdf_file = _.get(resp.data, 'data', {});
        this.setState({pdf_file: pdf_file});
      }).then(()=>{
        this.setState({loading: false});
      })
    }
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  }

  render(){
    const { pageNumber, numPages, pdf_file } = this.state;

    return (
      <div>
        {!_.isEmpty(pdf_file) && (
          <div className="row">
            <div className='col-12 text-center d-none d-lg-block'>
              <iframe style={{margin: '0 auto', width: '100%', height: '842px'}} src={pdf_file.attributes.url}></iframe>
            </div>
            <div className='col-12 text-center d-lg-none p-5'>
              <a href={pdf_file.attributes.url} target="_blank">
                ดาวน์โหลดไฟล์เอกสาร
              </a>
            </div>
          </div>
        )}

        {/*
        {!_.isEmpty(this.state.pdf_file) && (
          <div className='py-4 mt-2 bg-secondary col-10 ml-auto mr-auto'>
            <Document
              file={this.state.pdf_file.attributes.url}
              onLoadSuccess={this.onDocumentLoad}>
              {
                Array.from(
                  new Array(numPages),
                  (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      className={'mb-3'}
                      width={794}
                      pageNumber={index + 1}
                    />
                  ),
                )
              }
            </Document>
            <p className='text-center my-2'>Page {pageNumber} of {numPages}</p>
          </div>
        )}
      */}
      </div>
    )
  }
}
