import { Component } from 'react';
import { Quill } from 'react-quill';
import _ from 'lodash';

var Parchment = Quill.import('parchment');
var Delta = Quill.import('delta');
let Break = Quill.import('blots/break');
let Embed = Quill.import('blots/embed');
let Block = Quill.import('blots/block');

function lineBreakMatcher() {
  var newDelta = new Delta();
  newDelta.insert({'break': ''});
  return newDelta;
}

var options = {
  clipboard: {
    matchers: [
      ['BR', lineBreakMatcher] 
    ]
  },
  keyboard: {
    bindings: {
      linebreak: {
        key: 13,
        shiftKey: true,
        handler: function (range, context) {
          var nextChar = this.quill.getText(range.index + 1, 1)
          var nextCharBounds = this.quill.getBounds(range.index + 1);
          // var ee = this.quill.insertEmbed(range.index, 'break', true, 'user');
          // if (nextChar.length === 0) {
          //   // second line break inserts only at the end of parent element
          //   ee = this.quill.insertEmbed(range.index, 'break', true, 'user');
          // }
          // this.quill.setSelection(range.index + 1, Quill.sources.SILENT);

          var bounds = this.quill.getBounds(range.index);
          var bound_top = bounds.top;
          var next_bound_top = bounds.top;
          var bound_right = bounds.right;
          var currentIndex = range.index;

          while (bound_top === next_bound_top && bound_right < 612) {
            this.quill.insertText(currentIndex, ' ');
            currentIndex += 1;
            bounds = this.quill.getBounds(currentIndex);
            next_bound_top = bounds.top;
            bound_right = bounds.right;
          }

          this.quill.insertEmbed(currentIndex, 'break', true, 'user');
          if (nextChar.length === 0) { 
            // last char in the editor
            this.quill.insertEmbed(currentIndex, 'break', true, 'user');
          }else{
            if (nextCharBounds.left === 16){ 
              // nextChar is first text in new line
              this.quill.insertEmbed(currentIndex, 'break', true, 'user');
            }else{ 
              // nextChar is first text after current selection
            }
          }
          this.quill.setSelection(currentIndex + 1, Quill.sources.SILENT);
        }
      },
      custom_linebreak: {
        key: 32,
        handler: function (range, context) {

          console.log('quill ===>', this.quill)

          var range = this.quill.getSelection();
          if (range) {
            if (range.length === 0) {

              var currentChar = this.quill.getText(range.index, 1)

              if (currentChar !== ' '){
                this.quill.insertText(range.index, ' ');

                var nextIndex = range.index + 1;
                var bounds = this.quill.getBounds(nextIndex);
                console.log('1 bounds ---->', bounds)
                if (bounds.right === 16){
                  this.quill.insertEmbed(range.index, 'break', true, 'user');
                  this.quill.deleteText(range.index + 1, 1)
                }
              }else{
                var i = range.index + 1
                var nextChar = this.quill.getText(i, 1)
                while (nextChar === ' ') {
                  i++;
                  nextChar = this.quill.getText(i, 1)
                }

                if (nextChar !== ' '){
                  this.quill.insertText(range.index, ' ');

                  var nextIndex = i + 1;
                  var bounds = this.quill.getBounds(nextIndex);
                  console.log('2 bounds ---->', bounds)
                  if (bounds.right === 16){
                    this.quill.insertEmbed(i, 'break', true, 'user');
                    this.quill.deleteText(i + 1, 1)
                  }
                }
              }

            } else {
              var text = this.quill.getText(range.index, range.length);
              console.log('getBounds ==>', this.quill.getBounds(range.index))
            }
          }
        }
      },
      tab: {
        key: 9,
        handler: function (range, context) {
          // Handle tab
          // Tab size = 20
          if (context.offset === 0 || context.prefix.match(/^\s+$/)) {
            this.quill.insertText(range.index, String.fromCharCode(9)); 
          }else {
            var i;
            for (i = 0; i <= 19; i++) {
              this.quill.insertText(range.index + i, String.fromCharCode(160)); // 8195 = &emsp;, 8194 = &ensp;, 160 = &nbsp;
            }
          }
        }
      }

    }
  }
};

Break.prototype.insertInto = function(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref)
};
Break.prototype.length= function() {
    return 1;
}
Break.prototype.value= function() {
    return '\n';
}

class QuillShiftEnter extends Component {
  get optionsConfig() {
    return _.merge(options, {toolbar: {container: "#toolbar"}})
  }
}

export default new QuillShiftEnter();