import React from 'react';
import _ from 'lodash';
import moment from 'moment';

export function dateFormatter(cell, row){
  return moment(cell, "DD-MM-YYYY").format("DD/MM/YYYY");
}

export function issueNumberFormatter(cell, row){
  return _.isEmpty(cell) ? '####' : cell;
}

export function documentNumberFormatter(cell, row){
  return (
    <div>
      {row.workflow_name === "workflow_internal_receive" ? (
        <div>
          <div className='small'>{row.prev_issue_number}</div>
          <div className='text-success xs-small'>เลขที่รับ: {row.receive_number}</div>
        </div>
      ) : (
        <div>{row.issue_number ? row.issue_number : "####"}</div>
      )}
    </div>
  )
}

export function receiveNumberFormatter(cell, row){
  return (
    <div>
      <div className='small'>{cell}</div>
      <div className='text-muted small'>เลขที่รับ: {row.receive_number}</div>
    </div>
  )
}

export function thaiDateFormatter(cell, row){  
  if(cell && cell.match("29/02/2567")){
    return "29 ก.พ. 2567"
  }

  moment().locale('th');
  return moment(cell, "DD-MM-YYYY").format("Do MMM YYYY");
}

export function thaiDateTimeFormatter(cell, row){
  if(cell && cell.match("29/02/2567")){
    let time = cell.split(" ")[1].split(":");
    return `29 ก.พ. 2567 <br><i>${time[0]}:${time[1]}</i>`
  }

  return moment(cell, "DD-MM-YYYY HH:mm:ss").format("Do MMM YYYY <br><i>HH:mm</i>");
}

export function thaiShortDateFormatter(cell, row){
  if(cell && cell.match("29/02/2567")){
    return `29 ก.พ.`
  }

  return moment(cell, "DD-MM-YYYY HH:mm:ss").format("Do MMM");
}

export function thaiShortTimeFormatter(cell, row){
  if(cell && cell.match("29/02/2567")){
    let time = cell.split(" ")[1].split(":");
    return `${time[0]}:${time[1]}`
  }

  return moment(cell, "DD-MM-YYYY HH:mm:ss").format("HH:mm");
}

export function informsFormatter(cell, row){
  return cell.map(inform => inform.position_name).join(", ");
}

export function draftTextFormatter(cell, row){
  return row.issue_number ? row.issue_number : "Draft";
}

export function dashFormatter(cell, row){
  return "-";
}

export function rowClassNameFormat(row, rowIndex){
  switch(row.speed_level) {
    case "ด่วน":
        return "td-white-space-normal hurry";
        break;
    case "ด่วนมาก":
        return "td-white-space-normal very-hurry";
        break;
    case "ด่วนที่สุด":
        return "td-white-space-normal urgent";
        break;
    default:
      return "td-white-space-normal";
  }
}

export function rowClassNameWithPointerFormat(row, rowIndex){
  switch(row.speed_level) {
    case "ด่วน":
        return "td-white-space-normal hurry pointer";
        break;
    case "ด่วนมาก":
        return "td-white-space-normal very-hurry pointer";
        break;
    case "ด่วนที่สุด":
        return "td-white-space-normal urgent pointer";
        break;
    default:
      return "td-white-space-normal pointer";
  }
}

export function rowClassNameFormatWithUnread(row, rowIndex){
  if(row.speed_level === 'ด่วน'){
    return "td-white-space-normal hurry";
  } else if(row.speed_level === 'ด่วนมาก'){
    return "td-white-space-normal very-hurry";
  } else if(row.speed_level === 'ด่วนที่สุด'){
    return "td-white-space-normal urgent";
  } else if(row.unread === true){
    return "td-white-space-normal unread";
  } else {
    return "td-white-space-normal";
  }
}
