import React, { Component } from 'react';

import IssueNumberTable from '../IssueNumberTable';

class IssueNumber extends Component {
  constructor(){
    super();

    this.state = {
      issue_number_id: null
    }

    this.handleSelectIssueNumber = this.handleSelectIssueNumber.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSelectIssueNumber(row, isSelected, e){
    this.setState({
      issue_number_id: row.id
    });
  }

  handleSubmit(){
    this.props.onSubmitAction(this.state.issue_number_id);
  }

  render(){
    return(
      <div>
        <IssueNumberTable 
          workflow_process={this.props.workflow_process}
          is_front_page={this.props.is_front_page}
          onSelect={this.handleSelectIssueNumber}
          typeName={this.props.type_name} />

        <div className="modal-footer row">
              <button type='button' className="btn btn-primary pointer" disabled={this.props.clicked} onClick={this.handleSubmit}>ยืนยัน</button>
          <button type='button' className="btn btn-secondary pointer" onClick={this.props.onCloseModal}>ยกเลิก</button>
        </div>
      </div>
    )
  }
}

export default IssueNumber;
