import React, { Component } from 'react'

class NewWorkflowForm extends Component {
  constructor(){
    super();

    this.setAttributeValue = this.setAttributeValue.bind(this)
  }

  setAttributeValue(event) {
    const field_name = event.target.name;
    const value = event.target.value;

    this.setState({
      [field_name]: value
    });

    console.log("this.state", this.state);
  }

  render(){
    return(
      <div id="new-workflow-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" className="form-control" onChange={this.setAttributeValue} />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea name="description" className="form-control" onChange={this.setAttributeValue}></textarea>
        </div>
        <button type="submit" className="btn btn-primary" onClick={() => this.props.onSubmit({...this.state})}>Create</button>
      </div>
    )
  }
}

export default NewWorkflowForm;
