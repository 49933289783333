import React, {Component} from 'react';
import _ from 'lodash';

import { MergeAttributes } from '../../FlatData';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import SimpleModal from '../../SimpleModal';

const UpLine = 'line';
const AllUsers = 'all';
const EditingFieldName = 'number_issuer_id';

export class NumberIssuerForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			open: false,
			activeMode: UpLine
		}

		this.positionFormat = this.positionFormat.bind(this);
		this.departmentFormat = this.departmentFormat.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.onNumberIssuerOpen = this.onNumberIssuerOpen.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onChangeCircular = this.onChangeCircular.bind(this);
	}

	positionFormat(cell, row){
		return (
			<div style={{whiteSpace: 'normal'}}>{row.position_name}</div>
		)
	}

	departmentFormat(cell, row){
		return (
			<div style={{whiteSpace: 'normal'}}>{row.department_name}</div>
		)
	}

	onSelect(row, isSelected, e){
		const { document_template } = this.props;

		this.setState({open: false}, this.props.handleChange(EditingFieldName, row.id));

		if (document_template.attributes.const_value !== "record"){
			// Auto assign signee
			const signees = row.attributes.default_informee;
			const levels = _.map(signees, (s) => s.level);

			// Auto update set fornt page availability
			if (document_template.attributes.enable_front_page) {
				if (!_.isEmpty(signees) && _.includes(levels, document_template.attributes.signee_min_level)){
					this.props.handleChange('has_front_page', true);
				}else{
					this.props.handleChange('has_front_page', false);
				}
			}

			this.setState({open: false}, this.props.handleChange('signees', signees));	
		}
	}

	onNumberIssuerOpen(){
		this.setState({open: true});
	}

	onClose(){
		this.setState({open: false});
	}

	onChangeCircular(event){
		this.props.handleChange('is_circular', event.target.checked);
	}

	renderInternalNumberIssuers(){
		const options = {
		  	onRowClick: this.onSelect,
			sizePerPageList: [],
			sizePerPage: 6
		};
		var data = MergeAttributes(this.props.internal_number_issuers);
	   	var data =  _.filter(data, function(d) {
		                return d.is_actor == false
		            });
		return (
			<BootstrapTable 
		  	data={data}
				search={ true }
				multiColumnSearch={ true }
				options={ options }
				striped
				hover
				condensed
				pagination
				version='4'
				tableContainerClass={'table-sm'}>
			  		<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
					<TableHeaderColumn 
						dataField='full_name' 
						dataSort={ true } 
						columnClassName='pointer'>
						ชื่อ-สกุล
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='position_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.positionFormat}>
						ตำแหน่ง
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='department_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.departmentFormat}>
						แผนก
					</TableHeaderColumn>
			</BootstrapTable>
		)
	}

	renderCentralNumberIssuers(){
		const options = {
		  onRowClick: this.onSelect,
			sizePerPageList: [],
			sizePerPage: 6
		};
		var data = MergeAttributes(this.props.central_number_issuers);
		return (
			<BootstrapTable 
		  		data={data}
				search={ true }
				multiColumnSearch={ true }
				options={ options }
				striped
				hover
				condensed
				pagination
				version='4'
				tableContainerClass={'table-sm'}>
			  		<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
					<TableHeaderColumn 
						dataField='full_name' 
						dataSort={ true } 
						columnClassName='pointer'>
						ชื่อ-สกุล
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='position_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.positionFormat}>
						ตำแหน่ง
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='department_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.departmentFormat}>
						แผนก
					</TableHeaderColumn>
			</BootstrapTable>
		)
	}

	renderLineUsers(){
		const options = {
		  	onRowClick: this.onSelect,
			sizePerPageList: [],
			sizePerPage: 6
		};
		var data = MergeAttributes(this.props.users);
	   	var data =  _.filter(data, function(d) {
		                return d.is_actor == false
		            });

		return (
			<BootstrapTable 
		  		data={data}
				search={ true }
				multiColumnSearch={ true }
				options={ options }
				striped
				hover
				condensed
				pagination
				version='4'
				tableContainerClass={'table-sm'}>
			  	<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
					<TableHeaderColumn 
						dataField='full_name' 
						dataSort={ true } 
						columnClassName='pointer'>
						ชื่อ-สกุล
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='position_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.positionFormat}>
						ตำแหน่ง
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='department_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.departmentFormat}>
						แผนก
					</TableHeaderColumn>
			</BootstrapTable>
		)
	}

	render(){
		return (
			<div>
				<button 
					type='button' 
					className='btn btn-sm btn-primary mr-2'
					onClick={this.onNumberIssuerOpen}>ระบุผู้ออกเลข</button>
				
				{this.props.document_template.attributes.is_circular && (
				    <label className="form-check-label">
				      	<input 
				      		type="checkbox" 
				      		name='is_circular' 
				      		value={this.props.is_circular} 
				      		checked={this.props.is_circular}
				      		className="mr-2" 
				      		onChange={this.onChangeCircular} />
				      		เวียน
				    </label>
				)}

			    <SimpleModal 
					isOpen={this.state.open}
					title={'เลือกผู้ออกเลข'}
					size={'modal-lg'}
					showSubmitButton={false}
					onClose={this.onClose}>
						{this.props.document_template.attributes.name === "หนังสือประทับตรา" ? (
							<div className="mt-3">
							  	{this.renderCentralNumberIssuers()}
							</div>
						) : (
							<div>
								<ul className="nav nav-tabs" id="ReferenceDocuments" role="tablist">
								  	<li className="nav-item">
										<span className={`nav-link pointer ${(this.state.activeMode === UpLine) && 'active'}`} 
										href='#'
										onClick={()=>this.setState({activeMode: UpLine})}>
											ตามสายงาน
										</span>
								  	</li>
								  	<li className="nav-item">
										<span className={`nav-link pointer ${(this.state.activeMode === AllUsers) && 'active'}`} 
										href="#"
										onClick={()=>this.setState({activeMode: AllUsers})}>
											ทั้งหมด
										</span>
								  	</li>
								</ul>
								<div className="tab-content mt-3" id="ReferenceDocuments">
								  	<div className={`tab-pane fade ${(this.state.activeMode === UpLine) && 'show active'}`}>
								  		{this.renderInternalNumberIssuers()}
								  	</div>
								  	<div className={`tab-pane fade ${(this.state.activeMode === AllUsers) && 'show active'}`}>
								  		{this.renderLineUsers()}
								  	</div>
								</div>
							</div>
						)}
				</SimpleModal>
			</div>
		)
	}
}

export class RenderNumberIssuers extends Component {
	render() {
		var number_issuer = '';
		if (this.props.number_issuer_id){
			number_issuer = _.find(this.props.users, {id: this.props.number_issuer_id});
		}

		const on_duty = _.find(this.props.actings, (acting) => {
											return acting.attributes.user && acting.attributes.user.id === number_issuer.user_id
										});
		return (
			<div>
				{number_issuer && on_duty && (
		    	<ul className='pl-4 mb-0 small mt-2'>
		    		<li style={{position: 'relative'}} className="bg-danger text-white p-2">
		    			<i className="fa fa-times text-black top-right pointer" onClick={this.props.onDeleteNumberIssuer}></i>
          		<i className='fa fa-eject text-black top-right pointer' onClick={() => this.props.onShowActorsForm(number_issuer, "number_issuer")}></i>
		    			<span className='mr-1'>{number_issuer.attributes.full_name}</span>
		    			<span>({number_issuer.attributes.position_name})</span>
		    		</li>
		    	</ul>
		    )}
				{number_issuer && !on_duty && (
		    	<ul className='pl-4 mb-0 small mt-2'>
		    		<li>
		    			<span className='mr-1'>{number_issuer.attributes.full_name}</span>
		    			<span>({number_issuer.attributes.position_name})</span>
		    		</li>
		    	</ul>
		    )}
		  </div>
		)
	}
}


