import React, { Component } from 'react';
import _ from 'lodash';
import './stylesheets/EditDocumentPage.css';

import Document from '../../components/documents/Document';

import DocumentTemplateStore from '../../stores/DocumentTemplateStore';

export default class EditDocumentPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			document_id: this.props.params.id,
			template_id: this.props.params.template_id,
			document_template: {}
		}
	}

	componentDidMount(){
		DocumentTemplateStore.request({id: this.props.params.template_id}).then((resp)=>{
			let document_template = _.get(resp.data, 'data', {});

			this.setState({
				document_template: document_template
			});
		}).then(()=>{
			this.setState({loading: false});
		})
	}

	render(){
	  	return (
	    	<div>
	    		{!_.isEmpty(this.state.document_template) && (
	    			<Document 
	    				document_id={this.props.params.id} 
	    				document_template={this.state.document_template} />
	    		)}
	    	</div>
	  	)
	}
}
