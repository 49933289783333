import React, { Component } from 'react';
import _ from 'lodash';

import OrganizationalStructure from '../components/organizational_structures/OrganizationalStructure';
import RenderOrganization from '../components/organizational_structures/RenderOrganization';
import Loading from '../components/Loading';

import GroupStore from '../stores/GroupStore';

class OrganizationPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      focus: {},
      group: {}
    }

    this.handleFocus = this.handleFocus.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  handleFocus(item){
    this.setState({ focus: item }, this.loadData(item));
  }

  loadData(group){
    this.setState({loading: true});
    
    GroupStore.request({id: group.id, action: 'get_group_users'}).then((resp)=>{
      const group = _.get(resp.data, 'data', {});

      this.setState({
        group: group
      })
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  render(){
    const { loading, focus, group } = this.state;

    return (
      <div className='row'>
        <div className="col-md-4" style={{fontSize: '1.1rem', height: '550px', overflowY: 'scroll', borderBottom: '1px solid lightgray'}}>
          <OrganizationalStructure 
            focus={focus} 
            onFocus={this.handleFocus} />
        </div>
        <div className="col-md-8 bg-white p-4 inbox-body" style={{height: '550px', overflowY: 'scroll'}}>
          {loading ? (
            <Loading />
          ) : (
            <RenderOrganization 
              group={group} />
          )}
        </div>
      </div>
    );
  }
}

export default OrganizationPage;