import React, {Component} from 'react';

const EditingFieldName = 'respect';

export default class InlineEditRespect extends Component {
	constructor(props){
		super(props)

		this.state = {
			editing: false,
			respect: this.props.respect
		}

		this.handleEdit = this.handleEdit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSetValue = this.handleSetValue.bind(this);
	}

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading){
      this.setState({
        respect: nextProps.respect
      })
    }
  }

	handleEdit(){
		this.setState({editing: true})
	}

	handleChange(event){
		this.setState({respect: event.target.value});
	}

	handleSetValue(){
		let { respect } = this.state;
		this.setState({editing: false}, this.props.handleChange(EditingFieldName, respect));
	}

	render(){
		return (
			<div className='row pt-3'>
				<div className='col-7 ml-auto text-center'>
					<div className='row'>
						<div className='col-12'>
							{this.state.editing ? (
								<div className='row'>
									<div className='col-10'>
										<input 
											type='text' 
											name='respect' 
											value={this.state.respect} 
											className='form-control form-control-sm'
											autoFocus
											onBlur={this.handleSetValue} 
											onChange={this.handleChange} />
									</div>
									<div className='col-2'>
										<button 
											type='button' 
											className='btn btn-sm btn-primary'
											onClick={this.handleSetValue}>ตกลง</button>
									</div>
								</div>

							) : (
								<div>
									{this.props.respect ? (
										<div className='pointer' onClick={this.handleEdit}>
											{this.props.respect}
										</div>
									) : (
										<div className='pointer text-danger' onClick={this.handleEdit}>
											<em>คำลงท้าย</em>
										</div>
									)}
								</div>
							)}
							
						</div>
					</div>
				</div>
			</div>
		)
	}
}

