import React, { Component } from 'react';

import Main from './Main';
import TopHeader from './TopHeader';
import Header from './Header';
import Sidebar from './Sidebar';

export default class MainLayout extends Component {

  	render() {
		return (
			<div>
				{/*
					<TopHeader siteSetting={this.props.siteSetting} />
				*/}
				<Header location={this.props.location} siteSetting={this.props.siteSetting} />
				<div className='container-fluid pb-4'>
					<div className="row">
						<div className="col-lg-2 d-none d-lg-inline px-lg-0">
							<Sidebar location={this.props.location} siteSetting={this.props.siteSetting}/>
						</div>
						<div className="col-lg-10 bg-white p-3 px-lg-4 py-lg-3 inbox-body">
							<Main />
						</div>
					</div>
				</div>
			</div>
		)
  	}
}
