import React, { Component } from 'react';

const AdditionalSettingSection = (props) => {
  return(
    <div>
      <h3>การตั้งค่า</h3>
      
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title font-weight-bold">การรันเลขหนังสือ</h5>

          <div className="form-check form-check-inline">
            <input 
              className="form-check-input" 
              type="radio" 
              name="running_number_by" 
              id="running_number_by_0" 
              value="fiscal_year" 
              checked={props.site_setting.running_number_by == "fiscal_year"}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="running_number_by_1">ตามปีปฏิทิน</label>
          </div>
          <div className="form-check form-check-inline">
            <input 
              className="form-check-input" 
              type="radio" 
              name="running_number_by" 
              id="running_number_by_1" 
              value="calendar_year"
              checked={props.site_setting.running_number_by == "calendar_year"}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="running_number_by_2">ตามปีงบประมาณ</label>
          </div>
          <div className="form-check form-check-inline">
            <input 
              className="form-check-input" 
              type="radio" 
              name="running_number_by" 
              id="running_number_by_2" 
              value="custom_date" 
              checked={props.site_setting.running_number_by == "custom_date"}
              onChange={props.onUpdateAdditionalSetting} />
            <input 
              type="text" 
              className="form-control" 
              placeholder="dd/mm"
              onChange={props.onUpdateCustomerRunningNumberDate} />
          </div>
        </div>
      </div>
      
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title font-weight-bold">การกำหนดรูปแบบสมุดทะเบียน</h5>

          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="separated_number_book_by" 
              id="separated_number_book_by_0" 
              value="merged"
              checked={props.site_setting.separated_number_book_by == "merged"}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="separated_number_book_by_0">รวมเล่ม</label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="separated_number_book_by" 
              id="separated_number_book_by_1" 
              value="secret_level"
              checked={props.site_setting.separated_number_book_by == "secret_level"}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="separated_number_book_by_1">แยกเล่มตามชั้นความลับ</label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="separated_number_book_by" 
              id="separated_number_book_by_2" 
              value="template"
              checked={props.site_setting.separated_number_book_by == "template"}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="separated_number_book_by_2">แยกเล่มตามประเภทหนังสือ</label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="separated_number_book_by" 
              id="separated_number_book_by_3" 
              value="category"
              checked={props.site_setting.separated_number_book_by == "category"}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="separated_number_book_by_3">แยกเล่มตามหมวดหมู่</label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="separated_number_book_by" 
              id="separated_number_book_by_4" 
              value="custom_by_user"
              checked={props.site_setting.separated_number_book_by == "custom_by_user"}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="separated_number_book_by_4">ผู้ใช้งานกำหนดเอง</label>
          </div>
        </div>
      </div>
      
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title font-weight-bold">การแสดงหนังสือที่มีชั้นความลับบนหน้าจอ</h5>

          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="show_secret_document_content" 
              id="show_secret_document_content_0" 
              value="true"
              checked={props.site_setting.show_secret_document_content == true}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="show_secret_document_content_0">แสดงหนังสือที่มีชั้นความลับ</label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="show_secret_document_content" 
              id="show_secret_document_content_0" 
              value="false"
              checked={props.site_setting.show_secret_document_content == false}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="show_secret_document_content_1">ไม่แสดงหนังสือที่มีชั้นความลับ</label>
          </div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-body">
          <div className="form-inline">
            อนุญาตให้บันทึกข้อมูลย้อนหลังสูงสุด
            <input 
              type="text" 
              name="backup_days" 
              className="form-control mx-3" 
              size="3" 
              value={props.site_setting.backup_days}
              onChange={props.onUpdateAdditionalSetting} />
            วัน
          </div>
        </div>
      </div>
      
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title font-weight-bold">การรับหนังสือแทน ในกรณีที่เจ้าหน้าที่ผู้รับผิดชอบไม่อยู่</h5>

          <div className="form-check form-check-inline">
            <input 
              className="form-check-input" 
              type="radio" 
              name="enable_receive_others_document" 
              id="enable_receive_others_document"
              value="true"
              checked={props.site_setting.enable_receive_others_document == true}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="enable_receive_others_document">เปิดใช้งาน</label>
          </div>
          <div className="form-check form-check-inline">
            <input 
              className="form-check-input" 
              type="radio" 
              name="enable_receive_others_document" 
              id="enable_receive_others_document"
              value="false"
              checked={props.site_setting.enable_receive_others_document == false}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="enable_receive_others_document">ปิดการใช้งาน</label>
          </div>
        </div>
      </div>
      
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title font-weight-bold">การแสดงชื่อหน่วยงาน</h5>

          <div className="form-check form-check-inline">
            <input 
              className="form-check-input" 
              type="radio" 
              name="shorten_department_name" 
              id="shorten_department_name_0" 
              value="true"
              checked={props.site_setting.shorten_department_name == true}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="shorten_department_name_0">แบบเต็ม</label>
          </div>
          <div className="form-check form-check-inline">
            <input 
              className="form-check-input" 
              type="radio" 
              name="shorten_department_name" 
              id="shorten_department_name_0" 
              value="false"
              checked={props.site_setting.shorten_department_name == false}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="shorten_department_name_1">แบบย่อ</label>
          </div>
        </div>
      </div>
      
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title font-weight-bold">การออกเลขรับหนังสือ กรณีเป็นหนังสือที่เคยรับแล้ว</h5>

          <div className="form-check form-check-inline">
            <input 
              className="form-check-input" 
              type="radio" 
              name="enable_replace_received_document" 
              id="enable_replace_received_document_0" 
              value="true"
              checked={props.site_setting.enable_replace_received_document == true}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="enable_replace_received_document_0">ใช้เลขรับเก่า</label>
          </div>
          <div className="form-check form-check-inline">
            <input 
              className="form-check-input" 
              type="radio" 
              name="enable_replace_received_document" 
              id="enable_replace_received_document_0" 
              value="false"
              checked={props.site_setting.enable_replace_received_document == false}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="enable_replace_received_document_1">ออกเลขรับใหม่</label>
          </div>
        </div>
      </div>
      
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title font-weight-bold">การนับวันในระบบ</h5>

          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="count_only_workday" 
              id="count_only_workday_1" 
              value="true"
              checked={props.site_setting.count_only_workday == true}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="count_only_workday_1">นับวันตามวันดำเนินการ (หัก วันหยุดเสาร์-อาทิตย์ และวันหยุดนักขัตฤกษ์)</label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="count_only_workday" 
              id="count_only_workday_1" 
              value="false"
              checked={props.site_setting.count_only_workday == false}
              onChange={props.onUpdateAdditionalSetting} />
            <label className="form-check-label" htmlFor="count_only_workday_2">นับตามปฏิทิน</label>
          </div>
        </div>
      </div>
      
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title font-weight-bold">ระบบสำรองข้อมูล</h5>

          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="backup_schedule" 
              id="backup_schedule_0" 
              value="everyday"
              checked={props.site_setting.backup_schedule == "everyday"}
              onChange={props.onUpdateAdditionalSetting}  />
            <label className="form-check-label" htmlFor="backup_schedule_0">ทุกวัน</label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="backup_schedule" 
              id="backup_schedule_0" 
              value="everyweek"
              checked={props.site_setting.backup_schedule == "everyweek"}
              onChange={props.onUpdateAdditionalSetting}  />
            <label className="form-check-label" htmlFor="backup_schedule_1">ทุกสัปดาห์ (00.00 น. ของวันเสาร์)</label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="backup_schedule" 
              id="backup_schedule_0" 
              value="everymonth"
              checked={props.site_setting.backup_schedule == "everymonth"}
              onChange={props.onUpdateAdditionalSetting}  />
            <label className="form-check-label" htmlFor="backup_schedule_2">ทุกเดือน (00.00 น. ของวันที่ 1)</label>
          </div>
        </div>
      </div>
      
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title font-weight-bold">ขนาดของไฟล์เอกสารแนบได้</h5>

          <div className='row'>
            <div className='col-3 col-sm-2'>
              <input 
                type='number'
                name='max_file_size'
                value={props.site_setting.max_file_size}
                className='form-control form-control-sm'
                onChange={props.onUpdateSiteSetting} />
            </div>
            <div className='col-9 col-sm-10 pl-0'>
              <label className='col-form-label'>MB</label>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AdditionalSettingSection;
