import { Component } from 'react';
import _ from 'lodash';

class Pathname extends Component {
  prefixPathname(location){
    var prefixPathname = _.last(_.split(location.pathname, '/', 2)) || '';
    return prefixPathname
  }

  fullPathname(location){
  	var fullPathname = location.pathname;
    return fullPathname
  }
}

export default new Pathname();