import React, {Component} from 'react';
import _ from 'lodash';

import LoginSession from '../stores/LoginSession';

export default class RenderFileNames extends Component {
  constructor(props){
    super(props)

    this.renderFile = this.renderFile.bind(this);
  }

  renderFile(file, index){
    if (_.isEmpty(file)) { return }
    return (
      <div key={index} className='col-md-12 text-overflow' style={{position: 'relative'}}>
        {file.file.name}

        {(!_.isEmpty(file.creator_info) && file.creator_info.id === LoginSession.currentUser.id) && (
          <i onClick={()=>this.props.onDeleteFile(index)}
              className="fa fa-times top-right pointer" 
              aria-hidden="true">
          </i>
        )}

        {_.isEmpty(file.id) && (
          <i onClick={()=>this.props.onDeleteFile(index)}
              className="fa fa-times top-right pointer" 
              aria-hidden="true">
          </i>
        )}
      </div>
    )
  }

  render(){
    return (
      <div className='row'>
        {this.props.files && _.map(this.props.files, this.renderFile)}
        </div>
    )
  }
}