import { EventEmitter } from 'events';
import LoginSession from '../stores/LoginSession';
import _ from 'lodash';

export class Permission {
  constructor() {
    this.events = new EventEmitter()
  }

  canEditDepartmentGroup() {
    var canEdit = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.admin) {
      canEdit = true;
    }

    return (
      canEdit
    )
  }

  canEditUsers(){
    var canEdit = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.admin) {
      canEdit = true;
    }

    return (
      canEdit
    )
  }

  canBookNumber(){
    var canEdit = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.is_correspondence) {
      canEdit = true;
    }

    return (
      canEdit
    )
  }

  canReceiveDocument(){
    var canEdit = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.is_correspondence && LoginSession.current.user.can_receive_outer) {
      canEdit = true;
    }

    return (
      canEdit
    )
  }

  canReceiveInternalDocument(){
    var canEdit = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.is_correspondence) {
      canEdit = true;
    }

    return (
      canEdit
    )
  }

  isAdmin(){
    var is_admin = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.admin) {
      is_admin = true;
    }

    return is_admin
  }

  isSuperAdmin(){
    var is_super_admin = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.super_admin) {
      is_super_admin = true;
    }

    return is_super_admin
  }

  isSecretary(){
    var is_secretary = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.is_secretary) {
      is_secretary = true;
    }

    return is_secretary
  }

  isCorrespondence(){
    var is_correspondence = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.is_correspondence) {
      is_correspondence = true;
    }

    return is_correspondence
  }

  isUnitCorrespondence(){
    var is_unit_correspondence = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.is_unit_correspondence) {
      is_unit_correspondence = true;
    }

    return is_unit_correspondence
  }

  isDepartmentCorrespondence(){
    var is_department_correspondence = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.is_department_correspondence) {
      is_department_correspondence = true;
    }

    return is_department_correspondence
  }

  isDepartmentSecretary(){
    var is_department_secretary = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.is_department_secretary) {
      is_department_secretary = true;
    }

    return is_department_secretary
  }

  isBoard(){
    var is_board = false;

    if (!_.isEmpty(LoginSession.current) && LoginSession.current.user.is_board) {
      is_board = true;
    }

    return is_board
  }

}

export default new Permission()