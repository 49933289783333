export const userVariables = {
	code: '',
	username: '',
	prefix_name: '',
	email: '',
	first_name: '',
	last_name: '',
	phone: '',
	authentication_type: '',
	gender: '',
	status: '',
	positions: [],
	user_properties: [],
	profile_image: '',
	signature: ''
}

export const positionsUserVariables = {
  id: '',
  position_name: '',
  can_view_secret_document: false,
  can_access_folder: false,
  can_book_number: false,
  can_send_document_to_internal: false,
  can_send_document_to_external: false,
  can_issue_number: false,
  can_edit_others_document: false,
  can_create_internal_document: false,
  can_create_external_document: false,
  can_receive_internal_document: false,
  can_receive_external_document: false,
  can_cancel_document: false,
  can_attach_file: false,
  can_scan_document: false,
  can_close_document: false,
  can_undo_sent_document: false,
  can_return_document: false
}

export function positionVariables() {
	var variables = {
		code: '',
		name: '',
		reference_id: '',
		level_id: '',
		role_ids: []
	}
	return variables
}

export function groupVariables() {
	var variables = {
		code: '',
		name: '',
    short_name: '',
		active_directories: [],
		can_view_secret_document: false,
    can_access_folder: false,
    can_book_number: false,
    can_send_document_to_internal: false,
    can_send_document_to_external: false,
    can_issue_number: false,
    can_edit_others_document: false,
    can_create_internal_document: false,
    can_create_external_document: false,
    can_receive_internal_document: false,
    can_receive_external_document: false,
    can_cancel_document: false,
    can_attach_file: false,
    can_scan_document: false,
    can_close_document: false,
    can_undo_sent_document: false,
    can_return_document: false
	}
	return variables
}