import React, {Component} from 'react';
import _ from 'lodash';

import UserImage from '../assets/images/renderuserimage.png';

import LoginSession from '../stores/LoginSession';
import UserStore from '../stores/UserStore';

import AfterSaveLoading from '../components/AfterSaveLoading';
import SimpleModal from '../components/SimpleModal';
import ChangePasswordPage from './ChangePasswordPage';
import NotificationSettingPage from './NotificationSettingPage';


export default class EditProfilePage extends Component {
  constructor(props){
    super(props)

    this.state = {
      open_edit_password_modal: false,
      open_edit_notification_modal: false,
      loading: true,
      user: {},
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
      profile_image: '',
      allow_email_notice: false,
      allow_sms_notice: false,
      allow_push_notification: false
    }

    this.loadData = this.loadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleChangeProfile = this.handleChangeProfile.bind(this);
    this.handleNotificationChange = this.handleNotificationChange.bind(this);
    this.handleSaveNotification = this.handleSaveNotification.bind(this);
    this.handleClearNotification = this.handleClearNotification.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(){
    UserStore.request({id: LoginSession.current.user.id}).then((resp)=>{
      const user = _.get(resp.data, 'data', {});

      this.setState({
        user: user,
        allow_email_notice: user.attributes.allow_email_notice,
        allow_sms_notice: user.attributes.allow_sms_notice,
        allow_push_notification: user.attributes.allow_push_notification
      })
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  handleClear(){
    this.setState({
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
      open_edit_password_modal: false,
      open_edit_notification_modal: false
    });
  }

  handleClearNotification(){
    const { user } = this.state;

    this.setState({
      allow_email_notice: user.attributes.allow_email_notice,
      allow_sms_notice: user.attributes.allow_sms_notice,
      allow_push_notification: user.attributes.allow_push_notification,
      open_edit_password_modal: false,
      open_edit_notification_modal: false
    });
  }

  handleChange(event){
    let field_name = event.target.name;
    let value = event.target.value;

    this.setState({
      [field_name]: value
    });
  }

  handleNotificationChange(event){
    let field_name = event.target.name;
    let value = event.target.checked;

    this.setState({
      [field_name]: value
    });
  }

  handleSave(){
    let self = this;
    this.setState({loading: true});

    var data = {
      old_password: this.state.old_password,
      new_password: this.state.new_password,
      new_password_confirmation: this.state.new_password_confirmation
    }

    UserStore.request({
      method: 'post',
      action: 'reset_my_password',
      data: data
    }).then((resp)=>{
      const message = _.get(resp.data, 'message', "เปลี่ยนรหัสผ่านเรียบร้อย");
      alert(message);
      this.handleClear();
      this.loadData();
    }, function(error){
      self.setState({loading: false});
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    }).then(()=>{
      this.setState({loading: false});
    });
  }

  handleSaveNotification(){
    let self = this;
    this.setState({loading: true});

    var data = {
      allow_email_notice: this.state.allow_email_notice,
      allow_sms_notice: this.state.allow_sms_notice,
      allow_push_notification: this.state.allow_push_notification
    }

    UserStore.request({
      id: this.state.user.id, 
      action: 'update_profile', 
      method: 'put', 
      data
    }).then((resp)=>{
      alert("ตั้งค่าการแจ้งเตือนเรียบร้อย");
      this.loadData();
      this.onClose();
    }).then(()=>{
      this.setState({loading: false});
    });
  }

  handleChangeProfileImage(){
    this.setState({loading: true});
    var data = new FormData();
    data.append('user[profile_image]', this.state.profile_image, '');

    UserStore.request({id: this.state.user.id, action: 'update_profile_picture', method: 'put', data}).then((resp)=>{
      this.loadData();
    }).then(()=>{
      this.setState({loading: false});
    });
  }

  handleChangeProfile(){
    var image_value = this.refs.profile_image.files[0];
    if (image_value.size === 0) { return }

    this.setState({loading: true});
    var data = new FormData();
    data.append('user[profile_image]', image_value);

    UserStore.request({id: this.state.user.id, action: 'update_profile_picture', method: 'put', data}).then((resp)=>{
      this.loadData();
    }).then(()=>{
      this.setState({loading: false});
    });
  }

  onClose(){
    this.setState({open_edit_password_modal: false, open_edit_notification_modal: false});
  }

  render() {
    return (
      <div>
        {this.state.loading && <AfterSaveLoading />}

        <div className='row'>
          <div className='col-10'>

            <div className='row form-group'>
              <div className='col-12 text-center mb-2'>
                {(!this.state.loading && !_.isEmpty(this.state.user.attributes.profile_image)) ? (
                  <img src={this.state.user.attributes.profile_image} alt="PROFILE_IMAGE" className='border' style={{maxWidth: '200px'}} />
                ) : (
                  <img src={UserImage} alt="PROFILE_IMAGE" className='border' style={{width: '170px'}} />
                )}
              </div>
              <div className='col-12 text-center'>
                <label className="btn btn-sm btn-success pointer mb-0 mr-2">
                  เปลี่ยนรูป
                  <input 
                    type="file"
                    ref='profile_image' 
                    hidden
                    onChange={this.handleChangeProfile} />
                </label>
              </div>
            </div>
            
            <div className='row mb-2'>
              <div className='col-12 col-sm-6 text-sm-right'>
                <strong>ชื่อ-นามสกุล:</strong>
              </div>
              <div className='col-12 col-sm-6 text-left'>
                {!this.state.loading && (this.state.user.attributes.full_name || '-')}
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-12 col-sm-6 text-sm-right'>
                <strong>ตำแหน่ง:</strong>
              </div>
              <div className='col-12 col-sm-6 text-left'>
                {!this.state.loading && (this.state.user.attributes.position_names || '-')}
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-12 col-sm-6 text-sm-right'>
                <strong>สังกัด:</strong>
              </div>
              <div className='col-12 col-sm-6 text-left'>
                {!this.state.loading && (this.state.user.attributes.department_names || '-')}
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-12 col-sm-6 text-sm-right'>
                <strong>เบอร์โทรศัพท์:</strong>
              </div>
              <div className='col-12 col-sm-6 text-left'>
                {!this.state.loading && (this.state.user.attributes.phone || '-')}
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-12 col-sm-6 text-sm-right'>
                <strong>อีเมล:</strong>
              </div>
              <div className='col-12 col-sm-6 text-left'>
                {!this.state.loading && (this.state.user.attributes.email || '-')}
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-12 text-center'>
                <button 
                  type='button' 
                  className='btn btn-primary px-3 mr-2 mb-2'
                  onClick={()=>{
                    this.setState({
                      open_edit_password_modal: true, 
                      open_edit_notification_modal: false
                    })
                  }}>
                  เปลี่ยนรหัสผ่าน
                </button>
                <button 
                  type='button' 
                  className='btn btn-warning px-3 mb-2'
                  onClick={()=>{
                    this.setState({
                      open_edit_password_modal: false, 
                      open_edit_notification_modal: true
                    })
                  }}>
                  ตั้งค่าการแจ้งเตือน
                </button>
              </div>
            </div>
          </div>

          <SimpleModal 
            isOpen={this.state.open_edit_password_modal}
            title={'เปลี่ยนรหัสผ่าน'}
            showSubmitButton={false}
            onClose={this.onClose}>
            <ChangePasswordPage 
              {...this.state} 
              handleChange={this.handleChange}
              handleSave={this.handleSave}
              handleClear={this.handleClear} />
          </SimpleModal>

          <SimpleModal 
            isOpen={this.state.open_edit_notification_modal}
            title={'ตั้งค่าการแจ้งเตือน'}
            showSubmitButton={false}
            onClose={this.onClose}>
            <NotificationSettingPage 
              {...this.state} 
              handleChange={this.handleNotificationChange}
              handleSave={this.handleSaveNotification}
              handleClear={this.handleClearNotification} />
          </SimpleModal>
          
        </div>

      </div>
    )
  }
}