import React, {Component} from 'react';

const EditingFieldName = 'since';

export default class InlineEditSince extends Component {
	constructor(props){
		super(props)

		this.state = {
			editing: false,
			since: this.props.since
		}

		this.handleEditSince = this.handleEditSince.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSetValue = this.handleSetValue.bind(this);
	}

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading){
      this.setState({
        since: nextProps.since
      })
    }
  }

	handleEditSince(){
		this.setState({editing: true})
	}

	handleChange(event){
		this.setState({since: event.target.value});
	}

	handleSetValue(){
		let { since } = this.state;
		this.setState({editing: false}, this.props.handleChange(EditingFieldName, since));
	}

	render(){
		return (
			<div className='row'>
				<div className='col-10 ml-auto'>
					<div className={`pr-2 ${this.props.label_class}`} style={{display: 'inline-block'}}>
						{this.props.label}
					</div>
					<div className={this.props.content_class} style={{display: 'inline-block'}}>
						{this.state.editing ? (
							<div>
								<div className='mr-2' style={{display: 'inline-block', width: '400px'}}>
									<input 
										type='text' 
										name='since' 
										value={this.state.since} 
										className='form-control form-control-sm'
										autoFocus
										onBlur={this.handleSetValue} 
										onChange={this.handleChange} />
								</div>
								<div style={{display: 'inline-block'}}>
									<button 
										type='button' 
										className='btn btn-sm btn-primary'
										onClick={this.handleSetValue}>ตกลง</button>
								</div>
							</div>

						) : (
							<div>
								{this.props.since ? (
									<div className='pointer' style={{display: 'inline-block'}} onClick={this.handleEditSince}>
										{this.props.since}
									</div>
								) : (
									<div className='text-danger pointer' style={{display: 'inline-block'}} onClick={this.handleEditSince}>
										<em>{this.props.suggest_text}</em>
									</div>
								)}
							</div>
						)}
						
					</div>
				</div>
			</div>
		)
	}
}