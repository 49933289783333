import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import './stylesheets/DepartmentGroupTabel.css';

import { MergeAttributes } from '../FlatData';

class DepartmentGroupTabel extends Component {
  constructor(){
    super();

    this.showMemberCount = this.showMemberCount.bind(this);
    this.createCustomToolBar = this.createCustomToolBar.bind(this);
  }

  showMemberCount(cell, row){
    return row.member_names.length;
  }

  createCustomToolBar(props) {
    return (
      <div className='ml-auto col-6'>
        { props.components.searchPanel }
      </div>
    );
  }

  render(){
    const data = MergeAttributes(this.props.departmentGroups);

    const selectRowProp = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: this.props.onRowSelect,
        selected: this.props.selected
    };
    const options = {
      sizePerPageList: [],
      toolBar: this.createCustomToolBar
    };

    return(
      <div className="row">
        <div className="col-12 col-md-7 rui-dpg-table">
          <BootstrapTable 
            data={data}
            search={ true }
            multiColumnSearch={ true }
            selectRow={ selectRowProp }
            options={ options }
            striped
            hover
            condensed
            pagination
            version='4'
            tableContainerClass={'table-sm'}>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='name' dataSort={ true }>ชื่อกลุ่ม</TableHeaderColumn>
              <TableHeaderColumn dataField='member_names' dataSort={ true }>คนภายในกลุ่ม</TableHeaderColumn>
              <TableHeaderColumn  dataField='member_count' 
                                  dataSort={ true }
                                  width='65'
                                  dataFormat={this.showMemberCount}>
                                    จำนวน(คน)
              </TableHeaderColumn>
          </BootstrapTable>
        </div>
        <div className="col-12 col-md-5 pl-md-0 mb-3">
          <h4>กลุ่มที่ดำเนินงาน {`(${this.props.selected.length})`}</h4>
          <div className={`${this.props.selected.length > 0 && "card"}`}>
            <ul id="selected-department-groups" className="list-group list-group-flush">
              {this.props.selected.map(departmentGroupId => {
                let departmentGroup = _.find(this.props.departmentGroups, {id: departmentGroupId});
                return(
                  <li className="list-group-item" key={departmentGroupId}>
                    {departmentGroup.name}
                    <br />
                    {departmentGroup.member_names}
                    <i className='fa fa-times close-icon text-danger' onClick={() => this.props.removeSelected(departmentGroupId)}></i>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className="modal-footer col-12">
          <button type='button' className="btn btn-primary pointer" onClick={this.props.onSubmit}>บันทึกข้อมูล</button>
          <button type='button' className="btn btn-secondary pointer" onClick={this.props.onClose}>ยกเลิก</button>
        </div>
      </div>
    )
  }
}

export default DepartmentGroupTabel;
