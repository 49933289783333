import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import qs from 'qs';

import './stylesheets/DailyReceiveSummaryPage.css';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import LoginSession from '../../stores/LoginSession';
import ReportStore from '../../stores/ReportStore';
import AfterSaveLoading from '../../components/AfterSaveLoading';
import { monthList, getThaiMonth } from '../../utils';

export default class DailyReceiveSummaryPage extends Component {
  constructor(props) {
    super(props)

    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    this.state = {
      isDataFetched: false,
      date: params.date,
      month: params.month,
      year: params.year,
      is_total: params.is_total,
      from_receive_number: '',
      to_receive_number: '',
      results: []
    }

    this.renderRow = this.renderRow.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDownloadPdf = this.handleDownloadPdf.bind(this);
    this.handleDownloadWord = this.handleDownloadWord.bind(this);
    this.handleDownloadExcel = this.handleDownloadExcel.bind(this);
    this.handlePrintReport = this.handlePrintReport.bind(this);
  }

  componentDidMount() {
    ReportStore.request({
      method: 'POST',
      action: 'daily_receive_summary',
      data: {
        date: this.state.date,
        month: this.state.month,
        year: this.state.year,
        is_total: this.state.is_total
      }
    }).then((resp)=>{
      const results = _.get(resp.data, 'data', []);

      this.setState({
        results: results
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleSearch(){
    if (_.isEmpty(this.state.from_receive_number) || _.isEmpty(this.state.to_receive_number)) {
      alert('กรุณาระบุเลขที่ที่ต้องการค้นหาให้ครบถ้วน');
      return
    }

    ReportStore.request({
      method: 'POST',
      action: 'daily_receive_summary',
      data: {
        date: this.state.date,
        month: this.state.month,
        year: this.state.year,
        from_receive_number: this.state.from_receive_number,
        to_receive_number: this.state.to_receive_number
      }
    }).then((resp)=>{
      const results = _.get(resp.data, 'data', []);

      this.setState({
        results: results
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  renderRow(result, index){
    return (
      <tr key={result.id}>
        <td className="text-nowrap">{result.attributes.code}</td>
        <td className="text-nowrap">{result.attributes.issue_number}</td>
        <td className="text-nowrap">{result.attributes.document_date}</td>
        <td>{result.attributes.from_department}</td>
        <td>{LoginSession.current.user.full_name}</td>
        <td>{result.attributes.topic}</td>
        <td>{result.attributes.current_note_text}</td>
        <td className='visible-print'>&nbsp;</td>
      </tr>
    )
  }
  
  handlePrintReport(){
    window.print();
  }

  handleDownloadPdf(){
    axios({
      method:'POST',
      url: '/api/v1/reports/daily_receive_summary',
      responseType:'arraybuffer',
      cache: false,
      data: {
        date: this.state.date,
        month: this.state.month,
        year: this.state.year,
        is_total: this.state.is_total,
        from_receive_number: this.state.from_receive_number,
        to_receive_number: this.state.to_receive_number,
        response_type: 'pdf'
      }
    }).then(function(response) {
      let blob = new Blob([response.data], { type: 'application/pdf' } )
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'ทะเบียนหนังสือรับ.pdf'
      link.click()
    });
  }

  handleDownloadWord(){
    
  }

  handleDownloadExcel(){
    axios({
      method:'POST',
      url: '/api/v1/reports/daily_receive_summary',
      responseType:'arraybuffer',
      cache: false,
      data: {
        date: this.state.date,
        month: this.state.month,
        year: this.state.year,
        is_total: this.state.is_total,
        from_receive_number: this.state.from_receive_number,
        to_receive_number: this.state.to_receive_number,
        response_type: 'csv'
      }
    }).then(function(response) {
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' } )
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'ทะเบียนหนังสือรับ.xlsx'
      link.click()
    });
  }

  render(){
    let dateLabel = "";

    if(this.state.date){
      const dt = this.state.date.split('/');
      const day = parseInt(dt[0]);
      const month = parseInt(dt[1]);
      const year = parseInt(dt[2]);
      dateLabel = `${day} ${getThaiMonth(month)} ${year}`;
    } else if (this.state.month) {
      var date = new Date(this.state.year, this.state.month - 1, 1);
      
      const startDate = moment(date).startOf('month').format('DD/MM/YYYY').split('/');
      const endDate = moment(date).endOf('month').format('DD/MM/YYYY').split('/');

      const startDay = parseInt(startDate[0]);
      const startMonth = parseInt(startDate[1]);
      const startYear = parseInt(startDate[2]);
      const endDay = parseInt(endDate[0]);
      const endMonth = parseInt(endDate[1]);
      const endYear = parseInt(endDate[2]);

      dateLabel = `${startDay} ${getThaiMonth(startMonth)} ${startYear} ถึง วันที่ ${endDay} ${getThaiMonth(endMonth)} ${endYear}`;
    } else if (this.state.is_total) {
      const startDate = moment().startOf('month').format('DD/MM/YYYY').split('/');
      const endDate = moment().endOf('month').format('DD/MM/YYYY').split('/');

      const startDay = parseInt(startDate[0]);
      const startMonth = parseInt(startDate[1]);
      const startYear = parseInt(startDate[2]);
      const endDay = parseInt(endDate[0]);
      const endMonth = parseInt(endDate[1]);
      const endYear = parseInt(endDate[2]);

      dateLabel = `${startDay} ${getThaiMonth(startMonth)} ${startYear} ถึง วันที่ ${endDay} ${getThaiMonth(endMonth)} ${endYear}`;
    }

    const { results, from_receive_number, to_receive_number } = this.state;
    return (
      <div>
        {!this.state.isDataFetched && <AfterSaveLoading />}

        <div className='row'>
          <div className='col-12 text-center'>
            <h2>ทะเบียนหนังสือรับ วันที่ <u>{dateLabel}</u></h2>
          </div>
        </div>

        <div className='row mb-2 hidden-print'>
          <div className='col-8'>
            จากเลขที่ 
            <div className='ml-2' style={{display: 'inline-block'}}>
              <input 
                type='number' 
                name='from_receive_number'
                value={from_receive_number}
                onChange={ (e) => {this.setState({from_receive_number: e.target.value})} } />
            </div>
            <div className='mx-1' style={{display: 'inline-block'}}>ถึงเลขที่</div>
            <div className='mr-2' style={{display: 'inline-block'}}>
              <input 
                type='number' 
                name='to_receive_number'
                value={to_receive_number}
                onChange={ (e) => {this.setState({to_receive_number: e.target.value})} } />
            </div>
            <div style={{display: 'inline-block'}}>
              <button type='button' className='btn btn-primary' onClick={this.handleSearch}>
                ค้นหา
              </button>
            </div>
          </div>
          <div className='col-4 text-right pt-3'>
            <strong className='mr-2 pointer' onClick={this.handlePrintReport}>พิมพ์ทันที</strong>|
            <strong className='mr-2 pointer' onClick={this.handleDownloadPdf}>พิมพ์ PDF</strong>|
            <strong className='ml-2 pointer' onClick={this.handleDownloadExcel}>พิมพ์ MS-Excel</strong>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>

            <table className='table table-bordered w-100'>
              <thead>
                <tr style={{backgroundColor: 'lightgray'}}>
                  <th className="text-nowrap text-center" width="65px">เลขรับ</th>
                  <th className="text-center" width="135px">ที่</th>
                  <th className="text-nowrap text-center" width="100px">ลงวันที่</th>
                  <th className="text-center" width="170px">จาก</th>
                  <th className="text-center" width="170px">ถึง</th>
                  <th className="text-center" width="250px">เรื่อง</th>
                  <th className="text-nowrap text-center" width="120px">การปฏิบัติ</th>
                  <th className='visible-print text-center'>หมายเหตุ</th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(results) && _.map(results, this.renderRow)}
              </tbody>
            </table>

          </div>
        </div>
        
      </div>
    );
  }
}