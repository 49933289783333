import React, { Component } from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class ActingForm extends Component {
  render(){
    const actingPositionsUser = _.find(this.props.positions_users, { id: this.props.acting.positions_user_id })
    
    const actorPositionsUsers = this.props.actors.map(actor => {
                                  return _.find(this.props.positions_users, { id: actor.positions_user_id })
                                })
    return(
      <div id="acting-form">
        <div className="form-group">
          <h4 className="font-weight-bold">ผู้ไปราชการ</h4>
          <button type="button" className="btn btn-primary" onClick={this.props.openActingTable}>
            {actingPositionsUser ? "เปลี่ยน" : "เลือก"}
          </button>
          {actingPositionsUser && (
            <div className="alert alert-primary mt-3">{actingPositionsUser.user_name}</div>
          )}
        </div>
        <div className="form-group">
          <h4 className="font-weight-bold">วันที่ไปราชการ</h4>
          <div className="form-row">
            <div className="col">
              <div className="form-group row">
                <label htmlFor="startAt" className="col-sm-2 col-form-label">ตั้งแต่</label>
                <div className="col-sm-10">
                  <DatePicker dateFormat="DD/MM/YYYY" 
                              selected={this.props.start_at} 
                              onChange={this.props.changeStartAt} 
                              className='form-control' />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group row">
                <label htmlFor="endAt" className="col-sm-2 col-form-label">ถึง</label>
                <div className="col-sm-10">
                  <DatePicker dateFormat="DD/MM/YYYY" 
                              selected={this.props.end_at} 
                              onChange={this.props.changeEndAt} 
                              className='form-control' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <h4 className="font-weight-bold">ผู้รักษาการแทน</h4>
          {actorPositionsUsers.map(actorPositionsUser => {
            const message = _.find(this.props.actors, { positions_user_id: actorPositionsUser.id }).message

            return(
              <div className="position-relative" key={actorPositionsUser.id}>
                <div className="alert alert-primary mt-3">
                  {actorPositionsUser.user_name}
                </div>
                <i  className='fa fa-times close-icon text-danger' 
                    onClick={() => this.props.removeSelected(actorPositionsUser.id)}>
                </i>
                ข้อความที่ใช้
                <textarea name={`message[${actorPositionsUser.id}]`} 
                          className="w-100" 
                          rows="3"
                          value={message}
                          onChange={(event) => this.props.updateActorMessage(actorPositionsUser.id, event)}>
                </textarea>
              </div>
            )
          })}

          <button type="button" className="btn btn-primary" onClick={this.props.openActorTable}>
            เลือก
          </button>
        </div>
      </div>
    )
  }
}

export default ActingForm;
