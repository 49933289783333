import React, { Component } from 'react';
import _ from 'lodash';

import './stylesheets/InProgressPage.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { MergeAttributes } from '../../components/FlatData';
import { thaiDateFormatter, documentNumberFormatter } from '../../utils/bootstrapTableFormatters';

import ReportStore from '../../stores/ReportStore';
import Loading from '../../components/Loading';

export default class InProgressPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      workflow_processes: []
    }
  }

  componentDidMount(){
    ReportStore.request({
      method: 'POST',
      action: 'in_progress'
    }).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);

      this.setState({
        workflow_processes: workflow_processes
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  _setTableOption(){ 
    if (_.isEmpty(this.state.workflow_processes)) { 
      return "ไม่มีข้อมูล"; 
    }else{
      return <Loading />
    }
  }

  render(){
    var data = MergeAttributes(this.state.workflow_processes);

    const options = {
      sizePerPageList: [],
      noDataText: this._setTableOption(),
      sizePerPage: 25
    };
    
    return (
      <div>
        <div className='row mb-3'>
          <div className='col-12'>
            <h2>รายงานสรุปจำนวนหนังสือที่อยู่ในระหว่างดำเนินการ</h2>
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-12 rui-inprogress-report'>
            <BootstrapTable 
              data={data}
              search={ true }
              multiColumnSearch={ true }
              options={options}
              striped
              hover
              condensed
              pagination
              version='4'
              tableContainerClass={'table-sm'}>
                <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
                <TableHeaderColumn width="200" dataFormat={documentNumberFormatter}>เลขที่หนังสือ</TableHeaderColumn>
                <TableHeaderColumn dataField='topic' columnTitle dataSort={ true }>เรื่อง</TableHeaderColumn>
                <TableHeaderColumn dataField='from_department' columnTitle dataSort={ true }>จาก</TableHeaderColumn>
                <TableHeaderColumn dataField='to_department' columnTitle dataSort={ true }>เรียน</TableHeaderColumn>
                <TableHeaderColumn dataField='type_name' width='100'>ประเภท</TableHeaderColumn>
                <TableHeaderColumn dataField='current_state_name' dataSort={ true } width='160'>สถานะ</TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>

      </div>
    )
  }
}