import React, {Component} from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import moment from 'moment';

import GarudaEmblem from '../../assets/images/emblem.png';
import { speedLevelList, secretLevelList } from '../../utils/documentType';
import AfterSaveLoading from '../../components/AfterSaveLoading';

import DocumentStore from '../../stores/DocumentStore';
import DocumentParameters from '../../components/documents/DocumentParameters';
import ReceiveDocumentForm from '../../components/receive_documents/ReceiveDocumentForm';

const NormalLevel = 'ปกติ';

export default class EditInternalReceiveDocumentPage extends Component {
  constructor(props){
    super(props)

    const document_id = _.get(props.match, 'params.id', '');
    const workflow_process_id = _.get(props.match, 'params.workflow_process_id', '');

    this.state = {
      document_id: document_id,
      workflow_process_id: workflow_process_id,
      loading: true,
      open: false,
      due_date: moment(),
      government_service: '',
      issue_number: '',
      topic: '',
      owner: '',
      is_circular: false,
      execute: '',
      keywords: '',
      attachment: '',
      document_date: moment(),
      speed_level: NormalLevel,
      secret_level: NormalLevel,
      original_document_files: [],
      files: [],
      references: [],
      informs: [{
        id: '',
        name: '',
        executors: [],
        department_group_ids: []
      }],
      document_template: {},
      informees: [],
      department_groups: [],
      reference_issue_numbers: "",
      position_name_list: [],
      saved_topics: [],
      saved_from_departments: []
    }

    this.handleChangeReceiveDate = this.handleChangeReceiveDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDueDate = this.handleChangeDueDate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeOriginalDoc = this.handleChangeOriginalDoc.bind(this);
    this.handleUpdateFiles = this.handleUpdateFiles.bind(this);
    this.onDeleteFile = this.onDeleteFile.bind(this);
    this.handleChangeInformText = this.handleChangeInformText.bind(this);
    this.handleUpdateReference = this.handleUpdateReference.bind(this);
    this.onDeleteRefDoc = this.onDeleteRefDoc.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onRemoveOriginalDocument = this.onRemoveOriginalDocument.bind(this);
    this.handleInsert = this.handleInsert.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
    this.handleChangeSpeedLevel = this.handleChangeSpeedLevel.bind(this);
    this.handleChangeSecretLevel = this.handleChangeSecretLevel.bind(this);
    this.handleChangeSavedField = this.handleChangeSavedField.bind(this);
  }

  componentDidMount(){
    const { document_id, workflow_process_id } = this.state;

    DocumentStore.request({url: `documents/edit_receive/${document_id}/wp/${workflow_process_id}/edit`}).then((resp)=>{
      const doc = _.get(resp.data, 'data', {});
      const document_template = _.get(resp.data, 'included.document_template', []);
      const informees = _.get(resp.data, 'included.informees', []);
      const department_groups = _.get(resp.data, 'included.department_groups', []);

      const government_service = _.get(doc, 'attributes.document_attributes.government_service.value', '');
      const attachment = _.get(doc, 'attributes.document_attributes.attachment.value', '');
      const document_date = !_.isEmpty(doc.attributes.document_date) ? moment(doc.attributes.document_date) : moment();
      const informs = _.get(doc, 'attributes.informs', []);
      const references = _.get(doc, 'attributes.reference_documents', []);
      const original_document_files = _.get(doc, 'attributes.original_documents', []);
      const files = _.get(doc, 'attributes.attachments', []);
      const position_name_list = _.get(resp.data, 'included.position_name_list', []);
      const saved_topics = _.get(resp.data, 'included.saved_topics', []);
      const saved_from_departments = _.get(resp.data, 'included.saved_from_departments', []);
      
      this.setState({
        due_date: !_.isEmpty(doc.attributes.due_date) ? moment(doc.attributes.due_date) : moment(),
        government_service: government_service,
        issue_number: doc.attributes.issue_number || '',
        topic: doc.attributes.topic || '',
        is_circular: doc.attributes.is_circular || false,
        execute: doc.attributes.execute || '',
        keywords: doc.attributes.keywords || '',
        owner: doc.attributes.owner || '',
        attachment: attachment,
        document_date: document_date,
        speed_level: doc.attributes.speed_level || NormalLevel,
        secret_level: doc.attributes.secret_level || NormalLevel,
        original_document_files: original_document_files,
        files: files,
        references: references,
        informs: informs,
        document_template: document_template,
        informees: informees,
        department_groups: department_groups,
        reference_issue_numbers: doc.attributes.reference_issue_numbers || '',
        position_name_list: position_name_list,
        saved_topics: saved_topics,
        saved_from_departments: saved_from_departments
      })
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  onSortEnd({oldIndex, newIndex}){
    this.setState(({original_document_files}) => ({
      original_document_files: arrayMove(this.state.original_document_files, oldIndex, newIndex),
    }));
  }

  handleSave(){
    var data = new FormData();
    var document_attributes = _.get(this.state.document_template, 'attributes.template_attributes', {});

    document_attributes.government_service.value = this.state.government_service;
    document_attributes.topic.value = this.state.topic;

    data = DocumentParameters._assign_receive_document_informs(data, this.state);

    let attachment_obj = {
      attachment: {
        title: 'สิ่งที่ส่งมาด้วย',
        name: 'attachment',
        can_inline_edit: false,
        suggest_text: '',
        value: this.state.attachment
      }
    }
    _.assign(document_attributes, attachment_obj);
    delete(document_attributes.content);

    data = DocumentParameters._map_document_attributes(data, document_attributes);

    data.append('workflow_process[due_date]', this.state.due_date || '');
    data.append('workflow_process[execute]', this.state.execute || '');
    data.append('workflow_process[keywords]', this.state.keywords || '');
    data.append('workflow_process[document_date]', this.state.document_date || '');
    data.append('workflow_process[speed_level]', this.state.speed_level || '');
    data.append('workflow_process[secret_level]', this.state.secret_level || '');
    data.append('workflow_process[owner]', this.state.owner || '');

    if (!_.isEmpty(this.state.original_document_files)){
      _.forEach(this.state.original_document_files, function(file){
        if (file.file){
          data.append('original_document_files[][id]', file['id'] || '');
          data.append('original_document_files[][file]', file.file || '');
        }
      })
    }

    if (!_.isEmpty(this.state.files)){
      _.forEach(this.state.files, function(file){
        if (!_.isEmpty(file)){
          data.append('attachments[][id]', file['id'] || '');
          data.append('attachments[][file]', file.file || '');
        }
      })
    }

    if (!_.isEmpty(this.state.references)){
      _.forEach(this.state.references, function(ref){
        data.append('references[][id]', ref['id'] || '');
        data.append('references[][reference_text]', ref['reference_text'] || '');
        data.append('references[][document_id]', ref['document_id'] || '');
      })
    }
    
    // can edit issue number
    data.append('document[issue_number]', this.state.issue_number || '');

    // อ้างถึงเลขที่หนังสือ
    data.append('document[reference_issue_numbers]', this.state.reference_issue_numbers || '');

    // ไม่รันเลข
    data.append('is_custom', true);

    DocumentStore.request({
      id: this.props.match.params.id,
      method: 'put',
      action: `update_workflow_process/${this.props.match.params.workflow_process_id}`,
      data: data
    }).then(()=>{
      window.location.href = "/receive_documents/operated";
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    });
  }

  handleChangeReceiveDate(date){
    this.setState({document_date: date});
  }

  handleChangeDueDate(date){
    this.setState({due_date: date});
  }

  handleChangeSpeedLevel(speed_level){
    this.setState({speed_level: speed_level});
  }

  handleChangeSecretLevel(secret_level){
    this.setState({secret_level: secret_level});
  }

  handleChange(event){
    const field_name = event.target.name;
    var value = event.target.value;

    if (_.includes(['is_circular'], field_name)){
      value = event.target.checked;
    }

    this.setState({
      [field_name]: value
    })
  }

  handleChangeOriginalDoc(event){
    var original_file = event.target.files[0];
    if (original_file.size === 0) { return }
    if (original_file.type !== "application/pdf"){
      alert("แนบไฟล์-เอกสารต้นฉบับ จำกัดประเภทเฉพาะไฟล์นามสกุล .pdf");
      return
    }

    const new_file = {
      file: original_file
    }

    let { original_document_files } = this.state;

    original_document_files = _.concat(original_document_files, [new_file]);

    this.setState({
      original_document_files: original_document_files
    });
  }

  onRemoveOriginalDocument(index){
    let { original_document_files } = this.state;
    original_document_files.splice(index, 1);

    this.setState({
      original_document_files: original_document_files
    });
  }

  handleInsert(files){
    this.setState({
      original_document_files: files
    });
  }

  handleReplace(files){
    this.setState({
      original_document_files: files
    });
  }

  onClose(){
    this.setState({open: false});
  }

  handleChangeInformText(value){
    let { informs } = this.state;
    informs[0].name = value;
    
    this.setState({informs: informs});
  }

  handleChangeSavedField(field, value){
    this.setState({ 
      [field]: value
     });
  }

  handleUpdateFiles(new_files){
    if (_.isEmpty(new_files)) { return }

    var { files } = this.state;
    _.map(new_files, function(file, index){
      if (!_.isEmpty(file)){
        files = _.concat(files, file);
      }
    })
    
    this.setState({
      files: files
    });
  }

  onDeleteFile(index){
      if (!window.confirm('ยืนยันการลบไฟล์')) { return }
        
      var { files } = this.state;
      files.splice(index, 1);

      this.setState({
        files: files
      });
  }

  handleUpdateReference(field_name, value){
      console.log('field_name --->', field_name)
      console.log('value --->', value)

      this.setState({
        [field_name]: value
      });
  }

  onDeleteRefDoc(index){
      if (!window.confirm('ยืนยันการลบ')) { return }

      var { references } = this.state;
      references.splice(index, 1);

      this.setState({
        references: references
      });
  }

  render(){
    const inform = _.first(this.state.informs);
    return (
      <div>
        {this.state.loading && <AfterSaveLoading />}

        <div className='row mb-2'>
          <div className='col-12'>
            <h2 className='my-1'>แก้ไขเอกสาร</h2>
          </div>
        </div>

        <ReceiveDocumentForm 
          handleChangeInformText={this.handleChangeInformText}
          handleChange={this.handleChange}
          speedLevelList={speedLevelList}
          secretLevelList={secretLevelList}
          handleChangeOriginalDoc={this.handleChangeOriginalDoc}
          onRemoveOriginalDocument={this.onRemoveOriginalDocument}
          handleInsert={this.handleInsert}
          handleReplace={this.handleReplace}
          onSortEnd={this.onSortEnd}
          handleUpdateFiles={this.handleUpdateFiles}
          onDeleteFile={this.onDeleteFile}
          handleUpdateReference={this.handleUpdateReference}
          onDeleteRefDoc={this.onDeleteRefDoc}
          handleChangeSpeedLevel={this.handleChangeSpeedLevel}
          handleChangeSecretLevel={this.handleChangeSecretLevel}
          handleChangeDueDate={this.handleChangeDueDate}
          handleChangeReceiveDate={this.handleChangeReceiveDate}
          handleChangeSavedField={this.handleChangeSavedField}
          onClose={this.onClose}
          {...this.state} />

        <div className='row py-3'>
          <div className='col-12 col-md-2'></div>
          <div className='col-12 col-md-10'>
            <button type='button' className='btn btn-primary mr-1 pointer' onClick={this.handleSave}>บันทึก</button>
            <Link to='/receive_documents/mydoc' className='btn btn-secondary'>ยกเลิก</Link>
          </div>
        </div>

      </div>
    )
  }
}