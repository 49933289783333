import React, {Component} from 'react';
import _ from 'lodash';

import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import SimpleModal from '../SimpleModal';
import InsertOriginalFiles from './InsertOriginalFiles';
import ReplaceOriginalFiles from './ReplaceOriginalFiles';
import LoginSession from '../../stores/LoginSession';

const SortableItem = SortableElement(({value, index, onRemoveOriginalDocument}) => {
  return (
    <li className='list-group-item px-2 pointer'>
      <i className="fa fa-arrows-v pr-3" style={{color: 'gray'}} aria-hidden="true"></i>
      {`${index + 1}. ${value.file.name}`}

      {(!_.isEmpty(value.creator_info) && value.creator_info.id === LoginSession.currentUser.id) && (
        <i className="fa fa-times pl-3 float-right text-danger" 
          onClick={() => onRemoveOriginalDocument(index)} 
          aria-hidden="true">
        </i>
      )}

      {_.isEmpty(value.id) && (
        <i className="fa fa-times pl-3 float-right text-danger" 
          onClick={() => onRemoveOriginalDocument(index)} 
          aria-hidden="true">
        </i>
      )}
    </li>
  );
});

const SortableList = SortableContainer(({items, onRemoveOriginalDocument}) => {
  return (
    <ul className='list-group ml-0 mb-2'>
      {items.map((value, index) => (
        <SortableItem 
          key={`item-${index}`} 
          index={index} 
          sortIndex={index} 
          value={value}
          onRemoveOriginalDocument={onRemoveOriginalDocument} />
      ))}
    </ul>
  );
});

export default class OriginalDocumentFiles extends Component {
  constructor(props) {
    super(props)

    this.state = {
      files: [],
      insert_file_open: false,
      replace_file_open: false,
      insert_number: '',
      replace_from_number: '',
      replace_to_number: ''
    }

    this.handleUpload = this.handleUpload.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.handleChnage = this.handleChnage.bind(this);
    this.handleMergeInsertFiles = this.handleMergeInsertFiles.bind(this);
    this.handleMergeReplaceFiles = this.handleMergeReplaceFiles.bind(this);
  }

  componentDidMount() {
    
  }

  handleChnage(field_name, value){
    this.setState({
      [field_name]: value
    });
  }

  handleMergeInsertFiles(){
    const { original_document_files } = this.props;
    const { files, insert_number } = this.state;

    let new_original_sort_files = [];

    if (_.isEmpty(insert_number)){
      new_original_sort_files = _.union(original_document_files, files);
    }else{
      _.forEach(original_document_files, function(org_file, index){
        if (parseInt(insert_number) === (index + 1)){
          new_original_sort_files = _.union(new_original_sort_files, files);
        }
        new_original_sort_files = _.union(new_original_sort_files, [org_file]);
      });
    }
    
    this.props.handleInsert(new_original_sort_files);
    this.onCloseModal();
  }

  handleMergeReplaceFiles(){
    const { original_document_files } = this.props;
    const { files, replace_from_number, replace_to_number } = this.state;

    let new_original_sort_files = [];

    if (_.isEmpty(replace_from_number) || _.isEmpty(replace_to_number)){
      new_original_sort_files = _.union(original_document_files, files);
    }else{
      _.forEach(original_document_files, function(org_file, index){
        const sequence_number = index + 1;
        if (sequence_number < parseInt(replace_from_number) || sequence_number > parseInt(replace_to_number)){
          new_original_sort_files = _.union(new_original_sort_files, [org_file]);
        }

        if (sequence_number === parseInt(replace_to_number)){
          new_original_sort_files = _.union(new_original_sort_files, files);
        }
      });
    }

    this.props.handleReplace(new_original_sort_files);
    this.onCloseModal();
  }

  handleUpload(event){
    var original_file = event.target.files[0];
    if (original_file.size === 0) { return }
    if (original_file.type !== "application/pdf"){
      alert("จำกัดประเภทเฉพาะไฟล์นามสกุล .pdf");
      return
    }

    const new_file = { file: original_file };

    let { files } = this.state;
    files = _.concat(files, [new_file]);

    this.setState({ files: files });
  }

  onCloseModal(){
    this.setState({
      files: [],
      insert_file_open: false,
      replace_file_open: false,
      insert_number: '',
      replace_from_number: '',
      replace_to_number: ''
    });
  }

  render(){
    const { original_document_files } = this.props;
    const { files } = this.state;
    return (
      <div className='row mb-2'>
        <div className='col-4 col-sm-3 col-md-2'>
          แนบไฟล์-ต้นฉบับ
        </div>
        <div className='col-8 col-sm-9 col-md-10'>

          {!_.isEmpty(original_document_files) && (
            <SortableList 
              items={original_document_files} 
              onSortEnd={this.props.onSortEnd} 
              onRemoveOriginalDocument={this.props.onRemoveOriginalDocument}
              distance={1} 
              lockAxis="y" />
          )}

          <div>
            <label className="btn btn-sm btn-success pointer mb-0 mr-2">
              <i className="fa fa-upload mr-1" aria-hidden="true"></i>
                แนบไฟล์-เอกสารต้นฉบับ
                <input 
                  type="file" 
                  name='original_file' 
                  hidden
                  onChange={this.props.handleChangeOriginalDoc} />
            </label>

            {!_.isEmpty(original_document_files) && (
              <label 
                className="btn btn-sm btn-primary pointer mb-0 mr-2" 
                onClick={ () => this.setState({insert_file_open: true, replace_file_open: false}) }>
                <i className="fa fa-upload mr-1" aria-hidden="true"></i>
                  แทรกหน้า
              </label>
            )}

            {!_.isEmpty(original_document_files) && (
              <label 
                className="btn btn-sm btn-primary pointer mb-0 mr-2"
                onClick={ () => this.setState({insert_file_open: false, replace_file_open: true}) }>
                <i className="fa fa-upload mr-1" aria-hidden="true"></i>
                  แทนที่หน้า
              </label>
            )}
          </div>

          <div className='mt-2'>
            <button className='btn btn-primary' data-toggle="modal" data-target="#scannerModal">สแกนเอกสาร</button>
          </div>

          <InsertOriginalFiles 
            handleChnage={this.handleChnage}
            handleUpload={this.handleUpload}
            handleMergeInsertFiles={this.handleMergeInsertFiles}
            onCloseModal={this.onCloseModal}
            {...this.state} />

          <ReplaceOriginalFiles 
            handleChnage={this.handleChnage}
            handleUpload={this.handleUpload}
            handleMergeReplaceFiles={this.handleMergeReplaceFiles}
            onCloseModal={this.onCloseModal}
            {...this.state} />

        </div>

      </div>
    )
  }
}