import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import './stylesheets/ActingsSettingPage.css';

import Permission from '../../components/Permission';

import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';
import AfterSaveLoading from '../../components/AfterSaveLoading';
import SimpleModal from '../../components/SimpleModal';

import ActingStore from '../../stores/ActingStore';

import Form from '../../components/Acting/Form';
import { thaiDateFormatter } from '../../utils/bootstrapTableFormatters';

class ActingsSettingPage extends Component {
  constructor(){
    super();

    this.state = {
      loading: false,
      isDataFetched: false,
      edittingActing: {},
      actings: [],
      actors: [],
      positions_users: [],
      warning_message: "",
      modalOpen: false,
      modalTitle: ""
    }
    
    this.newRow = this.newRow.bind(this);
    this.editRow = this.editRow.bind(this);
    this.onClose = this.onClose.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleCloseAndReloadData = this.handleCloseAndReloadData.bind(this);
  }

  componentWillMount(){
    if (!Permission.isAdmin() && !Permission.isSecretary() && !Permission.isCorrespondence()) {
      window.location.href = '/receive_documents/mydoc';
    }else{
      this.reloadData();
    }
  }

  reloadData(){
    ActingStore.request({}).then((resp)=>{
      let actings = _.get(resp.data, 'data', []);
      let actors = _.get(resp.data, 'included.actors', []);
      let positions_users = _.get(resp.data, 'included.positions_users', []);
      let warning_message = _.get(resp.data, 'warning_message', "");

      this.setState({
        actings: actings,
        actors: actors,
        positions_users: positions_users,
        warning_message: warning_message,
      })
    }).then(()=>{
      this.setState({isDataFetched: true, loading: false});
    })
  }

  newRow(){
    this.setState({
      modalOpen: true,
      modalTitle: "เพิ่มรักษาราชการแทน",
    });
  }

  editRow(row, isSelected, e){
    const acting = _.find(this.state.actings, { id: row.id})

    this.setState({
      modalOpen: true,
      modalTitle: "แก้ไขรักษาราชการแทน",
      edittingActing: acting,
    });
  }

  onClose(){
    this.setState({
      modalOpen: false,
      modalTitle: "",
      edittingActing: {}
    });
  }

  handleCloseAndReloadData(){
    this.setState({
      edittingActing: {},
      actors: [],
      positions_users: [],
      modalOpen: false,
      modalTitle: "",
      loading: true,
    }, this.reloadData());
  }

  handleSubmit(data){
    let self = this;
    
    const {id, acting, actors, start_at, end_at} = data;

    ActingStore.save(id, {
      acting: {
        positions_user_id: acting.positions_user_id,
        start_at: start_at,
        end_at: end_at,
      },
      actors: actors,
    }).then(()=>{
      this.handleCloseAndReloadData();
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      self.setState({error_message: error_message});
    });
  }

  handleRemove(id){
    if(window.confirm("Are you sure?")){
      let self = this;
      
      ActingStore.delete(id).then(()=>{
        this.handleCloseAndReloadData()
      }, function(error){
        let error_message = _.get(error.response.data, 'error.message', '');
        self.setState({error_message: error_message});
      });
    }
  }

  _setTableOption(){ 
      if(this.state.isDataFetched){
            return "ไม่มีข้อมูล";
        }else{
            return <Loading />
        }
  }

  render(){
    const options = {
      sizePerPageList: [],
      onRowClick: this.editRow,
      noDataText: this._setTableOption()
    };
    let data = MergeAttributes(this.state.actings);

		return (
			<div id="department-groups">
        {this.state.loading && <AfterSaveLoading />}

        <div className="row">
          <div className="col-8">
            <h2>การรักษาราชการแทน</h2>
            {this.state.warning_message && (
              <span className="text-danger ml-1">{this.state.warning_message}</span>
            )}
          </div>
          <div className="col-4 text-right">
            <button className='btn btn-success' onClick={() => this.newRow()}>
              <span>เพิ่ม</span>
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 rui-acting-setting">
            <BootstrapTable 
              data={data}
              search={ true }
              multiColumnSearch={ true }
              options={ options }
              striped
              hover
              pagination
              version='4'
              tableContainerClass={'table-sm'}>
              <TableHeaderColumn  isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn  dataField='acting_name' dataSort={ true }>ชื่อ - นามสกุล</TableHeaderColumn>
              <TableHeaderColumn  dataField='start_at' dataFormat={ thaiDateFormatter } dataSort={ true }>ไป</TableHeaderColumn>
              <TableHeaderColumn  dataField='end_at' dataFormat={ thaiDateFormatter } dataSort={ true }>กลับ</TableHeaderColumn>
              <TableHeaderColumn  dataField='actor_names' dataSort={ true }>ผู้รักษาราชการแทน</TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>

        <SimpleModal 
          isOpen={this.state.modalOpen}
          title={this.state.modalTitle}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onClose}>
            <Form positions_users={this.state.positions_users} 
                  acting={this.state.edittingActing}
                  onClose={this.onClose} 
                  onSubmit={this.handleSubmit}
                  onRemove={this.handleRemove} />
        </SimpleModal>
      </div>
		)
	}
}

export default ActingsSettingPage;