import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Link, withRouter } from 'react-router-dom';
import $ from "jquery";

import './stylesheets/DocumentsPage.css';

import { rowClassNameFormatWithUnread, thaiDateFormatter, thaiDateTimeFormatter } from '../../utils/bootstrapTableFormatters';
import { DocumentOwner, SpeedLevelClassName } from '../../utils/Helper';
import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';
import SimpleModal from '../../components/SimpleModal';
import PositionFilter from '../../components/documents/filters/PositionFilter';
import StatusFilter from '../../components/documents/filters/StatusFilter';
import ShowAttachedFiles from '../../components/documents/ShowAttachedFiles';
import HandleOriginalFiles from '../../components/documents/HandleOriginalFiles';
import ReceiveDocumentConfirmationModal from '../../components/Modals/ReceiveDocumentConfirmationModal';
import SetNextReceiveNumber from '../../components/SetNextReceiveNumber';

import WorkflowProcessHistory from '../../components/WorkflowProcessHistory';
import Forward from '../../components/WorkflowActions/Forward';
import BatchForwarding from '../../components/BatchForwarding';

import WorkflowProcessStore from '../../stores/WorkflowProcessStore';
import ReceiveNumberStore from '../../stores/ReceiveNumberStore';

class DocumentsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      open_confirm_modal: false,
      isDataFetched: false,
      open_attached_file: false,
      open_insert_file: false,
      workflow_process_id: '',
      workflow_process_id_history: '',
      workflow_process_id_attachment: '',
      workflow_processes: [],
      active_position_user_id: '',
      available_statuses: [],
      active_status: '',
      pagination: {},
      searchText: '',
      selectedWorkflowProcessIds: [],
      modal_form: "history",
      modal_title: "ประวัติการดำเนินการ การปฏิบัติงาน",
      internal_users: [],
      department_groups: [],
      actings: [],
      acting_positions_user_ids: [],
      clicked: false,
      currentForwardIndex: 0,
      skip_receive_number: false,
      next_receive_number: ""
    }
    
    this.actionFormatter = this.actionFormatter.bind(this);
    this.fromDepartmentFormatter = this.fromDepartmentFormatter.bind(this);
    this.documentDetailsFormatter = this.documentDetailsFormatter.bind(this);
    this.documentNumberWithFilesFormatter = this.documentNumberWithFilesFormatter.bind(this);
    this.redirectToShowDocumentPage = this.redirectToShowDocumentPage.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.createBatchForwardButton = this.createBatchForwardButton.bind(this);
    this.handleBatchForwardClick = this.handleBatchForwardClick.bind(this);
    this.getUnselectableDocuments = this.getUnselectableDocuments.bind(this);
    this.handleBatchForward = this.handleBatchForward.bind(this);
    this.preventMultiClicks = this.preventMultiClicks.bind(this);
    this.forwardDocument = this.forwardDocument.bind(this);
    this.getNextNumber = this.getNextNumber.bind(this);
    this.showConfirmModal = this.showConfirmModal.bind(this);
    this.handleSubmitAction = this.handleSubmitAction.bind(this);

    this.handleUpdatedNextReceiveNumber = this.handleUpdatedNextReceiveNumber.bind(this);
  }

  componentDidMount() {
    const self = this;
    
    WorkflowProcessStore.request({url: 'workflow_processes?type=wait_for_receive&page=1'}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const available_statuses = _.get(resp.data, 'available_statuses', []);
      const internal_users =  _.get(resp.data, 'included.internal_users', []);
      const department_groups =  _.get(resp.data, 'included.department_groups', []);
      const actings = _.get(resp.data, 'included.actings', []);
      const acting_positions_user_ids = _.map(actings, 'attributes.positions_user.id') || [];
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        available_statuses: available_statuses,
        internal_users: internal_users,
        department_groups: department_groups,
        pagination: pagination
      });
    }).then(()=>{
      this.getNextNumber();
    }, function(error){
      self.setState({isDataFetched: true});
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  getNextNumber(){
    ReceiveNumberStore.request({url: `receive_numbers/get_next_receive_number`}).then((resp)=>{
      const next_receive_number = _.get(resp.data, 'data.next_number', "");

      this.setState({ next_receive_number: next_receive_number });
    }).then(()=>{
      this.setState({isDataFetched: true});
    }, function(error){
      this.setState({isDataFetched: true});
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  fromDepartmentFormatter(cell, row){
    return (
      <div className='text-link pointer' style={{whiteSpace: 'normal'}} onClick={this.openModal.bind(this, row.id)}>
        {cell}
      </div>
    )
  }

  redirectToShowDocumentPage(cell, row){
    this.props.history.push(`/templates/documents/${row.attributes.document.id}/show/${row.attributes.document_template.id}/wp/${row.id}`);
  }

  handleSubmitAction(workflow_process_id){
    const self = this;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      action: 'check_alerady_received',
      method: 'get'
    }).then((resp)=>{
      if(_.isEmpty(resp.data.message) || window.confirm(resp.data.message)){
        self.preventMultiClicks();

        WorkflowProcessStore.request({
          id: workflow_process_id,
          method: 'put',
          data: {
            action_name: "รับหนังสือ",
            positions_user_id: self.state.active_position_user_id,
            comment: ""
          },
        }).then(()=>{
          window.location.reload();
        }, function(error){
          let error_message = error.response.data;
          alert(error_message);
        })
      } else {
        self.onClose();
      }
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', 'ไม่สามารถเชื่อมต่อเครือข่ายได้ กรุณาบันทึกรับอีกครั้ง');
      alert(error_message);
    });
  }

  actionFormatter(cell, row) {
    const workflow_process_id = row.id;
    const skip_receive_number = false;
    if (row.current_state_name === "เอกสารรอบันทึกรับ") {
      return (
        <div>
          <button 
            type='button' 
            className={'btn btn-sm btn-success'} 
            onClick={() => this.showConfirmModal(workflow_process_id, skip_receive_number)}>
            รับหนังสือ
          </button>
          {_.size(row.original_documents) > 0 && (
            <i 
              className="fa fa-paperclip ml-2 text-danger pointer" 
              aria-hidden="true" 
              onClick={this.handleInsertFile.bind(this, cell)}>
            </i>
          )}

          {row.attributes.speed_level !== 'ปกติ' && (
            <div className={`mt-2 text-${SpeedLevelClassName(row.attributes.speed_level)}`}>
              {row.attributes.speed_level}
            </div>
          )}
        </div>
      )
    }

    return (<a/>)
  }

  showConfirmModal(workflow_process_id, skip_receive_number){
    this.setState({
      skip_receive_number: skip_receive_number,
      open_confirm_modal: true,
      workflow_process_id: workflow_process_id
    })
  }

  documentDetailsFormatter(cell, row){
    return (
      <div style={{whiteSpace: 'normal'}}>
        <div><u>เรียน</u> {row.to_department}</div>
        <div><u>เรื่อง</u> {row.topic}</div>
        <DocumentOwner cell={cell} row={row} />

        {!_.isEmpty(row.attachment) && (
          <div>
            <u className='mr-2'>สิ่งที่ส่งมาด้วย</u> 
            <span>{row.attachment}</span>
          </div>
        )}

        {row.reference_issue_numbers && (
          <div><u>อ้างอิงเลขที่หนังสือ</u> {row.reference_issue_numbers}</div>
        )}
      </div>
    )
  }

  documentNumberWithFilesFormatter(cell, row){
    const total_files_amount = _.size(row.original_documents) + row.attachment_count;
    return (
      <div>
        {row.workflow_name === "workflow_internal_receive" ? (
          <div>
            <div className='small text-link pointer' onClick={() => this.redirectToShowDocumentPage(cell, row)}>
              {row.prev_issue_number}
            </div>
            <div onClick={this.handleInsertFile.bind(this, cell)} className='font-weight-bold text-link pointer'>
              ไฟล์แทรก ({total_files_amount})
            </div>
          </div>
        ) : (
          <div>
            <div className='text-link pointer' onClick={() => this.redirectToShowDocumentPage(cell, row)}>
              {row.issue_number ? row.issue_number : "####"}
            </div>
            <div onClick={this.handleInsertFile.bind(this, cell)} className='font-weight-bold pointer'>
              ไฟล์แทรก ({total_files_amount})
            </div>
          </div>
        )}

        {/*
        <div className='text-link pointer' onClick={this.handleShowAttachedFiles.bind(this, cell)}>
          <strong className='pointer'>ไฟล์แนบ ({row.attachment_count})</strong>
        </div>
        */}
      </div>
    )
  }

  handleShowAttachedFiles(workflow_process_id, e){
    e.stopPropagation();
    this.setState({workflow_process_id_attachment: workflow_process_id, open_attached_file: true})
  }

  openModal(workflow_process_id, event){
    event.stopPropagation();

    this.setState({
      open: true,
      open_attached_file: false,
      open_insert_file: false,
      workflow_process_id: '',
      modal_form: "history",
      workflow_process_id_history: workflow_process_id,
    });
  }

  handleChangePosition(pu_id){
    this.setState({isDataFetched: false, workflow_processes: []});

    const { active_status, searchText } = this.state;
    WorkflowProcessStore.request({url: `workflow_processes?type=wait_for_receive&positions_user_id=${pu_id}&status=${active_status}&page=1&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});
      
      this.setState({
        active_position_user_id: pu_id,
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleChangeStatus(status){
    this.setState({isDataFetched: false, workflow_processes: []});

    const { active_position_user_id, searchText } = this.state;
    WorkflowProcessStore.request({url: `workflow_processes?type=wait_for_receive&status=${status}&positions_user_id=${active_position_user_id}&page=1&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});
      
      this.setState({
        active_status: status,
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  onClose(){
    this.setState({
      open: false,
      open_attached_file: false,
      open_insert_file: false,
      open_confirm_modal: false,
      workflow_process_id: ''
    });
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, workflow_processes: []});
    const { active_status, active_position_user_id, searchText } = this.state;

    WorkflowProcessStore.request({url: `workflow_processes?type=wait_for_receive&status=${active_status}&positions_user_id=${active_position_user_id}&page=${page}&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});
      
      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, workflow_processes: []});
    const { active_status, active_position_user_id } = this.state;

    WorkflowProcessStore.request({url: `workflow_processes?type=wait_for_receive&status=${active_status}&positions_user_id=${active_position_user_id}&page=1&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});
      
      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  _setTableOption(){ 
    if(this.state.isDataFetched){
      return "ไม่มีข้อมูล";
    }else{
      return <Loading />
    }
  }

   onRowSelect(row, isSelected, e) {
    let selectedWorkflowProcessIds = _.clone(this.state.selectedWorkflowProcessIds)
    let isIncluded = _.includes(selectedWorkflowProcessIds, row.id)

    if (isIncluded){
      selectedWorkflowProcessIds = _.pull(selectedWorkflowProcessIds, row.id)
    }else{
      selectedWorkflowProcessIds = [...selectedWorkflowProcessIds, row.id]
    }
    
    this.setState({selectedWorkflowProcessIds: selectedWorkflowProcessIds})
  }

  createBatchForwardButton(onClick) {
    let hiddenClass = "";

    if(_.isEmpty(this.state.selectedWorkflowProcessIds)){
      hiddenClass = " d-none"
    }

    return (
      <button className={"btn btn-sm btn-success mt-3" + hiddenClass} onClick={ this.handleBatchForwardClick }>ส่งต่อ</button>
    );
  }

  handleBatchForwardClick(){
    this.setState({ 
      modal_form: "forward",
      modal_title: "ส่งต่อ",
      open: true,
      open_attached_file: false,
      open_insert_file: false,
      workflow_process_id: ''
    });
  }

  getUnselectableDocuments(documents){
    const unselectable = _.reject(documents, { 'workflow_name': 'workflow_internal_receive', 'current_state_name': 'รอนำเสนอเกษียน' })

    return _.map(unselectable, "id");
  }

  handleBatchForward(targets){
    this.preventMultiClicks();
    
    this.setState({
      modal_form: "batchForward",
      modal_title: "กำลังส่งต่อ",
    }, function(){
      this.forwardDocument(targets);
    });
  }

  forwardDocument(targets){
    let {currentForwardIndex, selectedWorkflowProcessIds} = this.state;
    const workflowProcessId = selectedWorkflowProcessIds[currentForwardIndex];

    if(currentForwardIndex < selectedWorkflowProcessIds.length){

      WorkflowProcessStore.request({
        id: workflowProcessId,
        method: 'put',
        data: {
          action_name: "ส่งต่อ",
          targets: targets
        },
      }).then(()=>{
        this.setState({
          currentForwardIndex: currentForwardIndex + 1
        }, function(){
          this.forwardDocument(targets);
        });
      }, function(error){
        let error_message = _.get(error.response.data, 'error.message', '');
        alert(error_message);
      });

    } else {
      this.setState({
        currentForwardIndex: 0
      }, function(){
        window.location.reload();
      });
    }

  }

  preventMultiClicks(){
    this.setState({clicked: true});

    setTimeout(() => {
      this.setState({clicked: false});
    }, 30000);
  }

  handleInsertFile(workflow_process_id, e){
    e.stopPropagation();
    this.setState({
      open_insert_file: true,
      workflow_process_id: workflow_process_id
    });
  }

  handleUpdatedNextReceiveNumber(next_receive_number){
    this.setState({
      next_receive_number: next_receive_number
    }, function(){
      $("#collapseSetNextReceiveNumberTrigger").trigger("click");
    });
  }

  render(){
    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);
    const options = {
      sizePerPageList: [],
      sizePerPage: 10,
      page: active_page,
      noDataText: this._setTableOption(),
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500,
      insertBtn: this.createBatchForwardButton
    };

    let data = [];
    if (!_.isEmpty(this.state.workflow_processes)){
      data = MergeAttributes(this.state.workflow_processes);
    }

    const selectRowProp = {
        mode: 'checkbox',
        onSelect: this.onRowSelect,
        selected: this.state.selected,
        unselectable: this.getUnselectableDocuments(data)
    };

    return (
      <div className="inbox">
        <div className='row'>
          <div className='col-7'>
          </div>
          <div className='col-5 text-right d-none d-lg-block'>
            <Link to='/receive_documents/internal_receive' className='btn btn-primary mr-1'>หนังสือรับใหม่</Link>
            <Link to='/receive_documents/receive_all' className='btn btn-success mr-1'>ทะเบียนหนังสือรับ/จองเลข</Link>
          </div>
        </div>

        <SetNextReceiveNumber 
          label={"หนังสือรอรับ"}
          next_receive_number={this.state.next_receive_number}
          onUpdatedNextReceiveNumber={this.handleUpdatedNextReceiveNumber} />
          
        <div className='rui-mydoc'>
          <BootstrapTable 
            data={data}
            remote={ true }
            fetchInfo={{dataTotalSize: total_items_count}}
            search={ true }
            multiColumnSearch={ true }
            options={ options }
            striped
            hover
            pagination
            version='4'
            tableContainerClass={'table-sm'}
            trClassName={rowClassNameFormatWithUnread}
            insertRow>
            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.actionFormatter} width='100'>รับหนังสือ</TableHeaderColumn>
            <TableHeaderColumn dataField='sender_issue_code' width="70">เลขผู้ส่ง</TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.documentNumberWithFilesFormatter} width="140">ที่</TableHeaderColumn>
            <TableHeaderColumn dataField='document_date_thai_format' dataFormat={thaiDateFormatter} width='90'>ลงวันที่</TableHeaderColumn>
            <TableHeaderColumn dataField='from_department' dataFormat={this.fromDepartmentFormatter} width="200">จาก</TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.documentDetailsFormatter}>รายละเอียดหนังสือ</TableHeaderColumn>
            <TableHeaderColumn dataField='created_at' dataFormat={thaiDateTimeFormatter} width='90'>วันที่ส่ง</TableHeaderColumn>
          </BootstrapTable>
        </div>

        <ShowAttachedFiles 
          workflow_process_id={this.state.workflow_process_id_attachment}
          open_attached_file={this.state.open_attached_file}
          onClose={this.onClose} />

        <HandleOriginalFiles 
          workflow_process_id={this.state.workflow_process_id}
          open_insert_file={this.state.open_insert_file}
          after_save_redirect_to={"/receive_documents/mydoc"}
          onClose={this.onClose} />

        <SimpleModal 
          isOpen={this.state.open}
          title={this.state.modal_title}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onClose}>

            {this.state.modal_form === "history" && (
              <div>
                <WorkflowProcessHistory id={this.state.workflow_process_id_history} />

                <div className="modal-footer row">
                  <button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ปิด</button>
                </div>
              </div>
            )}

            {this.state.modal_form === "forward" && (
              <Forward  internal_users={this.state.internal_users}
                        department_groups={this.state.department_groups}
                        acting_positions_user_ids={this.state.acting_positions_user_ids}
                        actings={this.state.actings}
                        onSubmitAction={this.handleBatchForward}
                        clicked={this.state.clicked}
                        onCloseModal={this.onClose} />
            )}

            {this.state.modal_form === "batchForward" && (
              <BatchForwarding  totalCount={this.state.selectedWorkflowProcessIds.length}/>
            )}
        </SimpleModal>

        <ReceiveDocumentConfirmationModal 
          open={this.state.open_confirm_modal}
          workflow_process_id={this.state.workflow_process_id}
          skip_receive_number={this.state.skip_receive_number}
          next_receive_number={this.state.next_receive_number}
          handleSave={this.handleSubmitAction}
          clicked={this.state.clicked}
          onCloseModal={this.onClose} />
      </div>
    );
  }
}

export default withRouter(DocumentsPage);