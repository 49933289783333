import React, {Component} from 'react';
import _ from 'lodash';
import Autosuggest from 'react-autosuggest';

import UserStore from '../stores/UserStore';
import LoginSession from '../stores/LoginSession';

const renderSuggestion = suggestion => (
  <div className="row">
    <div className="col-11">
      {suggestion.value}
    </div>
  </div>
);

export default class AutoCompleteTextField extends Component {
  constructor(props){
    super(props)

    this.state = {
      options: [],
      value: '',
      suggestions: []
    }

    this.onChange = this.onChange.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps !== this.props) {
      this.setState({
        value: nextProps.value,
        options: nextProps.options
      });
    }
  }

  onChange(event, { newValue }){
    this.setState({
      value: newValue
    }, () => {
      const field = this.props.field;
      this.props.onChange(field, newValue);
    });
  };

  onDeleteItem(value){
    UserStore.request({
      id: LoginSession.current.user.id,
      method: 'PUT',
      action: 'remove_saved_data',
      data: {
        user: {
          field: this.props.field,
          value: value
        }
      }
    }).then((resp)=>{
      const new_options = _.reject(this.state.suggestions, { value: value })

      this.setState({
        suggestions: new_options
      });
    });
    
  }

  getSuggestions(value){
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.state.options.filter(option =>
      option.value.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  getSuggestionValue(suggestion){
    return suggestion.value;
  }

  onSuggestionsFetchRequested({ value }){
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested(){
    this.setState({
      suggestions: []
    });
  };

  render(){
    const { value, suggestions } = this.state;

    const inputProps = {
      value,
      onChange: this.onChange
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    )
  }
}