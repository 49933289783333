import React, { Component } from 'react';
import _ from 'lodash';
import renderHTML from 'react-render-html';
import LineShareIcon from '../../assets/images/wide-default.png';

import Loading from '../../components/Loading';
import ForumStore from '../../stores/ForumStore';
import LoginSession from '../../stores/LoginSession';
import SimpleModal from '../../components/SimpleModal';
import AddCommentForm from './AddCommentForm';
import ForumForm from './ForumForm';
import './stylesheets/ForumShowPage.css';

class ForumShowPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      isDataFetched: false,
      forum: {},
      open: false,
      title: '',
      body: ''
    }

    this.onEditTopic = this.onEditTopic.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeBody = this.handleChangeBody.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openLineShareWindow = this.openLineShareWindow.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(){
    ForumStore.request({id: this.props.params.id}).then((resp)=>{
      const forum = _.get(resp.data, 'data', {});
      const title = _.get(forum.attributes, 'title', '');
      const body = _.get(forum.attributes, 'body', '');
      this.setState({
        forum: forum,
        title: title,
        body: body
      })
    }).then(()=>{
      this.setState({isDataFetched: true, open: false});
    });
  }

  onEditTopic(){
    this.setState({open: true});
  }

  onClose(){
    this.setState({open: false});
  }

  handleChange(event){
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleChangeBody(value){
    this.setState({
      body: value
    });
  }

  handleSubmit(event){
    event.preventDefault();
    console.log('handleSave');

    const { forum, title, body } = this.state;
    let self = this;
    ForumStore.save(forum.id, {
      topic: {
        title: title,
        body: body
      }
    }).then(()=>{
      this.setState({
        open: false
      }, this.loadData());
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  openLineShareWindow(){
    const w = 503;
    const h = 510;
    const left = (window.screen.width/2)-(w/2);
    const top = (window.screen.height/2)-(h/2);
    const lineUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURI(window.location.href)}`;

    window.open(lineUrl, '_blank', `height=${h},width=${w},top=${top},left=${left}`);
  }

  render(){
    const { open, isDataFetched, forum, title, body } = this.state;
    const current_user_id = _.get(LoginSession.currentUser, 'id', '');
    const owner_id = _.get(forum.attributes, 'user.id', '');

    return (
      <div className='forums'>
        {!isDataFetched ? <Loading /> : (
        <div className='row'>
          <div className='col-12'>
            <span className='text-black-50'>
              {`สร้างกระทู้โดย ${forum.attributes.post_by} ${forum.attributes.created_at}`}
              {(forum.attributes.created_at !== forum.attributes.updated_at) && (
                <small className='ml-2 text-dark'>{`(มีการแก้ไขเนื้อหากระทู้ ${forum.attributes.updated_at})`}</small>
              )}
            </span>
          </div>
          <div className='col-12 col-md-10'>
            <h4 className='font-weight-bold'>
              {forum.attributes.title}
            </h4>
          </div>
          <div className='col-12 col-md-2 text-right'>
            {(current_user_id && owner_id && current_user_id === owner_id) && (
              <span className='ml-3 pointer text-muted font-weight-bold' onClick={this.onEditTopic}>
                <u>แก้ไข</u>
              </span>
            )}
          </div>
          <div className='col-12 my-3'>
            <div className='border bg-light p-3 forum-content'>
              {renderHTML(forum.attributes.body)}
            </div>
          </div>
          <div className='col-12'>
            <span className='text-muted font-weight-bold pointer share-to-line-group'>
              <img 
                alt='LINE SHARE' 
                onClick={this.openLineShareWindow} 
                src={LineShareIcon} 
                style={{height: '24px'}} />
            </span>
          </div>
          <div className='col-12 my-3'>
            <AddCommentForm forum={forum} />
          </div>
        </div>
        )}

        <ForumForm 
          open={open}
          edit_topic_id={forum.id}
          title={title}
          body={body}
          onClose={this.onClose}
          handleChange={this.handleChange}
          handleChangeBody={this.handleChangeBody}
          handleSubmit={this.handleSubmit} />
      </div>
    )
  }
}

export default ForumShowPage;