import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import qs from 'qs';
import arrayMove from 'array-move';

import SimpleModal from '../../components/SimpleModal';
import TopHeader from '../../layouts/TopHeader';
import DocumentExample from './actions/DocumentExample';
import DocumentActions from './actions/DocumentActions';
import IssueNumberPanel from './actions/IssueNumberPanel';
import AttachmentList from './actions/AttachmentList';
import ReferenceDocumentList from './actions/ReferenceDocumentList';
import DocumentVersions from './actions/DocumentVersions';

import SelectReviewer from '../../components/WorkflowActions/SelectReviewer';
import AddComment from '../../components/WorkflowActions/AddComment';
import Confirm from '../../components/WorkflowActions/Confirm';
import IssueNumber from '../../components/WorkflowActions/IssueNumber';
import ReceiveNumber from '../../components/WorkflowActions/ReceiveNumber';
import Note from '../../components/WorkflowActions/Note';
import EditNote from '../../components/WorkflowActions/EditNote';
import Order from '../../components/WorkflowActions/Order';
import Forward from '../../components/WorkflowActions/Forward';
import CommentList from '../../components/WorkflowActions/CommentList';
import AfterSaveLoading from '../../components/AfterSaveLoading';

import DocumentStore from '../../stores/DocumentStore';
import WorkflowProcessStore from '../../stores/WorkflowProcessStore';
import ReceiveNumberStore from '../../stores/ReceiveNumberStore';

class ShowDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      document: {},
      workflow_process: {},
      workflow_process_histories: [],
      comment_wph_histories: [],
      workflow_states: [],
      workflow_state_actions: [],
      attachments: [],
      reference_documents: [],
      all_users: [],
      internal_users: [],
      informee_positions_users: [],
      department_groups: [],
      secretary_groups: [],
      actings: [],
      acting_positions_user_ids: [],
      show_attachment: false,
      show_reference_document: false,
      action_name: "",
      modal_open: false,
      modal_title: "",
      modal_form: "",
      clicked: false,
      document_versions: [],
      showIssueNumberPanel: false,
      original_document_files: [],
      skip_receive_number: false,
      next_receive_number: '',
      latest_note_text: ''
    }

    this.attachmentToggle = this.attachmentToggle.bind(this);
    this.referenceDocumentToggle = this.referenceDocumentToggle.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.redirectToEditDocumentPage = this.redirectToEditDocumentPage.bind(this);
    this.redirectBack = this.redirectBack.bind(this);
    this.openReviewerModal = this.openReviewerModal.bind(this);
    this.openCommentListModal = this.openCommentListModal.bind(this);
    this.handleSaveNoteMessage = this.handleSaveNoteMessage.bind(this);
    this.handleUpdateNoteMessage = this.handleUpdateNoteMessage.bind(this);
    this.handleSubmitAction = this.handleSubmitAction.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
    this.handleForward = this.handleForward.bind(this);
    this.handleSubmitIssueNumber = this.handleSubmitIssueNumber.bind(this);
    this.handleSubmitReceiveNumber = this.handleSubmitReceiveNumber.bind(this);
    this.handleReloadWithoutPreview = this.handleReloadWithoutPreview.bind(this);
    this.handleArchiveDocument = this.handleArchiveDocument.bind(this);
    this.handleReloadDocument = this.handleReloadDocument.bind(this);
    this.toggleIssueNumberPanel = this.toggleIssueNumberPanel.bind(this);
    this.handleSubmitNumberBook = this.handleSubmitNumberBook.bind(this);
    this.handleIssueNumberAction = this.handleIssueNumberAction.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.preventMultiClicks = this.preventMultiClicks.bind(this);
    this.openEditDocumentModal = this.openEditDocumentModal.bind(this);
    this.handleUpdateDocument = this.handleUpdateDocument.bind(this);
    this.getNextNumber = this.getNextNumber.bind(this);

    this.handleChangeOriginalDoc = this.handleChangeOriginalDoc.bind(this);
    this.onRemoveOriginalDocument = this.onRemoveOriginalDocument.bind(this);
    this.handleInsert = this.handleInsert.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  componentDidMount(){
    DocumentStore.request({id: this.props.document_id}).then((resp)=>{
      const document = _.get(resp.data, 'data', {});
      const included = _.get(resp.data, 'included', {});
      const all_users = included.all_users || [];
      const all_groups = included.all_groups || [];
      const root_group = included.root_group || [];
      const internal_users = included.internal_users || [];
      const informee_positions_users = included.informee_positions_users || [];
      const department_groups = included.department_groups || [];
      const secretary_groups = included.secretary_groups || [];
      const actings = included.actings || [];
      const acting_positions_user_ids = _.map(actings, 'attributes.positions_user.id') || [];

      this.setState({
        document: document,
        all_users: all_users,
        all_groups: all_groups,
        root_group: root_group,
        internal_users: internal_users,
        informee_positions_users: informee_positions_users,
        department_groups: department_groups,
        secretary_groups: secretary_groups,
        actings: actings,
        acting_positions_user_ids: acting_positions_user_ids
      })
    }).then(()=>{
      this.reloadWorkflowProcess(this.props.workflow_process_id);
    }).then(()=>{
      this.getNextNumber();
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  reloadWorkflowProcess(workflow_process_id){
    WorkflowProcessStore.request({id: workflow_process_id}).then((resp)=>{
      const workflow_process = _.get(resp.data, 'data', {});
      const included = _.get(resp.data, 'included', {});
      const workflow_process_histories = included.workflow_process_histories || [];
      const workflow_states = included.workflow_states || [];
      const workflow_state_actions = included.workflow_state_actions || [];
      const attachments = _.get(included, 'attachments', []);
      const reference_documents = _.get(included, 'reference_documents', []);
      const document_versions = _.get(included, 'document_versions', []);
      const original_document_files = _.get(workflow_process, 'attributes.original_documents', []);
      const latest_note_text = _.get(workflow_process, 'attributes.latest_note_text')

      const comment_wph_histories = _.reject(workflow_process_histories, function(wph) { 
                                  return _.isEmpty(wph.attributes.comment) && _.isEmpty(wph.attributes.note_text); 
                                })
      
      this.setState({ 
        original_document_files: original_document_files,
        workflow_process: workflow_process,
        workflow_process_histories: workflow_process_histories,
        workflow_states: workflow_states,
        workflow_state_actions: workflow_state_actions,
        comment_wph_histories: comment_wph_histories,
        attachments: attachments,
        reference_documents: reference_documents,
        document_versions: document_versions,
        latest_note_text: latest_note_text
      })
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  getNextNumber(){
    ReceiveNumberStore.request({url: `receive_numbers/get_next_receive_number`}).then((resp)=>{
      const next_receive_number = _.get(resp.data, 'data.next_number', "");

      this.setState({ next_receive_number: next_receive_number });
    }, function(error){
      this.setState({loading: false});
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }
  
  preventMultiClicks(){
    this.setState({clicked: true});

    setTimeout(() => {
      this.setState({clicked: false});
    }, 30000);
  }

  attachmentToggle(){
    let show = true;
    if (this.state.show_attachment) {
      show = false;
    }
    this.setState({show_attachment: show, show_reference_document: false});
  }

  referenceDocumentToggle(){
    let show = true;
    if (this.state.show_reference_document) {
      show = false;
    }
    this.setState({show_reference_document: show, show_attachment: false});
  }

  getCommentSize(){
    return _.size(this.state.comment_wph_histories)
  }

  withoutOriginalAttachment(){
    if (_.isEmpty(this.state.attachments)) { return [] };

    const without_original_attachments = _.filter(this.state.attachments, function(a) {
      return a.attributes.is_original == false
    });
    return without_original_attachments
  }

  getAttachmentSize(){
    const without_original_attachments = this.withoutOriginalAttachment();
    return _.size(without_original_attachments);
  }

  getReferenceDocumentSize(){
    return _.size(this.state.reference_documents)
  }

  redirectToEditDocumentPage(){
    const { document, workflow_process } = this.state;
    const parent_document_template = document.attributes.parent_document_template;
    
    if(document.attributes.is_upload_file){
      if (document.attributes.is_internal_receive){
        this.props.history.push(`/receive_documents/internal_receive/${document.id}/wp/${workflow_process.id}/edit`);
      }else{
        this.props.history.push(`/send_documents/send/${document.id}/edit`);
      }
    } else {
      this.props.history.push(`/templates/documents/${document.attributes.parent_id}/edit/${parent_document_template.id}`);
    }
    
  }

  redirectToCreateFrontPage(){
    const document = this.state.document;
    this.props.history.push(`/frontpage/${document.attributes.child_id}/new`);
  }

  redirectToEditFrontPage(){
    const document = this.state.document;
    this.props.history.push(`/frontpage/${document.attributes.child_id}/edit`);
  }

  redirectBack(){
    this.props.history.go(-1);
  }

  openEditDocumentModal(){
    const { document, workflow_process } = this.state;
    const state_name = _.get(workflow_process, 'attributes.current_workflow_state.attributes.state_name', '');
    
    if (state_name === "เอกสารส่งออก") {
      this.props.history.push(`/send_documents/send/${document.id}/edit`);
    }else{
      this.props.history.push(`/receive_documents/internal_receive/${document.id}/wp/${workflow_process.id}/edit`);
    }
  }

  openReviewerModal(){
    this.setState({
      modal_open: true,
      modal_form: "select_reviewer",
    });
  }

  openCommentModal(){
    this.setState({
      modal_open: true,
      modal_form: "add_comment",
    });
  }

  openCommentListModal(){
    this.setState({
      modal_open: true,
      modal_form: "comment_list",
      modal_title: "ความคิดเห็น"
    });
  }

  openConfirmModal(){
    this.setState({
      modal_open: true,
      modal_form: "confirm",
    });
  }

  openReceiveNumberModal(){
    this.setState({
      modal_open: true,
      modal_form: "receive_number",
    });
  }

  openIssueNumberModal(){
    this.setState({
      modal_open: true,
      modal_form: "issue_number",
    });
  }

  openFrontPageIssueNumberModal(){
    this.setState({
      modal_open: true,
      modal_form: "front_page_issue_number",
    });
  }

  openNoteModal(){
    this.setState({
      modal_open: true,
      modal_form: "note",
    });
  }

  openEditNoteModal(){
    this.setState({
      modal_open: true,
      modal_form: "edit_note",
    });
  }

  openOrderModal(){
    this.setState({
      modal_open: true,
      modal_form: "order",
    });
  }

  openForwardModal(){
    this.setState({
      modal_open: true,
      modal_form: "forward",
    });
  }

  handleUpdateDocument(){
    alert("handleUpdateDocument");
  }

  handleActionClick(action_name){
    const state_name = this.state.workflow_process.attributes.current_workflow_state.attributes.state_name;
  
    if(action_name === "กลับ"){
      this.redirectBack();
      return;
    }

    if(state_name === "ส่งต่อเกษียน"){
      this.setState({action_name: action_name}, () => {
        this.handleSubmitAction();
      });
      
      return;
    }

    this.setState({action_name: action_name}, () => {
      switch(action_name) {
        case "แก้ไข":
          this.openEditDocumentModal();
          break;
        case "แก้ไขเอกสารหลัก":
          this.redirectToEditDocumentPage();
          break;
        case "ร่างใบปะหน้า":
          this.redirectToCreateFrontPage();
          break;
        case "แก้ไขใบปะหน้า":
          this.redirectToEditFrontPage();
          break;
        case "ส่งตรวจทาน":
          this.setState({modal_title: "ส่งตรวจทาน"}, () => {
            this.openReviewerModal();
          })
          break;
        case "ส่งกลับแก้ไข":
          this.setState({modal_title: "ส่งกลับแก้ไข"}, () => {
            this.openCommentModal();
          })
          break;
        case "ยกเลิกเอกสาร":
          this.setState({modal_title: "ยกเลิกเอกสาร"}, () => {
            this.openCommentModal();
          })
          break;
        case "ส่งกลับผู้ร่าง":
          this.setState({modal_title: "ส่งกลับผู้ร่าง"}, () => {
            this.openCommentModal();
          })
          break;
        case "ผ่าน (ส่งต่อผู้ลงนาม)":
          this.handleSubmitAction();
          break;
        case "ลงนาม":
          this.handleSubmitAction();
          break;
        case "ออกเลข":
          this.toggleIssueNumberPanel();
          break;
        case "ออกเลข (ใบปะหน้า)":
          this.handleSubmitAction();
          break;
        case "ผ่าน (ใบปะหน้า)":
          this.setState({modal_title: "ส่งผ่านใบปะหน้า"}, () => {
            this.openEditNoteModal();
          })
          break;
        case "ส่งผ่าน":
          this.setState({modal_title: "ส่งผ่าน"}, () => {
            this.openEditNoteModal();
          })
          break;
        case "ส่งต่อสารบรรณ":
          this.handleSubmitAction();
          break;
        case "ส่งกลับสารบรรณ":
          this.handleSubmitAction();
          break;
        case "ผ่าน":
          this.handleSubmitAction();
          break;
        case "ไม่ผ่าน":
          this.setState({modal_title: "ส่งกลับผู้ร่าง"}, () => {
            this.openCommentModal();
          })
          break;
        case "รับหนังสือ":
          this.setState({modal_title: "ยืนยันการรับหนังสือเพื่อดำเนินการต่อ"}, () => {
            this.openConfirmModal();
          })
          break;
        case "รับหนังสือ (ไม่ออกเลข)":
          this.setState({modal_title: "ยืนยันการรับหนังสือ (ไม่ออกเลข)"}, () => {
            this.openConfirmModal();
          })
          break;
        case "แทรกเลข":
          this.setState({modal_title: "แทรกเลข"}, () => {
            if(state_name === "รอออกเลข"){
              this.openIssueNumberModal();
            } else {
              this.openReceiveNumberModal();
            }
          })
          break;
        case "แทรกเลข (ใบปะหน้า)":
          this.setState({modal_title: "แทรกเลข (ใบปะหน้า)"}, () => {
            if(state_name === "รอออกเลข (ใบปะหน้า)"){
              this.openFrontPageIssueNumberModal();
            } else {
              this.openReceiveNumberModal();
            }
          })
          break;
        case "ส่งคืน":
          this.setState({modal_title: "ส่งคืน"}, () => {
            this.openCommentModal();
          })
          break;
        case "ตีกลับ":
          this.setState({modal_title: "ตีกลับ"}, () => {
            this.openCommentModal();
          })
          break;
        case "ส่งต่อ":
          this.setState({modal_title: "ส่งต่อ"}, () => {
            this.openForwardModal();
          })
          break;
        case "นำเสนอเกษียนหนังสือ":
          this.setState({modal_title: "นำเสนอเกษียนหนังสือ"}, () => {
            this.openNoteModal();
          })
          break;
        case "นำเสนอเกษียน":
          this.setState({modal_title: "นำเสนอเกษียนหนังสือ"}, () => {
            this.openNoteModal();
          })
          break;
        case "ส่งต่อเกษียน":
          this.handleSubmitAction();
          break;
        case "ลงนามกำกับการเกษียน":
          this.handleSubmitAction();
          break;
        case "ส่งกลับหน่วยงาน":
          this.setState({modal_title: "ส่งกลับหน่วยงาน"}, () => {
            this.openCommentModal();
          })
          break;
        case "แก้ไขข้อความสรุป":
          this.setState({modal_title: "แก้ไขข้อความสรุป"}, () => {
            this.openEditNoteModal();
          })
          break;
        case "ส่งต่อหน่วยงานอื่น":
          this.setState({modal_title: "ส่งต่อหน่วยงานอื่น"}, () => {
            this.openForwardModal();
          })
          break;
        case "นำเสนอลงนาม":
          this.setState({modal_title: "นำเสนอลงนาม"}, () => {
            this.openEditNoteModal();
          })
          break;
        case "ส่งกลับเจ้าของเรื่อง":
          this.setState({modal_title: "ส่งกลับเจ้าของเรื่อง"}, () => {
            this.openCommentModal();
          })
          break;
        case "คืนเรื่อง":
          this.setState({modal_title: "คืนเรื่อง"}, () => {
            this.openCommentModal();
          })
          break;
        case "จบการทำงาน":
          this.setState({modal_title: "ยืนยันจบการทำงาน"}, () => {
            this.openConfirmModal();
          })
          break;
        case "จัดเก็บเอกสาร":
          this.handleArchiveDocument();
          break;
        case "โหลดเอกสารอีกครั้ง":
          this.handleReloadDocument();
          break;
        case "รับทราบปิดงาน":
          this.setState({modal_title: "รับทราบปิดงาน"}, () => {
            this.openCommentModal();
          })
          break;
        case "ปิดงาน":
          this.setState({modal_title: "ปิดงาน"}, () => {
            this.openCommentModal();
          })
          break;
        case "ดำเนินการต่อ":
          this.handleReloadWithoutPreview();
          break;
        default:
          alert(action_name);
          break;
      }
    });
  }

  handleSubmitAction(positions_user_id, comment){
    this.preventMultiClicks();

    const workflow_process_id = this.state.workflow_process.id;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      data: {
        action_name: this.state.action_name,
        positions_user_id: positions_user_id,
        comment: comment
      },
    }).then(()=>{
      this.props.history.go(-1);
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  handleIssueNumberAction(issue_number_type, number_book_name){
    this.preventMultiClicks();

    const workflow_process_id = this.state.workflow_process.id;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      data: {
        action_name: issue_number_type,
        number_book_name: number_book_name
      },
    }).then(()=>{
      this.props.history.go(-1);
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  handleArchiveDocument(positions_user_id){
    const workflow_process_id = this.state.workflow_process.id;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      action: 'archive'
    }).then(()=>{
      this.props.history.go(-1);
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  handleReloadDocument(){
    const workflow_process_id = this.state.workflow_process.id;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      action: 'reload'
    }).then(()=>{
      window.location.reload();
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  handleSaveNoteMessage(note_data){
    this.preventMultiClicks();
    
    const workflow_process_id = this.state.workflow_process.id;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      data: {
        action_name: this.state.action_name,
        note_data: {...note_data},
      },
    }).then(()=>{
      this.props.history.go(-1);
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  handleUpdateNoteMessage(note_text){
    this.preventMultiClicks();
    
    const workflow_process_id = this.state.workflow_process.id;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      data: {
        action_name: this.state.action_name,
        note_data: { 
          note_text: note_text
        },
      },
    }).then(()=>{
      this.props.history.go(-1);
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  handleOrder(note_text, positions_user_ids, order_type, external_department){
    this.preventMultiClicks();
    this.setState({loading: true});
  
    // คำสั่งการพิเศษ
    let action_name = this.state.action_name;
    if(order_type === "return_to_central_correspondence"){
      action_name = "ส่งคืนสารบรรณกรม";
    }
    if(order_type === "return_to_document_owner"){
      action_name = "ส่งคืนเจ้าของหนังสือ";
    }

    var data = new FormData();

    if (!_.isEmpty(this.state.original_document_files)){
      _.forEach(this.state.original_document_files, function(file){
        if (file.file){
          data.append('original_document_files[][id]', file['id'] || '');
          data.append('original_document_files[][file]', file.file);
        }
      })
    }

    _.forEach(positions_user_ids, function(positions_user_id){
      data.append('targets[][positions_user_id]', positions_user_id);
    });

    data.append('action_name', action_name || '');    
    data.append('note_data[note_text]', note_text || '');
    data.append('order_type', order_type || '');
    data.append('external_department', external_department || '');
    
    const workflow_process_id = this.state.workflow_process.id;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      data: data
    }).then(()=>{
      this.props.history.go(-1);
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  handleForward(targets){
    this.preventMultiClicks();
    
    const workflow_process_id = this.state.workflow_process.id;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      data: {
        action_name: this.state.action_name,
        targets: targets
      },
    }).then(()=>{
      this.props.history.go(-1);
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  handleSubmitIssueNumber(issue_number_id){
    this.preventMultiClicks();
    
    const workflow_process_id = this.state.workflow_process.id;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      data: {
        action_name: this.state.action_name,
        issue_number_id: issue_number_id
      },
    }).then(()=>{
      this.props.history.go(-1);
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  handleSubmitReceiveNumber(receive_number_id){
    this.preventMultiClicks();
    
    const workflow_process_id = this.state.workflow_process.id;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      data: {
        action_name: this.state.action_name,
        receive_number_id: receive_number_id
      },
    }).then(()=>{
      this.props.history.go(-1);
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  handleReloadWithoutPreview(){
    window.location = window.location.pathname;
  }

  onCloseModal(){
    this.setState({ 
      modal_open: false,
      modal_title: "",
      modal_form: "",
      action_name: "",
    });
  }

  toggleIssueNumberPanel(){
    this.setState({
      showIssueNumberPanel: !this.state.showIssueNumberPanel
    });
  }

  handleSubmitNumberBook(issue_number_type, number_book_name){
    this.setState({
      action_name: issue_number_type,
    }, function(){
      this.handleIssueNumberAction(issue_number_type, number_book_name);
    });
  }

  handleChangeOriginalDoc(event){
    var original_file = event.target.files[0];
    if (original_file.size === 0) { return }
    if (original_file.type !== "application/pdf"){
      alert("แนบไฟล์-เอกสารต้นฉบับ จำกัดประเภทเฉพาะไฟล์นามสกุล .pdf");
      return
    }

    const new_file = {
      file: original_file
    }

    let { original_document_files } = this.state;

    original_document_files = _.concat(original_document_files, [new_file]);

    this.setState({
      original_document_files: original_document_files
    });
  }

  handleInsert(files){
    this.setState({
      original_document_files: files
    });
  }

  handleReplace(files){
    this.setState({
      original_document_files: files
    });
  }

  onRemoveOriginalDocument(index){
    let { original_document_files } = this.state;
    original_document_files.splice(index, 1);

    this.setState({
      original_document_files: original_document_files
    });
  }

  onSortEnd({oldIndex, newIndex}){
    this.setState(({original_document_files}) => ({
      original_document_files: arrayMove(this.state.original_document_files, oldIndex, newIndex),
    }));
  }

  render(){
    let state_name = '';
    if (!_.isEmpty(this.state.workflow_process)){
      const workflow_process = this.state.workflow_process;
      state_name = _.get(workflow_process, "attributes.current_workflow_state.attributes.state_name");
    }
    
    const assignees = _.get(this.state.workflow_process, "attributes.positions_users");
    const assignee_position_names = _.map(assignees, (assignee) => {
                                      return assignee.attributes.position_name;
                                    }).join(", ")

    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    let is_receive_document = false;
    const { document } = this.state;
    const is_external_receive = _.get(document, 'attributes.is_external_receive', false);
    const is_internal_receive = _.get(document, 'attributes.is_internal_receive', false);
    if (is_external_receive || is_internal_receive) {
      is_receive_document = true;
    }

    return (
      <div>
        {this.state.loading && <AfterSaveLoading />}
        
        <TopHeader siteSetting={this.props.siteSetting} />

        <div className='row m-3'>
          <div className='col-12 col-lg-2 px-0'>
            <div className='row'>
              <div className='col-12'>
                <div className='mb-2 p-2 text-white' style={{backgroundColor: 'rgb(49, 90, 125)'}}>
                  <h5>ตัวอย่างเอกสาร</h5>
                  <div>
                    เรียน {assignee_position_names}
                  </div>
                  <div>
                    {state_name}
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 mt-1'>
                <button type='button' className='btn btn-sm btn-secondary btn-block' onClick={this.openCommentListModal}>
                  <i className="fa fa-weixin mr-2" aria-hidden="true"></i>
                  ความคิดเห็น ({this.getCommentSize()})
                </button>
              </div>
              <div className='col-12 mt-1'>
                <button type='button' className='btn btn-sm btn-secondary btn-block' onClick={this.attachmentToggle}>
                  <i className="fa fa-file mr-2" aria-hidden="true"></i>
                  ไฟล์แนบ ({this.getAttachmentSize()})
                </button>
              </div>
              <div className='col-12 mt-1'>
                <button type='button' className='btn btn-sm btn-secondary btn-block' onClick={this.referenceDocumentToggle}>
                  <i className="fa fa-tasks mr-2" aria-hidden="true"></i>
                  เอกสารอ้างอิง ({this.getReferenceDocumentSize()})
                </button>
              </div>
            </div>

            {this.state.show_attachment && (
              <div className='row mt-2'>
                <div className='col-12'>
                  <div className='card bg-light py-1'>
                    <AttachmentList attachments={this.withoutOriginalAttachment()} />
                  </div>
                </div>
              </div>
            )}

            {this.state.show_reference_document && (
              <div className='row mt-2'>
                <div className='col-12'>
                  <div className='card bg-light py-1'>
                    <ReferenceDocumentList  reference_documents={this.state.reference_documents} />
                  </div>
                </div>
              </div>
            )}

            {/*((is_receive_document || this.props.document_template.attributes.is_upload_file) && this.state.document_versions) && (
              <DocumentVersions document_versions={this.state.document_versions} />
            )*/}
          </div>

          <div className='col-12 col-lg-8'>
            {!_.isEmpty(this.state.workflow_process) && (
              <DocumentExample 
                document={this.state.document} 
                workflow_process={this.state.workflow_process} 
                document_template={this.props.document_template}/>
            )}
          </div>

          <div className='col-12 col-lg-2'>
            {!_.isEmpty(this.state.workflow_process) && (this.state.showIssueNumberPanel == false) && (
              <DocumentActions  
                document={this.state.document} 
                workflow_process={this.state.workflow_process} 
                onActionClick={this.handleActionClick}
                clicked={this.state.clicked}
                preview={params.preview} />
            )}
            {this.state.showIssueNumberPanel && (
              <IssueNumberPanel
                document={this.state.document}
                workflow_process={this.state.workflow_process}
                onActionClick={this.handleSubmitNumberBook}
                toggleIssueNumberPanel={this.toggleIssueNumberPanel}
                clicked={this.state.clicked} />
            )}
          </div>
        </div>

        <SimpleModal 
          isOpen={this.state.modal_open}
          title={this.state.modal_title}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onCloseModal}>
            {this.state.modal_form === "select_reviewer" && (
              <SelectReviewer internal_users={this.state.internal_users} 
                              all_users={this.state.all_users}
                              acting_positions_user_ids={this.state.acting_positions_user_ids}
                              actings={this.state.actings}
                              onSubmitAction={this.handleSubmitAction}
                              onCloseModal={this.onCloseModal} />
            )}
            {this.state.modal_form === "add_comment" && (
              <AddComment onSubmitAction={this.handleSubmitAction}
                          clicked={this.state.clicked}
                          onCloseModal={this.onCloseModal} />
            )}
            {this.state.modal_form === "confirm" && (
              <Confirm  
                skip_receive_number={this.state.skip_receive_number}
                next_receive_number={this.state.next_receive_number}
                onSubmitAction={this.handleSubmitAction}
                clicked={this.state.clicked}
                onCloseModal={this.onCloseModal} />
            )}
            {this.state.modal_form === "issue_number" && (
              <IssueNumber  workflow_process={this.state.workflow_process}
                            onSubmitAction={this.handleSubmitIssueNumber}
                            clicked={this.state.clicked}
                            onCloseModal={this.onCloseModal}
                            type_name={this.state.workflow_process.attributes.type_name} />
            )}
            {this.state.modal_form === "front_page_issue_number" && (
              <IssueNumber  workflow_process={this.state.workflow_process}
                            is_front_page={true}
                            onSubmitAction={this.handleSubmitIssueNumber}
                            clicked={this.state.clicked}
                            onCloseModal={this.onCloseModal}
                            type_name={this.state.workflow_process.attributes.type_name} />
            )}
            {this.state.modal_form === "receive_number" && (
              <ReceiveNumber  onSubmitAction={this.handleSubmitReceiveNumber}
                              clicked={this.state.clicked}
                              onCloseModal={this.onCloseModal}
                              type_name={this.state.workflow_process.attributes.type_name} />
            )}
            {this.state.modal_form === "note" && (
              <Note internal_users={this.state.internal_users} 
                    informee_positions_users={this.state.informee_positions_users}
                    department_groups={this.state.department_groups}
                    acting_positions_user_ids={this.state.acting_positions_user_ids}
                    actings={this.state.actings}
                    onSubmitAction={this.handleSaveNoteMessage}
                    clicked={this.state.clicked}
                    onCloseModal={this.onCloseModal} />
            )}
            {this.state.modal_form === "edit_note" && (
              <EditNote onSubmitAction={this.handleUpdateNoteMessage}
                        clicked={this.state.clicked}
                        onCloseModal={this.onCloseModal} />
            )}
            {this.state.modal_form === "forward" && (
              <Order  
                all_groups={this.state.all_groups}
                root_group={this.state.root_group}
                internal_users={this.state.internal_users}
                document={this.state.document}
                workflow_process={this.state.workflow_process}
                latest_note_text={this.state.latest_note_text}
                onSubmitAction={this.handleOrder}
                clicked={this.state.clicked}
                document_versions={this.state.document_versions}
                original_document_files={this.state.original_document_files}
                handleChangeOriginalDoc={this.handleChangeOriginalDoc}
                onRemoveOriginalDocument={this.onRemoveOriginalDocument}
                handleInsert={this.handleInsert}
                handleReplace={this.handleReplace}
                onSortEnd={this.onSortEnd}
                onCloseModal={this.onCloseModal} />
            )}
            {this.state.modal_form === "comment_list" && (
              <CommentList  comment_wph_histories={this.state.comment_wph_histories}
                            workflow_states={this.state.workflow_states}
                            workflow_state_actions={this.state.workflow_state_actions}
                            onCloseModal={this.onCloseModal} />
            )}
        </SimpleModal>
      </div>
    )
  }
}

export default withRouter(ShowDocument);
