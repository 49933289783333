import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import _ from 'lodash';
import '../pages/documents/stylesheets/IssueNumberTable.css';

import { MergeAttributes } from './FlatData';
import IssueNumberStore from '../stores/IssueNumberStore';
import Loading from './Loading';
import { thaiDateFormatter } from '../utils/bootstrapTableFormatters';


class IssueNumberTable extends Component {
  constructor(){
    super();

    this.state = {
      isDataFetched: false,
      issue_numbers: [],
      pagination: {},
      searchText: ''
    }

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount(){
    const { typeName } = this.props;

    let url = `issue_numbers/get_booked_issue_numbers/${this.props.workflow_process.id}?type=${typeName}`;

    if(this.props.is_front_page){
      url = `issue_numbers/get_booked_front_page_issue_numbers/${this.props.workflow_process.id}?type=${typeName}`;
    }

    IssueNumberStore.request({url: url}).then((resp)=>{
      const issue_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        issue_numbers: issue_numbers,
        pagination: pagination
      })

    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  _setTableOption(){ 
    if(this.state.isDataFetched){
      return "ไม่มีข้อมูล";
    }else{
      return <Loading />
    }
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, issue_numbers: []});
    const { searchText } = this.state;
    const { typeName } = this.props;

    IssueNumberStore.request({url: `issue_numbers/get_booked_issue_numbers/${this.props.workflow_process.id}?page=${page}&searchText=${searchText}&type=${typeName}`}).then((resp)=>{
      const issue_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        issue_numbers: issue_numbers,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, issue_numbers: []});
    const { typeName } = this.props;

    IssueNumberStore.request({url: `issue_numbers/get_booked_issue_numbers/${this.props.workflow_process.id}?page=1&searchText=${searchText}&type=${typeName}`}).then((resp)=>{
      const issue_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        issue_numbers: issue_numbers,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  render(){
    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);

    var data = [];
    if (this.state.isDataFetched){
      data = MergeAttributes(this.state.issue_numbers);
    }

    const selectRowProp = {
      mode: 'radio',
      clickToSelect: true,
      bgColor: 'pink',
      hideSelectColumn: true,
      onSelect: this.props.onSelect
    };
    const options = {
      sizePerPageList: [],
      sizePerPage: 10,
      page: active_page,
      noDataText: this._setTableOption(),
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500
    };

    return(
      <div className="row">
        <div className="col-12 rui-issue-number-tb">
          <BootstrapTable 
            data={data}
            remote={ true }
            fetchInfo={{dataTotalSize: total_items_count}}
            search={ true }
            multiColumnSearch={ true }
            selectRow={ selectRowProp }
            options={ options }
            striped
            hover
            condensed
            pagination
            version='4'
            tableContainerClass={'table-sm'}>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='number' dataSort={ true } width='100'>เลขที่เอกสาร</TableHeaderColumn>
              <TableHeaderColumn dataField='topic' dataSort={ true }>เรื่อง</TableHeaderColumn>
              <TableHeaderColumn dataField='from_department' dataSort={ true }>จากหน่วยงาน</TableHeaderColumn>
              <TableHeaderColumn dataField='created_at' dataFormat={ thaiDateFormatter } dataSort={ true } width='100'>วันที่รับเอกสาร</TableHeaderColumn>
              <TableHeaderColumn dataField='type_name' width='100'>ประเภท</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    )
  }
}

export default IssueNumberTable;
