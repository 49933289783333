import React, {Component} from 'react';
import _ from 'lodash';

import UserImage from '../../../assets/images/16User.png';
import { getColSize } from '../../../utils/index';

import SimpleModal from '../../SimpleModal';
import SelectInformee from './SelectInformee';
import SelectMultipleInformee from './SelectMultipleInformee';

const EditingFieldName = 'informs';

export class InformeeForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			open: false,
			mode: 'overview',
			editing_index: '',
			editing_inform: {},
			informs: this.props.informs,
			send_all_informs: false
		}

		this.renderRow = this.renderRow.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onCloseSelectForm = this.onCloseSelectForm.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleAddInform = this.handleAddInform.bind(this);
		this.onInformOpen = this.onInformOpen.bind(this);
		this.handleChangeShowOneLine = this.handleChangeShowOneLine.bind(this);
		this.renderExecutor = this.renderExecutor.bind(this);
		this.onSendAllDepartmentGroups = this.onSendAllDepartmentGroups.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.informs !== nextProps.informs) {
			this.setState({ 
				informs: nextProps.informs 
			});
		}
	}

	onSelectUser(inform, index){
		this.setState({
			editing_index: index, 
			editing_inform: inform, 
			mode: 'select_inform_mode'
		});
	}

	handleSubmit(){
		this.setState({open: false}, this.props.handleChange(EditingFieldName, this.state.informs));
	}

	onClose(){
		this.setState({
			editing_index: '', 
			editing_inform: {}, 
			mode: 'overview',
			open: false
		});
	}

	onCloseSelectForm(){
		this.setState({
			editing_index: '', 
			editing_inform: {}, 
			mode: 'overview'
		});
	}

	handleDelete(department_group_id, index){
		var { informs } = this.state;
		var department_group_ids = informs[index].department_group_ids;
		department_group_ids = _.pull(department_group_ids, department_group_id);

		informs[index].department_group_ids = department_group_ids;

		this.setState({
			informs: informs
		});
	}

	handleChange(informee_ids){
		const { informees } = this.props;
		if (_.isEmpty(informee_ids)) { return }

		let { informs } = this.state;

		_.forEach(informee_ids, function(informee_id){
			let informee = _.find(informees, {id: informee_id});
			if (_.isEmpty(informee)) { return } // next if informee is empty

			let department_group_ids = _.map(informee.department_groups, 'id');

			const new_inform = {
				id: '',
				informee_id: informee.id,
				position_name: informee.position_name,
				executors: informee.executors,
				department_group_ids: department_group_ids
			}

			informs = _.union(informs, [new_inform])
		});

		this.setState({
			informs: informs,
			editing_index: '', 
			editing_inform: {}, 
			mode: 'overview'
		});
	}

	handleUpdate(informee){
		if (this.state.editing_index === '' || this.state.editing_index === null || this.state.editing_index === undefined) { return }

		let department_group_ids = _.map(informee.department_groups, 'id');

		var { informs } = this.state;
		informs[this.state.editing_index].informee_id = informee.id;
		informs[this.state.editing_index].position_name = informee.position_name;
		informs[this.state.editing_index].executors = informee.executors;
		informs[this.state.editing_index].department_group_ids = department_group_ids;

		this.setState({
			informs: informs,
			editing_index: '', 
			editing_inform: {}, 
			mode: 'overview'
		});
	}

	handleChangeName(index, event){
		var { informs } = this.state;
		informs[index].position_name = event.target.value;

		this.setState({
			informs: informs,
			editing_index: '', 
			editing_inform: {}, 
			mode: 'overview'
		});
	}

	handleAddInform(){
		this.setState({mode: 'multiple_select_informs_mode'});
	}

	handleRemoveInform(index){
		if (!window.confirm("ยืนยันการลบ")) { return }

		var { informs } = this.state;
		informs.splice(index, 1);

		this.setState({
			informs: informs
		});
	}

	handleChangeShowOneLine(event){
		let isChecked = event.target.checked;
		this.props.handleChangeAttributeValue('show_one_line_informee', isChecked);
	}

	renderExecutor(executor, index){
		return (
			<div key={index}>{executor.name_with_positions}</div>
		)
	}

	renderRow(inform, index){
		if (_.isEmpty(inform.informee_id)) { return }

		return (
			<div key={index} className='card card-body form-group'>
				{_.size(this.state.informs) > 1 && (
					<i className="fa fa-times red-icon pointer fixed-top-right" onClick={this.handleRemoveInform.bind(this, index)}></i>
				)}
				<div className='row mb-2'>
					<div className='col-3'>เรียน</div>
					<div className='col-8'>
						<input 
							type='text' 
							name='position_name'
							value={inform.position_name || ''}
							className='form-control form-control-sm'
							onChange={this.handleChangeName.bind(this, index)}/>
					</div>
				</div>
				<div className='row'>
					<div className='col-2'>ผู้ดำเนินการ</div>
					<div className='col-1 text-right'>
						<img alt='ORG' 
							src={UserImage} 
							className='pointer mb-2' 
							onClick={this.onSelectUser.bind(this, inform, index)} />
					</div>
					<div className='col-9'>
						{!_.isEmpty(inform.executors) && (
							<div className='card p-2'>
								{!_.isEmpty(inform.department_group_ids) && _.map(inform.department_group_ids, (dg_id, index)=>{
									var dg_object = _.find(this.props.department_groups, {id: dg_id}) || {};
									
									return (
										<div key={index}><strong>{dg_object.attributes.name || ''}</strong></div>
									)
								})}
								<div className='green-border xs-small text-success card p-1'>
									{_.map(inform.executors, this.renderExecutor)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}

	onInformOpen(){
		let { mode, informs } = this.state;

		if (_.isEmpty(informs)) {
			mode = 'multiple_select_informs_mode';
		}

		this.setState({open: true, mode: mode});
	}

	onSendAllDepartmentGroups(){
		let { send_all_informs } = this.state;
		let informs = []

		if (send_all_informs){
			send_all_informs = false
		}else{
			send_all_informs = true

			_.map(this.props.informees, function(informee, index){
				let department_group_ids = _.map(informee.attributes.department_groups, 'id');
				informs.push({
					informee_id: informee.id,
					position_name: informee.attributes.position_name,
					executors: informee.attributes.executors,
					department_group_ids: department_group_ids
				})
			});
		}

		this.setState({
			informs: informs,
			editing_index: '', 
			editing_inform: {}, 
			mode: 'overview',
			send_all_informs: send_all_informs
		}, this.props.handleChange(EditingFieldName, informs));
	}

	render(){
		var title = 'เรียน';
		if (this.state.mode !== 'overview'){
			title = 'ผู้ดำเนินการ';
		}
		return (
			<div>
				<button 
					type='button' 
					className='btn btn-sm btn-primary mr-2 mb-2'
					onClick={this.onInformOpen}>
					ระบุเรียน
				</button>
				{this.props.send_all_department_groups_section && (
					<button 
						type='button' 
						className='btn btn-sm btn-primary mr-2 mb-2'
						onClick={this.onSendAllDepartmentGroups}>
						ส่งทุกหน่วยงาน
					</button>
				)}
				{this.props.show_one_line_section && (
					<label className='mb-0'>
						<input 
							type='checkbox' 
							className='mr-1' 
							name='show_one_line_informee'
							checked={this.props.show_one_line_informee.toString() === "true"}
							onChange={this.handleChangeShowOneLine} />
						<label className='ex-small-text mb-0'>แสดงผลบรรทัดเดียว</label>
					</label>
				)}

				<SimpleModal 
					isOpen={this.state.open}
					title={title}
					size={'modal-lg'}
					showSubmitButton={false}
					onClose={this.onClose}>

					{this.state.mode === 'select_inform_mode' && (
						<SelectInformee
							editing_inform={this.state.editing_inform}
							informees={this.props.informees}
							onChange={this.handleUpdate}
							onClose={this.onCloseSelectForm} />
					)}

					{this.state.mode === 'multiple_select_informs_mode' && (
						<SelectMultipleInformee
							editing_inform={this.state.editing_inform}
							informees={this.props.informees}
							inform_groups={this.props.inform_groups}
							onChange={this.handleChange}
							onClose={this.onCloseSelectForm} />
					)} 

					{this.state.mode === 'overview' && (
						<div>
							<button type='button' className='btn btn-sm btn-success mb-2' onClick={this.handleAddInform}>เพิ่ม</button>
							
							{!_.isEmpty(this.state.informs) && _.map(this.state.informs, this.renderRow)}

							<div className="modal-footer row">
								<button type='button' className="btn btn-primary pointer" onClick={this.handleSubmit}>ตกลง</button>
								<button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ยกเลิก</button>
							</div>
						</div>
					)}

				</SimpleModal>
			</div>
		)
	}
}

export class RenderInformees extends Component {
	constructor(props) {
		super(props)

		this.renderInform = this.renderInform.bind(this);
		this.renderExecutor = this.renderExecutor.bind(this);
	}

	renderInform(inform, index){
		return (
			<div key={index} className='small my-1'>
				<div>{inform.position_name}</div>
				<div className='card p-1'>
				{!_.isEmpty(inform.executors) && (
					<div>
						<div className='mb-1'>ดำเนินงานโดย</div>
						<ul className='pl-4 mb-0'>
							{_.map(inform.executors, this.renderExecutor)}
						</ul>
					</div>
				)}
				</div>
			</div>
		)
	}

	renderExecutor(executor, index){
		return (
			<li key={index}>
				{executor.name || ''}
			</li>
		)
	}

	render(){
		return (
			<div>
				{this.props.informs && _.map(this.props.informs, this.renderInform)}
			</div>
		)
	}
}

export class RenderInformeeTo extends Component {
	render(){
		var position_names = [];
		if (this.props.values){
			position_names = _.map(this.props.values, 'position_name');
		}
		position_names = _.uniq(position_names);
		return (
			<div className='row mt-2' style={{paddingTop: '6pt'}}>
				<div className={`${getColSize(this.props.label)[0]}`}>
					{this.props.label}
				</div>
				<div className={`${getColSize(this.props.label)[1]}`}>
					{this.props.show_one_line_informee.toString() === 'true' ? (
						<span style={{whiteSpace: 'nowrap'}}>
							{position_names && _.join(position_names, ', ')}
						</span>
					) : (
						<span>{position_names && _.join(position_names, ', ')}</span>
					)}
				</div>
			</div>
		)
	}
}

