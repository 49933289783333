import React, {Component} from 'react';
import './Loading.css';

export default class Loading extends Component {
	render(){
		return (
			<div>
				<div className="loading loading-color">Loading</div>
				{/*<div id="fountainG">
					<div id="fountainG_1" className="fountainG"></div>
					<div id="fountainG_2" className="fountainG"></div>
					<div id="fountainG_3" className="fountainG"></div>
					<div id="fountainG_4" className="fountainG"></div>
					<div id="fountainG_5" className="fountainG"></div>
					<div id="fountainG_6" className="fountainG"></div>
					<div id="fountainG_7" className="fountainG"></div>
					<div id="fountainG_8" className="fountainG"></div>
				</div>*/}

				{/*
					<div id='loading'>
						<div className='loader'></div>
						<div className="loader-icon"></div>
					</div>
				*/}
			</div>
		)
	}
}