import React, {Component} from 'react';
import _ from 'lodash';

import { IssueNumber, IssueTime} from './Elements';

import InlineEditAddress from './InlineEditAddress';

export class NumberAndAddress extends Component {
  render(){
    const { document_template, document_attributes, loading } = this.props;

    return (
      <div className='row mt-2'>
        {(!_.isEmpty(document_template.attributes.template_attributes.issue_number) && _.isEmpty(document_template.attributes.template_attributes.heading)) && (
          <div className='col-6 mr-auto'>
              <IssueNumber
                label={document_attributes.issue_number.title} 
                label_class={'text-md font-weight-bold'}
                suggest_text={document_attributes.issue_number.suggest_text}
                issue_number={document_attributes.issue_number.value}
                document_template={document_template} />
          </div>
        )}

        {!_.isEmpty(document_template.attributes.template_attributes.address) ? (
          <div className='col-md-6 ml-auto'>
            <div>
              <InlineEditAddress 
                suggest_text={document_attributes.address.suggest_text}
                address={document_attributes.address.value} 
                handleChange={this.props.handleChangeAttributeValue}
                loading={loading} />
            </div>
            {!_.isEmpty(document_template.attributes.template_attributes.issue_number_date) && (
              <div className='text-muted small'>
                วันที่เอกสารจะถูกระบุตอนออกเลข
              </div>
            )}
          </div>
        ) : (
          <div className='col-6 ml-auto'>
            {!_.isEmpty(document_template.attributes.template_attributes.issue_number_date) && (
              <IssueTime 
                document_template={document_template}
                label={document_attributes.issue_number_date.title} 
                label_class={'text-md font-weight-bold'} />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default NumberAndAddress;
