import React, { Component } from 'react';
import _ from 'lodash';

export default class PropertyForm extends Component {
	constructor(props){
		super(props)

		this.renderRow = this.renderRow.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event){
		var property_id = event.target.name;
		var value = event.target.value;
		var property_data = [{id: property_id, value: value}];

		var user = _.clone(this.props.user);
		var user_properties = user.user_properties;

		var property = _.find(user_properties, {id: property_id});
		if (!_.isEmpty(property)) {
			_.remove(user_properties, {id: property_id});
		}
		user_properties = _.concat(user_properties, property_data);

		user.user_properties = user_properties;
		this.props.onChangeData(user);
	}

	renderRow(property, index){
		var property_obj = _.find(this.props.user.user_properties, {id: property.id});
		var value = '';
		if (!_.isEmpty(property_obj)){
			value = property_obj.value;
		}
		return (
			<div key={index} className='row form-group'>
				<div className='col-12 mb-2'>
					{property.attributes.description}
				</div>
				<div className='col-12'>
					<input 
						type='text'
						name={property.id}
						value={value}
						className='form-control'
						onChange={this.handleChange} />
				</div>
			</div>
		)
	}

	render(){
		return (
			<div>
				{!_.isEmpty(this.props.user_properties) && _.map(this.props.user_properties, this.renderRow)}
				<div className="modal-footer row">
					<button type='submit' className="btn btn-primary pointer">Save changes</button>
					<button type='button' className="btn btn-secondary pointer" onClick={()=>this.props.onClose()}>Close</button>
				</div>
			</div>
		)
	}
}