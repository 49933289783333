import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import Pathname from './Pathname';
import Permission from './Permission';

import myDoc from '../assets/images/mydoc.png';
import operated from '../assets/images/operated.png';
import page_forward from '../assets/images/page_forward.png';
import page_back from '../assets/images/page_back.png';
import file_text from '../assets/images/file_text.png';
import page_find from '../assets/images/page_find.png';
import page_cancel from '../assets/images/page_cancel.png';

import WorkflowProcessStore from '../stores/WorkflowProcessStore';

class SendDocumentSidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      wait_for_receive_count: 0
    }
  }

  componentDidMount() {
    WorkflowProcessStore.request({url: 'wait_for_receive_count'}).then((resp)=>{
      this.setState({
        wait_for_receive_count: "(" + _.get(resp.data, 'data.wait_for_receive_count', []) + ")"
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }
  render(){
    const prefixPathname = Pathname.prefixPathname(this.props.location);
    const fullPathname = Pathname.fullPathname(this.props.location);
    return(
      <div className="documents-sidebar pl-4 pl-lg-0 sub-sidebar-menu">
        <ul className="nav flex-column nav-pills py-4 ml-0" id="v-pills-tab" role="tablist">
          {Permission.canBookNumber() && (
            <li className="nav-item">
              <Link to='/send_documents/send_all' className={`pl-3 nav-link ${(fullPathname === '/send_documents/send_all') && 'active'}`}>
                <img alt="sentDoc" src={page_forward} className="mr-2" />
                ทะเบียนหนังสือส่ง
              </Link>
            </li>
          )}
          {Permission.canBookNumber() && ( 
            <li className="nav-item">
              <Link to='/send_documents/send' className={`pl-3 nav-link ${(fullPathname === '/send_documents/send') && 'active'}`}>
                <img alt="Draft" src={file_text} className="mr-2" />
                ออกเลขส่ง
              </Link>
            </li>
          )}
          <li className="nav-item">
            <Link to='/send_documents/search_send_documents' className={`pl-3 nav-link ${(fullPathname === '/send_documents/search_send_documents') && 'active'}`}>
              <img alt="Search" src={page_find} className="mr-2" />
              ค้นหาทะเบียนหนังสือส่ง
            </Link>
          </li>
          {(Permission.isDepartmentCorrespondence() || Permission.isDepartmentSecretary()) && (
            <li className="nav-item">
              <Link to='/department_numbers/department_numbers?activemenu=send_documents' className={`pl-3 nav-link ${(prefixPathname === 'department_numbers') && 'active'}`}>
                <img alt="Search" src={page_forward} className="mr-2" />
                ออกเลขกรม
              </Link>
            </li>
          )}
          {(Permission.isUnitCorrespondence() || Permission.isDepartmentSecretary()) && (
            <li className="nav-item">
              <Link to='/unit_numbers/unit_numbers?activemenu=send_documents' className={`pl-3 nav-link ${(prefixPathname === 'unit_numbers') && 'active'}`}>
                <img alt="Search" src={page_forward} className="mr-2" />
                ออกเลขหน่วยงาน
              </Link>
            </li>
          )}
        </ul>
      </div>
    )
  }
}

export default SendDocumentSidebar;