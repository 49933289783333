import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { MergeAttributes } from '../FlatData';

import SimpleModal from '../SimpleModal';

const EditingFieldName = 'external_receiver_ids';

class MainContentSection extends Component {
  constructor(props){
    super(props)

    this.state = {
      open: false,
      external_receiver_ids: []
    }

    this.onSelect = this.onSelect.bind(this);
    this.positionFormat = this.positionFormat.bind(this);
    this.departmentFormat = this.departmentFormat.bind(this);
    this.renderExternalReceiver = this.renderExternalReceiver.bind(this);
  }

  positionFormat(cell, row){
    return (
      <div style={{whiteSpace: 'normal'}}>{row.position_name}</div>
    )
  }

  departmentFormat(cell, row){
    return (
      <div style={{whiteSpace: 'normal'}}>{row.department_name}</div>
    )
  }

  onSelect(row, isSelected, e){
    let { external_receiver_ids } = this.props;
    external_receiver_ids = _.union(external_receiver_ids, [row.id]);
    
    this.setState({
      open: false
    }, this.props.onUpdateExternalReceivers(EditingFieldName, external_receiver_ids));
  }

  removeExternalReceiver(external_receiver){
    if (!window.confirm(`ยืนยันการลบ ${external_receiver.full_name}`)) { return }

    let external_receiver_id = external_receiver.id;
    let { external_receiver_ids } = this.props;
    _.pull(external_receiver_ids, external_receiver_id);
    
    this.setState({
      open: false
    }, this.props.onUpdateExternalReceivers(EditingFieldName, external_receiver_ids));
  }

  renderExternalReceiver(external_receiver_id, index){
    const external_receiver = _.find(this.props.external_receivers, {id: external_receiver_id});
    if (_.isEmpty(external_receiver)) { return }
    return (
      <div key={index}>
        {external_receiver.attributes.full_name} ({external_receiver.attributes.position_name})&ensp;
        <i 
          className="fa fa-times ml-2 text-danger" 
          aria-hidden="true" 
          onClick={this.removeExternalReceiver.bind(this, external_receiver)}>
        </i>
      </div>
    )
  }

  render(){
    const options = {
      onRowClick: this.onSelect,
      sizePerPageList: [],
      sizePerPage: 10
    };
    var data = MergeAttributes(this.props.external_receivers);
    return(
      <div>
        <div className="mb-2 row">
          <label htmlFor="code" className="col-sm-2 col-form-label">Code</label>
          <div className="col-sm-10">
            <input type='text' name="code" value={this.props.code} onChange={this.props.onUpdateSiteSetting} className='form-control form-control-sm' />
          </div>
        </div>
        <div className="mb-2 row">
          <label htmlFor="center_code" className="col-sm-2 col-form-label">Center Code</label>
          <div className="col-sm-10">
            <input type='text' name="center_code" value={this.props.center_code} onChange={this.props.onUpdateSiteSetting} className='form-control form-control-sm' />
          </div>
        </div>
        <div className="mb-2 row">
          <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
          <div className="col-sm-10">
            <input type='text' name="name" value={this.props.name} onChange={this.props.onUpdateSiteSetting} className='form-control form-control-sm' />
          </div>
        </div>
        <div className="mb-2 row">
          <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
          <div className="col-sm-10">
            <input type='text' name="email" value={this.props.email} onChange={this.props.onUpdateSiteSetting} className='form-control form-control-sm' />
          </div>
        </div>
        <div className="mb-2 row">
          <label htmlFor="address" className="col-sm-2 col-form-label">ส่วนราชการ</label>
          <div className="col-sm-10">
            <textarea name="address" value={this.props.address} onChange={this.props.onUpdateSiteSetting} rows="4" className='form-control form-control-sm' />
          </div>
        </div>
        <div className="mb-2 row">
          <label htmlFor="address" className="col-sm-2 col-form-label">Sender email</label>
          <div className="col-sm-10">
            <input 
              type='text' 
              name="mail_sender" 
              value={this.props.mail_sender || ''} 
              onChange={this.props.onUpdateSiteSetting} 
              className='form-control form-control-sm' />
          </div>
        </div>
        <div className="mb-2 row">
          <label htmlFor="email" className="col-sm-2 col-form-label">
            ระบบจะตัด Logout เมื่อไม่มีการใช้งานในช่วงเวลา
          </label>
          <div className="col-sm-2">
            <input 
              type='number' 
              name="session_timeout_minutes" 
              value={this.props.session_timeout_minutes} 
              onChange={this.props.onUpdateSiteSetting} 
              className='form-control form-control-sm' />
          </div>
          <div className="col-sm-8">
            <label htmlFor="session_timeout_minutes" className="col-form-label">
              นาที
            </label>
          </div>
        </div>

        <div className="mb-2 row">
          <label htmlFor="external_receiver_id" className="col-sm-2 col-form-label">เจ้าหน้าที่ รับ/ส่ง เอกสารภายนอก</label>
          <div className="col-sm-10">
            {!_.isEmpty(this.props.external_receiver_ids) && _.map(this.props.external_receiver_ids, this.renderExternalReceiver)}
            <button 
              type='button' 
              className='btn btn-sm btn-success pointer'
              onClick={()=>this.setState({open: true})}>เลือก</button>
          </div>
        </div>

        <SimpleModal 
          isOpen={this.state.open}
          title={'เลือกเจ้าหน้าที่รับเอกสารภายนอก'}
          showSubmitButton={false}
          size={'modal-lg'}
          onClose={()=>this.setState({open: false})}>
          <BootstrapTable 
            data={data}
            search={ true }
            multiColumnSearch={ true }
            options={ options }
            striped
            hover
            condensed
            pagination
            version='4'
            tableContainerClass={'table-sm'}>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn 
                dataField='full_name' 
                dataSort={ true } 
                columnClassName='pointer'>
                ชื่อ-สกุล
              </TableHeaderColumn>
              <TableHeaderColumn 
                dataField='position_name' 
                dataSort={ true } 
                columnClassName='pointer' 
                dataFormat={this.positionFormat}>
                ตำแหน่ง
              </TableHeaderColumn>
              <TableHeaderColumn 
                dataField='department_name' 
                dataSort={ true } 
                columnClassName='pointer' 
                dataFormat={this.departmentFormat}>
                แผนก
              </TableHeaderColumn>
          </BootstrapTable>
        </SimpleModal>
      </div>
    )
  }
}

export default MainContentSection;
