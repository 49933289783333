import React, { Component } from 'react';
import _ from 'lodash';

import { GroupType, PositionType } from '../../Constants';

import RenderUsers from './RenderUsers';
import RenderPositions from './RenderPositions';
import RenderGroups from './RenderGroups';

import GroupStore from '../../../stores/GroupStore';
import PositionStore from '../../../stores/PositionStore';

const GroupTab = 'group';
const PositionTab = 'position';
const UserTab = 'user';

export default class ShowChildren extends Component {
	constructor(props){
		super(props)

		this.state = {
			activeMode: GroupTab,
			groups: [],
			positions: [],
			users: [],
			loading: false
		}

		this.getUsers = this.getUsers.bind(this);
		this.onReloadUsers = this.onReloadUsers.bind(this);
	}

	componentWillMount() {
		if (!_.isEmpty(this.props.focus.id)){
			if (this.props.focus.type === GroupType()) {
				GroupStore.request({id: this.props.focus.id}).then((resp)=>{
					let group = _.get(resp.data, 'data', {});

					this.setState({
						groups: group.attributes.children || [],
						positions: group.attributes.positions || [],
						loading: false
					}, this.getUsers('groups', this.props.focus.id));
				})
			}

			if (this.props.focus.type === PositionType()) {
				this.setState({groups: [], positions: []}, this.getUsers('positions', this.props.focus.id));
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (_.isEmpty(nextProps.focus.id)) {
			this.setState({
				groups: [],
				positions: [],
				users: [],
				activeMode: GroupTab
			})
		}else{
			// this.setState({loading: true});
			if (nextProps.focus.type === GroupType()) {
				GroupStore.request({id: nextProps.focus.id}).then((resp)=>{
					let group = _.get(resp.data, 'data', {});

					this.setState({
						groups: group.attributes.children || [],
						positions: group.attributes.positions || [],
						loading: false
					}, this.getUsers('groups', nextProps.focus.id));
				})
			}

			if (nextProps.focus.type === PositionType()) {
				this.setState({
					groups: [], 
					positions: [],
					activeMode: UserTab,
					loading: false
				}, this.getUsers('positions', nextProps.focus.id));
			}
		}
	}

	getUsers(type, id){
		if (type === GroupType()){
			GroupStore.request({url: `groups/${id}/get_users`}).then((resp)=>{
				this.setState({users: resp.data.data})
			})
		}else{
			PositionStore.request({url: `positions/${id}/get_users`}).then((resp)=>{
				this.setState({users: resp.data.data})
			})
		}
	}

	onReloadUsers(focus){
		if (focus.type === GroupType()){
			GroupStore.request({url: `groups/${focus.id}/get_users`}).then((resp)=>{
				this.setState({users: resp.data.data})
			})
		}else{
			PositionStore.request({url: `positions/${focus.id}/get_users`}).then((resp)=>{
				this.setState({users: resp.data.data})
			})
		}
	}

	handleChangeTab(tab){
		this.setState({
			activeMode: tab
		});
	}

	render(){
		var groupSize = 0;
		var positionSize = 0;
		var userSize = 0;

		if (!_.isEmpty(this.state.groups) && !_.isEmpty(this.state.groups)){
			groupSize = _.size(this.state.groups)
		}

		if (!_.isEmpty(this.state.positions) && !_.isEmpty(this.state.positions)){
			positionSize = _.size(this.state.positions)
		}

		if (!_.isEmpty(this.state.users)){
			userSize = _.size(this.state.users)
		}

		return (
			<div className="card">
				<div className="card-header text-center">
					{_.isEmpty(this.props.focus.id) ? '...' : this.props.focus.attributes.name}
				</div>
				<div className="p-2">
					<ul className="nav nav-tabs nav-fill ml-0">
						<li className="nav-item pointer">
							<span className={`nav-link ${this.state.activeMode === GroupTab && 'active'}`}
								onClick={this.handleChangeTab.bind(this, GroupTab)}>
								Group
								{groupSize > 0 && (
									<em>&nbsp;({groupSize})</em>
								)}
							</span>
						</li>
						<li className="nav-item pointer">
							<span className={`nav-link ${this.state.activeMode === PositionTab && 'active'}`} 
								onClick={this.handleChangeTab.bind(this, PositionTab)}>
								Position
								{positionSize > 0 && (
									<em>&nbsp;({positionSize})</em>
								)}
							</span>
						</li>
						<li className="nav-item pointer">
							<span className={`nav-link ${this.state.activeMode === UserTab && 'active'}`}
								onClick={this.handleChangeTab.bind(this, UserTab)}>
								User
								{userSize > 0 && (
									<em>&nbsp;({userSize})</em>
								)}
							</span>
						</li>
					</ul>
					<div className="tab-content pt-2 children-content">
						{this.state.loading && (
							<div className='loading-color'>loading...</div>
						)}

						<div className={`tab-pane fade ${this.state.activeMode === GroupTab && 'show active'}`}>
							{(!_.isEmpty(this.state.groups) && !this.state.loading) && (
								<RenderGroups 
									focus={this.props.focus}
									activeMode={this.state.activeMode}
									groups={this.state.groups}
									active_directories={this.props.active_directories}
									onCloseAndReloadData={this.props.onCloseAndReloadData} />
							)}
						</div>
						<div className={`tab-pane fade ${this.state.activeMode === PositionTab && 'show active'}`}>
							{(!_.isEmpty(this.state.positions) && !this.state.loading) && (
								<RenderPositions 
									focus={this.props.focus}
									activeMode={this.state.activeMode}
									positions={this.state.positions}
									onCloseAndReloadData={this.props.onCloseAndReloadData} />
							)}
						</div>
						<div className={`tab-pane fade ${this.state.activeMode === UserTab && 'show active'}`}>
							{(!_.isEmpty(this.state.users) && !this.state.loading) && (
								<RenderUsers 
									focus={this.props.focus}
									activeMode={this.state.activeMode}
									users={this.state.users} 
									onReloadUsers={this.onReloadUsers} />
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}