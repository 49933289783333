import React, { Component } from 'react';
import _ from 'lodash';
import '../documents/stylesheets/DocumentsPage.css';

import Permission from '../../components/Permission';

import Loading from '../../components/Loading';
import SimpleModal from '../../components/SimpleModal';
import { MergeAttributes } from '../../components/FlatData';

import PositionsUserTable from '../../components/PositionsUserTable';
import WorkflowMigration from '../../components/WorkflowProcessMigration/WorkflowMigration';
import WorkflowMigrationHistory from '../../components/WorkflowProcessMigration/WorkflowMigrationHistory';
import Confirmation from '../../components/WorkflowProcessMigration/Confirmation';
import UndoConfirmation from '../../components/WorkflowProcessMigration/UndoConfirmation';

import WorkflowMigrationStore from '../../stores/WorkflowMigrationStore';

class WorkflowMigrationPage extends Component {
  constructor(){
    super();

    this.state = {
      loading: false,
      current_tab: "nav-workflow-migration",
      isDataFetched: false,
      modalOpen: false,
      modalTitle: "",
      modalMode: "",
      workflowMigrations: [],
      movedWorkflowProcesses: [],
      positions_users: [],
      workflowProcesses: [],
      pagination: {},
      selectedPositionsUser: null,
      sourcePositionUser: null,
      targetPositionUser: null,
      selectedWorkflowProcessIds: [],
      movingWorkflowProcessIds: [],
      undoWorkflowMigrationIds: []
    }
    
    this.reloadData = this.reloadData.bind(this);
    this.isActive = this.isActive.bind(this);
    this.openTab = this.openTab.bind(this);
    this.openSourcePositionsUserList = this.openSourcePositionsUserList.bind(this);
    this.openTargetPositionsUserList = this.openTargetPositionsUserList.bind(this);
    this.onSelectSourcePositionsUser = this.onSelectSourcePositionsUser.bind(this);
    this.onSelectTargetPositionsUser = this.onSelectTargetPositionsUser.bind(this);
    this.loadWorkflowProcessData = this.loadWorkflowProcessData.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSelectWorkflowProcess = this.onSelectWorkflowProcess.bind(this);
    this.onMoveSingleWorkflowProcess = this.onMoveSingleWorkflowProcess.bind(this);
    this.onMoveMultipleWorkflowProcesses = this.onMoveMultipleWorkflowProcesses.bind(this);
    this.onMoveAllWorkflowProcesses = this.onMoveAllWorkflowProcesses.bind(this);
    this.openConfirmUndoMigrationModal = this.openConfirmUndoMigrationModal.bind(this);
    this.onSubmitMigration = this.onSubmitMigration.bind(this);
    this.onMoveSuccess = this.onMoveSuccess.bind(this);
    this.onSelectWorkflowMigration = this.onSelectWorkflowMigration.bind(this);
    this.onUndoSingleWorkflowMigration = this.onUndoSingleWorkflowMigration.bind(this);
    this.onUndoMultipleWorkflowMigrations = this.onUndoMultipleWorkflowMigrations.bind(this);
    this.onUndoAllWorkflowMigrations = this.onUndoAllWorkflowMigrations.bind(this);
    this.onSubmitUndoMigration = this.onSubmitUndoMigration.bind(this);
    this.onUndoSuccess = this.onUndoSuccess.bind(this);
  }

  componentWillMount(){
    if (!Permission.isAdmin()) {
      window.location.href = '/receive_documents/mydoc';
    }else{
      this.reloadData();
    }
  }

  isActive(tab_id){
    if(tab_id === this.state.current_tab){
      return "show active";
    } else {
      return "";
    }
  }

  openTab(tab_id){
    this.setState({ current_tab: tab_id });
  }

  reloadData(){
    this.setState({loading: true});

    WorkflowMigrationStore.request({}).then((resp)=>{
      let workflowMigrations = _.get(resp.data, 'data', []);
      let moved_workflow_processes = _.get(resp.data, 'included.moved_workflow_processes', []);
      let positions_users = _.get(resp.data, 'included.positions_users', []);

      this.setState({
        workflowMigrations: workflowMigrations,
        movedWorkflowProcesses: moved_workflow_processes,
        positions_users: positions_users
      })
    }).then(()=>{
      this.setState({isDataFetched: true, loading: false});
    })
  }

  loadWorkflowProcessData(positions_user_id){
    const url = `workflow_migrations/${positions_user_id}/workflow_process_list`;

    WorkflowMigrationStore.request({url: url}).then((resp)=>{
      let workflowProcesses = _.get(resp.data, 'data', []);

      this.setState({
        workflowProcesses: workflowProcesses
      })
    })
  }

  openSourcePositionsUserList(){
    this.setState({
      modalOpen: true,
      modalMode: "source_positions_user_list",
      modalTitle: "เลือกเจ้าหน้าที่ต้นทาง"
    });
  }

  openTargetPositionsUserList(){
    this.setState({
      modalOpen: true,
      modalMode: "target_positions_user_list",
      modalTitle: "เลือกเจ้าหน้าที่ปลายทาง"
    });
  }

  onSelectSourcePositionsUser(row, isSelected, e){
    const positionsUser = _.find(this.state.positions_users, { id: row.id })

    this.setState({
      modalOpen: false,
      modalMode: null,
      modalTitle: "",
      sourcePositionUser: positionsUser
    }, function(){
      this.loadWorkflowProcessData(positionsUser.id);
    });
  }

  onSelectTargetPositionsUser(row, isSelected, e){
    const positionsUser = _.find(this.state.positions_users, { id: row.id })

    this.setState({
      modalMode: "confirmation",
      modalTitle: "ยืนยันการย้ายภาระงาน",
      targetPositionUser: positionsUser
    });
  }

  onCloseModal(){
    this.setState({
      modalOpen: false,
      modalMode: null,
      modalTitle: null,
      workflow_process_id: null
    });
  }

  onSelectWorkflowProcess(row, isSelected, e) {
    let { selectedWorkflowProcessIds } = this.state;
    const isIncluded = _.includes(selectedWorkflowProcessIds, row.id);

    if (isIncluded){
      selectedWorkflowProcessIds = _.pull(selectedWorkflowProcessIds, row.id)
    }else{
      selectedWorkflowProcessIds = _.union(selectedWorkflowProcessIds, [row.id])
    }

    this.setState({ selectedWorkflowProcessIds: selectedWorkflowProcessIds });
  }

  onMoveSingleWorkflowProcess(workflow_process_id){
    this.setState({
      movingWorkflowProcessIds: [workflow_process_id]
    }, () => {
      this.openTargetPositionsUserList();
    });
  }

  onMoveMultipleWorkflowProcesses(){
    this.setState({
      movingWorkflowProcessIds: this.state.selectedWorkflowProcessIds
    }, () => {
      this.openTargetPositionsUserList();
    });
  }

  onMoveAllWorkflowProcesses(){
    const movingWorkflowProcessIds = _.map(this.state.workflowProcesses, 'id');

    this.setState({
      movingWorkflowProcessIds: movingWorkflowProcessIds
    }, () => {
      this.openTargetPositionsUserList();
    });
  }

  onSubmitMigration(){
    let data = new FormData();
    data.append('source_positions_user_id', this.state.sourcePositionUser.id || '')
    data.append('target_positions_user_id', this.state.targetPositionUser.id || '')
    _.forEach(this.state.movingWorkflowProcessIds, function(workflow_process_id){
      data.append('moving_workflow_process_ids[]', workflow_process_id);
    })

    WorkflowMigrationStore.save(null, data).then((response)=>{
      this.onMoveSuccess(response);
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    })
  }

  onMoveSuccess(response){
    const { sourcePositionUser } = this.state;

    this.setState({
      modalOpen: false,
      modalMode: null,
      modalTitle: "",
      sourcePositionUser: sourcePositionUser
    }, function(){
      this.reloadData();
      this.loadWorkflowProcessData(sourcePositionUser.id);
    });
  }

  onSelectWorkflowMigration(row, isSelected, e) {
    let { undoWorkflowMigrationIds } = this.state;
    const isIncluded = _.includes(undoWorkflowMigrationIds, row.id);

    if (isIncluded){
      undoWorkflowMigrationIds = _.pull(undoWorkflowMigrationIds, row.id)
    }else{
      undoWorkflowMigrationIds = _.union(undoWorkflowMigrationIds, [row.id])
    }

    this.setState({ undoWorkflowMigrationIds: undoWorkflowMigrationIds });
  }

  onUndoSingleWorkflowMigration(workflow_migration_id){
    this.setState({
      undoWorkflowMigrationIds: [workflow_migration_id]
    }, () => {
      this.openConfirmUndoMigrationModal();
    });
  }

  onUndoMultipleWorkflowMigrations(){
    this.setState({
      undoWorkflowMigrationIds: this.state.undoWorkflowMigrationIds
    }, () => {
      this.openConfirmUndoMigrationModal();
    });
  }

  onUndoAllWorkflowMigrations(){
    const undoWorkflowMigrations = _.filter(MergeAttributes(this.state.workflowMigrations), { can_undo: true });
    const undoWorkflowMigrationIds = _.map(undoWorkflowMigrations, "id");

    this.setState({
      undoWorkflowMigrationIds: undoWorkflowMigrationIds
    }, () => {
      this.openConfirmUndoMigrationModal();
    });
  }

  openConfirmUndoMigrationModal(){
    this.setState({
      modalOpen: true,
      modalMode: "undo_confirmation",
      modalTitle: "ยืนยันการดึงกลับภาระงาน"
    });
  }

  onSubmitUndoMigration(){
    let data = new FormData();
    _.forEach(this.state.undoWorkflowMigrationIds, function(workflow_migration_id){
      data.append('undo_workflow_migration_ids[]', workflow_migration_id);
    })

    WorkflowMigrationStore.request({url: 'workflow_migrations/undo', method: 'delete', data: data}).then((response)=>{
      this.onUndoSuccess();
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    })
  }

  onUndoSuccess(){
    this.setState({
      modalOpen: false,
      modalMode: null,
      modalTitle: "",
      undoWorkflowMigrationIds: [],
    }, function(){
      this.reloadData();
    });
  }

  render(){
    return (
      <div id="workflow-migration">
        {this.state.loading ? (
          <Loading />
        ) : (
          <div>
            <nav className="mb-3">
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className={`nav-item nav-link ${this.isActive("nav-workflow-migration")}`} id="nav-workflow-migration-tab" data-toggle="tab" href="#nav-workflow-migration" role="tab" aria-controls="nav-workflow-migration" aria-selected="true"
                    onClick={() => this.openTab("nav-workflow-migration")}>
                  ย้ายภาระงาน
                </a>
                <a className={`nav-item nav-link ${this.isActive("nav-workflow-migration-history")}`} id="nav-workflow-migration-history-tab" data-toggle="tab" href="#nav-workflow-migration-history" role="tab" aria-controls="nav-workflow-migration-history" aria-selected="false"
                    onClick={() => this.openTab("nav-workflow-migration-history")}>
                  ประวัติการย้ายภาระงาน
                </a>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className={`tab-pane fade ${this.isActive("nav-workflow-migration")}`} id="nav-workflow-migration" role="tabpanel" aria-labelledby="nav-workflow-migration-tab">
                <WorkflowMigration  openSourcePositionsUserList={this.openSourcePositionsUserList}
                                isDataFetched={this.state.isDataFetched}
                                sourcePositionUser={this.state.sourcePositionUser}
                                targetPositionUser={this.state.targetPositionUser}
                                workflowProcesses={this.state.workflowProcesses}
                                pagination={this.state.pagination}
                                onMoveSingleWorkflowProcess={this.onMoveSingleWorkflowProcess}
                                selectedWorkflowProcessIds={this.state.selectedWorkflowProcessIds}
                                onSelectWorkflowProcess={this.onSelectWorkflowProcess}
                                onMoveAllWorkflowProcesses={this.onMoveAllWorkflowProcesses}
                                onMoveMultipleWorkflowProcesses={this.onMoveMultipleWorkflowProcesses} />
              </div>
              <div className={`tab-pane fade ${this.isActive("nav-workflow-migration-history")}`} id="nav-workflow-migration-history" role="tabpanel" aria-labelledby="nav-workflow-migration-history-tab">
                <WorkflowMigrationHistory workflowMigrations={this.state.workflowMigrations}
                                          movedWorkflowProcesses={this.state.movedWorkflowProcesses}
                                          positions_users={this.state.positions_users}
                                          onSelectWorkflowMigration={this.onSelectWorkflowMigration}
                                          undoWorkflowMigrationIds={this.state.undoWorkflowMigrationIds}
                                          onUndoSingleWorkflowMigration={this.onUndoSingleWorkflowMigration}
                                          onUndoMultipleWorkflowMigrations={this.onUndoMultipleWorkflowMigrations}
                                          onUndoAllWorkflowMigrations={this.onUndoAllWorkflowMigrations} />
              </div>
            </div>
          </div>
        )}

        <SimpleModal 
          isOpen={this.state.modalOpen}
          title={this.state.modalTitle}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onCloseModal}>
            {this.state.modalMode === "source_positions_user_list" && (
              <PositionsUserTable positions_users={this.state.positions_users}
                                  onSelect={this.onSelectSourcePositionsUser}
                                  onClose={this.onCloseModal} />
            )}

            {this.state.modalMode === "target_positions_user_list" && (
              <PositionsUserTable positions_users={this.state.positions_users}
                                  onSelect={this.onSelectTargetPositionsUser}
                                  onClose={this.onCloseModal} />
            )}

            {this.state.modalMode === "confirmation" && (
              <Confirmation sourcePositionUser={this.state.sourcePositionUser} 
                            movingWorkflowProcessIds={this.state.movingWorkflowProcessIds}
                            targetPositionUser={this.state.targetPositionUser}
                            onSubmit={this.onSubmitMigration}
                            onClose={this.onCloseModal} />
            )}

            {this.state.modalMode === "undo_confirmation" && (
              <UndoConfirmation undoWorkflowMigrationIds={this.state.undoWorkflowMigrationIds}
                                onSubmit={this.onSubmitUndoMigration}
                                onClose={this.onCloseModal} />
            )}
        </SimpleModal>
      </div>
    )
  }
}

export default WorkflowMigrationPage;
