import React, {Component} from 'react';
import _ from 'lodash';

import GroupStore from '../../../stores/GroupStore';
import Loading from '../../Loading';
import { PositionType } from '../../Constants';

import RenderMainGroup from '../businesses/RenderMainGroup';

const FocusAttributes = {id: '', type: ''};

export default class SelectPositions extends Component {
	constructor(props){
		super(props)

		this.state = {
			loading: true,
			group: {},
			focus: FocusAttributes,
			filter: '',
			open: false
		}

		this.handleFocus = this.handleFocus.bind(this);
		this.onAddPositionMode = this.onAddPositionMode.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleAddPosition = this.handleAddPosition.bind(this);
	}

	componentWillMount() {
		var self = this;
		GroupStore.request({action: 'get_main_group'}).then((resp)=>{
			const group = _.get(resp.data, 'data', {});
			this.setState({
				group: group,
				loading: false
			})
		}, function(error){
			self.setState({loading: false})
		});
	}

	componentWillReceiveProps(nextProps){
		if (this.props.open !== nextProps.open){
			this.setState({open: false, focus: FocusAttributes});
		}
		
	}

	handleFocus(item){
		if (item.type !== PositionType()) { return }
		if (item !== this.state.focus) {
			this.setState({focus: item});
		}
	}

	onAddPositionMode(){
		this.setState({open: true})
	}

	handleAddPosition(){
		this.setState({open: false, focus: FocusAttributes}, this.props.onChangeData(this.state.focus));
	}

	handleDelete(position){
		this.props.onDelete(position);
	}

	render(){
		return (
			<div>
				{this.state.loading && <Loading />}

				{this.state.open && (
					<div>
						<ul className='ul-group-list' style={{maxHeight: '380px', overflowY: 'scroll'}}>
							<RenderMainGroup 
								focus={this.state.focus}
								group={this.state.group}
								filter={this.state.filter}
								onFocus={this.handleFocus} />
						</ul>
						<div className="modal-footer row">
							{_.isEmpty(this.state.focus.id) ? (
								<button 
									type='button' 
									className="btn btn-primary"
									disabled>Add Position</button>
							) : (
								<button 
									type='button' 
									className="btn btn-primary"
									onClick={this.handleAddPosition}>Add Position</button>
							)}
							<button 
								type='button' 
								className="btn btn-secondary" 
								onClick={()=>this.setState({open: false, focus: FocusAttributes})}>Close</button>
						</div>
					</div>
				)}

				{!this.state.open && (
					<div>
						<div className='row form-group'>
							<div className='col-12 text-right'>
								<button 
									type='button' 
									className='btn btn-success'
									onClick={this.onAddPositionMode}>+ Add</button>
							</div>
						</div>

						<div className='row'>
							<div className='col-12'>
								{!_.isEmpty(this.props.positions) && _.map(this.props.positions, (position)=>{
									return (
										<div key={position.id} className="alert alert-primary" role="alert">
										  	{position.attributes.name}
										  	<button type="button" className="close" onClick={this.handleDelete.bind(this, position)}>
										    	<span aria-hidden="true">&times;</span>
										  	</button>
										</div>
									)
								})}
							</div>
						</div>

						<div className="modal-footer row">
							<button type='submit' className="btn btn-primary pointer">Save changes</button>
							<button type='button' className="btn btn-secondary pointer" onClick={()=>this.props.onClose()}>Close</button>
						</div>
					</div>
				)}
			</div>
		)
	}
}