import React, {Component} from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { MergeAttributes } from '../../FlatData';

import UserStore from '../../../stores/UserStore';
import PositionStore from '../../../stores/PositionStore';
import Loading from '../../Loading';

export default class SelectUsers extends Component {
	constructor(props){
		super(props)

		this.state = {
			isDataFetched: false,
			users: [],
			selected: []
		}

		this.onRowSelect = this.onRowSelect.bind(this)
		this.handleSave = this.handleSave.bind(this)
		this.fullnameFormat = this.fullnameFormat.bind(this)
		this.positionFormat = this.positionFormat.bind(this)
	}

	componentWillMount() {
		UserStore.request({}).then((resp)=>{
			this.setState({
				users: resp.data.data
			})
		}).then(() => {
			if (!_.isEmpty(this.props.focus.id)){
				PositionStore.request({id: this.props.focus.id}).then((resp) => {
					var position = resp.data.data;
					var user_ids = _.map(position.attributes.users, 'id');
					this.setState({
						selected: user_ids,
						isDataFetched: true
					})
				}).then(()=>{
					this.setState({isDataFetched: true});
				});
			}
		})
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.focus.id !== nextProps.focus.id){
			PositionStore.request({id: this.props.focus.id}).then((resp) => {
				var position = _.get(resp.data, 'data', {});
				var user_ids = _.map(position.attributes.users, 'id');
				this.setState({
					selected: user_ids,
					isDataFetched: true
				})
			})
		}
	}

	onRowSelect(row, isSelected, e) {
		var selected = _.clone(this.state.selected)
		var isIncluded = _.includes(selected, row.id)

		if (isIncluded){
			selected = _.pull(selected, row.id)
		}else{
			selected = _.concat(selected, row.id)
		}
	  	this.setState({selected: selected})
	}

	handleSave(event){
		event.preventDefault();
		PositionStore.save(this.props.focus.id, {
			position: {
				user_ids: this.state.selected
			}
		}).then(() => {
			this.props.onCloseAndReloadData()
		})
	}

	positionFormat(cell, row){
		return (
			<div style={{whiteSpace: 'normal'}}>{row.position_names}</div>
		)
	}

	fullnameFormat(cell, row){
		return (
			<div style={{whiteSpace: 'normal'}}>{row.full_name}</div>
		)
	}

	_setTableOption(){ 
     	if(this.state.isDataFetched){
           	return "Not found";
      	}else{
            return <Loading />
      	}
	}

	render(){
		const selectRowProp = {
		  	mode: 'checkbox',
		  	clickToSelect: true,
  			onSelect: this.onRowSelect,
  			selected: this.state.selected
		};
		var data = MergeAttributes(this.state.users);
		const options = {
			noDataText: this._setTableOption(),
	      	sizePerPageList: [],
	      	sizePerPage: 10
	    };
		return (
			<div>
				<form onSubmit={this.handleSave}>
					<BootstrapTable 
						data={data}
						search={ true }
						multiColumnSearch={ true }
						selectRow={ selectRowProp }
						options={options}
						striped
						hover
						condensed
						pagination
						version='4'
						tableContainerClass={'table-sm'}>
							<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
						  	<TableHeaderColumn dataField='code' dataSort={ true } width='120px'>Code</TableHeaderColumn>
						  	<TableHeaderColumn dataField='full_name' dataSort={ true } dataFormat={this.fullnameFormat}>Fullname</TableHeaderColumn>
						  	<TableHeaderColumn dataField='position_names' dataSort={ true } dataFormat={this.positionFormat}>Position(s)</TableHeaderColumn>
					</BootstrapTable>
					<div className="modal-footer row">
						<button type='submit' className="btn btn-primary pointer px-3">บันทึก</button>
						<button type='button' className="btn btn-secondary pointer px-3" onClick={()=>this.props.onClose()}>ปิด</button>
					</div>
				</form>
			</div>
		)
	}
}