import React, {Component} from 'react';
import './AfterSaveLoading.css';

export default class AfterSaveLoading extends Component {
	render(){
		return (
			<div id='after-save-loading'>
				<div className='loader'></div>
				<div className="loader-icon"></div>
			</div>
		)
	}
}