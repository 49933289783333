import React, { Component } from 'react';
import './SettingUserPage.css'
import UserProperty from '../../components/manage_users/settings/UserProperty';
import AD from '../../components/manage_users/settings/AD';
import PasswordValidate from '../../components/manage_users/settings/PasswordValidate';
import DataMapping from '../../components/manage_users/settings/DataMapping';

const UserPropertyMode = 'user_property';
const PasswordValidateMode = 'password_validate';
const ADMode = 'ad';
const DataMappingMode = 'data_mapping';

class SettingUserPage extends Component {
	constructor(props){
		super(props)

		this.state = {
			active: UserPropertyMode
		}
	}

	handleChangeMode(mode){
		this.setState({active: mode})
	}

  	render(){
		return (
			<div id="SettingUserPage" className="container-fluid">
				<div className="row">
				    <div className="col-md-12">

				      <div className="tabbable tabs-left row">
				      	<div className='col-md-2'>
					        <ul className="nav nav-tabs w-100">
					          <li className={`my-3 pointer ${this.state.active === UserPropertyMode && 'active'}`} onClick={this.handleChangeMode.bind(this, UserPropertyMode)}>
					          	<span>User Property</span>
					          </li>
					          <li className={`my-3 pointer ${this.state.active === PasswordValidateMode && 'active'}`} onClick={this.handleChangeMode.bind(this, PasswordValidateMode)}>
					          	<span>Password Validate</span>
					          </li>
					          <li className={`my-3 pointer ${this.state.active === ADMode && 'active'}`} onClick={this.handleChangeMode.bind(this, ADMode)}>
					          	<span>AD</span>
					          </li>
					          <li className={`my-3 pointer ${this.state.active === DataMappingMode && 'active'}`}  onClick={this.handleChangeMode.bind(this, DataMappingMode)}>
					          	<span>Data Mapping</span>
					          </li>
					        </ul>
					    </div>
				        <div className="tab-content py-3 col-md-10">
				         	<div className={`tab-pane ${this.state.active === UserPropertyMode && 'active'}`}>
				         		<UserProperty />
				         	</div>
				         	<div className={`tab-pane ${this.state.active === PasswordValidateMode && 'active'}`}>
				         		<PasswordValidate />
				         	</div>
				         	<div className={`tab-pane ${this.state.active === ADMode && 'active'}`}>
				         		<AD />
				         	</div>
				         	<div className={`tab-pane ${this.state.active === DataMappingMode && 'active'}`}>
				         		<DataMapping />
				         	</div>
				        </div>
				      </div>
				      
				    </div>   
				</div>
			</div>
		)
	}
}

export default SettingUserPage;