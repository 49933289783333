import React, {Component} from 'react';
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalClose,
  ModalBody,
  ModalFooter
} from 'react-modal-bootstrap';

export default class SimpleModal extends Component {
  constructor(props){
    super(props)

    this.hideModal = this.hideModal.bind(this);
  }

  hideModal(){
    this.props.onClose();
  }

	render(){
		return (
      <Modal isOpen={this.props.isOpen} onRequestHide={this.hideModal} size={this.props.size || 'modal-sm'}>
        <ModalHeader>
          <ModalTitle>{this.props.title || ''}</ModalTitle>
          <ModalClose onClick={this.hideModal}/>
        </ModalHeader>
        <ModalBody>
          {this.props.children}
        </ModalBody>
        {this.props.showSubmitButton && (
          <ModalFooter>
            <button className='btn btn-primary pointer' onClick={this.props.onSubmit}>
              บันทึก
            </button>
            <button className='btn btn-secondary pointer' onClick={this.hideModal}>
              ยกเลิก
            </button>
          </ModalFooter>
        )}
      </Modal>
		)
	}
}