import React, { Component } from 'react';

import Main from './Main';
import TopOrganizeHeader from './TopOrganizeHeader';

import Permission from '../components/Permission';
export default class ManageUsersLayout extends Component {

  componentWillMount(){
    if (!Permission.canEditUsers()) {
      window.location.href = '/receive_documents/mydoc';
    }
  }

	render() {
	return (
		<div>
			<TopOrganizeHeader location={this.props.location} siteSetting={this.props.siteSetting} />
			<div className='container-fluid' style={{position: 'absolute', marginTop: '50px'}}>
				<div className="row pt-3">
					<div className="col-md-12 bg-white py-3 px-4">
						<Main />
					</div>
				</div>
			</div>
		</div>
		)
	}
}
