import React, { Component } from 'react';
import _ from 'lodash';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { MergeAttributes } from '../components/FlatData';
import { documentNumberFormatter, rowClassNameFormat } from '../utils/bootstrapTableFormatters';
import './StoragesPage.css';

import SimpleModal from '../components/SimpleModal';
import WorkflowProcessHistory from '../components/WorkflowProcessHistory';

import Loading from '../components/Loading';
import StorageStore from '../stores/StorageStore';

class StoragesPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      isDataFetched: false,
      workflow_processes: [],
      open: false,
      workflow_process_id: null,
      pagination: {},
      searchText: ''
    }

    this.redirectToShowDocumentPage = this.redirectToShowDocumentPage.bind(this);
    this.stateFormatter = this.stateFormatter.bind(this);
    this.onClose = this.onClose.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    StorageStore.request({}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  redirectToShowDocumentPage(row, isSelected, e){
    this.props.history.push(`/templates/documents/${row.attributes.document.id}/show/${row.attributes.document_template.id}/wp/${row.id}`);
  }

  _setTableOption(){ 
    if(this.state.isDataFetched){
      return "ไม่มีข้อมูล";
    }else{
      return <Loading />
    }
  }

  stateFormatter(cell, row) {
    return (
      <a href="#" onClick={this.openModal.bind(this, row.id)}>
        {cell}
      </a>
    )
  }

  openModal(workflow_process_id, event){
    event.stopPropagation();

    this.setState({
      open: true,
      workflow_process_id: workflow_process_id,
    });
  }

  onClose(){
    this.setState({
      open: false,
      workflow_process_id: null
    });
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, workflow_processes: []});
    const { searchText } = this.state;

    StorageStore.request({url: `storages?page=${page}&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, workflow_processes: []});

    StorageStore.request({url: `storages?page=1&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  render(){
    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);
    const options = {
      sizePerPageList: [],
      sizePerPage: 20,
      page: active_page,
      noDataText: this._setTableOption(),
      onRowClick: this.redirectToShowDocumentPage,
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500
    };

    var data = [];
    if (!_.isEmpty(this.state.workflow_processes)){
      data = MergeAttributes(this.state.workflow_processes);
    }

    return (
      <div className='row my-3'>
        <div className='col-md-12'>
          <h2>ระบบแชร์ไฟล์เอกสาร</h2>

          <div className='row'>
            <div className='col-md-12 rui-storages'>
              <BootstrapTable 
                data={data}
                remote={ true }
                fetchInfo={{dataTotalSize: total_items_count}}
                search={ true }
                multiColumnSearch={ true }
                options={ options }
                striped
                hover
                pagination
                version='4'
                tableContainerClass={'table-sm'}
                trClassName={rowClassNameFormat}>
                <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
                <TableHeaderColumn width="150" dataFormat={documentNumberFormatter}>เลขที่หนังสือ</TableHeaderColumn>
                <TableHeaderColumn dataField='topic' columnTitle dataSort={ true }>เรื่อง</TableHeaderColumn>
                <TableHeaderColumn dataField='from_department' columnTitle dataSort={ true }>จาก</TableHeaderColumn>
                <TableHeaderColumn dataField='to_department' columnTitle dataSort={ true }>เรียน</TableHeaderColumn>
                <TableHeaderColumn dataField='current_state_name' dataSort={ true } dataFormat={this.stateFormatter}>สถานะ</TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>

        </div>

        <SimpleModal 
          isOpen={this.state.open}
          title="ประวัติการดำเนินการ การปฏิบัติงาน"
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onClose}>
            <WorkflowProcessHistory id={this.state.workflow_process_id} />

            <div className="modal-footer row">
              <button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ปิด</button>
            </div>
        </SimpleModal>
      </div>
    );
  }
}

export default StoragesPage;