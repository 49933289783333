import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import SimpleModal from '../../components/SimpleModal';
import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';
import AfterSaveLoading from '../../components/AfterSaveLoading';

import WorkflowStore from '../../stores/WorkflowStore';

import NewWorkflowForm from '../../components/Workflow/NewWorkflowForm';

class WorkflowsSettingPage extends Component {
  constructor(){
    super();

    this.state = {
      loading: false,
      isDataFetched: false,
      workflows: [],
      edittingWorkflow: {},
    }

    this.renderRowActions = this.renderRowActions.bind(this);
    this.editRow = this.editRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.openNewWorkflowForm = this.openNewWorkflowForm.bind(this);
    this.handleCreateNewWorkflow = this.handleCreateNewWorkflow.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleCloseAndReloadData = this.handleCloseAndReloadData.bind(this);
  }

  componentWillMount(){
    this.reloadData();
  }

  reloadData(){
    WorkflowStore.request({}).then((resp)=>{
      let workflows = _.get(resp.data, 'data', []);

      this.setState({
        workflows: workflows
      })
    }).then(()=>{
      this.setState({isDataFetched: true, loading: false});
    })
  }

  handleCloseAndReloadData(){
    this.setState(
      {
        loading: true,
        modalOpen: false,
        modalTitle: "",
      }, 
      this.reloadData()
    );
  }


  handleCreateNewWorkflow(workflowData){
    var self = this;

    WorkflowStore.save(null, {
      workflow: workflowData
    }).then(()=>{
      this.handleCloseAndReloadData();
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      self.setState({error_message: error_message});
    })
  }

  renderRowActions(cell, row){
    return (
      <div>
        <button className="btn btn-success" onClick={() => this.editRow(row)}>
          <i className="fa fa-cog"></i>{' '}Edit
        </button>
        {' '}
        <button className="btn btn-danger" onClick={() => this.deleteRow(row)}>
          <i className="fa fa-trash"></i>{' '}Delete
        </button>
      </div>
    )
  }

  openNewWorkflowForm(){
    this.setState({
      modalOpen: true,
      modalTitle: "Create new workflow",
    });
  }

  editRow(row, isSelected, e){
    this.props.history.push(`/settings/workflows/${row.id}/edit`);
  }

  deleteRow(row){
    if(window.confirm("Are you sure?")){
      let self = this;

      WorkflowStore.delete(row.id).then(()=>{
        this.handleCloseAndReloadData()
      }, function(error){
        let error_message = _.get(error.response.data, 'error.message', '');
        self.setState({error_message: error_message});
      });
    }
  }

  _setTableOption(){ 
    if(this.state.isDataFetched){
      return "Not found";
    }else{
      return <Loading />
    }
  }

  onClose(){
    this.setState({
      modalOpen: false,
      modalTitle: "",
    });
  }

  render(){
    const options = {
      sizePerPageList: [],
      noDataText: this._setTableOption()
    };
    let data = MergeAttributes(this.state.workflows);

    return (
      <div id="workflows-setting" className="container">
        {this.state.loading && <AfterSaveLoading />}

        <div className="row">
          <div className="col-6">
            <h2>Workflows</h2>
          </div>
          <div className="col-6 text-right">
            <button className='btn btn-success' onClick={() => this.openNewWorkflowForm()}>
              <span>Add</span>
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <BootstrapTable 
              data={data}
              search={ true }
              multiColumnSearch={ true }
              options={ options }
              striped
              hover
              pagination
              version='4'
              tableContainerClass={'table-sm'}>
              <TableHeaderColumn  isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn  dataField='name' dataSort={ true } width="300">Name</TableHeaderColumn>
              <TableHeaderColumn  dataField='description' dataSort={ true }>Description</TableHeaderColumn>
              <TableHeaderColumn  dataField='id'
                                  width='200' 
                                  dataFormat={this.renderRowActions}>
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>

        <SimpleModal 
          isOpen={this.state.modalOpen}
          title={this.state.modalTitle}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onClose}>
            <NewWorkflowForm  onClose={this.onClose} 
                              onSubmit={this.handleCreateNewWorkflow} />
        </SimpleModal>
      </div>
    )
  }
}

export default withRouter(WorkflowsSettingPage);
