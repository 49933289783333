import React, { Component } from 'react';
import _ from 'lodash';

export default class Images extends Component {
	constructor(props){
		super(props)

		this.state = {
			profilePreviewUrl: '',
	      	signaturePreviewUrl: ''
	    }

		this.handleChangeProfile = this.handleChangeProfile.bind(this);
		this.handleChangeSignature = this.handleChangeSignature.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.open !== nextProps.open){
			this.setState({profilePreviewUrl: '', signaturePreviewUrl: ''});
		}
	}

	handleChangeProfile(){
		var reader = new FileReader();
		var image_value = this.refs.profile_image.files[0];
    	if (image_value.size === 0) { return }

    	reader.onloadend = () => {
		    this.setState({
		        profilePreviewUrl: reader.result
		    });
		}

		reader.readAsDataURL(image_value);

    	var { user } = this.props;
    	user.profile_image = image_value;

    	this.props.onChangeData(user);
    	this.refs.profile_image.value = ''; // clear value then can select the same picture
	}

	handleChangeSignature(){
		var reader = new FileReader();
		var image_value = this.refs.signature.files[0];
    	if (image_value.size === 0) { return }

    	reader.onloadend = () => {
		    this.setState({
		        signaturePreviewUrl: reader.result
		    });
		}

		reader.readAsDataURL(image_value);

    	var { user } = this.props;
    	user.signature = image_value;

    	this.props.onChangeData(user);
    	this.refs.signature.value = ''; // clear value then can select the same picture
	}

	render(){
		let {signaturePreviewUrl} = this.state;
	    let $signaturePreview = null;
	    if (signaturePreviewUrl) {
	      $signaturePreview = (<img alt='Signature' src={signaturePreviewUrl} style={{maxWidth: '100%'}} />);
	    }

		let {profilePreviewUrl} = this.state;
	    let $profilePreview = null;
	    if (profilePreviewUrl) {
	      $profilePreview = (<img alt='Profile' src={profilePreviewUrl} style={{maxWidth: '100%'}} />);
	    }

		var profile_image = null;
		if (!_.isEmpty(this.props.user_data) && !_.isEmpty(this.props.user_data.attributes.profile_image)){
			profile_image = this.props.user_data.attributes.profile_image;
		}
		var signature = null;
		if (!_.isEmpty(this.props.user_data) && !_.isEmpty(this.props.user_data.attributes.signature)){
			signature = this.props.user_data.attributes.signature;
		}
		return (
			<div>
				<div className='row form-group'>
					<div className='col-6'>
						<div className='row form-group'>
							<div className='col-12 text-center'>
								<label className="btn btn-sm btn-success pointer mb-0 mr-2">
									<i className="fa fa-upload mr-1" aria-hidden="true"></i>
								    Profile select... 
								    <input 
								    	type="file"
								    	ref='profile_image' 
								    	hidden
								    	onChange={this.handleChangeProfile} />
								</label>
								<button type='button' className='btn btn-sm btn-dark pointer'>
									<i className="fa fa-trash-o mr-1" aria-hidden="true"></i>
									Reset
								</button>
							</div>
							{$profilePreview && (
								<div className='col-12 text-center mt-3'>
									{$profilePreview}
								</div>
							)}
							{(profile_image && !$profilePreview) && (
								<div className='col-12 text-center mt-3'>
									<img alt='Signature' src={profile_image} style={{maxWidth: '100%'}} />
								</div>
							)}
						</div>
					</div>
					<div className='col-6'>
						<div className='row form-group'>
							<div className='col-12 text-center'>
								<label className="btn btn-sm btn-success pointer mb-0 mr-2">
									<i className="fa fa-upload mr-1" aria-hidden="true"></i>
								    Signature select... 
								    <input 
								    	type="file" 
								    	ref='signature'
								    	hidden
								    	onChange={this.handleChangeSignature} />
								</label>
								<button type='button' className='btn btn-sm btn-dark pointer'>
									<i className="fa fa-trash-o mr-1" aria-hidden="true"></i>
									Reset
								</button>
							</div> 
							{$signaturePreview && (
								<div className='col-12 text-center mt-3'>
									{$signaturePreview}
								</div>
							)}
							{(signature && !$signaturePreview) && (
								<div className='col-12 text-center mt-3'>
									<img alt='Signature' src={signature} style={{maxWidth: '100%'}} />
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="modal-footer row">
					<button type='submit' className="btn btn-primary pointer">Save changes</button>
					<button type='button' className="btn btn-secondary pointer" onClick={()=>this.props.onClose()}>Close</button>
				</div>
			</div>
		)
	}
}