import React, { Component } from 'react';
import _ from 'lodash';

import Permission from '../Permission';

import DepartmentList from '../DepartmentList';
import OriginalDocumentFiles from '../documents/OriginalDocumentFiles';

const note_header_list = [
  "ทราบ",
  "ดำเนินการ",
  "ลงนามแล้ว",
  "อนุมัติ",
  "อนุมัติ/ลงนามแล้ว",
  "อนุญาต/ดำเนินการ",
  "แจ้งเวียนทุกฝ่าย",
  "เห็นชอบ",
  "มอบ",
  "งานติดตามฯ",
]

class Order extends Component {
  constructor(props){
    super(props);

    const latest_note_text = props.latest_note_text;
    let note_text_array = [];
    let note_headers = [];
    let note_text = "";

    if (!_.isEmpty(latest_note_text)){
      note_text_array = latest_note_text.split('\n');
      note_headers = _.first(note_text_array).split(' - ');
      note_text = _.last(note_text_array);
    }

    this.state = {
      note_headers: note_headers,
      note_text: note_text,
      selected_group_ids: [],
      order_type: null,
      external_department: ''
    }

    this.setNoteText = this.setNoteText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderNoteHeader = this.renderNoteHeader.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.batchSelectGroups = this.batchSelectGroups.bind(this);
    this.batchRemoveGroups = this.batchRemoveGroups.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.removeGroup = this.removeGroup.bind(this);
    this.setOrderType = this.setOrderType.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  onKeyPress(e){
    if(e.which === 13) {
      this.handleSubmit();
    }
  }

  setNoteHeader(note_header){
    let { note_headers } = this.state;

    if (_.includes(note_headers, note_header)){
      note_headers = _.pull(note_headers, note_header);
    }else{
      note_headers = _.union(note_headers, [note_header]);
    }

    this.setState({note_headers: note_headers});
  }

  setNoteText(event){
    const note_text = event.target.value;
    this.setState({note_text: note_text});
  }

  batchSelectGroups(department_id){
    const department = _.find(this.props.all_groups, { id: department_id });
    const group_ids = _.map(department.attributes.children, 'id');
    const selected_group_ids = _.concat(this.state.selected_group_ids, group_ids);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  batchRemoveGroups(department_id){
    const department = _.find(this.props.all_groups, { id: department_id });
    const group_ids = _.map(department.attributes.children, 'id');
    const selected_group_ids = _.pullAll(this.state.selected_group_ids, group_ids);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  selectGroup(group_id){
    const selected_group_ids = _.concat(this.state.selected_group_ids, group_id);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  removeGroup(group_id){
    const selected_group_ids = _.pull(this.state.selected_group_ids, group_id);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  handleSubmit(){
    const self = this;

    if (_.isEmpty(this.state.selected_group_ids) && this.state.order_type === null && this.state.external_department === null) {
      alert('กรุณาระบุหน่วยงานปลายทาง');
      return
    }

    const note_text = this.state.note_headers.join(' - ') + "\n" + this.state.note_text;

    const positions_users = _.filter(this.props.internal_users, function(internal_user){
      if (internal_user.attributes.position_name == "สารบรรณกรม" && internal_user.attributes.department_name == "สำนักงานเลขานุการกรม")
        return false;
      
      return _.includes(self.state.selected_group_ids, internal_user.attributes.group_id)
    })

    const positions_user_ids = _.map(positions_users, 'id');

    this.props.onSubmitAction(note_text, positions_user_ids, this.state.order_type, this.state.external_department);
  }

  setOrderType(type_name, event){
    if(event.target.checked){
      this.setState({order_type: type_name});
    } else {
      this.setState({order_type: null});
    }
  }

  renderNoteHeader(note_header, index){
    const active_note_headers = this.state.note_headers;
    const is_active = _.includes(active_note_headers, note_header);
    return (
      <button 
        key={index} 
        type='button' 
        className={`btn btn-sm btn-secondary mr-2 mt-2 ${is_active && 'active'}`}
        onClick={this.setNoteHeader.bind(this, note_header)}>
        {is_active ? (
          <span>
            <span className='mr-2'>x</span>{note_header}
          </span>
        ) : (
          <span>{`+ ${note_header}`}</span>
        )}
      </button>
    )
  }

  renderRow(document_version, index){
    return (
      <tr key={index}>
        <td style={{width: '22%'}}>{document_version.attributes.updated_at}</td>
        <td style={{width: '30%'}}>{document_version.attributes.updated_by.department_names}</td>
        <td>
          <a href={document_version.attributes.url} target="_blank" className='text-primary'>
            {document_version.attributes.pdf_file_file_name}
          </a>
        </td>
      </tr>
    )
  }

  handleChange(event){
    const field_name = event.target.name;
    const value = event.target.value;

    this.setState({
      [field_name]: value
    })
  }

  render(){
    const { note_headers } = this.state;
    const { document_versions, original_document_files, workflow_process } = this.props;
    let inform_names = _.map(this.props.document.attributes.informs, 'name');
    inform_names = _.uniq(inform_names).join(', ')

    return(
      <div id='workflow-actions-order'>
        <div className='alert alert-secondary'>
          <div className="row mb-2">
            <div className="col-2"><strong>ที่</strong></div>
            <div className="col-10">{workflow_process.attributes.issue_number || workflow_process.attributes.prev_issue_number}</div>
          </div>
          <div className="row mb-2">
            <div className="col-2"><strong>ลงวันที่</strong></div>
            <div className="col-10">{this.props.document.attributes.document_date_thai_format}</div>
          </div>
          <div className="row mb-2">
            <div className="col-2"><strong>เรื่อง</strong></div>
            <div className="col-10">{this.props.document.attributes.topic}</div>
          </div>
          <div className="row mb-2">
            <div className="col-2"><strong>เรียน</strong></div>
            <div className="col-10">{inform_names}</div>
          </div>
          <div className="row">
            <div className="col-2"><strong>คำสั่งการ</strong></div>
            <div className="col-10">
              <div className='mb-2'>
                <input className="form-control form-control-sm" value={note_headers.join(' - ')} />
              </div>
              <div>
                <textarea cols="30" rows="8" className="w-100" value={this.state.note_text} onChange={this.setNoteText}></textarea>
              </div>
            </div>
          </div>

          <div className="form-row align-items-center">
            <div className="col-2"></div>
            <div className="col-10">
              {_.map(note_header_list, this.renderNoteHeader)}

              <div className='text-danger mt-3'>
                * คลิกซ้ำที่คำสั่งการหากต้องการลบ
              </div>
            </div>
          </div>

          {(Permission.isSecretary() || Permission.isBoard()) && (
            <div className="row mt-2">
              <div className="col-2">ประเภทคำสั่งการ</div>
              <div className="col-10">
                <div className="form-check">
                  <input  className="form-check-input" 
                          type="checkbox" 
                          value="issue_department_number" 
                          checked={this.state.order_type === 'issue_department_number'} 
                          id="issue_department_number"
                          onChange={(event) => this.setOrderType('issue_department_number', event)}
                          onKeyPress={this.onKeyPress} />
                  <label className="form-check-label" htmlFor="issue_department_number">
                    ออกเลขกรม
                  </label>
                </div>

                {!Permission.isBoard() && (
                  <div className="form-check">
                    <input  className="form-check-input" 
                            type="checkbox" 
                            value="issue_unit_number" 
                            checked={this.state.order_type === 'issue_unit_number'} 
                            id="issue_unit_number"
                            onChange={(event) => this.setOrderType('issue_unit_number', event)}
                            onKeyPress={this.onKeyPress} />
                    <label className="form-check-label" htmlFor="issue_unit_number">
                      ออกเลขหน่วยงาน
                    </label>
                  </div>
                )}
                
                {/* เอาส่งคืนสารบรรณ ออก
                <div className="form-check">
                    <input  className="form-check-input" 
                            type="checkbox" 
                            value="return_to_central_correspondence" 
                            checked={this.state.order_type === 'return_to_central_correspondence'} 
                            id="return_to_central_correspondence"
                            onChange={(event) => this.setOrderType('return_to_central_correspondence', event)}
                            onKeyPress={this.onKeyPress} />
                    <label className="form-check-label" htmlFor="return_to_central_correspondence">
                      ส่งคืนสารบรรณ
                    </label>
                </div>
                */}

                <div className="form-check">
                  <input  className="form-check-input" 
                          type="checkbox" 
                          value="return_to_document_owner" 
                          checked={this.state.order_type === 'return_to_document_owner'} 
                          id="return_to_document_owner"
                          onChange={(event) => this.setOrderType('return_to_document_owner', event)}
                          onKeyPress={this.onKeyPress} />
                  <label className="form-check-label" htmlFor="return_to_document_owner">
                    ส่งคืนเจ้าของหนังสือ
                  </label>
                </div>
              </div>
            </div>
          )}
            
          {(Permission.isSecretary() || Permission.isBoard()) && (
            <div className="row mt-2">
              <div className="col-2"></div>
              <div className="col-10">
                <button className="btn btn-danger px-5" onClick={() => this.props.onActionClick("ปิดงาน")}>ยุติ</button>
              </div>
            </div>
          )}
        </div>

        <div className='alert alert-primary'>
          <div className="row">
            <div className="col-12">
              <table className='table table-sm table-bordered table-striped'>
                <thead className='thead-dark'>
                  <tr>
                    <th>วันที่</th>
                    <th>หน่วยงาน</th>
                    <th>ชื่อไฟล์</th>
                  </tr>
                </thead>
                <tbody>
                  {!_.isEmpty(document_versions) && _.map(document_versions, this.renderRow)}
                </tbody>
              </table>
            </div>
            <div className='col-12'>
              <OriginalDocumentFiles
                original_document_files={original_document_files}
                handleChangeOriginalDoc={this.props.handleChangeOriginalDoc}
                onRemoveOriginalDocument={this.props.onRemoveOriginalDocument}
                handleInsert={this.props.handleInsert}
                handleReplace={this.props.handleReplace}
                onSortEnd={this.props.onSortEnd} />
            </div>
          </div>
        </div>
        
        <div className='alert alert-success'>
          <div className="row mt-2">
            <div className="col-12">
              <DepartmentList 
                all_groups={this.props.all_groups}
                root_group={this.props.root_group}
                selected_group_ids={this.state.selected_group_ids}
                external_department={this.state.external_department}
                batchSelectGroups={this.batchSelectGroups}
                batchRemoveGroups={this.batchRemoveGroups}
                selectGroup={this.selectGroup}
                handleChange={this.handleChange}
                removeGroup={this.removeGroup}
                onKeyPress={this.onKeyPress} />
            </div>
          </div>
        </div>

        <div className="modal-footer row mt-2">
          <button type='button' className="btn btn-primary pointer" disabled={this.props.clicked} onClick={this.handleSubmit}>ส่งหนังสือ</button>
          <button type='button' className="btn btn-secondary pointer" onClick={this.props.onCloseModal}>ยกเลิก</button>
        </div>
      </div>
    )
  }
}

export default Order;