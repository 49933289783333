import React, {Component} from 'react';
import _ from 'lodash';

import SimpleModal from '../../SimpleModal';

const EditingFieldName = 'hidden_file';

export class UploadFiles extends Component {
	constructor(props){
		super(props)

		this.state = {
			open: false,
			files: [
				{
					file: '',
					file_name: '',
					preview_url: ''
				}
			]
		}

		this.handleChange = this.handleChange.bind(this);
		this.onClose = this.onClose.bind(this);
		this.renderFile = this.renderFile.bind(this);
		this.handleChangeFile = this.handleChangeFile.bind(this);
		this.handleSetValue = this.handleSetValue.bind(this);
		this.handleChangeHiddenFile = this.handleChangeHiddenFile.bind(this);
	}

	handleChange(){
		var reader = new FileReader();
		var file = this.refs.file.files[0];
		if (file.size === 0) { return }

		reader.onloadend = () => {
			let { files } = this.state;
			files[0].file = file;
			files[0].file_name = file.name;
			files[0].preview_url = reader.result;
			files = _.concat(files, {file: '', file_name: '', preview_url: ''});

			this.setState({
				open: true,
				files: files
			});
		}
		reader.readAsDataURL(file);
		this.refs.file.value = '';
	}

	handleChangeFile(){
		var reader = new FileReader();
		var file = this.refs.additional_file.files[0];
		if (file.size === 0) { return }

		var index = _.size(this.state.files);
		index = index - 1;

		reader.onloadend = () => {
			let { files } = this.state;
			files[index].file = file;
			files[index].file_name = file.name;
			files[index].preview_url = reader.result;
			files = _.concat(files, {file: '', file_name: '', preview_url: ''});

			this.setState({
				open: true,
				files: files
			});
		}
		reader.readAsDataURL(file);
		this.refs.additional_file.value = '';
	}

	onClose(){
		this.setState({open: false});
	}

	renderFile(file, index){
		if (_.isEmpty(file.preview_url)) { return }
		return (
			<div key={index} className='col-md-12'>
				{file.file_name}
				{/*<img alt='FILE' src={file.preview_url} className='w-100' />*/}
			</div>
		)
	}

	handleSetValue(){
		this.setState({
			open: false,
			files: [
				{
					file: '',
					file_name: '',
					preview_url: ''
				}
			]
		}, this.props.onChange(this.state.files));
	}

	handleChangeHiddenFile(event){
		var isChecked = event.target.checked;
		this.props.handleChange(EditingFieldName, isChecked);
	}

	render(){
		return (
			<div>
				<div>
					<label className="btn btn-sm btn-success pointer mb-0 mr-2">
						<i className="fa fa-upload mr-1" aria-hidden="true"></i>
						อัพโหลดไฟล์...
						<input 
							type="file"
							ref='file' 
							hidden
							onChange={this.handleChange} />
					</label>
					{this.props.hidden_section && (
						<label>
							<input 
								type='checkbox' 
								name='hidden_file'
								className='mr-1 mt-2'
								checked={this.props.hidden_file.toString() === "true"}
								onChange={this.handleChangeHiddenFile} />
							<label className='ex-small-text'>ซ่อน</label>
						</label>
					)}
				</div>
				<div className='small'>รายการสิ่งที่แนบมาด้วย</div>
				<div>
					<SimpleModal 
						isOpen={this.state.open}
						title={'อัพโหลดไฟล์'}
						showSubmitButton={false}
						onClose={this.onClose}>
						<div>
							<div className='row form-group'>
								<div className='col-md-12 form-group'>
									File size limit is 8.00 Mb.
								</div>
								<div className='col-md-12 form-group'>
									<div>
										<label className="btn btn-sm btn-success pointer mb-0 mr-2">
											<i className="fa fa-upload mr-1" aria-hidden="true"></i>
											เลือกไฟล์เพิ่ม
											<input 
												type="file"
												ref='additional_file' 
												hidden
												onChange={this.handleChangeFile} />
										</label>
									</div>
								</div>
								<div className='col-md-12 form-group bg-faded'>
									<div className='row'>
										{this.state.files && _.map(this.state.files, this.renderFile)}
									</div>
								</div>
							</div>
							<div className="modal-footer row">
								<button 
									type='button' 
									className="btn btn-primary pointer"
									onClick={this.handleSetValue}>อัพโหลด</button>
								<button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ยกเลิก</button>
							</div>
						</div>
					</SimpleModal>
				</div>
			</div>
		)
	}
}

export class RenderFileNames extends Component {
	constructor(props){
		super(props)

		this.renderFile = this.renderFile.bind(this);
	}

	renderFile(file, index){
		if (_.isEmpty(file.file_name)) { return }
		return (
			<div key={index} className='col-md-12 text-overflow' style={{position: 'relative'}}>
				<i onClick={()=>this.props.onDeleteFile(index)}
    				className="fa fa-times top-right pointer" 
    				aria-hidden="true">
    			</i>
				{file.file_name}
			</div>
		)
	}

	render(){
		return (
			<div className='row'>
				{this.props.files && _.map(this.props.files, this.renderFile)}
		    </div>
		)
	}
}

