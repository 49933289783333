import React, { Component } from 'react';
import _ from 'lodash';

import UserStore from '../../../stores/UserStore';
import { positionsUserVariables } from '../../Variable';
import Loading from '../../Loading';
import ViewDocumentPermission from './ViewDocumentPermission';

const GeneralMode = 'general';
const ViewDocumentMode = 'view_document';

export default class SetPermissionForm extends Component {
  constructor(props){
    super(props)

    this.state = {
      isDataFetched: false,
      activeMode: GeneralMode,
      can_view_confidential: false,
      can_view_secret: false,
      can_view_top_secret: false,
      can_view_document_template_ids: [],
      positions_users: positionsUserVariables,
      user: {},
      document_templates: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeViewPermission = this.handleChangeViewPermission.bind(this);
    this.handleSaveViewDocumentPermission = this.handleSaveViewDocumentPermission.bind(this);
    this.handleChangeViewDocumentType = this.handleChangeViewDocumentType.bind(this);
  }

  componentWillMount(){
    if (!_.isEmpty(this.props.set_permission_for_user_id)) {
      this.reloadData(this.props.set_permission_for_user_id);
    }else{
      this.setState({ 
        can_view_confidential: false,
        can_view_secret: false,
        can_view_top_secret: false,
        can_view_document_template_ids: [],
        user: {}, 
        positions_users: positionsUserVariables,
        isDataFetched: true 
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.set_permission_for_user_id !== nextProps.set_permission_for_user_id && !_.isEmpty(nextProps.set_permission_for_user_id)){
      this.reloadData(nextProps.set_permission_for_user_id);
    }else{
      this.setState({ 
        can_view_confidential: false,
        can_view_secret: false,
        can_view_top_secret: false,
        can_view_document_template_ids: [],
        user: {}, 
        positions_users: positionsUserVariables,
        isDataFetched: true 
      });
    }
  }

  reloadData(user_id){
    UserStore.request({id: user_id}).then((resp)=>{
      const user = _.get(resp.data, 'data', {});
      const lookups = _.get(resp.data, 'included', {});
      const positions_users = _.get(lookups, 'positions_users', []);
      const document_templates = _.get(lookups, 'document_templates', []);

      let positions_user_variables = [];
      _.forEach(positions_users, function(positions_user) {
        positions_user_variables.push(
          {
            id: positions_user.id,
            position_name: positions_user.attributes.position_name,
            can_view_secret_document: positions_user.attributes.can_view_secret_document,
            can_access_folder: positions_user.attributes.can_access_folder,
            can_book_number: positions_user.attributes.can_book_number,
            can_send_document_to_internal: positions_user.attributes.can_send_document_to_internal,
            can_send_document_to_external: positions_user.attributes.can_send_document_to_external,
            can_issue_number: positions_user.attributes.can_issue_number,
            can_edit_others_document: positions_user.attributes.can_edit_others_document,
            can_create_internal_document: positions_user.attributes.can_create_internal_document,
            can_create_external_document: positions_user.attributes.can_create_external_document,
            can_receive_internal_document: positions_user.attributes.can_receive_internal_document,
            can_receive_external_document: positions_user.attributes.can_receive_external_document,
            can_cancel_document: positions_user.attributes.can_cancel_document,
            can_attach_file: positions_user.attributes.can_attach_file,
            can_scan_document: positions_user.attributes.can_scan_document,
            can_close_document: positions_user.attributes.can_close_document,
            can_undo_sent_document: positions_user.attributes.can_undo_sent_document,
            can_return_document: positions_user.attributes.can_return_document
          }
        );
      });

      this.setState({
        can_view_confidential: user.attributes.can_view_confidential,
        can_view_secret: user.attributes.can_view_secret,
        can_view_top_secret: user.attributes.can_view_top_secret, 
        can_view_document_template_ids: user.attributes.can_view_document_template_ids,
        user: user, 
        positions_users: positions_user_variables,
        document_templates: document_templates
      });
    }).then(()=>{
      this.setState({ isDataFetched: true });
    });
  }

  handleSave(){
    const { positions_users } = this.state;

    var data = new FormData();
    _.forEach(positions_users, function(positions_user) {
      data.append('positions_users[][id]', positions_user.id);
      data.append('positions_users[][can_view_secret_document]', positions_user.can_view_secret_document || false);
      data.append('positions_users[][can_access_folder]', positions_user.can_access_folder || false);
      data.append('positions_users[][can_book_number]', positions_user.can_book_number || false);
      data.append('positions_users[][can_send_document_to_internal]', positions_user.can_send_document_to_internal || false);
      data.append('positions_users[][can_send_document_to_external]', positions_user.can_send_document_to_external || false);
      data.append('positions_users[][can_issue_number]', positions_user.can_issue_number || false);
      data.append('positions_users[][can_edit_others_document]', positions_user.can_edit_others_document || false);
      data.append('positions_users[][can_create_internal_document]', positions_user.can_create_internal_document || false);
      data.append('positions_users[][can_create_external_document]', positions_user.can_create_external_document || false);
      data.append('positions_users[][can_receive_internal_document]', positions_user.can_receive_internal_document || false);
      data.append('positions_users[][can_receive_external_document]', positions_user.can_receive_external_document || false);
      data.append('positions_users[][can_cancel_document]', positions_user.can_cancel_document || false);
      data.append('positions_users[][can_attach_file]', positions_user.can_attach_file || false);
      data.append('positions_users[][can_scan_document]', positions_user.can_scan_document || false);
      data.append('positions_users[][can_close_document]', positions_user.can_close_document || false);
      data.append('positions_users[][can_undo_sent_document]', positions_user.can_undo_sent_document || false);
      data.append('positions_users[][can_return_document]', positions_user.can_return_document || false);
    });

    UserStore.request({
      method: 'POST',
      action: 'batch_update_positions_users',
      data: data
    }).then(()=>{
      this.props.onCloseAndReloadData()
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      this.setState({error_message: error_message});
    });
  }

  handleSaveViewDocumentPermission(){
    var data = new FormData();

    const { user, can_view_document_template_ids } = this.state;
    data.append('user[can_view_confidential]', this.state.can_view_confidential || false);
    data.append('user[can_view_secret]', this.state.can_view_secret || false);
    data.append('user[can_view_top_secret]', this.state.can_view_top_secret || false);

    if (!_.isEmpty(can_view_document_template_ids)){
      _.forEach(can_view_document_template_ids, function(can_view_document_template_id) {
        data.append('user[can_view_document_template_ids][]', can_view_document_template_id);
      });
    }

    UserStore.request({
      id: user.id,
      method: 'PUT',
      action: 'view_document_permission',
      data: data
    }).then(()=>{
      this.props.onCloseAndReloadData()
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      this.setState({error_message: error_message});
    });
  }

  handleChange(index, event){
    const name = event.target.name;
    const checked = event.target.checked;

    let { positions_users } = this.state;
    positions_users[index][name] = checked;

    this.setState({
      positions_users: positions_users
    });
  }

  handleChangeViewPermission(event){
    const name = event.target.name;
    const checked = event.target.checked;

    this.setState({
      [name]: checked
    });
  }

  handleChangeViewDocumentType(event){
    const name = event.target.name;
    const checked = event.target.checked;
    const value = event.target.value;

    let { can_view_document_template_ids } = this.state;

    if (_.includes(can_view_document_template_ids, value)){
      can_view_document_template_ids = _.pull(can_view_document_template_ids, value);
    }else{
      can_view_document_template_ids = _.concat(can_view_document_template_ids, value);
    }

    this.setState({
      can_view_document_template_ids: can_view_document_template_ids
    });
  }

  renderRow(positions_user, index){
    return (
      <div key={index}>
        <div className='row'>
          <div className='col-12 mb-2'>
            <h5><strong>ตำแหน่ง: {positions_user.position_name}</strong></h5>
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_view_secret_document'
              checked={positions_user.can_view_secret_document}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการดูหนังสือที่มีชั้นความลับ (นายทะเบียนหนังสือลับ)
          </div>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_access_folder'
              checked={positions_user.can_access_folder}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการเข้าใช้งานแฟ้ม และตัวเอกสารแบบละเอียด
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_book_number'
              checked={positions_user.can_book_number}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการจองเลขที่หนังสือ/กันเลขรับ
          </div>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_send_document_to_internal'
              checked={positions_user.can_send_document_to_internal}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการส่งหนังสือภายในหน่วยงาน
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_send_document_to_external'
              checked={positions_user.can_send_document_to_external}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการส่งหนังสือออกภายนอกหน่วยงาน
          </div>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_issue_number'
              checked={positions_user.can_issue_number}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการออกเลขที่หนังสือ
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_edit_others_document'
              checked={positions_user.can_edit_others_document}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการแก้ไขหนังสือ สาหรับ User ที่ไม่ใช่ผู้สร้างหนังสือ
          </div>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_create_internal_document'
              checked={positions_user.can_create_internal_document}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการสร้างหนังสือภายใน
          </div>
        </div>
        
        <div className='row mb-2'>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_create_external_document'
              checked={positions_user.can_create_external_document}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการสร้างหนังสือส่งออก
          </div>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_receive_internal_document'
              checked={positions_user.can_receive_internal_document}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการรับหนังสือภายในหน่วยงาน
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_receive_external_document'
              checked={positions_user.can_receive_external_document}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการรับหนังสือภายนอกหน่วยงาน
          </div>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_cancel_document'
              checked={positions_user.can_cancel_document}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการยกเลิกหนังสือ
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_attach_file'
              checked={positions_user.can_attach_file}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการแนบไฟล์
          </div>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_scan_document'
              checked={positions_user.can_scan_document}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการ Scan หนังสือ
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_close_document'
              checked={positions_user.can_close_document}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการปิดงาน
          </div>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_undo_sent_document'
              checked={positions_user.can_undo_sent_document}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการดึงกลับ
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-1 text-right'>
            <input 
              type='checkbox'
              name='can_return_document'
              checked={positions_user.can_return_document}
              onChange={(e) => this.handleChange(index, e)} />
          </div>
          <div className='col-5'>
            สิทธิ์ในการส่งคืน
          </div>
        </div>

      </div>
    )
  }

  render(){
    const { positions_users, document_templates } = this.state;
    return (
      <div>
        {!this.state.isDataFetched && <Loading />}

        <ul className="nav nav-tabs nav-fill ml-0" id="ManageUser" role="tablist">
          <li className="nav-item">
            <span className={`nav-link pointer ${(this.state.activeMode === GeneralMode) && 'active'}`} 
            href='#'
            onClick={()=>this.setState({activeMode: GeneralMode})}>
              กำหนดสิทธิ์ทั่วไป
            </span>
          </li>
          <li className="nav-item">
            <span className={`nav-link pointer ${(this.state.activeMode === ViewDocumentMode) && 'active'}`} 
            href='#'
            onClick={()=>this.setState({activeMode: ViewDocumentMode})}>
              กำหนดสิทธิ์ในการดูหนังสือ
            </span>
          </li>
        </ul>

        <div className="tab-content mt-4" id="SetPermissionForEachUser">
          <div className={`tab-pane fade ${(this.state.activeMode === GeneralMode) && 'show active'}`}>
            {!_.isEmpty(positions_users) && _.map(positions_users, this.renderRow)}
            {_.isEmpty(positions_users) && 'ไม่พบตำแหน่งของผู้ใช้'}
          </div>
          <div className={`tab-pane fade ${(this.state.activeMode === ViewDocumentMode) && 'show active'}`}>
            <ViewDocumentPermission 
              can_view_confidential={this.state.can_view_confidential}
              can_view_secret={this.state.can_view_secret}
              can_view_top_secret={this.state.can_view_top_secret}
              can_view_document_template_ids={this.state.can_view_document_template_ids}
              document_templates={document_templates}
              handleChangeViewPermission={this.handleChangeViewPermission}
              handleChangeViewDocumentType={this.handleChangeViewDocumentType} />
          </div>
        </div>

        {this.state.activeMode === GeneralMode ? (
          <div className="modal-footer row mt-3">
            <button type='button' className="btn btn-primary pointer" onClick={this.handleSave}>Save changes</button>
            <button type='button' className="btn btn-secondary pointer" onClick={()=>this.props.onClose()}>Close</button>
          </div>
        ) : (
          <div className="modal-footer row mt-3">
            <button type='button' className="btn btn-primary pointer" onClick={this.handleSaveViewDocumentPermission}>Save changes</button>
            <button type='button' className="btn btn-secondary pointer" onClick={()=>this.props.onClose()}>Close</button>
          </div>
        )}
      </div>
    )
  }
}