import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import LoginSession from '../stores/LoginSession';

export default class TopHeader extends Component {
	constructor(props) {
		super(props)

		this.handleLogout = this.handleLogout.bind(this);
		this.handleRedirectToMyDocs = this.handleRedirectToMyDocs.bind(this);
	}

	handleLogout(event){
		event.preventDefault();
		LoginSession.logout();
		window.location.replace('/login');
	}

	handleRedirectToMyDocs(){
		window.location.replace('/receive_documents/mydoc');
	}

  render(){
		return (
			<div className='row top-header-height m-0 p-2'>
				{/*
				<div className='col-md-7'>	
					<h4 className='mt-2 font-weight-bold pointer' onClick={this.handleRedirectToMyDocs}>
						{_.get(this.props.siteSetting, 'name', '')}
					</h4>
				</div>
				*/}
				<div className='col-12 text-right'>	
					{LoginSession.current ? (
						<div>
							<Link to='/receive_documents/mydoc' className='mr-2'>กรมสุขภาพจิต</Link>|
							<Link to='/edit_profile' className='mx-2'>{LoginSession.current.user.full_name}</Link>|
							<Link to='/' className='ml-2' onClick={this.handleLogout}>Logout</Link>
						</div> 
					) : (
						<Link to='/login'>Login</Link>
					)}
				</div>
			</div>
		)
  	}
}
