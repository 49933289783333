import React, {Component} from 'react';
import _ from 'lodash';

import { MergeAttributes } from '../../FlatData';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import SimpleModal from '../../SimpleModal';
import InlineEditPositionName from './InlineEditPositionName';

const UpLine = 'line';
const AllUsers = 'all';
const EditingFieldName = 'throughs';

export class ThroughForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			open: false,
			activeMode: UpLine
		}

		this.positionFormat = this.positionFormat.bind(this);
		this.departmentFormat = this.departmentFormat.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.onThroughOpen = this.onThroughOpen.bind(this);
		this.onClose = this.onClose.bind(this);
	}

	positionFormat(cell, row){
		return (
			<div style={{whiteSpace: 'normal'}}>{row.position_name}</div>
		)
	}

	departmentFormat(cell, row){
		return (
			<div style={{whiteSpace: 'normal'}}>{row.department_name}</div>
		)
	}

	onSelect(row, isSelected, e){
		if (this.props.multiple) {
			let { throughs } = this.props;
			throughs = _.union(throughs, [{user_id: row.user_id, position_id: row.position_id, position_name: row.position_name}]);
			// throughs = [{user_id: row.user_id, position_id: row.position_id, position_name: row.position_name}];
			
			this.setState({open: false}, this.props.handleChange(EditingFieldName, throughs));
		}else{
			if (!_.isEmpty(this.props.throughs)) {
				alert('ไม่สามารถเพิ่มส่วนราชการที่ส่งหนังสือออกได้มากกว่าหนึ่งรายการ');
				return
			}
			let throughs = [{user_id: row.user_id, position_id: row.position_id, position_name: row.position_name}];
			this.setState({open: false}, this.props.handleChange(EditingFieldName, throughs));
		}
	}

	onThroughOpen(){
		this.setState({open: true});
	}

	onClose(){
		this.setState({open: false});
	}

	renderInternalUsers(){
		const options = {
		  onRowClick: this.onSelect,
			sizePerPageList: [],
			sizePerPage: 6
		};
		var data = MergeAttributes(this.props.internal_users);
	   	var data =  _.filter(data, function(d) {
		                return d.is_actor == false
		            });
		return (
			<BootstrapTable 
		  		data={data}
				search={ true }
				multiColumnSearch={ true }
				options={ options }
				striped
				hover
				condensed
				pagination
				version='4'
				tableContainerClass={'table-sm'}>
			  		<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
					<TableHeaderColumn 
						dataField='full_name' 
						dataSort={ true } 
						columnClassName='pointer'>
						ชื่อ-สกุล
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='position_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.positionFormat}>
						ตำแหน่ง
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='department_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.departmentFormat}>
						แผนก
					</TableHeaderColumn>
			</BootstrapTable>
		)
	}

	renderLineUsers(){
		const options = {
		  onRowClick: this.onSelect,
			sizePerPageList: [],
			sizePerPage: 6
		};
		var data = MergeAttributes(this.props.users);
	   	var data =  _.filter(data, function(d) {
		                return d.is_actor == false
		            });
		return (
			<BootstrapTable 
		  		data={data}
				search={ true }
				multiColumnSearch={ true }
				options={ options }
				striped
				hover
				condensed
				pagination
				version='4'
				tableContainerClass={'table-sm'}>
			  		<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
					<TableHeaderColumn 
						dataField='full_name' 
						dataSort={ true } 
						columnClassName='pointer'>
						ชื่อ-สกุล
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='position_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.positionFormat}>
						ตำแหน่ง
					</TableHeaderColumn>
					<TableHeaderColumn 
						dataField='department_name' 
						dataSort={ true } 
						columnClassName='pointer' 
						dataFormat={this.departmentFormat}>
						แผนก
					</TableHeaderColumn>
			</BootstrapTable>
		)
	}

	render(){
		return (
			<div>
				{_.isEmpty(this.props.signees) ? (
					<button 
						type='button' 
						className='btn btn-sm btn-primary mr-2'
						disabled>
						เพิ่มส่งผ่าน
					</button>
				) : (
					<button 
						type='button' 
						className='btn btn-sm btn-primary mr-2'
						onClick={this.onThroughOpen}>
						เพิ่มส่งผ่าน
					</button>
				)}

				<div className='small mt-2'>
					รายชื่อผู้ส่งผ่าน
				</div>

			    <SimpleModal 
					isOpen={this.state.open}
					title={'เลือกผู้ส่งผ่าน'}
					size={'modal-lg'}
					showSubmitButton={false}
					onClose={this.onClose}>
					<div>
						<ul className="nav nav-tabs" id="ReferenceDocuments" role="tablist">
						  	<li className="nav-item">
								<span className={`nav-link pointer ${(this.state.activeMode === UpLine) && 'active'}`} 
								href='#'
								onClick={()=>this.setState({activeMode: UpLine})}>
									ตามสายงาน
								</span>
						  	</li>
						  	<li className="nav-item">
								<span className={`nav-link pointer ${(this.state.activeMode === AllUsers) && 'active'}`} 
								href="#"
								onClick={()=>this.setState({activeMode: AllUsers})}>
									ทั้งหมด
								</span>
						  	</li>
						</ul>
						<div className="tab-content mt-3" id="ReferenceDocuments">
						  	<div className={`tab-pane fade ${(this.state.activeMode === UpLine) && 'show active'}`}>
						  		{this.renderInternalUsers()}
						  	</div>
						  	<div className={`tab-pane fade ${(this.state.activeMode === AllUsers) && 'show active'}`}>
						  		{this.renderLineUsers()}
						  	</div>
						</div>
					</div>
				</SimpleModal>
			</div>
		)
	}
}

export class RenderThroughs extends Component {
	constructor(props) {
		super(props)

		this.renderRow = this.renderRow.bind(this);
	}

	renderRow(through, index){
		let user = '';
		let user_attributes = _.map(this.props.users, 'attributes');
		user = _.find(user_attributes, {user_id: through.user_id, position_id: through.position_id});


		const on_duty = _.find(this.props.actings, (acting) => {
											return acting.attributes.user.id === through.user_id
										});

		if(on_duty){
			return (
				<li style={{position: 'relative'}} key={index} className="bg-danger text-white p-2">
	  			<i className="fa fa-times text-black top-right pointer" onClick={()=>this.props.onDeleteThrough(through)}></i>
          <i className='fa fa-eject text-black top-right pointer' onClick={() => this.props.onShowActorsForm(through, "through")}></i>
    			<div className='mr-1'>{user.full_name} ({user.position_name})</div>
	    	</li>
			)
		} else {
			return (
				<li style={{position: 'relative'}} key={index}>
    			<i className="fa fa-times top-right pointer" onClick={()=>this.props.onDeleteThrough(through)}></i>
    			<div className='mr-1'>{user.full_name} ({user.position_name})</div>
	    	</li>
			)
		}
	}

	render(){
		return (
			<div>
				{!_.isEmpty(this.props.throughs) && (
			    	<ul className='pl-4 mb-0 small mt-2'>
			    		{_.map(this.props.throughs, this.renderRow)}
			    	</ul>
				)}
		    </div>
		)
	}
}

export class RenderThroughsSignature extends Component {
	constructor(props){
		super(props)

		this.renderRow = this.renderRow.bind(this);
	}

	renderRow(through, index){
		let user = '';
		let user_attributes = _.map(this.props.users, 'attributes');
		user = _.find(user_attributes, {user_id: through.user_id, position_id: through.position_id});
		return (
			<div key={index} className='mt-5 pt-2'>
				<div>({user.full_name})</div>
				<div>
					<InlineEditPositionName 
						mode={'through'} 
						signee={through}
						position_name={through.position_name}
						handleChange={this.props.handleChange} />
				</div>
			</div>
		)
	}

	render(){
		return (
			<div className='a4-paper bg-light mt-3'>
        <div className='through-signature-section row'>
        	<div className='col-7 ml-auto text-center'>
        		<h1 className='text-primary'>ส่งผ่าน</h1>
        	</div>
        	<div className='col-7 ml-auto text-center'>
        		{_.map(this.props.throughs, this.renderRow)}
        	</div>
        </div>
      	<div className='footer-page'></div>
    	</div>
		)
	}
}




