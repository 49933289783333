import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';

import Permission from '../../components/Permission';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import SearchDocumentForm from '../../components/documents/SearchDocumentForm';
import Loading from '../../components/Loading';
import { rowClassNameWithPointerFormat, thaiDateFormatter, thaiDateTimeFormatter } from '../../utils/bootstrapTableFormatters';
import { DocumentOwner, SpeedLevelClassName } from '../../utils/Helper';
import { MergeAttributes } from '../../components/FlatData';
import SimpleModal from '../../components/SimpleModal';
import WorkflowProcessHistory from '../../components/WorkflowProcessHistory'
import ShowAttachedFiles from '../../components/documents/ShowAttachedFiles';
import HandleOriginalFiles from '../../components/documents/HandleOriginalFiles';

import './stylesheets/SearchReceiveDocumentsPage.css';

import SearchDocumentStore from '../../stores/SearchDocumentStore';
import WorkflowProcessStore from '../../stores/WorkflowProcessStore';

class SearchReceiveDocumentsPage extends Component {
  constructor() {
    super()

    var dateObj = new Date();
    var year = dateObj.getUTCFullYear() + 543;

    this.state = {
      isDataFetched: false,
      open_insert_file: false,
      year: year,
      document_template_types: [],
      data: [],
      pagination: {},
      search_params: {},
      modal_form: "history",
      modal_title: "ประวัติการดำเนินการ การปฏิบัติงาน",
      searchText: ''
    }

    this.stateFormatter = this.stateFormatter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.redirectToShowDocumentPage = this.redirectToShowDocumentPage.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.documentDetailsFormatter = this.documentDetailsFormatter.bind(this);
    this.speedLevelFormatter = this.speedLevelFormatter.bind(this);
    this.documentReceiveNumberFormatter = this.documentReceiveNumberFormatter.bind(this);
    this.documentIssueNumberWithFilesFormatter = this.documentIssueNumberWithFilesFormatter.bind(this);
    this.handleShowAttachedFiles = this.handleShowAttachedFiles.bind(this);

    // Pagination and Search
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCloseFile = this.onCloseFile.bind(this);
  }

  componentDidMount() {
    SearchDocumentStore.request({
      method: 'POST',
      action: 'search_receive_document',
      data: {}
    }).then((resp)=>{
      const data = _.get(resp.data, 'data', []);
      const included = _.get(resp.data, 'included', {});
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        data: data,
        document_template_types: included.document_template_types,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  stateFormatter(cell, row) {
    return (
      <div className='text-link pointer' style={{whiteSpace: 'normal'}} onClick={this.openModal.bind(this, row.workflow_process_id)}>
        {cell}
      </div>
    )
  }

  openModal(workflow_process_id, event){
    event.stopPropagation();

    this.setState({
      open: true,
      modal_form: "history",
      workflow_process_id_history: workflow_process_id,
    });
  }

  onClose(){
    this.setState({
      open: false,
      open_attached_file: false,
      open_insert_file: false, 
      workflow_process_id: null
    });
  }

  handleSubmit(search_params){
    this.setState({isDataFetched: false});
    
    SearchDocumentStore.request({
      method: 'POST',
      action: 'search_receive_document',
      data: _.merge(search_params, {search_document_type: 'receive'})
    }).then((resp)=>{
      const data = _.get(resp.data, 'data', []);
      const included = _.get(resp.data, 'included', {});
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        data: data,
        document_template_types: included.document_template_types,
        pagination: pagination,
        search_params: search_params
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleDownload(params){
    axios({
      method:'POST',
      url: '/api/v1/search_documents/search_receive_document',
      responseType:'arraybuffer',
      cache: false,
      data: _.merge(params, {response_type: 'csv'})
    }).then(function(response) {
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' } )
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'รายงาน.xlsx'
      link.click()
    });
  }

  handleInsertFile(workflow_process_id, e){
    e.stopPropagation();
    this.setState({
      open_insert_file: true,
      workflow_process_id: workflow_process_id
    });
  }

  onCloseFile(){
    this.setState({
      open_insert_file: false,
      workflow_process_id: ''
    });
  }

  _setTableOption(){ 
      if(this.state.isDataFetched){
            return "ไม่มีข้อมูล";
        }else{
            return <Loading />
        }
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, data: []});
    const { search_params } = this.state;

    let data = _.merge(search_params, {search_document_type: 'receive'});
    data = _.merge(data, {page: page});

    SearchDocumentStore.request({
      method: 'POST',
      action: 'search_receive_document',
      data: data
    }).then((resp)=>{
      const data = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        data: data,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, data: []});

    SearchDocumentStore.request({
      method: 'POST',
      action: 'search_receive_document',
      data: {
        page: 1,
        searchText: searchText
      }
    }).then((resp)=>{
      const data = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        data: data,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleShowAttachedFiles(workflow_process_id, e){
    e.stopPropagation();
    this.setState({workflow_process_id_attachment: workflow_process_id, open_attached_file: true})
  }

  speedLevelFormatter(cell, row) {
    return (
      <div className={`text-${SpeedLevelClassName(row.attributes.speed_level)}`}>
        {row.attributes.speed_level}
        {_.size(row.original_documents) > 0 && (
          <i 
            className="fa fa-paperclip ml-2 text-danger" 
            aria-hidden="true" 
            onClick={this.handleInsertFile.bind(this, cell)}>
          </i>
        )}
      </div>
    )
  }

  documentDetailsFormatter(cell, row){
    return (
      <div style={{whiteSpace: 'normal'}}>
        <div><u>เรียน</u> {row.to_department}</div>
        <div><u>เรื่อง</u> {row.topic}</div>
        <DocumentOwner cell={cell} row={row} />

        {row.reference_issue_numbers && (
          <div><u>อ้างอิงเลขที่หนังสือ</u> {row.reference_issue_numbers}</div>
        )}
      </div>
    )
  }

  documentIssueNumberWithFilesFormatter(cell, row){
    const total_files_amount = _.size(row.original_documents) + row.attachment_count;
    return (
      <div>
          <div>
            <div className='text-link pointer' onClick={() => this.redirectToShowDocumentPage(cell, row)}>
              {row.prev_issue_number || row.issue_number}
            </div>
            <div onClick={this.handleInsertFile.bind(this, cell)} className='font-weight-bold pointer text-link'>
              ไฟล์แทรก ({total_files_amount})
            </div>

            {/*
            <div className='pointer text-link' onClick={this.handleShowAttachedFiles.bind(this, cell)}>
              <strong className='pointer'>ไฟล์แนบ ({row.attachment_count})</strong>
            </div>
            */}

            {!_.isEmpty(row.attachment) && (
              <div>
                <u className='mr-2'>สิ่งที่ส่งมาด้วย</u> 
                <span>{row.attachment}</span>
              </div>
            )}
          </div>
      </div>
    )
  }

  documentReceiveNumberFormatter(cell, row){
    return (
      <div className='text-success'>{row.attributes.code}</div>
    )
  }

  handleDeleteDocument(workflow_process_id, e){
    e.stopPropagation();

    if (!window.confirm('ยืนยันการลบหนังสือ')) { return }

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'delete'
    }).then(()=>{
      window.location.reload();
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }


  redirectToShowDocumentPage(row, isSelected, e){
    if(_.isEmpty(row.document_id)) { return }
    this.props.history.push(`/templates/documents/${row.document_id}/show/${row.document_template_id}/wp/${row.workflow_process_id}`);
  }

  render(){
    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);

    const options = {
      sizePerPageList: [],
      sizePerPage: 10,
      page: active_page,
      noDataText: this._setTableOption(),
      onRowClick: this.redirectToShowDocumentPage,
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500
    };

    const { isDataFetched, year, document_template_types } = this.state;

    let data = [];
    if (this.state.isDataFetched) {
      data = MergeAttributes(this.state.data);
    }

		return (
			<div>
        <div className='row pl-3'>
          <div className='col-xs-12'>
            <h2>ค้นหาทะเบียนหนังสือรับ</h2>
          </div>
        </div>
        
        <div className='form-group'>
          <SearchDocumentForm 
            isDataFetched={isDataFetched} 
            year={year}
            document_template_types={document_template_types}
            handleSubmit={this.handleSubmit}
            handleDownload={this.handleDownload} />
        </div>
        
        <div className="rui-searc-receive-documents">
          <BootstrapTable 
            data={data}
            remote={ true }
            fetchInfo={{dataTotalSize: total_items_count}}
            search={ false }
            multiColumnSearch={ true }
            options={ options }
            striped
            hover
            pagination
            version='4'
            tableContainerClass={'table-sm'}
            trClassName={rowClassNameWithPointerFormat}>
            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.speedLevelFormatter} width='75'>ชั้นความเร็ว</TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.documentReceiveNumberFormatter} width='75'>เลขรับ</TableHeaderColumn>
            <TableHeaderColumn dataField='workflow_process_id' dataFormat={this.documentIssueNumberWithFilesFormatter} width='140'>ที่</TableHeaderColumn>
            <TableHeaderColumn dataField='document_date_thai_format' dataFormat={thaiDateFormatter} width='90'>ลงวันที่</TableHeaderColumn>
            <TableHeaderColumn dataField='from_department' dataFormat={this.stateFormatter} columnTitle width="200">จาก</TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.documentDetailsFormatter}>รายละเอียดหนังสือ</TableHeaderColumn>
            <TableHeaderColumn dataField='sent_at' dataFormat={thaiDateTimeFormatter} width='90'>วันที่ส่ง</TableHeaderColumn>
            <TableHeaderColumn dataField='received_at' dataFormat={thaiDateTimeFormatter} width='90'>วันที่รับ</TableHeaderColumn>
          </BootstrapTable>
        </div>

        <ShowAttachedFiles 
          workflow_process_id={this.state.workflow_process_id_attachment}
          open_attached_file={this.state.open_attached_file}
          onClose={this.onClose} />

        <HandleOriginalFiles 
          workflow_process_id={this.state.workflow_process_id}
          open_insert_file={this.state.open_insert_file}
          after_save_redirect_to={"/receive_documents/search_receive_documents"}
          onClose={this.onClose} />

        <SimpleModal 
          isOpen={this.state.open}
          title={this.state.modal_title}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onClose}>

            {this.state.modal_form === "history" && (
              <div>
                <WorkflowProcessHistory id={this.state.workflow_process_id_history} cancelable={true} />

                <div className="modal-footer row">
                  <button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ปิด</button>
                </div>
              </div>
            )}

        </SimpleModal>
			</div>
		)
	}
}

export default SearchReceiveDocumentsPage;