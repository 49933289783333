import React, {Component} from 'react';
import _ from 'lodash';

import SimpleModal from './SimpleModal';

export default class MultipleUpload extends Component {
  constructor(props){
    super(props)

    this.state = {
      open: false,
      files: []
    }

    this.onClose = this.onClose.bind(this);
    this.renderFile = this.renderFile.bind(this);
    this.handleSetValue = this.handleSetValue.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
  }

  handleChangeFile(event){
    var file = event.target.files[0];
    if (file.size === 0) { return }
    if (file.size > 10000000) { alert("จำกัดขนาดไฟล์ไม่เกิน 10MB."); return; }

    const new_file = { file: file }
    let { files } = this.state;

    files = _.concat(files, [new_file]);

    this.setState({
      open: true,
      files: files
    });

    event.target.value = '';
  }

  onClose(){
    this.setState({open: false});
  }

  renderFile(file, index){
    if (_.isEmpty(file)) { return }
    return (
      <div key={index} className='col-12'>
        {file.file.name}
      </div>
    )
  }

  handleSetValue(){
    this.setState({
      open: false,
      files: []
    }, this.props.onChange(this.state.files));
  }

  render(){
    return (
      <div>
        <div>
          <label className="btn btn-sm btn-success pointer mb-0 mr-2">
              <i className="fa fa-upload mr-1" aria-hidden="true"></i>
                {this.props.label}
                <input 
                  type="file" 
                  name='file' 
                  hidden
                  onChange={this.handleChangeFile} />
            </label>
            <label className='text-danger'>{this.props.limitation_label}</label>
        </div>

        <SimpleModal 
          isOpen={this.state.open}
          title={'อัพโหลดไฟล์'}
          showSubmitButton={false}
          onClose={this.onClose}>
          <div>
            <div className='row form-group'>
              <div className='col-md-12 form-group'>
                {this.props.notice_label}
              </div>
              <div className='col-md-12 form-group'>
                <div>
                  <label className="btn btn-sm btn-success pointer mb-0 mr-2">
                    <i className="fa fa-upload mr-1" aria-hidden="true"></i>
                    เลือกไฟล์เพิ่ม
                    <input 
                      type="file"
                      name='additional_file' 
                      hidden
                      onChange={this.handleChangeFile} />
                  </label>
                </div>
              </div>
              <div className='col-md-12 form-group bg-faded'>
                <div className='row'>
                  {this.state.files && _.map(this.state.files, this.renderFile)}
                </div>
              </div>
            </div>
            <div className="modal-footer row">
              <button 
                type='button' 
                className="btn btn-primary pointer"
                onClick={this.handleSetValue}>อัพโหลด</button>
              <button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ยกเลิก</button>
            </div>
          </div>
        </SimpleModal>
      </div>
    )
  }
}