import React, {Component} from 'react';
import _ from 'lodash';

export class OrderFilter extends Component {
  constructor(props) {
    super(props)
    
    this.renderOrder = this.renderOrder.bind(this);
  }

  handleChangeOrder(order){
    this.props.handleChangeOrder(order);
  }

  renderOrder(order, index){
    return (
      <label 
        key={index} 
        onClick={this.handleChangeOrder.bind(this, order)}
        className={`btn btn-sm btn-secondary ${this.props.active_order === order && 'active'}`}>
        <input type="radio" name="order_filter" id={`order_filter_${index}`} autoComplete="off" defaultChecked="true" /> 
        {order}
      </label>
    )
  }

  render(){
    const order_list = ["ทั้งหมด", "คำสั่ง", "ระเบียบ", "ข้อบังคับ"];
    return (
      <div className='row mb-1'>
        <div className='col-md-12'>
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            {_.map(order_list, this.renderOrder)}
          </div>
        </div>
      </div>
    )
  }
}

export default OrderFilter;
