import React, { Component } from 'react';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';
  
import { forumToolbarOptions } from '../../utils/editorToolbarOptions';
import LoginSession from '../../stores/LoginSession';
import ForumStore from '../../stores/ForumStore';
import SimpleModal from '../../components/SimpleModal';

class ForumForm extends Component {
  constructor(props){
    super(props)
  }

  render(){
    const { open, edit_topic_id, title, body } = this.props;
    return (
      <SimpleModal 
        isOpen={open}
        title={edit_topic_id ? 'แก้ไข' : 'สร้างกระทู้'}
        size={'modal-lg'}
        showSubmitButton={false}
        onClose={this.props.onClose}>
          <form onSubmit={this.props.handleSubmit}>
            <div className='row form-group'>
              <div className='col-12 font-weight-bold'>
                <label>หัวข้อ</label>
              </div>
              <div className='col-12'>
                <input 
                  name='title' 
                  value={title}
                  className='form-control'
                  onChange={this.props.handleChange} />
              </div>
            </div>
            <div className='row form-group'>
              <div className='col-12 font-weight-bold'>
                <label>เนื้อหา</label>
              </div>
              <div className='col-12'>
                <div className='mx-3'>
                  <ReactQuill 
                    value={body}
                    onChange={this.props.handleChangeBody}
                    modules={{toolbar: forumToolbarOptions}} />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <button type='submit' className='btn btn-primary'>บันทึก</button>
              </div>
            </div>
          </form>
      </SimpleModal>
    )
  }
}

export default ForumForm;