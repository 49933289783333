import React, {Component} from 'react';

import InlineEditAttribute from './elements/InlineEditAttribute';

export class ImportantNoteSection extends Component {
  render(){
    const { document_template, document_attributes, document, loading } = this.props;

    return (
      <div className='mt-5'>
        <div>
          <div className='mt-4' style={{width: '200px'}}>
            <InlineEditAttribute
              label={document_attributes.important_note_title.title}
              suggest_text={document_attributes.important_note_title.suggest_text} 
              field_name={document_attributes.important_note_title.name}
              value={document_attributes.important_note_title.value}
              text_align_left={false}
              label_class={'text-md'}
              content_class={'text-md'}
              has_space_after_label={false}
              has_parenthesis={false}
              handleChange={this.props.handleChangeAttributeValue}
              loading={loading} />
          </div>
        </div>
        <div className='border border-dark' style={{width: '200px', height: '304px'}}></div>
        <div>
          <div className='mt-4' style={{width: '200px'}}>
            <InlineEditAttribute
              label={document_attributes.important_note_signature.title}
              suggest_text={document_attributes.important_note_signature.suggest_text} 
              field_name={document_attributes.important_note_signature.name}
              value={document_attributes.important_note_signature.value}
              text_align_left={false}
              label_class={'text-md'}
              content_class={'text-md'}
              has_space_after_label={false}
              has_parenthesis={false}
              handleChange={this.props.handleChangeAttributeValue}
              loading={loading} />
            </div>
        </div>
        <div>
          <div style={{width: '200px'}}>
            <InlineEditAttribute
              label={document_attributes.important_note_fullname.title}
              suggest_text={document_attributes.important_note_fullname.suggest_text} 
              field_name={document_attributes.important_note_fullname.name}
              value={document_attributes.important_note_fullname.value}
              text_align_left={false}
              label_class={'text-md'}
              content_class={'text-md'}
              has_space_after_label={false}
              has_parenthesis={false}
              handleChange={this.props.handleChangeAttributeValue}
              loading={loading} />
          </div>
        </div>

      </div>
    )
  }
}

export default ImportantNoteSection;
