import React, { Component } from 'react';
import _ from 'lodash';

import GroupDataForm from './GroupDataForm';
import GroupLDAPForm from './GroupLDAPForm';
import GroupPermissionForm from './GroupPermissionForm';

import GroupStore from '../../../stores/GroupStore';
import { groupVariables } from '../../Variable';
import Loading from '../../Loading';

import ErrorMessage from '../../ErrorMessage';

const dataMode = 'data';
const ldapMode = 'ldap';
const permissionMode = 'permission';

class GroupForm extends Component {
	constructor(props){
		super(props)

		this.state = {
			loading: false,
			activeMode: 'data',
			group: groupVariables(),
			error_message: ''
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.loadData = this.loadData.bind(this);
	}

	componentWillMount(){
		if (this.props.action === 'edit') {
			this.loadData(this.props.focus.id);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.focus.id !== nextProps.focus.id && nextProps.action === 'edit'){
			this.loadData(nextProps.focus.id);
		}
	}

	loadData(group_id){
		this.setState({loading: true});

		GroupStore.request({id: group_id, action: 'edit'}).then((resp)=>{
			var group = _.get(resp.data, 'data', {});
			this.setState({
				group: {
					code: group.attributes.code,
					name: group.attributes.name,
					short_name: group.attributes.short_name,
					active_directories: group.attributes.active_directories,
					can_view_secret_document: group.attributes.can_view_secret_document,
			    can_access_folder: group.attributes.can_access_folder,
			    can_book_number: group.attributes.can_book_number,
			    can_send_document_to_internal: group.attributes.can_send_document_to_internal,
			    can_send_document_to_external: group.attributes.can_send_document_to_external,
			    can_issue_number: group.attributes.can_issue_number,
			    can_edit_others_document: group.attributes.can_edit_others_document,
			    can_create_internal_document: group.attributes.can_create_internal_document,
			    can_create_external_document: group.attributes.can_create_external_document,
			    can_receive_internal_document: group.attributes.can_receive_internal_document,
			    can_receive_external_document: group.attributes.can_receive_external_document,
			    can_cancel_document: group.attributes.can_cancel_document,
			    can_attach_file: group.attributes.can_attach_file,
			    can_scan_document: group.attributes.can_scan_document,
			    can_close_document: group.attributes.can_close_document,
			    can_undo_sent_document: group.attributes.can_undo_sent_document,
			    can_return_document: group.attributes.can_return_document
				}
			});
		}).then(()=>{
			this.setState({loading: false});
		})
	}

	handleChange(group){
		this.setState({group: group});
	}

	handleSave(event){
		event.preventDefault();
		var self = this;
		const { group } = this.state;
		var active_directory_ids = _.map(group.active_directories, 'id', []);

		var id = null;
		var group_attributes = {}
		if (this.props.action === 'edit') {
			id = this.props.focus.id;
			group_attributes = {
				code: group.code,
				name: group.name,
				short_name: group.short_name,
				active_directory_ids: active_directory_ids
			}
		} else {
			group_attributes = {
				code: group.code,
				name: group.name,
				short_name: group.short_name,
				active_directory_ids: active_directory_ids,
				parent_id: this.props.focus.id
			}
		}

		const permissions = {
			can_view_secret_document: group.can_view_secret_document,
	    can_access_folder: group.can_access_folder,
	    can_book_number: group.can_book_number,
	    can_send_document_to_internal: group.can_send_document_to_internal,
	    can_send_document_to_external: group.can_send_document_to_external,
	    can_issue_number: group.can_issue_number,
	    can_edit_others_document: group.can_edit_others_document,
	    can_create_internal_document: group.can_create_internal_document,
	    can_create_external_document: group.can_create_external_document,
	    can_receive_internal_document: group.can_receive_internal_document,
	    can_receive_external_document: group.can_receive_external_document,
	    can_cancel_document: group.can_cancel_document,
	    can_attach_file: group.can_attach_file,
	    can_scan_document: group.can_scan_document,
	    can_close_document: group.can_close_document,
	    can_undo_sent_document: group.can_undo_sent_document,
	    can_return_document: group.can_return_document
		}

		group_attributes = _.merge(group_attributes, permissions);
		GroupStore.save(id, {
			group: group_attributes
		}).then(()=>{
			this.props.onCloseAndReloadData()
		}, function(error){
			var error_message = _.get(error.response.data, 'error.message', '');
			self.setState({error_message: error_message});
		});
	}

	render(){
		var ldapSize = _.size(this.state.group.active_directories);
		return (
			<form onSubmit={this.handleSave}>
				{this.state.error_message && <ErrorMessage error_message={this.state.error_message} />}

				<ul className="nav nav-tabs nav-fill ml-0" id="ManageGroupUser" role="tablist">
				  <li className="nav-item">
						<span className={`nav-link pointer ${(this.state.activeMode === dataMode) && 'active'}`} 
						onClick={()=>this.setState({activeMode: dataMode})}>
							ข้อมูล
						</span>
				  </li>
				  <li className="nav-item">
						<span className={`nav-link pointer ${(this.state.activeMode === permissionMode) && 'active'}`} 
						onClick={()=>this.setState({activeMode: permissionMode})}>
							การกำหนดสิทธิ์
						</span>
				  </li>
				  <li className="nav-item">
						<span className={`nav-link pointer ${(this.state.activeMode === ldapMode) && 'active'}`} 
						onClick={()=>this.setState({activeMode: ldapMode})}>
							LDAP
							{ldapSize > 0 && (
				    			<em>&nbsp;({ldapSize})</em>
				    		)}
						</span>
				  </li>
				</ul>

				<div className="tab-content mt-4" id="ManageUserContent">
					{this.state.loading && <Loading />}

			  	<div className={`tab-pane fade ${(this.state.activeMode === dataMode) && 'show active'}`}>
			  		<GroupDataForm 
			  			group={this.state.group} 
			  			handleChangeData={this.handleChange} />
			  	</div>
			  	<div className={`tab-pane fade ${(this.state.activeMode === permissionMode) && 'show active'}`}>
			  		<GroupPermissionForm 
			  			group={this.state.group}
			  			handleChangeData={this.handleChange} />
			  	</div>
			  	<div className={`tab-pane fade ${(this.state.activeMode === ldapMode) && 'show active'}`}>
			  		<GroupLDAPForm 
			  			group={this.state.group}
			  			handleChangeData={this.handleChange}
			  			active_directories={this.props.active_directories} />
			  	</div>

				</div>
				<div className="modal-footer row">
					<button type='submit' className="btn btn-primary pointer px-3">บันทึก</button>
					<button type='button' className="btn btn-secondary pointer px-3" onClick={()=>this.props.onClose()}>ปิด</button>
				</div>
			</form>
		)
	}
}

export default GroupForm;