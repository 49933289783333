import React, { Component } from 'react';
import _ from 'lodash';

class TemplateSettingSection extends Component {
  constructor(props){
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.renderTemplate = this.renderTemplate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  renderRow(dt, index){
    return (
      <div key={dt.id} className='form-group'>
        <h5 className='font-weight-bold'>{dt.name}</h5>
        {!_.isEmpty(dt.document_templates) && _.map(dt.document_templates, this.renderTemplate)}
      </div>
    )
  }

  onChange(template, event){
    const value = !event.target.checked;
    this.props.onUpdateDocumentTemplate(template.id, value);
  }

  renderTemplate(t, index){
    const template = _.find(this.props.document_templates, {id: t.id});
    return (
      <div key={t.id}>
        <div className='ml-4'>
          <div className="form-check">
            <label className="form-check-label">
              <input  className="form-check-input mr-2" 
                      type="checkbox" 
                      name="disable"
                      checked={!template.disable}
                      onChange={this.onChange.bind(this, template)} />
              {template.name}
            </label>
          </div>
        </div>
      </div>
    )
  }

  render(){
    return(
      <div>
        <h4>ตั้งค่า เปิด / ปิด การใช้งานเอกสาร</h4>
        {!_.isEmpty(this.props.document_types) && _.map(this.props.document_types, this.renderRow)}
      </div>
    )
  }
}

export default TemplateSettingSection;
