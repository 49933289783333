import React, {Component} from 'react';

import { LeftGarudaEmblem, CenterGarudaEmblem} from './elements/Elements';

export class Header extends Component {
  render(){
    const { document_template } = this.props;
    return (
      <div>
        {document_template.attributes.has_garuda_emblem && (
          <div className='page-header'></div>
        )}

        {(document_template.attributes.has_garuda_emblem && document_template.attributes.garuda_emblem_align === 'left') && (
          <LeftGarudaEmblem />
        )}

        {(document_template.attributes.has_garuda_emblem && document_template.attributes.garuda_emblem_align === 'center') && (
          <CenterGarudaEmblem />
        )}

        {document_template.attributes.has_show_name && (
          <div className='row' style={{position: 'absolute', left: '53%', top: '30mm'}}>
            <div className='col-md-12' style={{position: 'relative', left: '-47%'}}>
              <h1>บันทึกข้อความ</h1>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Header;
