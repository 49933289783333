import React, { Component } from 'react';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';

import LoginSession from '../../stores/LoginSession';
import ForumStore from '../../stores/ForumStore';
import CommentStore from '../../stores/CommentStore';
import { ActionCable } from 'react-actioncable-provider';
import { forumToolbarOptions } from '../../utils/editorToolbarOptions';

class AddCommentForm extends Component {
  constructor(props){
    super(props)

    this.state = {
      value: '',
      comments: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.renderRow = this.renderRow.bind(this);

    this.handleReceivedNotification = this.handleReceivedNotification.bind(this);
  }

  componentDidMount() {
    ForumStore.request({id: this.props.forum.id}).then((resp)=>{
      const forum = _.get(resp.data, 'data', {});
      const comments = _.get(resp.data, 'included.comments', []);
      this.setState({
        forum: forum,
        comments: comments
      })
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleChange(value) {
    this.setState({ value: value });
  }

  handleSave(event){
    event.preventDefault();
    console.log('handleSave');

    const { value } = this.state;
    let self = this;
    CommentStore.save(null, {
      comment: {
        topic_id: this.props.forum.id,
        parent_id: '',
        body: value
      }
    }).then(()=>{
      this.setState({value: ''});
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  renderRow(comment, index){
    return (
      <div className='row forum-content border-bottom py-2' key={comment.id}>
        <div className='col-12 text-black-50 pl-0'>
          {`ตอบโดย ${comment.attributes.post_by} ${comment.attributes.created_at}`}
        </div>
        <div className='col-12 pl-0'>
          {renderHTML(comment.attributes.body)}
        </div>
      </div>
    )
  }

  handleReceivedNotification(response){
    const comments = response.comments;

    this.setState({comments: comments});
  }

  render(){
    const { comments } = this.state;
    return (
      <div>
        <div className='mb-3 mx-3 forum-comment-list'>
          {!_.isEmpty(comments) && _.map(comments, this.renderRow)}
        </div>

        <div className='mb-2'>
          <strong>คำตอบ</strong>
        </div>
        <div className='mx-3'>
          <ActionCable
            key={LoginSession.current.auth_token}  
            channel={{ channel: 'ForumChannel', forum_id: this.props.forum.id }}
            onReceived={this.handleReceivedNotification} />

          <ReactQuill 
            value={this.state.value}
            onChange={this.handleChange}
            modules={{toolbar: forumToolbarOptions}} />
        </div>
        <div className='mt-3'>
          <button type='button' className='btn btn-primary' onClick={this.handleSave}>
            บันทึก
          </button>
        </div>
      </div>
    )
  }
}

export default AddCommentForm;