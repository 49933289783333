import React, {Component} from 'react';
import _ from 'lodash';

import IssueNumberStore from '../stores/IssueNumberStore';

export default class SetNextIssueNumber extends Component {
  constructor(props){
    super(props);

    this.state = {
      next_issue_number: props.next_issue_number,
      number_book_name: props.number_book_name
    }

    this.handleSetNextIssueNumber = this.handleSetNextIssueNumber.bind(this);
    this.handleUpdateNextIssueNumber = this.handleUpdateNextIssueNumber.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.next_issue_number && this.state.next_issue_number !== nextProps.next_issue_number) {
      this.setState({
        next_issue_number: nextProps.next_issue_number,
        number_book_name: nextProps.number_book_name
      });
    }
  }

  handleSetNextIssueNumber(e){
    const next_issue_number = e.target.value;

    this.setState({
      next_issue_number: next_issue_number
    });
  }

  handleUpdateNextIssueNumber(){
    const self = this;
    const next_issue_number = this.state.next_issue_number;
    const number_book_name = this.state.number_book_name;

    IssueNumberStore.request({
      action: 'set_next_issue_number',
      method: 'post',
      data: {
        next_issue_number: next_issue_number,
        number_book_name: number_book_name
      },
    }).then((resp)=>{
      const next_issue_number = _.get(resp.data, 'data.next_number', "");
      const next_full_issue_number = _.get(resp.data, 'data.next_full_issue_number', "");

      self.setState({ 
        next_issue_number: next_issue_number,
      });

      this.props.onUpdatedNextIssueNumber(next_issue_number, next_full_issue_number);
    });
  }

  render(){
    return (
      <div>
        <div className='row'>
          <div className='col-7'>
            <h2>
              {this.props.label} 
              <span className='next-number mx-2'>{`(${this.state.next_issue_number})`}</span>
              <i id="collapseSetNextIssueNumberTrigger" className="fa fa-cog pointer" aria-hidden="true" data-toggle="collapse" href="#collapseSetNextIssueNumber" aria-expanded="false" aria-controls="collapseSetNextIssueNumber"></i>
            </h2>
          </div>
        </div>

        <div id="collapseSetNextIssueNumber" className="row collapse card bg-light mb-3">
          <div className="col-12 card-body">
            <h4>ตั้งค่าเลขส่ง</h4>

            <form className="form-inline">
              <div className="form-group mb-2">
                <label htmlFor="nextIssueNumber">เลขส่งต่อไป คือ:</label>
                <input 
                  type="text" 
                  className="form-control mx-2" 
                  id="nextIssueNumber" 
                  value={this.state.next_issue_number}
                  onChange={this.handleSetNextIssueNumber}
                />
              </div>
              <button type="button" className="btn btn-primary mb-2" onClick={this.handleUpdateNextIssueNumber}>บันทึก</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}