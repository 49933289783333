import React, { Component } from 'react';
import _ from 'lodash';

export default class RestoreDepartmentNumberButton extends Component {
  constructor(props) {
    super(props);
  }

  render(){
    return (
      <div>
        <div className='row mb-2'>
          <div className='col-12'>
            <a href="#" onClick={() => this.props.handleRestoreNumber(this.props.workflow_process_id)} className="mt-3">ย้ายไปหนังสือมาใหม่</a>
          </div>
        </div>
      </div>
    )
  }
}
