import React, { Component } from 'react';
import _ from 'lodash';

import RenderMainGroup from './RenderMainGroup';

import GroupStore from '../../stores/GroupStore';

export default class RenderChildren extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      group: {}
    }

    this.renderGroup = this.renderGroup.bind(this);
    this.reloadData = this.reloadData.bind(this);
  }

  componentWillMount() {
    if (_.includes(this.props.show, this.props.group.id)){
      this.reloadData(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (_.includes(nextProps.show, nextProps.group.id)) {
      if (!_.isEmpty(nextProps.loading_item) && nextProps.loading_item.id === nextProps.group.id) {
        this.setState({loading: true}, this.props.handleShowLoading);
        this.reloadData(nextProps);
      }
    }else{
      this.setState({ group: {} });
    }
  }

  reloadData(props){
    GroupStore.request({id: props.group.id}).then((resp)=>{
      let group = _.get(resp.data, 'data', {});

      this.setState({
        group: group
      })
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  renderGroup(group, index){
    return (
      <RenderMainGroup 
        key={index}
        focus={this.props.focus}
        group={group}
        onFocus={this.props.onFocus} />
    )
  }

  renderChildren(){
    return (
      <ul className='ul-group-list'>
        {!_.isEmpty(this.state.group.attributes.children) && (
          _.map(this.state.group.attributes.children, this.renderGroup)
        )}
      </ul>
    )
  }

  render(){
    var children = null;
    if (!_.isEmpty(this.state.group)){
      children = this.renderChildren();
    }
    return (
      <div>
        {this.state.loading && (<i className="fa fa-spinner mr-3 loading-spin" aria-hidden="true"></i>)}

        {children}
      </div>
    )
  }
}