import React, { Component } from 'react';
import _ from 'lodash';

import { Async } from 'react-select';

import GroupStore from '../stores/GroupStore';

class SearchBox extends Component {
	constructor(props){
		super(props)

		this.state = {
			selectedOption: ''
		}

		this.handleChange = this.handleChange.bind(this);
		this.getOptions = this.getOptions.bind(this);
	}

	handleChange(selectedOption){
		this.setState({ selectedOption });
		this.props.handleFilter(selectedOption);
	}

	getOptions(input, callback){
		if (_.isEmpty(input)){
			GroupStore.request({action: 'filter'}).then((resp)=>{
				const data = _.get(resp.data, 'data', []);
				// setTimeout(() => {
			    callback(null, {
			      options: data,
			      // CAREFUL! Only set this to true when there are no more options,
			      // or more specific queries will not be sent to the server.
			      complete: true
			    });
			  // }, 500);
			});
		}else{
			GroupStore.request({action: `filter?keyword=${input}`}).then((resp)=>{
				const data = _.get(resp.data, 'data', []);

				// setTimeout(() => {
			    callback(null, {
			      options: data,
			      // CAREFUL! Only set this to true when there are no more options,
			      // or more specific queries will not be sent to the server.
			      complete: true
			    });
			  // }, 500);
			});
		}
	}

	render() {
		const { selectedOption } = this.state;
		return (
			<div className='row'>
				<div className='col-md-12'>
		      <Async
				    name="keyword"
				    value={selectedOption}
				    onChange={this.handleChange}
				    loadOptions={this.getOptions} />
				</div>
			</div>
		)
	}
}

export default SearchBox;