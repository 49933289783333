import React, {Component} from 'react';

import edocLogo from '../assets/images/logo.png';

import LoginSession from '../stores/LoginSession';
import ErrorMessage from '../components/ErrorMessage';

const THEME_STORAGE_KEY = 'current_theme';
const CURRENT_THEME = localStorage.getItem(THEME_STORAGE_KEY) || 'light';

export default class LoginPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			error_message: null
		}
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleChange = this.handleChange.bind(this);
	}

  componentDidMount() {
    if(LoginSession.current){
    	window.location.href = '/';
    }

    var edoc_link_url = CURRENT_THEME == "secret" ? "http://edc.dmh.go.th" : "http://edcsecret.dmh.go.th";
    var edoc_link_text = CURRENT_THEME == "secret" ? "ระบบสารบรรณหลัก" : "ระบบทะเบียนหนังสือลับ";
    this.setState({edoc_link_url: edoc_link_url, edoc_link_text: edoc_link_text})
  }

	handleChange(values) {
		this.setState({
			error_message: null
		});
	}

	handleSubmit(event) {
		event.preventDefault()
		var self = this;
		LoginSession.login(this.refs.username.value, this.refs.password.value).then(()=> {
			window.location.href = '/';
		}, function(error) {
			var error_message = error.response.data.errors[0];
			self.setState({error_message: error_message});
		})
	}

	render(){
		return (
			<div id='LoginPage'>
				<div className='row p-4'>
					<div className='ml-auto col-md-10 text-center text-md-left'>
						<h1>ระบบงานสารบรรณอิเล็กทรอนิกส์ {CURRENT_THEME == "secret" ? "(ลับ)" : ""}</h1>
					</div>
				</div>
				<div className='row m-0'>
					<div className='ml-auto col-md-2 text-md-right'>
						&ensp;
					</div>
					<div className='col-md-8'>
						<div className='row'>
							<div className='col-md-2'></div>
							<div className='col-md-9 mr-auto error_message_navigation'>
								{this.state.error_message && <ErrorMessage error_message={this.state.error_message} />}
							</div>
						</div>
					</div>
				</div>
				<div className='row m-0'>
					<div className='ml-auto col-md-3 text-center text-md-right'>
						<img alt='LOGO' src={edocLogo} className='edoc-logo-sm' />
					</div>
					<div className='col-md-7'>
						<form onSubmit={this.handleSubmit}>
							<div className='row form-group'>
								<div className='col-md-2 text-md-right'>
									<strong>ชื่อเข้าใช้</strong>
								</div>
								<div className='col-md-8 col-lg-5'>
									<input 
										type="text" 
										ref="username" 
										placeholder="ชื่อเข้าใช้"
										onChange={this.handleChange}
										className='form-control'
										autoComplete="off" />
								</div>
							</div>

							<div className='row form-group'>
								<div className='col-md-2 text-md-right'>
									<strong>รหัสผ่าน</strong>
								</div>
								<div className='col-md-8 col-lg-5'>
									<input 
										type="password" 
										ref="password" 
										placeholder="รหัสผ่าน"
										onChange={this.handleChange}
										className='form-control'
										autoComplete="off" />
								</div>
							</div>

							<div className='row form-group'>
								<div className='col-md-2'></div>
								<div className='col-md-4'>
									<button type='submit' className='btn btn-primary'>เข้าสู่ระบบ</button>
								</div>
							</div>
							<div className='row form-group'>
								<div className='col-md-2'></div>
								<div className='col-md-4'>
									<a href={this.state.edoc_link_url} className="btn btn-danger switch-service-button">{this.state.edoc_link_text}</a>
								</div>
							</div>

							<div className='row form-group'>
								<div className='col-md-2'></div>
								<div className='col-md-10'>
									
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}