import React, {Component} from 'react';

import { NumberInThai } from '../../../utils/NumberHelper';

export class PageNumber extends Component {
  render(){
    const { content } = this.props;
    const page_number = NumberInThai(content.page_number.toString());
    
    return (
      <div style={{position: 'absolute', left: '50%', top: '10mm'}}>
        <div style={{position: 'relative', left: '-50%'}}>
          - {page_number} -
        </div>
      </div>
    )
  }
}

export default PageNumber;
