import React, {Component} from 'react';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';

import QuillShiftEnter from '../../QuillShiftEnter';
import { thaiVowels } from '../../../utils/index';
import CustomToolbar from '../../CustomToolbar';

const EditingFieldName = 'content';

export default class InlineEditContent extends Component {
	constructor(props){
		super(props)

		this.state = {
  		editing_content: false,
      value: this.props.content,
      rawHtml: this.props.content,
      showRaw: false
  	}

    this.quillRef = null;      // Quill instance
    this.reactQuillRef = null; // ReactQuill component
    this.unprivilegedEditor = null; // ReactQuill component

  	this.handleSetValue = this.handleSetValue.bind(this);
    this.onEditingContent = this.onEditingContent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRaw = this.handleChangeRaw.bind(this);
    this.handleClickShowRaw = this.handleClickShowRaw.bind(this);
    
  	this.attachQuillRefs = this.attachQuillRefs.bind(this);
	}

  componentDidMount() {
    if(this.state.editing_content){
      this.attachQuillRefs()
      if (this.textAreaRef) this.textAreaRef.style.height = this.textAreaRef.scrollHeight + "px";
    }
  }
  
  componentDidUpdate() {
    if(this.state.editing_content){
      this.attachQuillRefs()
      if (this.textAreaRef) this.textAreaRef.style.height = this.textAreaRef.scrollHeight + "px";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading){
      this.setState({
        value: nextProps.content
      })
    }
  }

  attachQuillRefs() {
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef.getEditor();
    this.unprivilegedEditor = this.reactQuillRef.makeUnprivilegedEditor(this.quillRef);

  }

  handleChange(value) {
    this.setState({ value: value })
  }

  handleChangeRaw (html) {
    this.setState({ rawHtml: html })
    if (this.state.showRaw) {
      var r = new RegExp(String.fromCharCode(8195), 'g');
      const v = html.replace(r, "&nbsp;".repeat(4));
      this.setState({value: v});
    }
  }
  
  handleClickShowRaw () {
    const isEditingRaw = this.state.showRaw;
    this.setState({showRaw: !isEditingRaw})
    this.syncViews(isEditingRaw)
  }
  
  syncViews (fromRaw) {
    if (fromRaw) {
      var r = new RegExp(String.fromCharCode(8195), 'g');
      const v = this.state.rawHtml.replace(r, "&nbsp;".repeat(4));
      this.setState({value: v});
    }else{
      this.setState({rawHtml: this.state.value});
    }
  }

	handleSetValue(){
    console.log('handleSetValue');
    const self = this;
    let text = this.quillRef.getText();
    const length = this.unprivilegedEditor.getLength();

    let break_indexs = []
    _.forEach(text, function(t, index){
      var bounds = self.unprivilegedEditor.getBounds(index);
      
      if ( bounds.right === 16 && index !== 0 && !thaiVowels().includes(t.charCodeAt(0)) ) {
        let prev_char = self.quillRef.getText(index - 1, 1);

        if (prev_char.charCodeAt(0) !== 10) {
          break_indexs.push(index)
        }
      }
    })

    _.forEach(break_indexs.reverse(), function(i){
      self.quillRef.insertEmbed(i, 'break', true, 'user');
    })

    const val = this.unprivilegedEditor.getHTML();
    const { page_number } = this.props;
		this.setState({editing_content: false, value: val}, this.props.handleChange(EditingFieldName, val, page_number));
	}

	onEditingContent(){
  	if (this.state.editing_content){
    		this.setState({editing_content: false});
  	}else{
    		this.setState({editing_content: true, showRaw: false});
  	}
	}

	stripHTML(text){
		if (_.isEmpty(text)) { return }

		var regex = /(<([^>]+)>)/ig;
 		return text.replace(regex, "");
	}

	render(){
		var content = this.stripHTML(this.props.content);
		return (
			<div className='row pt-3'>
				<div className='col-12'>
          <div id='blur-editor'></div> 
					{this.state.editing_content ? (
						<div id='editing_content_id'>
              <div className={ this.state.showRaw ? "showRaw" : ""}>
                <CustomToolbar onClickRaw={this.handleClickShowRaw} />
                <ReactQuill 
                  ref={(el) => { this.reactQuillRef = el }}
                  value={this.state.value}
                  onChange={this.handleChange}
                  modules={QuillShiftEnter.optionsConfig} />
                  
                <div className="raw-html">
                  <textarea
                    ref={textAreaRef=>this.textAreaRef = textAreaRef}
                    className={"raw-editor"}
                    onChange={(e) => this.handleChangeRaw(e.target.value)}
                    value={this.state.rawHtml.replace(/(&nbsp;){4}/g, String.fromCharCode(8195))} />
                </div>

  			        <div className='mt-2'>
  			        	<button 
                    id='submit_edit_content'
  				        	type='button' 
  				        	className='btn btn-primary'
  				        	onClick={this.handleSetValue}>
  				        	ตกลง
  				        </button>
					      </div>
              </div>
				    </div>
					) : (
						<div id='show_content_id'>
							{content ? (
								<div className='pointer editing_content' onClick={this.onEditingContent}>
									{renderHTML(this.props.content.replace(/<br>/gi, ' '))}
								</div>
							) : (
								<div className='text-danger ml-5 pointer' onClick={this.onEditingContent}>
									<em>{this.props.suggest_text}</em>
								</div>
							)}	
						</div>
					)}
				</div>
			</div>
		)
	}
}

document.addEventListener('click', function(event){
  var editing_content_id = document.getElementById('editing_content_id');
  var show_content_id = document.getElementById('show_content_id');

  if (show_content_id){
    if (show_content_id.contains(event.target)){ return }
  }

  if (editing_content_id){
    if (!editing_content_id.contains(event.target)){
      document.getElementById('submit_edit_content').click();
    }
  }
});