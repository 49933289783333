import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import 'react-datepicker/dist/react-datepicker.css';
import AfterSaveLoading from '../AfterSaveLoading';
import './elements/Elements.css';
import arrayMove from 'array-move';

import DocumentParameters from './DocumentParameters';
import DocumentUploadFileForm from './DocumentUploadFileForm';
import DocumentForm from './DocumentForm';
import DocumentBody from './DocumentBody';
import ActorForm from './ActorForm';
import FooterButtons from './elements/FooterButtons';
import DocumentStore from '../../stores/DocumentStore';

const NormalLevel = 'ปกติ';

class Document extends Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      document: {
        id: this.props.document_id,
        document_template_id: this.props.document_template.id,
        speed_level: NormalLevel,
        secret_level: NormalLevel,
        due_date: moment(),
        subject: '',
        number_issuer_id: '',
        is_circular: false,
        references: [],
        informs: [],
        files: [],
        signees: [],
        throughs: [],
        draft_by_department_id: '',
        has_front_page: false
      },
      original_document_files: [],
      document_attributes: this.props.document_template.attributes.template_attributes || {},
      department_groups: [],
      all_users: [],
      internal_users: [],
      number_issuers: [],
      internal_number_issuers: [],
      central_number_issuers: [],
      informees: [],
      inform_groups: [],
      reference_documents: [],
      actings: [],
      selected_acting: [],
      selected_acting_type: "",
      actor_positions_users: [],
      current_user_departments: [],
      open_actor_form: false
    }

    this.handleChangeAttributeValue = this.handleChangeAttributeValue.bind(this);
    this.onDeleteRefDoc = this.onDeleteRefDoc.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleUpdateFiles = this.handleUpdateFiles.bind(this);
    this.onDeleteFile = this.onDeleteFile.bind(this);
    this.handleDeleteNumberIssuer = this.handleDeleteNumberIssuer.bind(this);
    this.handleDeleteSignee = this.handleDeleteSignee.bind(this);
    this.handleDeleteThrough = this.handleDeleteThrough.bind(this);
    this.handleShowActorsForm = this.handleShowActorsForm.bind(this);
    this.handleSelectActor = this.handleSelectActor.bind(this);
    this.handleCloseActorForm = this.handleCloseActorForm.bind(this);
    this.handleChangeSigneePositionName = this.handleChangeSigneePositionName.bind(this);
    this.handleChangeContent = this.handleChangeContent.bind(this);
    
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleNewPage = this.handleNewPage.bind(this);
    this.onDeletePage = this.onDeletePage.bind(this);

    this.handleChangeOriginalDoc = this.handleChangeOriginalDoc.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onRemoveOriginalDocument = this.onRemoveOriginalDocument.bind(this);
    this.handleInsert = this.handleInsert.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
  }

  componentDidMount(){
    // new document
    let url = 'documents/new';

    if (!_.isEmpty(this.props.clone_document_id)){
      url = `documents/new?clone_document_id=${this.props.clone_document_id}`;
    }

    // edit document
    if (!_.isEmpty(this.props.document_id)){
      url = `documents/${this.props.document_id}/edit`
    }

    DocumentStore.request({url: url}).then((resp)=>{
      var document_object = _.get(resp.data, 'data', {});
      var { document } = this.state;
      var document_attributes = {};
      var original_document_files = [];

      if (!_.isEmpty(document_object)){
        document.due_date = !_.isEmpty(document_object.attributes.due_date) ? moment(document_object.attributes.due_date) : moment();
        document.speed_level = document_object.attributes.speed_level || NormalLevel;
        document.secret_level = document_object.attributes.secret_level || NormalLevel;
        document.subject = document_object.attributes.subject || '';
        document.number_issuer_id = _.get(document_object.attributes, 'number_issuer.id', '');
        document.is_circular = document_object.attributes.is_circular || false;
        document.has_front_page = document_object.attributes.has_front_page || false;
        document.references = document_object.attributes.reference_documents || [];
        document.informs = document_object.attributes.informs || [];
        document.files = document_object.attributes.attachments || [];
        document.signees = document_object.attributes.signees || [];
        document.throughs = document_object.attributes.throughs || [];
        document.draft_by_department_id = _.get(document_object.attributes, 'draft_by_department.id', '');

        document_attributes = document_object.attributes.document_attributes || {};
        original_document_files = _.get(document_object, 'attributes.original_documents', []);
      }else{
        document_attributes = this.props.document_template.attributes.template_attributes || {};
      }

      var department_groups = _.get(resp.data, 'included.department_groups', []);
      var all_users = _.get(resp.data, 'included.all_users', []);
      var internal_users = _.get(resp.data, 'included.internal_users', []);
      var number_issuers = _.get(resp.data, 'included.number_issuers', []);
      var internal_number_issuers = _.get(resp.data, 'included.internal_number_issuers', []);
      var central_number_issuers = _.get(resp.data, 'included.central_number_issuers', []);
      var informees = _.get(resp.data, 'included.informees', []);
      var inform_groups = _.get(resp.data, 'included.inform_groups', []);
      var reference_documents = _.get(resp.data, 'included.reference_documents', []);
      var actings = _.get(resp.data, 'included.actings', []);
      var current_user_departments = _.get(resp.data, 'included.current_user_departments', []);

      this.setState({
        document: document,
        original_document_files: original_document_files,
        document_attributes: document_attributes,
        department_groups: department_groups,
        all_users: all_users,
        internal_users: internal_users,
        number_issuers: number_issuers,
        internal_number_issuers: internal_number_issuers,
        central_number_issuers: central_number_issuers,
        informees: informees,
        inform_groups: inform_groups,
        reference_documents: reference_documents,
        actings: actings,
        current_user_departments: current_user_departments
      })
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  handleSave(saveType){
    const self = this;
    this.setState({loading: true});

    var data = new FormData();

    data = DocumentParameters._assign_attachments(data, this.state);
    data = DocumentParameters._assign_document_attributes(data, this.state);
    data = DocumentParameters._assign_signees(data, this.state);
    data = DocumentParameters._assign_throughs(data, this.state);
    data = DocumentParameters._assign_references(data, this.state);
    data = DocumentParameters._assign_informs(data, this.state);

    if (!_.isEmpty(this.state.original_document_files)){
      _.forEach(this.state.original_document_files, function(file){
        if (file.file){
          data.append('original_document_files[][id]', file['id'] || '');
          data.append('original_document_files[][file]', file.file);
        }
      })
    }

    data.append('document[subject]', this.state.document.subject || '')
    data.append('document[due_date]', this.state.document.due_date || '')
    data.append('document[document_template_id]', this.state.document.document_template_id || '')
    data.append('document[number_issuer_id]', this.state.document.number_issuer_id || '')
    data.append('document[speed_level]', this.state.document.speed_level || NormalLevel)
    data.append('document[secret_level]', this.state.document.secret_level || NormalLevel)
    data.append('document[is_circular]', this.state.document.is_circular || false)
    data.append('document[has_front_page]', this.state.document.has_front_page || false)

    const { current_user_departments, document } = this.state;

    let draft_by_department_id = '';
    if (document.draft_by_department_id){
      draft_by_department_id = document.draft_by_department_id
    }

    if (!draft_by_department_id && !_.isEmpty(current_user_departments)){
      draft_by_department_id = _.first(current_user_departments).id;
    }

    data.append('document[draft_by_department_id]', this.state.document.draft_by_department_id || draft_by_department_id);

    DocumentStore.save(this.state.document.id, data).then((resp)=>{
      this.setState({loading: false});
      let document = _.get(resp.data, 'data', {});

      let workflow_process_id = _.get(document, 'attributes.workflow_process.id');
      let action = 'show';
      if (saveType === 'draft') { action = 'edit'; }

      if (saveType === "preview"){
        this.props.history.push({
          pathname: `/templates/documents/${document.id}/${action}/${document.attributes.document_template.id}/wp/${workflow_process_id}`,
          search: qs.stringify({ preview: "true" })
        });
      }else{
        this.props.history.push({
          pathname: `/templates/documents/${document.id}/${action}/${document.attributes.document_template.id}/wp/${workflow_process_id}`
        });
      }
    }, function(error){
      self.setState({loading: false});
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    })
  }

  onSortEnd({oldIndex, newIndex}){
    let { original_document_files } = this.state;
    original_document_files = arrayMove(original_document_files, oldIndex, newIndex);
    this.setState({original_document_files: original_document_files});
  }

  handleChangeOriginalDoc(event){
    var original_file = event.target.files[0];
    if (original_file.size === 0) { return }
    if (original_file.type !== "application/pdf"){
      alert("แนบไฟล์-เอกสารต้นฉบับ จำกัดประเภทเฉพาะไฟล์นามสกุล .pdf");
      return
    }

    const new_file = { file: original_file };
    let { original_document_files } = this.state;
    original_document_files = _.concat(original_document_files, [new_file]);

    this.setState({original_document_files: original_document_files});
  }

  handleInsert(files){
    console.log('files ==>', files)
    this.setState({
      original_document_files: files
    });
  }

  handleReplace(files){
    this.setState({
      original_document_files: files
    });
  }

  onRemoveOriginalDocument(index){
    let { original_document_files } = this.state;
    original_document_files.splice(index, 1);

    this.setState({
      original_document_files: original_document_files
    });
  }

  handleChangeAttributeValue(field_name, value){
    console.log('attr field_name --->', field_name)
      console.log('attr value --->', value)
      var { document_attributes } = this.state;
      document_attributes[field_name].value = value;
      this.setState({
         document_attributes: document_attributes
      });
  }

  handleChangeContent(field_name, value, page_number){
    var { document_attributes } = this.state;
    var content = _.find(document_attributes.contents, {page_number: page_number});
    content.value = value;

    this.setState({document_attributes: document_attributes});
  }

  handleChangeEndDate(date){
    let { document } = this.state;
    document.due_date = date;

    this.setState({
          document: document
      });
  }

  onDeleteRefDoc(index){
      if (!window.confirm('ยืนยันการลบ')) { return }

      var { document } = this.state;
      document.references.splice(index, 1);

      this.setState({
        document: document
      });
  }

  handleUpdateFiles(files){
      var { document } = this.state;
      document.files = _.concat(document.files, files);

      this.setState({
        document: document
      });
  }

  onDeleteFile(index){
      if (!window.confirm('ยืนยันการลบไฟล์')) { return }
        
      var { document } = this.state;
      var files = document.files;
      files.splice(index, 1);

      document.files = files;
      this.setState({
        document: document
      });
  }

  handleDeleteNumberIssuer(){
    if (!window.confirm('ยืนยันการลบผู้ออกเลข')) { return }

    var { document } = this.state;
    document.number_issuer_id = null;

    this.setState({
       document: document
    });
  }

  handleDeleteSignee(signee){
    if (!window.confirm('ยืนยันการลบผู้ลงนาม')) { return }

    var { document } = this.state;
    let signees = [...document.signees];
    _.remove(signees, function(signee_obj) {
        return signee_obj.user_id === signee.user_id && signee_obj.position_id === signee.position_id
    });

    document.signees = signees;
    if (_.isEmpty(document.signees)) { document.throughs = []; }

    this.setState({
       document: document
    });
  }

  handleDeleteThrough(through){
    if (!window.confirm('ยืนยันการลบผู้ส่งผ่าน')) { return }

    var { document } = this.state;
    let throughs = document.throughs;
    _.remove(throughs, function(through_obj) {
      return through_obj.user_id === through.user_id && through_obj.position_id === through.position_id
    });

    document.throughs = throughs;
    this.setState({
       document: document
    });
  }

  handleShowActorsForm(object, type){
    const {user_id} = object;

    const acting =  _.find(this.state.actings, (acting) => {
                      return acting.attributes.user.id === user_id
                    });

    const actor_positions_users = acting.attributes.actor_positions_users;
    this.setState({
      open_actor_form: true,
      selected_acting: acting,
      selected_acting_type: type,
      actor_positions_users: actor_positions_users
    });
  }

  handleSelectActor(row, isSelected, e){
    var { document, selected_acting, selected_acting_type } = this.state;
    
    if(selected_acting_type === "signee"){
      let signees = [...document.signees, {position_id: row.position_id, user_id: row.user_id, position_name: row.position_name}];
      signees = _.reject(signees, { user_id: selected_acting.attributes.user.id })
      document.signees = signees;
    } 

    if(selected_acting_type === "through"){
      let throughs = [...document.throughs, {position_id: row.position_id, user_id: row.user_id, position_name: row.position_name}];
      throughs = _.reject(throughs, { user_id: selected_acting.attributes.user.id })
      document.throughs = throughs;
    }

    if(selected_acting_type === "number_issuer"){
      document.number_issuer_id = row.positions_user_id;
    }

    this.setState({
      document: document,
      open_actor_form: false,
      selected_acting_type: "",
      actor_positions_users: []
    });
  }

  handleCloseActorForm(){
    this.setState({
      open_actor_form: false,
      selected_acting_type: "",
      actor_positions_users: []
    });
  }

  handleUpdate(field_name, value){
      console.log('field_name --->', field_name)
      console.log('value --->', value)

      var { document } = this.state;
      document[field_name] = value;

      if(field_name === 'signees' && !_.isEmpty(value)){
        const signee = value[0];
        const user_attributes = _.map(this.state.all_users, 'attributes');

        if(signee !== undefined){
          const user = _.find(user_attributes, {user_id: signee.user_id, position_id: signee.position_id});
        
          if(user === undefined){
            document['signees'] = []
            alert("ไม่พบผู้ลงนาม โปรดตรวจสอบผังองค์กร");
          }
        }
      }

      this.setState({
        document: document
      });
  }

  handleChangeSigneePositionName(signee, mode, position_name){
    var { document } = this.state;
    var signees = document.signees;
    var throughs = document.throughs;
    var s = null;

    if (mode === "signee"){
      s = _.find(signees, {user_id: signee.user_id, position_id: signee.position_id})
    }

    if (mode === "through"){
      s = _.find(throughs, {user_id: signee.user_id, position_id: signee.position_id})
    }

    if (!_.isEmpty(s)) {
      s.position_name = position_name
    }

    this.setState({
      document: document
    });
  }

  handleNewPage(){
    var { document_attributes } = this.state;
    const contents_size = _.size(document_attributes.contents);

    const page_content = {
      page_number: (contents_size + 1),
      title: "",
      name: "content",
      can_inline_edit: true,
      suggest_text: "เขียนเนื้อความ",
      value: ""
    }
    document_attributes.contents = _.concat(document_attributes.contents, page_content)
    console.log('contents size', document_attributes.contents)

    this.setState({document_attributes: document_attributes});
  }

  onDeletePage(page_number){
    var { document_attributes } = this.state;

    _.remove(document_attributes.contents, function(content){
      return parseInt(content.page_number) === parseInt(page_number)
    })

    if (_.size(document_attributes.contents) > 1) {
      // Re-order content's page number
      _.forEach(document_attributes.contents, function(value, index) {
        value.page_number = index + 1
      });
    }

    this.setState({document_attributes: document_attributes});
  }

  NormalForm(){
    const { document_template } = this.props;
    return (
      <div id='Document' className='px-3 py-5'>
        {this.state.loading && <AfterSaveLoading />}

        <div className='row'>
          <DocumentForm 
            document_template={document_template}
            document_attributes={this.state.document_attributes}
            document={this.state.document}
            all_users={this.state.all_users}
            internal_users={this.state.internal_users}
            internal_number_issuers={this.state.internal_number_issuers}
            central_number_issuers={this.state.central_number_issuers}
            number_issuers={this.state.number_issuers}
            department_groups={this.state.department_groups}
            campus_department_groups={this.state.campus_department_groups}
            informees={this.state.informees}
            inform_groups={this.state.inform_groups}
            reference_documents={this.state.reference_documents}
            actings={this.state.actings}
            current_user_departments={this.state.current_user_departments}
            handleUpdate={this.handleUpdate}
            handleChangeEndDate={this.handleChangeEndDate}
            handleChangeAttributeValue={this.handleChangeAttributeValue}
            onDeleteRefDoc={this.onDeleteRefDoc}
            handleUpdateFiles={this.handleUpdateFiles}
            onDeleteFile={this.onDeleteFile}
            handleDeleteNumberIssuer={this.handleDeleteNumberIssuer}
            handleDeleteSignee={this.handleDeleteSignee}
            handleDeleteThrough={this.handleDeleteThrough}
            onShowActorsForm={this.handleShowActorsForm} />

          <DocumentBody 
            document_template={document_template}
            document={this.state.document}
            document_attributes={this.state.document_attributes}
            all_users={this.state.all_users}
            number_issuers={this.state.number_issuers}
            reference_documents={this.state.reference_documents}
            loading={this.state.loading}
            handleChangeAttributeValue={this.handleChangeAttributeValue}
            handleChangeContent={this.handleChangeContent}
            handleChange={this.handleChangeSigneePositionName}
            onDeletePage={this.onDeletePage} />

          <ActorForm  actor_positions_users={this.state.actor_positions_users}
                      open={this.state.open_actor_form}
                      onSelectActor={this.handleSelectActor}
                      onClose={this.handleCloseActorForm} />
        </div>
        <FooterButtons 
          document_template={document_template} 
          handleSave={this.handleSave} 
          handleNewPage={this.handleNewPage}
          document_id={this.props.document_id} />
      </div>
    )
  }

  UploadFileForm(){
    const { document_template } = this.props;
    return (
      <div id='UploadFileForm' className='row py-5'>
        <div className='col-10 ml-auto mr-auto'>
          {this.state.loading && <AfterSaveLoading />}

          <DocumentUploadFileForm 
              document_template={document_template}
              document_attributes={this.state.document_attributes}
              document={this.state.document}
              all_users={this.state.all_users}
              internal_users={this.state.internal_users}
              internal_number_issuers={this.state.internal_number_issuers}
              central_number_issuers={this.state.central_number_issuers}
              number_issuers={this.state.number_issuers}
              department_groups={this.state.department_groups}
              campus_department_groups={this.state.campus_department_groups}
              informees={this.state.informees}
              inform_groups={this.state.inform_groups}
              reference_documents={this.state.reference_documents}
              actings={this.state.actings}
              current_user_departments={this.state.current_user_departments}
              handleUpdate={this.handleUpdate}
              handleChangeEndDate={this.handleChangeEndDate}
              handleChangeAttributeValue={this.handleChangeAttributeValue}
              onDeleteRefDoc={this.onDeleteRefDoc}
              handleUpdateFiles={this.handleUpdateFiles}
              onDeleteFile={this.onDeleteFile}
              handleDeleteNumberIssuer={this.handleDeleteNumberIssuer}
              handleDeleteSignee={this.handleDeleteSignee}
              handleDeleteThrough={this.handleDeleteThrough}
              onShowActorsForm={this.handleShowActorsForm}
              original_document_files={this.state.original_document_files}
              handleChangeOriginalDoc={this.handleChangeOriginalDoc}
              onRemoveOriginalDocument={this.onRemoveOriginalDocument}
              handleInsert={this.handleInsert}
              handleReplace={this.handleReplace}
              onSortEnd={this.onSortEnd} />

          <FooterButtons  
            document_template={document_template}
            handleSave={this.handleSave} 
            handleNewPage={this.handleNewPage}
            document_id={this.props.document_id} />
        </div>
      </div>
    )
  }

  render() {
    const { document_template } = this.props;

    let form = this.NormalForm();
    if (document_template.attributes.is_upload_file) {
      form = this.UploadFileForm();
    }
    return form
  }
}

export default withRouter(Document);