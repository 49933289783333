import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import { MergeAttributes } from '../../components/FlatData';

class StateForm extends Component {
  constructor(){
    super();

    this.renderRowActions = this.renderRowActions.bind(this);
    this.renderGotoState = this.renderGotoState.bind(this);
  }

  renderGotoState(cell, row){
    const goto_state = _.find(this.props.states, { id: row.goto_state.id })

    return (
      <div>{goto_state.attributes.name}</div>
    )
  }

  renderRowActions(cell, row){
    return (
      <div>
        <button className="btn btn-success" onClick={() => this.props.editActionRow(row)}>
          <i className="fa fa-cog"></i>{' '}Edit
        </button>
        {' '}
        <button className="btn btn-danger" onClick={() => this.props.deleteActionRow(row)}>
          <i className="fa fa-trash"></i>{' '}Delete
        </button>
      </div>
    )
  }

  _setTableOption(){ 
    return "This state has no action yet.";
  }

  render(){
    const state = this.props.state;
    const options = {
      sizePerPageList: [],
      noDataText: this._setTableOption()
    };
    
    var actionData = [];
    if(state.actions){
      const action_ids = _.map(state.attributes.actions, 'id');
      const actions = _.filter(this.props.actions, function(action) { return _.includes(action_ids, action.id); })
      actionData = MergeAttributes(actions);
    }

    return(
      <div id="state-form">
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
          <div className="col-sm-10">
            <input type='text' name='name' value={state.name} onChange={this.props.setEdittingStateAttributes} className='form-control form-control-sm' />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="description" className="col-sm-2 col-form-label">Description</label>
          <div className="col-sm-10">
            <textarea name='description' value={state.description} onChange={this.props.setEdittingStateAttributes} className='form-control form-control-sm'>
            </textarea>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <h5>Actions</h5>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-primary" onClick={this.props.onAddNewAction}>
              Add new action
            </button>
          </div>
        </div>
        
        <div className="row mt-3">
          <div className="col-12">
            <BootstrapTable 
              data={actionData}
              search={ true }
              multiColumnSearch={ true }
              options={ options }
              striped
              hover
              version='4'
              tableContainerClass={'table-sm'}>
              <TableHeaderColumn  isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn  dataField='name' width='200' dataSort={ true }>Name</TableHeaderColumn>
              <TableHeaderColumn  dataField='actions' 
                                  dataSort={ true }
                                  dataFormat={this.renderGotoState}>
                                  Goto State
              </TableHeaderColumn>
              <TableHeaderColumn  dataField='id'
                                  width='200' 
                                  dataFormat={this.renderRowActions}>
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    )
  }
}

export default StateForm;
