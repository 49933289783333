import React, {Component} from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import moment from 'moment';

import { speedLevelList, secretLevelList } from '../../utils/documentType';
import AfterSaveLoading from '../../components/AfterSaveLoading';

import DocumentStore from '../../stores/DocumentStore';
import ReceiveNumberStore from '../../stores/ReceiveNumberStore';
import DocumentParameters from '../../components/documents/DocumentParameters';
import ReceiveDocumentForm from '../../components/receive_documents/ReceiveDocumentForm';

const NormalLevel = 'ปกติ';

export default class EditSendDocumentPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      open: false,
      is_send: true,
      workflow_process: {},
      document_id: props.match.params.id,
      document_template_id: '',
      has_front_page: false,
      due_date: moment(),
      government_service: '',
      issue_number: '',
      origin_issue_number_id: '',
      topic: '',
      owner: '',
      is_circular: false,
      execute: '',
      keywords: '',
      attachment: '',
      document_date: moment(),
      speed_level: NormalLevel,
      secret_level: NormalLevel,
      original_document_files: [],
      inform_text: '',
      selected_group_ids: [],
      external_department: '',
      files: [],
      document_templates: [],
      department_groups: [],
      reference_issue_numbers: "",
      next_issue_number: "",
      next_full_issue_number: "",
      number_book_name: "ทั่วไป",
      root_group: {},
      all_groups: [],
      position_name_list: [],
      saved_topics: [],
      saved_from_departments: [],
      is_custom: true
    }

    this.handleChangeReceiveDate = this.handleChangeReceiveDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDueDate = this.handleChangeDueDate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeOriginalDoc = this.handleChangeOriginalDoc.bind(this);
    this.handleUpdateFiles = this.handleUpdateFiles.bind(this);
    this.onDeleteFile = this.onDeleteFile.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onRemoveOriginalDocument = this.onRemoveOriginalDocument.bind(this);
    this.handleInsert = this.handleInsert.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
    this.handleChangeSpeedLevel = this.handleChangeSpeedLevel.bind(this);
    this.handleChangeSecretLevel = this.handleChangeSecretLevel.bind(this);
    this.handleChangeInformText = this.handleChangeInformText.bind(this);
    this.handleChangeFileFromScanner = this.handleChangeFileFromScanner.bind(this);
    this.handleChangeIssueNumber = this.handleChangeIssueNumber.bind(this);
    this.handleChangeSavedField = this.handleChangeSavedField.bind(this);

    this.batchSelectGroups = this.batchSelectGroups.bind(this);
    this.batchRemoveGroups = this.batchRemoveGroups.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.handleChangeGroup = this.handleChangeGroup.bind(this);
    this.removeGroup = this.removeGroup.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  componentDidMount(){
    DocumentStore.request({url: `documents/edit_send/${this.props.match.params.id}/edit`}).then((resp)=>{
      const workflow_process = _.get(resp.data, 'data', {});
      const document_template = _.get(resp.data, 'included.document_template', []);
      const department_groups = _.get(resp.data, 'included.department_groups', []);
      const root_group = _.get(resp.data, 'included.root_group', {});
      const all_groups = _.get(resp.data, 'included.all_groups', []);
      const position_name_list = _.get(resp.data, 'included.position_name_list', []);
      const saved_topics = _.get(resp.data, 'included.saved_topics', []);
      const saved_from_departments = _.get(resp.data, 'included.saved_from_departments', []);

      const government_service = _.get(workflow_process, 'attributes.document_attributes.government_service.value', '');
      const attachment = _.get(workflow_process, 'attributes.document_attributes.attachment.value', '');
      const document_date = !_.isEmpty(workflow_process.attributes.document_date) ? moment(workflow_process.attributes.document_date) : moment();
      const original_document_files = _.get(workflow_process, 'attributes.original_documents', []);
      const files = _.get(workflow_process, 'attributes.attachments', []);
      const has_front_page = _.get(workflow_process, 'attributes.has_front_page', false);

      this.setState({
        workflow_process: workflow_process,
        due_date: !_.isEmpty(workflow_process.attributes.due_date) ? moment(workflow_process.attributes.due_date) : moment(),
        has_front_page: has_front_page,
        government_service: government_service,
        issue_number: workflow_process.attributes.issue_number || '',
        topic: workflow_process.attributes.topic || '',
        inform_text: workflow_process.attributes.informs[0].name,
        is_circular: workflow_process.attributes.is_circular || false,
        owner: workflow_process.attributes.owner || '',
        execute: workflow_process.attributes.execute || '',
        keywords: workflow_process.attributes.keywords || '',
        attachment: attachment,
        document_date: document_date,
        speed_level: workflow_process.attributes.speed_level || NormalLevel,
        secret_level: workflow_process.attributes.secret_level || NormalLevel,
        reference_issue_numbers: workflow_process.attributes.reference_issue_numbers || '',
        original_document_files: original_document_files,
        files: files,
        document_template: document_template,
        department_groups: department_groups,
        root_group: root_group,
        all_groups: all_groups,
        position_name_list: position_name_list,
        saved_topics: saved_topics,
        saved_from_departments: saved_from_departments
      })
    }).then(()=>{
      this.setState({loading: false});
    });
  }

  onKeyPress(e){
    if(e.which === 13) {
      this.handleSave();
    }
  }

  batchSelectGroups(department_id){
    const department = _.find(this.state.all_groups, { id: department_id });
    const group_ids = _.map(department.attributes.children, 'id');
    const selected_group_ids = _.concat(this.state.selected_group_ids, group_ids);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  batchRemoveGroups(department_id){
    const department = _.find(this.state.all_groups, { id: department_id });
    const group_ids = _.map(department.attributes.children, 'id');
    const selected_group_ids = _.pullAll(this.state.selected_group_ids, group_ids);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  selectGroup(group_id){
    const selected_group_ids = _.concat(this.state.selected_group_ids, group_id);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  removeGroup(group_id){
    const selected_group_ids = _.pull(this.state.selected_group_ids, group_id);

    this.setState({
      selected_group_ids: selected_group_ids
    });
  }

  handleChangeGroup(event){
    const field_name = event.target.name;
    const value = event.target.value;

    this.setState({
      [field_name]: value
    })
  }

  onSortEnd({oldIndex, newIndex}){
    this.setState(({original_document_files}) => ({
      original_document_files: arrayMove(this.state.original_document_files, oldIndex, newIndex),
    }));
  }

  handleSave(){
    var data = new FormData();
    const { document_template } = this.state;
    const workflow_process_id = _.get(this.state.workflow_process, 'id', '');
    var document_attributes = _.get(document_template, 'attributes.template_attributes', {});

    document_attributes.government_service.value = this.state.government_service;
    document_attributes.topic.value = this.state.topic;
    document_attributes.issue_number.value = this.state.issue_number;

    let attachment_obj = {
      attachment: {
        title: 'สิ่งที่ส่งมาด้วย',
        name: 'attachment',
        can_inline_edit: false,
        suggest_text: '',
        value: this.state.attachment
      }
    }
    _.assign(document_attributes, attachment_obj);
    delete(document_attributes.content);

    data = DocumentParameters._map_document_attributes(data, document_attributes);

    data.append('workflow_process[due_date]', this.state.due_date || '');
    data.append('workflow_process[execute]', this.state.execute || '');
    data.append('workflow_process[keywords]', this.state.keywords || '');
    data.append('workflow_process[document_date]', this.state.document_date || '');
    data.append('workflow_process[speed_level]', this.state.speed_level || '');
    data.append('workflow_process[secret_level]', this.state.secret_level || '');
    data.append('workflow_process[owner]', this.state.owner || '');

    data.append('[informs][][inform_text]', this.state.inform_text || '');
    data.append('is_custom', this.state.is_custom); // default is true for edit send document

    // can edit issue number
    data.append('document[issue_number]', this.state.issue_number || '');

    // อ้างถึงเลขที่หนังสือ
    data.append('document[reference_issue_numbers]', this.state.reference_issue_numbers || '');

    if (!_.isEmpty(this.state.original_document_files)){
      _.forEach(this.state.original_document_files, function(file){
        if (file.file){
          data.append('original_document_files[][id]', file['id'] || '');
          data.append('original_document_files[][file]', file.file);
        }
      })
    }

    if (!_.isEmpty(this.state.files)){
      _.forEach(this.state.files, function(file){
        if (!_.isEmpty(file)){
          data.append('attachments[][id]', file['id'] || '');
          data.append('attachments[][file]', file.file);
        }
      })
    }

    DocumentStore.request({
      id: this.props.match.params.id,
      method: 'put',
      action: `update_workflow_process/${workflow_process_id}`,
      data: data
    }).then(()=>{
      this.props.history.go(-2)
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    });
  }

  handleChangeReceiveDate(date){
    this.setState({document_date: date});
  }

  handleChangeDueDate(date){
    this.setState({due_date: date});
  }

  handleChangeSpeedLevel(speed_level){
    this.setState({speed_level: speed_level});
  }

  handleChangeSecretLevel(secret_level){
    this.setState({secret_level: secret_level});
  }

  handleChange(event){
    const field_name = event.target.name;
    var value = event.target.value;

    if (_.includes(['is_circular', 'has_front_page'], field_name)){
      value = event.target.checked;
    }

    this.setState({
      [field_name]: value
    })
  }

  handleChangeOriginalDoc(event){
    var original_file = event.target.files[0];
    if (original_file.size === 0) { return }
    if (original_file.type !== "application/pdf"){
      alert("แนบไฟล์-เอกสารต้นฉบับ จำกัดประเภทเฉพาะไฟล์นามสกุล .pdf");
      return
    }

    const new_file = {
      file: original_file
    }

    let { original_document_files } = this.state;

    original_document_files = _.concat(original_document_files, [new_file]);

    this.setState({
      original_document_files: original_document_files
    });
  }

  handleInsert(files){
    this.setState({
      original_document_files: files
    });
  }

  handleReplace(files){
    this.setState({
      original_document_files: files
    });
  }

  onRemoveOriginalDocument(index){
    let { original_document_files } = this.state;
    original_document_files.splice(index, 1);

    this.setState({
      original_document_files: original_document_files
    });
  }

  onClose(){
    this.setState({open: false});
  }

  handleChangeInformText(value){
    this.setState({inform_text: value});
  }

  handleUpdateFiles(new_files){
    if (_.isEmpty(new_files)) { return }

    var { files } = this.state;
    _.map(new_files, function(file, index){
      if (!_.isEmpty(file)){
        files = _.concat(files, file);
      }
    })
    
    this.setState({
      files: files
    });
  }

  onDeleteFile(index){
      if (!window.confirm('ยืนยันการลบไฟล์')) { return }
        
      var { files } = this.state;
      files.splice(index, 1);

      this.setState({
        files: files
      });
  }

  urltoFile(url, filename, mimeType){
    return (fetch(url)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], filename, {type:mimeType});})
    );
  }

  handleChangeFileFromScanner(event){
    let self = this;

    const filename = event.target.getAttribute('data-filename');

    this.urltoFile(event.target.value, filename, 'application/pdf')
    .then(function(file){
        const new_file = { file: file };
        let { original_document_files } = self.state;
        original_document_files = _.concat(original_document_files, [new_file]);

        self.setState({
          original_document_files: original_document_files
        });
    })
  }

  handleChangeIssueNumber(event){
    this.setState({
      issue_number: event.target.value,
      next_full_issue_number: event.target.value,
      is_custom: true
    });
  }

  handleChangeSavedField(field, value){
    this.setState({ 
      [field]: value
     });
  }

  render(){
    const { document_templates, issue_number } = this.state;
    const informs = [{name: this.state.inform_text}];
    return (
      <div>
        {this.state.loading && <AfterSaveLoading />}  

        <div className='row form-group'>
          <div className='col-md-6'>
          <h3>แก้ไขเอกสาร</h3> 
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-2 required'><b>ที่</b></div>
          <div className='col-12 col-md-4'>
            <input 
              type="text" 
              value={issue_number} 
              onChange={this.handleChangeIssueNumber} 
              className="form-control form-control-sm" 
              readOnly={false} />
          </div>
        </div>

        <ReceiveDocumentForm 
          handleChangeInformText={this.handleChangeInformText}
          handleChange={this.handleChange}
          speedLevelList={speedLevelList}
          secretLevelList={secretLevelList}
          handleChangeOriginalDoc={this.handleChangeOriginalDoc}
          onRemoveOriginalDocument={this.onRemoveOriginalDocument}
          handleInsert={this.handleInsert}
          handleReplace={this.handleReplace}
          onSortEnd={this.onSortEnd}
          handleUpdateFiles={this.handleUpdateFiles}
          onDeleteFile={this.onDeleteFile}
          handleChangeSpeedLevel={this.handleChangeSpeedLevel}
          handleChangeSecretLevel={this.handleChangeSecretLevel}
          handleChangeDueDate={this.handleChangeDueDate}
          handleChangeReceiveDate={this.handleChangeReceiveDate}
          batchSelectGroups={this.batchSelectGroups}
          batchRemoveGroups={this.batchRemoveGroups}
          selectGroup={this.selectGroup}
          handleChangeGroup={this.handleChangeGroup}
          handleChangeFileFromScanner={this.handleChangeFileFromScanner}
          removeGroup={this.removeGroup}
          onClose={this.onClose}
          informs={informs}
          onKeyPress={this.onKeyPress}
          handleChangeSavedField={this.handleChangeSavedField}
          {...this.state} />

        <div className='row py-3'>
          <div className='col-12 col-md-2'></div>
          <div className='col-12 col-md-10'>
            <button type='button' className='btn btn-primary mr-1 pointer' onClick={this.handleSave}>บันทึก</button>
            <Link to='/send_documents/send_all' className='btn btn-secondary'>ยกเลิก</Link>
          </div>
        </div>
      </div>
    )
  }
}