import React, {Component} from 'react';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';

const EditingFieldName = 'contact';

export default class InlineEditContact extends Component {
	constructor(props){
		super(props)

		this.state = {
  		value: this.props.contact,
      editing_contact: false
  	}

  	this.handleChange = this.handleChange.bind(this);
  	this.handleSetValue = this.handleSetValue.bind(this);
  	this.onEditingContact = this.onEditingContact.bind(this);
	}

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading){
      this.setState({
        value: nextProps.contact
      })
    }
  }

  handleChange(value) {
    this.setState({ value: value })
  }

	handleSetValue(){
		var val = this.state.value;
		this.setState({editing_contact: false}, this.props.handleChange(EditingFieldName, val));
	}

	onEditingContact(){
  	if (this.state.editing_contact){
    		this.setState({editing_contact: false});
  	}else{
    		this.setState({editing_contact: true});
  	}
	}

	stripHTML(text){
		if (_.isEmpty(text)) { return }

		var regex = /(<([^>]+)>)/ig;
 		return text.replace(regex, "");
	}

	render(){
		var contact = this.stripHTML(this.props.contact);
		return (
			<div className='row my-3'>
				<div className='col-12'>
					{this.state.editing_contact ? (
						<div id="editing_contact_id">
							<ReactQuill 
                value={this.state.value}
                onChange={this.handleChange}
                modules={{toolbar: false}} />

				        <div className='mt-2'>
				        	<button 
                    id="submit_edit_contact"
					        	type='button' 
					        	className='btn btn-primary'
					        	onClick={this.handleSetValue}>
					        	ตกลง
					        </button>
					      </div>
				    </div>
					) : (
						<div id="show_contact_id">
							{contact ? (
								<div className='pointer' onClick={this.onEditingContact}>
									{renderHTML(this.props.contact)}
								</div>
							) : (
								<div className='text-danger pointer' onClick={this.onEditingContact}>
									<em>{this.props.suggest_text}</em>
								</div>
							)}	
						</div>
					)}
				</div>
			</div>
		)
	}
}



document.addEventListener('click', function(event){ 
  var editing_contact_id = document.getElementById('editing_contact_id');
  var show_contact_id = document.getElementById('show_contact_id');

  if (show_contact_id){
    if (show_contact_id.contains(event.target)){ return }
  }

  if (editing_contact_id){
    if (!editing_contact_id.contains(event.target)){
      document.getElementById('submit_edit_contact').click();
    }
  }
});