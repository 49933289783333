import React, {Component} from 'react';
import _ from 'lodash';

import LoginSession from '../../stores/LoginSession';

const Item = ({value, index, onRemoveAttachDocument}) => {
  return (
    <li className='list-group-item px-2'>
      {`${index + 1}. ${value.file.name}`}

      {(!_.isEmpty(value.creator_info) && value.creator_info.id === LoginSession.currentUser.id) && (
        <i className="fa fa-times pl-3 float-right text-danger" 
          onClick={() => onRemoveAttachDocument(index)} 
          aria-hidden="true">
        </i>
      )}

      {_.isEmpty(value.id) && (
        <i className="fa fa-times pl-3 float-right text-danger" 
          onClick={() => onRemoveAttachDocument(index)} 
          aria-hidden="true">
        </i>
      )}
    </li>
  );
};

const AttachedFileList = ({items, onRemoveAttachDocument}) => {
  return (
    <ul className='list-group ml-0 mb-2'>
      {items.map((value, index) => (
        <Item 
          key={`item-${index}`} 
          index={index} 
          value={value}
          onRemoveAttachDocument={onRemoveAttachDocument} />
      ))}
    </ul>
  );
};

export default class AttachedDocumentFiles extends Component {
  constructor(props) {
    super(props)

    this.state = {
      files: []
    }

    this.handleUpload = this.handleUpload.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  handleUpload(event){
    var attach_file = event.target.files[0];
    if (attach_file.size === 0) { return }

    const new_file = { file: attach_file };

    let { files } = this.state;
    files = _.concat(files, [new_file]);

    this.setState({ files: files });
  }

  onCloseModal(){
    this.setState({
      files: []
    });
  }

  render(){
    const { attach_document_files } = this.props;
    const { files } = this.state;
    return (
      <div className='row'>
        <div className='col-4 col-sm-3 col-md-2'>
          แนบไฟล์-อื่นๆ
        </div>
        <div className='col-8 col-sm-9 col-md-10'>
          {!_.isEmpty(attach_document_files) && (
            <AttachedFileList 
              items={attach_document_files}
              onRemoveAttachDocument={this.props.onRemoveAttachDocument} />
          )}

          <div>
            <label className="btn btn-success pointer mb-0 mr-2">
              <i className="fa fa-upload mr-1" aria-hidden="true"></i>
                แนบไฟล์-เอกสารอื่นๆ
                <input 
                  type="file" 
                  name='attach_file' 
                  hidden
                  onChange={this.props.handleChangeAttachDoc} />
            </label>
          </div>
        </div>
      </div>
    )
  }
}