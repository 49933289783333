import React, { Component } from 'react';
import _ from 'lodash';

import InformeeTabel from './InformeeTabel';

class Form extends Component {
  constructor(){
    super();

    this.state = {
      id: null,
      name: "",
      selected: [],
      showPositionTable: false
    }

    this.onRowSelect = this.onRowSelect.bind(this);
    this.removeSelected = this.removeSelected.bind(this);
    this.setName = this.setName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let nextInformGroup = nextProps.informGroup;

    if (nextInformGroup.id && this.state.id !== nextInformGroup.id) {
      let selected = nextInformGroup.informees.map(d => d.id);
      
      this.setState({
        id: nextInformGroup.id,
        selected: selected,
        name: nextInformGroup.name,
        showPositionTable: false
      });
    } else {
      this.setState({
        id: null,
        selected: [],
        name: "",
        showPositionTable: false
      })
    }

    if (nextProps.errorMessage && nextProps.errorMessage !== this.props.errorMessage){
      alert(nextProps.errorMessage);
    }
  }

  onRowSelect(row, isSelected, e) {
    let selected = [...this.state.selected]
    let isIncluded = _.includes(selected, row.id)

    if (isIncluded){
      selected = _.pull(selected, row.id)
    }else{
      selected = [...selected, row.id]
    }
    
    this.setState({selected: selected})
  }

  removeSelected(informeeId){
    this.setState({
      selected: _.pull(this.state.selected, informeeId)
    });
  }

  setName(event){
    this.setState({ name: event.target.value });
  }

  handleSubmit(){
    this.props.onSubmit({
      id: this.state.id,
      informee_ids: this.state.selected,
      name: this.state.name
    });
  }

  render(){
    return(
      <div id="inform-group-form">
        <div className="row mb-2">
          <div className="col-md-2">
            <span>ชื่อกลุ่มเรียน</span>
          </div>
          <div className="col-md-10 mb-2 mb-md-0">
            <input type="text" name="name" className="w-100 form-control form-control-sm" value={this.state.name} onChange={this.setName} required />
          </div>
        </div>
        <InformeeTabel  informees={this.props.informees}
                        selected={this.state.selected}
                        onRowSelect={this.onRowSelect}
                        removeSelected={this.removeSelected}
                        onSubmit={this.handleSubmit}
                        onClose={this.props.onClose} />
      </div>
    )
  }
}

export default Form;
