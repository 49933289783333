import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import DashboardPage from '../pages/DashboardPage';

import DepartmentNumberPage from '../pages/department_numbers/DepartmentNumberPage';
import EditDepartmentNumberDocumentPage from '../pages/department_numbers/EditDepartmentNumberDocumentPage';
import UnitNumberPage from '../pages/unit_numbers/UnitNumberPage';
import EditUnitNumberDocumentPage from '../pages/unit_numbers/EditUnitNumberDocumentPage';

import EditDocumentPage from '../pages/documents/EditDocumentPage';
import ShowDocumentPage from '../pages/documents/ShowDocumentPage';

import DocumentsPage from '../pages/documents/DocumentsPage';
import OperatedDocumentsPage from '../pages/documents/OperatedDocumentsPage';
import CCDocumentsPage from '../pages/documents/CCDocumentsPage';
import DraftDocumentsPage from '../pages/documents/DraftDocumentsPage';
import SendAllDocumentsPage from '../pages/documents/SendAllDocumentsPage';
import ReceiveAllDocumentsPage from '../pages/documents/ReceiveAllDocumentsPage';
import InternalReceiveDocumentPage from '../pages/documents/InternalReceiveDocumentPage';
import EditInternalReceiveDocumentPage from '../pages/documents/EditInternalReceiveDocumentPage';
import OrderDocumentsPage from '../pages/documents/OrderDocumentsPage';
import SearchReceiveDocumentsPage from '../pages/documents/SearchReceiveDocumentsPage';
import SearchSendDocumentsPage from '../pages/documents/SearchSendDocumentsPage';
import CancelDocumentsPage from '../pages/documents/CancelDocumentsPage';
import SendDocumentPage from '../pages/documents/SendDocumentPage';
import EditSendDocumentPage from '../pages/documents/EditSendDocumentPage';

import OrganizationPage from '../pages/OrganizationPage';
import StoragesPage from '../pages/StoragesPage';
import DmsPage from '../pages/DmsPage';
import ManualsPage from '../pages/ManualsPage';

import ReceiveDocumentPage from '../pages/reports/ReceiveDocumentPage';
import AwareReportPage from '../pages/reports/AwareReportPage';
import InternalReportPage from '../pages/reports/InternalReportPage';
import ExternalReportPage from '../pages/reports/ExternalReportPage';
import SystemUsageReportPage from '../pages/reports/SystemUsageReportPage';
import SystemUsageStatisticReportPage from '../pages/reports/SystemUsageStatisticReportPage';
import DocumentationSummaryPage from '../pages/reports/DocumentationSummaryPage';
import DailySendSummaryPage from '../pages/reports/DailySendSummaryPage';
import DailyReceiveSummaryPage from '../pages/reports/DailyReceiveSummaryPage';
import SummaryReceivePage from '../pages/reports/SummaryReceivePage';
import ExternalReceiveSummaryPage from '../pages/reports/ExternalReceiveSummaryPage';
import InternalReceiveSummaryPage from '../pages/reports/InternalReceiveSummaryPage';
import WaitingReceivePage from '../pages/reports/WaitingReceivePage';
import InProgressPage from '../pages/reports/InProgressPage';
import InconclusiveStatisticPage from '../pages/reports/InconclusiveStatisticPage';
import DocumentCreationStatisticPage from '../pages/reports/DocumentCreationStatisticPage';

import DocumentSettingPage from '../pages/DocumentSettingPage';

import SetupPage from '../pages/settings/SetupPage';
import SettingPage from '../pages/settings/SettingPage';
import ActingsSettingPage from '../pages/settings/ActingsSettingPage';
import SettingSignaturePage from '../pages/settings/SettingSignaturePage';
import SettingFooterPage from '../pages/settings/SettingFooterPage';
import WorkflowsSettingPage from '../pages/settings/WorkflowsSettingPage';
import WorkflowEditPage from '../pages/settings/WorkflowEditPage';
import WorkflowMigrationPage from '../pages/settings/WorkflowMigrationPage';
import SystemNotificationPage from '../pages/settings/SystemNotificationPage';

import BusinessUnitPage from '../pages/manage_users/BusinessUnitPage';
import UserPage from '../pages/manage_users/UserPage';
import RoleTemplatePage from '../pages/manage_users/RoleTemplatePage';
import SettingUserPage from '../pages/manage_users/SettingUserPage';

import DocumentTemplatePage from '../pages/templates/DocumentTemplatePage';
import TemplatePage from '../pages/templates/TemplatePage';
import FrontPage from '../pages/templates/FrontPage';

import DepartmentGroupsSettingPage from '../pages/department_groups_settings/DepartmentGroupsSettingPage';
import InformeesSettingPage from '../pages/department_groups_settings/InformeesSettingPage';
import InformGroupsSettingPage from '../pages/department_groups_settings/InformGroupsSettingPage';

import LoginPage from '../pages/LoginPage';
import EditProfilePage from '../pages/EditProfilePage';

import ForumsPage from '../pages/forums/ForumsPage';
import ForumShowPage from '../pages/forums/ForumShowPage';
import Permission from '../components/Permission';

class Main extends Component {
  render(){
    return(
      <main>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={DashboardPage} />

        <Route path={`${process.env.PUBLIC_URL}/login`} component={LoginPage} />
        <Route path={`${process.env.PUBLIC_URL}/edit_profile`} component={EditProfilePage} />

        <Route path={`${process.env.PUBLIC_URL}/receive_documents/mydoc`} component={DocumentsPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/receive_documents/internal_receive`} component={InternalReceiveDocumentPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/receive_documents/internal_receive/:id/wp/:workflow_process_id/edit`} component={EditInternalReceiveDocumentPage} />
        <Route path={`${process.env.PUBLIC_URL}/receive_documents/operated`} component={OperatedDocumentsPage} />
        <Route path={`${process.env.PUBLIC_URL}/receive_documents/search_receive_documents`} component={SearchReceiveDocumentsPage} />
        <Route path={`${process.env.PUBLIC_URL}/receive_documents/cancel`} component={CancelDocumentsPage} />
        <Route path={`${process.env.PUBLIC_URL}/receive_documents/receive_all`} component={ReceiveAllDocumentsPage} />

        <Route path={`${process.env.PUBLIC_URL}/send_documents/send_all`} component={SendAllDocumentsPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/send_documents/send`} component={SendDocumentPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/send_documents/send/:id/edit`} component={EditSendDocumentPage} />
        <Route path={`${process.env.PUBLIC_URL}/send_documents/search_send_documents`} component={SearchSendDocumentsPage} />

        <Route exact path={`${process.env.PUBLIC_URL}/department_numbers/department_numbers`} component={DepartmentNumberPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/department_numbers/:document_id/edit/wp/:workflow_process_id`} component={EditDepartmentNumberDocumentPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/unit_numbers/unit_numbers`} component={UnitNumberPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/unit_numbers/:document_id/edit/wp/:workflow_process_id`} component={EditUnitNumberDocumentPage} />

        <Route path={`${process.env.PUBLIC_URL}/documents/search_receive_documents`} component={SearchReceiveDocumentsPage} />
        <Route path={`${process.env.PUBLIC_URL}/documents/search_send_documents`} component={SearchSendDocumentsPage} />
        
        <Route path={`${process.env.PUBLIC_URL}/documents/cc_box`} component={CCDocumentsPage} />
        <Route path={`${process.env.PUBLIC_URL}/documents/draft`} component={DraftDocumentsPage} />
        <Route path={`${process.env.PUBLIC_URL}/documents/order`} component={OrderDocumentsPage} />

        <Route exact path={`${process.env.PUBLIC_URL}/templates`} component={DocumentTemplatePage} />

        <Route path={`${process.env.PUBLIC_URL}/templates/:id/new`} render={({match}) => (
          <TemplatePage params={match.params} />
        )} />

        <Route exact path={`${process.env.PUBLIC_URL}/frontpage/:parent_id/new`} render={({match}) => (
          <FrontPage params={match.params} />
        )} />

        <Route exact path={`${process.env.PUBLIC_URL}/frontpage/:document_id/edit`} render={({match}) => (
          <FrontPage params={match.params} />
        )} />

        <Route path={`${process.env.PUBLIC_URL}/templates/documents/:id/edit/:template_id`} render={({match}) => (
          <EditDocumentPage params={match.params} />
        )} />
        
        <Route path={`${process.env.PUBLIC_URL}/templates/documents/:id/show/:template_id/wp/:workflow_process_id`} component={ShowDocumentPage} />

        <Route path={`${process.env.PUBLIC_URL}/organizations`} component={OrganizationPage} />
        <Route path={`${process.env.PUBLIC_URL}/storages`} component={StoragesPage} />
        <Route path={`${process.env.PUBLIC_URL}/dms`} component={DmsPage} />
        <Route path={`${process.env.PUBLIC_URL}/manuals`} component={ManualsPage} />
        
        <Route exact path={`${process.env.PUBLIC_URL}/reports/receive`} component={ReceiveDocumentPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/aware`} component={AwareReportPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/internal`} component={InternalReportPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/external`} component={ExternalReportPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/system_usage`} component={SystemUsageReportPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/system_usage_statistic`} component={SystemUsageStatisticReportPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/documentation_summary`} component={DocumentationSummaryPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/daily_send_summary`} component={DailySendSummaryPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/daily_receive_summary`} component={DailyReceiveSummaryPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/receive_summary`} component={SummaryReceivePage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/external_receive_summary`} component={ExternalReceiveSummaryPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/internal_receive_summary`} component={InternalReceiveSummaryPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/wait_for_record_receive`} component={WaitingReceivePage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/in_progress`} component={InProgressPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/inconclusive`} component={InconclusiveStatisticPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/creation_and_number`} component={DocumentCreationStatisticPage} />

        <Route path={`${process.env.PUBLIC_URL}/document_setting`} component={DocumentSettingPage} />

        <Route path={`${process.env.PUBLIC_URL}/settings/setup`} component={SetupPage} />
        <Route path={`${process.env.PUBLIC_URL}/settings/sitesetting`} component={SettingPage} />
        <Route path={`${process.env.PUBLIC_URL}/settings/actings`} component={ActingsSettingPage} />
        <Route path={`${process.env.PUBLIC_URL}/settings/signature`} component={SettingSignaturePage} />
        <Route path={`${process.env.PUBLIC_URL}/settings/footer`} component={SettingFooterPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/settings/workflows`} component={WorkflowsSettingPage} />
        <Route path={`${process.env.PUBLIC_URL}/settings/workflows/:id/edit`} render={({match}) => (
          <WorkflowEditPage params={match.params} />
        )} />
        <Route path={`${process.env.PUBLIC_URL}/settings/workflow_migration`} component={WorkflowMigrationPage} />
        <Route path={`${process.env.PUBLIC_URL}/settings/system_notifications`} component={SystemNotificationPage} />

        <Route path={`${process.env.PUBLIC_URL}/manage_users/businesses`} component={BusinessUnitPage} />
        <Route path={`${process.env.PUBLIC_URL}/manage_users/users`} component={UserPage} />
        <Route path={`${process.env.PUBLIC_URL}/manage_users/roles`} component={RoleTemplatePage} />
        <Route path={`${process.env.PUBLIC_URL}/manage_users/settings`} component={SettingUserPage} />

        <Route path={`${process.env.PUBLIC_URL}/department_groups_settings/department_groups`} component={DepartmentGroupsSettingPage} />
        <Route path={`${process.env.PUBLIC_URL}/department_groups_settings/informees`} component={InformeesSettingPage} />
        <Route path={`${process.env.PUBLIC_URL}/department_groups_settings/inform_groups`} component={InformGroupsSettingPage} />

        <Route exact path={`${process.env.PUBLIC_URL}/forums`} component={ForumsPage} />
        <Route exact path={`${process.env.PUBLIC_URL}/forums/:id`} render={({match}) => (
          <ForumShowPage params={match.params} />
        )} />
      </main>
    )
  }
}

export default Main;
