import React, { Component } from 'react';

class DocumentSettingSection extends Component {
  
  onChange(document_type_id, event){
    let field_name = event.target.name;
    let is_checked = event.target.checked;
    this.props.onChange(document_type_id, field_name, is_checked)
  }

  onChangeMinLevel(document_type_id, event){
    let field_name = event.target.name;
    let value = event.target.value;
    this.props.onChange(document_type_id, field_name, value)
  }

  render(){
    return(
      <div>
        <h3>ใบปะหน้า</h3>

        {this.props.document_types.map((document_type, index) => {
          return(
            <div className="card mb-3" key={index}>
              <div className="card-body">
                <h5 className="card-title font-weight-bold">{document_type.name}</h5>
                <div className="form-check">
                  <label className="form-check-label">
                    <input 
                      className="form-check-input mr-2" 
                      type="checkbox" 
                      name='enable_front_page' 
                      checked={document_type.enable_front_page} />
                    {/* onChange={this.onChange.bind(this, document_type.id)} readonly */}
                    ใช้ใบปะหน้า
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input 
                      className="form-check-input mr-2" 
                      type="checkbox" 
                      name='verify_department_group_issuer'
                      checked={document_type.verify_department_group_issuer}
                      onChange={this.onChange.bind(this, document_type.id)} />
                    ตรวจสอบสารบรรณผู้ออกเลข
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input 
                      className="form-check-input mr-2" 
                      type="checkbox" 
                      name='review_by_central_department_group_only'
                      checked={document_type.review_by_central_department_group_only}
                      onChange={this.onChange.bind(this, document_type.id)} />
                    ตรวจทานด้วยสารบรรณกลางเท่านั้น
                  </label>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">ผู้ลงนามตั้งแต่ระดับ: </label>
                  <div className="col-sm-2">
                    <select 
                      name='signee_min_level' 
                      className="form-control form-control-sm"
                      value={document_type.signee_min_level || false}
                      onChange={this.onChangeMinLevel.bind(this, document_type.id)}>
                      <option defaultValue>ไม่ระบุ</option>
                      {[...Array(50)].map((number, index) => {
                        const value = index + 50;
                        return(
                          <option key={value} value={value}>{value}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">ผู้มีสิทธิ์เกษียนตั้งแต่ระดับ: </label>
                  <div className="col-sm-2">
                    <select 
                      name='note_min_level' 
                      className="form-control form-control-sm"
                      value={document_type.note_min_level || false}
                      onChange={this.onChangeMinLevel.bind(this, document_type.id)}>
                      <option defaultValue>ไม่ระบุ</option>
                      {[...Array(99)].map((number, index) => {
                        const value = index + 1;
                        return(
                          <option key={value} value={value}>{value}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default DocumentSettingSection;
