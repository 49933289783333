import React, { Component } from 'react';
import _ from 'lodash';

import PositionsUserTable from '../PositionsUserTable';
import DepartmentGroupTable from '../DepartmentGroupTable';
import SelectedInformee from '../SelectedInformee';
import SelectedDepartmentGroup from '../SelectedDepartmentGroup';

class Forward extends Component {
  constructor(){
    super();

    this.state = {
      current_form: "note",
      targets: [],
      current_tab: "nav-internal",
      acting_positions_user_id: null
    }

    this.openInformeeModal = this.openInformeeModal.bind(this);
    this.renderTarget = this.renderTarget.bind(this);
    this.handleSelectInformee = this.handleSelectInformee.bind(this);
    this.handleSelectDepartmentGroup = this.handleSelectDepartmentGroup.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setInformeeText = this.setInformeeText.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleRemoveTarget = this.handleRemoveTarget.bind(this);
    this.openTab = this.openTab.bind(this);
    this.isActive = this.isActive.bind(this);
    this.handleShowActors = this.handleShowActors.bind(this);
    this.handleSelectActor = this.handleSelectActor.bind(this);
  }

  openInformeeModal(){
    this.setState({current_form: "selectInformee"});
  }

  handleSelectInformee(row, isSelected, e){
    const is_exists = _.find(this.state.targets, { positions_user_id: row.id })
    
    if(is_exists){
      this.setState({ current_form: "note" });
    } else {
      this.setState({
        targets: [...this.state.targets,
          {
            positions_user_id: row.id,
            informee_text: row.position_name,
            informee_name_with_position: `${row.full_name} ${row.position_name} ${row.department_name}`,
            department_group_name: "",
            department_group_members: "",
          }
        ],
        current_form: "note",
      });
    }
  }

  handleSelectDepartmentGroup(row, isSelected, e){
    const is_exists = _.find(this.state.targets, { positions_user_id: row.secretary_positions_user_id })
    
    if(is_exists){
      this.setState({ current_form: "note" });
    } else {
      this.setState({
        targets: [...this.state.targets,
          {
            positions_user_id: row.secretary_positions_user_id,
            department_group_name: row.name,
            department_group_members: row.member_name_with_positions,
            informee_text: "",
            informee_name_with_position: "",
          }
        ],
        current_form: "note",
      });
    }
  }

  handleSubmit(){
    this.props.onSubmitAction(this.state.targets);
  }

  setInformeeText(event, positions_user_id){
    const informee_text = event.target.value;
    
    var targets = this.state.targets;
    var target = _.find(targets, {positions_user_id: positions_user_id})
    target.informee_text = informee_text;

    this.setState({targets: targets});
  }

  handleClose(){
    this.setState({current_form: "note"});
  }

  handleRemoveTarget(positions_user_id){
    const {targets} = this.state;

    _.remove(this.state.targets, { positions_user_id: positions_user_id });

    this.setState({ targets: targets });
  }

  handleShowActors(positions_user_id){
    const acting =  _.find(this.props.actings, (acting) => {
                      return acting.attributes.positions_user.id === positions_user_id
                    });
    const actor_positions_users = acting.attributes.actor_positions_users;

    this.setState({
      actor_positions_users: actor_positions_users,
      acting_positions_user_id: positions_user_id,
      current_form: "selectActor",
    });
  }

  handleSelectActor(row, isSelected, e){
    let targets = [...this.state.targets];
    let target = _.find(targets, {positions_user_id: this.state.acting_positions_user_id});

    target.positions_user_id = row.id;
    target.informee_name_with_position = `${row.full_name} ${row.position_name} ${row.department_name}`;

    this.setState({
      targets: targets,
      current_form: "note",
    });
  }

  openTab(tab_id){
    this.setState({ current_tab: tab_id });
  }

  isActive(tab_id){
    if(tab_id === this.state.current_tab){
      return "show active";
    } else {
      return "";
    }
  }
  
  renderTarget(target, index){
    return (
      <div key={index}>
        {target.informee_name_with_position && (
          <SelectedInformee {...target} 
                            acting_positions_user_ids={this.props.acting_positions_user_ids}
                            setInformeeText={this.setInformeeText}
                            handleRemoveTarget={this.handleRemoveTarget}
                            showActors={this.handleShowActors} />
        )}
        {target.department_group_name && (
          <SelectedDepartmentGroup  {...target} 
                                    setInformeeText={this.setInformeeText}
                                    handleRemoveTarget={this.handleRemoveTarget} />
        )}
      </div>
    )
  }

  render(){
    return(
      <div>
        { this.state.current_form === 'note' && (
          <div>
            <div className="row">
              <div className="col-2">เรียน</div>
              <div className="col-10">
                { _.map(this.state.targets, this.renderTarget) }

                <button className="btn btn-sm btn-primary" onClick={this.openInformeeModal}>เพิ่มผู้ถูกเรียน</button>
              </div>
            </div>

            <div className="modal-footer row mt-2">
              <button type='button' className="btn btn-primary pointer" disabled={this.props.clicked} onClick={this.handleSubmit}>ยืนยัน</button>
              <button type='button' className="btn btn-secondary pointer" onClick={this.props.onCloseModal}>ยกเลิก</button>
            </div>
          </div>
        )}
        { this.state.current_form === 'selectInformee' && (
          <div>
            <nav className="mb-1">
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className={`nav-item nav-link ${this.isActive("nav-internal")}`} id="nav-internal-tab" data-toggle="tab" href="#nav-internal" role="tab" aria-controls="nav-internal" aria-selected="true"
                    onClick={() => this.openTab("nav-internal")}>
                  หน่วยงานภายใน
                </a>
                <a className={`nav-item nav-link ${this.isActive("nav-department-groups")}`} id="nav-department-groups-tab" data-toggle="tab" href="#nav-department-groups" role="tab" aria-controls="nav-department-groups" aria-selected="false"
                    onClick={() => this.openTab("nav-department-groups")}>
                  สารบรรณหน่วยงาน
                </a>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className={`tab-pane fade ${this.isActive("nav-internal")}`} id="nav-internal" role="tabpanel" aria-labelledby="nav-internal-tab">
                <PositionsUserTable positions_users={this.props.internal_users}
                                    onSelect={this.handleSelectInformee}
                                    onClose={this.handleClose} />
              </div>
              <div className={`tab-pane fade ${this.isActive("nav-department-groups")}`} id="nav-department-groups" role="tabpanel" aria-labelledby="nav-department-groups-tab">
                <DepartmentGroupTable department_groups={this.props.department_groups}
                                      onSelect={this.handleSelectDepartmentGroup}
                                      onClose={this.handleClose} />
              </div>
            </div>
          </div>
        )}
        { this.state.current_form === 'selectActor' && (
          <PositionsUserTable positions_users={this.state.actor_positions_users}
                              onSelect={this.handleSelectActor}
                              onClose={this.handleClose} />
        )}
      </div>
    )
  }
}

export default Forward;