import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import './stylesheets/DepartmentGroupTable.css';

import { MergeAttributes } from './FlatData';

class DepartmentGroupTable extends Component {
  constructor(){
    super();

    this.showMemberCount = this.showMemberCount.bind(this);
  }

  showMemberCount(cell, row){
    return row.member_names.length;
  }

  render(){
    const data = MergeAttributes(this.props.department_groups);

    const selectRowProp = {
      mode: 'radio',
      clickToSelect: true,
      bgColor: 'pink',
      hideSelectColumn: true,
      onSelect: this.props.onSelect
    };
    const options = {
      sizePerPageList: []
    };

    return(
      <div className="row">
        <div className="col-12 rui-wf-action-dg-tb">
          <BootstrapTable 
            data={data}
            search={ true }
            multiColumnSearch={ true }
            selectRow={ selectRowProp }
            options={ options }
            striped
            hover
            condensed
            pagination
            version='4'
            tableContainerClass={'table-sm'}>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='name' dataSort={ true }>ชื่อกลุ่ม</TableHeaderColumn>
              <TableHeaderColumn dataField='member_names' dataSort={ true }>คนภายในกลุ่ม</TableHeaderColumn>
              <TableHeaderColumn  dataField='member_count' 
                                  dataSort={ true }
                                  width='65'
                                  dataFormat={this.showMemberCount}>
                                    จำนวน(คน)
              </TableHeaderColumn>
          </BootstrapTable>
        </div>
        
        {this.props.hideClose === undefined && (
          <div className="modal-footer col-12">
            <button type='button' className="btn btn-secondary pointer" onClick={this.props.onClose}>ยกเลิก</button>
          </div>
        )}
      </div>
    )
  }
}

export default DepartmentGroupTable;
