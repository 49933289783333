import _ from 'lodash'

export function prefixAttribute(prefix, attr) {
	if (prefix) {
		return '' + prefix + _.upperFirst(attr);
	} else {
		return attr;
	}
}

export function getComponentDisplayName(Component) {
	return Component.displayName || Component.name || 'Component'
}

export function getScrollBarWidth () {
	var inner = document.createElement('p');
	inner.style.width = '100%';
	inner.style.height = '200px';

	var outer = document.createElement('div');
	outer.style.position = 'absolute';
	outer.style.top = '0px';
	outer.style.left = '0px';
	outer.style.visibility = 'hidden';
	outer.style.width = '200px';
	outer.style.height = '150px';
	outer.style.overflow = 'hidden';
	outer.appendChild (inner);

	document.body.appendChild (outer);
	var w1 = inner.offsetWidth;
	outer.style.overflow = 'scroll';
	var w2 = inner.offsetWidth;
	if (w1 === w2) w2 = outer.clientWidth;

	document.body.removeChild (outer);

	return (w1 - w2);
};

export function issueNumberFormat(issue_number) {
	if (_.isEmpty(issue_number)){
		return '####'
	}else{
		return issue_number
	}
}

export function thaiVowels() {
	return [
	  3631, 3632, 3633, 3634, 3635, 3636, 3637, 3638, 3639, 3640, 3641, 3642, 3647, 
	  3653, 3654, 3655, 3656, 3657, 3658, 3659, 3660, 3661, 3662, 3663, 3674, 3675
	]
}

export function getColSize(label='เรียน') {
	let col1 = null;
	let col2 = null;
	switch(label) {
    case "ถึง":
      col1 = "col-1 pr-0";
      col2 = "col-11 ml-minus-30";
      break;
    case "กราบเรียน":
    	col1 = "col-2 pr-0";
    	col2 = "col-10 ml-minus-35";
    	break;
    case "นมัสการ":
    	col1 = "col-2 pr-0";
    	col2 = "col-10 pl-0 ml-minus-35";
    	break;
    case "กราบทูล":
    	col1 = "col-2 pr-0";
    	col2 = "col-10 pl-0 ml-minus-35";
    	break;
    case "ขอประทานกราบทูล":
    	col1 = "col-3 pr-0";
    	col2 = "col-9 ml-minus-35";
    	break;
    default:
        col1 = "col-1 pr-0";
        col2 = "col-11 pl-0";
	}
	return [col1, col2];
}

export const monthList = [
  [1, 'มกราคม'],
  [2, 'กุมภาพันธ์'],
  [3, 'มีนาคม'],
  [4, 'เมษายน'],
  [5, 'พฤษภาคม'],
  [6, 'มิถุนายน'],
  [7, 'กรกฎาคม'],
  [8, 'สิงหาคม'],
  [9, 'กันยายน'],
  [10, 'ตุลาคม'],
  [11, 'พฤศจิกายน'],
  [12, 'ธันวาคม']
]

export function getThaiMonth(month){
  const thaiMonth = _.find(monthList, function(m) { return m[0] === month; })[1];
  return thaiMonth;
}

