import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

class ActingTable extends Component {
  render(){
    const selectRowProp = {
      mode: 'radio',
      clickToSelect: true,
      hideSelectColumn: true,
      onSelect: this.props.onSelectActing
    };
    const options = {
      sizePerPageList: []
    };

    return(
      <div className="row">
        <div className="col-12">
          <BootstrapTable 
            data={this.props.positions_users}
            search={ true }
            multiColumnSearch={ true }
            selectRow={ selectRowProp }
            options={ options }
            striped
            hover
            condensed
            pagination
            version='4'
            tableContainerClass={'table-sm'}>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='user_name' dataSort={ true }>ชื่อ-นามสกุล</TableHeaderColumn>
              <TableHeaderColumn dataField='position_name' dataSort={ true }>ตำแหน่ง</TableHeaderColumn>
              <TableHeaderColumn dataField='department_name' dataSort={ true }>แผนก</TableHeaderColumn>
          </BootstrapTable>
        </div>

        <div className="modal-footer col-12">
          <button type='button' className="btn btn-secondary pointer" onClick={this.props.openForm}>ยกเลิก</button>
        </div>
      </div>
    )
  }
}

export default ActingTable;
