import React, {Component} from 'react';

import { SecretLevel } from './FormElement';

export default class SecretLevelForm extends Component {
  render(){
    return (
      <div className='my-3'>
        <SecretLevel 
          secret_level={this.props.secret_level}
          handleChange={this.props.handleChange} />
      </div>
    )
  }
}