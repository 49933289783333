import React, {Component} from 'react';
import _ from 'lodash';

import OrgImage from '../../../assets/images/16Org.png';
import { getColSize } from '../../../utils/index';

import SimpleModal from '../../SimpleModal';
import SelectInforms from './SelectInforms';

const EditingFieldName = 'informs';

export class InformForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			open: false,
			mode: 'overview',
			editing_index: '',
			editing_inform: {},
			informs: [
				{
					id: '',
					name: '',
					department_group_ids: []
				}
			],
			send_all_informs: false
		}

		this.renderRow = this.renderRow.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onCloseSelectForm = this.onCloseSelectForm.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleAddInform = this.handleAddInform.bind(this);
		this.onInformOpen = this.onInformOpen.bind(this);
		this.handleChangeShowOneLine = this.handleChangeShowOneLine.bind(this);
		this.sendAllDepartmentGroups = this.sendAllDepartmentGroups.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.informs !== nextProps.informs){
			this.setState({
				informs: nextProps.informs
			})
		}
	}

	onSelectDepartment(inform, index){
		this.setState({
			editing_index: index, 
			editing_inform: inform, 
			mode: 'select_informs'
		});
	}

	handleSubmit(){
		this.setState({open: false}, this.props.handleChange(EditingFieldName, this.state.informs));
	}

	onClose(){
		this.setState({
			editing_index: '', 
			editing_inform: {}, 
			mode: 'overview',
			open: false
		});
	}

	onCloseSelectForm(){
		this.setState({
			editing_index: '', 
			editing_inform: {}, 
			mode: 'overview'
		});
	}

	handleDelete(department_group_id, index){
		var { informs } = this.state;
		var department_group_ids = informs[index].department_group_ids;
		department_group_ids = _.pull(department_group_ids, department_group_id);

		informs[index].department_group_ids = department_group_ids;

		this.setState({
			informs: informs
		});
	}

	handleChange(selected){
		if (this.state.editing_index === '' || this.state.editing_index === null || this.state.editing_index === undefined) { return }

		var { informs } = this.state;
		informs[this.state.editing_index].department_group_ids = selected;

		this.setState({
			informs: informs,
			editing_index: '', 
			editing_inform: {}, 
			mode: 'overview'
		});
	}

	handleChangeName(index, event){
		var { informs } = this.state;
		informs[index].name = event.target.value;

		this.setState({
			informs: informs,
			editing_index: '', 
			editing_inform: {}, 
			mode: 'overview'
		});
	}

	handleAddInform(){
		var new_inform = {id: '', name: '', department_group_ids: []};
		var { informs } = this.state;

		informs = _.concat(informs, new_inform);
		
		this.setState({
			informs: informs
		});
	}

	handleRemoveInform(index){
		var { informs } = this.state;
		informs.splice(index, 1);

		this.setState({
			informs: informs
		});
	}

	handleChangeShowOneLine(event){
		let isChecked = event.target.checked;
		this.props.handleChangeAttributeValue('show_one_line_informee', isChecked);
	}

	renderRow(inform, index){
		return (
			<div key={index} className='card card-body form-group'>
				{_.size(this.state.informs) > 1 && (
					<i className="fa fa-times red-icon pointer fixed-top-right" onClick={this.handleRemoveInform.bind(this, index)}></i>
				)}
				<div className='row form-group'>
					<div className='col-3'>เรียน</div>
					<div className='col-8'>
						<input 
							type='text' 
							name='name'
							value={inform.name}
							className='form-control form-control-sm'
							onChange={this.handleChangeName.bind(this, index)}/>
					</div>
				</div>
				<div className='row'>
					<div className='col-2'>หน่วยงาน</div>
					<div className='col-1 text-right'>
						<img alt='ORG' 
							src={OrgImage} 
							className='pointer mb-2' 
							onClick={this.onSelectDepartment.bind(this, inform, index)} />
					</div>
					<div className='col-9'>
						{!_.isEmpty(inform.department_group_ids) && _.map(inform.department_group_ids, (department_group_id, i)=>{
							return (
								this.renderDepartmentRow(department_group_id, i, index)
							)
						})}
					</div>
				</div>
			</div>
		)
	}

	renderDepartmentRow(department_group_id, i, index){
		var department_obj = _.find(this.props.department_groups, {id: department_group_id});
		return (
			<div key={i} className='bg-success p-2 mb-2' style={{position: 'relative'}}>
				<div className='col-11'>
					{department_obj.attributes.name}
				</div>
				<div className='col-1' style={{position: 'absolute', top: '5px', right: '-10px'}}>
					<i className="fa fa-times pointer" 
					aria-hidden="true"
					onClick={this.handleDelete.bind(this, department_group_id, index)}></i>
				</div>
			</div>
		)
	}

	onInformOpen(){
		this.setState({open: true});
	}

	sendAllDepartmentGroups(){
		let { send_all_informs } = this.state;
		let informs = []

		if (send_all_informs){
			send_all_informs = false
		}else{
			send_all_informs = true

			_.map(this.props.department_groups, function(department_group, index){
				informs.push({
					id: '',
					name: '',
					department_group_ids: [department_group.id]
				})
			});
		}

		this.setState({
			informs: informs,
			editing_index: '', 
			editing_inform: {}, 
			mode: 'overview',
			send_all_informs: send_all_informs
		}, this.props.handleChange(EditingFieldName, informs));
	}

	render(){
		var title = 'เรียน';
		if (this.state.mode !== 'overview'){
			title = 'หน่วยงานปลายทาง';
		}
		return (
			<div>
				<button 
					type='button' 
					className='btn btn-sm btn-primary mr-2 mb-2'
					onClick={this.onInformOpen}>
					ระบุเรียน
				</button>
				{this.props.send_all_department_groups_section && (
					<button 
						type='button' 
						className='btn btn-sm btn-primary mr-2 mb-2'
						onClick={this.sendAllDepartmentGroups}>
						ส่งทุกหน่วยงาน
					</button>
				)}
				{this.props.show_one_line_section && (
					<label className='mb-0'>
						<input 
							type='checkbox' 
							className='mr-1' 
							name='show_one_line_informee'
							checked={this.props.show_one_line_informee.toString() === "true"}
							onChange={this.handleChangeShowOneLine} />
						<label className='ex-small-text mb-0'>แสดงผลบรรทัดเดียว</label>
					</label>
				)}

				<SimpleModal 
					isOpen={this.state.open}
					title={title}
					size={'modal-lg'}
					showSubmitButton={false}
					onClose={this.onClose}>
					{this.state.mode !== 'overview' ? (
						<SelectInforms 
							editing_inform={this.state.editing_inform}
							department_groups={this.props.department_groups}
							onChange={this.handleChange}
							onClose={this.onCloseSelectForm} />
					) : (
						<div>
							<button 
								type='button' 
								className='btn btn-sm btn-success form-group'
								onClick={this.handleAddInform}>
								เพิ่ม
							</button>
							
							{!_.isEmpty(this.state.informs) && _.map(this.state.informs, this.renderRow)}

							<div className="modal-footer row">
								<button type='button' className="btn btn-primary pointer" onClick={this.handleSubmit}>ตกลง</button>
								<button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ยกเลิก</button>
							</div>
						</div>
					)}
				</SimpleModal>
			</div>
		)
	}
}

export class RenderInforms extends Component {
	constructor(props) {
		super(props)

		this.renderInform = this.renderInform.bind(this);
		this.renderDepartment = this.renderDepartment.bind(this);
	}

	renderInform(inform, index){
		return (
			<div key={index} className='small my-1'>
				<div>{inform.name}</div>
				{inform.department_group_ids && (
					<div className='card p-1'>
						<div>
							<div className='mb-1'>ดำเนินงานโดย</div>
							<ul className='pl-4 mb-0'>
								{_.map(inform.department_group_ids, this.renderDepartment)}
							</ul>
						</div>
					</div>
				)}
			</div>
		)
	}

	renderDepartment(department_group_id, index){
		var department_obj = _.find(this.props.department_groups, {id: department_group_id});
		return (
			<li key={index}>
				{department_obj.attributes.name}
			</li>
		)
	}

	render(){
		return (
			<div>
				{this.props.informs && _.map(this.props.informs, this.renderInform)}
			</div>
		)
	}
}

export class RenderInformTo extends Component {
	render(){
		var list = '';
		if (this.props.values){
			list = _.map(this.props.values, 'name');
		}
		return (
			<div className='row mt-2'>
				<div className={`${getColSize(this.props.label)[0]}`}>
					{this.props.label}
				</div>
				<div className={`${getColSize(this.props.label)[1]}`}>
					{this.props.show_one_line_informee.toString() === 'true' ? (
						<span style={{whiteSpace: 'nowrap'}}>
							{list && _.join(list, ', ')}
						</span>
					) : (
						<span>{list && _.join(list, ', ')}</span>
					)}
				</div>
			</div>
		)
	}
}

