import React, { Component } from 'react';
import _ from 'lodash';

import ManualStore from '../stores/ManualStore';

class ManualsPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      manuals: [],
    }

    this.loadData = this.loadData.bind(this);
    this.renderManualGroup = this.renderManualGroup.bind(this);
    this.renderManualFile = this.renderManualFile.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(){
    ManualStore.request({}).then((resp)=>{
      const manuals = _.get(resp.data, 'data', {});
      this.setState({
        manuals: manuals
      })
    })
  }

  render(){
    return (
      <div className='bg-white p-3 px-lg-4 py-lg-3 inbox-body'>
        <h2>คู่มือการใช้งานระบบ</h2>
        <hr />

        {!_.isEmpty(this.state.manuals) && _.map(this.state.manuals, this.renderManualGroup)}
      </div>
    );
  }

  renderManualGroup(manualGroup, index){
    return (
      <div className='row mb-3' key={"group_" + index}>
        <div className='col-12 text-center bg-lightblue text-white pt-1 mb-3 font-weight-bold'>
          {manualGroup.group}
        </div>

        {!_.isEmpty(manualGroup.files) && _.map(manualGroup.files, this.renderManualFile)}
      </div>
    )
  }

  renderManualFile(manualFile, index){
    return (
      <div className='col-sm-2' key={"file_" + index}>
        <div className='p-2' style={{border: '2px dashed gray'}}>
          {manualFile.file_name}
          <a href={manualFile.file_url} target="_blank" className='btn btn-sm btn-block text-white' style={{backgroundColor: '#ff6a00'}}>pdf</a>
        </div>
      </div>
    )
  }
}

export default ManualsPage;