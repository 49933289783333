import React, {Component} from 'react';
import GarudaEmblem from '../../../assets/images/emblem.png';
import _ from 'lodash';

import './Elements.css';

const NormalLevel = 'ปกติ';

export class RenderSpeedLevel extends Component {
	render(){
		let style = {position: 'absolute', top: '35mm'};
		let document_template = this.props.document_template;
		if (document_template.attributes.has_garuda_emblem && document_template.attributes.garuda_emblem_align === 'left'){
			style = {position: 'absolute', top: '30mm', left: '190px'}
		}
		return (
			<div className='row mb-4' style={style}>
				{(this.props.speed_level !== NormalLevel) && (
					<div className='col-md-12' style={{position: 'relative'}}>
						<h1 className='class-level'>{this.props.speed_level}</h1>
					</div>
				)}
			</div>
		)
	}
}

export class RenderSecretLevel extends Component {
	render(){
		let style = {position: 'absolute', left: '53%', top: '15mm'}
		let document_template = this.props.document_template;
		if (document_template.attributes.has_garuda_emblem && document_template.attributes.garuda_emblem_align === 'left'){
			style = {position: 'absolute', left: '53%', top: '20mm'}
		}
		return (
			<div className='row mb-4' style={style}>
				{(this.props.secret_level !== NormalLevel) && (
					<div className='col-md-12' style={{position: 'relative', left: '-47%'}}>
						<h1 className='class-level'>{this.props.secret_level}</h1>
					</div>
				)}
			</div>
		)
	}
}

export class CenterGarudaEmblem extends Component {
	render(){
		return (
			<div className='row mb-4' style={{position: 'absolute', left: '53%', top: '25mm'}}>
				<div className='col-md-12' style={{position: 'relative', left: '-47%'}}>
					<img alt='Emblem' src={GarudaEmblem} style={{maxHeight: '3cm'}}/>
				</div>
			</div>
		)
	}
}

export class LeftGarudaEmblem extends Component {
	render(){
		return (
			<div style={{position: 'absolute', top: '25mm'}}>
				<img alt='Emblem' src={GarudaEmblem} style={{width: '1.5cm'}}/>
			</div>
		)
	}
}

export class Title extends Component {
	render(){
		return (
			<div className='row mb-4'>
				<div className='col-md-4'>
					<img alt='Emblem' src={GarudaEmblem} className='garuda-emblem-logo' />
				</div>
				<div className='col-md-4 text-center'>
					<h1>{this.props.title}</h1>
				</div>
			</div>
		)
	}
}

export class GovermentService extends Component {
	render(){
		return (
			<div className='row form-group'>
				<div className='col-md-12'>
					<span className='pr-2 text-md'>{this.props.label}</span>
					<span>............................................</span>
				</div>
			</div>
		)
	}
}

export class IssueNumber extends Component {
	render(){
		const { document_template, suggest_text, issue_number, label, label_class } = this.props;

		let text_underline_dashed = '';
		if (!_.isEmpty(document_template) && document_template.attributes.has_underline) {
			text_underline_dashed = 'text_underline_dashed';
		}

		return (
			<div className='row'>
				{!_.isEmpty(document_template.attributes.template_attributes.heading) ? (
					<div className='col-md-12'>
						<label className='pr-2'>{label}</label>
						<label>
							{ issue_number ? (
								<div className={text_underline_dashed}>{issue_number}</div>
							) : (
								<div className={`small text-muted ${text_underline_dashed}`}>
									{suggest_text}
								</div>
							)}
						</label>
					</div>
				) : (
					<div className='col-md-12 pr-0 mt-1' style={{display: 'table'}}>
						<div className={`pr-2 ${label_class}`} style={{display: 'table-cell'}}>
							{label}
						</div>
						{ issue_number ? (
							<div className={text_underline_dashed} style={{display: 'table-cell'}}>{issue_number}</div>
						) : (
							<div className={`small text-muted ${text_underline_dashed}`} style={{display: 'table-cell'}}>
								{suggest_text}
							</div>
						)}
					</div>
				)}
			</div>
		)
	}
}

export class IssueTime extends Component {
	render(){
		const { document_template } = this.props;

		let text_underline_dashed = '';
		if (!_.isEmpty(document_template) && document_template.attributes.has_underline) {
			text_underline_dashed = 'text_underline_dashed';
		}

		return (
			<div className='row'>
				<div className='col-md-12' style={{display: 'table'}}>
					<span className={`pr-2 ${this.props.label_class}`} style={{display: 'table-cell'}}>
						{this.props.label}
					</span>
					<span className={`small text-muted ${text_underline_dashed}`} style={{display: 'table-cell'}}>
						วันที่เอกสารจะถูกระบุตอนออกเลข
					</span>
				</div>
			</div>
		)
	}
}

export class Subject extends Component {
	render(){
		return (
			<div className='row form-group'>
				<div className='col-md-12'>
					<span className='pr-2 text-md'>{this.props.label}</span>
					<span>{this.props.topic}</span>
				</div>
			</div>
		)
	}
}





