import React, { Component } from 'react';
import _ from 'lodash';

import DepartmentGroupTabel from './DepartmentGroupTabel';
import PositionTable from './PositionTable';

class Form extends Component {
  constructor(){
    super();

    this.state = {
      id: null,
      positionId: null,
      name: "",
      userName: "",
      selected: [],
      showPositionTable: false
    }

    this.onRowSelect = this.onRowSelect.bind(this);
    this.removeSelected = this.removeSelected.bind(this);
    this.setName = this.setName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openPositionTable = this.openPositionTable.bind(this);
    this.onSelectPosition = this.onSelectPosition.bind(this);
    this.closePositionTable = this.closePositionTable.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let nextInformee = nextProps.informee;

    if (nextInformee.id && this.state.id !== nextInformee.id) {
      let selected = nextInformee.department_groups.map(d => d.id);
      
      this.setState({
        id: nextInformee.id,
        selected: selected,
        positionId: nextInformee.position_id,
        name: nextInformee.name,
        userId: nextInformee.user_id,
        userName: nextInformee.user_name,
        showPositionTable: false
      });
    } else {
      this.setState({
        id: null,
        selected: [],
        positionId: null,
        name: "",
        userId: "",
        userName: "",
        showPositionTable: false
      })
    }
  }

  onRowSelect(row, isSelected, e) {
    let selected = [...this.state.selected]
    let isIncluded = _.includes(selected, row.id)

    if (isIncluded){
      selected = _.pull(selected, row.id)
    }else{
      selected = [...selected, row.id]
    }
    
    this.setState({selected: selected})
  }

  removeSelected(departmentGroupId){
    this.setState({
      selected: _.pull(this.state.selected, departmentGroupId)
    });
  }

  setName(event){
    this.setState({ name: event.target.value });
  }

  handleSubmit(){
    this.props.onSubmit({
      id: this.state.id,
      department_group_ids: this.state.selected,
      name: this.state.name,
      position_id: this.state.positionId,
      user_id: this.state.userId,
      can_receive_outer: this.state.can_receive_outer,
    });
  }

  openPositionTable(){
    this.setState({ showPositionTable: true })
  }

  onSelectPosition(row, isSelected, e){
    this.setState({ 
      positionId: row.position_id,
      name: row.position_name, 
      userId: row.user_id, 
      userName: row.user_name, 
      showPositionTable: false 
    });
  }

  closePositionTable(){
    this.setState({ showPositionTable: false })
  }

  render(){
    return(
      <div id="informee-form">
        {this.state.showPositionTable ? (
          <PositionTable  positions={this.props.positions}
                          onSelectPosition={this.onSelectPosition}
                          onClosePositionTable={this.closePositionTable} />
        ) : (
          <div>
            <div className="row mb-2">
              <div className="col-md-1">
                <span>เรียน</span>
              </div>
              <div className="col-md-6 mb-2 mb-md-0">
                <input type="text" name="name" className="w-100 form-control form-control-sm" value={this.state.name} onChange={this.setName} />
              </div>
              <div className="col-md-5">
                { this.state.userName }
                {' '}
                <i className="fa fa-search pointer" aria-hidden="true" onClick={this.openPositionTable}></i>
              </div>
            </div>
            <DepartmentGroupTabel departmentGroups={this.props.departmentGroups}
                                  selected={this.state.selected}
                                  onRowSelect={this.onRowSelect}
                                  removeSelected={this.removeSelected}
                                  onSubmit={this.handleSubmit}
                                  onClose={this.props.onClose} />
          </div>
        )}
      </div>
    )
  }
}

export default Form;
