import React, { Component } from 'react';
import _ from 'lodash';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { MergeAttributes } from '../FlatData';
import Loading from '../Loading';

class SystemUsages extends Component {
  constructor(props) {
    super(props);
  }

  _setTableOption(){ 
    if (_.isEmpty(this.props.results)) { 
      return "ไม่มีข้อมูล"; 
    }else{
      return <Loading />
    }
  }

  render(){
    var data = MergeAttributes(this.props.system_usages);

    const options = {
      sizePerPageList: [],
      sizePerPage: 25,
      noDataText: this._setTableOption()
    };
    return (
      <BootstrapTable 
        data={data}
        search={ true }
        multiColumnSearch={ true }
        options={options}
        striped
        hover
        condensed
        pagination
        version='4'
        tableContainerClass={'table-sm'}>
          <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn dataField='full_name' dataSort={ true } width='120px'>ชื่อ-สกุล</TableHeaderColumn>
            <TableHeaderColumn dataField='position' columnTitle dataSort={ true }>ตำแหน่ง</TableHeaderColumn>
            <TableHeaderColumn dataField='department' columnTitle dataSort={ true }>หน่วยงาน</TableHeaderColumn>
            <TableHeaderColumn dataField='sign_in_at' dataSort={ true }>วันเข้าระบบ</TableHeaderColumn>
            <TableHeaderColumn dataField='sign_in_ip' dataSort={ true }>IP Address</TableHeaderColumn>
      </BootstrapTable>
    )
  }
}

export default SystemUsages;
