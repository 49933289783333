import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Link } from 'react-router-dom';

import './stylesheets/ReceiveAllDocumentsPage.css';

import LoginSession from '../../stores/LoginSession';

import { thaiDateFormatter, rowClassNameFormat } from '../../utils/bootstrapTableFormatters';
import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';
import AfterSaveLoading from '../../components/AfterSaveLoading';
import Permission from '../../components/Permission';
import DocumentTypeFilter from '../../components/documents/filters/DocumentTypeFilter';

import SimpleModal from '../../components/SimpleModal';
import ReceiveNumberStore from '../../stores/ReceiveNumberStore';

import CommentIcon from '../../assets/images/comment.png';

import WorkflowProcessHistory from '../../components/WorkflowProcessHistory';

class ReceiveAllDocumentsPage extends Component {
  constructor(props){
    super(props)

    var dateObj = new Date();
    var year = dateObj.getUTCFullYear();
    var current_year = year + 543;

    this.state = {
      loading: false,
      isDataFetched: false,
      start_year: current_year.toString(),
      end_year: current_year.toString(),
      open: false,
      stateModalOpen: false,
      group_id: '',
      amount: 1,
      receive_numbers: [],
      groups: [],
      years: [],
      show_warning: false,
      edit_comment: false,
      editing_comment: {},
      workflow_process_id: null,
      workflow_process_id_history: null,
      workflow_process_id_attachment: null,
      allow_remove_ids: [],
      pagination: {},
      searchText: '',
      active_type: 'ทั้งหมด',
      selectedNumberType: ["หนังสือรับ"],
      next_receive_number: ""
    }

    this.handleBook = this.handleBook.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.confirm = this.confirm.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCloseStateModal = this.onCloseStateModal.bind(this);
    this.loadData = this.loadData.bind(this);
    this.inlineEditComment = this.inlineEditComment.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);
    this.handleSubmitCommit = this.handleSubmitCommit.bind(this);
    this.stateFormatter = this.stateFormatter.bind(this);
    this.openStateModal = this.openStateModal.bind(this);
    this.redirectToShowDocumentPage = this.redirectToShowDocumentPage.bind(this);
    this.onChange = this.onChange.bind(this);
    this.numberWithReservation = this.numberWithReservation.bind(this);
    this.renderNumberTypeOption = this.renderNumberTypeOption.bind(this);

    // Pagination & Search
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(){
    ReceiveNumberStore.request({}).then((resp)=>{
      const receive_numbers = _.get(resp.data, 'data', []);
      const groups = _.get(resp.data, 'included.groups', []);
      const years = _.get(resp.data, 'included.years', []);
      const allow_remove_ids = _.get(resp.data, 'included.allow_remove_ids', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      let group_id = '';
      if (!_.isEmpty(groups)){
        group_id = _.first(groups).id;
      }

      this.setState({
        receive_numbers: receive_numbers,
        groups: groups,
        group_id: group_id,
        years: years,
        allow_remove_ids: allow_remove_ids,
        pagination: pagination
      })
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    }).then(()=>{
      this.setState({loading: false, isDataFetched: true});
    });

    ReceiveNumberStore.request({url: `receive_numbers/get_next_receive_number`}).then((resp)=>{
      const next_receive_number = _.get(resp.data, 'data.next_number', "");

      this.setState({
        next_receive_number: "(" + next_receive_number + ")"
      })

    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  confirm(){
    this.setState({show_warning: true});
  }

  handleSubmit(){
    this.setState({loading: true});
    ReceiveNumberStore.save(null, {
      receive_number: {
        for_group_id: this.state.group_id,
        amount: this.state.amount,
        type: this.state.selectedNumberType
      }
    }).then((resp)=>{
      this.setState({open: false, show_warning: false, amount: 1}, this.loadData());
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  handleBook(){
    this.setState({open: true, amount: 1});
  }

  renderOption(group, index){
    return (
      <option key={index} value={group.id}>{group.attributes.name}</option>
    )
  }

  renderNumberTypeOption(type_name, index){
    return (
      <option key={`number_type_${index}`} value={type_name}>{type_name}</option>
    )
  }

  handleChange(event){
    let field_name = event.target.name;
    let value = event.target.value;
    this.setState({
      [field_name]: value
    })
  }

  handleDelete(receive_number){
    if (!window.confirm('ยืนยันยกเลิกการจอง')) { return }
    ReceiveNumberStore.request({id: receive_number.id, method: 'delete'}).then(()=>{
      this.loadData();
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    })
  }

  numberWithReservation(cell, row){
    const allow_remove_ids = this.state.allow_remove_ids;
    const empty_issue_number = _.isEmpty(row.issue_number);
    const present_booked_by = !_.isEmpty(row.booked_by_user_name);

    return (
      <div>
        {(empty_issue_number && present_booked_by) ? (
          <div>
            <div className='xxs-small text-warning'>{row.booked_by_user_name}</div>
            {_.includes(allow_remove_ids, row.id) && (
              <button 
                type='button' 
                className='btn btn-sm btn-warning'
                onClick={this.handleDelete.bind(this, row)}>
                ยกเลิกการจอง
              </button>
            )}
          </div>
        ) : (
          <div>{row.issue_number}</div>
        )}
      </div>
    )
  }

  stateFormatter(cell, row) {
    const workflow_process_id = _.get(row, "document_detail.workflow_process_id");
    
    return (
      <div>
        {workflow_process_id && (
          <a href="#" onClick={this.openStateModal.bind(this, workflow_process_id)}>
            {cell}
          </a>
        )}
      </div>
    )
  }

  openStateModal(workflow_process_id, event){
    event.stopPropagation();

    this.setState({
      stateModalOpen: true,
      workflow_process_id_history: workflow_process_id,
    });
  }

  onCloseStateModal(){
    this.setState({
      stateModalOpen: false, 
      workflow_process_id: null
    });
  }

  onClose(){
    this.setState({
      open: false, 
      show_warning: false, 
      amount: 1,
      edit_comment: false,
      editing_comment: {}
    });
  }

  openEditComment(comment, event){
    event.stopPropagation();
    this.setState({edit_comment: true, editing_comment: {...comment}});
  }

  inlineEditComment(cell, row){
    var card = null;
    if (row.created_by.id === LoginSession.current.user.id){
      if (_.isEmpty(row.comment)){
        card = <img src={CommentIcon} alt='Comment' />
      }else{
        card = <div>{row.comment}</div>
      }
    }
    
    return (
      <div onClick={this.openEditComment.bind(this, row)}>
        {card}
      </div>
    )
  }

  handleChangeComment(){
    var { editing_comment } = this.state;
    editing_comment.comment = this.refs.comment.value;

    this.setState({editing_comment: editing_comment});
  }

  handleSubmitCommit(){
    this.setState({loading: true});
    ReceiveNumberStore.request({
      id: this.state.editing_comment.id,
      method: 'put',
      data: {
        receive_number: {
          comment: this.state.editing_comment.comment
        }
      },
    }).then(()=>{
      this.setState({edit_comment: false, editing_comment: {}}, this.loadData());
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    }).then(()=>{
      this.setState({loading: false});
    })
  }

  onChange(fieldname, value){
    this.setState({
      [fieldname]: value
    })
  }

  _setTableOption(){ 
    if(this.state.isDataFetched){
      return "ไม่มีข้อมูล";
    }else{
      return <Loading />
    }
  }

  redirectToShowDocumentPage(row, isSelected, e){
    if (_.isEmpty(row.document_detail)) { return }
      
    const { document_id, document_template_id, workflow_process_id } = row.document_detail;

    if (!_.isEmpty(document_id) && !_.isEmpty(document_template_id) && !_.isEmpty(workflow_process_id)){
      this.props.history.push(`/templates/documents/${document_id}/show/${document_template_id}/wp/${workflow_process_id}`);
    }
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, receive_numbers: []});
    const { searchText } = this.state;

    ReceiveNumberStore.request({url: `receive_numbers?page=${page}&searchText=${searchText}`}).then((resp)=>{
      const receive_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        receive_numbers: receive_numbers,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, receive_numbers: []});

    ReceiveNumberStore.request({url: `receive_numbers?page=1&searchText=${searchText}&type=${this.state.active_type}`}).then((resp)=>{
      const receive_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        receive_numbers: receive_numbers,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleChangeType(type){
    this.setState({isDataFetched: false, receive_numbers: []});

    ReceiveNumberStore.request({url: `receive_numbers?page=1&searchText=${this.state.searchText}&type=${type}`}).then((resp)=>{
      const receive_numbers = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        receive_numbers: receive_numbers,
        pagination: pagination,
        active_type: type
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  render() {
    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);

    var data = [];
    if (this.state.isDataFetched && !_.isEmpty(this.state.receive_numbers)){
      data = MergeAttributes(this.state.receive_numbers);
    }

    const options = {
      sizePerPageList: [],
      sizePerPage: 10,
      page: active_page,
      noDataText: this._setTableOption(),
      onRowClick: this.redirectToShowDocumentPage,
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500
    };

    let modalTitle = 'ระบุจำนวนเอกสารที่ต้องการจอง';
    if (this.state.show_warning){
      modalTitle = 'เตือน';
    }

    const group = _.find(this.state.groups, {id: this.state.group_id});

    let documentTypes = ["ทั้งหมด", "หนังสือรับ"];
    let receiveNumberTypes = ["หนังสือรับ"];
    if(Permission.canReceiveDocument()){
      documentTypes = ["ทั้งหมด", "หนังสือรับ"];
      receiveNumberTypes = ["หนังสือรับ"];
    }

    return (
      <div>
        {this.state.loading && <AfterSaveLoading />}
        
        <div className='row form-group'>
          <div className='col-md-6'>
            <h3>ทะเบียนหนังสือรับ <span className='next-number'>{this.state.next_receive_number}</span></h3>
          </div>
          <div className='col-md-6 text-right'>
            {/*Permission.canBookNumber() && (
              <button type='button' className='btn btn-info mr-1 pointer' onClick={this.handleBook}>จองเลข</button>
            )*/}
            {Permission.canReceiveInternalDocument() && (
              <Link to='/receive_documents/internal_receive' className='btn btn-primary mr-1'>หนังสือรับใหม่</Link>
            )}
          </div>
        </div>

        <div className='row form-group'>
          <div className='col-12'>
            <DocumentTypeFilter 
              active_type={this.state.active_type}
              document_types={documentTypes}
              handleChangeType={this.handleChangeType} />
          </div>
        </div>

        <div className='rui-receivealldoc'>
          <BootstrapTable 
            data={data}
            remote={ true }
            fetchInfo={{dataTotalSize: total_items_count}}
            search={ true }
            multiColumnSearch={ true }
            options={ options }
            striped
            hover
            pagination
            version='4'
            tableContainerClass={'table-sm'}
            trClassName={rowClassNameFormat}>
            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn dataField='code' dataSort={ true } width='100'>เลขทะเบียนรับ</TableHeaderColumn>
            <TableHeaderColumn dataField='number' dataFormat={this.numberWithReservation} dataSort={ true } width='100'>ที่</TableHeaderColumn>
            <TableHeaderColumn dataField='created_at' dataFormat={ thaiDateFormatter } dataSort={ true } width='100'>ลงวันที่</TableHeaderColumn>
            <TableHeaderColumn dataField='from_department' columnTitle width='200'>จาก</TableHeaderColumn>
            <TableHeaderColumn dataField='to_department' columnTitle width='200'>ถึง</TableHeaderColumn>
            <TableHeaderColumn dataField='topic' columnTitle dataSort={ true }>เรื่อง</TableHeaderColumn>
            <TableHeaderColumn dataField='type_name'>ประเภท</TableHeaderColumn>
            <TableHeaderColumn dataField='current_state_name' dataSort={ true } dataFormat={this.stateFormatter} width='140'>สถานะ</TableHeaderColumn>
          </BootstrapTable>
        </div>

        <SimpleModal 
          isOpen={this.state.open}
          title={modalTitle}
          showSubmitButton={false}
          onClose={this.onClose}>
            {!this.state.show_warning ? (
              <div>
                <div className='row mb-3'>
                  <div className='col-md-4 pt-2'>
                    <strong>ประเภท</strong>
                  </div>
                  <div className='col-md-7'>
                    <select 
                      value={this.state.selectedNumberType}
                      name='selectedNumberType'
                      className='form-control form-control-sm'
                      onChange={this.handleChange}>
                      {_.map(receiveNumberTypes, this.renderNumberTypeOption)}
                    </select>
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-md-4 pt-2'>
                    <strong>หน่วยงาน</strong>
                  </div>
                  <div className='col-md-7'>
                    <select 
                      value={this.state.group_id}
                      name='group_id'
                      className='form-control form-control-sm'
                      onChange={this.handleChange}>
                      {(this.state.isDataFetched && _.isEmpty(this.state.groups)) && (
                        <option value=''>-- เลือกหน่วยงาน --</option>
                      )}
                      {(this.state.isDataFetched && !_.isEmpty(this.state.groups)) && _.map(this.state.groups, this.renderOption)}
                    </select>
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-md-4 pt-2'>
                    <strong>จำนวนหนังสือที่จอง</strong>
                  </div>
                  <div className='col-md-3'>
                    <input 
                      type='text' 
                      value={this.state.amount}
                      name='amount'
                      className='form-control'
                      onChange={this.handleChange} />
                  </div>
                  <div className='col-md-4 pt-2'>
                    เรื่อง
                  </div>
                </div>
                <div className="modal-footer row">
                  <button type='button' className="btn btn-primary pointer" onClick={this.confirm}>บันทึก</button>
                  <button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ยกเลิก</button>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  คุณต้องการเพิ่มการจองเลข
                </div>
                {!_.isEmpty(group) ? (
                  <div>
                    ของหน่วยงาน <u>{group.attributes.name}</u>
                  </div>
                ) : (
                  <div>
                    ของหน่วยงาน -
                  </div>
                )}
                <div>
                  ทั้งหมด {this.state.amount} เรื่อง
                </div>
                <div className="modal-footer row">
                  <button type='button' className="btn btn-primary pointer" onClick={this.handleSubmit}>ยืนยัน</button>
                  <button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ยกเลิก</button>
                </div>
              </div>
            )}
        </SimpleModal>

        <SimpleModal 
          isOpen={this.state.edit_comment}
          title={'ระบุหมายเหตุ'}
          showSubmitButton={false}
          onClose={this.onClose}>
          <div>
            หมายเหตุ
            <div className='form-group'>
              <textarea 
                ref='comment' 
                value={this.state.editing_comment.comment || ''} 
                className='form-control'
                onChange={this.handleChangeComment} />
            </div>
            <div className="modal-footer row">
              <button type='button' className="btn btn-primary pointer" onClick={this.handleSubmitCommit}>บันทึก</button>
              <button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ยกเลิก</button>
            </div>
          </div>
        </SimpleModal>

        <SimpleModal 
          isOpen={this.state.stateModalOpen}
          title="ประวัติการดำเนินการ การปฏิบัติงาน"
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={this.onCloseStateModal}>
            <WorkflowProcessHistory id={this.state.workflow_process_id} />

            <div className="modal-footer row">
              <button type='button' className="btn btn-secondary pointer" onClick={this.onCloseStateModal}>ปิด</button>
            </div>
        </SimpleModal>
      </div>
    )
  }
}

export default ReceiveAllDocumentsPage;