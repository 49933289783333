import React, { Component } from 'react';
import _ from 'lodash';

import SetNextUnitIssueNumber from './SetNextUnitIssueNumber';

import IssueNumberStore from '../stores/IssueNumberStore';

class IssueNumberByBooks extends Component {
  constructor(props){
    super(props)

    this.state = {
      next_issue_number_by_book: [ 
        { name: "ทั่วไป", next_number: "1" },
        { name: "ลับ", next_number: "1" },
        { name: "คำสั่ง", next_number: "1" },
        { name: "รับรอง", next_number: "1" },
      ]
    }

    this.renderNumberBook = this.renderNumberBook.bind(this);
    this.handleUpdatedNextIssueNumber = this.handleUpdatedNextIssueNumber.bind(this);
  }

  componentDidMount(){
    IssueNumberStore.request({url: `issue_numbers/get_next_issue_number_by_book`}).then((resp)=>{
      const next_issue_number_by_book = _.get(resp.data, 'data.next_issue_number_by_book', "");

      this.setState({
        next_issue_number_by_book: next_issue_number_by_book
      })
    });
  }

  handleUpdatedNextIssueNumber(next_issue_number, number_book_name){
    const updated_next_issue_number_by_book = {...this.state.next_issue_number_by_book};

    let updated_book = _.find(updated_next_issue_number_by_book, { name: number_book_name });
    updated_book["next_number"] = next_issue_number

    this.setState({
      next_issue_number_by_book: updated_next_issue_number_by_book
    });
  }

  renderNumberBook(number_book, index){
    let class_name = "text-success";

    switch(number_book.name) {
      case "ทั่วไป":
        class_name = "text-success";
        break;
      case "ลับ":
        class_name = "text-danger";
        break;
      case "คำสั่ง":
        class_name = "text-warning";
        break;
      case "รับรอง":
        class_name = "text-info";
        break;
    }

    return (
      <tr key={index}>
        <td className={`${class_name} font-weight-bold py-1`}>{number_book.name}</td>
        <td className="py-1">{number_book.next_number}</td>
        <td className="py-1">
          <a onClick={() => this.props.onActionClick("ออกเลข", number_book.name)} className="cursor-pointer">ออกเลข</a>
        </td>
        <td className="py-1">
          <a onClick={() => this.props.onActionClick("ออกเลขเวียน", number_book.name)} className="cursor-pointer">ออกเลขเวียน</a>
        </td>
        <td className="py-1">
          <SetNextUnitIssueNumber
            next_issue_number={number_book.next_number}
            number_book_name={number_book.name}
            onUpdatedNextIssueNumber={this.handleUpdatedNextIssueNumber} />
        </td>
      </tr>
    )
  }

  render(){
    return (
      <div className='row'>
        <div className="col-12">
          <table className='table table-bordered text-center'>
            <thead>
              <th className="py-1">เล่ม</th>
              <th className="py-1">เลขที่</th>
              <th className="py-1"></th>
              <th className="py-1"></th>
              <th className="py-1"></th>
            </thead>

            <tbody>
              {!_.isEmpty(this.state.next_issue_number_by_book) && _.map(this.state.next_issue_number_by_book, this.renderNumberBook)}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default IssueNumberByBooks;

