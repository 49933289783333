import React, { Component } from 'react';
import _ from 'lodash';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import SpeedLevelForm from './elements/SpeedLevelForm';
import SecretLevelForm from './elements/SecretLevelForm';
import SubjectForm from './elements/SubjectForm';
import SetFrontPageAvailability from './elements/SetFrontPageAvailability';

import Opening from './elements/Opening';
import { SigneeForm, RenderSignee } from './elements/Signees';
import { UploadFiles, RenderFileNames } from './elements/Files';
import { InformeeForm, RenderInformees } from './elements/Informees';
import { InformForm, RenderInforms } from './elements/Informs';
import { ReferenceDocumentForm, RenderRefDocs} from './elements/ReferenceDocuments';
import { NumberIssuerForm, RenderNumberIssuers} from './elements/NumberIssuers';
import { ThroughForm, RenderThroughs } from './elements/Throughs';
import DraftPosition from './elements/DraftPosition';

const NormalLevel = 'ปกติ';

export default class DocumentForm extends Component {
  render(){
    const { 
      is_front_document,
      document_template,
      document_attributes,
      document,
      internal_number_issuers,
      central_number_issuers,
      number_issuers,
      department_groups,
      informees,
      inform_groups,
      reference_documents,
      all_users,
      internal_users,
      current_user_departments
    } = this.props;

    let hidden_ref = false;
    if (!_.isEmpty(document_attributes.hidden_ref)){
      hidden_ref = document_attributes.hidden_ref.value;
    }

    let hidden_file = false;
    if (!_.isEmpty(document_attributes.hidden_file)){
      hidden_file = document_attributes.hidden_file.value;
    }

    return (
      <div className='col-md-3 mt-3'>
        {document_template.attributes.has_speed_level && (
          <SpeedLevelForm
            speed_level={document.speed_level}
            handleChange={this.props.handleUpdate} />
        )}

        {(document_template.attributes.has_due_date && document.speed_level !== NormalLevel) && (
          <div>
            <div className='alert alert-danger py-0 mb-1'>กำหนดวันแล้วเสร็จ</div>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              selected={document.due_date}
              onChange={this.props.handleChangeEndDate}
              className='form-control' />
          </div>
        )}

        {document_template.attributes.has_secret_level && (
          <SecretLevelForm 
            secret_level={document.secret_level}
            handleChange={this.props.handleUpdate} />
        )}

        {!is_front_document && (
          <DraftPosition 
            current_user_departments={current_user_departments}
            document={document}
            handleChange={this.props.handleUpdate} />
        )}

        {document_template.attributes.has_subject && (
          <SubjectForm 
            subject={document.subject}
            handleChange={this.props.handleUpdate} />
        )}

        {document_template.attributes.has_number_issuer && (
          <div className='my-3'>
            <NumberIssuerForm 
              document_template={document_template}
              internal_number_issuers={internal_number_issuers}
              central_number_issuers={central_number_issuers}
              users={number_issuers}
              number_issuer_id={document.number_issuer_id}
              is_circular={document.is_circular}
              handleChange={this.props.handleUpdate} />
            <RenderNumberIssuers 
              number_issuer_id={document.number_issuer_id}
              users={number_issuers}
              actings={this.props.actings}
              onDeleteNumberIssuer={this.props.handleDeleteNumberIssuer}
              onShowActorsForm={this.props.onShowActorsForm} />
              <hr className='mt-2' />
          </div>
        )}

        {(document_template.attributes.enable_front_page && 
          document_template.attributes.const_value !== 'front_page' && 
          document_template.attributes.const_value !== 'front_page_with_upload_file') && (
          <SetFrontPageAvailability 
            document={document}
            handleChange={this.props.handleUpdate} />
        )}

        {document_template.attributes.has_title && (
          <div className='mt-3 mb-4'>
            <Opening 
              opening={document_attributes.opening.value}
              handleChange={this.props.handleChangeAttributeValue} />
          </div>
        )}

        {document_template.attributes.has_inform_to_informee && (
          <div className='my-3'>
            <InformeeForm 
              show_one_line_section={document_template.attributes.has_show_one_line_informee}
              show_one_line_informee={document_attributes.show_one_line_informee.value}
              send_all_department_groups_section={document_template.attributes.can_send_to_all}
              handleChange={this.props.handleUpdate} 
              department_groups={department_groups}
              informs={document.informs}
              informees={informees}
              inform_groups={inform_groups}
              handleChangeAttributeValue={this.props.handleChangeAttributeValue} />
            <RenderInformees 
              department_groups={department_groups}
              informs={document.informs}
              informees={informees} />
            <hr className='mt-2' />
          </div>
        )}

        {document_template.attributes.has_inform_to_department_group && (
          <div className='my-3'>
            <InformForm 
              show_one_line_section={document_template.attributes.has_show_one_line_informee}
              show_one_line_informee={document_attributes.show_one_line_informee.value}
              send_all_department_groups_section={document_template.attributes.can_send_to_all}
              handleChange={this.props.handleUpdate} 
              department_groups={department_groups}
              informs={document.informs}
              informees={informees}
              handleChangeAttributeValue={this.props.handleChangeAttributeValue} />
            <RenderInforms
              department_groups={department_groups}
              informs={document.informs}
              informees={informees} />
            <hr className='mt-3' />
          </div>
        )}

        {document_template.attributes.has_references && (
          <div className='my-3'>
            <ReferenceDocumentForm 
              is_receive_document_form={false}
              hidden_section={document_template.attributes.has_hide_references}
              hidden_ref={hidden_ref}
              reference_documents={reference_documents}
              references={document.references}
              onChange={this.props.handleUpdate}
              handleChange={this.props.handleChangeAttributeValue} />
            
            <RenderRefDocs 
              hidden_ref={hidden_ref}
              references={document.references}
              reference_documents={reference_documents}
              onDeleteRefDoc={this.props.onDeleteRefDoc} />
            <hr className='mt-2' />
          </div>
        )}

        {document_template.attributes.has_attachment && (
          <div className='my-3'>
            <UploadFiles 
              hidden_section={document_template.attributes.has_hide_attachment}
              hidden_file={hidden_file}
              onChange={this.props.handleUpdateFiles} 
              handleChange={this.props.handleChangeAttributeValue} />
            
            <RenderFileNames 
              hidden_file={hidden_file}
              files={document.files}
              onDeleteFile={this.props.onDeleteFile} />
            <hr className='mt-2' />
          </div>
        )}

        {document_template.attributes.has_signee && (
          <div className='my-3'>
            <SigneeForm 
              signees={document.signees}
              document_template={document_template}
              users={all_users}
              internal_users={internal_users}
              handleChange={this.props.handleUpdate} />
            <RenderSignee 
              signees={document.signees}
              users={all_users}
              actings={this.props.actings}
              onDeleteSignee={this.props.handleDeleteSignee}
              onShowActorsForm={this.props.onShowActorsForm} />
            <hr className='mt-2' />
          </div>
        )}

        {document_template.attributes.has_through && (
          <div className='my-3'>
              <ThroughForm 
                signees={document.signees}
                throughs={document.throughs}
                multiple={true}
                users={all_users}
                internal_users={internal_users}
                handleChange={this.props.handleUpdate} />
              <RenderThroughs 
                throughs={document.throughs}
                users={all_users}
                actings={this.props.actings}
                onDeleteThrough={this.props.handleDeleteThrough}
                onShowActorsForm={this.props.onShowActorsForm} />
            <hr className='mt-2' />
          </div>
        )}
      </div>
    )
  }
}