import React from 'react';
import _ from 'lodash';
import moment from 'moment';


const CommentList = (props) => {
  const renderComment = (comment_wph, index) => {
    const comment_text = comment_wph.attributes.comment || comment_wph.attributes.note_text;
    const executor_name = comment_wph.attributes.executor.attributes.full_name;

    moment.locale('th');
    const created_at =  moment(comment_wph.attributes.created_at, "DD/MM/YYYY").format('DD/MM/YYYY เวลา h:mm');

    const state_name = _.find(props.workflow_states, {id: comment_wph.attributes.workflow_state.id}).attributes.state_name;
    const action_name = _.find(props.workflow_state_actions, {id: comment_wph.attributes.workflow_state_action.id}).attributes.action_name;

    return(
      <div className="card mb-3" key={"comment_list_" + index}>
        <div className="card-header">
          <strong>{action_name}</strong>
          <span className="ml-1 text-muted">{"โดย" + executor_name + " เมื่อ " + created_at}</span>
        </div>
        <div className="card-body">
          <p className="card-text">{comment_text}</p>
        </div>
      </div>
    )
  }

  return(
    <div>
        <div className="form-group">
          {props.comment_wph_histories.map((comment_wph, index) => (renderComment(comment_wph, index)))}
        </div>
        <div className="modal-footer row">
          <button type='button' className="btn btn-secondary pointer" onClick={props.onCloseModal}>ปิด</button>
        </div>
      </div>
  )
}

export default CommentList;