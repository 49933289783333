import React from 'react';
import _ from 'lodash';

const NoteSelectInformee = (props) => (
  <div className="row">
    <div className="col-2">เรียน</div>
    <div className="col-10">
      <input type="text" className="form-control form-control-sm mb-2" value={props.informee_text} onChange={props.setInformeeText} />
      {props.informee_name_with_position && _.includes(props.acting_positions_user_ids, props.informee_id) && (
        <div className="px-2 pt-2 pb-1 mb-2 bg-danger text-white position-relative">
          {props.informee_name_with_position}
          <i className='fa fa-eject text-black close-icon' onClick={() => props.showActors(props.informee_id, "informee")}></i>
        </div>
      )}

      {props.informee_name_with_position &&  !_.includes(props.acting_positions_user_ids, props.informee_id) && (
        <div className="px-2 pt-2 pb-1 mb-2 bg-success text-white">{props.informee_name_with_position}</div>
      )}

      {props.department_group_name && (
        <div className="card mb-1">
          <div className="card-body">
            <h4>{props.department_group_name}</h4>
            {_.map(props.department_group_members, function(member, index){
              return(
                <div key={index} className="green-border green-text p-1 mb-1">
                  {member}
                </div>
              )
            })}
            <i  className='fa fa-times close-icon text-danger' 
                onClick={() => props.clearDepartmentGroup()}>
            </i>
          </div>
        </div>
      )}
      <button className="btn btn-sm btn-primary" onClick={props.openInformeeModal}>เลือกผู้ถูกเรียน</button>
    </div>
  </div>
)

export default NoteSelectInformee;