import React, { Component } from 'react';
import _ from 'lodash';

import WorkflowStore from '../../stores/WorkflowStore';

import { MergeAttributes } from '../../components/FlatData';
import Form from '../../components/Workflow/Form';

class WorkflowEditPage extends Component {
  constructor(){
    super();

    this.state = {
      modalOpen: false,
      modalTitle: "",
      workflow: {},
      states: [],
      actions: [],
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.reloadData = this.reloadData.bind(this);
  }

  componentWillMount(){
    this.reloadData();
  }

  reloadData(){
    const workflowID = this.props.params.id;

    WorkflowStore.request({id: workflowID}).then((resp)=>{
      let workflow  = _.get(resp.data, 'data', {});
      let states    = _.get(resp.data, 'included.states', {});
      let actions   = _.get(resp.data, 'included.actions', {});
      
      this.setState({
        workflow: workflow,
        states: MergeAttributes(states),
        actions: MergeAttributes(actions),
      });
    });
  }

  handleSubmit(){
    this.reloadData();
  }

  render(){
    return (
      <div id="workflow-edit-page" className="container">
        <Form workflow={this.state.workflow}
              states={this.state.states}
              actions={this.state.actions}
              onReloadData={this.reloadData}
              onSubmit={this.handleSubmit} />
      </div>
    )
  }
}

export default WorkflowEditPage;