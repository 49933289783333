import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import EditAction from '../../EditAction';
import DeleteAction from '../../DeleteAction';

import ActiveDirectoryStore from '../../../stores/ActiveDirectoryStore';

import SimpleModal from '../../SimpleModal';
import { MergeAttributes } from '../../FlatData';

const ADAttributes = {
	name: '',
	url: '',
	username: '',
	password: ''
}

export default class UserProperty extends Component {
	constructor(props){
		super(props)

		this.state = {
			open: false,
			editing_id: '',
			active_directory: ADAttributes,
			active_directories: []
		}

		this.actionsFormatter = this.actionsFormatter.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.fetchData = this.fetchData.bind(this);
	}

	componentWillMount() {
		this.fetchData();
	}

	fetchData(){
		ActiveDirectoryStore.request({}).then((resp)=>{
			var data = _.get(resp.data, 'data', []);
			this.setState({active_directories: data})
		})
	}

	handleAdd(){
		this.setState({
			open: true,
			editing_id: '',
			active_directory: ADAttributes
		})
	}

	handleEdit(id){
		ActiveDirectoryStore.request({id: id}).then((resp)=>{
			var data = _.get(resp.data, 'data', []);
			this.setState({
				open: true,
				editing_id: data.id,
				active_directory: {
					name: data.attributes.name,
					url: data.attributes.url,
					username: data.attributes.username,
					password: data.attributes.password
				}
			})
		})
	}

	handleDelete(id){
		if (window.confirm(`Would you like to delete this AD?`)) {
			ActiveDirectoryStore.request({id: id, method: 'delete'}).then(()=>{
				this.fetchData();
			})
		}
	}

	handleChange(){
		this.setState({
			active_directory: {
				name: this.refs.name.value,
				url: this.refs.url.value,
				username: this.refs.username.value,
				password: this.refs.password.value
			}
		})
	}

	handleClose(){
		this.setState({
			open: false,
			editing_id: '',
			active_directory: ADAttributes
		})
	}

	actionsFormatter(cell, row) {
	  	return (
	  		<div>
	  			<EditAction id={ cell } onEdit={this.handleEdit.bind(this, cell)}/>&ensp;
	  			<DeleteAction id={ cell } onDelete={this.handleDelete.bind(this, cell)}/>
	  		</div>
	  	)
	}

	handleSave(event){
		event.preventDefault();

		ActiveDirectoryStore.save(this.state.editing_id, {
			active_directory: {
				name: this.state.active_directory.name,
				url: this.state.active_directory.url,
				username: this.state.active_directory.username,
				password: this.state.active_directory.password
			}
		}).then(()=>{
			this.setState({open: false}, this.fetchData());
		})
	}

	render(){
		var data = MergeAttributes(this.state.active_directories);
		const options = {
	      sizePerPageList: []
	    };
		return (
			<div>
				<div className='row form-group'>
					<div className='col-6'>
						<h4>AD</h4>
					</div>
					<div className='col-6 text-right'>
						<button 
							type='button' 
							className='btn btn-success'
							onClick={this.handleAdd}>+ Add</button>
					</div>
				</div>

				<div className='row'>
					<div className='col-12'>
						<BootstrapTable 
								data={data}
								options={ options }
								hover
								version='4'
								tableContainerClass={'table-sm'}>
								<TableHeaderColumn isKey dataField='name'>Key</TableHeaderColumn>
								<TableHeaderColumn dataField='url'>URL</TableHeaderColumn>
								<TableHeaderColumn dataField='username'>Username</TableHeaderColumn>
								<TableHeaderColumn dataField='password'>Password</TableHeaderColumn>
								<TableHeaderColumn dataField='id' dataFormat={ this.actionsFormatter } columnClassName='text-center'></TableHeaderColumn>
							</BootstrapTable>
					</div>
				</div>

				<SimpleModal 
					isOpen={this.state.open}
					title={'Create AD'}
					showSubmitButton={false}
					onClose={this.handleClose}>
					<form onSubmit={this.handleSave}>
						<div className='row form-group'>
							<div className='col-3 text-right'>
								<label className='required'>Name:</label>
							</div>
							<div className='col-9'>
								<input 
									type='text' 
									ref='name'
									value={this.state.active_directory.name || ''}
									className='form-control'
									onChange={this.handleChange} />
							</div>
						</div>
						<div className='row form-group'>
							<div className='col-3 text-right'>
								<label className='required'>URL:</label>
							</div>
							<div className='col-9'>
								<input
									type='text' 
									ref='url'
									value={this.state.active_directory.url || ''}
									className='form-control'
									onChange={this.handleChange} />
							</div>
						</div>
						<div className='row form-group'>
							<div className='col-3 text-right'>
								<label className='required'>Username:</label>
							</div>
							<div className='col-9'>
								<input
									type='text' 
									ref='username'
									value={this.state.active_directory.username || ''}
									className='form-control'
									onChange={this.handleChange} />
							</div>
						</div>
						<div className='row form-group'>
							<div className='col-3 text-right'>
								<label className='required'>Password:</label>
							</div>
							<div className='col-9'>
								<input
									type='text' 
									ref='password'
									value={this.state.active_directory.password || ''}
									className='form-control'
									onChange={this.handleChange} />
							</div>
						</div>
						<div className="modal-footer row">
							<button type='submit' className="btn btn-primary pointer">Save changes</button>
							<button type='button' className="btn btn-secondary pointer" onClick={this.handleClose}>Close</button>
						</div>
					</form>
				</SimpleModal>
			</div>
		)
	}
}