import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LoginSession from '../stores/LoginSession';
import Pathname from '../components/Pathname';

export default class TopOrganizeHeader extends Component {
	constructor(props) {
		super(props)

		this.handleLogout = this.handleLogout.bind(this);
	}

	handleLogout(event){
		event.preventDefault();
		LoginSession.logout();
		window.location.replace('/login');
	}

  	render(){
  		var fullPathname = Pathname.fullPathname(this.props.location);

		return (
			<div className='fixed-top'>
				<nav id='organize-header' className='navbar navbar-expand-lg navbar-light' 
					style={{
						backgroundColor: '#b5e0e2', 
            			'boxShadow': '0 4px 10px 0 #859fa0'
					}}>
					 <h3 className='navbar-brand'>ORGANIZE</h3>

					 <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
						<span className='navbar-toggler-icon'>ORGANIZE</span>
					</button>
					<div className='collapse navbar-collapse' id='navbarNav'>
						<ul className="navbar-nav ml-5">
							<li className='nav-item px-3'>
								<Link to='/manage_users/businesses' className={`nav-link ${(fullPathname === '/manage_users/businesses') && 'active'}`}>Business Unit</Link>
						 	</li>
							<li className='nav-item px-3'>
								<Link to='/manage_users/users' className={`nav-link ${(fullPathname === '/manage_users/users') && 'active'}`}>User</Link>
						  	</li>
						  	<li className='nav-item px-3'>
								<Link to='/manage_users/roles' className={`nav-link ${(fullPathname === '/manage_users/roles') && 'active'}`}>Role Template</Link>
						 	</li>
						  	<li className='nav-item px-3'>
								<Link to='/manage_users/settings' className={`nav-link ${(fullPathname === '/manage_users/settings') && 'active'}`}>Setting</Link>
						 	</li>
						</ul>
					</div>
					<div>
						<Link to='/' className='pull-right' onClick={this.handleLogout}>Logout</Link>
					</div>
				</nav>
			</div>
		)
  	}
}
