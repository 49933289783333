import React, {Component} from 'react';
import _ from 'lodash';

export class DocumentTypeFilter extends Component {
  constructor(props) {
    super(props)
    
    this.renderType = this.renderType.bind(this);
  }

  handleChangeType(order){
    this.props.handleChangeType(order);
  }

  renderType(order, index){
    return (
      <label 
        key={index} 
        onClick={this.handleChangeType.bind(this, order)}
        className={`btn btn-sm btn-secondary ${this.props.active_type === order && 'active'}`}>
        <input type="radio" name="order_filter" id={`order_filter_${index}`} autoComplete="off" defaultChecked="true" /> 
        {order}
      </label>
    )
  }

  render(){
    const { document_types } = this.props;
    return (
      <div className='row mb-1'>
        <div className='col-md-12'>
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            {_.map(document_types, this.renderType)}
          </div>
        </div>
      </div>
    )
  }
}

export default DocumentTypeFilter;
