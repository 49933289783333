import React, { Component } from 'react';
import _ from 'lodash';

class PositionDataForm extends Component {
	constructor(props){
		super(props)

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(){
		var position = _.clone(this.props.position);
		position.reference_id = this.refs.reference_id.value;
		position.level_id = this.refs.level_id.value;
		position.code = this.refs.code.value;
		position.name = this.refs.name.value;
		this.props.handleChangeData(position);
	}

	render() {
		return (
			<div>
				<div className='row form-group'>

					<div className='col-md-12'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label'><strong>Reference</strong></label>
							</div>
							<div className='col-md-12'>
								<select 
									ref='reference_id' 
			                        className='form-control form-control-sm'
			                        value={this.props.position.reference_id}
			                        onChange={this.handleChange}>
			                        <option value={''} key={'Please Select'}>-- Please Select --</option>
			                    	{!_.isEmpty(this.props.references) && _.map(this.props.references, (ref) => {
			                      		return <option value={ref.id} key={ref.id}>{`${ref.attributes.code} - ${ref.attributes.name}`}</option>
			                    	})}
			                  	</select>
							</div>
						</div>
					</div>

					<div className='col-md-12'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>Code</strong></label>
							</div>
							<div className='col-md-12'>
								<input
									type='text' 
									ref='code' 
									value={this.props.position.code}
									className='form-control' 
									placeholder='Code...'
									onChange={this.handleChange}/>
							</div>
						</div>
					</div>

					<div className='col-md-12'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>Name</strong></label>
							</div>
							<div className='col-md-12'>
								<input
									type='text' 
									ref='name' 
									value={this.props.position.name}
									className='form-control' 
									placeholder='Name...'
									onChange={this.handleChange}/>
							</div>
						</div>
					</div>

					<div className='col-md-12'>
						<div className='row form-group'>
							<div className='col-md-12'>
								<label className='field-label required'><strong>Level</strong></label>
							</div>
							<div className='col-md-12'>
								<select 
									ref='level_id' 
			                        className='form-control form-control-sm'
			                        value={this.props.position.level_id}
			                        onChange={this.handleChange}>
			                        <option value={''} key={'Please Select'}>-- Please Select --</option>
			                    	{_.map(this.props.levels, (level) => {
			                      		return <option value={level.id} key={level.id}>{level.attributes.name}</option>
			                    	})}
			                  	</select>
							</div>
						</div>
					</div>

				</div>
			</div>
		)
	}
}

export default PositionDataForm;