import React, { Component } from 'react';
import _ from 'lodash';

import IssueNumberStore from '../../../stores/IssueNumberStore';

class IssueNumberPanel extends Component {
  constructor(props){
    super(props)

    this.state = {
      next_issue_number_by_book: [ 
        { name: "ทั่วไป", next_number: "1" },
        { name: "ลับ", next_number: "1" },
        { name: "คำสั่ง", next_number: "1" },
        { name: "รับรอง", next_number: "1" },
      ]
    }

    this.renderNumberBook = this.renderNumberBook.bind(this);
  }

  componentDidMount(){
    IssueNumberStore.request({url: `issue_numbers/get_next_issue_number_by_book`}).then((resp)=>{
      const next_issue_number_by_book = _.get(resp.data, 'data.next_issue_number_by_book', "");

      this.setState({
        next_issue_number_by_book: next_issue_number_by_book
      })
    });
  }

  renderNumberBook(number_book, index){
    let class_name = "text-success";

    switch(number_book.name) {
      case "ทั่วไป":
        class_name = "text-success";
        break;
      case "ลับ":
        class_name = "text-danger";
        break;
      case "คำสั่ง":
        class_name = "text-warning";
        break;
      case "รับรอง":
        class_name = "text-success";
        break;
    }

    return (
      <tr key={index}>
        <td className={class_name}>{number_book.name}</td>
        <td>{number_book.next_number}</td>
        <td>
          <a onClick={() => this.props.onActionClick("ออกเลข", number_book.name)}>ออกเลข</a>
          <br />
          <br />
          <a onClick={() => this.props.onActionClick("ออกเลข", number_book.name)}>ออกเลขเวียน</a>
        </td>
      </tr>
    )
  }

  render(){
    const document = this.props.document;
    const workflow_process = this.props.workflow_process;

    return (
      <div className='row'>
        <div className='col-12 text-white text-center py-2 mb-2' style={{backgroundColor: 'rgb(49, 90, 125)'}}>
          การปฏิบัติ
        </div>

        <div className='col-12 mb-2 px-0'>
          <button type='button' className={`btn btn-sm btn-success btn-block d-none d-lg-inline`} onClick={() => this.props.toggleIssueNumberPanel()}>
            ออกเลข
          </button>
        </div>
        
        <table className='table table-bordered w-100 text-center xxs-small'>
          <tr>
            <td>เล่ม</td>
            <td>เลขที่</td>
            <td></td>
          </tr>

          {!_.isEmpty(this.state.next_issue_number_by_book) && _.map(this.state.next_issue_number_by_book, this.renderNumberBook)}
        </table>
        
        <div className='col-12 mb-2 px-0'>
          <button type='button' className={`btn btn-sm btn-secondary btn-block`} onClick={() => this.props.toggleIssueNumberPanel()}>
            ยกเลิก
          </button>
        </div>
      </div>
    )
  }
}

export default IssueNumberPanel;

