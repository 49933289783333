import React, {Component} from 'react';

import { MergeAttributes } from '../../FlatData';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

export default class SelectInformee extends Component {
	constructor(props){
		super(props)

		this.onRowSelect = this.onRowSelect.bind(this);
		this.informeeFormat = this.informeeFormat.bind(this);
	}

	informeeFormat(cell, row){
		return (
			<div style={{whiteSpace: 'normal'}}>
				<span className='mr-2'>{row.position_name}</span>
				<span className='mr-2 text-success xs-small'>{row.name}</span>
			</div>
		)
	}

	onRowSelect(row, isSelected, e) {
		this.props.onChange(row);
	}

	render(){
		const options = {
			onRowClick: this.onRowSelect,
	    sizePerPageList: []
	  };

		var data = MergeAttributes(this.props.informees);

		return (
			<div>
				<BootstrapTable 
			  	data={data}
					search={ true }
					multiColumnSearch={ true }
					options={ options }
					striped
					hover
					condensed
					pagination
					version='4'
					tableContainerClass={'table-sm'}>
				  	<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
						<TableHeaderColumn dataField='name' dataSort={ true } dataFormat={this.informeeFormat}>เรียน</TableHeaderColumn>
						<TableHeaderColumn dataField='executor_names' dataSort={ true }>ผู้ดำเนินการ</TableHeaderColumn>
				</BootstrapTable>

				<div className="modal-footer row">
					<button type='button' className="btn btn-secondary pointer" onClick={this.props.onClose}>ยกเลิก</button>
				</div>
			</div>
		)
	}
}