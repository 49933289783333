import React, { Component } from 'react';
import _ from 'lodash';

import RenderPositionRow from './RenderPositionRow';
import RenderMainGroup from './RenderMainGroup';

import GroupStore from '../../../stores/GroupStore';

export default class RenderChildren extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: false,
			group: {}
		}

		this.renderPosition = this.renderPosition.bind(this);
		this.renderGroup = this.renderGroup.bind(this);
		this.reloadData = this.reloadData.bind(this);
		this.reloadFilterData = this.reloadFilterData.bind(this);
	}

	componentWillMount() {
		if (_.includes(this.props.show, this.props.group.id)){
			this.reloadData(this.props);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.filter !== nextProps.filter && _.isEmpty(nextProps.group.attributes.parent)) {
			this.reloadFilterData(nextProps);
		}else if (!_.isEmpty(nextProps.filter) && _.isEmpty(nextProps.group.attributes.parent)){
			this.reloadFilterData(nextProps);
		}else if (_.includes(nextProps.show, nextProps.group.id)) {
			if (!_.isEmpty(nextProps.newest_loading_item) && nextProps.newest_loading_item.id === nextProps.group.id){
				this.setState({loading: true}, this.props.handleShowLoading);
				this.reloadData(nextProps);
			}else{
				if (this.props.group !== nextProps.group){
					this.reloadData(nextProps);
				}
			}
		}else{
			this.setState({
				group: {}
			});
		}
	}

	reloadData(props){
		GroupStore.request({id: props.group.id}).then((resp)=>{
			let group = _.get(resp.data, 'data', {});

			this.setState({
				group: group,
				loading: false
			})
		}).then(()=>{
			this.setState({loading: false});
		})
	}

	reloadFilterData(props){
		let url = `/groups/${props.group.id}`;
		if (props.filter) {
			url = `/groups/${props.group.id}?filter=${props.filter.value}`
		}
		GroupStore.request({url: url}).then((resp)=>{
			let group = _.get(resp.data, 'data', {});

			this.setState({
				group: group,
				loading: false
			})
		}).then(()=>{
			this.setState({loading: false});
		})
	}

	renderPosition(position, index){
		return (
			<RenderPositionRow 
				key={index}
				focus={this.props.focus}
				position={position}
				onFocus={this.props.onFocus} />
		)
	}

	renderGroup(group, index){
		return (
			<RenderMainGroup 
				key={index}
				focus={this.props.focus}
				group={group}
				filter={this.props.filter}
				onFocus={this.props.onFocus} />
		)
	}

	renderChildren(){
		return (
			<ul className='ul-group-list'>
				{!_.isEmpty(this.state.group.attributes.positions) && (
					_.map(this.state.group.attributes.positions, this.renderPosition)
				)}

				{!_.isEmpty(this.state.group.attributes.children) && (
					_.map(this.state.group.attributes.children, this.renderGroup)
				)}
			</ul>
		)
	}

	render(){
		var children = null;
		if (!_.isEmpty(this.state.group)){
			children = this.renderChildren();
		}
		return (
			<div>
				{this.state.loading && (
					<i className="fa fa-spinner mr-3 loading-spin" aria-hidden="true"></i>
				)}

				{children}
			</div>
		)
	}
}