import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import './stylesheets/SystemNotificationPage.css';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { thaiDateTimeFormatter } from '../../utils/bootstrapTableFormatters';
import SystemNotificationStore from '../../stores/SystemNotificationStore';
import Permission from '../../components/Permission';
import Loading from '../../components/Loading';
import { MergeAttributes } from '../../components/FlatData';
import SimpleModal from '../../components/SimpleModal';

class SystemNotificationPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      isDataFetched: false,
      system_notifications: [],
      pagination: {},
      searchText: '',
      content: '',
      open: false,
      show: false,
      showData: {}
    }

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);

    this.reloadData = this.reloadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeAndReload = this.closeAndReload.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount(){
    if (!Permission.isAdmin()) {
      window.location.href = '/receive_documents/mydoc';
    }else{
      SystemNotificationStore.request({}).then((resp)=>{
        const system_notifications = _.get(resp.data, 'data', []);
        const pagination = _.get(resp.data, 'meta.pagination', {});

        this.setState({
          system_notifications: system_notifications,
          pagination: pagination
        });
      }).then(()=>{
        this.setState({isDataFetched: true});
      })
    }
  }

  reloadData(){
    this.setState({isDataFetched: false});

    SystemNotificationStore.request({}).then((resp)=>{
      const system_notifications = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        system_notifications: system_notifications,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, system_notifications: []});
    const { searchText } = this.state;

    SystemNotificationStore.request({url: `system_notifications?page=${page}&searchText=${searchText}`}).then((resp)=>{
      const system_notifications = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        system_notifications: system_notifications,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, system_notifications: []});

    SystemNotificationStore.request({url: `system_notifications?page=1&searchText=${searchText}`}).then((resp)=>{
      const system_notifications = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        system_notifications: system_notifications,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  _setTableOption(){ 
    if(this.state.isDataFetched){
      return "ไม่มีข้อมูล";
    }else{
      return <Loading />
    }
  }

  handleChange(event){
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event){
    event.preventDefault();
    console.log('handleSave');

    const { content } = this.state;
    let self = this;
    SystemNotificationStore.save(null, {
      system_notification: {
        content: content,
      }
    }).then(()=>{
      this.closeAndReload();
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message);
    });
  }

  closeAndReload(){
    this.setState({open: false}, this.reloadData());
  }

  onRowClick(row) {
    this.setState({
      show: true, 
      showData: row
    });
  }

  render(){
    const { system_notifications } = this.state;
    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);
    const options = {
      sizePerPageList: [],
      sizePerPage: 20,
      page: active_page,
      noDataText: this._setTableOption(),
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500,
      onRowClick: this.onRowClick
    };
    var data = [];
    if (!_.isEmpty(system_notifications)){
      data = MergeAttributes(system_notifications);
    }

    return (
      <div className='settings-page'>

        <div className="row">
          <div className="col-8">
            <h2>แสดงข้อความแจ้งเตือน</h2>
          </div>
          <div className="col-4 text-right">
            <button className='btn btn-success' onClick={ () => this.setState({open: true, content: ''}) }>
              <span>เพิ่ม</span>
            </button>
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-12 rui-system-notification-setting'>
            <BootstrapTable 
              data={data}
              remote={ true }
              fetchInfo={{dataTotalSize: total_items_count}}
              search={ true }
              multiColumnSearch={ true }
              options={ options }
              striped
              hover
              pagination
              version='4'
              tableContainerClass={'table-sm'}
              trClassName='td-white-space-normal'>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='content'>ข้อความแจ้งเตือน</TableHeaderColumn>
              <TableHeaderColumn dataField='created_at' dataFormat={ thaiDateTimeFormatter } dataSort={ true } width='200'>วันเวลาที่แจ้งเตือน</TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>

        <SimpleModal 
          isOpen={this.state.open}
          title={'สร้างข้อความแจ้งเตือน'}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={ () => this.setState({open: false}) }>
            <form onSubmit={this.handleSubmit}>
              <div className='row form-group'>
                <div className='col-12 font-weight-bold'>
                  <label>ข้อความที่ต้องการแจ้งเตือน</label>
                </div>
                <div className='col-12'>
                  <textarea 
                    name='content' 
                    value={this.state.content}
                    className='form-control'
                    style={{minHeight: '150px'}}
                    onChange={this.handleChange} />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <button type='submit' className='btn btn-primary'>แจ้งเตือน</button>
                </div>
              </div>
            </form>
        </SimpleModal>

        <SimpleModal 
          isOpen={this.state.show}
          title={'ข้อมูลการแจ้งเตือน'}
          size={'modal-lg'}
          showSubmitButton={false}
          onClose={ () => this.setState({show: false, showData: {}}) }>
            <div className='row'>
              <div className='col-12'>

                <div className='row form-group'>
                  <div className='col-12 font-weight-bold'>
                    <label>วันเวลาที่แจ้งเตือน</label>
                  </div>
                  <div className='col-12'>
                    {_.get(this.state.showData, 'created_at', '') && moment(_.get(this.state.showData, 'created_at', ''), "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}
                  </div>
                </div>
                <div className='row form-group'>
                  <div className='col-12 font-weight-bold'>
                    <label>ข้อความการแจ้งเตือน</label>
                  </div>
                  <div className='col-12'>
                    {_.get(this.state.showData, 'content', '')}
                  </div>
                </div>

              </div>
            </div>
        </SimpleModal>
      </div>
    )
  }
}

export default SystemNotificationPage;