import React, { Component } from 'react';
import _ from 'lodash';
import { ActionCable } from 'react-actioncable-provider';

import ManageUsersLayout from './ManageUsersLayout';
import DocumentLayout from './DocumentLayout';
import ReportLayout from './ReportLayout';
import MainLayout from './MainLayout';
import OrganizationLayout from './OrganizationLayout';
import StoragesLayout from './StoragesLayout';

import Pathname from '../components/Pathname';
import LoginSession from '../stores/LoginSession';
import SiteSettingStore from '../stores/SiteSettingStore';

export default class AppLayout extends Component {
	constructor(props){
		super(props)

		this.state = {
			loading: true,
			siteSetting: {}
		}

		this.handleLogout = this.handleLogout.bind(this);
		this.handleReceivedNotification = this.handleReceivedNotification.bind(this);
	}

	componentDidMount(){
		SiteSettingStore.request({}).then((resp)=>{
      const siteSetting = _.get(resp.data, 'data', []);
      this.setState({
      	siteSetting: siteSetting
      });
    }).then(()=>{
    	this.setState({loading: false});
    })
	}

	handleLogout(){
		LoginSession.logout();
	}

	handleReceivedNotification(response) {
		setTimeout(
	    function() {
	      // this.setState({position: 1});
	      alert(response.content);
	    }
	    .bind(this),
	    1000
		);
    // console.log('response', response)
    // alert(response.content);
  };

	render (){
		const prefixPathname = Pathname.prefixPathname(this.props.location);
		var layout = null;

		switch(prefixPathname){
			case 'manage_users': 
				layout = <ManageUsersLayout location={this.props.location} siteSetting={this.state.siteSetting} />
				break;
			case 'templates':
				layout = <DocumentLayout location={this.props.location} siteSetting={this.state.siteSetting} />
				break;
			case 'frontpage':
				layout = <DocumentLayout location={this.props.location} siteSetting={this.state.siteSetting} />
				break;
			case 'reports':
				layout = <ReportLayout location={this.props.location} siteSetting={this.state.siteSetting} />
				break;
			case 'organizations':
				layout = <OrganizationLayout location={this.props.location} siteSetting={this.state.siteSetting} />
				break;
			case 'storages':
				layout = <StoragesLayout location={this.props.location} siteSetting={this.state.siteSetting} />
				break;
			case 'manuals':
				layout = <OrganizationLayout location={this.props.location} siteSetting={this.state.siteSetting} />
				break;
			default:
				layout = <MainLayout location={this.props.location} siteSetting={this.state.siteSetting} />
		}
		return (
			<div id='AppLayout'>
				{layout}

				<ActionCable
          key={LoginSession.current.auth_token}  
          channel={{ channel: 'RoomChannel', system_notification: 'SystemNotification' }}
          onReceived={this.handleReceivedNotification} />
			</div>
		)
	}
}
