import React, {Component} from 'react';
import _ from 'lodash';

export class StepFilter extends Component {
  constructor(props) {
    super(props)
    
    this.renderStep = this.renderStep.bind(this);
  }

  handleChangeStep(step){
    this.props.handleChangeStep(step);
  }

  renderStep(step, index){
    return (
      <label 
        key={index} 
        onClick={this.handleChangeStep.bind(this, step)} 
        className={`btn btn-sm btn-secondary ${this.props.active_step === step && 'active'}`}>
        <input type="radio" name="step_filter" id={`step_filter_${index}`} defaultChecked="true" /> 
        {step}
      </label>
    )
  }

  render(){
    const step_list = ["ร่างเอกสาร", "รอตรวจทาน", "รอลงนาม", "รอออกเลข"];
    return (
      <div className='row mb-1'>
        <div className='col-md-12'>
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label
              onClick={this.handleChangeStep.bind(this, '')}
              className={`btn btn-sm btn-secondary ${this.props.active_step === '' && 'active'}`}>
              <input type="radio" name="step_filter" id={'step_filter'} defaultChecked="true" /> 
              ทั้งหมด
            </label>
            
            {_.map(step_list, this.renderStep)}
          </div>
        </div>
      </div>
    )
  }
}

export default StepFilter;
