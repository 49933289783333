import React, { Component } from 'react';
import _ from 'lodash';
import { Popover } from 'reactstrap';

import SimpleModal from '../../SimpleModal';
import GroupStore from '../../../stores/GroupStore';

import GroupForm from './GroupForm';
import PositionForm from './PositionForm';

const GroupTab = 'group';
const AddGroupMode = 'add_group';
const AddPositionMode = 'add_position';
const EditGroupMode = 'edit_group';

export default class RenderGroups extends Component {
	constructor(props){
		super(props)

		this.state = {
			popoverOpenGroupId: '',
			manage: {
				mode: '', 
				object: {}
			}
		}

		this.toggle = this.toggle.bind(this);
		this.renderGroupRow = this.renderGroupRow.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onCloseAndReloadData = this.onCloseAndReloadData.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if ((this.props.focus !== nextProps.focus) || nextProps.activeMode !== GroupTab) {
			this.setState({popoverOpenGroupId: ''});
		}
	}

	onClose(){
		this.setState({
			manage: {
				mode: '',
				object: {}
			}
		})
	}

	onCloseAndReloadData(){
		this.setState({
			manage: {
				mode: '', 
				object: {}
			}
		}, this.props.onCloseAndReloadData)
	}

	toggle(group){
		let group_id = group.id;
		if (!_.isEmpty(this.state.popoverOpenGroupId)){
			group_id = '';
		}
		this.setState({
	      	popoverOpenGroupId: group_id
	    });
	}

	renderGroups(){
		return (
			<ul className='list-unstyled'>
				{_.map(this.state.groups, this.renderGroupRow)}
			</ul>
		)
	}

	renderGroupRow(group, index){
		var item = this.props.focus;
		return (
			<li key={index} className='py-1' style={{borderBottom: '1px solid #eee'}}>
				<div className='row px-2'>
					<div className='col-1 pr-0 text-center'>
						<i className="fa fa-users green-icon" aria-hidden="true"></i>
					</div>
					<div className='col-10 pr-0'>
						{group.attributes.code} - {group.attributes.name}<br />
						<small className="text-muted font-italic">{item.attributes.code} - {item.attributes.name}</small>
					</div>
					<div className='col-1 pl-0'>
						<i id={`Popover_${group.id}`}
							className="fa fa-cog pointer gray-icon" 
							aria-hidden="true"
							onClick={this.toggle.bind(this, group)}>
						</i>

						{(this.state.popoverOpenGroupId === group.id) && (
							<div>{this.renderPopover(group)}</div>
						)}
					</div>
				</div>
			</li>
		)
	}

	renderPopover(group){
		let open = false;
		if (this.state.popoverOpenGroupId === group.id){
			open = true;
		}
		return (
			<Popover placement="left" isOpen={open} target={`Popover_${group.id}`} toggle={this.toggle.bind(this, group)} style={{minWidth: '200px'}}>
			  	<ul className='p-0 m-0 popover-list'>
			  		<li className='px-2 py-1' onClick={this.onChangeMode.bind(this, AddGroupMode, group)}>
			  			<i className="fa fa-user-plus mr-2 green-icon" aria-hidden="true"></i>
			  			Add Group
			  		</li>
			  		<li className='px-2 py-1' onClick={this.onChangeMode.bind(this, AddPositionMode, group)}>
			  			<i className="fa fa-user-plus mr-2 green-icon" aria-hidden="true"></i>
			  			Add Position
			  		</li>
			  		<li className='px-2 py-1' onClick={this.onChangeMode.bind(this, EditGroupMode, group)}>
			  			<i className="fa fa-pencil mr-2 red-icon" aria-hidden="true"></i>
			  			Edit
			  		</li>
			  		<li className='px-2 py-1' onClick={this.handleDelete.bind(this, group)}>
			  			<i className="fa fa-times mr-2 red-icon" aria-hidden="true"></i>
			  			Remove
			  		</li>
			  	</ul>
			</Popover>
		)
	}

	onChangeMode(mode, object){
		this.setState({
			manage: {
				mode: mode,
				object: object
			},
			popoverOpenGroupId: ''
		})
	}

	handleDelete(group){
		if (!window.confirm(`Would you like to delete this item?`)) { return }

		GroupStore.request({
			id: group.id,
			method: 'delete'
		}).then(()=>{
			this.props.onCloseAndReloadData();
		})
	}

	render(){
		return (
			<div>
				<ul className='list-unstyled'>
					{_.map(this.props.groups, this.renderGroupRow)}
				</ul>

				{this.state.manage.mode === AddGroupMode && (
					<SimpleModal 
						isOpen={true}
						title={"Create Group"}
						showSubmitButton={false}
						onClose={this.onClose}>
						<GroupForm 
							action={'create'}
							focus={this.state.manage.object}
							active_directories={this.props.active_directories}
							onClose={this.onClose}
							onCloseAndReloadData={this.onCloseAndReloadData} />
					</SimpleModal>
				)}

				{this.state.manage.mode === EditGroupMode && (
					<SimpleModal 
						isOpen={true}
						title={"Edit Group"}
						showSubmitButton={false}
						onClose={this.onClose}>
						<GroupForm 
							action={'edit'}
							focus={this.state.manage.object}
							active_directories={this.props.active_directories}
							onClose={this.onClose}
							onCloseAndReloadData={this.onCloseAndReloadData} />
					</SimpleModal>
				)}

				{this.state.manage.mode === AddPositionMode && (
					<SimpleModal 
						isOpen={true}
						title={"Create Position"}
						showSubmitButton={false}
						onClose={this.onClose}>
						<PositionForm 
							action={'create'}
							focus={this.state.manage.object}
							onClose={this.onClose} 
							onCloseAndReloadData={this.onCloseAndReloadData} />
					</SimpleModal>
				)}
			</div>
		)
	}
}