import React, {Component} from 'react';

import { Subject } from './FormElement';

export default class SubjectForm extends Component {
  render(){
    return (
      <div className='my-3'>
        <Subject 
          subject={this.props.subject}
          handleChange={this.props.handleChange} />
      </div>
    )
  }
}