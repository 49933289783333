import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import EditDocumentForm from '../../components/department_numbers/form/EditDocumentForm';
import ArchiveDepartmentNumberButton from '../../components/department_numbers/form/ArchiveDepartmentNumberButton';
import RestoreDepartmentNumberButton from '../../components/department_numbers/form/RestoreDepartmentNumberButton';
import DepartmentNumberList from '../../components/department_numbers/DepartmentNumberList';
import DocumentParameters from '../../components/documents/DocumentParameters';
import AfterSaveLoading from '../../components/AfterSaveLoading';
import DepartmentIssueNumberByBooks from '../../components/DepartmentIssueNumberByBooks';

import DocumentStore from '../../stores/DocumentStore';
import DepartmentNumberStore from '../../stores/DepartmentNumberStore';
import WorkflowProcessStore from '../../stores/WorkflowProcessStore';

const NormalLevel = 'ปกติ';

class EditDepartmentNumberDocumentPage extends Component {
  constructor(props) {
    super(props)

    const document_id = _.get(props.match, 'params.document_id', '');
    const workflow_process_id = _.get(props.match, 'params.workflow_process_id', '');

    this.state = {
      isDataFetched: false,
      document_id: document_id,
      workflow_process_id: workflow_process_id,
      document: {},
      government_service: '',
      speed_level: NormalLevel,
      secret_level: NormalLevel,
      due_date: moment(),
      document_date: moment(),
      owner: '',
      topic: '',
      informs: [{
        id: '',
        name: '',
        executors: [],
        department_group_ids: []
      }],
      position_name_list: [],
      document_template: {},
      department_issue_number: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSpeedLevel = this.handleChangeSpeedLevel.bind(this);
    this.handleChangeSecretLevel = this.handleChangeSecretLevel.bind(this);
    this.handleChangeDueDate = this.handleChangeDueDate.bind(this);
    this.handleChangeInformText = this.handleChangeInformText.bind(this);
    this.handleChangeReceiveDate = this.handleChangeReceiveDate.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleIssueDepartmentNumber = this.handleIssueDepartmentNumber.bind(this);
    this.handleCancelDepartmentNumber = this.handleCancelDepartmentNumber.bind(this);
    this.handleArchiveDepartmentNumber = this.handleArchiveDepartmentNumber.bind(this);
    this.handleRestoreUnitNumber = this.handleRestoreUnitNumber.bind(this);
  }

  componentDidMount() {
    const { document_id, workflow_process_id } = this.state;

    DocumentStore.request({url: `documents/edit_receive/${document_id}/wp/${workflow_process_id}/edit`}).then((resp)=>{
      const doc = _.get(resp.data, 'data', {});
      const document_date = !_.isEmpty(doc.attributes.document_date) ? moment(doc.attributes.document_date) : moment();
      const position_name_list = _.get(resp.data, 'included.position_name_list', []);
      const informs = _.get(doc, 'attributes.informs', []);
      const government_service = _.get(doc, 'attributes.document_attributes.government_service.value', '');
      const document_template = _.get(resp.data, 'included.document_template', []);

      this.setState({ 
        document: doc,
        due_date: !_.isEmpty(doc.attributes.due_date) ? moment(doc.attributes.due_date) : moment(),
        government_service: government_service,
        topic: doc.attributes.topic || '',
        owner: doc.attributes.owner || '',
        document_date: document_date,
        informs: informs,
        speed_level: doc.attributes.speed_level || NormalLevel,
        secret_level: doc.attributes.secret_level || NormalLevel,
        position_name_list: position_name_list,
        document_template: document_template
      });
    }).then(()=>{
      DepartmentNumberStore.request({
        method: 'GET',
        url: `department_numbers?workflow_process_id=${workflow_process_id}`
      }).then((resp)=>{
        const department_issue_number = _.get(resp.data, 'data', {});

        this.setState({
          department_issue_number: department_issue_number
        })
      }).then(()=>{
        this.setState({ isDataFetched: true });
      })
    });
  }

  handleSave(){
    var data = new FormData();
    var document_attributes = _.get(this.state.document_template, 'attributes.template_attributes', {});

    document_attributes.government_service.value = this.state.government_service;
    document_attributes.topic.value = this.state.topic;

    data = DocumentParameters._assign_receive_document_informs(data, this.state);

    if (!_.isEmpty(document_attributes)){
      _.forEach(document_attributes, function(document_attr, key){
        data.append(`document_attributes[${key}][title]`, document_attr['title']);
        data.append(`document_attributes[${key}][name]`, document_attr['name']);
        data.append(`document_attributes[${key}][can_inline_edit]`, document_attr['can_inline_edit']);
        data.append(`document_attributes[${key}][suggest_text]`, document_attr['suggest_text']);
        data.append(`document_attributes[${key}][value]`, document_attr['value']);
      })
    }

    data.append('document[due_date]', this.state.due_date || '');
    data.append('document[owner]', this.state.owner || '');
    data.append('document[document_date]', this.state.document_date || '');
    data.append('document[speed_level]', this.state.speed_level || '');
    data.append('document[secret_level]', this.state.secret_level || '');
    data.append('document[workflow_process_id]', this.state.workflow_process_id);

    DepartmentNumberStore.request({
      id: this.props.match.params.document_id,
      method: 'put',
      action: `update_workflow_process/${this.state.workflow_process_id}`,
      data: data
    }).then(()=>{
      window.location.href = "/department_numbers/department_numbers";
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    });
  }

  handleChange(event){
    const field_name = event.target.name;
    var value = event.target.value;

    this.setState({
      [field_name]: value
    });
  }

  handleChangeSpeedLevel(speed_level){
    this.setState({speed_level: speed_level});
  }

  handleChangeSecretLevel(secret_level){
    this.setState({secret_level: secret_level});
  }

  handleChangeDueDate(date){
    this.setState({due_date: date});
  }

  handleChangeInformText(value){
    let { informs } = this.state;
    informs[0].name = value;
    
    this.setState({informs: informs});
  }

  handleChangeReceiveDate(date){
    this.setState({document_date: date});
  }

  handleIssueDepartmentNumber(action_name, number_book_name){
    var data = new FormData();
    data.append('department_number[workflow_process_id]', this.state.workflow_process_id);
    data.append('department_number[number_book_name]', number_book_name);
    data.append('department_number[action_name]', action_name);

    DepartmentNumberStore.request({
      method: 'post',
      data: data
    }).then(()=>{
      window.location.reload();
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    });
  }

  handleArchiveDepartmentNumber(workflow_process_id){
    if (!window.confirm('ยันยันการเก็บเข้าแฟ้ม')) { return }

    const self = this;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      action: 'archive',
    }).then(()=>{
      window.location.href = "/department_numbers/department_numbers";
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    });
  }

  handleCancelDepartmentNumber(issue_number){
    if (!window.confirm('ยืนยันการลบเลขกรม')) { return }

    DepartmentNumberStore.request({
      id: issue_number.id,
      method: 'delete',
    }).then(()=>{
      window.location.reload();
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    });
  }

  handleRestoreUnitNumber(workflow_process_id){
    if (!window.confirm('ยันยันการย้ายไปหนังสือมาใหม่')) { return }

    const self = this;

    WorkflowProcessStore.request({
      id: workflow_process_id,
      method: 'put',
      action: 'restore',
    }).then(()=>{
      window.location.href = "/unit_numbers/unit_numbers";
    }, function(error){
      var error_message = _.get(error.response.data, 'error.message', '');
      alert(error_message)
    });
  }

  render(){
    return (
      <div>
        {!this.state.isDataFetched && <AfterSaveLoading />}
        
        <div className='row'>
          <div className="col-12 col-md-7">
            <EditDocumentForm 
              informs={this.state.informs}
              handleChange={this.handleChange}
              handleChangeSpeedLevel={this.handleChangeSpeedLevel}
              handleChangeSecretLevel={this.handleChangeSecretLevel}
              handleChangeDueDate={this.handleChangeDueDate}
              handleChangeInformText={this.handleChangeInformText}
              handleChangeReceiveDate={this.handleChangeReceiveDate}
              handleSave={this.handleSave}
              {...this.state} />
          </div>
          <div className="col-12 col-md-5">
            <DepartmentIssueNumberByBooks onActionClick={this.handleIssueDepartmentNumber} />

            {!_.isEmpty(this.state.department_issue_number) && (
              <DepartmentNumberList 
                title="เลขกรม"
                issue_numbers={this.state.department_issue_number}
                onCancelIssueNumber={this.handleCancelDepartmentNumber}
                deletable={true}
              />
            )}
            
            {_.get(this.state.document, "attributes.status") !== "Archived" && (
              <ArchiveDepartmentNumberButton
                workflow_process_id={this.state.workflow_process_id}
                handleArchiveNumber={this.handleArchiveDepartmentNumber} />
            )}
            
            {_.get(this.state.document, "attributes.status") == "Archived" && (
              <RestoreDepartmentNumberButton
                workflow_process_id={this.state.workflow_process_id}
                handleRestoreNumber={this.handleRestoreUnitNumber} />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(EditDepartmentNumberDocumentPage);
