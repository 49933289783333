import React, { Component } from 'react';

import Main from './Main';
import TopHeader from './TopHeader';
import Header from './Header';

export default class OrganizationLayout extends Component {

  render() {
    return (
      <div>
        {/*
          <TopHeader siteSetting={this.props.siteSetting} />
        */}
        <Header location={this.props.location} siteSetting={this.props.siteSetting} />
        <div className='container-fluid pb-4'>
          <div className="row">
            <div className='col-md-12'>
              <Main />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
