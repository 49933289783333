import React, {Component} from 'react';
import _ from 'lodash';
import moment from 'moment';

import SimpleModal from '../SimpleModal';

import DocumentStore from '../../stores/DocumentStore';

const ReceiveDocumentConfirmationModal = (props) => {

  const handleSubmit = () => {
    DocumentStore.request({
      url: `documents/check_alerady_received?issue_number=${props.issue_number}`,
      method: 'get'
    }).then((resp)=>{
      if(_.isEmpty(resp.data.message) || window.confirm(resp.data.message)){
        if (_.isEmpty(props.workflow_process_id)){
          props.handleSave()
        }else{
          props.handleSave(props.workflow_process_id)
        }
      } else {
        props.onCloseModal();
      }
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', 'ไม่สามารถเชื่อมต่อเครือข่ายได้ กรุณาบันทึกรับอีกครั้ง');
      alert(error_message);
    });
  }

  return (
    <SimpleModal 
      isOpen={props.open}
      title="ยืนยันการบันทึกรับหนังสือ"
      size={'modal-sm'}
      showSubmitButton={false}
      onClose={props.onCloseModal}>

      <div className="row mb-2">
        <div className="col-sm-3"></div>
        <div className="col-sm-2"><strong>เลขรับ</strong></div>
        <div className="col-sm-7">
          {props.skip_receive_number ? "ไม่ออกเลข" : props.next_receive_number}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-sm-3"></div>
        <div className="col-sm-2"><strong>วันที่</strong></div>
        <div className="col-sm-7">{new Date().toLocaleDateString('th-TH', { day: 'numeric', month: 'long', year: 'numeric' })}</div>
      </div>
      <div className="row mb-2">
        <div className="col-sm-3"></div>
        <div className="col-sm-2"><strong>เวลา</strong></div>
        <div className="col-sm-7">{moment().format('LTS')}</div>
      </div>

      <div className="modal-footer row pb-0">
        <button type='button' className="btn btn-primary pointer" onClick={handleSubmit} disabled={props.clicked}>ตกลง</button>
        <button type='button' className="btn btn-secondary pointer" onClick={props.onCloseModal}>ยกเลิก</button>
      </div> 
    </SimpleModal>
  )
}

export default ReceiveDocumentConfirmationModal;
