import React, {Component} from 'react';
import _ from 'lodash';

export default class References extends Component {
	render(){
		var ref_size = _.size(this.props.references);
		return (
			<div className='mt-2'>
				<div className='row'>
					<div className='col-1 pr-0'>{this.props.document_attributes.references.title}</div>
					<div className='col-11 pl-2'>
						{_.isEmpty(this.props.references) ? (
      				<em className='text-danger'>{this.props.document_attributes.references.suggest_text}</em>
  					) : (
  						<ol className={`mb-0 ${(ref_size > 1) ? 'pl-3 list-thai-style' : 'pl-0 list-style-none'}`}>
    						{_.map(this.props.references, (ref, index)=>{
                  
                  let text = ref.reference_text;
                  if (!_.isEmpty(ref.document_id)){
                    let ref_doc = _.find(this.props.reference_documents, {id: ref.document_id});
                    
                    if (!_.isEmpty(ref_doc)){
                      text = ref_doc.attributes.topic
                    }
                  }

    							return (
    								<li key={index}>{text}</li>
    							)
    						})}
  						</ol>
  					)}
						</div>
				</div>
			</div>
		)
	}
}