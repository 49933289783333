import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Emblem from '../../assets/images/emblem.png';

import './DocumentTemplatePage.css';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import DocumentTypeStore from '../../stores/DocumentTypeStore';
import Loading from '../../components/Loading';
import Permission from '../../components/Permission';

class DocumentTemplatePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      popoverOpenDocTypeId: '',
      document_types: [],
      document_templates: []
    };
    this.toggle = this.toggle.bind(this);
    this.renderCard = this.renderCard.bind(this);
    this.renderTemplate = this.renderTemplate.bind(this);
  }

  componentDidMount() {
    var self = this;
    DocumentTypeStore.request({}).then((resp)=>{
      let document_types = _.get(resp.data, 'data', []);
      let document_templates = _.get(resp.data, 'included.document_templates', []);

      if(Permission.canReceiveDocument() === false){
        _.remove(document_types, function(document_type) {
          return document_type.attributes.name == "หนังสือภายนอก"
        });
      }

      this.setState({
        document_types: document_types,
        document_templates: document_templates
      })
    }).then(()=>{
      self.setState({loading: false})
    });
  }

  toggle(document_type) {
    let document_type_id = document_type.id;
    if (!_.isEmpty(this.state.popoverOpenDocTypeId)){
      document_type_id = '';
    }
    this.setState({
      popoverOpenDocTypeId: document_type_id
    });
  }

  handleRedirect(id){
    this.props.history.push(`/templates/${id}/new`);
  }

  renderCard(document_type, index){
    const { document_templates } = this.state;
    const document_template = _.find(document_templates, function(t) { return t.attributes.document_type.id === document_type.id; });
    return (
      <div key={index} className='col-sm-4 col-lg-3 mb-5'>
        
          {document_type.attributes.can_select_sub_template ? (
            <div 
              id={`Popover-${index}`} 
              className='document-card note' 
              style={{backgroundImage: `url(${Emblem})`}} 
              onClick={this.toggle.bind(this, document_type)}>
              <div>
                {document_type.attributes.name}
              </div>
              <Popover placement="right" isOpen={this.state.popoverOpenDocTypeId === document_type.id} target={`Popover-${index}`} toggle={this.toggle.bind(this, document_type)} style={{minWidth: '200px'}}>
                <PopoverHeader>{document_type.attributes.name}</PopoverHeader>
                <PopoverBody>
                  {!_.isEmpty(document_type.attributes.document_templates) && _.map(document_type.attributes.document_templates, this.renderTemplate)}
                </PopoverBody>
              </Popover>
            </div>
          ) : (
            <div className='document-card note' style={{backgroundImage: `url(${Emblem})`}} onClick={this.handleRedirect.bind(this, document_template.id)}>
              {document_type.attributes.name}
            </div>
          )}
      </div>
    )
  }

  renderTemplate(document_template, index){
    const template = _.find(this.state.document_templates, {id: document_template.id});
    if (_.isEmpty(template)) { return }

    return (
      <div key={index} className='pointer lightgray_hover'  onClick={this.handleRedirect.bind(this, document_template.id)}>
        {template.attributes.name}
      </div>
    )
  }

  render(){
    return (
      <div className='row my-4' id="DocumentTemplatePage">
        {this.state.loading && <Loading />}

        {!_.isEmpty(this.state.document_types) && _.map(this.state.document_types, this.renderCard)}
      </div>
    );
  }
}

export default withRouter(DocumentTemplatePage);
