import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { MergeAttributes } from '../FlatData';

import ActingForm from './ActingForm';
import ActingTable from './ActingTable';
import ActorTable from './ActorTable';

class Form extends Component {
  constructor(){
    super();

    this.state = {
      id: null,
      acting: {},
      start_at: moment(),
      end_at: moment(),
      actors: [],
      showForm: true,
      showActingTable: false,
      showActorTable: false
    }

    this.openForm = this.openForm.bind(this);
    this.openActingTable = this.openActingTable.bind(this);
    this.openActorTable = this.openActorTable.bind(this);
    this.handleSelectActing = this.handleSelectActing.bind(this);
    this.handleSelectActor = this.handleSelectActor.bind(this);
    this.removeSelected = this.removeSelected.bind(this);
    this.changeStartAt = this.changeStartAt.bind(this);
    this.changeEndAt = this.changeEndAt.bind(this);
    this.updateActorMessage = this.updateActorMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let nextActing = nextProps.acting;

    if (nextActing.id && this.state.id !== nextActing.id) {
      let actors = nextActing.actors.map(actor => {
        return(
          {
            positions_user_id: actor.attributes.positions_user.id,
            message: actor.attributes.message
          }
        )
      });

      this.setState({
        id: nextActing.id,
        acting: {
          positions_user_id: nextActing.positions_user.id
        },
        start_at: moment.utc(nextActing.start_at, "DD/MM/YYYY"),
        end_at: moment.utc(nextActing.end_at, "DD/MM/YYYY"),
        actors: actors,
        showForm: true,
        showActingTable: false,
        showActorTable: false
      });
    } else {
      this.setState({
        id: null,
        acting: {},
        start_at: moment(),
        end_at: moment(),
        actors: [],
        showForm: true,
        showActingTable: false,
        showActorTable: false
      })
    }
  }
  
  openForm(){
    this.setState({
      showForm: true,
      showActingTable: false,
      showActorTable: false
    })
  }
  
  openActingTable(){
    this.setState({
      showForm: false,
      showActingTable: true,
      showActorTable: false
    })
  }
  
  openActorTable(){
    this.setState({
      showForm: false,
      showActingTable: false,
      showActorTable: true
    })
  }

  handleSelectActing(row, isSelected, e){
    this.setState({
      acting: {
        positions_user_id: row.id
      }
    });

    this.openForm();
  }

  handleSelectActor(row, isSelected, e){
    let actors = [...this.state.actors];
    let isIncluded = _.find(actors, {positions_user_id: row.id});

    if(isIncluded === undefined){
      this.setState({
        actors: [
          ...this.state.actors, 
          {
            positions_user_id: row.id,
            message: ""
          }
        ]
      });
    }

    this.openForm();
  }

  removeSelected(positions_user_id){
    this.setState({
      actors: _.reject(this.state.actors, { positions_user_id: positions_user_id })
    })
  }

  changeStartAt(date){
    this.setState({
      start_at: date.utc()
    });
  }

  changeEndAt(date){
    this.setState({
      end_at: date.utc()
    });
  }

  updateActorMessage(positions_user_id, event){
    var newActors = this.state.actors;
    var actor = _.find(newActors, {positions_user_id: positions_user_id});
    actor.message = event.target.value;

    this.setState({
      actors: newActors
    });
  }

  handleSubmit(){
    console.log("this.state.acting", this.state.acting);

    this.props.onSubmit({
      id: this.state.id,
      acting: this.state.acting,
      start_at: this.state.start_at,
      end_at: this.state.end_at,
      actors: this.state.actors,
    });
  }

  handleRemove(){
    this.props.onRemove(this.state.id);
  }

  render(){
    const positions_users = MergeAttributes(this.props.positions_users);

    return(
      <form>
        {this.state.showForm && (
          <ActingForm positions_users={positions_users}
                      acting={this.state.acting}
                      start_at={this.state.start_at}
                      end_at={this.state.end_at}
                      actors={this.state.actors}
                      openActingTable={this.openActingTable}
                      openActorTable={this.openActorTable}
                      removeSelected={this.removeSelected}
                      changeStartAt={this.changeStartAt}
                      changeEndAt={this.changeEndAt}
                      updateActorMessage={this.updateActorMessage} />
        )}

        {this.state.showActingTable && (
          <ActingTable  positions_users={positions_users}
                        onSelectActing={this.handleSelectActing}
                        openForm={this.openForm} />
        )}

        {this.state.showActorTable && (
          <ActorTable positions_users={positions_users}
                      onSelectActor={this.handleSelectActor}
                      openForm={this.openForm} />
        )}
        
        <div className="modal-footer col-12">
          <button type='button' className="btn btn-primary pointer" onClick={this.handleSubmit}>บันทึกข้อมูล</button>
          <button type='button' className="btn btn-secondary pointer" onClick={this.handleRemove}>ยกเลิกรักษาราชการแทน</button>
          <button type='button' className="btn btn-secondary pointer" onClick={this.props.onClose}>ยกเลิก</button>
        </div>
      </form>
    )
  }
}

export default Form;
