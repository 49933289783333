import React from 'react';
import _ from 'lodash';

const SelectedInformee = (props) => (
  <div className="card mb-1">
    <div className="card-body">
      <input type="text" className="form-control from-control-sm mb-2" value={props.informee_text} onChange={(event) => props.setInformeeText(event, props.positions_user_id)} />
      
      {_.includes(props.acting_positions_user_ids, props.positions_user_id) && (
        <div className="px-2 pt-2 pb-1 mb-2 bg-danger text-white position-relative">
          {props.informee_name_with_position}
          <i className='fa fa-eject text-black close-icon' onClick={() => props.showActors(props.positions_user_id)}></i>
        </div>
      )}
      {!_.includes(props.acting_positions_user_ids, props.positions_user_id) && (
        <div className="px-2 pt-2 pb-1 bg-success text-white">{props.informee_name_with_position}</div>
      )}

      <i className='fa fa-times close-icon text-danger' onClick={() => props.handleRemoveTarget(props.positions_user_id)}></i>
    </div>
  </div>
)

export default SelectedInformee;
