import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Pathname from './Pathname';

import page_portrait from '../assets/images/page_portrait.png';

export default class ReportSidebar extends Component {
  render(){
    var fullPathname = Pathname.fullPathname(this.props.location);

    return(
      <div className="reports-sidebar pl-4 pl-lg-0 sub-sidebar-menu">
        <ul className="nav flex-column nav-pills py-4 ml-0 reports-sidebar" id="v-pills-tab" role="tablist">
          <li className="nav-item">
            <Link to='/reports/documentation_summary' className={`pl-3 nav-link ${(fullPathname === '/reports/documentation_summary') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              สรุปการ รับ-ส่ง หนังสือ
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/reports/receive' className={`pl-3 nav-link ${(fullPathname === '/reports/receive') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              รับเอกสาร
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/reports/aware' className={`pl-3 nav-link ${(fullPathname === '/reports/aware') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              ส่งเอกสารเพื่อทราบ
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/reports/internal' className={`pl-3 nav-link ${(fullPathname === '/reports/internal') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              ส่งเอกสารภายในองค์กร
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/reports/external' className={`pl-3 nav-link ${(fullPathname === '/reports/external') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              ส่งเอกสารภายนอกองค์กร
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/reports/system_usage' className={`pl-3 nav-link ${(fullPathname === '/reports/system_usage') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              รายงานการใช้ระบบงาน
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/reports/system_usage_statistic' className={`pl-3 nav-link ${(fullPathname === '/reports/system_usage_statistic') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              รายงานสถิติการเข้าใช้งาน
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/reports/receive_summary' className={`pl-3 nav-link ${(fullPathname === '/reports/receive_summary') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              รายงานสรุปจำนวนหนังสือรับเข้า
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/reports/wait_for_record_receive' className={`pl-3 nav-link ${(fullPathname === '/reports/wait_for_record_receive') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              รายงานหนังสือค้างรับ
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/reports/in_progress' className={`pl-3 nav-link ${(fullPathname === '/reports/in_progress') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              รายงานสรุปจำนวนหนังสือที่อยู่ในระหว่างดำเนินการ
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/reports/inconclusive' className={`pl-3 nav-link ${(fullPathname === '/reports/inconclusive') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              สถิติจำนวนเรื่องค้างรับ, ค้างส่ง
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/reports/creation_and_number' className={`pl-3 nav-link ${(fullPathname === '/reports/creation_and_number') && 'active'}`}>
              <img alt="Doc" src={page_portrait} className="mr-2" />
              สถิติการสร้างหนังสือ, ออกเลข
            </Link>
          </li>
        </ul>
      </div>
    )
  }
}