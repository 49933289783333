import React, { Component } from 'react';

export default class DeleteAction extends Component {
	render(){
		return (
			<button className="btn btn-sm btn-danger pointer" onClick={()=>this.props.onDelete()}>
				<i className="fa fa-times" aria-hidden="true"></i>&nbsp;
				Remove
			</button>
		)
	}
}