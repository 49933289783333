import React, { Component } from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Link, withRouter } from 'react-router-dom';

import './stylesheets/CancelDocumentsPage.css';

import { documentNumberFormatter, thaiDateFormatter, dashFormatter } from '../../utils/bootstrapTableFormatters';
import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';

import WorkflowProcessStore from '../../stores/WorkflowProcessStore';

class CancelDocumentsPage extends Component {
  constructor() {
    super()

    this.state = {
      isDataFetched: false,
      workflow_processes: [],
      pagination: {},
      searchText: ''
    }

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    WorkflowProcessStore.request({url: 'workflow_processes?type=cancelled&page=1'}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handlePageChange(page, sizePerPage){
    this.setState({isDataFetched: false, workflow_processes: []});
    const { searchText } = this.state;

    WorkflowProcessStore.request({url: `workflow_processes?type=cancelled&page=${page}&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  handleSearchChange(searchText){
    if (searchText.length === 1) { return }
    this.setState({isDataFetched: false, workflow_processes: []});

    WorkflowProcessStore.request({url: `workflow_processes?type=cancelled&page=1&searchText=${searchText}`}).then((resp)=>{
      const workflow_processes = _.get(resp.data, 'data', []);
      const pagination = _.get(resp.data, 'meta.pagination', {});

      this.setState({
        workflow_processes: workflow_processes,
        pagination: pagination,
        searchText: searchText
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    })
  }

  _setTableOption(){ 
    if(this.state.isDataFetched){
      return "ไม่มีข้อมูล";
    }else{
      return <Loading />
    }
  }

  render(){
    const total_items_count = _.get(this.state.pagination, 'total_items_count', 0);
    const active_page = _.get(this.state.pagination, 'active_page', 1);
    const options = {
      sizePerPageList: [],
      sizePerPage: 10,
      page: active_page,
      noDataText: this._setTableOption(),
      onPageChange: this.handlePageChange,
      onSearchChange: this.handleSearchChange,
      searchDelayTime: 500
    };
    var data = [];
    if (!_.isEmpty(this.state.workflow_processes)){
      data = MergeAttributes(this.state.workflow_processes);
    }

    return (
      <div className="inbox">
        <div className='row'>
          <div className='col-7'>
            <h2>เอกสารยกเลิก</h2>
          </div>
          <div className='col-5 text-right d-none d-lg-block'>
            <Link to='/templates' className='btn btn-success'>ร่างเอกสาร</Link>
          </div>
        </div>
        <div className='rui-cancelleddoc'>
          <BootstrapTable 
            data={data}
            remote={ true }
            fetchInfo={{dataTotalSize: total_items_count}}
            search={ true }
            multiColumnSearch={ true }
            options={ options }
            striped
            hover
            pagination
            version='4'
            tableContainerClass={'table-sm'}
            trClassName='td-white-space-normal'>
            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn width="130" dataFormat={documentNumberFormatter}>ที่</TableHeaderColumn>
            <TableHeaderColumn dataField='created_at' dataFormat={ thaiDateFormatter } width='140'>เอกสารลงวันที่</TableHeaderColumn>
            <TableHeaderColumn dataField='informee_name' columnTitle dataSort={ true }>เรียน</TableHeaderColumn>
            <TableHeaderColumn dataField='topic' columnTitle dataSort={ true }>เรื่อง</TableHeaderColumn>
            <TableHeaderColumn dataField='from_department' columnTitle dataSort={ true }>จาก</TableHeaderColumn>
            <TableHeaderColumn dataField='to_department' columnTitle dataSort={ true }>ถึง</TableHeaderColumn>
            <TableHeaderColumn dataFormat={ dashFormatter } width='70'>แผนภาพ</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    )
  }
}

export default withRouter(CancelDocumentsPage);