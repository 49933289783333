import React, {Component} from 'react';

import InlineEditContent from './elements/InlineEditContent';

export class ContentsSection extends Component {
  render(){
    const { content, loading } = this.props;

    let content_value = content.value;
    if (content_value){
      content_value = content_value.replace(/\t/g, '&nbsp;'.repeat(20));
      content_value = content_value.replace(/<p>(&nbsp;){20}/g, '<p>\t');
    }

    return (
      <InlineEditContent 
        page_number={content.page_number}
        suggest_text={content.suggest_text}
        content={content_value} 
        handleChange={this.props.handleChangeContent}
        loading={loading} />
    )
  }
}

export default ContentsSection;
