import React, { Component } from 'react';
import _ from 'lodash';

import NoteSelectInformee from './NoteSelectInformee';
import NoteSelectSignee from './NoteSelectSignee';
import PositionsUserTable from '../PositionsUserTable';
import InformeePositionsUserTable from '../InformeePositionsUserTable';
import DepartmentGroupTable from '../DepartmentGroupTable';

class Note extends Component {
  constructor(props){
    super(props)

    this.state = {
      current_form: "note",
      informee_id: null,
      informee_text: "",
      informee_name_with_position: "",
      department_group_name: "",
      department_group_members: [],
      note_text: "",
      signee_id: null,
      signee_name_with_position: "",
      actor_positions_users: [],
      actor_type: "",
      current_tab: "nav-internal",
    }

    this.openInformeeModal = this.openInformeeModal.bind(this);
    this.openSigneeModal = this.openSigneeModal.bind(this);
    this.handleSelectInformee = this.handleSelectInformee.bind(this);
    this.handleSelectDepartmentGroup = this.handleSelectDepartmentGroup.bind(this);
    this.handleClearInformee = this.handleClearInformee.bind(this);
    this.handleShowActors = this.handleShowActors.bind(this);
    this.handleSelectActor = this.handleSelectActor.bind(this);
    this.setInformeeText = this.setInformeeText.bind(this);
    this.handleSelectSignee = this.handleSelectSignee.bind(this);
    this.setNoteText = this.setNoteText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openTab = this.openTab.bind(this);
    this.isActive = this.isActive.bind(this);
  }

  handleSubmit() {
    const note_data = _.omit({...this.state}, ["current_form", "actor_positions_users"])

    this.props.onSubmitAction(note_data);
  }

  openInformeeModal(){
    this.setState({current_form: "selectInformee"});
  }

  openSigneeModal(){
    this.setState({current_form: "selectSignee"});
  }

  handleSelectInformee(row, isSelected, e){
    this.setState({
      informee_id: row.id,
      informee_text: row.position_name,
      informee_name_with_position: `${row.full_name} ${row.position_name} ${row.department_name}`,
      department_group_name: "",
      department_group_members: [],
      current_form: "note",
    });
  }

  handleSelectDepartmentGroup(row, isSelected, e){
    this.setState({
      informee_id: row.informee_positions_user_id,
      department_group_name: row.name,
      department_group_members: row.member_name_with_positions,
      informee_text: row.informee_name,
      informee_name_with_position: row.informee_name_with_position,
      current_form: "note",
    });
  }

  handleClearInformee(){
    this.setState({
      informee_id: "",
      department_group_name: "",
      department_group_members: "",
      informee_text: "",
      informee_name_with_position: "",
      current_form: "note",
    });
  }

  handleShowActors(positions_user_id, actor_type){
    const acting =  _.find(this.props.actings, (acting) => {
                      return acting.attributes.positions_user.id === positions_user_id
                    });
    const actor_positions_users = acting.attributes.actor_positions_users;

    this.setState({
      actor_positions_users: actor_positions_users,
      actor_type: actor_type,
      current_form: "selectActor",
    });
  }


  handleSelectActor(row, isSelected, e){
    if(this.state.actor_type === 'informee'){
      this.setState({
        informee_id: row.positions_user_id,
        informee_name_with_position: `${row.full_name} ${row.position_name} ${row.department_name}`,
        current_form: "note",
      });
    }

    if(this.state.actor_type === 'signee'){
      this.setState({
        signee_id: row.positions_user_id,
        signee_name_with_position: `${row.full_name} ${row.position_name} ${row.department_name}`,
        current_form: "note",
      });
    }
  }

  handleSelectSignee(row, isSelected, e){
    this.setState({
      signee_id: row.id,
      signee_name_with_position: `${row.full_name} ${row.position_name} ${row.department_name}`,
      current_form: "note",
    });
  }

  setInformeeText(event){
    const informee_text = event.target.value;
    this.setState({informee_text: informee_text});
  }

  setNoteText(event){
    const note_text = event.target.value;
    this.setState({note_text: note_text});
  }

  handleClose(){
    this.setState({current_form: "note"});
  }

  openTab(tab_id){
    this.setState({ current_tab: tab_id });
  }

  isActive(tab_id){
    if(tab_id === this.state.current_tab){
      return "show active";
    } else {
      return "";
    }
  }

  render(){
    return (
      <div>
        { this.state.current_form === 'note' && (
          <div>
            <NoteSelectInformee openInformeeModal={this.openInformeeModal}
                                setInformeeText={this.setInformeeText}
                                informee_id={this.state.informee_id} 
                                informee_text={this.state.informee_text} 
                                informee_name_with_position={this.state.informee_name_with_position}
                                department_group_name={this.state.department_group_name}
                                department_group_members={this.state.department_group_members}
                                acting_positions_user_ids={this.props.acting_positions_user_ids}
                                clearDepartmentGroup={this.handleClearInformee}
                                showActors={this.handleShowActors} />

            <div className="row">
              <div className="col-12">
                <h4>เพื่อดำเนินการ</h4>
                <textarea cols="30" rows="10" className="w-100" value={this.state.note_text} onChange={this.setNoteText}></textarea>
              </div>
            </div>

            <NoteSelectSignee openSigneeModal={this.openSigneeModal} 
                              signee_id={this.state.signee_id}
                              signee_name_with_position={this.state.signee_name_with_position}
                              acting_positions_user_ids={this.props.acting_positions_user_ids}
                              showActors={this.handleShowActors} />

            <div className="modal-footer row mt-2">
              <button type='button' className="btn btn-primary pointer" disabled={this.props.clicked} onClick={this.handleSubmit}>ยืนยัน</button>
              <button type='button' className="btn btn-secondary pointer" onClick={this.props.onCloseModal}>ยกเลิก</button>
            </div>
          </div>
        )}
        { this.state.current_form === 'selectInformee' && (
          <div>
            <nav className="mb-1">
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className={`nav-item nav-link ${this.isActive("nav-internal")}`} id="nav-internal-tab" data-toggle="tab" href="#nav-internal" role="tab" aria-controls="nav-internal" aria-selected="true"
                    onClick={() => this.openTab("nav-internal")}>
                  หน่วยงานภายใน
                </a>
                <a className={`nav-item nav-link ${this.isActive("nav-department-groups")}`} id="nav-department-groups-tab" data-toggle="tab" href="#nav-department-groups" role="tab" aria-controls="nav-department-groups" aria-selected="false"
                    onClick={() => this.openTab("nav-department-groups")}>
                  สารบรรณหน่วยงาน
                </a>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className={`tab-pane fade ${this.isActive("nav-internal")}`} id="nav-internal" role="tabpanel" aria-labelledby="nav-internal-tab">
                <PositionsUserTable positions_users={this.props.internal_users}
                                            onSelect={this.handleSelectInformee}
                                            onClose={this.handleClose} />
              </div>
              <div className={`tab-pane fade ${this.isActive("nav-department-groups")}`} id="nav-department-groups" role="tabpanel" aria-labelledby="nav-department-groups-tab">
                <DepartmentGroupTable department_groups={this.props.department_groups}
                                      onSelect={this.handleSelectDepartmentGroup}
                                      onClose={this.handleClose} />
              </div>
            </div>
          </div>
        )}
        { this.state.current_form === 'selectSignee' && (
          <PositionsUserTable positions_users={this.props.internal_users}
                              onSelect={this.handleSelectSignee}
                              onClose={this.handleClose} />
        )}
        { this.state.current_form === 'selectActor' && (
          <PositionsUserTable positions_users={this.state.actor_positions_users}
                              onSelect={this.handleSelectActor}
                              onClose={this.handleClose} />
        )}
      </div>
    )
  }
}

export default Note;
