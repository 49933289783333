import React, {Component} from 'react';
import _ from 'lodash';

const EditFieldName = 'draft_by_department_id';

export default class DraftPosition extends Component {
  constructor(props){
    super(props)

    this.onChange = this.onChange.bind(this);
  }

  onChange(event){
    let value = event.target.value;
    this.props.handleChange(EditFieldName, value);
  }

  render(){
    const { current_user_departments, document } = this.props;

    let draft_by_department_id = '';
    if (document.draft_by_department_id){
      draft_by_department_id = document.draft_by_department_id
    }

    if (!draft_by_department_id && !_.isEmpty(current_user_departments)){
      draft_by_department_id = _.first(current_user_departments).id;
    }

    return (
      <div className='row my-3'>
        <div className='col-12'>
          ร่างโดยหน่วยงาน
        </div>
        <div className='col-12'>
          <select 
            name='draft_by_department_id' 
            className='form-control form-control-sm'
            value={draft_by_department_id}
            onChange={this.onChange}>
            {_.map(current_user_departments, (d, index) => {
                return <option value={d.id} key={"draft_"+index}>{d.attributes.code} - {d.attributes.name}</option>
            })}
          </select>
        </div>
      </div>
    )
  }
}
