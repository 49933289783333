import React from 'react';

const UndoConfirmation = (props) => (
  <div className="row">
    <div className="col-12 mb-2">ดึงกลับภาระงานจำนวน <strong>{props.undoWorkflowMigrationIds.length}</strong> ภาระงาน</div>

    <div className="modal-footer col-12">
      <button type='button' className="btn btn-primary pointer" onClick={props.onSubmit}>ดึงกลับภาระงาน</button>
      <button type='button' className="btn btn-secondary pointer" onClick={props.onClose}>ยกเลิก</button>
    </div>
  </div>
)

export default UndoConfirmation;
