import React, { Component } from 'react';
import _ from 'lodash';

import SimpleModal from '../SimpleModal';
import SiteSettingStore from '../../stores/SiteSettingStore';

export default class UploadLogo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: this.props.open,
      file: this.props.logo,
      preview_url: '',
      error_message: ''
    }

    this.onChange = this.onChange.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.open !== nextProps.open){
      this.setState({open: nextProps.open});
    }
  }

  onChange(event){
    var reader = new FileReader();
    var file = event.target.files[0];
    if (file.size === 0) { return }

    reader.onloadend = () => {
      this.setState({
        file: file,
        preview_url: reader.result
      });
    }
    reader.readAsDataURL(file);
    event.target.value = '';
  }

  uploadLogo(){
    var self = this;
    if (this.state.file === undefined || this.state.file === null) { return }
    var data = new FormData();
    data.append('site_setting[logo]', this.state.file);

    SiteSettingStore.request({
      method: 'put',
      data: data,
    }).then(()=>{
      this.setState({open: false}, this.props.reloadData());
    }, function(error){
      let error_message = _.get(error.response.data, 'error.message', '');
      self.setState({error_message: error_message});
    });
  }

  onClose(){
    this.setState({open: false}, this.props.onClose());
  }

  render(){
    return (
      <SimpleModal 
        isOpen={this.state.open}
        title={'อัพโหลดโลโก'}
        showSubmitButton={false}
        onClose={this.onClose}>
        <div className='row mb-3'>
          {this.state.error_message && (
            <div className='col-12 text-danger'>
              {this.state.error_message}
            </div>
          )}

          {(!_.isEmpty(this.props.logo) && _.isEmpty(this.state.preview_url)) && (
            <div className='col-8 ml-auto mr-auto text-center'>
              <img alt="LOGO" src={this.props.logo} style={{maxWidth: '100%'}} />
            </div>
          )}

          {!_.isEmpty(this.state.preview_url) && (
            <div className='col-8 ml-auto mr-auto text-center'>
              <img alt='LOGO' src={this.state.preview_url} style={{maxWidth: '100%'}} />
            </div>
          )}
        </div>
        <div className='row mb-3'>
          <div className='col-12'>
            <input type='file' name='file' onChange={this.onChange} />
          </div>
        </div>
        <div className="modal-footer row">
          <button type='submit' className="btn btn-primary pointer" onClick={this.uploadLogo}>บันทึก</button>
          <button type='button' className="btn btn-secondary pointer" onClick={this.onClose}>ยกเลิก</button>
        </div>
      </SimpleModal>
    )
  }
}