import React, { Component } from 'react';
import _ from 'lodash';

import LoginSession from '../stores/LoginSession';
import { MergeAttributes } from './FlatData';
import Permission from './Permission';

class DepartmentList extends Component {
  constructor(){
    super();

    this.toggleSelectDepartment = this.toggleSelectDepartment.bind(this);
    this.toggleSelectGroup = this.toggleSelectGroup.bind(this);
    this.renderBatchSelect = this.renderBatchSelect.bind(this);
    this.renderDepartmentRow = this.renderDepartmentRow.bind(this);
    this.renderGroupRow = this.renderGroupRow.bind(this);
  }

  renderBatchSelect(group_ref){
    const department = _.find(this.props.all_groups, { id: group_ref.id });

    if(department.attributes.name === 'Administrator'){ return null; }

    return (
      <li key={group_ref.id}>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id={`group_${group_ref.id}`} onClick={(e) => this.toggleSelectDepartment(department.id, e)} onKeyPress={this.props.onKeyPress} />
          <label className="form-check-label" htmlFor={`group_${group_ref.id}`}>
            {department.attributes.name}
          </label>
        </div>
      </li>
    )
  }

  toggleSelectDepartment(department_id, e){
    e.target.checked ? this.props.batchSelectGroups(department_id) : this.props.batchRemoveGroups(department_id);
  }

  toggleSelectGroup(group_id, e){
    e.target.checked ? this.props.selectGroup(group_id) : this.props.removeGroup(group_id);
  }

  renderDepartmentRow(group_ref, index){
    const department = _.find(this.props.all_groups, { id: group_ref.id });
    let groups = department.attributes.children;

    return (
      <div key={index} className='mt-2'>
        <h4>{department.attributes.name}</h4>
        <ul className="ul-group-list" id={`department-${department.id}`}>
          {_.map(groups, this.renderGroupRow)}
        </ul>
      </div>
    )
  }

  renderGroupRow(group_ref){
    const group = _.find(this.props.all_groups, { id: group_ref.id });

    if(group.attributes.name === 'Administrator'){ return null; }

    const checked = _.includes(this.props.selected_group_ids, group.id);

    return (
      <li key={group_ref.id}>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" value={group.id} checked={checked} onClick={(e) => this.toggleSelectGroup(group.id, e)} onKeyPress={this.props.onKeyPress} id={`group_${group_ref.id}`} />
          <label className="form-check-label" htmlFor={`group_${group_ref.id}`}>
            
            { ( _.startsWith(group.attributes.name, 'หัวหน้า') || 
                _.startsWith(group.attributes.name, 'ผู้อำนวยการ') ||
                _.startsWith(group.attributes.name, 'เลขาหน้าห้อง') ||
                _.startsWith(group.attributes.name, 'เลขานุการกรม') ) ? (
                <span className='indent'>{`- ${group.attributes.name}`}</span>
              ) : (
                <span>{group.attributes.name}</span>
              )
            }
          </label>
        </div>
      </li>
    )
  }

  render(){
    const data = MergeAttributes(this.props.all_groups);

    const root_group = this.props.root_group;
    let departments = root_group.attributes.children;

    const current_user_group = _.find(this.props.all_groups, { id: LoginSession.current.user.group_id });
    const parent_group = _.find(this.props.all_groups, { id: current_user_group.parent.id });

    if(!_.includes(_.map(departments, "id"), parent_group.id)){
      departments = _.concat(departments, parent_group);
    }
  
    // ส่วนกลาง(ยกเว้นสารบรรณกรม) จะเห็นทุกคนในหน่วยงานตนเอง
    departments = _.concat(departments, current_user_group);
  
    // เลขาหน้าห้องจะไม่เห็น สถาบัน/รพ. และ ศูนย์สุขภาพจิต
    if(Permission.isSecretary()){
      departments = _.reject(departments, (d) => {
        const department = _.find(data, { id: d.id });
       
        return department.name === "สถาบัน/รพ." || department.name === "ศูนย์สุขภาพจิต";
      });
    }
  
    // // กลุ่มงาน (สารบรรณทั่วไป) จะเห็นแค่ ส่วนกลางของหน่วยงานตัวเอง และ ภายในหน่วยงานของตัวเอง
    // if(Permission.isUnitCorrespondence()){
    //   departments = _.reject(departments, (d) => {
    //     const department = _.find(data, { id: d.id });
       
    //     return department.name === "ผู้บริหาร" || department.name === "สถาบัน/รพ." || department.name === "ศูนย์สุขภาพจิต";
    //   });
    // }

    return(
      <div className="row">
        <div className="col-12 rui-positions-user-tb">
          <h6>ถึง...หน่วยงาน</h6>
        </div>
        <div className="col-3 rui-positions-user-tb">
          <div className='mt-2'>
            <ul className="ul-group-list p-0 m-0">
              {_.map(departments, this.renderBatchSelect)}
            </ul>
          </div>
        </div>
        <div className="col-9 rui-positions-user-tb">
          {!_.isEmpty(root_group) && _.map(departments, this.renderDepartmentRow)}

          <div className='mt-2'>
            <h4>หน่วยงานภายนอก</h4>
            <ul className="ul-group-list" id={`external-department-infos`}>
              <li>
                <input 
                  type='text' 
                  name='external_department'
                  value={this.props.external_department}
                  className='form-control form-control-sm'
                  onChange={this.props.handleChange} />
              </li>
            </ul>
          </div>

          {_.isEmpty(root_group) && <div>No items</div>}
        </div>
      </div>
    )
  }
}

export default DepartmentList;
