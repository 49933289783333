import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './stylesheets/ExternalReportPage.css';

import ReportStore from '../../stores/ReportStore';
import SendDocumentTable from '../../components/reports/SendDocumentTable';
import Loading from '../../components/Loading';

export default class ExternalReportPage extends Component {
  constructor() {
    super()

    this.state = {
      isDataFetched: false,
      speed_level: '',
      secret_level: '',
      start_date: moment(),
      end_date: moment(),
      from_department: '',
      to_department: '',
      results: []
    }

    this.onChange = this.onChange.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
    ReportStore.request({
      method: 'POST',
      action: 'filter_external'
    }).then((resp)=>{
      const results = _.get(resp.data, 'data', []);

      this.setState({
        results: results
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleSubmit(){
    this.setState({isDataFetched: false});
    
    ReportStore.request({
      method: 'POST',
      action: 'filter_external',
      data: {
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        speed_level: this.state.speed_level,
        secret_level: this.state.secret_level,
        from_department: this.state.from_department,
        to_department: this.state.to_department
      }
    }).then((resp)=>{
      const results = _.get(resp.data, 'data', []);

      this.setState({
        results: results
      });
    }).then(()=>{
      this.setState({isDataFetched: true});
    });
  }

  handleDownload(){
    axios({
      method:'POST',
      url: '/api/v1/reports/filter_external',
      responseType:'arraybuffer',
      cache: false,
      data: {
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        speed_level: this.state.speed_level,
        secret_level: this.state.secret_level,
        from_department: this.state.from_department,
        to_department: this.state.to_department,
        response_type: 'csv'
      }
    }).then(function(response) {
      var dt = new Date();
      var day = dt.getDate();
      var month = dt.getMonth() + 1;
      var year = dt.getFullYear();
      var postfix = day + "-" + month + "-" + year;

      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' } )
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'รายงานส่งเอกสารภายนอกองค์กร.xlsx'
      link.click()
    });
  }

  onChange(event){
    const field_name = event.target.name;
    const value = event.target.value;

    this.setState({
      [field_name]: value
    });
  }

  changeStartDate(date){
    this.setState({start_date: date});
  }

  changeEndDate(date){
    this.setState({end_date: date});
  }

  render(){
    const {
      from_department,
      to_department,
      speed_level, 
      secret_level, 
      start_date, 
      end_date,
      isDataFetched,
      results
    } = this.state;
    
    return (
      <div>
        <h2>รายงานส่งเอกสารภายนอกองค์กร</h2>

        <div className='card'>
          <div className='p-2'>

            <div className='row mb-2'>
              <div className='col-md-2'>จากหน่วยงาน:</div>
              <div className='col-md-5'>
                <input 
                  type='text' 
                  name='from_department' 
                  value={from_department}
                  className='form-control form-control-sm'
                  onChange={this.onChange} />
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-2'>ถึงหน่วยงาน:</div>
              <div className='col-md-5'>
                <input 
                  type='text' 
                  name='to_department' 
                  value={to_department}
                  className='form-control form-control-sm'
                  onChange={this.onChange} />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-2'>ชั้นความเร็ว:</div>
              <div className='col-md-2 mb-2'>
                <select 
                  className='form-control form-control-sm'
                  name='speed_level'
                  value={speed_level}
                  onChange={this.onChange}>
                  <option value='ปกติ'>ปกติ</option>
                  <option value='ด่วน'>ด่วน</option>
                  <option value='ด่วนมาก'>ด่วนมาก</option>
                  <option value='ด่วนที่สุด'>ด่วนที่สุด</option>
                </select>
              </div>
              <div className='col-md-1 p-lg-0'>ชั้นความลับ:</div>
              <div className='col-md-2 mb-2'>
                <select 
                  className='form-control form-control-sm'
                  name='secret_level'
                  value={secret_level}
                  onChange={this.onChange}>
                  <option value='ปกติ'>ปกติ</option>
                  <option value='ลับ'>ลับ</option>
                  <option value='ลับมาก'>ลับมาก</option>
                  <option value='ลับที่สุด'>ลับที่สุด</option>
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-2'>จากวันที่:</div>
              <div className='col-md-2 mb-2 mb-md-0'>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  selected={start_date}
                  className='form-control form-control-sm'
                  placeholderText={'DD/MM/YYYY'}
                  onChange={this.changeStartDate} />
              </div>
              <div className='col-md-1 p-lg-0'>ถึงวันที่:</div>
              <div className='col-md-2 mb-2 mb-md-0'>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  selected={end_date}
                  className='form-control form-control-sm'
                  placeholderText={'DD/MM/YYYY'}
                  onChange={this.changeEndDate} />
              </div>
              <div className='col-md-5'>
                <button type='button' className='btn btn-success mr-3' onClick={this.handleSubmit}>ค้นหา</button>
                <button type='button' className='btn btn-success' onClick={this.handleDownload}>พิมพ์</button>
              </div>
            </div>
            
          </div>
        </div>

        {isDataFetched ? (
          <div className='py-3 rui-external-report'>
            <SendDocumentTable results={results} />
          </div>
        ) : (
          <Loading />
        )}
        
      </div>
    );
  }
}