import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Pathname from './Pathname';

class DepartmentGroupsSettingSidebar extends Component {
  render(){
    var fullPathname = Pathname.fullPathname(this.props.location);

    return(
      <div className="department_groups_settings-sidebar pl-4 pl-lg-0 sub-sidebar-menu">
        <ul className="nav flex-column nav-pills py-4 ml-0" id="v-pills-tab" role="tablist">
          <li className="nav-item">
            <Link to='/department_groups_settings/department_groups' 
              className={`pl-3 nav-link ${(fullPathname === '/department_groups_settings/department_groups') && 'active'}`}>
              <i className="fa fa-cog mr-2" aria-hidden="true"></i>
              <span>ตั้งค่ากลุ่ม</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/department_groups_settings/informees' 
              className={`pl-3 nav-link ${(fullPathname === '/department_groups_settings/informees') && 'active'}`}>
              <i className="fa fa-cog mr-2" aria-hidden="true"></i>
              <span>ตั้งค่าเรียน</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to='/department_groups_settings/inform_groups' 
              className={`pl-3 nav-link ${(fullPathname === '/department_groups_settings/inform_groups') && 'active'}`}>
              <i className="fa fa-cog mr-2" aria-hidden="true"></i>
              <span>ตั้งค่ากลุ่มเรียน</span>
            </Link>
          </li>
        </ul>
      </div>
    )
  }
}

export default DepartmentGroupsSettingSidebar;