import React, { Component } from 'react';
import _ from 'lodash';

import './BusinessUnitPage.css'

import SearchBox from '../../components/SearchBox';
import SimpleModal from '../../components/SimpleModal';

import GroupForm from '../../components/manage_users/businesses/GroupForm';
import PositionForm from '../../components/manage_users/businesses/PositionForm';
import SelectUsers from '../../components/manage_users/users/SelectUsers';
import Actions from '../../components/manage_users/businesses/Actions';
import RenderMainGroup from '../../components/manage_users/businesses/RenderMainGroup';
import ShowChildren from '../../components/manage_users/businesses/ShowChildren';

import GroupStore from '../../stores/GroupStore';
import PositionStore from '../../stores/PositionStore';
import Loading from '../../components/Loading';
import { GroupType, PositionType, UserType } from '../../components/Constants';

const FocusAttributes = {id: '', type: ''};

class BusinessUnitPage extends Component {
	constructor(props){
		super(props)

		this.state = {
			loading: true,
			open: false,
			mode: '',
			action: '',
			group: '',
			focus: FocusAttributes,
			active_directories: [],
			filter: ''
		}

		this.handleChangeMode = this.handleChangeMode.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.hanleEdit = this.hanleEdit.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleCloseAndReloadData = this.handleCloseAndReloadData.bind(this);
		this.reloadData = this.reloadData.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
	}

	componentWillMount() {
		this.reloadData()
	}

	reloadData(){
		var self = this;
		GroupStore.request({action: 'get_main_group'}).then((resp)=>{
			let group = _.get(resp.data, 'data', {});
			let lookups = _.get(resp.data, 'included', {});

			this.setState({
				group: group,
				active_directories: lookups.active_directories,
				loading: false
			})
		}, function(error){
			self.setState({loading: false})
		});
	}

	handleFilter(filter){
		this.setState({filter: filter, focus: FocusAttributes});
	}

	handleCloseAndReloadData(){
		// this.setState({loading: true});
		this.setState({mode: '', open: false}, this.reloadData());
	}

	handleChangeMode(mode){
		this.setState({
			mode: mode, 
			action: 'create', 
			open: true
		});
	}

	hanleEdit(){
		this.setState({
			mode: this.state.focus.type, 
			action: 'edit',
			open: true
		});
	}

	handleDelete(){
		if (window.confirm(`Would you like to delete this item?`)){
			this.setState({loading: true});
			
			if (this.state.focus.type === GroupType()){
				GroupStore.request({id: this.state.focus.id, method: 'delete'}).then(()=>{
					this.setState({focus: FocusAttributes}, this.reloadData());
				})
			}else{
				PositionStore.request({id: this.state.focus.id, method: 'delete'}).then(()=>{
					this.setState({focus: FocusAttributes}, this.reloadData());
				})
			}
		}
	}

	handleFocus(item){
		if (item !== this.state.focus){
			this.setState({focus: item});
		}
	}

	handleClose(){
		this.setState({mode: '', action: '', open: false})
	}

	render(){
		var modal_title = null;
		if (this.state.action === 'edit') {
			if (this.state.mode === GroupType()){
				modal_title = 'Edit Group'
			} else if (this.state.mode === PositionType()) {
				modal_title = 'Edit Position'
			} else {
				modal_title = 'Select User(s)'
			}
		}else{
			if (this.state.mode === GroupType()) {
				modal_title = 'Create Group'
			} else if (this.state.mode === PositionType()) {
				modal_title = 'Create Position'
			} else {
				modal_title = 'Select User(s)'
			}
		}
		return (
			<div>
				{this.state.loading && <Loading />}
				<div className='row mb-3'>
					<div className='col-md-8'>
						<div className='card'>
							<Actions 
								focus={this.state.focus}
								onChangeMode={this.handleChangeMode}
								onEdit={this.hanleEdit}
								onDelete={this.handleDelete} />
						</div>
					</div>				
					<div className='col-md-4'>
						<div className='card'>
							<div className='p-2 bg-light'>
								<SearchBox handleFilter={this.handleFilter} />
							</div>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-md-8 group-content'>
						{!_.isEmpty(this.state.group) && (
							<ul className='ul-group-list'>
								<RenderMainGroup 
									focus={this.state.focus}
									group={this.state.group}
									filter={this.state.filter}
									onFocus={this.handleFocus} />
							</ul>
						)}
					</div>				
					<div className='col-md-4'>
						<ShowChildren 
							focus={this.state.focus}
							active_directories={this.state.active_directories}
							onCloseAndReloadData={this.handleCloseAndReloadData} />
					</div>
				</div>

				<SimpleModal 
					isOpen={this.state.open}
					title={modal_title}
					size={'modal-lg'}
					showSubmitButton={false}
					onClose={this.handleClose}>
					{this.state.mode === GroupType() && (
						<GroupForm 
							action={this.state.action}
							focus={this.state.focus} 
							active_directories={this.state.active_directories}
							onClose={this.handleClose} 
							onCloseAndReloadData={this.handleCloseAndReloadData} />
					)}
					{this.state.mode === PositionType() && (
						<PositionForm 
							action={this.state.action}
							focus={this.state.focus}
							onClose={this.handleClose} 
							onCloseAndReloadData={this.handleCloseAndReloadData} />
					)}
					{this.state.mode === UserType() && (
						<SelectUsers 
							focus={this.state.focus}
							onClose={this.handleClose} 
							onCloseAndReloadData={this.handleCloseAndReloadData} />
					)}
				</SimpleModal>
			</div>
		)
	}
}

export default BusinessUnitPage;
