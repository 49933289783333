import React, {Component} from 'react';
import _ from 'lodash';

import IssueNumberStore from '../stores/IssueNumberStore';

export default class SetNextUnitIssueNumber extends Component {
  constructor(props){
    super(props);

    this.state = {
      is_editting: false,
      next_issue_number: props.next_issue_number,
      number_book_name: props.number_book_name
    }

    this.toggleEditor = this.toggleEditor.bind(this);
    this.handleSetNextIssueNumber = this.handleSetNextIssueNumber.bind(this);
    this.handleUpdateNextIssueNumber = this.handleUpdateNextIssueNumber.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.next_issue_number && this.state.next_issue_number !== nextProps.next_issue_number) {
      this.setState({
        next_issue_number: nextProps.next_issue_number,
        number_book_name: nextProps.number_book_name
      });
    }
  }

  toggleEditor(){
    this.setState({
      is_editting: !this.state.is_editting
    });
  }

  handleSetNextIssueNumber(e){
    const next_issue_number = e.target.value;

    this.setState({
      next_issue_number: next_issue_number
    });
  }

  handleUpdateNextIssueNumber(){
    const self = this;
    const next_issue_number = this.state.next_issue_number;
    const number_book_name = this.state.number_book_name;

    IssueNumberStore.request({
      action: 'set_next_issue_number',
      method: 'post',
      data: {
        next_issue_number: next_issue_number,
        number_book_name: number_book_name,
        set_department_issue_number: false
      },
    }).then((resp)=>{
      const next_issue_number = parseInt(_.get(resp.data, 'data.next_number', ""));
      const next_full_issue_number = _.get(resp.data, 'data.next_full_issue_number', "");

      self.setState({ 
        next_issue_number: next_issue_number,
      }, function(){
        this.toggleEditor();
      });

      this.props.onUpdatedNextIssueNumber(next_issue_number, number_book_name);
    });
  }

  render(){
    return (
      <div>
        { this.state.is_editting ? (
          <div>
            <div>เลขต่อไป</div>
            <input 
              type="text" 
              className="form-control my-2" 
              value={this.state.next_issue_number}
              onChange={this.handleSetNextIssueNumber}size="2" />
            <button type="button" className="btn btn-primary mb-2" onClick={this.handleUpdateNextIssueNumber}>เปลี่ยน</button>
            <button type="button" className="btn btn-danger" onClick={this.toggleEditor}>ยกเลิก</button>
          </div>
        ) : (
          <a onClick={this.toggleEditor} className="cursor-pointer">ตั้งค่า</a>
        )}
      </div>
    )
  }
}