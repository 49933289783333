import React, { Component } from 'react';
import _ from 'lodash';

class NumberFormatSection extends Component {
  constructor(props){
    super(props)

    this.renderRow = this.renderRow.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event){
    const document_template_id = event.target.name;
    const value = event.target.value;

    let { document_templates } = this.props;
    const edit_index = _.findIndex(document_templates, {id: document_template_id});
    document_templates[edit_index].number_format = value;

    this.props.onUpdateIssueNumberFormat(document_templates);
  }

  renderRow(document_template, index){
    if (document_template.number_format === "'RRRR'") { return }
    return (
      <div key={index} className="mb-2 row">
        <label htmlFor="document_code_internal" className="col-sm-2 col-form-label">
          {document_template.name}
        </label>
        <div className="col-sm-10">
          <input 
            type='text' 
            name={document_template.id} 
            value={document_template.number_format || ''} 
            onChange={this.onChange.bind(this)} 
            className='form-control form-control-sm' />
        </div>
      </div>
    )
  }

  render(){
    let document_templates = this.props.document_templates;
    const receive_document_templates = _.filter(document_templates, function(dt){
      return dt.is_send === false && dt.const_value !== 'front_page'
    });
    const send_document_templates = _.filter(document_templates, function(dt){
      return dt.is_send === true || dt.const_value === 'front_page'
    });
    return(
      <div>
        <div className="form-group row">
          <div className='col-12 text-danger'>
            R-แทนหมายเลขเอกสาร,OrgCode-แทนหมายเลขหน่วยงาน,OwnerFrontRef-แทนหมายเลขหน่วยงานที่ออกเลขใบปะหน้า-YYYY-แทนปี 
            ตัวอย่างเช่น 'มท '+'OrgCode'+'/'+'RRRR' หรือ 'ศธ ' + 'OwnerFrontRef' + 'RRRR'
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-12'>
            <h5><strong>หนังสือรับ</strong></h5>
          </div>
          <div className='col-12'>
            {!_.isEmpty(receive_document_templates) && _.map(receive_document_templates, this.renderRow)}
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-12'>
            <h5><strong>หนังสือส่ง</strong></h5>
          </div>
          <div className='col-12'>
            {!_.isEmpty(send_document_templates) && _.map(send_document_templates, this.renderRow)}
          </div>
        </div>
      </div>
    )
  }
}

export default NumberFormatSection;
