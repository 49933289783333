import React, { Component } from 'react';
import _ from 'lodash';

import RenderChildren from './RenderChildren';

const TOGGLE_STORAGE_KEY = 'toggle';

export default class RenderMainGroup extends Component {
	constructor(props) {
		super(props)

		let show = [];
		if (_.isEmpty(this.props.group.attributes.parent)){
			show = [this.props.group.id];
		}

		this.state = {
			show: show,
			newest_loading_item: {}
		}

		this.handleFocus = this.handleFocus.bind(this);
		this.handleShowLoading = this.handleShowLoading.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.filter !== nextProps.filter && !_.isEmpty(nextProps.group.attributes.parent)) {
			this.setState({show: []});
		}
	}

	handleShow(child){
		let { show } = this.state;
		show = _.concat(show, child.id);

		this.setState({show: show, newest_loading_item: child});
	}

	handleHide(child){
		let { show } = this.state;
		show = _.pull(show, child.id);

		this.setState({show: show});
	}

	handleFocus(){
		this.props.onFocus(this.props.group);
	}

	toggle(child, isShowChildren){
		var icon = null;
		if (isShowChildren) {
			icon = <i className="fa fa-minus pointer mr-3" aria-hidden="true" onClick={this.handleHide.bind(this, child)}></i>
		}else{
			icon = <i className="fa fa-plus pointer mr-3" aria-hidden="true" onClick={this.handleShow.bind(this, child)}></i>
		}	
		
		return (
			icon
		)
	}

	handleShowLoading(){
		this.setState({
			newest_loading_item: {}
		});
	}

	render() {
		var isShowChildren = false;
		var hasChild = false;

		if (_.includes(this.state.show, this.props.group.id)) {
  		isShowChildren = true;
  	}

  	if (!_.isEmpty(this.props.group.attributes.positions) || !_.isEmpty(this.props.group.attributes.children)){
  		hasChild = true;
  	}

  	const {group: {attributes: {code, name, short_name}}} = this.props;

		return (
			<li>
				{hasChild ? (
					this.toggle(this.props.group, isShowChildren)
				) : (
					<span className='px-2'>&nbsp;&nbsp;&nbsp;</span>
				)}

				<span className={`group-list pointer ${(this.props.focus.id === this.props.group.id) && 'focus-item'}`} onClick={this.handleFocus}>
					<i className="fa fa-users green-icon mr-3" aria-hidden="true"></i>
					<span className='mr-3'>{code}</span>
					<span className='mr-3'>
						{name}
						{!_.isEmpty(short_name) && ` (${short_name})`}
					</span>
				</span>
				
				<RenderChildren 
					show={this.state.show}
					focus={this.props.focus}
					group={this.props.group}
					filter={this.props.filter}
					newest_loading_item={this.state.newest_loading_item}
					handleShowLoading={this.handleShowLoading}
					onFocus={this.props.onFocus} />
			</li>
		)
	}
}