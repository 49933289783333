import axios, {create} from 'axios'

import LoginSession from '../stores/LoginSession';

const API_END_POINT = '/api/v1/';

export function createApi(options) {
	return create(options)
}

export function setHeader(key, value, api) {
	(api || axios).defaults.headers.common[key] = value;
}

export const adminApi = create({
	baseURL: API_END_POINT,
	headers: {
		'Accept': 'application/json',
		'X-Requested-With': 'XMLHttpRequest'
	}
})

adminApi.interceptors.response.use(response => {
   return response;
}, error => {
  if (error.response.status === 401) {
   	LoginSession.logout();
   	window.location.href = "/login";
  }
  return Promise.reject(error);
});
