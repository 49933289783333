import React, { Component } from 'react';

const CustomToolbar = ({onClickRaw}) => (
  <div id="toolbar">
    <select className="ql-header">
      <option value="1"></option>
      <option value="2"></option>
      <option value="3"></option>
      <option selected="selected"></option>
    </select>

    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-link"></button>
    <button className="ql-html" onClick={onClickRaw}>HTML</button>

    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>
    <button className="ql-clean"></button>
  </div>
)

export default CustomToolbar;