import React from 'react';
import moment from 'moment';

const Confirm = (props) => (
  <div>
    <div className="row mb-2">
      <div className="col-sm-3"></div>
      <div className="col-sm-2"><strong>เลขรับ</strong></div>
      <div className="col-sm-7">
        {props.skip_receive_number ? "ไม่ออกเลข" : props.next_receive_number}
      </div>
    </div>
    <div className="row mb-2">
      <div className="col-sm-3"></div>
      <div className="col-sm-2"><strong>วันที่</strong></div>
      <div className="col-sm-7">{moment().format('Do MMMM YYYY')}</div>
    </div>
    <div className="row mb-2">
      <div className="col-sm-3"></div>
      <div className="col-sm-2"><strong>เวลา</strong></div>
      <div className="col-sm-7">{moment().format('LTS')}</div>
    </div>

    <div className="modal-footer border-0 row">
      <button type='button' className="btn btn-primary pointer" disabled={props.clicked} onClick={() => props.onSubmitAction(null, null)}>ยืนยัน</button>
      <button type='button' className="btn btn-secondary pointer" onClick={props.onCloseModal}>ยกเลิก</button>
    </div>
  </div>
)

export default Confirm;
