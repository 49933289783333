import React, { Component } from 'react';
import _ from 'lodash';

export default class RenderOrganization extends Component {
  constructor(props) {
    super(props)

    this.renderPosition = this.renderPosition.bind(this);
  }

  renderPosition(position, index){
    return (
      <div key={index} className='ml-3'>
        {!_.isEmpty(position.attributes.users) && _.map(position.attributes.users, function(user, i){
          return (
            <div key={user.id}>
              <i className="fa fa-user-o mr-2 text-primary" aria-hidden="true"></i>
              {user.attributes.full_name} <b>[{position.attributes.name}]</b>
            </div>
          )
        })}
      </div>
    )
  }

  renderOrganizations(){
    return(
      <fieldset className='border border-secondary p-2'>
        <legend className='w-auto'>{this.props.group.attributes.name}</legend>
        <div><b>รหัสหน่วยงาน:</b> {this.props.group.attributes.code}</div>
        {_.map(this.props.group.attributes.positions, this.renderPosition)}
      </fieldset>
    )
  }

  render() {
    let organization = null;
    if (!_.isEmpty(this.props.group) && !_.isEmpty(this.props.group.attributes.positions)) {
      organization = this.renderOrganizations();
    }
    return organization;
  }
}