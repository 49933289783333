import React, { Component } from 'react';

export default class EditAction extends Component {
	render(){
		return (
			<button className="btn btn-sm btn-secondary pointer" onClick={this.props.onEdit}>
				<i className="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;
				Edit
			</button>
		)
	}
}