import React, { Component } from 'react';

class CCDocumentsPage extends Component {
  	render(){
		return (
			<div>
				<h3>เอกสารสำเนาถึง</h3>
			</div>
		)
	}
}

export default CCDocumentsPage;