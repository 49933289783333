import { EventEmitter } from 'events'
import _ from 'lodash';

import {adminApi, setHeader} from '../api'

const SESSION_STORAGE_KEY = 'auth_session'

export class LoginSession {
	constructor() {
		this.events = new EventEmitter()
		this.loadSessionFromStorage()
	}

	get current() {
		return this._session
	}

	get currentUser() {
		return _.get(this.current, 'user', '');
	}

	get siteSetting() {
		return _.get(this.current, 'site_setting', '');
	}

	loadSessionFromStorage() {
		this._session = JSON.parse(localStorage.getItem(SESSION_STORAGE_KEY) || null)
		if (this._session) {
			var token = this._session.auth_token;
			setHeader('Authorization', 'Bearer ' + token)
		} else {
			setHeader('Authorization', null)
		}
	}

	logout() {
		this._session = null;
		localStorage.removeItem(SESSION_STORAGE_KEY)
		setHeader('Authorization', null)

		if (window.navigator.userAgent.indexOf("Chrome") > -1){
			window.postMessage("logout");
		}

		this.events.emit('change')
	}

	login(username, password) {
		return adminApi.post('login', {username: username, password: password}).then((response) => {
			var session = response.data;
			localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(session))

			if (window.navigator.userAgent.indexOf("Chrome") > -1){
				window.postMessage(JSON.stringify(session));
			}

			this.loadSessionFromStorage()
			this.events.emit('change')
		}, function(error) {
			throw error;
		})
	}

	fetch() {
		if (!this.current) {
			return Promise.resolve(null);
		}
		return adminApi.get('me').then((response) => {
			return response.data.data;
		}, () => null)
	}

	reload() {
		if (!this.current) {
			return Promise.resolve(null);
		}
		return adminApi.get('me').then((response) => {
			localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(response.data.data))
			this.loadSessionFromStorage()
			this.events.emit('change')
			return response.data.data;
		}, () => null)
	}
}

export default new LoginSession()