import React, {Component} from 'react';
import './Loading.css';

export default class BatchForwarding extends Component {
  render(){
    return (
      <div>
        <div className="loading loading-color">{"กำลังส่งต่อเอกสารจำนวน " + this.props.totalCount + " ฉบับ..."}</div>
      </div>
    )
  }
}
