import React, { Component } from 'react';
import _ from 'lodash';

import Permission from '../../components/Permission';

import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';
import AfterSaveLoading from '../../components/AfterSaveLoading';

import UserStore from '../../stores/UserStore';
import './stylesheets/SettingSignaturePage.css';

class SettingSignaturePage extends Component {
	constructor(){
	    super();

	    this.state = {
	    	isDataFetched: false,
	    	users: [],
	    	loading: false
	    }

	    this.reload = this.reload.bind(this);
	    this.showSignatureImg = this.showSignatureImg.bind(this);
	    this.handleChangeSignature = this.handleChangeSignature.bind(this);
	    this.actionsFormatter = this.actionsFormatter.bind(this);
	}

	componentDidMount(){
		if (!Permission.isAdmin()) {
			window.location.href = '/receive_documents/mydoc';
		}else{
			this.reload();
		}
	}

	reload(){
		UserStore.request({}).then((resp)=>{
			var users = _.get(resp.data, 'data', []);

			this.setState({
				users: users,
				isDataFetched: true
			})
		}).then(()=>{
			this.setState({loading: false});
		});
	}

	handleChangeSignature(user, event){
		var file = event.target.files[0];
    	if (file.size === 0) { return }

    	this.setState({loading: true});
    	var data = new FormData();
    	data.append('user[signature]', file);

    	UserStore.save(user.id, data).then(()=>{
    		this.reload();
		}, function(error){
			this.setState({loading: false});
			var error_message = _.get(error.response.data, 'error.message', '');
			alert(error_message);
		});
	}

	actionsFormatter(cell, row){
		return (
			<div>
	  			<label className="btn btn-sm btn-success pointer mb-0 mr-2">
				    เปลี่ยน
				    <input 
				    	type="file"
				    	name='signature' 
				    	hidden
				    	onChange={this.handleChangeSignature.bind(this, row)} />
				</label>
	  		</div>
		)
	}

	showSignatureImg(cell, row){
		var signature = null;
		if (!_.isEmpty(row.signature)){
			signature = row.signature;
		}
		return (
			<img alt='' src={signature} style={{maxHeight: '60px'}} />
		)
	}

	_setTableOption(){ 
     	if(this.state.isDataFetched){
           	return "Not found";
      	}else{
            return <Loading />
      	}
	}

  	render(){
  		var data = MergeAttributes(this.state.users);
		const options = {
	      	sizePerPageList: [],
	      	sizePerPage: 10,
	      	noDataText: this._setTableOption()
	    };
		return (
			<div>
				{this.state.loading && <AfterSaveLoading />}

				<div className='row'>
					<div className='col-md-12'>
						<h2>จัดการลายมือชื่อ</h2>
					</div>
				</div>
				
				<div className="rui-signature-setting">
					<BootstrapTable 
						data={data}
						search={ true }
						multiColumnSearch={ true }
						options={options}
						striped
						hover
						condensed
						pagination
						version='4'
						tableContainerClass={'table-sm'}>
							<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
							<TableHeaderColumn dataField='full_name' dataSort={ true }>ชื่อ-นามสกุล</TableHeaderColumn>
							<TableHeaderColumn dataField='position_names' dataSort={ true }>ตำแหน่ง</TableHeaderColumn>
							<TableHeaderColumn dataField='department_names' dataSort={ true }>สาขา</TableHeaderColumn>
							<TableHeaderColumn dataField='id' dataFormat={ this.showSignatureImg } columnClassName='text-md-center'>ลายมือชื่อ</TableHeaderColumn>
							<TableHeaderColumn dataField='id' dataFormat={ this.actionsFormatter } columnClassName='text-md-center'></TableHeaderColumn>
					</BootstrapTable>
				</div>
		  </div>
		)
	}
}

export default SettingSignaturePage;