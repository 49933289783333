import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import GarudaEmblem from '../../assets/images/emblem.png';
import UserImage from '../../assets/images/16User.png';
import OrgImage from '../../assets/images/16Org.png';
import '../../pages/documents/stylesheets/DocumentUploadFileForm.css';

import SpeedLevelForm from './elements/SpeedLevelForm';
import SecretLevelForm from './elements/SecretLevelForm';
import SubjectForm from './elements/SubjectForm';
import SetFrontPageAvailability from './elements/SetFrontPageAvailability';
import { speedLevelList, secretLevelList } from '../../utils/documentType';
import OriginalDocumentFiles from './OriginalDocumentFiles';
import { ReferenceDocumentForm, RenderRefDocs} from './elements/ReferenceDocuments';
import { UploadFiles, RenderFileNames } from './elements/Files';
import { SigneeForm, RenderSignee } from './elements/Signees';
import { ThroughForm, RenderThroughs } from './elements/Throughs';
import { NumberIssuerForm, RenderNumberIssuers} from './elements/NumberIssuers';
import { InformeeForm, RenderInformees } from './elements/Informees';
import { InformForm, RenderInforms } from './elements/Informs';

const NormalLevel = 'ปกติ';

export default class DocumentUploadFileForm extends Component {
  constructor(props){
    super(props)

    this.renderSpeedLevel = this.renderSpeedLevel.bind(this);
    this.renderSecretLevel = this.renderSecretLevel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  handleChangeSpeedLevel(speed_level){
    this.props.handleUpdate('speed_level', speed_level);
  }

  handleChangeSecretLevel(secret_level){
    this.props.handleUpdate('secret_level', secret_level);
  }

  handleChange(event){
    this.props.handleChangeAttributeValue(event.target.name, event.target.value);
  }

  handleCheckboxChange(event){
    this.props.handleUpdate(event.target.name, event.target.checked);
  }

  renderSpeedLevel(speed_level, index){
    const { document } = this.props;
    return (
      <label 
        key={index} 
        className={`btn btn-sm btn-secondary ${document.speed_level === speed_level && 'active'}`}
        onClick={this.handleChangeSpeedLevel.bind(this, speed_level)}>
        <input type="radio" name="speed_level" id={`speed_level_${index}`} autoComplete="off" checked /> 
        {speed_level}
      </label>
    )
  }

  renderSecretLevel(secret_level, index){
    const { document } = this.props;
    return (
      <label 
        key={index} 
        className={`btn btn-sm btn-secondary ${document.secret_level === secret_level && 'active'}`}
        onClick={this.handleChangeSecretLevel.bind(this, secret_level)}>
        <input type="radio" name="secret_level" id={`secret_level_${index}`} autoComplete="off" checked /> 
        {secret_level}
      </label>
    )
  }

  render(){
    const { 
      is_front_document,
      document_template,
      document_attributes,
      document,
      original_document_files,
      internal_number_issuers,
      central_number_issuers,
      number_issuers,
      department_groups,
      campus_department_groups,
      informees,
      inform_groups,
      reference_documents,
      all_users,
      internal_users,
      current_user_departments
    } = this.props;

    let hidden_ref = false;
    if (!_.isEmpty(document_attributes.hidden_ref)){
      hidden_ref = document_attributes.hidden_ref.value;
    }

    let hidden_file = false;
    if (!_.isEmpty(document_attributes.hidden_file)){
      hidden_file = document_attributes.hidden_file.value;
    }

    return (
      <div>
        {/*
        <div className='row mb-2'>
          <div className='col-12 col-md-2'></div>
          <div className='col-12 col-md-4'>
            <img alt='Emblem' src={GarudaEmblem} style={{maxWidth: '1.5cm'}}/>
          </div>
          <div className='col-12 col-md-6'>
            <h1 className='my-2'>บันทึกข้อความ</h1>
          </div>
        </div>
        */}

        <div className='row mb-2'>
          <div className='col-12 col-md-2'><b>ส่วนราชการ</b></div>
          <div className='col-12 col-md-10'>
            <textarea 
              name='government_service' 
              className='form-control'
              value={document_attributes.government_service.value}
              onChange={this.handleChange} />
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-2'><b>เรื่อง</b></div>
          <div className='col-12 col-md-10'>
            <input 
              name='topic' 
              className='form-control'
              value={document_attributes.topic.value}
              onChange={this.handleChange} />
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-12'><h4 className='font-weight-bold'>ประเภทเอกสาร</h4></div>
        </div>

        <div className='row mb-2'>
          <div className='col-12 col-md-2'><b>ชั้นความเร็ว</b></div>
          <div className='col-12 col-md-10'>

            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              {_.map(speedLevelList, this.renderSpeedLevel)}
            </div>

          </div>
        </div>

        {(document_template.attributes.has_due_date && document.speed_level !== NormalLevel) && (
          <div className='row mb-2'>
            <div className='col-12 col-md-2'><b>กำหนดวันแล้วเสร็จ</b></div>
            <div className='col-12 col-md-10'>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                selected={document.due_date}
                onChange={this.props.handleChangeEndDate}
                className='form-control form-control-sm' />
            </div>
          </div>
        )}

        <div className='row mb-2'>
          <div className='col-12 col-md-2'><b>ชั้นความลับ</b></div>
          <div className='col-12 col-md-10'>

            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              {_.map(secretLevelList, this.renderSecretLevel)}
            </div>

          </div>
        </div>
        <hr className='mt-3' />

        {document_template.attributes.has_number_issuer && (
          <div className='my-3'>
            <NumberIssuerForm 
              document_template={document_template}
              internal_number_issuers={internal_number_issuers}
              central_number_issuers={central_number_issuers}
              users={number_issuers}
              number_issuer_id={document.number_issuer_id}
              is_circular={document.is_circular}
              handleChange={this.props.handleUpdate} />
            <RenderNumberIssuers 
              number_issuer_id={document.number_issuer_id}
              users={number_issuers}
              actings={this.props.actings}
              onDeleteNumberIssuer={this.props.handleDeleteNumberIssuer}
              onShowActorsForm={this.props.onShowActorsForm} />
              <hr className='mt-3' />
          </div>
        )}

        {document_template.attributes.has_inform_to_informee && (
          <div className='my-3'>
            <InformeeForm 
              show_one_line_section={document_template.attributes.has_show_one_line_informee}
              show_one_line_informee={document_attributes.show_one_line_informee.value}
              send_all_department_groups_section={document_template.attributes.can_send_to_all}
              handleChange={this.props.handleUpdate} 
              department_groups={department_groups}
              informs={document.informs}
              informees={informees}
              inform_groups={inform_groups}
              handleChangeAttributeValue={this.props.handleChangeAttributeValue} />
            <RenderInformees 
              department_groups={department_groups}
              informs={document.informs}
              informees={informees} />
            <hr className='mt-3' />
          </div>
        )}

        {document_template.attributes.has_inform_to_department_group && (
          <div className='my-3'>
            <InformForm 
              show_one_line_section={document_template.attributes.has_show_one_line_informee}
              show_one_line_informee={document_attributes.show_one_line_informee.value}
              send_all_department_groups_section={document_template.attributes.can_send_to_all}
              handleChange={this.props.handleUpdate} 
              department_groups={department_groups}
              informs={document.informs}
              informees={informees}
              handleChangeAttributeValue={this.props.handleChangeAttributeValue} />
            <RenderInforms
              department_groups={department_groups}
              informs={document.informs}
              informees={informees} />
            <hr className='mt-3' />
          </div>
        )}

        {(document_template.attributes.enable_front_page && document_template.attributes.const_value !== 'front_page') && (
          <div>
            <SetFrontPageAvailability 
              document={document}
              handleChange={this.props.handleUpdate} />
            <hr className='my-3' />
          </div>
        )}

        <div className='row mb-2'>
          <div className='col-12'><b>เอกสารต้นฉบับ</b></div>
          <div className='col-12 col-md-10'>
            <OriginalDocumentFiles
              original_document_files={original_document_files} 
              handleChangeOriginalDoc={this.props.handleChangeOriginalDoc}
              onRemoveOriginalDocument={this.props.onRemoveOriginalDocument}
              handleInsert={this.props.handleInsert}
              handleReplace={this.props.handleReplace}
              onSortEnd={this.props.onSortEnd} />
          </div>
        </div>
        <hr className='my-3' />

        {document_template.attributes.has_references && (
          <div className='my-3'>
            <ReferenceDocumentForm 
              is_receive_document_form={false}
              hidden_section={document_template.attributes.has_hide_references}
              hidden_ref={hidden_ref}
              reference_documents={reference_documents}
              references={document.references}
              onChange={this.props.handleUpdate}
              handleChange={this.props.handleChangeAttributeValue} />
            
            <RenderRefDocs 
              hidden_ref={hidden_ref}
              references={document.references}
              reference_documents={reference_documents}
              onDeleteRefDoc={this.props.onDeleteRefDoc} />
            <hr className='mt-3' />
          </div>
        )}

        {document_template.attributes.has_attachment && (
          <div className='my-3'>
            <UploadFiles 
              hidden_section={document_template.attributes.has_hide_attachment}
              hidden_file={hidden_file}
              onChange={this.props.handleUpdateFiles} 
              handleChange={this.props.handleChangeAttributeValue} />
            
            <RenderFileNames 
              hidden_file={hidden_file}
              files={document.files}
              onDeleteFile={this.props.onDeleteFile} />
            <hr className='mt-3' />
          </div>
        )}

        {document_template.attributes.has_signee && (
          <div className='my-3'>
            <SigneeForm 
              signees={document.signees}
              document_template={document_template}
              users={all_users}
              internal_users={internal_users}
              handleChange={this.props.handleUpdate} />
            <RenderSignee 
              signees={document.signees}
              users={all_users}
              actings={this.props.actings}
              onDeleteSignee={this.props.handleDeleteSignee}
              onShowActorsForm={this.props.onShowActorsForm} />
            <hr className='mt-3' />
          </div>
        )}

        {document_template.attributes.has_through && (
          <div className='my-3'>
              <ThroughForm 
                signees={document.signees}
                throughs={document.throughs}
                multiple={true}
                users={all_users}
                internal_users={internal_users}
                handleChange={this.props.handleUpdate} />
              <RenderThroughs 
                throughs={document.throughs}
                users={all_users}
                actings={this.props.actings}
                onDeleteThrough={this.props.handleDeleteThrough}
                onShowActorsForm={this.props.onShowActorsForm} />
            <hr className='mt-3' />
          </div>
        )}

      </div>
    )
  }
}
