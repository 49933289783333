import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import _ from 'lodash';

import { MergeAttributes } from '../../components/FlatData';

import SimpleModal from '../../components/SimpleModal';
import EditAction from '../../components/EditAction';
import DeleteAction from '../../components/DeleteAction';

import UserForm from '../../components/manage_users/users/UserForm';
import SetPermissionForm from '../../components/manage_users/users/SetPermissionForm';
import UserStore from '../../stores/UserStore';
import Loading from '../../components/Loading';
import AfterSaveLoading from '../../components/AfterSaveLoading';

const SetPermission = (props) => {
	return (
		<button className="btn btn-sm btn-primary pointer" onClick={props.onSetPermission}>
			กำหนดสิทธิ์
		</button>
	)
}

class UserPage extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isDataFetched: false,
			open: false,
			editingId: '',
			open_set_permission: false,
			set_permission_for_user_id: '',
			users: []
		}

		this.handleNew = this.handleNew.bind(this);
		this.actionsFormatter = this.actionsFormatter.bind(this);
		this.positionFormat = this.positionFormat.bind(this);
		this.fullnameFormat = this.fullnameFormat.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.reloadData = this.reloadData.bind(this);
		this.handleCloseAndReloadData = this.handleCloseAndReloadData.bind(this);
	}

	componentWillMount() {
		this.reloadData();
	}

	reloadData(){
		UserStore.request({}).then((resp)=>{
			this.setState({
				users: resp.data.data
			})
		}).then(()=>{
			this.setState({isDataFetched: true});
		})
	}

	handleNew(){
		this.setState({
			open: true,
			editingId: '',
			open_set_permission: false,
			set_permission_for_user_id: ''
		})
	}

	handleSetPermission(id){
		this.setState({
			open: false,
			editingId: '',
			open_set_permission: true,
			set_permission_for_user_id: id
		})
	}

	handleEdit(id){
		this.setState({
			open: true,
			editingId: id,
			open_set_permission: false,
			set_permission_for_user_id: ''
		})
	}

	handleDelete(id){
		if (window.confirm(`Would you like to delete this user?`)){
			this.setState({isDataFetched: false});

			UserStore.request({id: id, method: 'delete'}).then(()=>{
				this.reloadData();
			})
		}
	}

	actionsFormatter(cell, row) {
	  	return (
	  		<div>
	  			<SetPermission id={ cell } onSetPermission={this.handleSetPermission.bind(this, cell)}/>&ensp;
	  			<EditAction id={ cell } onEdit={this.handleEdit.bind(this, cell)}/>&ensp;
	  			<DeleteAction id={ cell } onDelete={this.handleDelete.bind(this, cell)}/>
	  		</div>
	  	)
	}

	positionFormat(cell, row){
		return (
			<div style={{whiteSpace: 'normal'}}>{row.position_names}</div>
		)
	}

	fullnameFormat(cell, row){
		return (
			<div style={{whiteSpace: 'normal'}}>{row.full_name}</div>
		)
	}

	handleClose(){
		this.setState({
			open: false, 
			editingId: '', 
			open_set_permission: false,
			set_permission_for_user_id: ''
		});
	}

	handleCloseAndReloadData(){
		this.setState({isDataFetched: false});
		this.setState({
			open: false, 
			editingId: '', 
			open_set_permission: false, 
			set_permission_for_user_id: ''
		}, this.reloadData());
	}

	_setTableOption(){ 
     	if(this.state.isDataFetched){
           	return "Not found";
      	}else{
            return <Loading />
      	}
	}

	render(){
		var title_modal = 'Create User'
		if (!_.isEmpty(this.state.editingId)){
			title_modal = 'Edit User'
		}
		const options = {
			noDataText: this._setTableOption(),
	      	sizePerPageList: []
	    };
		var data = MergeAttributes(this.state.users);
		return (
		  <div>
		  	{!this.state.isDataFetched && <AfterSaveLoading />}
		  	
				<div className='row'>
				  <div className='col-md-6'>
					<h4>Users</h4>
				  </div>
				  <div className='col-md-6 text-right'>
					<button type='button' 
						className='btn btn-success'
						onClick={this.handleNew}>+ Create User</button>
				  </div>
				</div>

				<BootstrapTable 
				  data={data}
				  search={ true }
				  multiColumnSearch={ true }
				  options={options}
				  striped
				  hover
				  pagination
				  version='4'
				  tableContainerClass={'table-sm'}>
				  <TableHeaderColumn isKey dataField='code' dataSort={ true } width='100px' columnClassName='text-center'>Code</TableHeaderColumn>
				  <TableHeaderColumn dataField='full_name' dataSort={ true } dataFormat={this.fullnameFormat}>Fullname</TableHeaderColumn>
				  <TableHeaderColumn dataField='username' dataSort={ true } width='100px'>Username</TableHeaderColumn>
				  <TableHeaderColumn dataField='email' dataSort={ true }>Email</TableHeaderColumn>
				  <TableHeaderColumn dataField='position_names' dataSort={ true } dataFormat={this.positionFormat}>Position(s)</TableHeaderColumn>
				  <TableHeaderColumn dataField='id' dataFormat={ this.actionsFormatter } columnClassName='text-center'></TableHeaderColumn>
				</BootstrapTable>

				<SimpleModal 
					isOpen={this.state.open}
					title={title_modal}
					size={'modal-lg'}
					showSubmitButton={false}
					onClose={this.handleClose}>
					<UserForm 
						{...this.state} 
						onClose={this.handleClose} 
						onCloseAndReloadData={this.handleCloseAndReloadData} />
				</SimpleModal>
				
				<SimpleModal 
					isOpen={this.state.open_set_permission}
					title={'กำหนดสิทธิ์'}
					size={'modal-lg'}
					showSubmitButton={false}
					onClose={this.handleClose}>
					<SetPermissionForm 
						{...this.state}
						onClose={this.handleClose}
						onCloseAndReloadData={this.handleCloseAndReloadData} />
				</SimpleModal>
		  </div>
		);
	}
}

export default UserPage;