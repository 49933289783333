import React, {Component} from 'react';
import _ from 'lodash';

import { MergeAttributes } from '../../FlatData';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

export default class SelectInforms extends Component {
	constructor(props){
		super(props)

		this.state = {
			selected: this.props.editing_inform.department_group_ids
		}

		this.onRowSelect = this.onRowSelect.bind(this);
		this.handleSetValue = this.handleSetValue.bind(this);
	}

	onRowSelect(row, isSelected, e) {
		var selected = _.clone(this.state.selected)
		var isIncluded = _.includes(selected, row.id)

		if (isIncluded){
			selected = _.pull(selected, row.id)
		}else{
			selected = _.concat(selected, row.id)
		}
	  	this.setState({selected: selected})
	}

	handleSetValue(){
		this.props.onChange(this.state.selected);
	}

	render(){
		const selectRowProp = {
		  	mode: 'checkbox',
		  	clickToSelect: true,
  			onSelect: this.onRowSelect,
  			selected: this.state.selected
		};
		const options = {
	      sizePerPageList: []
	    };
		var data = MergeAttributes(this.props.department_groups);
		return (
			<div>
				<BootstrapTable 
		  		data={data}
				search={ true }
				multiColumnSearch={ true }
				selectRow={ selectRowProp }
				options={ options }
				striped
				hover
				condensed
				pagination
				version='4'
				tableContainerClass={'table-sm'}>
			  		<TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
					<TableHeaderColumn dataField='name' dataSort={ true }>ชื่อ</TableHeaderColumn>
			</BootstrapTable>

				<div className="modal-footer row">
					<button 
						type='button' 
						className="btn btn-primary pointer"
						onClick={this.handleSetValue}>
						ยืนยันที่เลือก
					</button>
					<button type='button' className="btn btn-secondary pointer" onClick={()=>this.props.onClose()}>ยกเลิก</button>
				</div>
			</div>
		)
	}
}