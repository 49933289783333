import React from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import { documentNumberFormatter, dateFormatter, rowClassNameFormat } from '../../utils/bootstrapTableFormatters';
import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';

const WorkflowMigration = (props) => {
  const _setTableOption = () => { 
    if(props.isDataFetched){
      return "ไม่มีข้อมูล";
    }else{
      return <Loading />
    }
  }

  const showActions = (cell, row) => {
    return(
      <div>
        <button className="btn btn-success btn-sm" onClick={() => props.onMoveSingleWorkflowProcess(row.id)}>
          ย้ายภาระงาน
        </button>
      </div>
    );
  }

  const total_items_count = _.get(props.pagination, 'total_items_count', 0);
  const active_page = _.get(props.pagination, 'active_page', 1);

  const selectRowProp = {
      mode: 'checkbox',
      onSelect: props.onSelectWorkflowProcess,
      selected: props.selectedWorkflowProcessIds,
  };

  const options = {
    sizePerPageList: [],
    sizePerPage: 20,
    page: active_page,
    noDataText: _setTableOption(),
    searchDelayTime: 500
  };

  let workflow_process_data = [];
  if (!_.isEmpty(props.workflowProcesses)){
    workflow_process_data = MergeAttributes(props.workflowProcesses);
  }

  return(
    <div id="WorkflowMigration">
      <div className="row">
        <div className="col-12">
          <h4>ย้ายภาระงาน</h4>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <span className="mr-2">เจ้าหน้าที่ต้นทาง</span>
          <button className="btn btn-primary" onClick={props.openSourcePositionsUserList}>
            เลือก
          </button>

          {props.sourcePositionUser && (
            <div className="mt-2">
              <div>{props.sourcePositionUser.attributes.full_name}</div>
              <div>
                { 
                  props.sourcePositionUser.attributes.position_name +
                  ", " +
                  props.sourcePositionUser.attributes.department_name
                }
              </div>
            </div>
          )}
        </div>

        {props.targetPositionUser && (
          <div className="col-6">
            <span className="mr-2">เจ้าหน้าที่ปลายทาง</span>
            <div className="mt-2">
              <div>{props.targetPositionUser.attributes.full_name}</div>
              <div>
                { 
                  props.targetPositionUser.attributes.position_name +
                  ", " +
                  props.targetPositionUser.attributes.department_name
                }
              </div>
            </div>
          </div>
        )}
      </div>
      
      {props.sourcePositionUser && (
        <div className="row mt-2">
          <div className="col-12 rui-mydoc">
            <BootstrapTable 
              data={workflow_process_data}
              remote={ true }
              fetchInfo={{dataTotalSize: total_items_count}}
              search={ true }
              multiColumnSearch={ true }
              selectRow={ selectRowProp }
              options={ options }
              striped
              hover
              pagination
              version='4'
              tableContainerClass={'table-sm'}
              trClassName={rowClassNameFormat}>
              <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
              <TableHeaderColumn width="150" dataFormat={documentNumberFormatter}>เลขที่หนังสือ</TableHeaderColumn>
              <TableHeaderColumn dataField='topic' columnTitle dataSort={ true }>เรื่อง</TableHeaderColumn>
              <TableHeaderColumn dataField='from_department' columnTitle dataSort={ true }>จาก</TableHeaderColumn>
              <TableHeaderColumn dataField='to_department' columnTitle dataSort={ true }>เรียน</TableHeaderColumn>
              <TableHeaderColumn dataField='current_state_name' dataSort={ true }>สถานะ</TableHeaderColumn>
              <TableHeaderColumn dataField='id' dataFormat={showActions}>จัดการ</TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12 text-center">
          {!_.isEmpty(props.workflowProcesses) && (
            <button className="btn btn-primary" onClick={() => props.onMoveAllWorkflowProcesses()}>
              ย้ายภาระงานทั้งหมด
            </button>
          )}

          {!_.isEmpty(props.selectedWorkflowProcessIds) && (
            <button className="btn btn-success ml-3" onClick={() => props.onMoveMultipleWorkflowProcesses()}>
              ย้ายภาระงานที่ถูกเลือก
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkflowMigration;