import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import $ from "jquery";
import edocLogo from '../assets/images/app_name.png';

import LoginSession from '../stores/LoginSession';
import Pathname from '../components/Pathname';
import Permission from '../components/Permission';

import DashboardSidebar from '../components/DashboardSidebar';
import ReceiveDocumentSidebar from '../components/ReceiveDocumentSidebar';
import SendDocumentSidebar from '../components/SendDocumentSidebar';
import SettingSidebar from '../components/SettingSidebar';
import ReportSidebar from '../components/ReportSidebar';
import DepartmentGroupsSettingSidebar from '../components/DepartmentGroupsSettingSidebar';

export default class Header extends Component {
  constructor(props) {
    super(props)

    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout(event){
    event.preventDefault();
    LoginSession.logout();
    window.location.replace('/login');
  }

  handleRedirectToMyDocs(){
    window.location.replace('/receive_documents/mydoc');
  }

  render(){
    const searchParam = _.get(this.props.location, 'search', '');
    const prefixPathname = Pathname.prefixPathname(this.props.location);
    const { siteSetting } = this.props;
    var fullPathname = Pathname.fullPathname(this.props.location);
    return(
      <header>
        <nav className='navbar navbar-expand-lg navbar-dark header__top border-bottom'>
          <a className="navbar-brand d-lg-none" href="/documents/mydoc">
            <img alt='LOGO' src={edocLogo} style={{height: '24px'}} />
          </a>
          <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className="navbar-nav font-weight-bold mr-auto">
              <li className='nav-item pr-2 border-right d-none d-lg-inline'>
                <Link 
                  to='/' 
                  style={{textDecoration: 'none'}}
                  className={`nav-link ${(prefixPathname === '') && 'active'}`}>
                  <img alt='LOGO' src={edocLogo} style={{height: '24px'}} />
                </Link>
              </li>
              <li className='nav-item px-2'>
                <Link 
                  to='/' className={`pt-2 nav-link ${(_.includes(['', 'department_numbers', 'unit_numbers', 'documents'], prefixPathname) && searchParam === '') && 'active'}`}>
                  สารบรรณ
                </Link>
                <div className="d-lg-none"><DashboardSidebar location={this.props.location} /></div>
              </li>
              <li className="nav-item px-2">
                <Link to='/receive_documents/operated' className={`pl-2 nav-link ${(fullPathname === '/receive_documents/operated') && 'active'}`}>
                  ปฏิบัติ
                </Link>
              </li>
              {(Permission.isSecretary() || Permission.isCorrespondence()) && (
                <li className='nav-item px-2'>
                  <Link to='/receive_documents/mydoc' className={`pt-2 nav-link ${(fullPathname === '/receive_documents/mydoc') && 'active'}`}>ทะเบียนหนังสือรับ</Link>
                  <div className="d-lg-none"><ReceiveDocumentSidebar location={this.props.location} /></div>
                </li>
              )}
              {(Permission.isCorrespondence()) && (
                <li className='nav-item px-2'>
                  <Link 
                    to='/send_documents/send_all' 
                    className={`pt-2 nav-link ${(prefixPathname === 'send_documents' || searchParam === "?activemenu=send_documents") && 'active'}`}>
                    ทะเบียนหนังสือส่ง
                  </Link>
                  <div className="d-lg-none"><SendDocumentSidebar location={this.props.location} /></div>
                </li>
              )}
              {Permission.isAdmin() && (
                <li className='nav-item px-2'>
                  <Link to='/department_groups_settings/department_groups' className={`pt-2 nav-link ${(prefixPathname === 'department_groups_settings') && 'active'}`}>ตั้งค่ากลุ่มสารบรรณ</Link>
                  <div className="d-lg-none"><DepartmentGroupsSettingSidebar location={this.props.location} /></div>
                </li>
              )}
              {Permission.isAdmin() && (
                <li className='nav-item px-2'>
                  <Link to='/settings/sitesetting' className={`pt-2 nav-link ${(prefixPathname === 'settings') && 'active'}`}>ตั้งค่า</Link>
                  <div className="d-lg-none">
                    <SettingSidebar location={this.props.location} siteSetting={siteSetting} />
                  </div>
                </li>
              )}
              {!Permission.isAdmin() && Permission.isSuperAdmin() && (
                <li className='nav-item px-2'>
                  <Link to='/settings/setup' className={`pt-2 nav-link ${(prefixPathname === 'settings') && 'active'}`}>ตั้งค่า</Link>
                  <div className="d-lg-none">
                    <SettingSidebar location={this.props.location} siteSetting={siteSetting}/>
                  </div>
                </li>
              )}
              {(Permission.isSecretary() || Permission.isCorrespondence()) && (
                <li className='nav-item px-2'>
                  <Link to='/settings/actings' className={`pt-2 nav-link ${(prefixPathname === 'settings') && 'active'}`}>ตั้งค่า</Link>
                  <div className="d-lg-none">
                    <SettingSidebar location={this.props.location} siteSetting={siteSetting}/>
                  </div>
                </li>
              )}
            </ul>
            
              {LoginSession.current ? (
                <ul className="navbar-nav font-weight-bold ml-auto">
                  <li className='nav-item px-2 py-2 py-lg-0 border-right'>
                    <Link to='/receive_documents/mydoc'>กรมสุขภาพจิต</Link>
                  </li>
                  <li className='nav-item px-2 py-2 py-lg-0 border-right'>
                    <Link to='/edit_profile' style={{color: '#555'}}>{LoginSession.current.user.full_name}</Link>
                  </li>
                  <li className='nav-item px-2'>
                    <Link to='/' onClick={this.handleLogout}>Logout</Link>
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav font-weight-bold ml-auto">
                  <li className='nav-item px-2 py-2 py-lg-0'>
                    <Link to='/login'>Login</Link>
                  </li>
                </ul>
              )}
          </div>
        </nav>
      </header>
    )
  }
}

$(document).ready(function(){
  $(document).on('click', '.navbar-collapse a', function(){
    $(".navbar-collapse").removeClass('show');
  });
})

