import React, {Component} from 'react';
import _ from 'lodash';

import SimpleModal from '../SimpleModal';
import WorkflowProcessStore from '../../stores/WorkflowProcessStore';
import AttachmentList from './actions/AttachmentList';
import Loading from '../../components/Loading';

export default class ShowAttachedFiles extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDataFetched: false,
      attachments: []
    }
  }

  componentDidMount(){
    if (!_.isEmpty(this.props.workflow_process_id)){
      WorkflowProcessStore.request({id: this.props.workflow_process_id}).then((resp)=>{
        const attachments = _.get(resp.data, 'included.attachments', []);

        this.setState({
          attachments: attachments
        });
      }).then(()=>{
        this.setState({isDataFetched: true});
      });
    }else{
      this.setState({isDataFetched: true});
    }
  }

  componentWillReceiveProps(nextProps){
    if (!_.isEmpty(nextProps.workflow_process_id) && nextProps.workflow_process_id != this.props.workflow_process_id){
      this.setState({
        isDataFetched: false, 
        attachments: []
      });
      WorkflowProcessStore.request({id: nextProps.workflow_process_id}).then((resp)=>{
        const attachments = _.get(resp.data, 'included.attachments', []);

        this.setState({
          attachments: attachments
        });
      }).then(()=>{
        this.setState({isDataFetched: true});
      });
    }
  }

  render(){
    const { attachments } = this.state;
    return (
      <SimpleModal
        isOpen={this.props.open_attached_file}
        title={'ไฟล์ที่แนบมาด้วย'}
        size={'modal-lg'}
        showSubmitButton={false}
        onClose={this.props.onClose}>

        {this.state.isDataFetched ? (
          <div>
            {!_.isEmpty(attachments) && <AttachmentList attachments={attachments} />}
          </div>
        ) : (
          <Loading />
        )}

        <div className="modal-footer row mt-3 pb-0">
          <button type='button' className="btn btn-secondary pointer" onClick={this.props.onClose}>ปิด</button>
        </div>
      </SimpleModal>
    )
  }
}