import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import SiteSettingStore from '../../stores/SiteSettingStore';

export default class ShowSettingDetails extends Component {
	constructor(){
		super();

		this.state ={
      siteSetting: {
        code: "", 
        center_code: "",
        name: "",
        email: "",
      }
		}
	}

  componentWillMount(){
    SiteSettingStore.request({}).then((resp)=>{
      let siteSetting = _.get(resp.data, 'data', []);

      this.setState({
        siteSetting: siteSetting
      })
    })
  }

	render(){
    	return (
      		<div className='card-box-shadow p-4 bg-light'>
				<div className='row form-group'>
					<div className='col-md-2'>
						<strong>Code</strong>
					</div>
					<div className='col-md-10'>
						{this.state.siteSetting.code}
					</div>
				</div>
				<div className='row form-group'>
					<div className='col-md-2'>
						<strong>Center Code</strong>
					</div>
					<div className='col-md-10'>
						{this.state.siteSetting.center_code}
					</div>
				</div>
				<div className='row form-group'>
					<div className='col-md-2'>
						<strong>Name</strong>
					</div>
					<div className='col-md-10'>
						{this.state.siteSetting.name}
					</div>
				</div>
				<div className='row form-group mb-3'>
					<div className='col-md-2'>
						<strong>Email</strong>
					</div>
					<div className='col-md-10'>
						{this.state.siteSetting.email}
					</div>
				</div>

				<div className='row'>
					<div className='col-md-12'>
						<button 
							type='button' 
							className='btn btn-success mr-2 mt-2 pointer'
							onClick={()=>this.props.handleShow()}>
							แก้ไขข้อมูล
						</button>
						<Link to='/manage_users/businesses' target='_blank' className='btn btn-danger mr-2 mt-2 pointer'>จัดการผู้ใช้งาน</Link>
						<button type='button' className='btn btn-warning mr-2 mt-2 pointer'>โหลดข้อมูลหน่วยงาน</button>
						<button type='button' className='btn btn-primary mr-2 mt-2 pointer'>ดาวน์โหลดผู้ใช้งาน</button>
					</div>
				</div>
			</div>
    	)
	}
}