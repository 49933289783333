import React, { Component } from 'react';

class SettingFooterPage extends Component {
  	render(){
		return (
			<div>
				<h2>Footer</h2>
			</div>
		)
	}
}

export default SettingFooterPage;