import React, { Component } from 'react';

class AddComment extends Component {
  constructor(props){
    super(props)

    this.state = {
      comment: ""
    }

    this.updateComment = this.updateComment.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  updateComment(event){
    this.setState({ comment: event.target.value });
  }

  handleSubmit() {
    this.props.onSubmitAction(null, this.state.comment);
  }

  render(){
    return (
      <div>
        <div className="form-group">
          <label htmlFor="comment">ระบุหมายเหตุ</label>
          <textarea className="form-control" 
                    id="comment" 
                    rows="6" 
                    onChange={this.updateComment} value={this.state.comment}>
          </textarea>
        </div>
        <div className="modal-footer row">
          <button type='button' className="btn btn-primary pointer" disabled={this.props.clicked} onClick={this.handleSubmit}>ตกลง</button>
          <button type='button' className="btn btn-secondary pointer" onClick={this.props.onCloseModal}>ยกเลิก</button>
        </div>
      </div>
    )
  }
}

export default AddComment;
