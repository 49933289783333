import React, {Component} from 'react';

export class DeletePageButton extends Component {
  handleDelete(){
    const { content } = this.props;
    this.props.onDeletePage(content.page_number)
  }

  render(){
    const { content } = this.props;
    return (
      <button 
        type='button' 
        className='btn btn-warning delete-document-page-btn'
        onClick={this.handleDelete.bind(this)}>
        ลบหน้า
      </button>
    )
  }
}

export default DeletePageButton;
