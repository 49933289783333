import React from 'react';
import _ from 'lodash';

const NoteSelectSignee = (props) => (
  <div className="row">
    <div className="col-4 col-md-3 col-lg-2">ผู้ลงนามกำกับ</div>
    <div className="col-8 col-md-9 col-lg-10">
      {props.signee_name_with_position && _.includes(props.acting_positions_user_ids, props.signee_id) && (
        <div className="px-2 pt-2 pb-1 mb-2 bg-danger text-white position-relative">
          {props.signee_name_with_position}
          <i className='fa fa-eject text-black close-icon' onClick={() => props.showActors(props.signee_id, "signee")}></i>
        </div>
      )}

      {props.signee_name_with_position &&  !_.includes(props.acting_positions_user_ids, props.signee_id) && (
        <div className="px-2 pt-2 pb-1 mb-2 bg-success text-white">{props.signee_name_with_position}</div>
      )}

      <button className="btn btn-sm btn-primary" onClick={props.openSigneeModal}>เลือกผู้ลงนามกำกับ</button>
    </div>
  </div>
)

export default NoteSelectSignee;