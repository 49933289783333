import React, { Component } from 'react';
import _ from 'lodash';

export default class AttachmentList extends Component {
  constructor(props){
    super(props)

    this.renderRow = this.renderRow.bind(this);
  }

  renderRow(attachment, index){
    if (attachment.attributes.is_original === true) { return }
    return (
      <li key={index}>
        <a href={attachment.attributes.url} target="_blank">
          {attachment.attributes.document_file_name}
        </a>
      </li>
    )
  }

  render(){
    let hasItems = this.props.attachments && this.props.attachments.length > 0
    return (
      <ul className='m-0 ul-group-list'>
        {!_.isEmpty(this.props.attachments) && _.map(this.props.attachments, this.renderRow)}
        {!hasItems && <li>No items</li>}
      </ul>
    )
  }
}
