import React, { Component } from 'react';

import Main from './Main';
import TopHeader from './TopHeader';
import Header from './Header';

import Pathname from '../components/Pathname';

export default class DocumentLayout extends Component {

  	render() {
  		var fullPathname = Pathname.fullPathname(this.props.location);
		return (
			<div className='bg-white'>
				{fullPathname === '/templates' ? (
					<div>
						{/*
							<TopHeader siteSetting={this.props.siteSetting} />
						*/}
						<Header location={this.props.location} siteSetting={this.props.siteSetting} />
						<div className='container pb-4'>
							<div className='row'>
								<div className='ml-auto col-8 mr-auto'>
									<Main />
								</div>
							</div>
						</div>
					</div>
				) : (
					<div>
						<Main />
					</div>
				)}
			</div>
		)
  	}
}
