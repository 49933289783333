import React, {Component} from 'react';
import _ from 'lodash';
import { Creatable } from 'react-select';
import PositionStore from '../../stores/PositionStore';

export default class InformText extends Component {
  constructor(props){
    super(props)

    this.state = {
      showOptions: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    // this.formatCreateLabel = this.formatCreateLabel.bind(this);
  }

  handleChange(obj){
    const value = _.get(obj, 'value', '');
    this.props.handleChangeInformText(value);
  }

  handleInputChange(value){
    console.log('value ==>', value);

    this.setState({showOptions: value.length > 2});
  }

  formatCreateLabel(inputValue){
    const label_format = `สร้างใหม่ "${inputValue}"`;
    return label_format
  }

  render(){
    const { inform, position_name_list } = this.props;
    const inform_name = _.get(inform, 'name', '')

    return (
      <Creatable
        value={_.isEmpty(inform_name) ? '' : {value: inform_name, label: inform_name}}
        isClearable
        // formatCreateLabel={this.formatCreateLabel}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        options={this.state.showOptions ? position_name_list : []} />
    )
  }
}