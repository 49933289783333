import React, {Component} from 'react';
import _ from 'lodash';

export class StatusFilter extends Component {
  constructor(props) {
    super(props)
    
    this.renderStatus = this.renderStatus.bind(this);
  }

  handleChangeStatus(status){
    this.props.handleChangeStatus(status);
  }

  renderStatus(status, index){
    return (
      <label 
        key={index} 
        onClick={this.handleChangeStatus.bind(this, status)} 
        className={`btn btn-sm btn-secondary ${this.props.active_status === status && 'active'}`}>
        <input type="radio" name="status_filter" id={`status_filter_${index}`} defaultChecked="true" /> 
        {status}
      </label>
    )
  }

  render(){
    
    return (
      <div className='row mb-1'>
        <div className='col-md-1'><b>สถานะ</b></div>
        <div className='col-md-11'>
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label
              onClick={this.handleChangeStatus.bind(this, '')} 
              className={`btn btn-sm btn-secondary ${this.props.active_status === '' && 'active'}`}>
              <input type="radio" name="status_filter" id={'status_filter'} defaultChecked="true" /> 
              ทั้งหมด
            </label>

            {_.map(this.props.available_statuses, this.renderStatus)}
          </div>
        </div>
      </div>
    )
  }
}

export default StatusFilter;
