import React, {Component} from 'react';
import _ from 'lodash';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import DocumentStore from '../../stores/DocumentStore';

export default class SearchDocumentForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      keywords: '',
      target: 'all',
      year: props.year
    }

    this.onChange = this.onChange.bind(this);
    this.onChangeTarget = this.onChangeTarget.bind(this);
    this.onClearConditions = this.onClearConditions.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  onKeyPress(e){
    if(e.which === 13) {
      this.props.handleSubmit(this.state);
    }
  }

  handleSubmit(){
    this.props.handleSubmit(this.state);
  }

  handleDownload(){
    this.props.handleDownload(this.state);
  }

  onChange(event){
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onChangeTarget(event){
    this.setState({
      'target': event.target.value
    })
  }

  onClearConditions(event){
    var dateObj = new Date();
    var year = dateObj.getUTCFullYear() + 543;

    this.setState({
      'keywords': '',
      'target': 'all',
      'year': year
    })
  }

  changeStartDate(date){
    this.setState({start_date: date});
  }

  changeEndDate(date){
    this.setState({end_date: date});
  }

  render(){
    const { keywords, target, year } = this.state;
    let year_options = [2567, 2566, 2565];

    return (
      <div onKeyPress={this.onKeyPress}>
        <div className='card p-3 bg-light'>
          <div className="row mt-3">
            <div className='col-md-1 text-right pt-1'>
              ค้นหา:
            </div>
            <div className='col-md-4'>
              <input 
                type='text' 
                className='form-control form-control-sm'
                name='keywords'
                value={keywords}
                onChange={this.onChange } />
            </div>
            <div className='col-md-4'>
              <select 
                className='form-control form-control-sm'
                name='target'
                value={target}
                onChange={this.onChange}>
                  <option value='all'>ค้นหาทุกเงื่อนไข</option>
                  <option value='number'> - เลข รับ/ส่ง</option>
                  <option value='department_name'> - จาก/ถึง หน่วยงาน</option>
                  <option value='issue_number'> - ที่หนังสือ</option>
                  <option value='document_date'> - ลงวันที่(วว/ดด/25xx) เช่น 01/05/2563</option>
                  <option value='topic'> - เรื่อง</option>
              </select>
            </div>
            <div className='col-md-2'>
              <select 
                className='form-control form-control-sm'
                name='year'
                value={year}
                onChange={this.onChange}>
                  {_.map(year_options, (value, index) => {
                      return <option value={value} key={index}>{value}</option>
                  })}
              </select>
            </div>
            <div className="col-md-1">
              <button type='button' className='btn btn-primary' onClick={this.handleSubmit}>ค้นหา</button>
              <a className='btn btn-link' href="#" onClick={this.onClearConditions}>ล้าง</a>
            </div>
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-12 text-right'>
            <button type='button' className='btn btn-success' onClick={this.handleDownload}>ดาวน์โหลดรายงาน</button>
          </div>
        </div>
      </div>
    )
  }
}
