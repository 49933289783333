import React, { Component } from 'react';

import SimpleModal from '../components/SimpleModal';
import WorkflowProcessHistory from '../components/WorkflowProcessHistory';

class DmsPage extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
  }

  render(){
    return (
      <div className='row my-3'>
        <div className='col-md-12'>
          <h2>ระบบแชร์ไฟล์เอกสาร</h2>
        </div>
        <div className='col-md-12'>
          
          <iframe 
            src="http://dms.winservecorp.co.th/out/out.ViewFolder.php" 
            style={{width: '100%', minHeight: '100vh', height: '100%', border: 0}}>
          </iframe>

        </div>
      </div>
    );
  }
}

export default DmsPage;