import React, {Component} from 'react';
import _ from 'lodash';

const EditOpening = 'opening';

export default class Opening extends Component {
	constructor(props){
    super(props)

    this.onChange = this.onChange.bind(this);
  }

  onChange(event){
    let value = event.target.value;
    this.props.handleChange(EditOpening, value);
  }

	render(){
		let opening_list = ["เรียน", "กราบเรียน", "นมัสการ", "กราบทูล", "ขอประทานกราบทูล"]
		return (
			<div className='row'>
				<div className='col-12'>
					คำขึ้นต้น
				</div>
				<div className='col-12'>
					<select 
            name='opening'
            className='form-control form-control-sm' 
            value={this.props.opening}
            onChange={this.onChange}>
            {_.map(opening_list, (opening_text, index) => {
                return <option value={opening_text} key={index}>{opening_text}</option>
            })}
        	</select>
				</div>
			</div>
		)
	}
}