import React, {Component} from 'react';

export default class InlineEditAttribute extends Component {
	constructor(props){
		super(props)

		this.state = {
			editing: false,
			editing_field_name: this.props.field_name,
			value: this.props.value
		}

		this.handleEditAttribute = this.handleEditAttribute.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSetValue = this.handleSetValue.bind(this);
	}

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading){
      this.setState({
        editing_field_name: nextProps.field_name,
				value: nextProps.value
      })
    }
  }

	handleEditAttribute(){
		this.setState({editing: true})
	}

	handleChange(event){
		this.setState({value: event.target.value});
	}

	handleSetValue(){
		let { value } = this.state;
		this.setState({editing: false}, this.props.handleChange(this.state.editing_field_name, value));
	}

	render(){
		const { label_class, content_class, has_space_after_label, text_align_left } = this.props;

		return (
			<div className='row'>
				<div className={`col-12 ${text_align_left ? 'text-left' : 'text-center'}`}>

					<label className={`${label_class} ${has_space_after_label && 'mr-2'} ${text_align_left && 'text_underline'}`}>
						{this.props.field_name === 'number' && '('}
						{this.props.label}
					</label>
					<label className={content_class}>
						{this.state.editing ? (
							<div>
								<div className='mr-2' style={{display: 'inline-block', width: '400px'}}>
									<input 
										type='text' 
										name='value' 
										value={this.state.value} 
										className='form-control form-control-sm'
										autoFocus
										onBlur={this.handleSetValue} 
										onChange={this.handleChange} />
								</div>
								<div style={{display: 'inline-block'}}>
									<button 
										type='button' 
										className='btn btn-sm btn-primary'
										onClick={this.handleSetValue}>ตกลง</button>
								</div>
							</div>

						) : (
							<div>
								{this.props.value ? (
									<div className='pointer' style={{display: 'inline-block'}} onClick={this.handleEditAttribute}>
										{this.props.value}
									</div>
								) : (
									<div className='text-danger pointer' style={{display: 'inline-block'}} onClick={this.handleEditAttribute}>
										<em>{this.props.suggest_text}</em>
									</div>
								)}
							</div>
						)}
					</label>
					{this.props.field_name === 'number' && ')'}
				</div>
			</div>
		)
	}
}