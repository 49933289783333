import React from 'react';
import _ from 'lodash';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import { documentNumberFormatter, dateFormatter, rowClassNameFormat, thaiDateFormatter } from '../../utils/bootstrapTableFormatters';
import { MergeAttributes } from '../../components/FlatData';
import Loading from '../../components/Loading';

const WorkflowMigrationHistory = (props) => {
  const _setTableOption = () => { 
    if(_.isEmpty(props.workflowMigrations)){
      return "ไม่มีข้อมูล";
    }else{
      return <Loading />
    }
  }

  const showActions = (cell, row) => {
    return(
      <div>
        {row.attributes.can_undo && (
          <button className="btn btn-success btn-sm" onClick={() => props.onUndoSingleWorkflowMigration(row.id)}>
            ดึงกลับภาระงาน
          </button>
        )}
      </div>
    );
  }

  const documentTitleFormatter = (cell, row) => {
    const workflowProcess = _.find(props.movedWorkflowProcesses, { id: row.workflow_process.id })

    return(
      <div>{workflowProcess.attributes.topic}</div>
    )
  }

  const sourceFormatter = (cell, row) => {
    const positionsUser = _.find(props.positions_users, { id: row.source_positions_user.id })

    return(
      <div>{positionsUser.attributes.user_name}</div>
    )
  }

  const targetFormatter = (cell, row) => {
    const positionsUser = _.find(props.positions_users, { id: row.target_positions_user.id })

    return(
      <div>{positionsUser.attributes.user_name}</div>
    )
  }


  const total_items_count = _.get(props.pagination, 'total_items_count', 0);
  const active_page = _.get(props.pagination, 'active_page', 1);

  const cannotUndoWorkflowMigrations = _.filter(MergeAttributes(props.workflowMigrations), { can_undo: false });
  const cannotUndoWorkflowMigrationIds = _.map(cannotUndoWorkflowMigrations, "id");

  const selectRowProp = {
      mode: 'checkbox',
      onSelect: props.onSelectWorkflowMigration,
      selected: props.undoWorkflowMigrationIds,
      unselectable: cannotUndoWorkflowMigrationIds
  };

  const options = {
    sizePerPageList: [],
    sizePerPage: 20,
    page: active_page,
    noDataText: _setTableOption(),
    searchDelayTime: 500
  };

  let workflow_migration_data = [];
  if (!_.isEmpty(props.workflowMigrations)){
    workflow_migration_data = MergeAttributes(props.workflowMigrations);
  }

  return(
    <div id="WorkflowMigrationHistory">
      <div className="row">
        <div className="col-12">
          <h4>ประวัติการย้ายภาระงาน</h4>
        </div>
      </div>
      
      <div className="row mt-2">
        <div className="col-12 rui-mydoc">
          <BootstrapTable 
            data={workflow_migration_data}
            remote={ true }
            fetchInfo={{dataTotalSize: total_items_count}}
            search={ true }
            multiColumnSearch={ true }
            selectRow={ selectRowProp }
            options={ options }
            striped
            hover
            pagination
            version='4'
            tableContainerClass={'table-sm'}
            trClassName={rowClassNameFormat}>
            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
            <TableHeaderColumn width="120" dataFormat={documentNumberFormatter}>เลขที่หนังสือ</TableHeaderColumn>
            <TableHeaderColumn width="120" dataFormat={documentTitleFormatter}>เรื่อง</TableHeaderColumn>
            <TableHeaderColumn dataFormat={sourceFormatter}>ย้ายจาก</TableHeaderColumn>
            <TableHeaderColumn dataFormat={targetFormatter}>ไปยัง</TableHeaderColumn>
            <TableHeaderColumn width="120" dataField='created_at' dataFormat={ thaiDateFormatter }>ย้ายเมื่อ</TableHeaderColumn>
            <TableHeaderColumn width="120" dataField='id' dataFormat={showActions}>จัดการ</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-center">
          {!_.isEmpty(props.workflowMigrations) && (
            <button className="btn btn-primary" onClick={() => props.onUndoAllWorkflowMigrations()}>
              ดึงกลับภาระงานทั้งหมด
            </button>
          )}

          {!_.isEmpty(props.undoWorkflowMigrationIds) && (
            <button className="btn btn-success ml-3" onClick={() => props.onUndoMultipleWorkflowMigrations()}>
              ดึงกลับภาระงานที่ถูกเลือก
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkflowMigrationHistory;