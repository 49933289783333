import React, { Component } from 'react';
import _ from 'lodash';
import { Popover } from 'reactstrap';

import SelectUsers from '../users/SelectUsers';
import SimpleModal from '../../SimpleModal';
import PositionForm from './PositionForm';

import PositionStore from '../../../stores/PositionStore';

const PositionTab = 'position';

export default class RenderPositions extends Component {
	constructor(props){
		super(props)

		this.state = {
			popoverOpenPositionId: '',
			select_user_for_position: {},
			edit_position: {}
		}

		this.toggle = this.toggle.bind(this);
		this.renderPositionRow = this.renderPositionRow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.onCloseAndReloadData = this.onCloseAndReloadData.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if ((this.props.focus !== nextProps.focus) || nextProps.activeMode !== PositionTab) {
			this.setState({popoverOpenPositionId: ''});
		}
	}

	toggle(position){
		let position_id = position.id;
		if (!_.isEmpty(this.state.popoverOpenPositionId)){
			position_id = '';
		}
		this.setState({popoverOpenPositionId: position_id})
	}

	onSelectUser(position){
		this.setState({
			select_user_for_position: position, 
			edit_position: {},
			popoverOpenPositionId: ''
		});
	}

	onEditPosition(position){
		this.setState({
			edit_position: position, 
			select_user_for_position: {},
			popoverOpenPositionId: ''
		});
	}

	onDeletePosition(position){
		if (window.confirm('Would you like to remove this position?')) { 
			PositionStore.request({
	  			id: position.id,
	  			method: 'delete'
	  		}).then((resp)=>{
	  			this.setState({popoverOpenPositionId: ''}, this.props.onCloseAndReloadData());
	  		});
	  	}else{
	  		this.setState({popoverOpenPositionId: ''});
	  	}
	}

	handleClose(){
		this.setState({
			select_user_for_position: {}, 
			edit_position: {},
			popoverOpenPositionId: ''
		});
	}

	renderPositionRow(position, index){
		var item = this.props.focus;
		return (
			<li key={index} className='py-1' style={{borderBottom: '1px solid #eee'}}>
				<div className='row px-2'>
					<div className='col-1 pr-0 text-center'>
						<i className="fa fa-user red-icon" aria-hidden="true"></i>
					</div>
					<div className='col-10 pr-0'>
						{position.attributes.code} - {position.attributes.name}<br />
						<small className="text-muted font-italic">{item.attributes.code} - {item.attributes.name}</small>
					</div>
					<div className='col-1 pl-0'>
						<i id={`Popover_${position.id}`}
							className="fa fa-cog pointer gray-icon" 
							aria-hidden="true"
							onClick={this.toggle.bind(this, position)}>
						</i>
						{(this.state.popoverOpenPositionId === position.id) && (
							<div>{this.renderPopoverPosition(position)}</div>
						)}
					</div>
				</div>
			</li>
		)
	}

	renderPopoverPosition(position){
		let open = false;
		if (this.state.popoverOpenPositionId === position.id){
			open = true;
		}
		return (
			<Popover placement="left" isOpen={open} target={`Popover_${position.id}`} toggle={this.toggle.bind(this, position)} style={{minWidth: '200px'}}>
			  	<ul className='p-0 m-0 popover-list'>
			  		<li className='px-2 py-1' onClick={this.onSelectUser.bind(this, position)}>
			  			<i className="fa fa-user-plus mr-2 green-icon" aria-hidden="true"></i>
			  			Add/Remove User
			  		</li>
			  		<li className='px-2 py-1' onClick={this.onEditPosition.bind(this, position)}>
			  			<i className="fa fa-pencil mr-2 red-icon" aria-hidden="true"></i>
			  			Edit
			  		</li>
			  		<li className='px-2 py-1' onClick={this.onDeletePosition.bind(this, position)}>
			  			<i className="fa fa-times mr-2 red-icon" aria-hidden="true"></i>
			  			Remove
			  		</li>
			  	</ul>
			</Popover>
		)
	}

	onCloseAndReloadData(){
		this.setState({select_user_for_position: {}, edit_position: {}}, this.props.onCloseAndReloadData());
	}

	render() {
		return (
			<div>
				<ul className='list-unstyled'>
					{_.map(this.props.positions, this.renderPositionRow)}
				</ul>

				{!_.isEmpty(this.state.select_user_for_position) && (
					<SimpleModal 
					isOpen={true}
					title={'Select User'}
					showSubmitButton={false}
					onClose={this.handleClose}>
						<SelectUsers 
							focus={this.state.select_user_for_position}
							onClose={this.handleClose} 
							onCloseAndReloadData={this.onCloseAndReloadData} />
					</SimpleModal>
				)}

				{!_.isEmpty(this.state.edit_position) && (
					<SimpleModal 
					isOpen={true}
					title={'Edit Position'}
					showSubmitButton={false}
					onClose={this.handleClose}>
						<PositionForm 
							action={'edit'}
							focus={this.state.edit_position}
							onClose={this.handleClose} 
							onCloseAndReloadData={this.onCloseAndReloadData} />
					</SimpleModal>
				)}
			</div>
		)
	}
}

