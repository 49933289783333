import React, {Component} from 'react';

export default class InlineEditDate extends Component {
	constructor(props){
		super(props)

		this.state = {
			editing: false,
			editing_field_name: this.props.field_name,
			date: this.props.date
		}

		this.handleEditDate = this.handleEditDate.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSetValue = this.handleSetValue.bind(this);
	}

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading){
      this.setState({
        editing_field_name: nextProps.field_name,
				date: nextProps.date
      })
    }
  }

	handleEditDate(){
		this.setState({editing: true})
	}

	handleChange(event){
		this.setState({date: event.target.value});
	}

	handleSetValue(){
		let { date } = this.state;
		this.setState({editing: false}, this.props.handleChange(this.state.editing_field_name, date));
	}

	render(){
		return (
			<div className='row mt-2'>
				<div className='col-12 text-center'>
					<div className={`pr-2 ${this.props.label_class}`} style={{display: 'inline-block'}}>
						{this.props.label}
					</div>
					<div className={this.props.content_class} style={{display: 'inline-block'}}>
						{this.state.editing ? (
							<div>
								<div className='mr-2' style={{display: 'inline-block', width: '400px'}}>
									<input 
										type='text' 
										name='date' 
										value={this.state.date} 
										className='form-control form-control-sm'
										autoFocus
										onBlur={this.handleSetValue} 
										onChange={this.handleChange} />
								</div>
								<div style={{display: 'inline-block'}}>
									<button 
										type='button' 
										className='btn btn-sm btn-primary'
										onClick={this.handleSetValue}>ตกลง</button>
								</div>
							</div>

						) : (
							<div>
								{this.props.date ? (
									<div className='pointer' style={{display: 'inline-block'}} onClick={this.handleEditDate}>
										{this.props.date}
									</div>
								) : (
									<div className='text-danger pointer' style={{display: 'inline-block'}} onClick={this.handleEditDate}>
										<em>{this.props.suggest_text}</em>
									</div>
								)}
							</div>
						)}
						
					</div>
				</div>
			</div>
		)
	}
}